import React from "react"

import arrowRightIcon from "../static/images/arrow-right-icon.png"

/*
	INPUTS
*/
let emptyFunction = () => {} // for react to be happy (onChange events are handled higher up in the dom tree)

export const PootsySelectInput = ({
	name,
	options,
	defaultText,
	selected = "",
	customClass = "",
	showRequired = false,
	isRequired = false,
	disabled,
	allowEmpty = false,
	onChange = emptyFunction,
}) => {
	// A default value in the destructuring will not catch null values
	// which in turn will trigger the selection of the first option
	selected = selected || ""
	let wrapperClass = "pootsy-input pootsy-select-box " + customClass
	isRequired && (wrapperClass += " is-required")
	let inputFinalClass = "pootsy-select " + customClass
	showRequired && (inputFinalClass += " show-required")
	selected !== "" && (inputFinalClass += " option-chosen")
	return (
		<div className={wrapperClass}>
			<select
				name={name}
				className={inputFinalClass}
				value={selected}
				disabled={disabled}
				onChange={onChange}
			>
				<option value="" disabled={!allowEmpty} />
				{options.map(entry => (
					<option key={entry.value} value={entry.value} disabled={entry.disabled}>
						{entry.label}
					</option>
				))}
			</select>
			<label className="select-text-label">{defaultText}</label>
		</div>
	)
}

export const PootsyTextInput = ({
	label,
	name = "",
	value,
	disabled,
	customClass = "",
	onChange = emptyFunction,
	type = "text",
	showRequired = false,
	isRequired = false,
	maxLength,
	min,
	max,
	step,
	onKeyPress = emptyFunction,
	onFocus = emptyFunction,
	placeholder = undefined,
	errorMessage = "",
}) => {
	customClass += isRequired ? " is-required" : ""
	let finalClass = "pootsy-text-input " + customClass + (showRequired ? " show-required" : "")
	return (
		<div className={"pootsy-input pootsy-text-input-box " + customClass}>
			<input
				className={finalClass}
				required
				disabled={disabled}
				type={type}
				value={value}
				name={name}
				onChange={onChange}
				min={min}
				max={max}
				maxLength={maxLength}
				onKeyPress={onKeyPress}
				onFocus={onFocus}
				step={step}
				placeholder={placeholder}
				autoComplete="new-password"
			/>
			<label className="text-label">{label}</label>
			{errorMessage && <div className="warning-text-input">{errorMessage}</div>}
		</div>
	)
}

export const PootsyTwoTextInput = ({
	label,
	name = "",
	secondName,
	value,
	disabled,
	separator,
	customClass = "",
	onChange = emptyFunction,
	type = "text",
	showRequired = false,
	isRequired = false,
	maxLength,
	min,
	max,
	step,
	values,
	onKeyPress = emptyFunction,
	onFocus = emptyFunction,
	placeholder = undefined,
	errorMessage = "",
}) => {
	customClass += isRequired ? " is-required" : ""
	let finalClass = "pootsy-text-input " + customClass + (showRequired ? " show-required" : "")
	return (
		<div className={"pootsy-input pootsy-text-input-box " + customClass}>
			<input
				className={finalClass}
				required
				disabled={disabled}
				type={type}
				value={values[0]}
				name={name}
				onChange={onChange}
				min={min}
				max={max}
				maxLength={maxLength}
				onKeyPress={onKeyPress}
				onFocus={onFocus}
				step={step}
				placeholder={placeholder}
				autoComplete="new-password"
			/>
			{separator}
			<input
				className={finalClass}
				required
				disabled={disabled}
				type={type}
				value={values[1]}
				name={secondName}
				onChange={onChange}
				min={min}
				max={max}
				maxLength={maxLength}
				onKeyPress={onKeyPress}
				onFocus={onFocus}
				step={step}
				placeholder={placeholder}
				autoComplete="new-password"
			/>
			<label className="text-label">{label}</label>
			{errorMessage && <div className="warning-text-input">{errorMessage}</div>}
		</div>
	)
}

export const PootsyRadioInputs = ({
	selected,
	options,
	groupName,
	groupLabel,
	showRequired = false,
	isRequired = false,
	onChange = emptyFunction,
	displayWarning = false,
	warning,
}) => {
	let finalClass =
		"group-title " + (showRequired ? " show-required" : "") + (isRequired ? " is-required" : "")
	return (
		<div className="pootsy-input pootsy-radio-inputs-box">
			<div className={finalClass}>{groupLabel}</div>
			{options.map(entry => (
				<span key={entry.id} className="radio-input-block">
					<input
						type="radio"
						id={entry.id}
						name={groupName}
						value={entry.value}
						checked={selected === entry.value}
						onChange={onChange}
					/>
					<label htmlFor={entry.id} className="radio input-label">
						{entry.label}
					</label>
				</span>
			))}
			{displayWarning && <div className="warning">{warning}</div>}
		</div>
	)
}

export const PootsyCheckboxInput = ({
	label,
	name,
	checked,
	onChange = emptyFunction,
	disabled = false,
}) => {
	let finalClass = "pootsy-input pootsy-checkbox-input-box" + (disabled ? " disabled" : "")
	return (
		<div className={finalClass}>
			<input type="checkbox" name={name} id={name} checked={checked} onChange={onChange} />
			<label className="checkbox-text-label" htmlFor={name}>
				{label}
			</label>
		</div>
	)
}

export const PootsyTextArea = ({ label, value, name, onChange = emptyFunction, placeholder }) => (
	<div className="pootsy-input pootsy-text-area">
		<textarea
			required
			name={name}
			value={value}
			onChange={onChange}
			placeholder={placeholder}
		/>
		<label className="text-label">{label}</label>
	</div>
)

export const PootsyFileInput = ({ label, name, accept = "", onChange }) => (
	<div className="pootsy-input pootsy-file-input-box">
		<input
			className="hidden-file-input"
			id={name}
			name={name}
			type="file"
			accept={accept}
			onChange={onChange}
		/>
		<label className="file-input-label" htmlFor={name}>
			{label}
		</label>
	</div>
)

/*
	Combos
*/
export const PootsySearchBox = ({
	label,
	value,
	name,
	onChange,
	searchResults,
	onResultClick,
	showRequired = false,
	isRequired = false,
}) => {
	return (
		<div className="pootsy-search-box">
			<PootsyTextInput
				label={label}
				name={name}
				value={value}
				showRequired={showRequired}
				isRequired={isRequired}
				onChange={onChange}
			/>
			{searchResults.length > 0 && (
				<div className="pootsy-search-box-results">
					{searchResults.map(entry => (
						<div
							key={entry.value}
							data-value={entry.value}
							data-name={name}
							className="pootsy-search-box-result"
							onClick={onResultClick}
							onKeyPress={onResultClick}
							tabIndex="0"
						>
							{entry.label}
						</div>
					))}
				</div>
			)}
		</div>
	)
}

export const PootsyMultiSelect = ({
	label,
	name,
	showRequired = false,
	isRequired = false,
	searchValue,
	searchResults,
	onChange,
	onResultClick,
	selectedValues,
	handleRemoveValue,
}) => {
	return (
		<div className="pootsy-multiselect">
			<PootsySearchBox
				label={label}
				name={name}
				showRequired={showRequired}
				isRequired={isRequired}
				value={searchValue}
				searchResults={searchResults}
				onChange={onChange}
				onResultClick={onResultClick}
			/>
			{selectedValues.length > 0 && (
				<div className="pootsy-multiselect-current-filters">
					<div className="pootsy-multiselect-results-label">{label}</div>
					{selectedValues.map(entry => (
						<span
							key={entry.value}
							data-value={entry.value}
							data-name={name}
							className="pootsy-multiselect-current-filter"
							onClick={handleRemoveValue}
						>
							{entry.label}
						</span>
					))}
				</div>
			)}
		</div>
	)
}

export const SearchAndSort = ({
	searchLabel,
	handleSearchChange,
	showSortingMenu,
	toggleSortingMenu,
	sortLabel,
	sortValues,
	currentSort,
	handleSortChange,
}) => (
	<div className="pootsy-search-and-sort">
		<PootsyTextInput label={searchLabel} onChange={handleSearchChange} />
		<div
			className={"pootsy-sort" + (showSortingMenu ? " active" : "")}
			onClick={toggleSortingMenu}
		>
			<div className="sort-heading">
				<div className="sort-label">{sortLabel}</div>
				<div className="current-sort">
					{sortValues.find(obj => obj.value === currentSort).label}
				</div>
			</div>
			<img alt="arrow-down" className="pootsy-search-and-sort-arrow" src={arrowRightIcon} />
			{showSortingMenu && (
				<div className="pootsy-sorting-menu">
					{sortValues.map(obj => (
						<div
							key={obj.value}
							className={
								"sorting-value" + (currentSort === obj.value ? " active" : "")
							}
							data-value={obj.value}
							onClick={handleSortChange}
						>
							{obj.label}
						</div>
					))}
				</div>
			)}
		</div>
	</div>
)
