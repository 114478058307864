import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import * as actions from "../actions"

import InputsChangeHandler from "./InputsChangeHandler"
import PootsyAddressBlockInputs from "./PootsyAddressBlockInputs"
import CustomSelect from "./CustomSelect"
import { ContentLayout, OptionBox, PootsyButton, PootsySubHeading } from "./FunctionalDesign"
import ModalLayout from "./ModalLayout"
import {
	PootsyTextInput,
	PootsySelectInput,
	PootsyCheckboxInput,
	PootsyRadioInputs,
	PootsyTextArea,
} from "./FunctionalInputs"
import ImpactedBookingsModal from "./ImpactedBookingsModal"

class WorkerProfile extends Component {
	state = {
		showRequired: { street: false, streetNumber: false, box: false, city: false, zip: false },
	}
	handleFormChanges = data => {
		this.props.dispatch(actions.editWorkerContract(data))
	}
	toggleResetPasswordModal = () => {
		let { workerProfileComponent, dispatch } = this.props
		dispatch(
			actions.workerProfileUIChange({
				showResetPasswordModal: !workerProfileComponent.showResetPasswordModal,
			})
		)
	}
	toggleDeleteAffiliateWorkerModal = () => {
		let { workerProfileComponent, dispatch } = this.props
		dispatch(
			actions.workerProfileUIChange({
				showDeleteAffiliateWorkerModal: !workerProfileComponent.showDeleteAffiliateWorkerModal,
			})
		)
	}
	handleInputChange = changes => {
		this.setState(changes)
	}
	resetPassword = () => {
		let { dispatch, currentWorker } = this.props
		let init = { method: "PUT" }
		let url = `/front/affiliates/affiliate_workers/${currentWorker.id}/reset_password`
		dispatch(actions.simpleFetch(url, init, "RESET_WORKER_PASSWORD"))
	}
	deleteAffiliateWorker = () => {
		this.props.dispatch(actions.deleteAffiliateWorker(this.props.currentWorker.id))
	}
	render() {
		let { t } = this.context
		let {
			workerProfileComponent,
			allWorkerLanguages,
			currentWorker,
			currAffiliate,
		} = this.props
		if (Object.keys(currentWorker).length < 2) {
			return null
		}
		let { showRequired } = this.state
		let {
			sodexoNumber,
			nissNumber,
			title,
			firstName,
			lastName,
			email,
			mobilePhone,
			street,
			streetNumber,
			box,
			city,
			zip,
			country,
			mainLanguage,
			affiliateWorkerNote,
			secSocIdentifier,
			sodexoPin,
			sixtyPercentRuleEligible,
			region,
			identityCardNumber,
			allergies,
			excludedFromTSS,
			canWorkWithAnimals,
			comment,
			sodexoNumberIsRecorded,
			squadIds,
			needsUpfrontPayment,
			externalPartnerId,
		} = currentWorker
		if (!currentWorker.id) {
			return null
		}
		let optionsBoxes = (
			<OptionBox boxTitle={t("cleaner_info")} customClass="cleaner-profile-info">
				<PootsyTextInput
					label={t("sodexo_number")}
					name="sodexoNumber"
					value={sodexoNumber}
				/>
				<PootsyTextArea
					label={t("affiliate_worker_note")}
					name="affiliateWorkerNote"
					value={affiliateWorkerNote}
				/>
			</OptionBox>
		)
		let resetPasswordModalButtons = [
			<PootsyButton key="a" text={t("yes")} onClick={this.resetPassword} />,
			<PootsyButton
				key="b"
				text={t("no")}
				theme="cancel"
				onClick={this.toggleResetPasswordModal}
			/>,
		]
		let deleteWorkerContractModalButtons = [
			<PootsyButton key="a" text={t("yes")} onClick={this.deleteAffiliateWorker} />,
			<PootsyButton
				key="b"
				text={t("no")}
				theme="cancel"
				onClick={this.toggleDeleteAffiliateWorkerModal}
			/>,
		]
		return (
			<InputsChangeHandler customClass="WorkerProfile" onChange={this.handleFormChanges}>
				<ContentLayout optionsBoxes={optionsBoxes} customClass="cleaner-profile">
					{workerProfileComponent.showImpactedBookingsModalForAffiliateWorkerDeletion && (
						<ImpactedBookingsModal onSubmit={this.deleteAffiliateWorker} />
					)}
					{workerProfileComponent.showResetPasswordModal && (
						<ModalLayout
							title={t("reset_worker_password")}
							buttons={resetPasswordModalButtons}
							closeModal={this.toggleResetPasswordModal}
						>
							<div className="reset-password-modal-prompt">
								{t("reset_worker_password_confirmation")}
							</div>
						</ModalLayout>
					)}
					{workerProfileComponent.showDeleteAffiliateWorkerModal && (
						<ModalLayout
							title={t("delete_affiliate_worker")}
							buttons={deleteWorkerContractModalButtons}
							closeModal={this.toggleDeleteAffiliateWorkerModal}
						>
							<div className="reset-password-modal-prompt">
								{t("delete_worker_contract_confirmation")}
							</div>
							{currAffiliate.secSocName === "Group S" && (
								<div className="reset-password-modal-prompt">
									{t("delete_worker_contract_reminder")}
								</div>
							)}
						</ModalLayout>
					)}
					<PootsySubHeading text={t("personal_details")} />
					<div className="cleaner-profile-details">
						<div className="block">
							<PootsyTextInput
								name="nissNumber"
								type="text"
								label={t("niss_number")}
								showRequired={showRequired.nissNumber}
								value={nissNumber}
							/>
							<PootsyTextInput
								name="secSocIdentifier"
								type="text"
								label={t("cleaner_sec_soc_identifier")}
								showRequired={showRequired.secSocIdentifier}
								value={secSocIdentifier}
							/>
							<PootsyTextInput
								name="sodexoPin"
								type="text"
								label={t("cleaner_sodexo_pin")}
								showRequired={showRequired.sodexoPin}
								value={sodexoPin}
							/>
							{!sodexoNumberIsRecorded && (
								<PootsyTextInput
									name="sodexoNumber"
									type="text"
									label={t("sodexo_number")}
									showRequired={showRequired.sodexoNumber}
									value={sodexoNumber}
								/>
							)}
							<CustomSelect
								name="squadIds"
								selectLabel={t("squads")}
								valuesList={currAffiliate.squads.map(e => ({
									label: e.name,
									value: e.id,
								}))}
								currentValues={squadIds}
								onChange={this.handleFormChanges}
								showRequired={showRequired.coveredAreas}
							/>
							<PootsyCheckboxInput
								name="needsUpfrontPayment"
								label={t("needs_upfront_payment")}
								checked={needsUpfrontPayment}
							/>
						</div>
						<div className="block">
							<PootsyRadioInputs
								groupName="title"
								groupLabel={t("title")}
								showRequired={showRequired.cleanerTitle}
								selected={title}
								options={[
									{ id: "radio-mr", value: "mr", label: t("mr") },
									{ id: "radio-mrs", value: "mrs", label: t("mrs") },
								]}
							/>
						</div>
						<div className="block">
							<PootsyTextInput
								name="firstName"
								label={t("first_name")}
								showRequired={showRequired.firstName}
								value={firstName}
							/>
							<PootsyTextInput
								name="lastName"
								label={t("last_name")}
								showRequired={showRequired.lastName}
								value={lastName}
							/>
							<PootsyTextInput
								name="email"
								label={t("email")}
								showRequired={showRequired.email}
								value={email}
							/>
							<PootsyTextInput
								name="mobilePhone"
								label={t("phone")}
								showRequired={showRequired.mobilePhone}
								value={mobilePhone}
							/>
							<div className="languages-spoken-checkboxes">
								<PootsySelectInput
									name="mainLanguage"
									defaultText={t("cleaner_main_language")}
									customClass="main-language"
									selected={mainLanguage}
									showRequired={showRequired.mainLanguage}
									options={allWorkerLanguages.map(e => ({
										label: t(e.ref),
										value: e.ref,
									}))}
								/>
								<label className="new-customer-spoken-languages-label">
									{t("spoken_languages")}
								</label>
								<div className="new-worker-checkboxes">
									{allWorkerLanguages
										.filter(e => ["nl", "en", "fr"].indexOf(e.ref) > -1)
										.map(e => (
											<PootsyCheckboxInput
												key={e.ref}
												name={e.ref}
												label={t(e.ref)}
												checked={
													currentWorker[e.ref] || mainLanguage === e.ref
												}
											/>
										))}
								</div>
							</div>
							<PootsySelectInput
								name="region"
								defaultText={t("region")}
								selected={region}
								showRequired={showRequired.region}
								options={[
									{ label: t("wallonie"), value: "Wallonie" },
									{ label: t("vlaanderen"), value: "Vlaanderen" },
									{ label: t("brussels"), value: "Bruxelles" },
								]}
							/>
							<PootsySelectInput
								name="sixtyPercentRuleEligible"
								defaultText={t("worker_sixty_percent_rule_eligible")}
								selected={sixtyPercentRuleEligible}
								showRequired={showRequired.sixtyPercentRuleEligible}
								options={[
									{ label: t("eligible"), value: "eligible" },
									{ label: t("ineligible"), value: "ineligible" },
									{ label: t("not_applicable"), value: "not_applicable" },
								]}
							/>
							<PootsyTextInput
								name="identityCardNumber"
								type="text"
								label={t("identity_card_number")}
								showRequired={showRequired.identityCardNumber}
								value={identityCardNumber}
							/>
							<PootsyTextInput
								name="externalPartnerId"
								type="text"
								label={t("external_partner_id")}
								showRequired={showRequired.externalPartnerId}
								value={externalPartnerId}
							/>
						</div>
						<div className="block">
							<PootsyTextInput
								name="allergies"
								label={t("allergies")}
								showRequired={showRequired.allergies}
								value={allergies}
							/>
							<PootsyTextInput
								name="comment"
								label={t("comment")}
								showRequired={showRequired.comment}
								value={comment}
							/>
							<PootsyRadioInputs
								groupName="excludedFromTSS"
								groupLabel={t("excluded_from_tss")}
								showRequired={showRequired.excludedFromTSS}
								selected={excludedFromTSS}
								options={[
									{ id: "radio-true", value: "true", label: t("yes") },
									{ id: "radio-false", value: "false", label: t("no") },
								]}
							/>
							<PootsyRadioInputs
								groupName="canWorkWithAnimals"
								groupLabel={t("can_work_with_animals")}
								showRequired={showRequired.canWorkWithAnimals}
								selected={canWorkWithAnimals}
								options={[
									{ id: "radio-animals-true", value: "true", label: t("yes") },
									{ id: "radio-animals-false", value: "false", label: t("no") },
								]}
							/>
						</div>
					</div>
					<div className="cleaner-profile-address">
						<PootsyAddressBlockInputs
							showRequired={showRequired}
							setValues={this.handleFormChanges}
							values={{ city, zip, box, streetNumber, street, country }}
							showCountry={true}
						/>
					</div>
					<div className="cleaner-profile-danger-zone">
						<div className="button-box">
							<PootsySubHeading text={t("danger_zone")} />
							<PootsyButton
								text={t("reset_worker_password")}
								theme="red"
								onClick={this.toggleResetPasswordModal}
							/>
							<PootsyButton
								text={t("delete_affiliate_worker")}
								theme="red"
								onClick={this.toggleDeleteAffiliateWorkerModal}
							/>
						</div>
					</div>
				</ContentLayout>
			</InputsChangeHandler>
		)
	}
}

WorkerProfile.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currAffiliate: state.redData.currentAffiliate,
	currentWorker: state.redData.currentWorker,
	allWorkerLanguages: state.redData.allWorkerLanguages,
	workerProfileComponent: state.redComponents.workerProfileComponent,
})
export default connect(mapStateToProps)(WorkerProfile)
