import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"

import * as actions from "../actions"

import { PootsySubHeading, SodexoServiceLine, PSVSlipLine } from "./FunctionalDesign"
import ModalLayout from "./ModalLayout"
import PSVScanningZone from "./PSVScanningZone"

class SearchPSV extends Component {
	handleChanges = changes => {
		this.props.dispatch(actions.modalSearchPsvStateChange(changes))
	}
	searchPsv = code => {
		if (code !== "") {
			code = code.substring(0, 33)
			this.props.dispatch(actions.fetchPSV(code))
		}
	}
	componentWillUnmount() {
		this.handleChanges({ psv: null })
	}
	addPsvToGroup = () => {
		this.props.dispatch(
			actions.addPsvToGroup(this.props.component.psv.id, this.props.delivery_groupID)
		)
	}
	closeModal = () => {
		this.handleChanges({ psv: null })
	}
	render() {
		let { t } = this.context
		let {
			component: { psv },
		} = this.props

		let psvScheduledDate = moment.invalid()
		if (psv) psvScheduledDate = moment(psv.attributes.delivery_date)
		return (
			<div className="SearchPSV">
				<PootsySubHeading text={t("search_psv")} />
				<PSVScanningZone onScan={this.searchPsv} />
				{psv && (
					<ModalLayout
						title={t("search_psv_result_modal_title")}
						customClass="search-psv-result-modal"
						closeModal={this.closeModal}
					>
						<div className="psv-infos">
							<PootsySubHeading fontSize="small" text={t("psv_code")} />
							<div className="psv-code">{psv.attributes.code}</div>
						</div>

						{psv.attributes.origin === "affiliate" && (
							<PootsySubHeading text={t("added_psv_label_by_affiliates_title")} />
						)}
						{psv.attributes.origin === "worker" && (
							<PootsySubHeading text={t("added_psv_label_by_workers")} />
						)}
						{psv && (
							<SodexoServiceLine
								sodexoService={{
									id: psv.attributes.subject_id,
									date: psvScheduledDate,
									customerName: psv.attributes.customer_name,
									workerName: psv.attributes.worker_name,
									type: psv.attributes.subject_type,
									vouchersDueCount: psv.attributes.subject_due_psv_count,
									status: psv.attributes.subject_status,
									isLinked: psv.attributes["is_linked?"],
								}}
							/>
						)}
						{psv.attributes.paper_voucher_delivery_group && (
							<Fragment>
								<PootsySubHeading text={t("linked_return_slip")} />
								<PSVSlipLine
									psvSlip={psv.attributes.paper_voucher_delivery_group}
								/>
							</Fragment>
						)}
					</ModalLayout>
				)}
			</div>
		)
	}
}

SearchPSV.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.searchPsvComponent,
})
export default connect(mapStateToProps)(SearchPSV)
