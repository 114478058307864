import * as ActionTypes from "../actions"
import { history } from "../history"

// new booking > redirection
export const networkResponsesActions = store => next => action => {
	let downloadTriggerer
	switch (action.type) {
		// NETWORK CALL REQUESTS
		///////////////////////////////////////////////////////////////////////////
		case ActionTypes.NEWBOOKING_REQUEST:
			next(ActionTypes.showSpinner("creating_new_booking", action.requestId))
			next(action)
			return
		case ActionTypes.WORKERSFILTERS_REQUEST:
			next(ActionTypes.showSpinner("getting_worker_filters", action.requestId))
			next(action)
			return
		case ActionTypes.CREATEABSENCE_REQUEST:
			next(ActionTypes.showSpinner("creating_absence", action.requestId))
			next(action)
			return
		case ActionTypes.CREATEABSENCEFROMAGENDA_REQUEST:
			next(ActionTypes.showSpinner("creating_absence", action.requestId))
			next(action)
			return
		case ActionTypes.DELETEBOOKING_REQUEST:
			next(ActionTypes.showSpinner("deleting_booking", action.requestId))
			next(action)
			return
		case ActionTypes.SUBMITDIRECTBOOKINGFROMAGENDA_REQUEST:
			next(ActionTypes.showSpinner("creating_direct_booking", action.requestId))
			next(action)
			return
		case ActionTypes.UPDATEABSENCE_REQUEST:
			next(ActionTypes.showSpinner("updating_absence", action.requestId))
			next(action)
			return
		case ActionTypes.UPDATESETTINGS_REQUEST:
			next(ActionTypes.showSpinner("updating_settings", action.requestId))
			next(action)
			return
		case ActionTypes.UPDATEWORKER_REQUEST:
			next(ActionTypes.showSpinner("updating_cleaner", action.requestId))
			next(action)
			return
		case ActionTypes.UPDATECUSTOMER_REQUEST:
			next(ActionTypes.showSpinner("updating_customer", action.requestId))
			next(action)
			return
		case ActionTypes.CUSTOMERSFILTERS_REQUEST:
			next(ActionTypes.showSpinner("getting_customer_filters", action.requestId))
			next(action)
			return
		case ActionTypes.ALLAREAS_REQUEST:
			next(ActionTypes.showSpinner("getting_areas", action.requestId))
			next(action)
			return
		case ActionTypes.NEWAFFILIATEUSER_REQUEST:
			next(ActionTypes.showSpinner("creating_new_affiliate_user", action.requestId))
			next(action)
			return
		case ActionTypes.APPROVEABSENCE_REQUEST:
			next(ActionTypes.showSpinner("approving_absence", action.requestId))
			next(action)
			return
		case ActionTypes.REFUSEABSENCE_REQUEST:
			next(ActionTypes.showSpinner("refusing_absence", action.requestId))
			next(action)
			return
		case ActionTypes.NEWBOOKING_SUBMITNEWRESIDENCE_REQUEST:
			next(ActionTypes.showSpinner("creating_new_residence", action.requestId))
			next(action)
			return
		case ActionTypes.NEWDIRECTBOOKING_SUBMITNEWRESIDENCE_REQUEST:
			next(ActionTypes.showSpinner("creating_new_residence", action.requestId))
			next(action)
			return
		case ActionTypes.SUBMITEXTRAAVAIL_REQUEST:
			next(ActionTypes.showSpinner("creating_extra_availability", action.requestId))
			next(action)
			return
		case ActionTypes.CANCELABSENCE_REQUEST:
			next(ActionTypes.showSpinner("cancelling_absence", action.requestId))
			next(action)
			return
		case ActionTypes.DASHBOARDDATA_REQUEST:
			next(ActionTypes.showSpinner("getting_dashboard_data", action.requestId))
			next(action)
			return
		case ActionTypes.CUSTOMERPROFILE_SUBMITNEWRESIDENCE_REQUEST:
			next(ActionTypes.showSpinner("creating_new_residence", action.requestId))
			next(action)
			return
		case ActionTypes.UPDATERESIDENCE_REQUEST:
			next(ActionTypes.showSpinner("updating_residence", action.requestId))
			next(action)
			return
		case ActionTypes.WORKERCONTRACTEND_REQUEST:
			next(ActionTypes.showSpinner("adding_end_date_to_worker_contract", action.requestId))
			next(action)
			return
		case ActionTypes.RESET_WORKER_PASSWORD_REQUEST:
			next(ActionTypes.showSpinner("resetting_worker_password", action.requestId))
			next(action)
			return
		case ActionTypes.CREATECUSTOMERFROMNEWBOOKING_REQUEST:
		case ActionTypes.CREATECUSTOMERFROMNEWDIRECTBOOKING_REQUEST:
			next(ActionTypes.showSpinner("creating_customer", action.requestId))
			next(action)
			return
		case ActionTypes.AUTH_REQUEST:
			next(ActionTypes.showSpinner("authenticating", action.requestId))
			next(action)
			return
		case ActionTypes.CHECKTOKEN_REQUEST:
			next(ActionTypes.showSpinner("checking_token", action.requestId))
			next(action)
			return
		case ActionTypes.FETCH_WORKSHOP_ORDERS_REQUEST:
			next(ActionTypes.showSpinner("fetching_orders", action.requestId))
			next(action)
			return
		case ActionTypes.FETCHBOOKINGS_REQUEST:
			if (action.requestMeta.source !== "SODEXO") {
				next(ActionTypes.showSpinner("fetching_booking", action.requestId))
			}
			next(action)
			return
		case ActionTypes.FETCHBOOKING_REQUEST:
			next(ActionTypes.showSpinner("fetching_booking", action.requestId))
			next(action)
			return
		case ActionTypes.BOOKINGFILTERS_REQUEST:
			next(ActionTypes.showSpinner("fetching_booking_filters", action.requestId))
			next(action)
			return
		case ActionTypes.CHANGEBOOKINGSTATUS_REQUEST:
			next(ActionTypes.showSpinner("changing_booking_statug", action.requestId))
			next(action)
			return
		case ActionTypes.CANCELBOOKING_REQUEST:
			next(ActionTypes.showSpinner("cancelling_bookings", action.requestId))
			next(action)
			return
		case ActionTypes.FETCHWORKERS_REQUEST:
			next(ActionTypes.showSpinner("fetching_workers", action.requestId))
			next(action)
			return
		case ActionTypes.FETCHWORKER_REQUEST:
			next(ActionTypes.showSpinner("fetching_worker", action.requestId))
			next(action)
			return
		case ActionTypes.FETCHWORKERABSENCES_REQUEST:
			next(ActionTypes.showSpinner("fetching_worker_absences", action.requestId))
			next(action)
			return
		case ActionTypes.FETCHWORKERBOOKINGS_REQUEST:
			next(ActionTypes.showSpinner("fetching_worker_bookings", action.requestId))
			next(action)
			return
		case ActionTypes.WORKERSUGGESTIONS_REQUEST:
			next(ActionTypes.showSpinner("fetching_cleaners", action.requestId))
			next(action)
			return
		case ActionTypes.CREATEWORKER_REQUEST:
			next(ActionTypes.showSpinner("creating_cleaner", action.requestId))
			next(action)
			return
		case ActionTypes.FETCHCUSTOMERS_REQUEST:
			next(ActionTypes.showSpinner("fetching_customers", action.requestId))
			next(action)
			return
		case ActionTypes.FETCHCUSTOMER_REQUEST:
			next(ActionTypes.showSpinner("fetching_customer", action.requestId))
			next(action)
			return
		case ActionTypes.SUBMITNEWCUSTOMER_REQUEST:
			next(ActionTypes.showSpinner("creating_new_customer", action.requestId))
			next(action)
			return
		case ActionTypes.CUSTOMERSUGGESTION_REQUEST:
			next(ActionTypes.showSpinner("fetching_customers", action.requestId))
			next(action)
			return
		case ActionTypes.TIMESLOTS_REQUEST:
			next(ActionTypes.showSpinner("searching_available_timeslots", action.requestId))
			next(action)
			return
		case ActionTypes.CHECKSODEXO_REQUEST:
			next(ActionTypes.showSpinner("checking_pluxee_number", action.requestId))
			next(action)
			return
		case ActionTypes.FETCHSCHEDULES_REQUEST:
			next(ActionTypes.showSpinner("fetching_schedules", action.requestId))
			next(action)
			return
		case ActionTypes.REOPEN_ALL_MONTH_TIMESHEETS_REQUEST:
			next(ActionTypes.showSpinner("reopening_timesheets", action.requestId))
			next(action)
			return
		case ActionTypes.SCHEDULE_PDF_REQUEST:
			next(ActionTypes.showSpinner("creating_pdf", action.requestId))
			next(action)
			return
		case ActionTypes.DELETECUSTOMERCONTRACT_REQUEST:
			next(ActionTypes.showSpinner("deleting_customer_contract", action.requestId))
			next(action)
			return
		case ActionTypes.SUBMITBOOKINGMESSAGE_REQUEST:
			next(ActionTypes.showSpinner("sending_booking_timeline_message", action.requestId))
			next(action)
			return
		case ActionTypes.INVITECUSTOMER_REQUEST:
			next(ActionTypes.showSpinner("inviting_customer", action.requestId))
			next(action)
			return
		case ActionTypes.ENDCUSTOMERCONTRACT_REQUEST:
			next(ActionTypes.showSpinner("ending_contract", action.requestId))
			next(action)
			return
		case ActionTypes.TIMESHEET_EXPORT_REQUEST:
			next(ActionTypes.showSpinner("creating_timesheet_export", action.requestId))
			next(action)
			return
		case ActionTypes.SUBMITTIMESHEETUPDATE_REQUEST:
			next(ActionTypes.showSpinner("updating_timesheet", action.requestId))
			next(action)
			return
		case ActionTypes.RETURN_SLIP_PDF_REQUEST:
		case ActionTypes.SUBMIT_RENDER_TEMPLATE_REQUEST:
			next(ActionTypes.showSpinner("creating_pdf", action.requestId))
			next(action)
			return
		case ActionTypes.SODEXO_DECLARATION_CSV_REQUEST:
			next(ActionTypes.showSpinner("creating_csv", action.requestId))
			next(action)
			return
		case ActionTypes.FETCHEDITEDBOOKING_REQUEST:
			next(ActionTypes.showSpinner("fetching_booking", action.requestId))
			next(action)
			return
		case ActionTypes.SUBMIT_BOOKINGNOTEUPDATE_REQUEST:
			next(ActionTypes.showSpinner("fetching_booking", action.requestId))
			next(action)
			return
		case ActionTypes.FETCH_CUSTOMER_PAYMENTS_REQUEST:
			next(ActionTypes.showSpinner("fetching_payments_status", action.requestId))
			next(action)
			return
		case ActionTypes.SAVE_WORKER_DOCUMENT_REQUEST:
			next(ActionTypes.showSpinner("fetching_payments_status", action.requestId))
			next(action)
			return
		case ActionTypes.SODEXO_MANUAL_SYNC_REQUEST:
			next(ActionTypes.showSpinner("syncing_with_pluxee", action.requestId))
			next(action)
			return
		case ActionTypes.FETCH_EARLIEST_PICK_UP_DATE_REQUEST:
			next(ActionTypes.showSpinner("fetching_pick_up_dates", action.requestId))
			next(action)
			return
		case ActionTypes.FETCH_PAYMENT_REMINDER_REQUEST:
		case ActionTypes.AGENDA_WORKER_FULL_MONTH_REQUEST:
		case ActionTypes.FETCHWORKERMESSAGES_REQUEST:
		case ActionTypes.FETCH_LINKED_CUSTOMER_REQUEST:
		case ActionTypes.UPDATEABSENCEFROMAGENDA_REQUEST:
		case ActionTypes.CUSTOMERS_LIST_EXPORT_REQUEST:
		case ActionTypes.CHEQUES_LIST_EXPORT_REQUEST:
		case ActionTypes.WORKERS_LIST_EXPORT_REQUEST:
		case ActionTypes.FETCH_WORKER_WEEK_REQUEST:
		case ActionTypes.SUBMIT_OTHER_ACTIVITY_REQUEST:
		case ActionTypes.FETCH_EXPENSES_DATA_REQUEST:
		case ActionTypes.SUBMIT_UPDATE_WORKER_CONTRACT_REQUEST:
		case ActionTypes.SUBMIT_NEW_WORKER_CONTRACT_REQUEST:
		case ActionTypes.SUBMIT_FORCE_UPDATE_WORKER_CONTRACT_REQUEST:
		case ActionTypes.AGENDA_ROWS_REQUEST:
		case ActionTypes.SUBMIT_SEC_SOC_TERM_MAPPING_REQUEST:
		case ActionTypes.FETCH_ALL_WORKERS_REQUEST:
		case ActionTypes.FETCH_ALL_CUSTOMERS_REQUEST:
		case ActionTypes.FETCH_ALL_BOOKINGS_REQUEST:
		case ActionTypes.FETCH_TIMESHEET_CODES_SUMS_REQUEST:
		case ActionTypes.DELETE_WORKER_CONTRACT_REQUEST:
		case ActionTypes.FETCH_WORKSHOP_ORDER_REQUEST:
		case ActionTypes.SUBMIT_WORKSHOP_ORDER_REQUEST:
		case ActionTypes.UPDATE_WORKSHOP_ORDER_REQUEST:
		case ActionTypes.FETCH_DIMONAS_REQUEST:
		case ActionTypes.DIMONAS_FILTERS_REQUEST:
		case ActionTypes.DELETE_DIMONA_REQUEST:
		case ActionTypes.SUBMIT_NEW_DIMONA_REQUEST:
		case ActionTypes.FETCH_UNEMPLOYMENT_DECLARATIONS_REQUEST:
		case ActionTypes.FETCH_UNEMPLOYMENT_BOOKS_REQUEST:
		case ActionTypes.FETCH_ECONOMIC_SUSPENSIONS_REQUEST:
		case ActionTypes.SUBMIT_BIG_ECONOMIC_SUSPENSION_REQUEST:
		case ActionTypes.FETCH_ECONOMIC_SUSPENSION_PDF_REQUEST:
		case ActionTypes.FETCH_DECLARATION_PDF_REQUEST:
		case ActionTypes.CREATE_CUSTOMER_ABSENCE_REQUEST:
		case ActionTypes.WORKSHOP_ORDERS_RESCHEDULING_ATTEMPT_REQUEST:
		case ActionTypes.SUBMIT_LOCK_WEEK_REQUEST:
		case ActionTypes.REPLACE_EVENT_AGENDA_ROWS_REQUEST:
		case ActionTypes.FETCH_ACTIVE_CONTRACTS_IDS_REQUEST:
		case ActionTypes.FETCH_POTENTIAL_CORRESPONDING_DIMONA_IN_REQUEST:
		case ActionTypes.LAUNDRY_DAY_REPORT_REQUEST:
		case ActionTypes.UPDATE_WORKSHOP_REQUEST:
		case ActionTypes.CANCELABSENCE_FROMAGENDA_REQUEST:
		case ActionTypes.DELETEHOLIDAY_FROMWORKER_REQUEST:
		case ActionTypes.RESTOREHOLIDAY_FROMWORKER_REQUEST:
		case ActionTypes.UPDATE_WORKER_SALARIES_REQUEST:
		case ActionTypes.INDEXING_WORKERS_SALARY_REQUEST:
		case ActionTypes.SUBMIT_BATCH_ECONOMIC_SUSPENSION_REQUEST:
		case ActionTypes.SUBMIT_DIRECT_REPLACEMENTS_REQUEST:
		case ActionTypes.SUBMIT_REPLACEMENTS_REQUEST:
		case ActionTypes.SUBMIT_OTHER_ACTIVITY_WITH_IMPACTED_BOOKINGS_REQUEST:
		case ActionTypes.SUBMIT_REMOVE_BOOKING_WISH_DATERANGE_REQUEST:
		case ActionTypes.SUBMIT_BOOKING_WISH_DATERANGE_REQUEST:
		case ActionTypes.FETCH_BOOKING_WISHES_REQUEST:
		case ActionTypes.FETCH_TEAMS_REQUEST:
		case ActionTypes.SUBSCRIBE_TO_TEAM_REQUEST:
		case ActionTypes.SUBMIT_DELETE_WORKSHOP_ORDER_REQUEST:
		case ActionTypes.REFRESH_TIMESHEET_REQUEST:
		case ActionTypes.CONFIRM_REFRESH_TIMESHEET_REQUEST:
		case ActionTypes.REOPEN_TIMESHEET_WEEK_REQUEST:
		case ActionTypes.CLOSE_TIMESHEET_MONTH_REQUEST:
		case ActionTypes.FETCH_SODEXO_PSV_DELIVERIES_REQUEST:
		case ActionTypes.FETCH_SODEXO_PSV_DELIVERY_REQUEST:
		case ActionTypes.FETCH_NEW_PSV_DELIVERY_REQUEST:
		case ActionTypes.SEARCH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT_REQUEST:
		case ActionTypes.UPDATE_BOOKING_WISH_GROUP_STATUS_REQUEST:
		case ActionTypes.SEARCH_FULL_MONTH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT_REQUEST:
		case ActionTypes.UPDATE_WORKSHOP_ADDRESS_REQUEST:
		case ActionTypes.REACTIVATE_CUSTOMER_CONTRACT_REQUEST:
		case ActionTypes.DIRECT_BOOKING_MODIFICATION_REQUEST:
		case ActionTypes.FETCH_CUSTOMER_ABSENCES_REQUEST:
		case ActionTypes.FETCH_SEC_SOC_CUSTOM_EXPENSES_CODES_REQUEST:
		case ActionTypes.DELETE_CUSTOM_EXPENSE_CODE_REQUEST:
		case ActionTypes.SUBMIT_CUSTOM_EXPENSE_CODE_REQUEST:
			next(ActionTypes.showSpinner("", action.requestId))
			next(action)
			return

		// NETWORK CALL SUCCESSES
		///////////////////////////////////////////////////////////////////////////
		case ActionTypes.DELETE_AFFILIATE_WORKER_SUCCESS:
			history.push("/cleaners/")
			next(action)
			return
		case ActionTypes.NEWBOOKING_SUCCESS:
			history.push("/bookings/" + action.data.id)
			next(ActionTypes.bookingDetailsUIChange({ showNewBookingModal: false }))
			next(action)
			return
		case ActionTypes.DELETEBOOKING_SUCCESS:
		case ActionTypes.CANCELBOOKING_SUCCESS:
			if (action.requestMeta.from === "agenda") {
				let {
					redData: {
						agenda: { weekStart },
					},
				} = store.getState()
				// agenda needs to be refetched, because of potentially missing availabilities
				let workerID = action.data.attributes.worker_contract_id
				store.dispatch(ActionTypes.refetchAgendaLines([workerID], weekStart.format()))
			} else {
				history.push({
					pathname: action.requestMeta.pushToPath,
					search: action.requestMeta.pushQuery,
				})
			}
			next(ActionTypes.bookingDetailsUIChange({ showCancelModal: false }))
			next(ActionTypes.agendaStateChange({ showCancelBookingModal: false }))
			next(action)
			return
		case ActionTypes.SUBMITNEWCUSTOMER_SUCCESS:
			next(action)
			history.push("/customers/" + action.data.id + "/profile")
			return
		case ActionTypes.CREATEWORKER_SUCCESS:
			history.push("/cleaners/" + action.data.id + "/profile")
			next(action)
			return
		case ActionTypes.CHANGEBOOKINGSTATUS_SUCCESS:
			next(ActionTypes.bookingDetailsUIChange({ showChangeStatusModal: false }))
			next(action)
			return
		case ActionTypes.FETCHSCHEDULES_SUCCESS:
			next(action)
			return
		case ActionTypes.DELETECUSTOMERCONTRACT_SUCCESS:
			history.push("/customers/")
			next(ActionTypes.resetCustomerData())
			next(action)
			return
		case ActionTypes.ENDCUSTOMERCONTRACT_SUCCESS:
			history.push("/customers/")
			next(ActionTypes.resetCustomerData())
			next(action)
			return
		case ActionTypes.CREATECUSTOMERFROMNEWBOOKING_SUCCESS:
			next(ActionTypes.newBookingStateChange({ showNewCustomerModal: false }))
			next(action)
			return
		case ActionTypes.SCHEDULE_PDF_SUCCESS:
			next(ActionTypes.timesheetsStateChange({ showPDFModal: true, text: action.data }))
			next(action)
			return
		case ActionTypes.TIMESHEET_EXPORT_SUCCESS:
			if (action.timesheetExportURL) {
				downloadTriggerer = document.createElement("a")
				downloadTriggerer.href = action.timesheetExportURL
				downloadTriggerer.download = action.filename
				downloadTriggerer.click()
				downloadTriggerer.remove()
			}
			return
		case ActionTypes.RETURN_SLIP_PDF_SUCCESS:
			next(ActionTypes.sodexoUIChange({ showReturnSlipModal: true }))
			next(action)
			return
		case ActionTypes.SODEXO_DECLARATION_CSV_SUCCESS:
			downloadTriggerer = document.createElement("a")
			downloadTriggerer.href = action.sodexoDeclarationCSV
			downloadTriggerer.download = action.filename
			downloadTriggerer.click()
			downloadTriggerer.remove()
			return
		case ActionTypes.FETCH_ECONOMIC_SUSPENSION_PDF_SUCCESS:
		case ActionTypes.FETCH_DECLARATION_PDF_SUCCESS:
			downloadTriggerer = document.createElement("a")
			downloadTriggerer.href = "data:application/pdf;base64," + action.base64_data
			downloadTriggerer.download = action.filename
			downloadTriggerer.click()
			downloadTriggerer.remove()
			return
		case ActionTypes.SUBMIT_RENDER_TEMPLATE_SUCCESS:
			downloadTriggerer = document.createElement("a")
			downloadTriggerer.href = action.templatePDF
			downloadTriggerer.download = action.filename
			downloadTriggerer.click()
			downloadTriggerer.remove()
			return
		case ActionTypes.CSV_SODEXO_PSV_DELIVERY_SUCCESS:
			downloadTriggerer = document.createElement("a")
			downloadTriggerer.href = action.csvDeliveryGroupExport
			downloadTriggerer.download = action.filename
			downloadTriggerer.click()
			downloadTriggerer.remove()
			return
		case ActionTypes.UPDATECUSTOMER_SUCCESS:
			if (action.meta && action.meta.warnings.length > 0) {
				next(ActionTypes.showBackendWarnings(action.meta.warnings))
			}
			next(action)
			return
		case ActionTypes.SUBMIT_WORKSHOP_ORDER_SUCCESS:
			history.push("/workshop/com-center/today")
			next(action)
			return
		case ActionTypes.CUSTOMERS_LIST_EXPORT_SUCCESS:
		case ActionTypes.CHEQUES_LIST_EXPORT_SUCCESS:
		case ActionTypes.WORKERS_LIST_EXPORT_SUCCESS:
			downloadTriggerer = document.createElement("a")
			downloadTriggerer.href = action.url
			downloadTriggerer.download = action.filename
			downloadTriggerer.click()
			downloadTriggerer.remove()
			return

		// NETWORK CALL FAILURES
		///////////////////////////////////////////////////////////////////////////
		case ActionTypes.SUBMITDIRECTBOOKINGFROMAGENDA_FAILURE:
			next(ActionTypes.directBookingCreationWarning(action.error.json.error))
			next(ActionTypes.acknowledgeErrors())
			next(action)
			return
		case ActionTypes.CANCELABSENCE_FAILURE:
			if (
				action.error.status === 412 &&
				action.error.json?.error_data?.impacted_other_activities?.impacted_other_activities
					?.length > 0
			) {
				next(ActionTypes.workerAbsencesCancelWarning(action.requestMeta.absenceIndex))
			}
			next(action)
			return
		case ActionTypes.CANCELABSENCE_FROMAGENDA_FAILURE:
			if (
				action.error.status === 412 &&
				action.error.json?.error_data?.impacted_other_activities?.impacted_other_activities
					?.length > 0
			) {
				next(ActionTypes.absencesCancelWarningAgenda(action.requestMeta.absenceIndex))
			}
			next(action)
			return
		default:
			next(action)
			return
	}
}
