import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { Link, Route } from "react-router-dom"
import PropTypes from "prop-types"

import * as actions from "../actions"

import { PageLayout, PootsyButton } from "./FunctionalDesign"
import { PootsySelectInput } from "./FunctionalInputs"
import { NavLink } from "./NavLink"
import WorkshopCommandCenter from "./WorkshopCommandCenter"
import WorkshopOrders from "./WorkshopOrders"
import WorkshopOrdersIssues from "./WorkshopOrdersIssues"

class Workshop extends Component {
	UNSAFE_componentWillMount = () => {
		let {
			dispatch,
			component: { workshopId },
		} = this.props
		if (!workshopId) dispatch(actions.setGlobalWorkshopId())
	}
	handleWorkshopChange = e => {
		localStorage.setItem("pootsy-last-chosen-workshop", e.target.value)
		this.props.dispatch(actions.processWorkshopChange({ newWorkshopId: e.target.value }))
	}
	toggleFindOrderMode = () => {
		this.handleChanges({ findOrderMode: !this.props.component.findOrderMode })
	}
	render() {
		let { t } = this.context
		let {
			match,
			currentAffiliate: { workshops, workshopNeedsMapping },
			component: { workshopId },
		} = this.props
		let pageHeaderLeft = (
			<div className="page-header-left">
				<h1 className="page-title customers">{t("workshop")}</h1>
				<PootsySelectInput
					name="workshopId"
					defaultText={t("workshop")}
					selected={workshopId}
					onChange={this.handleWorkshopChange}
					options={workshops.map(entry => ({
						label: entry.workshopName,
						value: entry.id,
					}))}
				/>
			</div>
		)

		let pageHeaderRight = (
			<Link to="/workshop/new">
				<PootsyButton
					text={t("new_workshop_order")}
					onClick={this.toggleNewCustomerModal}
					disable={workshopNeedsMapping}
				/>
			</Link>
		)
		let linksArray = [
			{ label: t("command_center"), link: "com-center/today" },
			{ label: t("laundry_orders"), link: "orders" },
			{ label: t("problem_orders"), link: "issues" },
		]
		let subNavLinks = linksArray.map(entry => (
			<NavLink key={entry.link} to={"/workshop/" + entry.link}>
				{entry.icon} {entry.label}
			</NavLink>
		))
		return (
			<PageLayout
				customClass="Workshop"
				headerLeft={pageHeaderLeft}
				headerRight={pageHeaderRight}
				subNavLinks={subNavLinks}
			>
				{!workshopNeedsMapping && workshops.length !== 0 && (
					<Fragment>
						<Route
							path={match.path + "/com-center"}
							component={WorkshopCommandCenter}
						/>
						<Route path={match.path + "/orders"} component={WorkshopOrders} />
						<Route path={match.path + "/issues"} component={WorkshopOrdersIssues} />
					</Fragment>
				)}
				{workshops.length === 0 && (
					<div className="workshop-no-mapping">{t("no_workshop")}</div>
				)}
				{workshopNeedsMapping && (
					<div className="workshop-no-mapping">{t("workshop_needs_mapping_message")}</div>
				)}
			</PageLayout>
		)
	}
}

Workshop.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.workshopComponent,
	currentAffiliate: state.redData.currentAffiliate,
})
export default connect(mapStateToProps)(Workshop)
