import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"

import * as actions from "../actions"

import { OptionBox } from "./FunctionalDesign"
import DateFilterer from "./DateFilterer"
import BackEndFilters from "./BackEndFilters"

class DimonasFilters extends Component {
	state = {
		queuedCall: "",
	}
	componentDidMount = async () => {
		let { dispatch, customQueries } = this.props
		dispatch(actions.fetchDimonasFilters())
		await dispatch(
			actions.addQueries({
				...customQueries,
				pageQuery: {
					urlName: "page",
					queryName: "page",
					mustBeSet: true,
					defaultValue: "1",
				},
			})
		)
	}
	componentWillUnmount = () => {
		this.props.dispatch(
			actions.dimonasFiltersStateChange({
				dimonasNeedRefetch: true,
				page: 1,
			})
		)
		this.props.dispatch(actions.resetQuery())
	}
	UNSAFE_componentWillReceiveProps = async newProps => {
		let {
			dispatch,
			component: { search: oldSearch, page: oldPage, fetched: oldFetched },
		} = this.props
		let {
			dimonasFilters,
			component: {
				search: newSearch,
				page: newPage,
				fetched: newFetched,
				dimonasNeedRefetch,
			},
		} = newProps
		if (oldSearch !== newSearch && (newSearch.length === 0 || newSearch.length > 2)) {
			this.scheduleApiCall(newSearch)
		}
		if (oldPage !== newPage) {
			this.updateQuery({ name: "pageQuery", value: newPage })
		}
		if ((!oldFetched && newFetched) || dimonasNeedRefetch) {
			let newQueries = dimonasFilters.reduce((acc, filter) => {
				acc[filter.name] = {
					urlName: filter.name,
					queryName: filter.url_param,
					mustBeSet: false,
					defaultValue: filter.default_value,
				}
				return acc
			}, {})
			this.handleChanges({ dimonasNeedRefetch: false })
			await dispatch(actions.addQueries(newQueries))
			dispatch(actions.fetchDimonas())
		}
	}
	scheduleApiCall = value => {
		let { queuedCall } = this.state
		clearTimeout(queuedCall)
		let futureCall = setTimeout(
			() => this.updateQuery({ name: "searchQuery", value: value }),
			800
		)
		this.setState({ queuedCall: futureCall })
	}
	handleChanges = changes => {
		this.props.dispatch(actions.dimonasFiltersStateChange(changes))
	}
	updateQuery = async changes => {
		if (changes.name !== "pageQuery") {
			if (Array.isArray(changes)) {
				changes = [...changes, { name: "pageQuery", value: 1 }]
			} else {
				changes = [changes, { name: "pageQuery", value: 1 }]
			}
		}
		await this.props.dispatch(actions.updateQuery(changes))
		this.props.dispatch(actions.fetchDimonas())
	}
	handleDateFilterChange = changes => {
		let key = Object.keys(changes)[0]
		let newQuery = { name: key }
		if (!changes[key].isValid()) {
			newQuery.value = ""
		} else {
			newQuery.value = changes[key].format("YYYY-MM-DD")
		}
		this.updateQuery(newQuery)
	}
	handleCreationDateFiltersChange = dateFilters => {
		if (!dateFilters.from) {
			dateFilters.from = moment(dateFilters.to).subtract(14, "days")
		}
		if (!dateFilters.to) {
			dateFilters.to = moment(dateFilters.from).add(7, "days")
		}
		let query = [
			{ name: "fromDateQuery", value: dateFilters.from.format("YYYY-MM-DD") },
			{ name: "toDateQuery", value: dateFilters.to.format("YYYY-MM-DD") },
		]
		this.updateQuery(query)
	}
	handleSelectFilterChange = e => {
		let { name, value } = e.target
		this.updateQuery({ name, value })
	}
	handleUpdateFilters = changes => {
		if (changes.name === "cleanerQuery" || changes.name === "customerQuery") {
			changes = [changes, { name: "pageQuery", value: 1 }]
		}
		this.updateQuery(changes)
	}
	render() {
		let { t } = this.context
		let {
			dimonasFilters,
			filteredFilters,
			hideAllFilters,
			children,
			currentQuery: {
				urlQuery,
				urlQuery: { from, to },
			},
		} = this.props

		let mFrom = from ? moment(from) : ""
		let mTo = to ? moment(to) : ""

		dimonasFilters.forEach(entry => (entry.value = urlQuery[entry.name]))
		if (hideAllFilters) {
			return null
		}
		return (
			<OptionBox boxTitle={t("filters")} customClass="DimonasFilters">
				<DateFilterer
					onChange={this.handleCreationDateFiltersChange}
					from={mFrom}
					to={mTo}
				/>
				<BackEndFilters
					filters={dimonasFilters.filter(entry => !filteredFilters.includes(entry.name))}
					filterInputChange={this.handleUpdateFilters}
					selectInputChange={this.handleSelectFilterChange}
				/>
				{children && children}
			</OptionBox>
		)
	}
}

DimonasFilters.defaultProps = {
	customQueries: [],
	filteredFilters: [],
	hideAllFilters: false,
}
// Those are required because used by Queryize
// the main offender is match, which is used in the getUrl parameter function in Queryize
DimonasFilters.propTypes = {
	match: PropTypes.shape({
		path: PropTypes.string,
		url: PropTypes.string,
		isExact: PropTypes.bool,
		params: PropTypes.object,
	}).isRequired,
	location: PropTypes.shape({
		pathname: PropTypes.string,
		search: PropTypes.string,
		hash: PropTypes.string,
		state: PropTypes.object,
		key: PropTypes.string,
	}).isRequired,
	history: PropTypes.shape({
		length: PropTypes.number,
		action: PropTypes.string,
		block: PropTypes.func,
		createHred: PropTypes.func,
		go: PropTypes.func,
		goBack: PropTypes.func,
		goForward: PropTypes.func,
		listen: PropTypes.func,
		location: PropTypes.object,
		push: PropTypes.func,
		replace: PropTypes.func,
	}).isRequired,
}
DimonasFilters.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	dimonasFilters: state.redData.dimonasFilters,
	component: state.redComponents.dimonasFiltersComponent,
	currentQuery: state.currentURLQuery,
})

export default connect(mapStateToProps)(DimonasFilters)
