import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import * as actions from "../actions"

import moment from "moment"

import { history } from "../history"
import BookingItem from "./BookingItem"
import Paginator from "./Paginator"
import BookingFilters from "./BookingFilters"
import { ContentLayout } from "./FunctionalDesign"
import { PootsyTextInput } from "./FunctionalInputs"

class WorkerBookings extends Component {
	handleSearchChange = e => {
		this.props.dispatch(actions.bookingFiltersStateChange({ search: e.target.value }))
	}
	handleChangePage = changes => {
		this.props.dispatch(actions.bookingFiltersStateChange({ page: changes.value }))
	}

	changePage = async changes => {
		let { dispatch } = this.props
		await dispatch(actions.updateQuery(changes))
		dispatch(actions.fetchBookings())
	}
	render() {
		let { t } = this.context
		let { match, pagination, bookings } = this.props
		let optionsBoxes = (
			<BookingFilters
				key="a"
				match={match}
				location={window.location}
				history={history}
				filteredFilters={["workers_filter"]}
				customQueries={{
					fromDateQuery: {
						urlName: "from",
						queryName: "from_date",
						mustBeSet: true,
						defaultValue: moment().format("YYYY-MM-DD"),
					},
					toDateQuery: {
						urlName: "to",
						queryName: "to_date",
						mustBeSet: true,
						defaultValue: moment().format("YYYY-MM-DD"),
					},
					searchQuery: { urlName: "search", queryName: "filter_name", mustBeSet: false },
					ratingQuery: {
						urlName: "rating",
						queryName: "filter_rating",
						mustBeSet: false,
					},
					workerQuery: {
						urlName: "wf",
						queryName: "filter_affiliate_worker_ids",
						mustBeSet: true,
						defaultValue: [match.params.id],
					},
				}}
			/>
		)
		let contentTop = (
			<PootsyTextInput
				customClass="bookings-search-input"
				label={t("search_booking")}
				onChange={this.handleSearchChange}
			/>
		)
		return (
			<ContentLayout optionsBoxes={optionsBoxes} contentTop={contentTop}>
				<div className="cleaner-bookings-list">
					{bookings.map(entry => (
						<BookingItem key={entry.id} booking={entry} />
					))}
				</div>
				<Paginator
					name="pageQuery"
					currPage={this.props.pagination.current_page}
					totalPages={pagination.total_pages}
					goToPage={this.changePage}
				/>
			</ContentLayout>
		)
	}
}

WorkerBookings.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currentWorker: state.redData.currentWorker,
	bookings: state.redData.bookings,
	lang: state.i18nState.lang,
	filters: state.redData.bookingFilters,
	pagination: state.redComponents.bookingFiltersComponent.pagination,
	page: state.redComponents.bookingFiltersComponent.page,
})
export default connect(mapStateToProps)(WorkerBookings)
