import React from "react"

const SvgComponent = props => (
	<svg className="timer-svg" width={24} height={24} {...props}>
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M15 1H9v2h6V1zm-4 13h2V8h-2v6zm8.03-6.61l1.42-1.42c-.43-.51-.9-.99-1.41-1.41l-1.42 1.42A8.962 8.962 0 0 0 12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9a8.994 8.994 0 0 0 7.03-14.61zM12 20c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" />
	</svg>
)

export default SvgComponent
