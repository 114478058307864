import { combineReducers } from "redux"
import * as ActionTypes from "../actions"
import { i18nState } from "redux-i18n"

import { translations } from "../translations"

import { redData } from "./redData"
import { redComponents } from "./redComponents"

const appMeta = (state = { frozen: false }, action) => {
	switch (action.type) {
		case ActionTypes.REHYDRATE_FROZEN_STATE:
			return { frozen: true }
		default:
			return state
	}
}

const translationsInit = { lang: "en", translations, translationsFetched: false }
const locale = (state = translationsInit, action) => {
	switch (action.type) {
		case ActionTypes.SET_LANG:
			return { ...state, lang: action.lang }
		case ActionTypes.FETCH_REPLACEMENT_TRANSLATIONS_SUCCESS:
			return {
				...state,
				translationsFetched: true,
				translations: JSON.parse(action.translations),
			}
		default:
			return state
	}
}

const auth = (
	state = { loggedIn: false, tokenChecked: false, currentAffiliateID: "", token: "" },
	action
) => {
	switch (action.type) {
		case ActionTypes.CHECKTOKEN_SUCCESS:
		case ActionTypes.AUTH_SUCCESS:
			return { loggedIn: true, tokenChecked: true, token: action.data.attributes.token }
		case ActionTypes.SELECT_AFFILIATE:
			return { ...state, currentAffiliateID: action.chosenAffiliate.id }
		case ActionTypes.AUTH_FAILURE:
			return { ...state }
		case ActionTypes.TOKENCHECKED:
			return { ...state, tokenChecked: true }
		case ActionTypes.CHECKTOKEN_FAILURE:
			return { ...state, tokenChecked: true }
		case ActionTypes.LOGOUT:
			return { loggedIn: false, tokenChecked: true }
		default:
			return state
	}
}

const requestsSuccess = (state = {}, action) => {
	switch (action.type) {
		case ActionTypes.SUBMITNEWCUSTOMER_SUCCESS:
			return { ...state, [ActionTypes.SUBMITNEWCUSTOMER_SUCCESS]: true }
		case ActionTypes.SUBMITEXTRAAVAIL_SUCCESS:
			return { ...state, [ActionTypes.SUBMITEXTRAAVAIL_SUCCESS]: true }
		case ActionTypes.NEWDIRECTBOOKING_SUBMITNEWRESIDENCE_SUCCESS:
			return { ...state, [ActionTypes.NEWDIRECTBOOKING_SUBMITNEWRESIDENCE_SUCCESS]: true }
		case ActionTypes.NEWBOOKING_SUBMITNEWRESIDENCE_SUCCESS:
			return { ...state, [ActionTypes.NEWBOOKING_SUBMITNEWRESIDENCE_SUCCESS]: true }
		case ActionTypes.CREATEABSENCE_SUCCESS:
			return { ...state, [ActionTypes.CREATEABSENCE_SUCCESS]: true }
		case ActionTypes.CREATEWORKER_SUCCESS:
			return { ...state, [ActionTypes.CREATEWORKER_SUCCESS]: true }
		case ActionTypes.CREATEABSENCEFROMAGENDA_SUCCESS:
			return { ...state, [ActionTypes.CREATEABSENCEFROMAGENDA_SUCCESS]: true }
		case ActionTypes.UPDATECUSTOMER_SUCCESS:
			return { ...state, [ActionTypes.UPDATECUSTOMER_SUCCESS]: true }
		case ActionTypes.UPDATEABSENCE_SUCCESS:
			return { ...state, [ActionTypes.UPDATEABSENCE_SUCCESS]: true }
		case ActionTypes.UPDATEWORKER_SUCCESS:
			return { ...state, [ActionTypes.UPDATEWORKER_SUCCESS]: true }
		case ActionTypes.UPDATESETTINGS_SUCCESS:
			return { ...state, [ActionTypes.UPDATESETTINGS_SUCCESS]: true }
		case ActionTypes.ACKNOWLEDGE_REQUEST_SUCCESS:
			let newState = { ...state }
			delete newState[action.successType]
			return newState
		case ActionTypes.LOGOUT:
			return {}
		default:
			return state
	}
}

const backendErrors = (state = { show: false, errors: "", status: "" }, action) => {
	switch (action.type) {
		case ActionTypes.BACKENDERROR:
			return { show: true, errors: action.error, status: action.status }
		case ActionTypes.ACKNOWLEDGE_ERRORS:
		case ActionTypes.AUTH_REQUEST:
		case ActionTypes.LOGOUT:
			return { ...state, show: false }
		default:
			return state
	}
}

const backendWarnings = (state = { show: false, messages: [] }, action) => {
	switch (action.type) {
		case ActionTypes.BACKEND_WARNINGS:
			return { show: true, messages: action.messages }
		case ActionTypes.ACKNOWLEDGE_WARNINGS:
		case ActionTypes.AUTH_REQUEST:
		case ActionTypes.LOGOUT:
			return { ...state, show: false }
		default:
			return state
	}
}

const flashMessage = (state = { message: "" }, action) => {
	switch (action.type) {
		case ActionTypes.FLASH_MESSAGE:
			return { ...state, message: action.message }
		default:
			return state
	}
}

const warningsInit = {
	warningNotChrome: false,
	warningOldVersionUsage: false,
	warningMissingConventions: false,
}
const warnings = (state = warningsInit, action) => {
	switch (action.type) {
		case ActionTypes.WARNING_OLD_VERSION_USAGE:
			return { ...state, warningOldVersionUsage: true }
		case ActionTypes.WARNING_NOT_CHROME:
			return { ...state, warningNotChrome: true }
		case ActionTypes.WARNING_MISSING_CONVENTIONS:
			return { ...state, warningMissingConventions: true }
		case ActionTypes.LOGOUT:
			return warningsInit
		default:
			return state
	}
}

const showSpinner = (state = { show: false, messages: {}, currentRequests: [] }, action) => {
	switch (action.type) {
		case ActionTypes.SHOW_SPINNER:
			return {
				show: true,
				messages: { ...state.messages, [action.requestId]: action.mess },
				currentRequests: [...state.currentRequests, action.requestId],
			}
		case ActionTypes.HIDE_SPINNER:
			let newCurrentRequest = state.currentRequests.filter(e => e !== action.requestId)
			let shouldShow = false
			let newMessages = { ...state.messages }
			delete newMessages[action.requestId]
			if (newCurrentRequest.length > 0) {
				shouldShow = true
			}
			return { show: shouldShow, messages: newMessages, currentRequests: newCurrentRequest }
		case ActionTypes.LOGOUT:
			return { show: false, messages: {}, currentRequests: [] }
		default:
			return state
	}
}

const currentURLQueryInit = {
	queries: {},
	realQuery: {},
	urlQuery: {},
	fetchingAllowed: true,
}
const currentURLQuery = (state = currentURLQueryInit, action) => {
	switch (action.type) {
		case ActionTypes.URL_QUERY_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.RESET_QUERY:
		case ActionTypes.LOGOUT:
			return currentURLQueryInit
		default:
			return state
	}
}

const initUrlQueriesArchive = {}
const urlQueriesArchive = (state = initUrlQueriesArchive, action) => {
	switch (action.type) {
		case ActionTypes.STORE_QUERY:
			return { ...state, [action.componentName]: action.data }
		case ActionTypes.LOGOUT:
			return {}
		default:
			return state
	}
}

const initComponentStateArchive = {}
const componentStateArchive = (state = initComponentStateArchive, action) => {
	switch (action.type) {
		case ActionTypes.STORE_COMPONENT_STATE:
			return { ...state, [action.componentName]: action.data }
		case ActionTypes.LOGOUT:
			return {}
		default:
			return state
	}
}

const metaStateArchiveInit = {}
const metaStateArchive = (state = metaStateArchiveInit, action) => {
	switch (action.type) {
		case ActionTypes.STORE_META_STATE:
			return { ...state, [action.componentName]: action.data }
		case ActionTypes.LOGOUT:
			return {}
		default:
			return state
	}
}

const longPollingInit = {
	active: false,
	requestContent: false,
}
const longPolling = (state = longPollingInit, action) => {
	switch (action.type) {
		// case ActionTypes.AUTH_SUCCESS:
		// case ActionTypes.CHECKTOKEN_SUCCESS:
		// return { ...state, active: true }
		case ActionTypes.LONG_POLLING_CHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.LOGOUT:
			return longPollingInit
		default:
			return state
	}
}

const appReducer = combineReducers({
	appMeta,
	i18nState,
	redData,
	redComponents,
	locale,
	auth,
	requestsSuccess,
	backendErrors,
	backendWarnings,
	flashMessage,
	warnings,
	showSpinner,
	currentURLQuery,
	longPolling,
	urlQueriesArchive,
	componentStateArchive,
	metaStateArchive,
})

const rootReducer = (state, action) => {
	if (action.type === ActionTypes.REHYDRATE_FROZEN_STATE) {
		state = action.frozenState
	}
	return appReducer(state, action)
}

export default rootReducer
