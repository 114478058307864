import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { PootsyButton, LabeledDateTime } from "./FunctionalDesign"
import { PootsyRadioInputs, PootsySelectInput } from "./FunctionalInputs"
import ModalLayout from "./ModalLayout"

import * as actions from "../actions"
import SearchCustomer from "./SearchCustomer"

class DirectBookingModificationModal extends Component {
	componentDidMount = () => {
		let {
			dispatch,
			directBookingModificationModal: { customerContractId },
		} = this.props
		dispatch(actions.fetchBookingCustomerContract(customerContractId))
	}
	componentWillUnmount = () => {
		this.props.dispatch(actions.resetDirectBookingModificationModal())
	}
	handleFormChanges = changes => {
		this.props.dispatch(actions.directBookingModificationStateChange(changes))
	}
	submit = () => {
		this.props.dispatch(actions.submitDirectBookingModification())
	}
	handleLinkedCustomerChange = customerId => {
		let {
			directBookingModificationModal: { linkedCustomers },
		} = this.props

		let chosenCustomer = linkedCustomers.find(lc => lc.id === customerId) || null

		this.handleFormChanges({
			showOtherCustomer: false,
			chosenCustomer: chosenCustomer,
		})
	}
	handleCustomerClick = customer => {
		this.handleFormChanges({
			chosenCustomer: customer,
			newCustomerContractId: null,
			otherCustomerContractId: customer.id,
			customerName: customer.attributes.display_name,
		})
	}
	handleSearchCustomer = customerName => {
		this.handleFormChanges({
			customerName: customerName,
		})
	}
	render() {
		let { t } = this.context
		let {
			closeModal,
			directBookingModificationModal: {
				startTime,
				endTime,
				dayPeriod,
				reach,
				voucherType,
				showRequired,
				disableSubmission,
				ssCode,
				linkedCustomers,
				newCustomerContractId,
				chosenCustomer,
				residenceIndex,
				showOtherCustomer,
				customerName,
				addressId,
			},
		} = this.props
		let buttons = [
			<PootsyButton
				key="a"
				text={t("save")}
				onClick={this.submit}
				disabled={disableSubmission}
			/>,
			<PootsyButton key="b" text={t("cancel")} theme="cancel" onClick={closeModal} />,
		]
		let reachOptions = [
			{
				label: t("modify_one_occurrence"),
				value: "one_occurrence",
			},
		]

		if (dayPeriod !== 0) {
			reachOptions.push({
				label: t("modify_all_occurrences"),
				value: "all_occurrences_from_date",
			})
		}

		if (
			!residenceIndex &&
			chosenCustomer &&
			chosenCustomer.attributes.residences.data.length > 1
		) {
			chosenCustomer.attributes.residences.data.forEach((residence, index) => {
				if (Number(residence.id) === Number(addressId)) {
					this.handleFormChanges({ residenceIndex: String(index) })
				}
			})
		}

		return (
			<ModalLayout
				title={t("direct_booking_modification_modal_title")}
				closeModal={closeModal}
				customClass="direct-booking-modification-modal"
				formDivClass="form"
				withInputsChangeHandler={true}
				handleChanges={this.handleFormChanges}
				buttons={buttons}
			>
				<PootsySelectInput
					name="reach"
					defaultText={t("modification_reach")}
					selected={reach}
					showRequired={showRequired.reach}
					options={reachOptions}
				/>
				<PootsySelectInput
					name="voucherType"
					defaultText={t("voucher_type")}
					selected={voucherType}
					showRequired={showRequired.voucherType}
					options={[
						{ label: t("paper"), value: "paper" },
						{ label: t("electronic"), value: "electronic" },
					]}
				/>
				{linkedCustomers.length > 0 && (
					<PootsySelectInput
						name="newCustomerContractId"
						defaultText={t("linked_customers_title")}
						selected={newCustomerContractId}
						showRequired={showRequired.newCustomerContractId}
						options={linkedCustomers.map(lc => ({
							label: lc.attributes.display_name,
							value: lc.id,
						}))}
						onChange={event => this.handleLinkedCustomerChange(event.target.value)}
					/>
				)}

				{showOtherCustomer && (
					<SearchCustomer
						name="otherCustomerContractId"
						customLabel={t("customer")}
						onChange={this.handleSearchCustomer}
						handleCustomerClick={this.handleCustomerClick}
						value={customerName}
					/>
				)}

				<PootsySelectInput
					name="ssCode"
					defaultText={t("ss_code")}
					customClass="ss-code"
					selected={ssCode}
					showRequired={showRequired.SSCode}
					options={this.props.affiliate.secSocEvents.codes
						.sort((a, b) => t(a.label).localeCompare(t(b.label)))
						.map(code_object => {
							return {
								value: code_object.id,
								label: code_object.id + " - " + t(code_object.label),
							}
						})}
				/>
				<div className="timepickers">
					<LabeledDateTime
						label={t("start_time")}
						showDatePicker={false}
						timePickerProps={{
							value: startTime,
							name: "startTime",
							onChange: this.handleFormChanges,
						}}
					/>
					<LabeledDateTime
						label={t("end_time")}
						showDatePicker={false}
						timePickerProps={{
							value: endTime,
							name: "endTime",
							onChange: this.handleFormChanges,
							filterHours: h => h >= startTime.hours(),
							filterMinutes: m =>
								startTime.hours() < endTime.hours()
									? m >= 0 && m <= 59
									: m > startTime.minute(),
						}}
					/>
				</div>
				{reach === "all_occurrences_from_date" && (
					<PootsyRadioInputs
						groupLabel={t("recurrence")}
						groupName="dayPeriod"
						selected={dayPeriod && dayPeriod.toString()}
						showRequired={showRequired.dayPeriod}
						options={[
							{ id: "weekly", value: "7", label: t("every_week") },
							{ id: "bimonthly", value: "14", label: t("every_two_weeks") },
							{ id: "monthly", value: "28", label: t("every_four_weeks") },
						]}
					/>
				)}

				{chosenCustomer && (
					<div className="chosen-customer-address">
						<PootsyRadioInputs
							groupLabel={t("residence")}
							groupName="residenceIndex"
							selected={residenceIndex || "0"}
							options={chosenCustomer.attributes.residences.data.map(
								(entry, index) => ({
									id: entry.id,
									value: index.toString(),
									label: entry.attributes.address,
								})
							)}
						/>
					</div>
				)}
			</ModalLayout>
		)
	}
}

DirectBookingModificationModal.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	directBookingModificationModal: state.redComponents.directBookingModificationModal,
	affiliate: state.redData.currentAffiliate,
})
export default connect(mapStateToProps)(DirectBookingModificationModal)
