import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"
import * as actions from "../actions"

import Filter from "./Filter"
import {
	OptionBox,
	ContentLayout,
	PootsyButton,
	LabeledSimpleLine,
	PootsySubHeading,
	OnssErrorModal,
} from "./FunctionalDesign"
import DateFilterer from "./DateFilterer"

import { PootsySelectInput } from "./FunctionalInputs"
import { downloadFileFromUrl } from "../helpers/downloadFileFromUrl"
import SearchWorker from "./SearchWorker"
import NewEconomicSuspensionModal from "./NewEconomicSuspensionModal"
import WorkerMultiSelect from "./WorkerMultiSelect"

class EconomicSuspensions extends Component {
	componentWillUnmount = () => {
		this.props.dispatch(actions.resetEconomicSuspensionsState())
	}
	UNSAFE_componentWillMount = () => {
		this.handleChanges({
			reason_big_eco: this.props.currAffiliate.reasonBigEcoUnemployment,
			reason_small_eco: this.props.currAffiliate.reasonSmallEcoUnemployment,
		})
		this.props.dispatch(actions.fetchEconomicSuspensions())
		if (this.props.teams.length === 0) {
			this.props.dispatch(actions.fetchTeams())
		}
	}
	handleChanges = changes => {
		this.props.dispatch(actions.economicUnemploymentStateChange(changes))
	}
	handleFilterWorker = workerName => {
		this.handleChanges({ searchedAffiliateWorker: { name: workerName } })
		if (workerName === "" || workerName === null) {
			this.handleChanges({ searchedAffiliateWorkerId: null })
			this.props.dispatch(actions.fetchEconomicSuspensions())
		}
	}
	handleFilterStatus = e => {
		let { value } = e.target
		this.handleChanges({ status: value })
		this.props.dispatch(actions.fetchEconomicSuspensions())
	}
	handleSquadsFilterChange = changes => {
		this.handleChanges({ squadsFilter: changes.value })
		this.props.dispatch(actions.fetchEconomicSuspensions())
	}
	toggleOnssErrorModal = id => {
		let { economicSuspensions } = this.props
		let es = economicSuspensions.find(entry => entry.id === id)
		let changes = {}
		if (!es || !id || !es.attributes.errors) {
			changes = { showOnssErrorModal: false, onssErrors: "" }
		} else {
			changes = {
				showOnssErrorModal: true,
				onssErrors: es.attributes.errors,
				onssErrorsTradKeys: es.attributes.error_translation_keys,
			}
		}
		this.handleChanges(changes)
	}
	toggleWorkerMultiSelectModal = () => {
		let {
			component: { showWorkersSelectionModal },
		} = this.props
		this.handleChanges({ showWorkersSelectionModal: !showWorkersSelectionModal })
	}
	toggleEcoSuspModalForBatchCreation = () => {
		let {
			component: { showEcoSuspModalForBatchCreation },
		} = this.props
		this.handleChanges({ showEcoSuspModalForBatchCreation: !showEcoSuspModalForBatchCreation })
	}
	handleFilterWorkerClick = worker => {
		this.handleChanges({
			searchedAffiliateWorkerId: Number(worker.id),
			searchedAffiliateWorker: { name: worker.attributes.display_name },
		})
		this.props.dispatch(actions.fetchEconomicSuspensions())
	}
	toggleNewSuspensionModal = () => {
		this.props.dispatch(actions.resetEconomicSuspensionsState())
		this.handleChanges({ showNewSuspensionModal: !this.props.component.showNewSuspensionModal })
	}
	closeSuspensionModal = () => {
		this.handleChanges({ showNewSuspensionModal: false })
	}
	handleDateFilterChange = changes => {
		let newObj = { rangeStart: changes.from, rangeEnd: changes.to }
		this.handleChanges(newObj)
		this.props.dispatch(actions.fetchEconomicSuspensions())
	}
	fetchEcoSuspensionPDF = suspension => {
		if (suspension.attributes.pdf_url) {
			downloadFileFromUrl(
				suspension.attributes.pdf_url,
				`#${suspension.id} - ${suspension.attributes.affiliate_worker} unemployment declaration.pdf`
			)
			return
		}
		this.props.dispatch(actions.fetchEconomicSuspensionPDF(suspension.id))
	}
	selectWorkers = list => {
		this.handleChanges({ selectedWorkersForBatchSuspensionCreation: list })
		this.toggleWorkerMultiSelectModal()
		this.toggleEcoSuspModalForBatchCreation()
	}
	submitBatchSuspensionCreation = suspension => {
		let {
			component: { selectedWorkersForBatchSuspensionCreation },
		} = this.props
		this.props.dispatch(
			actions.batchCreateSuspensions({
				workers: selectedWorkersForBatchSuspensionCreation,
				suspension,
			})
		)
		this.handleChanges({ selectedWorkersForBatchSuspensionCreation: [] })
		this.toggleEcoSuspModalForBatchCreation()
	}
	render() {
		let { t } = this.context
		let {
			economicSuspensions,
			teams,
			component: {
				rangeStart,
				rangeEnd,
				searchedAffiliateWorker,
				showOnssErrorModal,
				onssErrors,
				onssErrorsTradKeys,
				status,
				showNewSuspensionModal,
				squadsFilter,
				showWorkersSelectionModal,
				showEcoSuspModalForBatchCreation,
			},
			currAffiliate,
		} = this.props
		let optionsBoxes = (
			<OptionBox boxTitle={t("eco_unemployment")}>
				<PootsySubHeading text={t("date_filters")} />
				<DateFilterer
					alwaysOpened={true}
					from={rangeStart}
					to={rangeEnd}
					// rangeLimitEnd={}
					onChange={this.handleDateFilterChange}
				/>
				<SearchWorker
					onChange={this.handleFilterWorker}
					onClick={this.handleFilterWorkerClick}
					value={searchedAffiliateWorker && searchedAffiliateWorker.name}
				/>
				<PootsySelectInput
					name="status"
					defaultText={t("status")}
					selected={status}
					options={actions.ONSS_STATUSES.map(e => ({ label: t(e), value: e }))}
					onChange={this.handleFilterStatus}
					allowEmpty={true}
				/>
				<Filter
					label={t("squads")}
					name="squadsFilter"
					possibleValues={teams.map(entry => ({
						label: entry.attributes.name,
						value: entry.id,
					}))}
					currentFilters={squadsFilter}
					onChange={this.handleSquadsFilterChange}
				/>
				<PootsyButton
					text={t("new_economic_suspension")}
					onClick={this.toggleNewSuspensionModal}
					disabled={!currAffiliate.hasONSSSettings}
				/>
				<PootsyButton
					text={t("batch_economic_suspensions")}
					onClick={this.toggleWorkerMultiSelectModal}
					disabled={!currAffiliate.hasONSSSettings}
				/>
			</OptionBox>
		)
		return (
			<ContentLayout optionsBoxes={optionsBoxes} customClass="EconomicSuspensions">
				{showOnssErrorModal && (
					<OnssErrorModal
						errors={onssErrors}
						errorsTradKeys={onssErrorsTradKeys}
						closeModal={this.toggleOnssErrorModal}
					/>
				)}
				{economicSuspensions &&
					economicSuspensions.map(eco_suspension => (
						<LabeledSimpleLine
							key={eco_suspension.id}
							simpleLineProps={{
								noLink: true,
								customClass: "eco-unemployment-line",
								onClick: () => this.toggleOnssErrorModal(eco_suspension.id),
							}}
							columns={[
								{ noLabel: true, content: eco_suspension.id, customClass: "id" },
								{
									label: t("onss_ref_number"),
									value: eco_suspension.attributes.onss_ref_number,
								},
								{
									label: t("start_date"),
									value: moment(eco_suspension.attributes.start_date).format(
										"DD/MM/YYYY"
									),
								},
								{
									label: t("end_date"),
									value: eco_suspension.attributes.end_date
										? moment(eco_suspension.attributes.end_date).format(
												"DD/MM/YYYY"
										  )
										: "-",
								},
								{
									noLabel: true,
									content: t(eco_suspension.attributes.economic_suspension_type),
								},
								{
									label: t("status"),
									value: eco_suspension.attributes.status
										? t(eco_suspension.attributes.status)
										: t("onss_connection_not_yet"),
								},
								{
									label: t("final_decision"),
									value: eco_suspension.attributes.final_decision
										? eco_suspension.attributes.final_decision
										: "-",
								},
								{
									label: t("worker"),
									value: eco_suspension.attributes.affiliate_worker,
								},
								{
									noLabel: true,
									content: (
										<PootsyButton
											text={t("fetch_eco_suspension_pdf")}
											size="small"
											disabled={
												!eco_suspension.attributes.base64_pdf_available &&
												!eco_suspension.attributes.pdf_url
											}
											onClick={() =>
												this.fetchEcoSuspensionPDF(eco_suspension)
											}
										/>
									),
								},
							]}
						/>
					))}
				{economicSuspensions && economicSuspensions.length === 0 && (
					<div>{t("empty_list")}</div>
				)}
				{showNewSuspensionModal && (
					<NewEconomicSuspensionModal closeModal={this.closeSuspensionModal} />
				)}
				{showEcoSuspModalForBatchCreation && (
					<NewEconomicSuspensionModal
						closeModal={this.toggleEcoSuspModalForBatchCreation}
						bypassWorkerSearch={true}
						customSubmit={this.submitBatchSuspensionCreation}
						mode="batch"
					/>
				)}
				{showWorkersSelectionModal && (
					<WorkerMultiSelect
						closeModal={this.toggleWorkerMultiSelectModal}
						retrieveExtraWorkerInfo={w => <div>{t(w.attributes.status)}</div>}
						submitButtonText={t("select_workers")}
						submitAction={this.selectWorkers}
					/>
				)}
			</ContentLayout>
		)
	}
}

EconomicSuspensions.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.economicSuspensionsComponent,
	economicSuspensions: state.redData.economicSuspensions,
	currAffiliate: state.redData.currentAffiliate,
	teams: state.redData.teams,
})
export default connect(mapStateToProps)(EconomicSuspensions)
