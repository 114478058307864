import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"

import * as actions from "../actions"

import Paginator from "./Paginator"
import { ContentLayout, WorkshopOrderLine } from "./FunctionalDesign"
import { PootsySelectInput } from "./FunctionalInputs"
import WorkshopOrdersFilters from "./WorkshopOrdersFilters"

class CustomerWorkshopOrders extends Component {
	componentDidMount = () => {
		let { dispatch, selectedWorkshopId, currentCustomer } = this.props
		if (currentCustomer.lastWorkshopUsed) {
			dispatch(actions.workshopStateChange({ workshopId: currentCustomer.lastWorkshopUsed }))
		} else if (!selectedWorkshopId) {
			dispatch(actions.setGlobalWorkshopId())
		}
	}
	handleChanges = changes => {
		this.props.dispatch(actions.customerWorkshopOrdersStateChange(changes))
	}
	handleWorkshopChange = async e => {
		let { dispatch } = this.props
		await dispatch(actions.workshopStateChange({ workshopId: Number(e.target.value) }))
		dispatch(actions.fetchWorkshopOrders())
	}
	handleChangePage = changes => {
		this.props.dispatch(actions.workshopOrdersFiltersStateChange({ page: changes.value }))
	}
	render() {
		let { t } = this.context
		let {
			currentAffiliate,
			selectedWorkshopId,
			match,
			history,
			location,
			currentCustomer,
			workshopOrders,
			page,
			pagination,
		} = this.props
		let optionsBoxes = (
			<WorkshopOrdersFilters
				match={match}
				location={location}
				history={history}
				filteredFilters={["customers_filter"]}
				customQueries={{
					fromDateQuery: {
						urlName: "from",
						queryName: "from_date",
						mustBeSet: true,
						defaultValue: moment()
							.subtract(1, "month")
							.format("YYYY-MM-DD"),
					},
					toDateQuery: {
						urlName: "to",
						queryName: "to_date",
						mustBeSet: true,
						defaultValue: moment().format("YYYY-MM-DD"),
					},
					customerQuery: {
						urlName: "customers_filter",
						queryName: "filter_customer_contract_ids",
						mustBeSet: true,
						defaultValue: [currentCustomer.id],
					},
				}}
			/>
		)
		return (
			<ContentLayout optionsBoxes={optionsBoxes} customClass="CustomerWorkshopOrders">
				<PootsySelectInput
					name="workshopId"
					defaultText={t("workshop")}
					selected={selectedWorkshopId}
					onChange={this.handleWorkshopChange}
					options={currentAffiliate.workshops.map(entry => ({
						label: entry.workshopName,
						value: entry.id,
					}))}
				/>
				{workshopOrders.map(entry => (
					<WorkshopOrderLine key={entry.id} workshopOrder={entry} />
				))}
				<Paginator
					name="pageQuery"
					currPage={page}
					totalPages={pagination.total_pages}
					goToPage={this.handleChangePage}
				/>
			</ContentLayout>
		)
	}
}

CustomerWorkshopOrders.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	workshopOrders: state.redData.workshopOrders,
	component: state.redComponents.customerWorkshopOrdersComponent,
	currentCustomer: state.redData.currentCustomer,
	selectedWorkshopId: state.redComponents.workshopComponent.workshopId,
	currentAffiliate: state.redData.currentAffiliate,
	page: state.redComponents.workshopOrdersFiltersComponent.page,
	pagination: state.redComponents.workshopOrdersFiltersComponent.pagination,
})
export default connect(mapStateToProps)(CustomerWorkshopOrders)
