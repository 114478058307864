import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"

import * as actions from "../actions"

import Paginator from "./Paginator"
import { WorkshopOrderLine } from "./FunctionalDesign"
import WorkshopOrdersFilters from "./WorkshopOrdersFilters"

class WorkshopDayOrders extends Component {
	handleChanges = changes => {
		this.props.dispatch(actions.workshopDayOrderStateChange(changes))
	}
	handleChangePage = changes => {
		this.props.dispatch(actions.workshopOrdersFiltersStateChange({ page: changes.value }))
	}
	render() {
		// let { t } = this.context
		let { page, pagination, match, location, history, workshopOrders } = this.props
		let optionsBoxes = (
			<WorkshopOrdersFilters
				match={match}
				location={location}
				history={history}
				hideAllFilters={true}
				customQueries={{
					executionDateQuery: {
						urlName: "x",
						queryName: "filter_execution_date",
						mustBeSet: true,
						defaultValue: moment().format("YYYY-MM-DD"),
					},
				}}
			/>
		)
		return (
			<div className="WorkshopDayOrders">
				{optionsBoxes}
				{workshopOrders.map(entry => (
					<WorkshopOrderLine key={entry.id} workshopOrder={entry} />
				))}
				<Paginator
					name="pageQuery"
					currPage={page}
					totalPages={pagination.total_pages}
					goToPage={this.handleChangePage}
				/>
			</div>
		)
	}
}

WorkshopDayOrders.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.workshopDayOrderComponent,
	workshopOrders: state.redData.workshopOrders,
	page: state.redComponents.workshopOrdersFiltersComponent.page,
	pagination: state.redComponents.workshopOrdersFiltersComponent.pagination,
})
export default connect(mapStateToProps)(WorkshopDayOrders)
