import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"

import * as actions from "../actions"
import DeleteLaundryOrderModal from "./DeleteLaundryOrderModal"

import {
	ContentLayout,
	WorkshopOrderLine,
	PootsyButton,
	LabeledDateTime,
	NotificationCard,
} from "./FunctionalDesign"
import { PootsyCheckboxInput } from "./FunctionalInputs"
import WorkshopOrdersFilters from "./WorkshopOrdersFilters"
import ModalLayout from "./ModalLayout"
import ImpactedBookingsModal from "./ImpactedBookingsModal"

class WorkshopOrdersIssues extends Component {
	handleChanges = changes => {
		this.props.dispatch(actions.workshopOrdersIssuesStateChange(changes))
	}
	handleStageForReScheduling = e => {
		let {
			component: { stagedForReScheduling },
		} = this.props
		let newArray = []
		let id = e.target.name.split("::")[0]
		if (stagedForReScheduling.includes(id)) {
			newArray = stagedForReScheduling.filter(entry => entry !== id)
		} else {
			newArray = [...stagedForReScheduling, id]
		}
		this.handleChanges({ stagedForReScheduling: newArray })
	}
	closeReschedulingConfirmationModal = () => {
		this.handleChanges({
			showReschedulingConfirmationModal: false,
			disableReSchedulingButton: false,
			errorMessage: "",
			errorObject: {},
		})
	}
	checkEarliestPossiblePickUpDate = async workshopOrderId => {
		let {
			dispatch,
			currentWorkshopId,
			workshopOrders,
			component: { earliestPossiblePickUpDates },
		} = this.props
		let existingMinPossibleDate = earliestPossiblePickUpDates.find(
			entry => entry.orderId === workshopOrderId
		)
		if (existingMinPossibleDate) {
			return
		}
		let workshopOrder = workshopOrders.find(entry => entry.id === workshopOrderId)
		let { actionType, data } = await dispatch(
			actions.fetchEarliestPickUpDate({
				workshopId: currentWorkshopId,
				duration: workshopOrder.estimatedDurationMinutes,
				specificAffiliateWorkerId: workshopOrder.forcedAffiliateWorkerId,
			})
		)
		if (actionType === actions.FETCH_EARLIEST_PICK_UP_DATE_SUCCESS) {
			this.handleChanges({
				earliestPossiblePickUpDates: [
					...earliestPossiblePickUpDates,
					{ date: data.earliest_possible_pick_up_date, orderId: workshopOrderId },
				],
			})
		}
	}
	toggleDeleteWarningModal = id => {
		this.handleChanges({
			showDeleteWarningModal: !this.props.component.showDeleteWarningModal,
			idStagedForDeletion: id,
		})
	}
	submitDeleteOrder = async () => {
		let {
			dispatch,
			workshopOrders,
			component: { idStagedForDeletion },
		} = this.props
		let { actionType, data } = await dispatch(
			actions.submitDeleteLaundryOrder(idStagedForDeletion)
		)
		if (actionType === actions.SUBMIT_DELETE_WORKSHOP_ORDER_SUCCESS) {
			dispatch(
				actions.editWorkshopOrderList(
					workshopOrders.filter(
						entry => Number(entry.id) !== Number(data.deleted_order_id)
					)
				)
			)
		}
	}
	submitPickUpDateUpdate = async (order, { pickUpDate }) => {
		let { dispatch, workshopOrders, currentWorkshopId } = this.props
		if (!pickUpDate) return
		let { actionType, data } = await dispatch(
			actions.submitUpdateWorkshopOrder(
				{
					id: order.id,
					workshop_id: currentWorkshopId,
					scheduled_pick_up_date: pickUpDate.format("YYYY-MM-DD"),
					status: "PLANNED",
				},
				{ skipDenormalize: true }
			)
		)
		if (
			actionType === actions.UPDATE_WORKSHOP_ORDER_SUCCESS &&
			data.data.attributes.display_status === "PLANNED"
		) {
			dispatch(
				actions.editWorkshopOrderList(
					workshopOrders.filter(entry => entry.id !== data.data.id)
				)
			)
		}
	}
	submitPickUpDateUpdateWithImpactedActions = () => {
		let { dispatch, pendingUpdatedWorkshopOrder } = this.props
		dispatch(
			actions.submitUpdateWorkshopOrder(pendingUpdatedWorkshopOrder, {
				skipDenormalize: true,
			})
		)
	}
	submitReSchedulingDryRun = () => {
		this.props.dispatch(actions.submitReSchedulingAttempt({ dryRun: true }))
	}
	submitReScheduling = () => {
		this.props.dispatch(actions.submitReSchedulingAttempt({ dryRun: false }))
	}
	render() {
		let { t } = this.context
		let {
			match,
			location,
			history,
			workshopOrders,
			impactedBookings,
			component: {
				idStagedForDeletion,
				showDeleteWarningModal,
				earliestPossiblePickUpDates,
				stagedForReScheduling,
				showReschedulingConfirmationModal,
				errorMessage,
				errorObject,
				disableReSchedulingButton,
				dryRunSuccess,
			},
		} = this.props
		let optionsBoxes = (
			<WorkshopOrdersFilters
				match={match}
				location={location}
				history={history}
				hideAllFilters={true}
				customQueries={{
					fromDateQuery: {
						urlName: "from",
						queryName: "from_date",
						mustBeSet: true,
						defaultValue: moment()
							.subtract(1, "month")
							.format("YYYY-MM-DD"),
					},
					toDateQuery: {
						urlName: "to",
						queryName: "to_date",
						mustBeSet: true,
						defaultValue: moment()
							.add(1, "month")
							.format("YYYY-MM-DD"),
					},
					limboOrdersQuery: {
						urlName: "limbo",
						queryName: "filter_limbo",
						mustBeSet: true,
						defaultValue: "true",
					},
				}}
			/>
		)
		let notifButtons = (
			<PootsyButton text={t("reschedule")} onClick={this.submitReSchedulingDryRun} />
		)
		let reschedulingModalButtons = (
			<PootsyButton
				key="a"
				text={t("reschedule")}
				onClick={this.submitReScheduling}
				disabled={disableReSchedulingButton}
			/>
		)
		return (
			<ContentLayout customClass="WorkshopOrdersIssues" optionsBoxes={optionsBoxes}>
				<NotificationCard
					text={t("try_rescheduling_these_orders")}
					active={stagedForReScheduling.length !== 0}
					buttons={notifButtons}
				/>
				{showDeleteWarningModal && (
					<DeleteLaundryOrderModal
						submit={this.submitDeleteOrder}
						id={idStagedForDeletion}
						closeModal={() => this.toggleDeleteWarningModal(0)}
					/>
				)}
				{showReschedulingConfirmationModal && (
					<ModalLayout
						title={t("rescheduling_attempt_report_modal")}
						closeModal={this.closeReschedulingConfirmationModal}
						buttons={reschedulingModalButtons}
						customClass="rescheduling-report-modal"
					>
						{errorObject &&
							Object.entries(errorObject).map(([error_string, params]) =>
								t(error_string, params)
							)}
						{!errorObject && errorMessage && errorMessage}
						{dryRunSuccess && t("rescheduling_dry_run_success_message")}
					</ModalLayout>
				)}
				{impactedBookings.showImpactedBookingsModal && (
					<ImpactedBookingsModal
						onSubmit={this.submitPickUpDateUpdateWithImpactedActions}
						triggerActionExpected={actions.UPDATE_WORKSHOP_ORDER_FAILURE}
					/>
				)}
				{workshopOrders.map(entry => {
					let earliestPossiblePickUpDate = earliestPossiblePickUpDates.find(
						mppud => mppud.orderId === entry.id
					)
					return (
						<WorkshopOrderLine key={entry.id} workshopOrder={entry} noLink={true}>
							<div className="column execution-date">
								<div className="label">{t("execution_date")}</div>
								<div className="value">
									{t(entry.executionDate.format("DD/MM"))}
								</div>
							</div>
							<LabeledDateTime
								label={t("pick_up_date")}
								showTimePicker={false}
								datePickerProps={{
									name: "pickUpDate",
									value: "",
									calendarStyle: { top: "100%", right: "0" },
									disableDay: day =>
										day.isBefore(moment(), "day") ||
										(earliestPossiblePickUpDate &&
											day.isBefore(earliestPossiblePickUpDate.date)),
									onChange: change => this.submitPickUpDateUpdate(entry, change),
									onCalendarOpen: () => {
										this.checkEarliestPossiblePickUpDate(entry.id)
									},
								}}
							/>
							<PootsyButton
								text={t("delete")}
								onClick={() => this.toggleDeleteWarningModal(entry.id)}
							/>
							<PootsyCheckboxInput
								name={`${entry.id}::stage_order`}
								label={t("stage_order_for_re_scheduling")}
								checked={stagedForReScheduling.includes(entry.id)}
								disabled={entry.pickUpDate.isBefore(moment())}
								onChange={this.handleStageForReScheduling}
							/>
						</WorkshopOrderLine>
					)
				})}
			</ContentLayout>
		)
	}
}

WorkshopOrdersIssues.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.workshopOrdersIssuesComponent,
	workshopOrders: state.redData.workshopOrders,
	currentAffiliate: state.redData.currentAffiliate,
	currentWorkshopId: state.redComponents.workshopComponent.workshopId,
	impactedBookings: state.redComponents.impactedBookingsModal,
	pendingUpdatedWorkshopOrder:
		state.redComponents.workshopOrderDetailsComponent.pendingUpdatedWorkshopOrder,
})
export default connect(mapStateToProps)(WorkshopOrdersIssues)
