import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import api from "../middleware/api"
import { networkResponsesActions } from "../middleware/networkResponsesActions"
import rootReducer from "../reducers"

const configureStore = preloadedState =>
	createStore(
		rootReducer,
		preloadedState,
		compose(applyMiddleware(api, thunk, networkResponsesActions))
	)

export default configureStore
