import moment from "moment"
import { normalizeDocumentTemplate } from "../normalizers/Normalizers"
import {
	simpleFetch,
	resetImpactedBookings,
	denormalizeImpactedBookingActions,
	fetchWorker,
	retrieveTemplateVariables,
	RETRIEVE_TEMPLATE_VARIABLES_SUCCESS,
	workerContractComponentStateChange,
	toggleTwoWeeksSchedule,
} from "./index"
import * as actions from "../actions"

import { workerScheduleValidator } from "../helpers/workerScheduleValidator"
import { socialSecretariesFields } from "../constants/socialSecretariesFields"

export const CONTRACT_V2_METACHANGE = "CONTRACT_V2_METACHANGE"
export const contractV2MetaChange = changes => ({ type: CONTRACT_V2_METACHANGE, changes })

export const WORKER_TEMPLATES_STATE_CHANGE = "WORKER_TEMPLATES_STATE_CHANGE"
export const workerTemplatesStateChange = changes => ({
	type: WORKER_TEMPLATES_STATE_CHANGE,
	changes,
})

export const CONTRACT_V2_UI_CHANGE = "CONTRACT_V2_UI_CHANGE"
export const contractV2UIChange = changes => ({ type: CONTRACT_V2_UI_CHANGE, changes })

const adjustTimeRangesStartDatesToManagerSP = (timeRanges, schedulePeriods, associationArray) => {
	return timeRanges.map(entry => {
		let weekStart = moment(
			schedulePeriods.find(sp => sp[associationArray].includes(entry.id)).startDate
		)
		return {
			...entry,
			startDate: moment(weekStart)
				.startOf("isoweek")
				.isoWeekday(entry.startDate.isoWeekday()),
		}
	})
}

export const getPreviousWorkerContract = (currentContract, workerContracts) => {
	return workerContracts.reduce((acc, entry) => {
		if (entry.id === currentContract.id) {
			return acc
		}
		if (entry.endDate && entry.endDate.isBefore(currentContract.startDate)) {
			if (!acc) {
				return entry
			}
			let accDiff = currentContract.startDate.diff(acc.startDate)
			let entryDiff = currentContract.startDate.diff(entry.startDate)
			if (accDiff > entryDiff) {
				return entry
			}
		}
		return acc
	}, undefined)
}

export const getLastWorkerContract = workerContracts => {
	return workerContracts.reduce((acc, entry) => {
		if (entry.id !== "new-contract" && (!acc || entry.startDate.isAfter(acc.startDate))) {
			acc = entry
		}
		return acc
	}, undefined)
}

export const EDIT_WORKER_CONTRACT_V2 = "EDIT_WORKER_CONTRACT_V2"
export const editWorkerContractV2 = (changes, contractID) => ({
	type: EDIT_WORKER_CONTRACT_V2,
	changes,
	contractID,
})
export const handleEditWorkerContract = changes => async (dispatch, getState) => {
	let {
		redComponents: { workerContractComponent },
		redData: { workerContractsOriginalData, currentAffiliate },
	} = getState()
	let currentID = workerContractComponent.selectedContractID

	if (
		changes.contractVariability &&
		["variable", "variable_variable"].includes(changes.contractVariability)
	) {
		changes.theoreticalHours = []
		if (changes.contractVariability === "variable_variable") {
			let contract = getState().redData.workerContracts.find(entry => entry.id === currentID)
			if (contract.schedulePeriods.length === 2) {
				// can only be a one week schedule
				await dispatch(toggleTwoWeeksSchedule())
				// refetching contract to get the updated schedulePeriods
				contract = getState().redData.workerContracts.find(entry => entry.id === currentID)
			}
			changes = {
				...changes,
				schedulePeriods: contract.schedulePeriods.map(entry => ({
					...entry,
					maxWorkHours: 0,
				})),
			}
		}
	}
	let old_worker_contract = {}
	workerContractsOriginalData.forEach(wc => {
		if (currentID === wc.id) {
			old_worker_contract = wc
		}
	})
	// SHOW DIMONA MODAL IF ADDED END DATE
	if (
		changes.endDate &&
		changes.endDate.isValid() &&
		old_worker_contract.endDate &&
		!old_worker_contract.endDate.isValid() &&
		currentAffiliate.dimonaSettings.dimonaAutoSyncOnEndContract
	) {
		let {
			redData: { currentWorker },
		} = getState()
		await dispatch(
			actions.newDimonaStateChange({
				dimonaType: "OUT",
				contractOut: true,
				dateOut: moment(changes.endDate),
				workerType: currentAffiliate.dimonaSettings.dimonaWorkerType,
				chosenWorker: {
					name: currentWorker.displayName,
					sodexo: currentWorker.sodexoNumber,
					affiliateWorkerId: currentWorker.id,
				},
			})
		)
		dispatch(workerContractComponentStateChange({ showDimonaModal: true }))
	}

	if (changes.startDate) {
		// this can only happen for a new contract, the start date isn't changeable on saved contracts
		// the contract's start date influences the start dates of
		// schedulePeriods, availabilities, and theoretical hours
		// they all need to be updated accordingly
		let contract = getState().redData.workerContracts.find(entry => entry.id === currentID)
		let newSchedulePeriods = contract.schedulePeriods.map((entry, index) => ({
			...entry,
			startDate: moment(changes.startDate)
				.startOf("isoweek")
				.add(index * 7, "days"),
		}))

		if (contract.oldContractEndDate && contract.oldContractEndDate.isAfter(changes.startDate)) {
			changes.oldContractEndDate = moment(changes.startDate).subtract(1, "day")
		}
		changes = {
			...changes,
			schedulePeriods: newSchedulePeriods,
			availabilities: adjustTimeRangesStartDatesToManagerSP(
				contract.availabilities,
				newSchedulePeriods,
				"managedAvails"
			),
			theoreticalHours: adjustTimeRangesStartDatesToManagerSP(
				contract.theoreticalHours,
				newSchedulePeriods,
				"managedTheoHours"
			),
			otherActivities: adjustTimeRangesStartDatesToManagerSP(
				contract.otherActivities,
				newSchedulePeriods,
				"managedOtherActivities"
			),
		}
	}

	dispatch(editWorkerContractV2(changes, currentID))

	// refetching contract here to get all the new changes
	let contract = getState().redData.workerContracts.find(entry => entry.id === currentID)
	let { errors, schedErrors } = workerScheduleValidator(contract)
	dispatch(contractV2MetaChange({ errors, schedErrors }))

	// from here comparing to old values, which is useless for a brand new contract
	if (currentID === "new-contract") {
		return new Promise((resolve, reject) => resolve())
	}

	let newEditedKeys = {
		[currentID]: workerContractComponent.editedKeys[currentID]
			? [...workerContractComponent.editedKeys[currentID]]
			: [],
	}

	const originalContract = workerContractsOriginalData.find(entry => entry.id === currentID)
	let changedKeys = Object.keys(changes)
	newEditedKeys[currentID] = changedKeys.reduce((acc, entry) => {
		if (newEditedKeys[currentID].indexOf(entry) < 0) {
			acc.push(entry)
		} else if (JSON.stringify(originalContract[entry]) === JSON.stringify(changes[entry])) {
			acc = acc.filter(key => key !== entry)
		}
		return acc
	}, newEditedKeys[currentID])
	dispatch(
		contractV2MetaChange({
			editedKeys: { ...workerContractComponent.editedKeys, ...newEditedKeys },
		})
	)

	return new Promise((resolve, reject) => resolve())
}

export const swapSchedulePeriods = () => (dispatch, getState) => {
	let {
		redComponents: { workerContractComponent },
		redData: { workerContracts },
	} = getState()
	let contract = workerContracts.find(
		entry => entry.id === workerContractComponent.selectedContractID
	)
	let newSchedulePeriods = contract.schedulePeriods.map((entry, index) => {
		let otherSP = contract.schedulePeriods[1 - index]
		return { ...otherSP, startDate: moment(entry.startDate) }
	})
	let changes = {
		availabilities: adjustTimeRangesStartDatesToManagerSP(
			contract.availabilities,
			newSchedulePeriods,
			"managedAvails"
		),
		theoreticalHours: adjustTimeRangesStartDatesToManagerSP(
			contract.theoreticalHours,
			newSchedulePeriods,
			"managedTheoHours"
		),
		otherActivities: adjustTimeRangesStartDatesToManagerSP(
			contract.otherActivities,
			newSchedulePeriods,
			"managedOtherActivities"
		),
		schedulePeriods: newSchedulePeriods,
	}
	dispatch(handleEditWorkerContract(changes))
}

export const handleSecSocSpecificFieldChange = changes => (dispatch, getState) => {
	let {
		redComponents: { workerContractComponent },
		redData: { workerContracts },
	} = getState()
	let contract = workerContracts.find(
		entry => entry.id === workerContractComponent.selectedContractID
	)
	let contractChanges = { secSocSpecificData: { ...contract.secSocSpecificData, ...changes } }
	let changedKey = Object.keys(changes)[0]
	if (!changes[changedKey]) {
		// if the change empties the field, nullify the value
		contractChanges.secSocSpecificData[changedKey] = null
	}
	dispatch(handleEditWorkerContract(contractChanges))
}

export const REMOVE_NEW_CONTRACT = "REMOVE_NEW_CONTRACT"
export const removeNewContract = () => ({ type: REMOVE_NEW_CONTRACT })

export const processRemoveNewContract = () => (dispatch, getState) => {
	let {
		redData: { workerContracts },
	} = getState()
	let activeContract = workerContracts.find(entry => entry.activeContract)
	dispatch(
		contractV2MetaChange({
			selectedContractID: activeContract ? activeContract.id : "",
			selectedSchedulePeriod: 0,
		})
	)
	dispatch(removeNewContract())
}

export const ADD_NEW_CONTRACT = "ADD_NEW_CONTRACT"
export const addNewContract = emptyContract => ({ type: ADD_NEW_CONTRACT, emptyContract })

export const emptyContract = (contractStartDate = moment(), oldContractEndDate) => {
	if (!oldContractEndDate || !oldContractEndDate.isValid()) {
		oldContractEndDate = moment(contractStartDate).subtract(1, "day")
	}
	return {
		id: "new-contract",
		startDate: contractStartDate,
		endDate: moment.invalid(),
		signingDate: moment(),
		hoursPerWeek: "",
		salaryType: "",
		comment: "",
		activeContract: false,
		oldContractEndDate: oldContractEndDate,
		coveredAreas: [],
		refPeriodsStartDate: moment().startOf("isoweek"),
		schedulePeriods: [
			{
				id: Math.floor(Math.random() * 1000000000000),
				startDate: moment(contractStartDate).startOf("isoweek"),
				hasMaxHoursPerDay: false,
				dayPeriod: 7,
				maxWorkHours: 38,
				maxExtraWorkHours: 0,
				daysMaxHours: [0, 0, 0, 0, 0, 0, 0],
				managedAvails: [],
				managedTheoHours: [],
				managedOtherActivities: [],
			},
		],
		theoreticalHours: [],
		availabilities: [],
		otherActivities: [],
		unsavedBookings: [],
		secSocSpecificData: {},
		documents: [],
		newWorkerContractIsAddendum: false,
		operatingHeadquarter: {},
	}
}

export const processAddNewContract = () => (dispatch, getState) => {
	let {
		redData: { workerContracts, currentAffiliate, currentWorker },
	} = getState()
	if (workerContracts.some(entry => entry.id === "new-contract")) {
		dispatch(contractV2MetaChange({ selectedContractID: "new-contract" }))
		return
	}
	let lastContract = getLastWorkerContract(workerContracts)
	let newContractStartDate = moment() // default to today
	if (lastContract) {
		if (lastContract.startDate.isAfter(newContractStartDate)) {
			newContractStartDate = moment(lastContract.startDate).add(2, "day")
		}
		if (lastContract.endDate && lastContract.endDate.isAfter(newContractStartDate)) {
			newContractStartDate = moment(lastContract.endDate).add(2, "day")
		}
	}
	let ec
	if (lastContract && lastContract.endDate && lastContract.endDate.isValid()) {
		ec = emptyContract(newContractStartDate, lastContract.endDate)
	} else {
		ec = emptyContract(newContractStartDate)
	}

	let secSocInputs = socialSecretariesFields[currentAffiliate.secSocName] || []
	secSocInputs.forEach(category => {
		category.inputs.forEach(input => {
			if (input.default) {
				ec.secSocSpecificData[input.label] = input.default(
					currentWorker,
					currentAffiliate.secSocPrefillWorkerContract
				)
			}
		})
	})

	dispatch(addNewContract(ec))
	dispatch(contractV2MetaChange({ selectedContractID: ec.id }))
	dispatch(contractV2UIChange({ selectedSchedulePeriod: 0 }))
}

export const copyLastContractDataToNewContract = () => (dispatch, getState) => {
	let {
		redData: { workerContracts },
	} = getState()
	let lastContract = getLastWorkerContract(workerContracts)
	if (!lastContract) {
		return
	}
	let {
		contractType,
		contractPlanningType,
		workerCategory,
		contractRegimeType,
		grossSalary,
		payPeriod,
		hoursPerWeek,
		salaryType,
		comment,
		vehicleType,
		fixedTerm,
		contractVariability,
		coveredAreas,
		secSocSpecificData,
		operatingHeadquarter,
		sponsor,
	} = lastContract

	let changes = {
		contractType,
		contractPlanningType,
		workerCategory,
		contractRegimeType,
		grossSalary,
		payPeriod,
		hoursPerWeek,
		salaryType,
		comment,
		vehicleType,
		fixedTerm,
		contractVariability,
		coveredAreas,
		secSocSpecificData,
		operatingHeadquarter,
		sponsor,
	}
	dispatch(editWorkerContractV2(changes, "new-contract"))
}

export const copyLastContractTheoreticalSchedulesToNewContract = () => (dispatch, getState) => {
	let {
		redData: { workerContracts },
	} = getState()
	let lastContract = getLastWorkerContract(workerContracts)
	if (!lastContract) {
		return
	}
	let newContract = workerContracts.find(entry => entry.id === "new-contract")

	let { schedulePeriods, availabilities, theoreticalHours } = lastContract
	let newSchedulePeriods = schedulePeriods.map(entry => {
		let isFirstSchedule =
			entry.startDate.isSame(lastContract.startDate, "day") || // this is for legacy reasons (the other condition should be sufficient)
			entry.startDate.isSame(moment(lastContract.startDate).startOf("isoweek"), "day")
		return {
			...entry,
			startDate: isFirstSchedule
				? moment(newContract.startDate).startOf("isoweek")
				: moment(newContract.startDate)
						.startOf("isoweek")
						.add(7, "days"),
			daysMaxHours: [...entry.daysMaxHours],
			managedAvails: [...entry.managedAvails],
			managedTheoHours: [...entry.managedTheoHours],
			managedOtherActivities: [...entry.managedOtherActivities],
		}
	})
	let changes = {
		schedulePeriods: newSchedulePeriods.sort((a, b) =>
			b.startDate.isBefore(a.startDate) ? 1 : -1
		),
		theoreticalHours: theoreticalHours.map(entry => {
			let managerSP = newSchedulePeriods.find(sp => sp.managedTheoHours.includes(entry.id))
			return {
				...entry,
				startDate: moment(managerSP.startDate).isoWeekday(entry.startDate.isoWeekday()), // should take the next weekday given that managerSP should ALWAYS be a monday
				start: moment(entry.start),
				end: moment(entry.end),
			}
		}),
		availabilities: availabilities.map(entry => {
			let managerSP = newSchedulePeriods.find(sp => sp.managedAvails.includes(entry.id))
			return {
				...entry,
				startDate: moment(managerSP.startDate).isoWeekday(entry.startDate.isoWeekday()), // should take the next weekday given that managerSP should ALWAYS be a monday
				start: moment(entry.start),
				end: moment(entry.end),
			}
		}),
	}
	if (["variable_variable", "variable"].includes(newContract.contractVariability)) {
		// Removing THs for contract that do not have them
		changes.theoreticalHours = []
		changes.schedulePeriods = changes.schedulePeriods.map(entry => {
			return { ...entry, managedTheoHours: [] }
		})
	}
	dispatch(editWorkerContractV2(changes, "new-contract"))
}

export const resetCurrentContractData = () => (dispatch, getState) => {
	let {
		redComponents: { workerContractComponent },
		redData: { workerContractsOriginalData },
	} = getState()
	let currentID = workerContractComponent.selectedContractID
	if (currentID === "new-contract") {
		return
	}
	const originalContract = workerContractsOriginalData.find(entry => entry.id === currentID)
	dispatch(editWorkerContractV2(originalContract, currentID))
	let newEditedKeys = { [currentID]: [] }
	let { errors, schedErrors } = workerScheduleValidator(originalContract)
	dispatch(
		contractV2MetaChange({
			errors,
			schedErrors,
			editedKeys: { ...workerContractComponent.editedKeys, ...newEditedKeys },
		})
	)
	dispatch(resetImpactedBookings())
}

const converDurationToMin = duration => {
	let formatedMaxHours = duration.toString()
	let separator = formatedMaxHours.includes("h") ? "h" : "u"
	return formatedMaxHours.includes(separator)
		? parseFloat(formatedMaxHours.split(separator)[0] * 60) +
				parseFloat(formatedMaxHours.split(separator)[1])
		: formatedMaxHours * 60
}

const denormalizeContractForSubmission = (workerID, contract, currentAffiliate) => {
	let addIds = contract.id !== "new-contract"
	let data = {
		affiliate_worker_id: workerID,
		social_secretary_specific_data: Object.keys(contract.secSocSpecificData).reduce(
			(acc, key) => {
				let value = contract.secSocSpecificData[key]
				if ((key === "gross_salary" || key === "work_interruption_percentage") && value) {
					value = value.toString().replace(",", ".")
				}
				acc[key] = value
				if (moment.isMoment(value)) {
					acc[key] = value.format("YYYY-MM-DD")
				}
				return acc
			},
			{}
		),
		worker_contract: {
			start_date: contract.startDate.format("YYYY-MM-DD"),
			end_date: null,
			signing_date: contract.signingDate.format(),
			work_hours_per_week: converDurationToMin(contract.hoursPerWeek) / 60,
			salary_type: contract.salaryType,
			comment: contract.comment,
			fixed_term: contract.fixedTerm,
			vehicle_type: contract.vehicleType,
			contract_variability: contract.contractVariability,
			area_references: contract.coveredAreas,
			operating_headquarter_id: contract.operatingHeadquarter.id,
			reference_period: contract.refPeriodWeekCount,
			varvar_ref_period_avg_minutes_per_week: contract.avgHoursPerWeekForRefPeriod * 60,
			varvar_min_work_minutes_per_week: contract.varvarMinWorkHoursPerWeek * 60,
			varvar_max_work_minutes_per_week: contract.varvarMaxWorkHoursPerWeek * 60,
			sponsor: contract.sponsor,
			schedule_periods: contract.schedulePeriods.map(entry => ({
				id: addIds ? entry.id : undefined,
				start_date: entry.startDate.format("YYYY-MM-DD"),
				has_max_mins_per_day: entry.hasMaxHoursPerDay,
				day_period: entry.dayPeriod,
				max_work_mins: converDurationToMin(entry.maxWorkHours),
				max_extra_work_mins: converDurationToMin(entry.maxExtraWorkHours),
				...entry.daysMaxHours.reduce((acc, entry, index) => {
					// translating to sunday
					let day = index === 6 ? 0 : index + 1
					acc[`day_${day}_max_mins`] = converDurationToMin(entry) || 0
					return acc
				}, {}),
			})),
			availabilities: contract.availabilities.map(entry => ({
				id: addIds ? entry.id : undefined,
				day_period: contract.schedulePeriods[0].dayPeriod,
				start_date: entry.startDate.format("YYYY-MM-DD"),
				start_time: entry.start.format("HH:mm"),
				end_time: entry.end.format("HH:mm"),
				is_extra_availability: entry.isExtraAvailability,
			})),
			theoretical_hours: contract.theoreticalHours.map(entry => ({
				id: addIds ? entry.id : undefined,
				day_period: contract.schedulePeriods[0].dayPeriod,
				start_date: entry.startDate.format("YYYY-MM-DD"),
				start_time: entry.start.format("HH:mm"),
				end_time: entry.end.format("HH:mm"),
			})),
			direct_bookings: contract.unsavedBookings.map(entry => ({
				force: true,
				delivery_date: entry.startDate.format(),
				recurrence: true,
				recurrence_delay: entry.dayPeriod === 7 ? "1" : "2",
				start_time: entry.start.format("HH:mm"),
				end_time: entry.end.format("HH:mm"),
				day_period: entry.dayPeriod,
				residence_id: entry.residenceID,
				zip_code: entry.zip,
				voucher_type: entry.voucherType,
				customer_contract_id: entry.chosenCustomer.id,
			})),
			other_activities: contract.otherActivities.map(entry => ({
				id: addIds ? entry.id : undefined,
				start_date: entry.startDate.format("YYYY-MM-DD"),
				start_time: entry.start.format("HH:mm"),
				end_time: entry.end.format("HH:mm"),
				day_period: entry.dayPeriod,
				affiliate_note: entry.note,
				activity_category: entry.activityCategory,
				activity_code: entry.activityCode,
				contractual: true,
				is_workshop_hour: entry.isWorkshopHour,
				workshop_id: entry.workshopId,
			})),
		},
	}
	if (
		contract.contractVariability === "variable_variable" &&
		!contract.newWorkerContractIsAddendum &&
		!contract.addendum
	) {
		data.worker_contract.ref_periods_start_date = contract.refPeriodsStartDate.format(
			"YYYY-MM-DD"
		)
	}

	return data
}

const checkRequiredFields = contract => {
	if (contract.contractVariability === "variable_variable") {
		return {}
	}
	let toRequire = ["hoursPerWeek"].reduce((acc, entry) => {
		if (!contract[entry]) {
			acc[entry] = true
		}
		return acc
	}, {})
	if (contract.coveredAreas.length === 0) {
		toRequire.coveredAreas = true
	}
	return toRequire
}

export const FETCH_ACTIVE_CONTRACTS_IDS = "FETCH_ACTIVE_CONTRACTS_IDS"
export const FETCH_ACTIVE_CONTRACTS_IDS_REQUEST = "FETCH_ACTIVE_CONTRACTS_IDS_REQUEST"
export const FETCH_ACTIVE_CONTRACTS_IDS_SUCCESS = "FETCH_ACTIVE_CONTRACTS_IDS_SUCCESS"
export const FETCH_ACTIVE_CONTRACTS_IDS_FAILURE = "FETCH_ACTIVE_CONTRACTS_IDS_FAILURE"
export const fetchActiveContractsIdsForMonth = date => async (dispatch, getState) => {
	let forMonth = moment(date)
		.startOf("month")
		.format("YYYY-MM-DD")
	let url = "/front/affiliates/worker_contracts/0/active_workers?for_month=" + forMonth
	let init = { method: "GET" }
	return dispatch(simpleFetch(url, init, FETCH_ACTIVE_CONTRACTS_IDS))
}

export const SUBMIT_NEW_WORKER_CONTRACT = "SUBMIT_NEW_WORKER_CONTRACT"
export const SUBMIT_NEW_WORKER_CONTRACT_REQUEST = "SUBMIT_NEW_WORKER_CONTRACT_REQUEST"
export const SUBMIT_NEW_WORKER_CONTRACT_SUCCESS = "SUBMIT_NEW_WORKER_CONTRACT_SUCCESS"
export const SUBMIT_NEW_WORKER_CONTRACT_FAILURE = "SUBMIT_NEW_WORKER_CONTRACT_FAILURE"
export const submitNewContract = () => async (dispatch, getState) => {
	let {
		redData: { workerContracts, currentWorker, currentAffiliate },
	} = getState()
	let newContract = workerContracts.find(entry => entry.id === "new-contract")
	let fieldsToRequire = checkRequiredFields(newContract)
	if (Object.keys(fieldsToRequire).length > 0) {
		dispatch(contractV2UIChange({ showRequired: fieldsToRequire }))
		return
	}
	let data = denormalizeContractForSubmission(currentWorker.id, newContract, currentAffiliate)
	if (workerContracts.length > 1) {
		// this is included only if we have another contract to terminate
		data.old_contract_end_date = newContract.newWorkerContractIsAddendum
			? moment(newContract.startDate)
					.subtract(1, "day")
					.format()
			: newContract.oldContractEndDate.format()
		data.impacted_bookings_action = newContract.oldContractBookingsTransfer
		data.worker_contract.addendum = newContract.newWorkerContractIsAddendum
		let activeContract = workerContracts.find(entry => entry.activeContract)
		if (newContract.newWorkerContractIsAddendum && activeContract) {
			data.worker_contract.parent_id = activeContract.id
		}
	}
	if (newContract.endDate && newContract.endDate.isValid()) {
		data.worker_contract.end_date = newContract.endDate.format("YYYY-MM-DD")
	}
	let init = { method: "POST", body: JSON.stringify(data) }
	let url = "/front/affiliates/worker_contracts"
	let { actionType, data: creationResponseData } = await dispatch(
		simpleFetch(url, init, SUBMIT_NEW_WORKER_CONTRACT)
	)
	if (actionType === SUBMIT_NEW_WORKER_CONTRACT_SUCCESS) {
		await dispatch(fetchWorker(currentWorker.id))
		if (currentAffiliate.dimonaAddOn && !newContract.newWorkerContractIsAddendum) {
			let {
				redData: { currentWorker, workerContracts },
				redComponents: { workerContractComponent },
			} = getState()
			let contract = workerContracts.find(
				entry => entry.id === workerContractComponent.selectedContractID
			)

			await dispatch(
				actions.newDimonaStateChange({
					dimonaType: "IN",
					contractIn: true,
					dateIn: moment(contract.startDate),
					workerType: currentAffiliate.dimonaSettings.dimonaWorkerType,
					chosenWorker: {
						name: currentWorker.displayName,
						sodexo: currentWorker.sodexoNumber,
						affiliateWorkerId: currentWorker.id,
					},
					afterContractCreation: creationResponseData.meta.created_worker_contract_id,
				})
			)

			dispatch(workerContractComponentStateChange({ showDimonaModal: true }))
		}
	}
}

export const SUBMIT_UPDATE_WORKER_CONTRACT = "SUBMIT_UPDATE_WORKER_CONTRACT"
export const SUBMIT_UPDATE_WORKER_CONTRACT_REQUEST = "SUBMIT_UPDATE_WORKER_CONTRACT_REQUEST"
export const SUBMIT_UPDATE_WORKER_CONTRACT_SUCCESS = "SUBMIT_UPDATE_WORKER_CONTRACT_SUCCESS"
export const SUBMIT_UPDATE_WORKER_CONTRACT_FAILURE = "SUBMIT_UPDATE_WORKER_CONTRACT_FAILURE"
export const submitUpdateCurrentContract = () => async (dispatch, getState) => {
	let {
		redData: { workerContracts, currentWorker, currentAffiliate },
		redComponents: { workerContractComponent },
	} = getState()
	let contract = workerContracts.find(
		entry => entry.id === workerContractComponent.selectedContractID
	)

	let fieldsToRequire = checkRequiredFields(contract)
	if (Object.keys(fieldsToRequire).length > 0) {
		dispatch(contractV2UIChange({ showRequired: fieldsToRequire }))
		return
	}
	if (!contract.changesDueDate) {
		dispatch(contractV2UIChange({ showRequired: { changesDueDate: true } }))
		return
	}

	let newContractStart = moment(contract.changesDueDate)

	// Updating a contract will simply create a new one
	// The new start date will be computed from the `changesDueDate` so all timeranges must be updated accordingly
	// The following adjustments assume the previous dates are all valid, existing mistakes will be propagated
	contract.schedulePeriods = contract.schedulePeriods.map((entry, index) => ({
		...entry,
		startDate: moment(newContractStart)
			.startOf("isoweek")
			.add(index * 7, "days"),
	}))
	contract.availabilities = contract.availabilities.map(entry => {
		let weekStart = moment(
			contract.schedulePeriods.find(sp => sp.managedAvails.includes(entry.id)).startDate
		)
		return {
			...entry,
			startDate: moment(weekStart)
				.startOf("isoweek")
				.isoWeekday(entry.startDate.isoWeekday()),
		}
	})
	contract.theoreticalHours = contract.theoreticalHours.map(entry => {
		let weekStart = moment(
			contract.schedulePeriods.find(sp => sp.managedTheoHours.includes(entry.id)).startDate
		)
		return {
			...entry,
			startDate: moment(weekStart)
				.startOf("isoweek")
				.isoWeekday(entry.startDate.isoWeekday()),
		}
	})
	contract.otherActivities = contract.otherActivities.map(entry => {
		let weekStart = moment(
			contract.schedulePeriods.find(sp => sp.managedOtherActivities.includes(entry.id))
				.startDate
		)
		return {
			...entry,
			startDate: moment(weekStart)
				.startOf("isoweek")
				.isoWeekday(entry.startDate.isoWeekday()),
		}
	})

	let data = denormalizeContractForSubmission(currentWorker.id, contract, currentAffiliate)
	data.ref_periods_start_date = undefined
	data.old_contract_end_date = moment(newContractStart)
		.subtract(1, "day")
		.format()
	data.worker_contract.addendum = true
	data.worker_contract.parent_id = contract.id
	data.worker_contract.start_date = moment(newContractStart).format()
	data.impacted_bookings_action = "transfer_to_new_contract" // always transfer old bookings to new contract
	if (contract.endDate && contract.endDate.isValid()) {
		data.worker_contract.end_date = contract.endDate.format("YYYY-MM-DD")
	}
	let url = "/front/affiliates/worker_contracts/"
	let init = { method: "POST", body: JSON.stringify(data) }
	let { actionType } = await dispatch(
		simpleFetch(url, init, SUBMIT_UPDATE_WORKER_CONTRACT, { previousContractID: contract.id })
	)
	if (actionType === SUBMIT_UPDATE_WORKER_CONTRACT_SUCCESS) {
		await dispatch(fetchWorker(currentWorker.id))
	}
}

export const SUBMIT_FORCE_UPDATE_WORKER_CONTRACT = "SUBMIT_FORCE_UPDATE_WORKER_CONTRACT"
export const SUBMIT_FORCE_UPDATE_WORKER_CONTRACT_REQUEST =
	"SUBMIT_FORCE_UPDATE_WORKER_CONTRACT_REQUEST"
export const SUBMIT_FORCE_UPDATE_WORKER_CONTRACT_SUCCESS =
	"SUBMIT_FORCE_UPDATE_WORKER_CONTRACT_SUCCESS"
export const SUBMIT_FORCE_UPDATE_WORKER_CONTRACT_FAILURE =
	"SUBMIT_FORCE_UPDATE_WORKER_CONTRACT_FAILURE"
export const submitForceUpdateCurrentContract = () => async (dispatch, getState) => {
	let {
		redData: { workerContracts, currentWorker, currentAffiliate, impactedBookings },
		redComponents: {
			workerContractComponent,
			impactedBookingsModal: { forceUnlink },
		},
	} = getState()
	let contract = workerContracts.find(
		entry => entry.id === workerContractComponent.selectedContractID
	)

	let data = denormalizeContractForSubmission(currentWorker.id, contract, currentAffiliate)
	let url = "/front/affiliates/worker_contracts/" + contract.id

	data.worker_contract.parent_id = contract.parentID
	if (contract.endDate && contract.endDate.isValid()) {
		data.worker_contract.end_date = contract.endDate.format("YYYY-MM-DD")
		data.impacted_bookings_actions = denormalizeImpactedBookingActions(impactedBookings)
		data.force_unlink = forceUnlink
	}
	let init = { method: "PUT", body: JSON.stringify(data) }
	let { actionType } = await dispatch(simpleFetch(url, init, SUBMIT_FORCE_UPDATE_WORKER_CONTRACT))
	if (actionType === SUBMIT_FORCE_UPDATE_WORKER_CONTRACT_SUCCESS) {
		dispatch(fetchWorker(currentWorker.id))
	}
}

export const DELETE_AFFILIATE_WORKER_REQUEST = "DELETE_AFFILIATE_WORKER_REQUEST"
export const DELETE_AFFILIATE_WORKER_SUCCESS = "DELETE_AFFILIATE_WORKER_SUCCESS"
export const DELETE_AFFILIATE_WORKER_FAILURE = "DELETE_AFFILIATE_WORKER_FAILURE"
export const deleteAffiliateWorker = affiliateWorkeID => (dispatch, getState) => {
	let init = { method: "DELETE" }
	let url = `/front/affiliates/affiliate_workers/${affiliateWorkeID}`
	dispatch(simpleFetch(url, init, "DELETE_AFFILIATE_WORKER", { deletedID: affiliateWorkeID }))
}

export const requestTemplatePDF = (templateID, lang) => (dispatch, getState) => {
	let {
		redComponents: { workerContractComponent },
	} = getState()
	let url = "/front/affiliates/document_templates/" + templateID + "/render_template/"
	let init = {
		method: "POST",
		body: JSON.stringify({
			worker_contract_id: workerContractComponent.selectedContractID,
			lang,
		}),
	}
	let requestMeta = { fileRequest: true, fileKeyName: "templatePDF" }
	dispatch(simpleFetch(url, init, "SUBMIT_RENDER_TEMPLATE", requestMeta))
}

export const processWorkerTemplateStateChange = changes => async (dispatch, getState) => {
	dispatch(workerTemplatesStateChange(changes))
	let {
		redComponents: {
			workerTemplatesComponent: { selectedTemplate, selectedLang },
			workerContractComponent: { selectedContractID },
		},
	} = getState()
	if (!selectedTemplate || !selectedLang) {
		return
	}
	let { data, actionType } = await dispatch(
		retrieveTemplateVariables({
			templateID: Number(selectedTemplate),
			lang: selectedLang,
			customParams: { worker_contract_id: selectedContractID },
		})
	)

	if (actionType === RETRIEVE_TEMPLATE_VARIABLES_SUCCESS) {
		dispatch(
			workerTemplatesStateChange({
				renderableTemplate: normalizeDocumentTemplate(data.data),
			})
		)
	}
}

export const DELETE_WORKER_CONTRACT = "DELETE_WORKER_CONTRACT"
export const DELETE_WORKER_CONTRACT_REQUEST = "DELETE_WORKER_CONTRACT_REQUEST"
export const DELETE_WORKER_CONTRACT_SUCCESS = "DELETE_WORKER_CONTRACT_SUCCESS"
export const DELETE_WORKER_CONTRACT_FAILURE = "DELETE_WORKER_CONTRACT_FAILURE"
export const deleteWorkerContract = () => async (dispatch, getState) => {
	let {
		redData: { currentWorker },
		redComponents: {
			workerContractComponent: { selectedContractID },
			impactedBookingsModal: { forceUnlink },
		},
	} = getState()
	let url = `/front/affiliates/worker_contracts/${selectedContractID}`
	let body = {}
	if (forceUnlink) {
		body.force_unlink = forceUnlink
	}
	let init = { method: "DELETE", body: JSON.stringify(body) }
	let { actionType } = await dispatch(simpleFetch(url, init, DELETE_WORKER_CONTRACT))
	if (actionType === DELETE_WORKER_CONTRACT_SUCCESS) {
		dispatch(fetchWorker(currentWorker.id))
	}
}
