import { simpleFetch, longPollingChange } from "./index"
import { stringifyQuery } from "../helpers/qs"

export const DRAFT_TASK_EDITOR_STATE_CHANGE = "DRAFT_TASK_EDITOR_STATE_CHANGE"
export const draftTaskEditorStateChange = changes => ({
	type: DRAFT_TASK_EDITOR_STATE_CHANGE,
	changes,
})

export const TASKS_STATE_CHANGE = "TASKS_STATE_CHANGE"
export const tasksStateChange = changes => ({ type: TASKS_STATE_CHANGE, changes })

export const RESET_TASK_COMPONENT = "RESET_TASK_COMPONENT"
export const resetTaskComponent = () => ({ type: RESET_TASK_COMPONENT })

export const FETCH_TASKS = "FETCH_TASKS"
export const FETCH_TASKS_REQUEST = "FETCH_TASKS_REQUEST"
export const FETCH_TASKS_SUCCESS = "FETCH_TASKS_SUCCESS"
export const FETCH_TASKS_FAILURE = "FETCH_TASKS_FAILURE"
export const fetchTasks = () => (dispatch, getState) => {
	let init = { method: "GET" }
	let url =
		"/front/affiliates/tasks?" +
		getState()
			.redComponents.tasksComponent.currentAssigneesFilter.map(
				entry => "&assignee_id_filter[]=" + entry
			)
			.join("")
	return dispatch(simpleFetch(url, init, FETCH_TASKS))
}

const validateForAutoSave = ({ taskTitle, taskCategory, taskAssignees, taskScheduledDate }) => {
	if (taskAssignees.length < 1) return false
	// if (!taskScheduledDate || !taskScheduledDate.isValid()) return false
	return !!taskTitle
}

export const fetchTasksWithId = id => async (dispatch, getState) => {
	let { actionType } = await dispatch(fetchTasks())
	if (actionType === FETCH_TASKS_SUCCESS) {
		dispatch(resetTaskComponent())
		dispatch(longPollingChange({ requestContent: true }))
		dispatch(tasksStateChange({ show: true }))
		let {
			redData: { tasks },
		} = getState()
		let task = tasks.find(entry => entry.id === id)
		if (!task) {
			return null
		}
		dispatch(tasksStateChange({ ...task, currentTaskId: id }))
	}
}

export const AUTO_SAVE_TASK = "AUTO_SAVE_TASK"
export const AUTO_SAVE_TASK_REQUEST = "AUTO_SAVE_TASK_REQUEST"
export const AUTO_SAVE_TASK_SUCCESS = "AUTO_SAVE_TASK_SUCCESS"
export const AUTO_SAVE_TASK_FAILURE = "AUTO_SAVE_TASK_FAILURE"
export const autoSaveTask = ({
	taskId,
	taskBody,
	taskTitle,
	taskCategory,
	taskAssignees,
	taskScheduledDate,
} = {}) => async (dispatch, getState) => {
	let autoSaveable = validateForAutoSave({
		taskTitle,
		taskCategory,
		taskAssignees,
		taskScheduledDate,
	})
	if (!autoSaveable) return

	let scheduled_date = undefined
	if (taskScheduledDate && taskScheduledDate.isValid()) {
		scheduled_date = taskScheduledDate.format("YYYY-MM-DD")
	}
	let init = {
		method: "POST",
		body: JSON.stringify({
			scheduled_date: scheduled_date,
			title: taskTitle,
			category: taskCategory,
			done: false,
			affiliate_user_ids: taskAssignees.map(entry => entry.value),
			task_body: taskBody,
		}),
	}
	let url = "/front/affiliates/tasks"

	if (taskId !== "new-task" && taskId > 0) {
		init.method = "PUT"
		url += "/" + taskId
	}
	let { actionType, data } = await dispatch(
		simpleFetch(url, init, AUTO_SAVE_TASK, { taskId: taskId })
	)
	if (taskId !== "new-task") return

	if (actionType === AUTO_SAVE_TASK_SUCCESS) {
		// if the currentTaskId stays set to "new-task", all autoSave calls will create a new task
		dispatch(tasksStateChange({ currentTaskId: Number(data.data.id) }))
	}
}

export const TASK_DONE = "TASK_DONE"
export const TASK_DONE_REQUEST = "TASK_DONE_REQUEST"
export const TASK_DONE_SUCCESS = "TASK_DONE_SUCCESS"
export const TASK_DONE_FAILURE = "TASK_DONE_FAILURE"
export const taskDone = ({ taskId, done } = {}) => (dispatch, getState) => {
	let {
		redData: { tasks },
	} = getState()
	let task = tasks.find(entry => entry.id === taskId)
	let init = {
		method: "PUT",
		body: JSON.stringify({
			scheduled_date:
				task.taskScheduledDate &&
				task.taskScheduledDate.isValid() &&
				task.taskScheduledDate.format("YYYY-MM-DD"),
			title: task.taskTitle,
			category: task.taskCategory,
			done: done,
			affiliate_user_ids: task.taskAssignees.map(entry => entry.value),
			task_body: task.taskBody,
		}),
	}
	let url = "/front/affiliates/tasks/" + taskId
	dispatch(simpleFetch(url, init, TASK_DONE, { taskId: taskId }))
}

export const SUBMIT_DELETE_CURRENT_TASK = "SUBMIT_DELETE_CURRENT_TASK"
export const SUBMIT_DELETE_CURRENT_TASK_REQUEST = "SUBMIT_DELETE_CURRENT_TASK_REQUEST"
export const SUBMIT_DELETE_CURRENT_TASK_SUCCESS = "SUBMIT_DELETE_CURRENT_TASK_SUCCESS"
export const SUBMIT_DELETE_CURRENT_TASK_FAILURE = "SUBMIT_DELETE_CURRENT_TASK_FAILURE"
export const deleteCurrentTask = () => (dispatch, getState) => {
	let {
		redComponents: {
			tasksComponent: { currentTaskId },
		},
	} = getState()

	let init = { method: "DELETE" }
	let url = "/front/affiliates/tasks/" + currentTaskId
	dispatch(simpleFetch(url, init, SUBMIT_DELETE_CURRENT_TASK, { deletedId: currentTaskId }))
}

export const SEARCH_TASK_OBJECT = "SEARCH_TASK_OBJECT"
export const SEARCH_TASK_OBJECT_REQUEST = "SEARCH_TASK_OBJECT_REQUEST"
export const SEARCH_TASK_OBJECT_SUCCESS = "SEARCH_TASK_OBJECT_SUCCESS"
export const SEARCH_TASK_OBJECT_FAILURE = "SEARCH_TASK_OBJECT_FAILURE"
export const searchTaskObject = () => async (dispatch, getState) => {
	let {
		redData: { currentAffiliate },
		redComponents: {
			draftTaskEditorComponent: { currentObjectSearchType, currentObjectSearch },
		},
	} = getState()

	if (currentObjectSearchType === "squad") {
		let search = currentObjectSearch.toLowerCase()
		let filteredTeams = currentAffiliate.squads.filter(entry =>
			entry.name.toLowerCase().includes(search)
		)
		dispatch(draftTaskEditorStateChange({ objectSearchResults: filteredTeams }))
		return
	}

	let url = "/front/affiliates/bookings" + stringifyQuery({ filter_name: currentObjectSearch })
	if (currentObjectSearchType === "worker") {
		url =
			"/front/affiliates/affiliate_workers" +
			stringifyQuery({ filter_name: currentObjectSearch })
	} else if (currentObjectSearchType === "customer") {
		url =
			"/front/affiliates/customer_contracts" +
			stringifyQuery({ filter_name: currentObjectSearch })
	}

	let init = { method: "GET" }
	dispatch(simpleFetch(url, init, SEARCH_TASK_OBJECT))
}
