import React, { Component } from "react"
import PropTypes from "prop-types"

import { PootsyButton } from "./FunctionalDesign"
import ModalLayout from "./ModalLayout"
import { PootsyTextInput, PootsyCheckboxInput } from "./FunctionalInputs"

export default class AddBookingSVModal extends Component {
	state = {
		warning: "",
		canSubmit: false,
	}
	UNSAFE_componentWillReceiveProps = newProps => {
		let { checkedPSV, getPVSRelatedBookings } = this.props
		if (checkedPSV.currentPSV !== newProps.checkedPSV.currentPSV) {
			if (this.validatePSV(newProps.checkedPSV.currentPSV))
				getPVSRelatedBookings(newProps.checkedPSV.currentPSV)
		}
		if (
			newProps.checkedPSV.currentPSV !== "" &&
			newProps.checkedPSV.lastPSVChecked === newProps.checkedPSV.currentPSV &&
			!checkedPSV.linkedBookingID
		) {
			this.setState({ canSubmit: true })
		}
	}
	handlePSVCodeChange = e => {
		let { value } = e.target
		this.props.handlePSVInputChange(value)
	}
	validatePSV = PSVCode => {
		let { customerSodexo } = this.props
		if (PSVCode.length < 33) {
			this.setState({ warning: "psv_too_short_warning", canSubmit: false })
			return false
		} else if (PSVCode.length > 33) {
			this.setState({ warning: "psv_too_long_warning", canSubmit: false })
			return false
		}
		let cusSodFromPSV = parseInt(PSVCode.substring(2, 12), 10)
		let check = cusSodFromPSV % 97
		if (check < 10) {
			check = `0${check}`
		}
		cusSodFromPSV = cusSodFromPSV.toString() + check.toString()
		if (cusSodFromPSV !== customerSodexo) {
			this.setState({ warning: "psv_wrong_customer_warning", canSubmit: false })
			return false
		} else {
			this.setState({ warning: "" })
			return true
		}
		// 001533832623000000000000000000000
	}
	submitPSV = () => {
		let { currentPSV, skipCode } = this.props.checkedPSV
		let { canSubmit } = this.state
		if (canSubmit || skipCode) {
			this.props.submitPSV(currentPSV)
		}
	}
	render() {
		let { t } = this.context
		let { closeModal, checkedPSV, toggleSkipCode } = this.props
		let { warning } = this.state
		let buttons = [
			<PootsyButton key="a" text={t("confirm")} onClick={this.submitPSV} />,
			<PootsyButton key="b" text={t("cancel")} theme="cancel" onClick={closeModal} />,
		]
		return (
			<ModalLayout
				title={t("add_booking_psv")}
				buttons={buttons}
				closeModal={closeModal}
				formDivClass="add-booking-psv-modal"
			>
				<div className="prompt">{t("add_psv_modal_prompt")}</div>
				<PootsyTextInput
					label={t("psv_code")}
					value={checkedPSV.currentPSV}
					disabled={checkedPSV.skipCode}
					onChange={this.handlePSVCodeChange}
				/>
				<PootsyCheckboxInput
					name="skip_bar_code"
					label={t("add_psv_without_barcode")}
					checked={checkedPSV.skipCode}
					onChange={toggleSkipCode}
				/>
				<div className="warning">{!checkedPSV.skipCode && t(warning)}</div>
			</ModalLayout>
		)
	}
}

AddBookingSVModal.contextTypes = { t: PropTypes.func }
