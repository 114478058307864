import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { PootsyMultipleValuesBox } from "./FunctionalDesign"

/**
 * Give status (connected or not) and information (last sync date...) about the synchronisation
 * with the sodexo api.
 */
class SodexoConnectionStatus extends Component {
	connectionStatus = () => {
		let { t } = this.context
		return this.props.sodexoConnectionStatus.authToken
			? t("sodexo_conn_stat_connected")
			: t("sodexo_conn_stat_not_connected")
	}

	formatDate = date => {
		if (!date.isValid()) return ""
		return date.format("DD-MM-YYYY hh:mm:ss")
	}

	render() {
		let { t } = this.context
		let { sodexoConnectionStatus } = this.props
		return (
			<PootsyMultipleValuesBox
				values={[
					{ label: t("sodexo_conn_stat"), values: [this.connectionStatus()] },
					{
						label: t("sodexo_conn_last_requ_sync"),
						values: [this.formatDate(sodexoConnectionStatus.lastRequestedSync)],
						name: "lastRequestedSync",
					},
					{
						label: t("sodexo_conn_last_succ_sync"),
						values: [this.formatDate(sodexoConnectionStatus.lastSucceededSync)],
					},
					{
						label: t("sodexo_conn_last_fail_sync"),
						values: [this.formatDate(sodexoConnectionStatus.lastFailedSync)],
					},
				].filter(value => value.values[0])}
			/>
		)
	}
}

SodexoConnectionStatus.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	sodexoConnectionStatus: state.redData.currentAffiliate.sodexoConnectionStatus,
})
export default connect(mapStateToProps)(SodexoConnectionStatus)
