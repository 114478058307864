import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"

import * as actions from "../actions"

import NewCustomerModal from "./NewCustomerModal"
import NewResidenceModal from "./NewResidenceModal"
import InputsChangeHandler from "./InputsChangeHandler"
import {
	LayoutSwitch,
	PootsyButton,
	PootsySubHeading,
	NavLinkArrowBack,
	StepButton,
	LabeledDateTime,
	PootsyEvolvingData,
	PootsyValueDisplayer,
	AvailableWorkerCard,
} from "./FunctionalDesign"
import { PootsyTextInput, PootsyRadioInputs, PootsySelectInput } from "./FunctionalInputs"
import SearchCustomer from "./SearchCustomer"
import SearchWorker from "./SearchWorker"

class NewBooking extends Component {
	state = {
		today: moment().startOf("day"),
	}
	UNSAFE_componentWillMount = () => {
		let {
			dispatch,
			newBookingComponent: { editionMode, editedBookingID, editedBookingDate },
		} = this.props
		if (editionMode) {
			dispatch(actions.fetchEditedBooking(editedBookingID, editedBookingDate))
		}
	}
	componentWillUnmount = () => {
		this.props.dispatch(actions.resetNewBookingForm())
	}
	handleFormChanges = changes => {
		if (changes.duration) {
			if (
				isFinite(changes.duration.replace(",", ".")) &&
				parseFloat(changes.duration.replace(",", ".")) >= 1 &&
				parseFloat(changes.duration.replace(",", ".")) <= 9
			) {
				changes.duration = changes.duration.replace(".", ",")
				this.props.dispatch(actions.newBookingModalDataChange(changes))
			}
		} else {
			this.props.dispatch(actions.newBookingModalDataChange(changes))
		}
	}
	goToStep = step => {
		this.props.dispatch(actions.processNewBookingStepChange(step))
	}
	handleStateChange = changes => {
		let { dispatch } = this.props
		dispatch(actions.newBookingStateChange(changes))
	}
	toggleNewCustomerModal = () => {
		let { showNewCustomerModal } = this.props.newBookingComponent
		this.handleStateChange({ showNewCustomerModal: !showNewCustomerModal })
	}
	toggleNewResidenceModal = () => {
		let { showNewResidenceModal } = this.props.newBookingComponent
		this.handleStateChange({ showNewResidenceModal: !showNewResidenceModal })
	}
	handleSearchSpecificWorker = workerName => {
		this.handleFormChanges({
			specificWorker: { name: workerName, sodexo: "" },
			selectedWorker: {},
		})
	}
	disableEndDays = day => {
		let { currentEditedBooking, formData } = this.props
		let startDate = currentEditedBooking.startDate
		let recurrence = formData.recurrence

		if (day.isBefore(startDate) || day.isBefore(moment())) {
			return true
		} else if (recurrence === "1") {
			return (day.diff(startDate, "days") + 1) % (Number(recurrence) * 7) !== 0
		} else if (recurrence === "2") {
			return (day.diff(startDate, "days") + 1) % (Number(recurrence) * 7) !== 0
		}
	}
	handleSpecificWorkerClick = worker => {
		let newSpecWorker = {
			name: worker.attributes.display_name,
			sodexo: worker.attributes.sodexo_reference,
		}
		this.handleFormChanges({ specificWorker: newSpecWorker, language: "" })
	}
	handleSlotClick = e => {
		let { slot } = e.target.dataset
		this.handleStateChange({ showFeaturedWorker: true })
		this.handleFormChanges({ startTime: slot, selectedWorker: {} })
	}
	handleWorkerClick = worker => {
		let sc = { name: worker.displayName, contractId: worker.contractId }
		this.handleFormChanges({ selectedWorker: sc })
	}
	handleCustomerClick = customer => {
		this.props.dispatch(actions.chooseCustomer(customer))
	}
	handleDateChange = changes => {
		let {
			newBookingComponent: { timeSlotsPerDate },
		} = this.props
		let { selectedDate } = changes
		let showTimeSlots = false
		if (
			selectedDate &&
			timeSlotsPerDate &&
			timeSlotsPerDate[selectedDate.format("YYYY-MM-DD")]
		) {
			showTimeSlots = true
		}
		this.handleStateChange({
			showTimeSlots,
			showFeaturedWorker: false,
			showTimeslotsScrollHint:
				showTimeSlots &&
				Object.keys(timeSlotsPerDate[selectedDate.format("YYYY-MM-DD")]).length > 6,
		})
		this.handleFormChanges({ ...changes, selectedWorker: {}, startTime: "" })
	}
	handleDisplayedMonthChange = month => {
		this.props.dispatch(actions.handleNewBookingDisplayedMonthChange(month))
	}
	submit = () => {
		this.props.dispatch(actions.submitNewBooking())
	}
	render() {
		let { t } = this.context
		let { today } = this.state
		let {
			closeModal,
			currentEditedBooking,
			formData,
			newBookingComponent: {
				showNewCustomerModal,
				showNewResidenceModal,
				serverWarnings,
				layout,
				editionMode,
				currentStep,
				showTimeslotsScrollHint,
				showTimeSlots,
				timeSlotsPerDate,
				showFeaturedWorker,
			},
		} = this.props
		let showZipWarningMessage = false
		if (
			formData.chosenCustomer.name &&
			formData.chosenCustomer.residences.length > 0 &&
			formData.zip &&
			formData.zip !==
				formData.chosenCustomer.residences[formData.residenceIndex].attributes.zip_code
		) {
			showZipWarningMessage = true
		}
		let layoutProps, wrapperClass
		if (layout === "page") {
			layoutProps = {
				pageHeaderLeft: (
					<div className="page-header-left">
						<NavLinkArrowBack to="/bookings" />
						<h1 className="page-title">New Booking</h1>
					</div>
				),
			}
			wrapperClass = "new-booking-page"
		} else if (layout === "modal") {
			layoutProps = {
				title: editionMode ? t("edit_booking_modal_title") : t("new_booking_modal_title"),
				closeModal: closeModal,
			}
			wrapperClass = "new-booking-modal"
		}
		let deliveryDates = Object.keys(timeSlotsPerDate)
		let featuredWorker = undefined,
			timeslots = undefined
		if (formData.selectedDate) {
			if (timeSlotsPerDate[formData.selectedDate.format("YYYY-MM-DD")]) {
				/* without this it crashes on re-arrange booking */
				timeslots =
					timeSlotsPerDate[formData.selectedDate.format("YYYY-MM-DD")][formData.startTime]
			}
		}
		if (showFeaturedWorker && timeslots) {
			featuredWorker = timeslots[0]
		}
		return (
			<InputsChangeHandler onChange={this.handleFormChanges}>
				<LayoutSwitch layout={layout} wrapperClass={wrapperClass} layoutProps={layoutProps}>
					<div className="new-booking-inputs">
						<div className="new-booking-step-indicator">
							<StepButton
								active={currentStep === 1}
								disabled={false}
								onClick={() => this.goToStep(1)}
								text="1"
							/>
							<StepButton
								active={currentStep === 2}
								disabled={currentStep < 2}
								onClick={() => this.goToStep(2)}
								text="2"
							/>
						</div>
						{editionMode && currentEditedBooking.sodexo_status && (
							<div className="booking-linked-to-sodexo-warning">
								{t("booking_already_linked_to_sodexo_warning")}
							</div>
						)}
						<div className="new-booking-steps-container">
							<div className={"slider step-" + currentStep}>
								<section className="new-booking-step step-1">
									<div className="step-header">
										<PootsySubHeading text={t("enter_booking_details")} />
										<PootsyButton
											text={t("next")}
											customClass="new-booking-next-button"
											onClick={() => this.goToStep(2)}
										/>
									</div>
									<PootsyTextInput
										name="duration"
										type="text"
										label={t("duration")}
										showRequired={formData.showRequired.duration}
										min="1"
										max="9"
										value={formData.duration}
									/>
									<PootsySelectInput
										name="language"
										defaultText={t("language")}
										showRequired={formData.showRequired.language}
										selected={formData.language}
										allowEmpty={true}
										options={[
											{ label: t("english"), value: "en" },
											{ label: t("dutch"), value: "nl" },
											{ label: t("french"), value: "fr" },
										]}
									/>
									{editionMode && currentEditedBooking.recurrence && (
										<PootsySelectInput
											name="reach"
											defaultText={t("modification_reach")}
											selected={formData.reach}
											showRequired={formData.showRequired.reach}
											options={[
												{
													label: t("modify_one_occurrence"),
													value: "custom",
												},
												{
													label: t("modify_all_occurrences"),
													value: "definitive_from_date",
												},
											]}
										/>
									)}
									{(!editionMode ||
										formData.reach === "definitive_from_date") && (
										<PootsyRadioInputs
											groupLabel={t("recurrence")}
											groupName="recurrence"
											selected={formData.recurrence}
											showRequired={formData.showRequired.recurrence}
											options={[
												{ id: "once", value: "0", label: t("once") },
												{
													id: "weekly",
													value: "1",
													label: t("every_week"),
												},
												{
													id: "bimonthly",
													value: "2",
													label: t("every_two_weeks"),
												},
												{
													id: "monthly",
													value: "4",
													label: t("every_four_weeks"),
												},
											]}
										/>
									)}
									<SearchWorker
										onChange={this.handleSearchSpecificWorker}
										onClick={this.handleSpecificWorkerClick}
										value={formData.specificWorker.name}
									/>
									{((formData.reach === "definitive_from_date" &&
										formData.recurrence !== "0") ||
										!editionMode) && (
										<div className="end-date-input">
											<LabeledDateTime
												showTimePicker={false}
												label={t("optional_end_date")}
												datePickerProps={{
													name: "selectedEndDate",
													value: formData.selectedEndDate,
													alwaysOpened: false,
													disableDay: this.disableEndDays,
													disableAutoFocus: true,
													highlightDates: editionMode
														? [
																moment(
																	currentEditedBooking.delivery_date
																),
														  ]
														: [],

													onChange: this.handleFormChanges,
													disableInput: true,
												}}
											/>
										</div>
									)}
									<div className="step-header">
										<PootsySubHeading text={t("customer_details")} />
									</div>

									{!editionMode && (
										<div className="new-booking-customer-search">
											<SearchCustomer
												showRequired={formData.showRequired.chosenCustomer}
												handleCustomerClick={this.handleCustomerClick}
												suggestionCallback={suggestion => {
													let disable = false,
														divTitle = ""
													return { disable, divTitle }
												}}
											/>
											<PootsyButton
												text={t("create_new_customer")}
												onClick={this.toggleNewCustomerModal}
											/>
										</div>
									)}
									{formData.chosenCustomer.name && (
										<div className="new-booking-chosen-customer">
											<PootsySubHeading
												text={t("booking_for_following_customer")}
											/>
											<div className="chosen-customer-info">
												<PootsyValueDisplayer
													label={t("name")}
													values={[formData.chosenCustomer.name]}
												/>
												<PootsyValueDisplayer
													label={t("sodexo_number")}
													values={[
														formData.chosenCustomer.sodexo ||
															t("unknown"),
													]}
												/>
											</div>
											<div className="chosen-customer-address">
												<PootsyRadioInputs
													groupLabel={t("residence")}
													groupName="residenceIndex"
													selected={formData.residenceIndex}
													showRequired={
														formData.showRequired.residenceIndex
													}
													options={formData.chosenCustomer.residences.map(
														(entry, index) => ({
															id: entry.id,
															value: index.toString(),
															label: entry.attributes.address,
														})
													)}
												/>
												{formData.chosenCustomer.residences.length < 1 && (
													<div className="no-residence-warning-message">
														{t("new_booking_no_residence_warning")}
													</div>
												)}
											</div>
											<PootsyButton
												text={t("add_new_residence")}
												onClick={this.toggleNewResidenceModal}
												theme="cancel"
											/>
										</div>
									)}
								</section>
								<section className="new-booking-step step-2">
									<div className="step-header">
										<PootsySubHeading text={t("find_timeslot")} />
									</div>
									<div className="new-booking-datepicker">
										<PootsySubHeading
											fontSize="1em"
											text={t("choose_first_service_date")}
										/>
										{deliveryDates.length === 0 && (
											<div className="no-delivery-dates-warning">
												{t("no_delivery_date_warning")}
											</div>
										)}
										{deliveryDates && (
											<LabeledDateTime
												showTimePicker={false}
												datePickerProps={{
													name: "selectedDate",
													value: formData.selectedDate,
													alwaysOpened: true,
													disableAutoFocus: true,
													highlightDates: editionMode
														? [
																moment(
																	currentEditedBooking.delivery_date
																),
														  ]
														: [],
													disableDay: day =>
														day.isBefore(today) ||
														deliveryDates.indexOf(
															day.format("YYYY-MM-DD")
														) < 0,
													onChange: this.handleDateChange,
													onMonthChange: this.handleDisplayedMonthChange,
													disableInput: true,
												}}
											/>
										)}
									</div>
									<div
										className={
											"new-booking-timeslots" +
											(showTimeslotsScrollHint ? " scrollhint" : "")
										}
									>
										{showTimeSlots && (
											<PootsySubHeading
												fontSize="1em"
												text={t("available_timeslots")}
											/>
										)}
										<div className="new-booking-available-slots-list">
											{showTimeSlots &&
												Object.keys(
													timeSlotsPerDate[
														formData.selectedDate.format("YYYY-MM-DD")
													]
												)
													.sort((a, b) =>
														Number(a.slice(0, 2) + a.slice(3)) >
														Number(b.slice(0, 2) + b.slice(3))
															? 1
															: -1
													)
													.map(slot => (
														<div
															key={slot}
															data-slot={slot}
															className={
																"new-booking-hour-slot" +
																(formData.startTime === slot
																	? " active"
																	: "")
															}
															onClick={this.handleSlotClick}
														>
															{slot} >{" "}
															{moment(slot, "HH:mm")
																.add(
																	Number(formData.duration),
																	"hours"
																)
																.format("HH:mm")}
														</div>
													))}
										</div>
									</div>
									<div className="new-booking-featured-worker">
										{formData.startTime && (
											<Fragment>
												<PootsySubHeading
													fontSize="1em"
													text={t("featured_worker")}
												/>
												{featuredWorker && (
													<AvailableWorkerCard
														key={featuredWorker.contractId}
														selected={
															featuredWorker.contractId ===
															formData.selectedWorker.contractId
														}
														timeslot={featuredWorker}
														onClick={() =>
															this.handleWorkerClick(featuredWorker)
														}
													/>
												)}
											</Fragment>
										)}
									</div>
									{serverWarnings.map(warning => (
										<div
											key={warning.weekStart}
											className="server-warning-message"
										>
											{t("for_week", {
												week: warning.weekStart.format("DD/MM/YYYY"),
											})}{" "}
											- {t("booking_warning_" + warning.error)}
										</div>
									))}
									{formData.startTime &&
										timeSlotsPerDate[
											formData.selectedDate.format("YYYY-MM-DD")
										] &&
										timeSlotsPerDate[
											formData.selectedDate.format("YYYY-MM-DD")
										][formData.startTime].length > 1 && (
											<div className="available-workers-list">
												<PootsySubHeading
													fontSize="1em"
													text={t("available_cleaners")}
												/>
												{timeSlotsPerDate[
													formData.selectedDate.format("YYYY-MM-DD")
												][formData.startTime]
													.slice(
														1,
														timeSlotsPerDate[
															formData.selectedDate.format(
																"YYYY-MM-DD"
															)
														][formData.startTime].count
													)
													.map(worker => (
														<AvailableWorkerCard
															key={worker.contractId}
															selected={
																worker.contractId ===
																formData.selectedWorker.contractId
															}
															timeslot={worker}
															onClick={() =>
																this.handleWorkerClick(worker)
															}
														/>
													))}
											</div>
										)}
								</section>
							</div>
						</div>
					</div>
					<div className="new-booking-summary">
						<PootsyEvolvingData
							title={t("summary")}
							data={[
								{ label: t("duration"), value: formData.duration, show: true },
								{
									label: t("recurrence"),
									value: [t("once"), t("every_week"), t("every_two_weeks")][
										Number(formData.recurrence)
									],
									show: !editionMode || formData.reach === "definitive_from_date",
								},
								{ label: t("language"), value: formData.language, show: true },
								{
									label: t("customer"),
									value:
										formData.chosenCustomer.name &&
										formData.chosenCustomer.name,
									show: true,
								},
								{
									label: t("date"),
									value:
										formData.selectedDate &&
										formData.selectedDate.format("ddd DD/MM/YYYY"),
									show: true,
								},
								{ label: t("start"), value: formData.startTime, show: true },
								{
									label: t("cleaner"),
									value: formData.selectedWorker.name,
									show: true,
								},
							]}
						/>
						{editionMode && (
							<div className="edition-mode-prompt">
								<div className="description">{t("edition_mode_description")}</div>
								<div className="modifications-reach">
									{formData.reach !== "" &&
										t("this_update_will_modify", { reach: t(formData.reach) })}
								</div>
							</div>
						)}
						{showZipWarningMessage && (
							<div className="zip-warning-message">
								{t("new_booking_different_zip_warning", {
									cleanerZip: formData.zip,
									customerZip:
										formData.chosenCustomer.residences[formData.residenceIndex]
											.attributes.zip_code,
								})}
							</div>
						)}
						{editionMode ? (
							<PootsyButton
								text={t("edit_booking_button")}
								onClick={this.submit}
								theme="green"
								disabled={
									formData.startTime === "" || !formData.selectedWorker.contractId
								}
							/>
						) : (
							<PootsyButton
								text={t("create_booking")}
								onClick={this.submit}
								theme="green"
								disabled={
									formData.startTime === "" || !formData.selectedWorker.contractId
								}
							/>
						)}
					</div>
				</LayoutSwitch>
				{showNewCustomerModal && (
					<NewCustomerModal
						fromPage="newbooking"
						onCancel={this.toggleNewCustomerModal}
					/>
				)}
				{showNewResidenceModal && (
					<NewResidenceModal
						context="booking"
						customerId={formData.chosenCustomer.id}
						onCancel={this.toggleNewResidenceModal}
					/>
				)}
			</InputsChangeHandler>
		)
	}
}

let noop = () => {}
NewBooking.contextTypes = { t: PropTypes.func }
NewBooking.defaultProps = { closeModal: noop }
const mapStateToProps = state => ({
	lang: state.i18nState.lang,
	formData: state.redData.currentNewBookingFormData,
	newBookingComponent: state.redComponents.newBookingComponent,
	currentEditedBooking: state.redData.currentEditedBooking,
	currentAffiliate: state.redData.currentAffiliate,
})
export default connect(mapStateToProps)(NewBooking)
