import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"

// import * as actions from "../actions"

import { PootsyButton, LabeledDateTime } from "./FunctionalDesign"
import { PootsyRadioInputs, PootsySelectInput } from "./FunctionalInputs"
import SearchCustomer from "./SearchCustomer"

import ModalLayout from "./ModalLayout"
import InputsChangeHandler from "./InputsChangeHandler"

export class DirectBookingModal extends Component {
	state = {
		showRequired: {},
		start: moment().set({ year: 2000, month: 0, date: 1, hour: 14, minutes: 0, second: 0 }),
		end: moment()
			.set({ year: 2000, month: 0, date: 1, hour: 14, minutes: 0, second: 0 })
			.add(3, "hours"),
		dayPeriod: "",
		chosenCustomer: {},
		// endDate: moment(),
	}
	UNSAFE_componentWillMount = () => {
		let { initialStartDate, minStartDate, editedBooking } = this.props
		let newState = { startDate: initialStartDate, minStartDate }
		if (editedBooking && editedBooking.id) {
			newState = {
				...newState,
				id: editedBooking.id,
				start: editedBooking.start,
				end: editedBooking.end,
				startDate: editedBooking.startDate,
				voucherType: editedBooking.voucherType,
				dayPeriod: parseInt(editedBooking.dayPeriod, 10),
				chosenCustomer: editedBooking.chosenCustomer,
				residenceIndex: editedBooking.chosenCustomer.attributes.residences.data
					.reduce((acc, entry, index) => {
						if (editedBooking.residenceID === entry.id) {
							acc = index
						}
						return acc
					}, 0)
					.toString(),
			}
		}
		this.setState(newState)
	}
	handleFormChanges = changes => {
		if (changes.dayPeriod) {
			changes.dayPeriod = parseInt(changes.dayPeriod, 10)
		}
		this.setState(changes)
	}
	handleCustomerClick = customer => {
		let changes = { chosenCustomer: customer }
		if (customer.attributes.residences.data.length === 1) {
			changes.residenceIndex = "0"
		}
		if (customer.attributes.voucher_type_preference) {
			changes.voucherType = customer.attributes.voucher_type_preference
		} else {
			changes.voucherType = ""
		}
		this.setState({ ...changes })
	}
	validateState = () => {
		let toCheck = ["startDate", "dayPeriod", "chosenCustomer", "voucherType", "residenceIndex"],
			valid = true
		let toRequire = []
		toCheck.forEach(entry => {
			if (!this.state[entry]) {
				valid = false
				toRequire.push(entry)
			}
		})
		let newRequired = { ...this.state.showRequired }
		toRequire.forEach(entry => {
			newRequired[entry] = true
		})
		this.setState({ showRequired: newRequired })

		return valid
	}
	submit = () => {
		let {
			start,
			end,
			startDate,
			dayPeriod,
			chosenCustomer,
			residenceIndex,
			voucherType,
		} = this.state
		let valid = this.validateState()
		if (!valid) {
			return
		}
		let duration = Math.round(end.diff(start, "seconds") / 60)
		this.props.submit({
			dayPeriod: dayPeriod,
			startDate: moment(startDate),
			start: moment(start),
			end: moment(end),
			duration: duration,
			chosenCustomer: chosenCustomer,
			residenceID: chosenCustomer.attributes.residences.data[residenceIndex].id,
			zip: chosenCustomer.attributes.residences.data[residenceIndex].attributes.zip_code,
			voucherType,
		})
		this.props.closeModal()
	}
	render() {
		let { t } = this.context
		let { closeModal, disableDay } = this.props
		let {
			startDate,
			start,
			end,
			dayPeriod,
			chosenCustomer,
			voucherType,
			residenceIndex,
			showRequired,
		} = this.state
		return (
			<InputsChangeHandler onChange={this.handleFormChanges}>
				<ModalLayout
					title={t("new_direct_booking")}
					formDivClass="direct-booking-modal"
					closeModal={closeModal}
				>
					<div className="dates">
						<LabeledDateTime
							label={t("start_date")}
							showTimePicker={false}
							datePickerProps={{
								name: "startDate",
								value: startDate,
								onChange: this.handleFormChanges,
								calendarStyle: { top: "150%", left: 0 },
								showRequired: showRequired.startDate,
								disableDay: day => disableDay(dayPeriod, day),
							}}
						/>
						<LabeledDateTime
							label={t("start_time")}
							showDatePicker={false}
							timePickerProps={{
								value: start,
								name: "start",
								onChange: this.handleFormChanges,
							}}
						/>
						<LabeledDateTime
							label={t("end_time")}
							showDatePicker={false}
							timePickerProps={{
								value: end,
								name: "end",
								onChange: this.handleFormChanges,
								filterHours: h => h >= start.hours(),
								filterMinutes: m =>
									start.hours() < end.hours()
										? m >= 0 && m <= 59
										: m > start.minute(),
							}}
						/>
					</div>
					<div className="recurrence">
						<PootsyRadioInputs
							groupLabel={t("dayPeriod")}
							groupName="dayPeriod"
							showRequired={showRequired.dayPeriod}
							selected={dayPeriod.toString()}
							options={[
								{ id: "weekly", value: "7", label: t("every_week") },
								{ id: "bimonthly", value: "14", label: t("every_two_weeks") },
								{ id: "monthly", value: "28", label: t("every_four_weeks") },
							]}
						/>
					</div>
					<div className="customer">
						<SearchCustomer handleCustomerClick={this.handleCustomerClick} />
						{chosenCustomer.id && (
							<div className="chosen-customer">
								<div className="customer-info">
									{chosenCustomer.attributes.title +
										" " +
										chosenCustomer.attributes.display_name}
								</div>
								<PootsySelectInput
									name="voucherType"
									defaultText={t("voucher_type")}
									showRequired={showRequired.voucherType}
									selected={voucherType}
									options={[
										{ label: t("electronic"), value: "electronic" },
										{ label: t("paper"), value: "paper" },
										{ label: t("other"), value: "other" },
									]}
								/>
								{chosenCustomer.id &&
									voucherType !== "" &&
									chosenCustomer.attributes.voucher_type_preference !== null &&
									chosenCustomer.attributes.voucher_type_preference !== "" &&
									chosenCustomer.attributes.voucher_type_preference !==
										voucherType && (
										<div className="voucher-preference-mismatch-warning">
											{t("not_customer_preferred_voucher_type_warning")}
										</div>
									)}
								<div className="chosen-customer-address">
									<PootsyRadioInputs
										groupLabel={t("residence")}
										groupName="residenceIndex"
										selected={residenceIndex}
										showRequired={showRequired.residenceIndex}
										options={chosenCustomer.attributes.residences.data.map(
											(entry, index) => ({
												id: entry.id,
												value: index.toString(),
												label: entry.attributes.address,
											})
										)}
									/>
								</div>
							</div>
						)}
					</div>
					<PootsyButton text={t("submit")} onClick={this.submit} />
				</ModalLayout>
			</InputsChangeHandler>
		)
	}
}

DirectBookingModal.defaultProps = {
	disableDay: () => false,
}
DirectBookingModal.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({})
export default connect(mapStateToProps)(DirectBookingModal)
