import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { NavLink } from "./NavLink"

import Paginator from "./Paginator"
import * as actions from "../actions"

import { ContentLayout, LabeledSimpleLine, PootsyButton, OnssErrorModal } from "./FunctionalDesign"
import DimonaFilters from "./DimonaFilters"

import NewDimonaModal from "./NewDimonaModal"

class Dimonas extends Component {
	handleStateChanges = changes => {
		this.props.dispatch(actions.dimonasStateChange(changes))
	}
	handleChanges = changes => {
		this.props.dispatch(actions.dimonasFiltersStateChange(changes))
	}
	handleChangePage = changes => {
		this.props.dispatch(actions.dimonasFiltersStateChange({ page: changes.value }))
	}
	toggleShowNewDimonaModal = () => {
		let {
			component: { showNewDimonaModal },
		} = this.props
		this.handleStateChanges({ showNewDimonaModal: !showNewDimonaModal })
	}
	deleteDimona = id => {
		this.props.dispatch(actions.deleteDimona(id))
	}
	toggleOnssErrorModal = id => {
		let { dimonas } = this.props
		let dim = dimonas.find(entry => entry.id === id)
		let changes = {}
		if (!dim || !id || !dim.errors) {
			changes = {
				showOnssErrorModal: false,
				onssErrors: "",
			}
		} else {
			changes = {
				showOnssErrorModal: true,
				onssErrors: dim.errors,
				onssErrorsTradKeys: dim.errorTradKeys,
			}
		}
		this.handleStateChanges(changes)
	}
	optionsBoxes = () => {
		let { match, location, history } = this.props
		let { t } = this.context

		return (
			<Fragment>
				<DimonaFilters
					match={match}
					location={location}
					history={history}
					customQueries={{
						fromDateQuery: {
							urlName: "from",
							queryName: "from_date",
						},
						toDateQuery: {
							urlName: "to",
							queryName: "to_date",
						},
					}}
				/>
				<PootsyButton text={t("new_dimona")} onClick={this.toggleShowNewDimonaModal} />
			</Fragment>
		)
	}
	workerContractLink = affiliateWorkerId => {
		let { t } = this.context
		let result
		if (affiliateWorkerId) {
			result = (
				<NavLink
					to={`/cleaners/${affiliateWorkerId}/contract`}
					className="worker-contract-link"
				>
					{t("link_to_worker_contract")}
				</NavLink>
			)
		} else {
			result = t("unknow_contract")
		}

		return result
	}
	render() {
		let {
			page,
			pagination,
			dimonas,
			component: { showOnssErrorModal, onssErrors, onssErrorsTradKeys },
		} = this.props
		let { t } = this.context
		let {
			component: { showNewDimonaModal },
		} = this.props

		return (
			<ContentLayout customClass="Dimonas" optionsBoxes={this.optionsBoxes()}>
				{showOnssErrorModal && (
					<OnssErrorModal
						errors={onssErrors}
						errorsTradKeys={onssErrorsTradKeys}
						closeModal={this.toggleOnssErrorModal}
					/>
				)}
				{dimonas.map(entry => (
					<LabeledSimpleLine
						key={entry.id}
						simpleLineProps={{
							noLink: true,
							onClick: () => this.toggleOnssErrorModal(entry.id),
						}}
						columns={[
							{ noLabel: true, content: "#" + entry.id, customClass: "id" },
							{ label: t("onss_ref_number"), value: entry.onssRefNumber },
							{ noLabel: true, content: entry.dimonaType },
							{
								label: t("created_at"),
								value: entry.creationDate.format("DD/MM/YYYY"),
							},
							{
								noLabel: true,
								content:
									entry.dateIn && entry.dateIn.isValid()
										? entry.dateIn.format("DD/MM/YYYY")
										: "-",
							},
							{
								noLabel: true,
								content:
									entry.dateOut && entry.dateOut.isValid()
										? entry.dateOut.format("DD/MM/YYYY")
										: "-",
							},
							{
								label: t("worker"),
								value: entry.workerFirstName + " " + entry.workerLastName,
							},
							{ label: t("niss"), value: entry.workerNiss },
							{
								noLabel: true,
								content: this.workerContractLink(entry.affiliateWorkerId),
							},
							{
								label: t("status"),
								value: entry.status
									? t(entry.status)
									: t("onss_connection_not_yet"),
							},
							{
								noLabel: true,
								content: (
									<PootsyButton
										text={t("delete_dimona")}
										onClick={() => this.deleteDimona(entry.id)}
									/>
								),
							},
						]}
					/>
				))}

				{dimonas && dimonas.length === 0 && <div> {t("empty_list")} </div>}
				<div className="content" />
				<Paginator
					name="pageQuery"
					currPage={page}
					totalPages={pagination.total_pages}
					goToPage={this.handleChangePage}
				/>
				{showNewDimonaModal && (
					<NewDimonaModal closeModal={this.toggleShowNewDimonaModal} />
				)}
			</ContentLayout>
		)
	}
}

Dimonas.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.dimonasComponent,
	dimonas: state.redData.dimonas,
	page: state.redComponents.dimonasFiltersComponent.page,
	pagination: state.redComponents.dimonasFiltersComponent.pagination,
})
export default connect(mapStateToProps)(Dimonas)
