export const isOverlapping = (allTimeRanges, currentTimeRange) => {
	let overlappingEntryIndices = [],
		timeRangeWithinAnother = false
	allTimeRanges.forEach((entry, index, array) => {
		if (entry.day === currentTimeRange.day) {
			if (
				entry.start.isSameOrBefore(currentTimeRange.start) &&
				entry.end.isSameOrAfter(currentTimeRange.end)
			) {
				// here the time range to be added is within (or exactly equal to) another one
				// TODO: put some error / warning message
				timeRangeWithinAnother = true
			} else if (
				entry.start.isSameOrAfter(currentTimeRange.start) &&
				entry.end.isSameOrBefore(currentTimeRange.end)
			) {
				// here the time range to be added surrounds another one
				// (this would also catch exactly-equal timeranges,
				// but the block above will catch those before)
				overlappingEntryIndices.push(index)
			} else if (
				(entry.start.isBefore(currentTimeRange.end) &&
					entry.start.isAfter(currentTimeRange.start)) ||
				entry.start.isSame(currentTimeRange.end)
			) {
				// here the [time range to be added] is overlaping {another one}. This way >> [ { ] }
				currentTimeRange.end = entry.end
				overlappingEntryIndices.push(index)
			} else if (
				(entry.end.isBefore(currentTimeRange.end) &&
					entry.end.isAfter(currentTimeRange.start)) ||
				entry.end.isSame(currentTimeRange.start)
			) {
				// here the [time range to be added] is overlaping {another one}. This way >> { [ } ]
				currentTimeRange.start = entry.start
				overlappingEntryIndices.push(index)
			}
		}
	})
	// ensure correct duration if it was changed
	currentTimeRange.duration = currentTimeRange.end.diff(currentTimeRange.start, "minutes")
	return { timeRangeWithinAnother, overlappingEntryIndices }
}
