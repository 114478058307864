export const downloadFileFromUrl = (url, filename) => {
	fetch(url)
		.then(response => response.blob())
		.then(blob => {
			const url = window.URL.createObjectURL(blob)
			let downloadTriggerer = document.createElement("a")
			downloadTriggerer.href = url
			downloadTriggerer.download = filename
			downloadTriggerer.click()
			downloadTriggerer.remove()
			window.URL.revokeObjectURL(url)
		})
		.catch(e => console.log("Error while downloading pdf from url", e))
}
