import React, { Component } from "react"
import { ContentLayout } from "./FunctionalDesign"
import WorkshopSettings from "./WorkshopSettings"
import AvailableOrderItemCreator from "./AvailableOrderItemCreator"
import AvailableOrderItemList from "./AvailableOrderItemList"

export default class SettingsWorkshop extends Component {
	render() {
		return (
			<ContentLayout customClass="SettingsWorkshop">
				<WorkshopSettings />
				<AvailableOrderItemCreator />
				<AvailableOrderItemList category="CASH" />
				<AvailableOrderItemList category="SERVICE_VOUCHER" />
			</ContentLayout>
		)
	}
}
