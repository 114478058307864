import React from "react"
import PropTypes from "prop-types"
import moment from "moment"

import { PootsyButton } from "./FunctionalDesign"
import PootsyCalendar from "./PootsyCalendar"

const CalendarWeekPicker = ({
	week,
	showPicker,
	togglePicker,
	handleCalendarChange,
	calendarProps = {
		name: "weekFilter",
		controlToggle: true,
	},
}) => {
	return (
		<div className="calendar-week-picker">
			<PootsyButton
				onClick={togglePicker}
				disabled={calendarProps.disableCalendar}
				text={
					week.isoWeekday(1).format("DD/MM/YYYY") +
					" - " +
					moment(week)
						.isoWeekday(7)
						.format("DD/MM/YYYY")
				}
			/>
			<PootsyCalendar
				value={moment(week).isoWeekday(1)}
				weekPickerMode={true}
				togglePicker={togglePicker}
				{...calendarProps}
			/>
		</div>
	)
}

CalendarWeekPicker.contextTypes = { t: PropTypes.func }
export default CalendarWeekPicker
