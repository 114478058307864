import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment"

import { PootsyButton, LabeledDateTime } from "./FunctionalDesign"
import { PootsyRadioInputs, PootsyCheckboxInput } from "./FunctionalInputs"

export class NewWorkerContractActions extends Component {
	render() {
		let { t } = this.context
		let {
			currentNewContract,
			handleFormChanges,
			copyLastContractDataToNewContract,
			copyLastContractTheoreticalSchedulesToNewContract,
		} = this.props
		return (
			<div className="new-worker-contract-actions">
				<div className="buttons">
					<PootsyButton
						theme="cancel"
						text={t("copy_active_contract_data")}
						onClick={copyLastContractDataToNewContract}
					/>
					<PootsyButton
						theme="cancel"
						text={t("copy_theoretical_schedules")}
						onClick={copyLastContractTheoreticalSchedulesToNewContract}
					/>
				</div>
				<div className="actions">
					<LabeledDateTime
						label={t("previous_worker_contract_end_date")}
						showTimePicker={false}
						datePickerProps={{
							name: "oldContractEndDate",
							value: currentNewContract.newWorkerContractIsAddendum
								? moment(currentNewContract.startDate).subtract(1, "day")
								: currentNewContract.oldContractEndDate,
							disableDay: day => day.isAfter(currentNewContract.startDate),
							onChange: handleFormChanges,
							calendarStyle: { top: "150%", left: 0 },
							disableCalendar: currentNewContract.newWorkerContractIsAddendum,
						}}
					/>
					<PootsyRadioInputs
						groupName="oldContractBookingsTransfer"
						groupLabel={t("impacted_bookings_actions")}
						selected={currentNewContract.oldContractBookingsTransfer}
						options={[
							{
								id: "transfer_to_new_contract",
								value: "transfer_to_new_contract",
								label: t("transfer_bookings_to_new_contract"),
							},
							{
								id: "cancel",
								value: "cancel",
								label: t("cancel_bookings_from_old_contract"),
							},
						]}
					/>
					<PootsyCheckboxInput
						name="newWorkerContractIsAddendum"
						label={t("new_worker_contract_is_addendum")}
						checked={currentNewContract.newWorkerContractIsAddendum}
					/>
				</div>
			</div>
		)
	}
}

NewWorkerContractActions.contextTypes = { t: PropTypes.func }
export default NewWorkerContractActions
