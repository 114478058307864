import iso3166 from "../iso-3166-1.alpha-2.js"

export const countryCodes = iso3166

export default [
	{
		categoryName: "groups_identification",
		inputs: [
			{
				label: "private_email",
				inputType: "text",
				required: false,
				default: (currentWorker, secSocPrefill) =>
					secSocPrefill.email || currentWorker.email,
			},
			{
				label: "nationality",
				inputType: "select",
				required: false,
				options: countryCodes,
			},
			{
				label: "birthplace_town",
				inputType: "text",
				required: false,
			},
			{
				label: "birthplace_country",
				inputType: "select",
				required: false,
				options: countryCodes,
			},
			{
				label: "date_of_birth",
				inputType: "datepicker",
				required: true,
			},
			{
				label: "badge_number",
				inputType: "text",
				required: false,
			},
			{
				label: "region",
				inputType: "select",
				required: false,
				options: [
					{ label: "region_nd", value: "0" },
					{ label: "region_brussels", value: "1" },
					{ label: "region_flanders", value: "2" },
					{ label: "region_wallonia", value: "3" },
					{ label: "region_federal", value: "4" },
				],
				default: (currentWorker, secSocPrefill) => {
					let valuesMapping = { Wallonie: "3", Bruxelles: "1" }
					if (secSocPrefill.region) return valuesMapping[secSocPrefill.region]
					return valuesMapping[currentWorker.region]
				},
			},
			{
				label: "language_regimen",
				inputType: "select",
				required: false,
				options: [
					{ label: "lang_regimen_nd", value: "0" },
					{ label: "lang_regimen_fr", value: "1" },
					{ label: "lang_regimen_nl", value: "2" },
					{ label: "lang_regimen_de", value: "3" },
					{ label: "lang_regimen_frnl", value: "4" },
					{ label: "lang_regimen_frde", value: "5" },
				],
				default: (currentWorker, secSocPrefill) => {
					let valuesMapping = { fr: "1", nl: "2" }
					if (secSocPrefill.mainLanguage) return valuesMapping[secSocPrefill.mainLanguage]
					return valuesMapping[currentWorker.mainLanguage]
				},
			},
			{
				label: "civil_status",
				inputType: "select",
				required: true,
				options: [
					{ label: "civil_status_celib", value: "1" },
					{ label: "civil_status_married", value: "2" },
					{ label: "civil_status_widow", value: "3" },
					{ label: "civil_status_divorced", value: "4" },
					{ label: "civil_status_separated", value: "5" },
					{ label: "civil_status_cohab", value: "6" },
				],
			},
		],
	},
	{
		categoryName: "groups_conjoint",
		show: values => ["2", "5", "6"].includes(values["civil_status"]),
		inputs: [
			{
				label: "partner_name",
				inputType: "text",
				required: false,
			},
			{
				label: "partner_dob",
				inputType: "datepicker",
				required: false,
			},
		],
	},
	{
		categoryName: "groups_contrat",
		inputs: [
			{
				label: "bic",
				inputType: "text",
				required: true,
			},
			{
				label: "iban",
				inputType: "text",
				required: true,
			},
			{
				label: "end_reason",
				inputType: "select",
				required: false,
				options: [
					{ label: "end_reason_nd", value: "0" }, // non significatif
					{ label: "end_reason_dem_no_notice", value: "1" }, // démission sans préavis
					{ label: "end_reason_terminated", value: "2" }, // licenciement
					{ label: "end_reason_notice_by_worker", value: "3" }, // préavis par le travailleur
					{ label: "end_reason_death", value: "4" }, // décès
					{ label: "end_reason_retired", value: "5" }, // pension de retraite
					{ label: "end_reason_end_fixed_term", value: "8" }, // fin contrat durée déterminée / trav net. défini
					{ label: "end_reason_misconduct", value: "9" }, // rupture par l’employeur pour motif grave
					{ label: "end_reason_mutated", value: "12" }, // transfert / mutation
					{ label: "end_reason_status_change", value: "13" }, // changement de statut
					{ label: "end_reason_medical", value: "50" }, // force majeure raison médicale
					{ label: "end_reason_non_medical_necessity", value: "51" }, // force majeure autre que raison médicale
					{ label: "end_reason_accord", value: "54" }, // convention de rupture de commun accord
					{ label: "end_reason_bankruptcy", value: "55" }, // faillite
					{ label: "end_reason_rcc", value: "56" }, // rcc : régime de chômage avec complément d’entreprise
					{ label: "end_reason_rcic", value: "57" }, // rcic : régime de chômage avec indemnité complémentaire pour travailleurs agés
					{ label: "end_reason_cao_32b", value: "58" }, // cao 32 bis transfert conventionnel d’entreprise
					{ label: "end_reason_collective_layoff", value: "59" }, // licenciement collectif
					{ label: "end_reason_closed", value: "60" }, // fermeture
					{ label: "end_reason_no_certificate_or_c4", value: "99" }, // ni attestation occup ni c4
				],
			},
			{
				label: "contract_type",
				inputType: "select",
				required: true,
				options: [
					{ label: "contract_type_fixed_term", value: "1" }, // contrat de travail à durée déterminée
					{ label: "contract_type_permanent", value: "2" }, // contrat de travail à durée indéterminée
					{ label: "contract_type_substitute", value: "3" }, // contrat de remplacement
					{ label: "contract_type_defined_tasks", value: "4" }, // pour taches définies
					{ label: "contract_type_specific_tasks", value: "5" }, // pour taches spécifiques
					{ label: "contract_type_intern", value: "6" }, // statutaire en stage
					{ label: "contract_type_interim", value: "7" }, // contractuel en intérimaire
					{ label: "contract_type_doctor", value: "8" }, // médecin
					{ label: "contract_type_jobist", value: "9" }, // jobiste
					{ label: "contract_type_concierge", value: "10" }, // concierge
					{ label: "contract_type_law_24071987", value: "11" }, // contrat loi (24/07/1987)
				],
			},
			{
				label: "fixed_term_contract_length",
				inputType: "select",
				required: false,
				show: values => values["contract_type"] === "1",
				options: [
					{ label: "contract_length_under_3_months", value: "A" },
					{ label: "contract_length_over_3_months", value: "B" },
				],
			},
		],
	},
	{
		categoryName: "groups_categorie_du_contrat",
		inputs: [
			{
				label: "worker_title",
				inputType: "text",
				required: true,
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) => secSocPrefill.worker_title,
			},
		],
	},
	{
		categoryName: "groups_remuneration",
		inputs: [
			{
				label: "gross_salary",
				inputType: "text",
				required: true,
			},
			{
				label: "payment_mode",
				inputType: "select",
				required: false,
				options: [
					{ label: "payment_mode_other", value: "0" }, // paiement par moyen autre que banque
					{ label: "payment_mode_sepa", value: "1" }, // paiement par banque (sepa)
					{ label: "payment_mode_cash", value: "2" }, // de la main à la main
					{ label: "payment_mode_bank_non_sepa", value: "6" }, // banque non-sepa étranger
				],
			},
		],
	},
	{
		categoryName: "groups_onss",
		inputs: [
			{
				label: "local_unit_number",
				inputType: "text",
				required: true,
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) => secSocPrefill.local_unit_number,
			},
		],
	},
	{
		categoryName: "groups_precompte_professionnel",
		inputs: [
			{
				label: "worker_disabled",
				inputType: "select",
				required: false,
				options: [
					{ label: "no", value: "false" },
					{ label: "yes", value: "true" },
				],
			},
			{
				label: "child_dependents_number",
				inputType: "text",
				required: false,
			},
			{
				label: "disabled_child_dependents_number",
				inputType: "text",
				required: false,
			},
			{
				label: "other_dependents_number",
				inputType: "text",
				required: false,
			},
			{
				label: "disabled_other_dependents_number",
				inputType: "text",
				required: false,
			},
			{
				label: "over_65_dependents_number",
				inputType: "text",
				required: false,
			},
			{
				label: "disabled_over_65_dependents_number",
				inputType: "text",
				required: false,
			},
		],
	},
	{
		categoryName: "groups_bilan_social",
		inputs: [
			{
				label: "education_level",
				inputType: "select",
				required: false,
				options: [
					{ label: "education_primary", value: "1" }, // etudes primaires
					{ label: "education_secondary", value: "2" }, // etudes secondaires
					{ label: "education_superior", value: "3" }, // etudes supérieures non universitaires
					{ label: "education_univeristy", value: "4" }, // etudes universitaires
				],
			},
		],
	},
	{
		categoryName: "groups_regime_de_travail",
		inputs: [],
	},
	{
		categoryName: "groups_anciennete",
		inputs: [
			{
				label: "first_entry_date",
				inputType: "datepicker",
				required: true,
			},
			{
				label: "seniority_start_date",
				inputType: "datepicker",
				required: false,
			},
		],
	},
	{
		categoryName: "groups_end_of_contract_dates",
		show: values => values.endDate && values.endDate.isValid(),
		inputs: [
			{
				label: "notice_notification_date",
				inputType: "datepicker",
				required: false,
			},
			{
				label: "notice_start_date",
				inputType: "datepicker",
				required: false,
			},
		],
	},
	{
		categoryName: "groups_allocations_familiales",
		inputs: [],
	},
]
