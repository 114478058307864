import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment"

import { PootsyDateInput } from "./PootsyDateInput"
import { PootsySelectInput } from "./FunctionalInputs"
import arrowRightIcon from "../static/images/arrow-right-icon.png"

class PootsyCalendar extends Component {
	constructor(props) {
		super(props)
		let month = moment(props.value)
		if (!month.isValid()) {
			month = moment()
		}
		this.state = {
			monthInView: month,
			showPicker: false,
			showCalendarBtn: false,
			dateInput: month,
			dateProposed: "",
		}
	}
	UNSAFE_componentWillMount = () => {
		this.rejectInvalidValue(this.props)
	}
	UNSAFE_componentWillReceiveProps = newProps => {
		let { value: currValue } = this.props
		let { value: newValue } = newProps
		if (newProps.parentShowPicker) {
			document.addEventListener("click", this.handleClickOutside)
		} else if (this.props.parentShowPicker && !newProps.parentShowPicker) {
			document.removeEventListener("click", this.handleClickOutside)
		}
		if (
			currValue &&
			newValue &&
			currValue.isValid() &&
			newValue.isValid() &&
			!newValue.isSame(currValue, "month")
		) {
			// if the value was changed dynamically,
			// make sure the month in view is the right one.
			this.setState({ monthInView: moment(newProps.value) })
		}
		this.rejectInvalidValue(newProps)
	}
	componentWillUnmount = () => {
		document.removeEventListener("click", this.handleClickOutside)
	}
	stopPropagation = e => {
		e.stopPropagation()
	}
	rejectInvalidValue = props => {
		let { name, value, disableDay, onChange } = props
		if (disableDay && value && disableDay(value)) {
			// if the value passed is wrong, reject it straight up
			onChange({ [name]: undefined })
		}
	}
	getWeeksOfMonth = month => {
		let startOfMonth = moment(month).startOf("month")
		let nextMonth = moment(startOfMonth).add(1, "month")
		let m = moment(startOfMonth).startOf("isoweek")
		let weeksInCurrentMonth = []
		while (m.isBefore(nextMonth)) {
			weeksInCurrentMonth.push(moment(m))
			m.add(1, "week")
		}
		return weeksInCurrentMonth
	}
	handleInputFocus = () => {
		let { controlToggle, onCalendarOpen } = this.props
		if (controlToggle) {
			return
		}
		this.setState({ showPicker: true })
		onCalendarOpen()
		document.addEventListener("click", this.handleClickOutside)
	}
	handleClickOutside = e => {
		e.stopPropagation()
		let wrapper = this.refs.wrapper
		let isClickInside = wrapper.contains(e.target)
		if (!isClickInside) {
			this.closeCalendarAndCleanup()
		}
	}
	closeCalendarAndCleanup = () => {
		this.setState({ showPicker: false })
		this.props.togglePicker()
		document.removeEventListener("click", this.handleClickOutside)
	}
	goToPrevMonth = e => {
		e.stopPropagation()
		if (this.props.disableCalendar) {
			return
		}
		let newMonthInView = moment(this.state.monthInView).subtract(1, "month")
		this.setDate(newMonthInView)
	}
	goToNextMonth = e => {
		e.stopPropagation()
		if (this.props.disableCalendar) {
			return
		}
		let newMonthInView = moment(this.state.monthInView).add(1, "month")
		this.setDate(newMonthInView)
	}
	goToPrevYear = e => {
		e.stopPropagation()
		if (this.props.disableCalendar) {
			return
		}
		let newMonthInView = moment(this.state.monthInView).subtract(1, "year")
		this.setDate(newMonthInView)
	}
	goToNextYear = e => {
		e.stopPropagation()
		if (this.props.disableCalendar) {
			return
		}
		let newMonthInView = moment(this.state.monthInView).add(1, "year")
		this.setDate(newMonthInView)
	}
	selectYear = e => {
		let newMonthInView = moment(this.state.monthInView).year(parseInt(e.target.value, 10))
		this.setDate(newMonthInView)
	}
	setDate = newMonthInView => {
		this.setState({ monthInView: newMonthInView })
		this.props.onMonthChange(moment(newMonthInView))
	}
	handleDayClick = e => {
		e.stopPropagation()
		let { disabled, date } = e.target.dataset
		let { name, onChange, value, disableCalendar, weekPickerMode } = this.props
		if (disableCalendar || weekPickerMode || disabled === "true") {
			return
		}
		let m = moment(date)
		if (moment.isMoment(value) && value.isValid()) {
			// if the value provided is valid, keep the original hours & minutes
			m.set({ hour: value.hour(), minutes: value.minutes() })
		}
		onChange({ [name]: m })
		this.closeCalendarAndCleanup()
	}
	handleWeekClick = e => {
		e.stopPropagation()
		let { weekPickerMode, name, onChange } = this.props
		if (!weekPickerMode) {
			return
		}
		let { weekstart } = e.target.dataset
		let weekStart = moment(weekstart)
		onChange({ [name]: weekStart })
		this.closeCalendarAndCleanup()
	}
	handleUnsetDate = () => {
		let { name, onChange } = this.props
		onChange({ [name]: moment.invalid() })
	}
	handleBtnDateChange = e => {
		e.stopPropagation()
		let { name, onChange } = this.props
		onChange({ [name]: this.state.dateInput })
	}
	handleDateInputChange = e => {
		e.stopPropagation()
		let { value } = this.props
		if (!moment(e.target.value).isSame(value)) {
			this.setState({ showCalendarBtn: true, dateInput: moment(e.target.value) })
		}
	}
	changeProposedDay = day => {
		this.setState({ proposed: day })
	}
	renderDaysOfWeek = startOfWeek => {
		let { value, disableDay, highlightDates } = this.props
		let proposed = this.state.proposed
		let days = []
		let m = moment(startOfWeek)
		while (m.isSame(startOfWeek, "isoweek")) {
			let cellLabel = m.format("DD")
			let disabled = disableDay(m)
			let className = "day-cell"
			let highlightDay = false
			highlightDates.forEach(date => {
				if (m.isSame(date, "day")) {
					highlightDay = true
				}
			})
			className += disabled ? " disabled" : ""
			// m.isSame(undefined, "day") will become m.isSame(moment(), 'day'), so the value
			// also needs to be checked
			className += value && m.isSame(value, "day") ? " selected" : ""
			className += highlightDay ? " highlighted" : ""
			className += proposed && m.isSame(proposed, "day") ? " proposed" : ""
			days.push(
				<div
					key={"day" + cellLabel}
					className={className}
					onClick={this.handleDayClick}
					data-disabled={disabled}
					data-date={m.format()}
				>
					{cellLabel}
				</div>
			)
			m.add(1, "day")
		}
		return days
	}
	render() {
		let { t } = this.context
		let { monthInView, showPicker } = this.state
		let {
			value,
			alwaysOpened,
			calendarStyle,
			disableCalendar,
			allowUnset,
			weekPickerMode,
			controlToggle,
			parentShowPicker,
			isRequired,
			showRequired,
		} = this.props
		// TODO: maybe this is better handled through moments locales
		let days = [t("Mon"), t("Tue"), t("Wed"), t("Thu"), t("Fri"), t("Sat"), t("Sun")]
		let calendarWrapperClass = "pootsy-calendar-wrapper"
		if (alwaysOpened) {
			calendarWrapperClass += " always-opened"
			showPicker = true
		}
		if (disableCalendar) {
			calendarWrapperClass += " calendar-disabled"
		}
		if (weekPickerMode) {
			calendarWrapperClass += " week-picker-mode"
		}
		if (showRequired) {
			calendarWrapperClass += " show-required"
		}
		if (isRequired) {
			calendarWrapperClass += " is-required"
		}
		let inputValue = ""
		if (moment.isMoment(value) && value.isValid()) {
			inputValue = value.format("YYYY-MM-DD")
		}
		let selectableYears = Array(Math.max(monthInView.year(), moment().year()) - 1944)
			.fill()
			.map((e, i) => {
				let year = monthInView.year() + 5 - i
				return { label: year, value: year }
			})
		return (
			<div ref="wrapper" className={calendarWrapperClass}>
				{allowUnset && moment.isMoment(value) && value.isValid() && (
					<div className="unset-date" onClick={this.handleUnsetDate}>
						X
					</div>
				)}
				<input
					className="pootsy-calendar-collapsed"
					type="text"
					value={inputValue}
					onFocus={this.handleInputFocus}
					readOnly
				/>
				{(controlToggle && !disableCalendar ? parentShowPicker : showPicker) && (
					<div
						className="pootsy-calendar"
						style={calendarStyle}
						onClick={this.stopPropagation}
					>
						<PootsyDateInput
							name={this.props.name}
							onChange={this.props.onChange}
							value={this.props.value}
							disableAutoFocus={this.props.disableAutoFocus}
							disableDay={this.props.disableDay}
							checkValidity={this.props.checkValidity}
							closeCalendarAndCleanup={this.closeCalendarAndCleanup}
							onMonthChange={this.setDate}
							changeProposedDay={this.changeProposedDay}
							disableInput={this.props.disableInput}
							goToNextMonth={this.goToNextMonth}
							goToPrevMonth={this.goToPrevMonth}
						/>

						<div className="year-picker">
							<img
								alt="arrow-left"
								className="arrow-left"
								src={arrowRightIcon}
								onClick={this.goToPrevYear}
							/>
							<PootsySelectInput
								value={monthInView.year()}
								selected={monthInView.year()}
								onChange={this.selectYear}
								options={selectableYears}
							/>
							<img
								alt="arrow-right"
								className="arrow-right"
								src={arrowRightIcon}
								onClick={this.goToNextYear}
							/>
						</div>
						<div className="current-month-row">
							<img
								alt="arrow-left"
								className="arrow-left"
								src={arrowRightIcon}
								onClick={this.goToPrevMonth}
							/>
							<div className="month-label">{monthInView.format("MMMM")}</div>
							<img
								alt="arrow-right"
								className="arrow-right"
								src={arrowRightIcon}
								onClick={this.goToNextMonth}
							/>
						</div>
						<div className="title-days-row">
							{days.map(e => (
								<div key={e} className="title-day-cell">
									{e}
								</div>
							))}
						</div>
						{this.getWeeksOfMonth(monthInView).map(weekStart => {
							return (
								<div
									key={"week" + weekStart}
									className="week-row"
									onClick={this.handleWeekClick}
									data-weekstart={weekStart.format("YYYY-MM-DD")}
								>
									{this.renderDaysOfWeek(weekStart)}
								</div>
							)
						})}
					</div>
				)}
			</div>
		)
	}
}

let noop = () => {}
PootsyCalendar.defaultProps = {
	disableDay: noop,
	onChange: noop,
	onMonthChange: noop,
	alwaysOpened: false,
	calendarStyle: { top: "-200%", left: "115%" },
	disableCalendar: false,
	highlightDates: [],
	allowUnset: false,
	weekPickerMode: false,
	controlToggle: false,
	parentShowPicker: false,
	showRequired: false,
	isRequired: false,
	togglePicker: () => {},
	onCalendarOpen: () => {},
}

PootsyCalendar.contextTypes = { t: PropTypes.func }
export default PootsyCalendar
