import React from "react"
import PropTypes from "prop-types"

import pdfIcon from "../static/images/pdf-icon.png"

import { PootsyButton } from "./FunctionalDesign"

const TimesheetActions = (
	{
		timesheet,
		submitCloseMonth,
		toggleIntermediateClosureModal,
		cancelIntermediateClosureDate,
		toggleEditMode,
		user,
		toggleCustomizePDFModal,
	},
	{ t }
) => {
	if (!timesheet.timesheetInfo) {
		return null
	}

	const hasClosureDate = !!timesheet.timesheetInfo.closureDate
	const hasIntermediateClosureDate =
		timesheet.timesheetInfo.intermediateClosureDates &&
		timesheet.timesheetInfo.intermediateClosureDates.length > 0 &&
		!hasClosureDate
	return (
		<div className="TimesheetActions">
			{!timesheet.timesheetInfo.closureDate && (
				<PootsyButton
					text={t("close_month_timesheet")}
					onClick={submitCloseMonth}
					size="small"
					theme={timesheet.timesheetInfo.needsMapping ? "cancel" : "green"}
				/>
			)}
			{!hasClosureDate && !hasIntermediateClosureDate && (
				<PootsyButton
					text={t("intermediate_timesheet_closure")}
					onClick={toggleIntermediateClosureModal}
					size="small"
					theme={"green"}
				/>
			)}
			{hasIntermediateClosureDate && (
				<PootsyButton
					text={t("cancel_intermediate_timesheet_closure")}
					onClick={cancelIntermediateClosureDate}
					theme={"green"}
					size="small"
				/>
			)}
			<PootsyButton
				theme="blue"
				text={t("schedules_toggle_edit_mode")}
				onClick={toggleEditMode}
				size="small"
				disabled={hasClosureDate && !user.isMaster}
			/>
			<PootsyButton
				customClass="dl-pdf-button"
				theme="cancel"
				size="small"
				text={[<img key="a" alt="pdf" src={pdfIcon} />, t("sec_soc_download_month_sched")]}
				onClick={() => toggleCustomizePDFModal("workerTimesheet")}
			/>
		</div>
	)
}

TimesheetActions.contextTypes = { t: PropTypes.func }
export default TimesheetActions
