import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import WorkshopLine from "./WorkshopLine"
import InputsChangeHandler from "./InputsChangeHandler"
import { PootsyTextInput, PootsyRadioInputs, PootsyTextArea } from "./FunctionalInputs"
import { PootsySubHeading, PootsyButton } from "./FunctionalDesign"
import * as actions from "../actions"

class WorkshopSettings extends Component {
	handleChanges = e => {
		e.stopPropagation()
		this.props.dispatch(actions.workshopSettingsStateChange({ workshopName: e.target.value }))
	}
	handleFormChanges = changes => {
		this.props.dispatch(actions.editSettings(changes))
	}
	handleWorkshopSettingsChange = changes => {
		let key = ""
		if (changes["fixedFeeAmount"]) {
			key = "fixedFeeAmount"
		} else if (changes["maxMinutesOvertime"]) {
			key = "maxMinutesOvertime"
		}

		if (changes.duration) {
			if (isFinite(changes[key].replace(",", "."))) {
				changes[key] = changes[key].replace(".", ",")
			}
		}

		let { workshopSettings } = this.props.currAffiliate
		let newWorkshopSettings = { ...workshopSettings }
		let keyChanged = Object.keys(changes)[0]
		newWorkshopSettings[keyChanged] = changes[keyChanged]
		this.handleFormChanges({ workshopSettings: newWorkshopSettings })
	}
	submitNewWorkshop = () => {
		this.props.dispatch(actions.submitNewWorkshop())
	}

	floatNumberHandler = number => {
		return number ? number.toString().replace(".", ",") : ""
	}

	render() {
		let { t } = this.context
		let {
			currAffiliate,
			component: { workshopName },
		} = this.props
		return (
			<div className="WorkshopSettings">
				<div className="workshops-managements">
					<PootsySubHeading text={t("workshops")} />
					<div className="creation">
						<PootsyTextInput
							label={t("workshop_name")}
							name="workshopName"
							value={workshopName}
							onChange={this.handleChanges}
						/>
						<PootsyButton
							text={t("submit_new_workshop")}
							onClick={this.submitNewWorkshop}
						/>
					</div>
					<div className="workshops">
						{currAffiliate.workshops.map(entry => (
							<WorkshopLine key={entry.id} entry={entry} />
						))}
					</div>
				</div>
				<PootsySubHeading text={t("workshop_settings_title")} />
				<InputsChangeHandler
					onChange={this.handleWorkshopSettingsChange}
					customClass="inputs"
				>
					<div className="line">
						<PootsyTextInput
							label={t("max_minutes_overtime")}
							name="maxMinutesOvertime"
							value={this.floatNumberHandler(
								currAffiliate.workshopSettings.maxMinutesOvertime
							)}
						/>
						<PootsyRadioInputs
							groupName="avBasketToday"
							groupLabel={t("av_basket_today")}
							selected={currAffiliate.workshopSettings.avBasketToday}
							options={[
								{ id: "av-basket-today", value: "true", label: t("today") },
								{
									id: "av-basket-day-after",
									value: "false",
									label: t("the_day_after"),
								},
							]}
						/>
						<PootsyTextInput
							label={t("fixed_fee_amount")}
							name="fixedFeeAmount"
							value={this.floatNumberHandler(
								currAffiliate.workshopSettings.fixedFeeAmount
							)}
						/>
					</div>
					<div className="line">
						<PootsyTextArea
							name="orderTicketSignature"
							label={t("affiliate_custom_workshop_ticket_signature")}
							value={currAffiliate.workshopSettings.orderTicketSignature}
						/>
					</div>
				</InputsChangeHandler>
			</div>
		)
	}
}

WorkshopSettings.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currAffiliate: state.redData.currentAffiliate,
	component: state.redComponents.workshopSettingsComponent,
})
export default connect(mapStateToProps)(WorkshopSettings)
