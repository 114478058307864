import React, { Component } from "react"
import moment from "moment"

import InputsChangeHandler from "../components/InputsChangeHandler"
import { PootsySelectInput } from "../components/FunctionalInputs"

export default class PootsyTimePicker extends Component {
	constructor(props) {
		super(props)
		let allHours = Array(24)
			.fill()
			.map((e, i) => i)
		let allMinutes = Array(60)
			.fill()
			.map((e, i) => i)
		this.state = {
			allHours,
			allMinutes,
			displayedHours: allHours.filter(props.filterHours),
			displayedMinutes: allMinutes.filter(props.filterMinutes),
		}
	}
	UNSAFE_componentWillReceiveProps = newProps => {
		let { filterHours, filterMinutes } = this.props
		let { allHours, allMinutes } = this.state
		if (newProps.filterHours !== filterHours) {
			this.setState({ displayedHours: allHours.filter(newProps.filterHours) })
		}
		if (newProps.filterMinutes !== filterMinutes) {
			this.setState({ displayedMinutes: allMinutes.filter(newProps.filterMinutes) })
		}
	}
	handleChanges = changes => {
		let { value, name, onChange } = this.props
		let newValue = moment(value).set(changes)
		onChange({ [name]: newValue })
	}
	render() {
		let { value } = this.props
		let { displayedHours, displayedMinutes } = this.state
		return (
			<InputsChangeHandler customClass="pootsy-timepicker" onChange={this.handleChanges}>
				<PootsySelectInput
					name="hours"
					defaultText=""
					onChange={this.handleHoursChange}
					selected={value.hours().toString()}
					options={displayedHours.map(e => ({ label: e < 10 ? "0" + e : e, value: e }))}
				/>
				:
				<PootsySelectInput
					name="minutes"
					defaultText=""
					onChange={this.handleMinutesChange}
					selected={value.minutes().toString()}
					options={displayedMinutes.map(e => ({ label: e < 10 ? "0" + e : e, value: e }))}
				/>
			</InputsChangeHandler>
		)
	}
}

let noop = () => {}
let defaultFilter = () => true
PootsyTimePicker.defaultProps = {
	filterHours: defaultFilter,
	filterMinutes: defaultFilter,
	filterTime: defaultFilter,
	onChange: noop,
	value: moment({ hour: 0, minute: 0 }),
}
