import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"

import * as actions from "../actions"

import {
	PootsySubHeading,
	ContentLayout,
	OptionBox,
	PootsyTable,
	SodexoBookingDatedLine,
	PootsyButton,
} from "./FunctionalDesign"
import Paginator from "./Paginator"
import BookingFilters from "./BookingFilters"
import ModalLayout from "./ModalLayout"

class SodexoInsufficient extends Component {
	handleChanges = changes => {
		this.props.dispatch(actions.sodexoInsufficientStateChange(changes))
	}
	handleChangePage = changes => {
		this.props.dispatch(actions.bookingFiltersStateChange({ page: changes.value }))
	}
	submitSendReminder = () => {
		let { dispatch } = this.props
		this.handleChanges({ sendingRemindersFromModal: true })
		let url = "/front/affiliates/payment_reminders/batch_create"
		let params = this.props.currentURLQuery.realQuery
		params.filter_voucher_type = this.props.component.voucherTypeModalReminder
		let init = { method: "POST", body: JSON.stringify(params) }
		dispatch(actions.simpleFetch(url, init, "SEND_PAYMENT_REMINDER"))
	}
	openSendReminderModal = voucherType => {
		this.handleChanges({ voucherTypeModalReminder: voucherType, showEmailModal: true })
	}
	fetchCheques = () => {
		let { dispatch } = this.props
		let url =
			"/front/affiliates/bookings/export_cheques" +
			new URL(document.location.toString()).search
		let init = { method: "GET" }
		let requestMeta = { fileRequest: true, fileKeyName: "url" }
		return dispatch(actions.simpleFetch(url, init, "CHEQUES_LIST_EXPORT", requestMeta))
	}
	render() {
		let { t } = this.context
		let {
			bookings,
			match,
			location,
			history,
			page,
			pagination,
			component: { svData, sendingRemindersFromModal, showEmailModal },
		} = this.props
		// enrich filters with their value
		let optionsBoxes =
			((
				<OptionBox boxTitle={t("sodexo_info")}>
					<PootsySubHeading text={t("reminders_sent")} />
				</OptionBox>
			),
			(
				<BookingFilters
					match={match}
					location={location}
					history={history}
					filteredFilters={["sodexo_status_filter"]}
					customQueries={{
						fromDateQuery: {
							urlName: "from",
							queryName: "from_date",
							mustBeSet: true,
							defaultValue: moment()
								.subtract(1, "month")
								.format("YYYY-MM-DD"),
						},
						toDateQuery: {
							urlName: "to",
							queryName: "to_date",
							mustBeSet: true,
							defaultValue: moment().format("YYYY-MM-DD"),
						},
						svData: {
							urlName: "svd",
							queryName: "with_sv_data",
							mustBeSet: true,
							defaultValue: true,
						},
						sodexoStatus: {
							urlName: "vt",
							queryName: "filter_sodexo_status_only_electronic",
							mustBeSet: true,
							defaultValue: ["InsufficientCustomerCredit"],
						},
						incompleteBookings: {
							urlName: "ib",
							queryName: "filter_completion",
							mustBeSet: true,
							defaultValue: "psv_incomplete",
						},
						cancelledOccurrences: {
							urlName: "c",
							queryName: "reject_cancelled_occurrences",
							mustBeSet: true,
							defaultValue: true,
						},
					}}
				>
					<PootsyButton text={t("fetch_list_export")} onClick={this.fetchCheques} />
				</BookingFilters>
			))
		return (
			<ContentLayout optionsBoxes={optionsBoxes} customClass="SodexoInsufficient">
				{showEmailModal && (
					<ModalLayout
						title={t("confirm_sending_reminder")}
						formDivClass="absence-modal-inputs"
						buttons={[
							<PootsyButton
								key="1"
								text={t("send_reminder")}
								onClick={() => this.submitSendReminder()}
								fetching={sendingRemindersFromModal}
							/>,
						]}
						closeModal={() => this.handleChanges({ showEmailModal: false })}
					/>
				)}
				<PootsyTable
					labels={[t("voucher_type"), t("due_count"), t("missing_count"), t("reminder")]}
					rows={["electronic", "paper"].map(voucherType => ({
						columns: [
							t(voucherType),
							svData[voucherType].total,
							svData[voucherType].missing,
							<PootsyButton
								text={t("send_reminder")}
								onClick={() => this.openSendReminderModal(voucherType)}
							/>,
						],
					}))}
				/>
				{bookings.map(entry => (
					<SodexoBookingDatedLine key={entry.id} booking={entry} />
				))}

				<Paginator
					name="pageQuery"
					currPage={page}
					totalPages={pagination.total_pages}
					goToPage={this.handleChangePage}
				/>
			</ContentLayout>
		)
	}
}

SodexoInsufficient.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currentURLQuery: state.currentURLQuery,
	component: state.redComponents.sodexoInsufficientComponent,
	bookings: state.redData.bookings,
	page: state.redComponents.bookingFiltersComponent.page,
	pagination: state.redComponents.bookingFiltersComponent.pagination,
})
export default connect(mapStateToProps)(SodexoInsufficient)
