import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"
import _ from "lodash"
import { Link } from "react-router-dom"

import { stringifyQuery } from "../helpers/qs"
import * as actions from "../actions"

import {
	ContentLayout,
	OptionBox,
	PootsySubHeading,
	PootsyMultipleValuesBox,
	PootsyButton,
	ConfirmationModal,
	PootsyHeading,
	NavLinkArrowBack,
	PageLayout,
} from "./FunctionalDesign"
import { PootsyTextArea } from "./FunctionalInputs"
import { NavLink } from "./NavLink"

import binIcon from "../static/images/bin-icon.png"
import previewIcon from "../static/images/preview-icon.png"

class PSVDeliveryDetails extends Component {
	state = {
		item_delete_id: null,
		note: "",
	}
	handleChanges = changes => {
		this.props.dispatch(actions.paperDeliveryStateChange(changes))
	}
	UNSAFE_componentWillMount = () => {
		this.props.dispatch(actions.fetchPaperDelivery(this.props.match.params.id))
	}
	componentWillUnmount = () => {
		this.props.dispatch(actions.resetPaperDelivery())
	}
	deletingPsv = () => {
		this.props.dispatch(
			actions.deletePSVInGroup(this.state.item_delete_id, this.props.component.PSVDelivery.id)
		)
	}
	requestSodexoDeclaration = e => {
		let { dispatch } = this.props
		let url = "/front/affiliates/sodexo_documents/psv_declaration"
		let query = { paper_voucher_delivery_group: this.props.component.PSVDelivery.id }
		url += stringifyQuery(query)
		let requestMeta = { fileRequest: true, fileKeyName: "sodexoDeclarationCSV" }
		dispatch(actions.simpleFetch(url, { method: "GET" }, "SODEXO_DECLARATION_CSV", requestMeta))
	}
	cancellingDeletion = () => {
		this.handleChanges({ isDeleting: !this.props.component.isDeleting })
		this.setState({ item_delete_id: null })
	}
	handleCommentPVDG = e => {
		let {
			component: { PSVDelivery },
		} = this.props
		let body = {
			id: PSVDelivery.id,
			note: e.target.value,
			month_start_filter: PSVDelivery.attributes.month_start_filter,
			month_end_filter: PSVDelivery.attributes.month_end_filter,
			subject_type: PSVDelivery.attributes.subject_type,
			squad_id: PSVDelivery.attributes.squad_id,
		}
		this.handleChanges({ note: e.target.value })
		this.sendUpdateComment(body)
	}
	sendUpdateComment = _.debounce(body => {
		this.props.dispatch(actions.updatePaperDelivery(body))
	}, 2000)
	reOpenningDelivery = () => {
		this.handleChanges({ isReopening: !this.props.component.isReopening })
	}
	reOpenDelivery = () => {
		this.props.dispatch(actions.reopenPaperDelivery(this.props.component.PSVDelivery.id))
	}
	closingDelivery = () => {
		this.handleChanges({ isClosing: !this.props.component.isClosing })
	}
	deletePSVfromGroup = id => {
		this.setState({ item_delete_id: id })
		this.handleChanges({ isDeleting: !this.props.component.isDeleting })
	}
	cancellingDelivery = () => {
		this.handleChanges({ isCancelling: !this.props.component.isCancelling })
	}
	cancelDelivery = () => {
		this.props.dispatch(actions.cancelPaperDelivery(this.props.component.PSVDelivery.id))
	}
	closeDelivery = () => {
		this.props.dispatch(actions.closePaperDelivery(this.props.component.PSVDelivery.id))
	}
	printCSV = () => {
		this.props.dispatch(actions.downloadDeliveryCSV(this.props.component.PSVDelivery.id))
	}
	render() {
		let { t } = this.context
		let {
			component: { PSVDelivery, isClosing, isCancelling, isReopening, isDeleting, note },
		} = this.props
		let total_values = []
		let total = 0
		let region = null
		let isStatusOpen = false
		let isStatusCancel = false
		let isStatusSent = false
		let pageHeaderLeft = ""
		let isReady = PSVDelivery && PSVDelivery.attributes
		if (isReady) {
			let values = PSVDelivery.attributes.paper_service_vouchers.total_value

			for (let [key, value] of Object.entries(values)) {
				total_values.push(`${value} * ${key}€:   ${value * key}€`)
				total += value * key
			}
			isStatusOpen =
				PSVDelivery.attributes.status === "open" ||
				PSVDelivery.attributes.status === "re_opened"
			isStatusCancel = PSVDelivery.attributes.status === "cancelled"
			isStatusSent = PSVDelivery.attributes.status === "sent"
			total = `${total}€`
			pageHeaderLeft = (
				<div className="page-header-left">
					<NavLinkArrowBack to="/sodexo/exports" />

					<h1 className="page-title">
						{PSVDelivery.attributes.psv_group_affiliate_reference}
					</h1>
				</div>
			)

			region = t(PSVDelivery.attributes.sodexo_region)
		}
		let closingButton = <PootsyButton text={t("Confirm")} onClick={this.closeDelivery} />
		let cancelClosingButton = (
			<PootsyButton text={t("cancel")} onClick={this.closingDelivery} theme="cancel" />
		)
		let cancellingButton = <PootsyButton text={t("Confirm")} onClick={this.cancelDelivery} />
		let cancelDeletingButton = (
			<PootsyButton text={t("cancel")} onClick={this.cancellingDeletion} theme="cancel" />
		)
		let deletingButton = <PootsyButton text={t("delete")} onClick={this.deletingPsv} />
		let cancelCancellingButton = (
			<PootsyButton text={t("cancel")} onClick={this.cancellingDelivery} theme="cancel" />
		)
		let reopeningButton = <PootsyButton text={t("reopen")} onClick={this.reOpenDelivery} />
		let cancelReopeningButton = (
			<PootsyButton text={t("cancel")} theme="cancel" onClick={this.reOpenningDelivery} />
		)
		let optionsBoxes = (
			<OptionBox>
				{isReady && (
					<Fragment>
						<PootsyHeading
							text={PSVDelivery.attributes.psv_group_affiliate_reference}
						/>
						<PootsySubHeading
							text={moment(PSVDelivery.attributes.month_start).format("MMMM - YYYY")}
						/>
						<PootsyMultipleValuesBox
							values={[
								{ label: t("value_delivery"), values: total_values },
								{
									label: t("total"),
									values: [total],
								},
								{ label: t("region"), values: [region] },
							]}
						/>
						<PootsyTextArea
							label={t("comment")}
							placeholder={PSVDelivery.attributes.note}
							value={note}
							name="note"
							onChange={this.handleCommentPVDG}
						/>
						<div className="action-buttons">
							{isStatusOpen && (
								<PootsyButton
									text={t("close")}
									customClass="pootsy-option-button"
									onClick={this.closingDelivery}
								/>
							)}
							{isStatusOpen && (
								<PootsyButton
									text={t("cancel")}
									customClass="small pootsy-option-button"
									theme="cancel"
									onClick={this.cancellingDelivery}
								/>
							)}
							{isStatusCancel && (
								<PootsyButton
									text={t("reopen")}
									customClass="pootsy-option-button"
									onClick={this.reOpenningDelivery}
								/>
							)}
							{isStatusSent && (
								<div className="sent-buttons">
									<PootsyButton
										text={t("download_csv_slip")}
										customClass="pootsy-option-button"
										onClick={this.printCSV}
									/>
									<PootsyButton
										text={t("reopen")}
										customClass="pootsy-option-button"
										onClick={this.reOpenningDelivery}
									/>
								</div>
							)}
						</div>
						{isReady && (
							<div className="pvd-logs">
								{PSVDelivery.attributes.pvd_groups_logs.map(item => (
									<div className="pvd-log" key={item.date_log}>
										<div className="column date">
											{moment(item.date_log).format("DD/MM/YY")}:
										</div>
										<div className="column action">{t(item.action_type)}</div>
										<div className="column author">{item.actor}</div>
									</div>
								))}
							</div>
						)}
					</Fragment>
				)}
			</OptionBox>
		)
		let pageHeaderRight = (
			<div className="page-header-right">
				{PSVDelivery && isStatusOpen && (
					<Link to={"/update_paper_delivery_group/" + PSVDelivery.id}>
						<PootsyButton text={t("add_return_slip")} theme="white" />
					</Link>
				)}
				{isStatusSent && (
					<PootsyButton
						text={t("download_psv_declaration")}
						onClick={this.requestSodexoDeclaration}
					/>
				)}
			</div>
		)
		return (
			<PageLayout
				headerLeft={pageHeaderLeft}
				customClass="PSVDeliveryDetails"
				headerRight={pageHeaderRight}
			>
				<ContentLayout optionsBoxes={optionsBoxes}>
					{isClosing && (
						<ConfirmationModal
							title={t("close")}
							yesButton={closingButton}
							noButton={cancelClosingButton}
						/>
					)}
					{isCancelling && (
						<ConfirmationModal
							title={t("cancel")}
							yesButton={cancellingButton}
							noButton={cancelCancellingButton}
						/>
					)}
					{isReopening && (
						<ConfirmationModal
							title={t("cancel")}
							yesButton={reopeningButton}
							noButton={cancelReopeningButton}
						/>
					)}
					{isDeleting && (
						<ConfirmationModal
							title={t("remove_psv_link")}
							yesButton={deletingButton}
							noButton={cancelDeletingButton}
						/>
					)}
					<div className="psv-table">
						<div className="row row-first">
							<div className="column code">{t("psv_code")}</div>
							<div className="column customer">{t("customer")}</div>
							<div className="column booking">{t("booking")}</div>
							<div className="column delete">{t("delete")}</div>
							<div className="column preview">{t("preview")}</div>
						</div>
						{isReady &&
							PSVDelivery.attributes.paper_service_vouchers.paper_slips
								.sort(function(a, b) {
									if (a.is_expired) return -1
									if (a.is_expiring_this_month && b.is_expired) return 1
									if (a.is_expiring_this_month && !b.is_expired) return -1
									return 0
								})
								.map(item => {
									if (!item) return null
									let expired =
										item.is_expired && PSVDelivery.attributes.status !== "sent"
									let isExpiring =
										item.is_expiring_this_month &&
										PSVDelivery.attributes.status !== "sent"
									let date = moment(item.delivery_date)
									return (
										<div key={item.id} className={"row "}>
											<div
												className={
													"column code" +
													(expired ? " expired" : "") +
													(isExpiring ? " expiring" : "")
												}
											>
												{item.code}
											</div>
											{item.warning && (
												<div className="column warning">
													{t(item.warning)}
												</div>
											)}
											<div className="column customer">
												{item.customer_contract_display_name}
											</div>
											<div className="column booking">
												{date.format("DD/MM/YY")} -{" "}
												{item.worker_contract_display_name}
											</div>
											<div className="column delete">
												{!isStatusSent && (
													<img
														className="bin-icon"
														alt="bin-icon"
														src={binIcon}
														onClick={() => {
															this.deletePSVfromGroup(item.id)
														}}
													/>
												)}
											</div>
											<div className="column preview">
												<NavLink
													newTab={true}
													to={
														item.subject_type === "LaundryOrder"
															? "/workshop/" + item.subject_id
															: "/bookings/" +
															  item.subject_id +
															  "_" +
															  date.format("YYYY-MM-DD")
													}
												>
													<img
														className="preview-icon"
														alt="preview-icon"
														src={previewIcon}
													/>
												</NavLink>
											</div>
										</div>
									)
								})}
					</div>
				</ContentLayout>
			</PageLayout>
		)
	}
}

PSVDeliveryDetails.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.PSVDeliveryComponent,
})
export default connect(mapStateToProps)(PSVDeliveryDetails)
