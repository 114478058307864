import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import * as actions from "../actions"

import InputsChangeHandler from "./InputsChangeHandler"
import { ContentLayout, PootsySubHeading } from "./FunctionalDesign"
import { PootsyCheckboxInput } from "./FunctionalInputs"

class SettingsMe extends Component {
	handleChanges = changes => {
		let { currUser } = this.props
		let key = Object.keys(changes)[0]
		let newPref = { label: key, value: changes[key] }
		let url = "/front/affiliates/accounts/" + currUser.accountID
		let init = {
			method: "PUT",
			body: JSON.stringify({
				account_notification_preferences: currUser.notificationsPreferences.map(entry => {
					if (entry.label === newPref.label) {
						return Object.assign(newPref, {
							notification_type: entry.notification_type,
						})
					}
					return entry
				}),
			}),
		}
		this.props.dispatch(actions.simpleFetch(url, init, "CURRENT_USER_UPDATE"))
	}
	render() {
		let { t } = this.context
		let { currUser } = this.props
		return (
			<ContentLayout customClass="settings-me">
				<InputsChangeHandler customClass="inputs-card" onChange={this.handleChanges}>
					<PootsySubHeading text={t("notification_preferences")} />
					<div className="notification-preferences-inputs">
						{currUser.notificationsPreferences.map(entry => (
							<PootsyCheckboxInput
								key={entry.label}
								name={entry.label}
								label={t("notification_preference_" + entry.label)}
								checked={entry.value}
							/>
						))}
					</div>
				</InputsChangeHandler>
			</ContentLayout>
		)
	}
}

SettingsMe.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currUser: state.redData.currentUser,
})
export default connect(mapStateToProps)(SettingsMe)
