import React, { Component } from "react"
import { CSSTransition } from "react-transition-group"



export default class SlideIn extends Component {
	state = {
		mounted: false,
	}
	componentDidMount = () => {
		let { delay } = this.props
		if (delay) {
			this.timeoutID = setTimeout(() => {
				this.setState({ mounted: true })
			}, delay)
			return
		}
		this.setState({ mounted: true })
	}
	componentWillUnmount = () => {
		clearTimeout(this.timeoutID)
	}
	render() {
		let { slideDirection = "to-bottom", show } = this.props
		let inProp = this.state.mounted
		if (show !== undefined) {
			inProp = show
		}
		return (
			<CSSTransition
				classNames={"SlideIn-" + slideDirection}
				timeout={2000}
				in={inProp}
				// mountOnEnter is required because if the "in" prop is set to true _after_ this.props.children
				// is rendered (i.e. rendering everything and only then start to animate), the CSS
				// won't be triggered properly.
				// The 'enter' phase (the initial CSS we want to animate _from_) will be applied
				// too fast (?) and there will be no repaint, effectively going straight to the 'enter-active'
				// phase (with the CSS we want to animate _to_)...
				// But for some reason, the CSS is applied properly if the component isn't mounted yet...
				mountOnEnter={true}
				unmountOnExit={true}
			>
				{this.props.children}
			</CSSTransition>
		)
	}
}
