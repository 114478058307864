import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
	Editor,
	EditorBlock,
	EditorState,
	RichUtils,
	getDefaultKeyBinding,
	CompositeDecorator,
	AtomicBlockUtils,
	Modifier,
	convertToRaw,
	convertFromRaw,
} from "draft-js"

import { PootsyButton } from "./FunctionalDesign"
import { PootsyTextInput, PootsySelectInput } from "./FunctionalInputs"
import ModalLayout from "./ModalLayout"
import { BoldSymbol } from "../svg/boldSymbol"
import { ItalicSymbol } from "../svg/italicSymbol"
import { UnderlineSymbol } from "../svg/underlineSymbol"
import { OrderedListSymbol } from "../svg/olSymbol"
import { UnorderedListSymbol } from "../svg/ulSymbol"
import { RightAlignedTextIcon } from "../svg/rightAlignedText"
import { CenterAlignedTextIcon } from "../svg/centerAlignedText"

import arrowRightIcon from "../static/images/arrow-right-icon.png"
import imgIcon from "../static/images/img-icon.png"

const BLOCK_TYPES = [
	{ icon: <UnorderedListSymbol />, style: "unordered-list-item" },
	{ icon: <OrderedListSymbol />, style: "ordered-list-item" },
	{ icon: <CenterAlignedTextIcon />, style: "text_align_center" },
	{ icon: <RightAlignedTextIcon />, style: "text_align_right" },
]
const INLINE_STYLES = [
	{ icon: <BoldSymbol />, style: "BOLD" },
	{ icon: <ItalicSymbol />, style: "ITALIC" },
	{ icon: <UnderlineSymbol />, style: "UNDERLINE" },
]

const Image = ({ contentState, block }) => {
	const entity = contentState.getEntity(block.getEntityAt(0))
	const { src, alignment } = entity.getData()
	return (
		<div style={{ display: "flex", justifyContent: alignment }}>
			<img className="in-editor-logo" alt="logo" src={src} style={{ height: "2cm" }} />
		</div>
	)
}

const containerStyle = {
	marginRight: "3px",
	marginLeft: "3px",
}
const variableStyle = {
	background: "#fdba88",
	color: "white",
	borderRadius: "3px",
	paddingLeft: "3px",
	paddingRight: "3px",
}

const Variable = ({ contentState, entityKey, children, ...rest }) => {
	const entity = contentState.getEntity(entityKey)
	const data = entity.getData()
	return (
		<span style={containerStyle}>
			<span style={variableStyle}>
				{data.translatedVariable}
				<wbr />
			</span>
			<span style={{ position: "absolute", width: 0, visibility: "hidden" }}>{children}</span>
		</span>
	)
}

const findVariableEntities = (contentBlock, callback, contentState) => {
	return contentBlock.findEntityRanges(character => {
		const entityKey = character.getEntity()
		return entityKey !== null && contentState.getEntity(entityKey).getType() === "VARIABLE"
	}, callback)
}

const variableDecorator = new CompositeDecorator([
	{
		strategy: findVariableEntities,
		component: Variable,
	},
])

const RightAligned = stuff => (
	<div style={{ textAlign: "right" }}>
		<EditorBlock {...stuff} />
	</div>
)
const CenterAligned = stuff => (
	<div style={{ textAlign: "center" }}>
		<EditorBlock {...stuff} />
	</div>
)

export class DraftEditorStyleButton extends Component {
	onToggle = e => {
		e.preventDefault()
		this.props.onToggle(this.props.style)
	}
	render() {
		let { icon, active } = this.props
		let className = "text-style-button"
		if (active) {
			className += " active"
		}
		return (
			<div className={className} onMouseDown={this.onToggle}>
				{icon}
			</div>
		)
	}
}
DraftEditorStyleButton.contextTypes = { t: PropTypes.func }

class DraftEditor extends Component {
	state = {
		mounted: false,
		showVariables: false,
	}
	static getDerivedStateFromProps(nextProps, state) {
		let { preloadedTemplate } = nextProps
		if (state.mounted) {
			return state
		}
		if (preloadedTemplate) {
			let preloadedContent = convertFromRaw(preloadedTemplate.templateBody)
			return {
				mounted: true,
				editorState: EditorState.createWithContent(preloadedContent, variableDecorator),
			}
		} else {
			return {
				mounted: true,
				editorState: EditorState.createEmpty(variableDecorator),
			}
		}
	}
	toggleVariables = () => {
		this.setState(state => ({ showVariables: !state.showVariables }))
	}
	handleChanges = editorState => {
		this.setState({ editorState })
	}
	handleTemplateMetaChange = e => {
		let { name, value } = e.target
		this.props.handleTemplateMetaChange({ [name]: value })
	}
	toggleBlockType = blockType => {
		this.handleChanges(RichUtils.toggleBlockType(this.state.editorState, blockType))
	}
	toggleInlineStyle = inlineStyle => {
		this.handleChanges(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle))
	}
	handleKeyCommand = (command, editorState) => {
		const newState = RichUtils.handleKeyCommand(editorState, command)
		if (newState) {
			this.handleChanges(newState)
			return true
		}
		return false
	}
	mapKeyToEditorCommand = e => {
		if (e.keyCode === 9 /* TAB */) {
			const newEditorState = RichUtils.onTab(e, this.state.editorState, 4 /* maxDepth */)
			if (newEditorState !== this.state.editorState) {
				this.handleChanges(newEditorState)
			}
			return
		}
		return getDefaultKeyBinding(e)
	}
	addLogo = (e, alignment) => {
		e.preventDefault()
		let { currAffiliate } = this.props
		let url = currAffiliate.fullSizeLogoUrl
		//let url = 'https://www.google.be/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png'
		// let url = arrowRightIcon
		if (this.checkImage(url)) {
			this.addMedia(url, "image", alignment)
		}
	}
	checkImage = url => async () => {
		var request = new XMLHttpRequest()
		request.open("GET", url, true)
		request.send()
		request.onload = function () {
			if (request.status === 200) {
				return true
			} else {
				return false
			}
		}
	}

	addMedia = (urlValue, urlType, alignment) => {
		const { editorState } = this.state
		const contentState = editorState.getCurrentContent()
		const contentStateWithEntity = contentState.createEntity(urlType, "IMMUTABLE", {
			src: urlValue,
			alignment,
		})
		const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
		const newEditorState = EditorState.set(editorState, {
			currentContent: contentStateWithEntity,
		})

		this.setState({
			editorState: AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " "),
		})
	}
	insertVariable = e => {
		e.preventDefault()
		let { vvalue } = e.target.dataset
		const { editorState } = this.state
		let { variables } = this.props
		let variable = variables.find(entry => entry.value === vvalue)
		const contentState = editorState.getCurrentContent()
		const selection = editorState.getSelection()
		const translatedVariable = this.context.t(variable.label)
		const contentStateWithEntity = contentState.createEntity("VARIABLE", "IMMUTABLE", {
			variable: variable.value,
			translatedVariable,
		})
		const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
		const textWithEntity = Modifier.replaceText(
			contentStateWithEntity,
			selection,
			`{{${variable.value}}}`,
			null,
			entityKey
		)
		const newEditorState = EditorState.push(editorState, textWithEntity, "insert-characters")
		this.setState({ editorState: newEditorState }, () => {
			this.editorRef.focus()
		})
		this.toggleVariables()
	}
	mediaBlockRenderer = block => {
		const type = block.getType()
		if (type === "atomic") {
			return { component: Image, editable: false }
		} else if (type === "text_align_right") {
			return { component: RightAligned }
		} else if (type === "text_align_center") {
			return { component: CenterAligned }
		}
	}
	handleSubmit = () => {
		let { onSubmit, templateName, templateTarget } = this.props
		let { editorState } = this.state
		if (!templateName || !templateTarget) {
			return
		}
		onSubmit({ templateBody: convertToRaw(editorState.getCurrentContent()) })
	}
	render() {
		let { t } = this.context
		let { closeEditor, variables, templateName, templateTarget } = this.props
		const { editorState, showVariables } = this.state
		const selection = editorState.getSelection()
		const blockType = editorState
			.getCurrentContent()
			.getBlockForKey(selection.getStartKey())
			.getType()

		const currentStyle = editorState.getCurrentInlineStyle()
		let buttons = (
			<Fragment>
				<PootsyButton text={t("submit")} onClick={this.handleSubmit} />
				<PootsyButton text={t("cancel")} theme="cancel" onClick={closeEditor} />
			</Fragment>
		)
		return (
			<ModalLayout formDivClass="draft" buttons={buttons}>
				<div className="top">
					<PootsyTextInput
						name="templateName"
						label={t("template_name")}
						value={templateName}
						onChange={this.handleTemplateMetaChange}
					/>
					<PootsySelectInput
						name="templateTarget"
						defaultText={t("template_target")}
						selected={templateTarget}
						onChange={this.handleTemplateMetaChange}
						options={[
							{ label: t("customer"), value: "customer" },
							{ label: t("worker"), value: "worker" },
						]}
					/>
				</div>
				<div className="draft-editor">
					<div className="toolbar">
						<div className="left">
							{BLOCK_TYPES.map(type => (
								<DraftEditorStyleButton
									key={type.style}
									icon={type.icon}
									active={type.style === blockType}
									label={t(type.label)}
									onToggle={this.toggleBlockType}
									style={type.style}
								/>
							))}
							{INLINE_STYLES.map(type => (
								<DraftEditorStyleButton
									key={type.style}
									active={currentStyle.has(type.style)}
									icon={type.icon}
									onToggle={this.toggleInlineStyle}
									style={type.style}
								/>
							))}
						</div>
						<div className="right">
							<div className="add-logo-buttons">
								<img className="img-icon" src={imgIcon} alt="img-icon" />
								<div
									className="add-button"
									onMouseDown={e => this.addLogo(e, "flex-start")}
								>
									{t("left")}
								</div>
								<div
									className="add-button"
									onMouseDown={e => this.addLogo(e, "center")}
								>
									{t("center")}
								</div>
								<div
									className="add-button"
									onMouseDown={e => this.addLogo(e, "flex-end")}
								>
									{t("right")}
								</div>
							</div>
							<div className="variables">
								<div
									className={
										"variables-toggle" + (showVariables ? " toggled" : "")
									}
									onClick={this.toggleVariables}
								>
									<img className="arrow-img" src={arrowRightIcon} alt="arrow" />{" "}
									{t("templates_variables")}
								</div>
								{showVariables && (
									<div className="variables-list">
										{variables.map(entry => (
											<div
												key={entry.value}
												className="variable"
												onMouseDown={this.insertVariable}
												data-vvalue={entry.value}
											>
												{t(entry.label)}
											</div>
										))}
									</div>
								)}
							</div>
						</div>
					</div>
					<Editor
						ref={ref => (this.editorRef = ref)}
						editorState={editorState}
						blockRendererFn={this.mediaBlockRenderer}
						onChange={this.handleChanges}
						keyBindingFn={this.mapKeyToEditorCommand}
						handleKeyCommand={this.handleKeyCommand}
					/>
				</div>
			</ModalLayout>
		)
	}
}

DraftEditor.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currAffiliate: state.redData.currentAffiliate,
})
export default connect(mapStateToProps)(DraftEditor)
