import React, { Component } from "react"
import { PootsyMultiSelect } from "./FunctionalInputs"

export default class Filter extends Component {
	constructor(props) {
		super(props)
		this.state = {
			search: "",
			searchResults: [],
		}
	}
	handleFilterChange = e => {
		e.stopPropagation()
		let search = e.target.value.toLowerCase()
		if (search.length < 1) {
			this.setState({ searchResults: [], search: "" })
			return
		}
		let searchResults = this.props.possibleValues.filter(entry =>
			entry.label.toLowerCase().includes(search)
		)
		this.setState({ search: e.target.value, searchResults: searchResults })
	}
	handleFilterClick = e => {
		let {
			key,
			target: {
				dataset: { value },
			},
		} = e
		let { name, currentFilters, onChange } = this.props
		if (key === undefined || key === "Enter") {
			let newFilters = [...currentFilters]
			if (newFilters.indexOf(value) === -1) {
				// Adding value only if not already in the current filters
				newFilters.push(value)
			}
			onChange({ name: name, value: newFilters })
			this.setState({ search: "", searchResults: [] })
		}
	}
	handleRemoveFilter = e => {
		let { value } = e.target.dataset
		let { name, currentFilters, onChange } = this.props
		let newFilters = currentFilters.filter(entry => entry !== value)
		onChange({ name: name, value: newFilters })
	}
	render() {
		let { label, name, currentFilters, possibleValues, showRequired } = this.props
		let { search, searchResults } = this.state
		let newFilters = []
		newFilters = possibleValues.filter(entry => currentFilters.includes(entry.value.toString()))
		return (
			<PootsyMultiSelect
				label={label}
				name={name}
				searchValue={search}
				searchResults={searchResults}
				onChange={this.handleFilterChange}
				onResultClick={this.handleFilterClick}
				selectedValues={newFilters}
				handleRemoveValue={this.handleRemoveFilter}
				showRequired={showRequired}
			/>
		)
	}
}

Filter.defaultProps = {
	currentFilters: [],
	showRequired: false,
}
