import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { showIndexation } from "../config"

import * as actions from "../actions"

import InputsChangeHandler from "./InputsChangeHandler"
import { SecSocInput } from "./SecSocInput"
import SalariesIndexingForm from "./SalariesIndexingForm"
import { ContentLayout, PootsySubHeading } from "./FunctionalDesign"
import { PootsySelectInput, PootsyTextInput, PootsyCheckboxInput } from "./FunctionalInputs"
import { socialSecretariesFields, translationPrefixes } from "../constants/socialSecretariesFields"

class SettingsSecSoc extends Component {
	handleFormChanges = changes => {
		this.props.dispatch(actions.editSettings(changes))
	}
	handleExpensesChange = changes => {
		let { mealVoucherConfig, travelExpConfig, clothesExpConfig } = this.props.currAffiliate
		let newMvConfig = { ...mealVoucherConfig },
			newTeConfig = { ...travelExpConfig },
			newCEconfig = { ...clothesExpConfig }
		let keyChanged = Object.keys(changes)[0]
		if (
			[
				"meal_voucher_automatic",
				"meal_voucher_rule",
				"meal_voucher_use_different_codes",
			].includes(keyChanged)
		) {
			if (keyChanged === "meal_voucher_automatic") {
				newMvConfig.automatic = changes[keyChanged]
			} else if (keyChanged === "meal_voucher_rule") {
				newMvConfig.rule = changes[keyChanged]
			} else if (keyChanged === "meal_voucher_use_different_codes") {
				newMvConfig.use_different_codes = changes[keyChanged]
			} else {
				newMvConfig[keyChanged] = changes[keyChanged]
			}
			this.handleFormChanges({ mealVoucherConfig: newMvConfig })
			return
		} else if (
			[
				"travel_expense_automatic",
				"last_customer_home_own_transport",
				"last_customer_home_public_transport",
				"last_customer_home_bike",
				"last_customer_home_company_car",
				"last_customer_home_others",
				"bike_amount_per_km",
				"public_transport_amount_per_km",
				"max_hours_between_clients",
				"car_home_client_rate",
				"cc_car_less_15_rate",
				"cc_car_more_15_rate",
				"travel_time_rate",
				"travel_time_minimum",
			].includes(keyChanged)
		) {
			if (keyChanged === "travel_expense_automatic") {
				newTeConfig.automatic = changes[keyChanged]
			} else if (
				[
					"last_customer_home_own_transport",
					"last_customer_home_public_transport",
					"last_customer_home_bike",
					"last_customer_home_company_car",
					"last_customer_home_others",
				].includes(keyChanged)
			) {
				newTeConfig[keyChanged] = changes[keyChanged] // otherwise it sends "null" to backend instead of false
			} else {
				newTeConfig[keyChanged] = changes[keyChanged]
			}
			this.handleFormChanges({ travelExpConfig: newTeConfig })
			return
		} else if (["clothes_expenses_rule", "clothes_expenses_automatic"].includes(keyChanged)) {
			if (keyChanged === "clothes_expenses_automatic") {
				newCEconfig.automatic = changes[keyChanged]
			} else if (keyChanged === "clothes_expenses_rule") {
				newCEconfig.rule = changes[keyChanged]
			} else {
				newCEconfig[keyChanged] = changes[keyChanged]
			}
			this.handleFormChanges({ clothesExpConfig: newCEconfig })
			return
		}
	}

	handlePrefillChanges = changes => {
		let { secSocPrefillWorkerContract } = this.props.currAffiliate
		let newsecSocPrefillWorkerContract = { ...secSocPrefillWorkerContract }
		let keyChanged = Object.keys(changes)[0]
		newsecSocPrefillWorkerContract[keyChanged] = changes[keyChanged]
		this.handleFormChanges({ secSocPrefillWorkerContract: newsecSocPrefillWorkerContract })
	}

	handleSeparator = value => {
		if (!value) {
			return ""
		}
		return value.toString().replace(".", ",")
	}

	render() {
		let { t } = this.context
		let { currAffiliate, currUser } = this.props
		let secSocInputs = socialSecretariesFields[currAffiliate.secSocName] || []
		let translationPrefix = translationPrefixes[currAffiliate.secSocName] || ""
		let prefillableInputs = secSocInputs
			.map(category => category.inputs.filter(input => input.secSocPrefillable))
			.flat()
		return (
			<ContentLayout customClass="settings-secsoc">
				<InputsChangeHandler
					customClass="inputs-card expenses"
					onChange={this.handleExpensesChange}
				>
					<PootsySubHeading text={t("settings_auto_expenses_title")} />
					<div className="section meal-vouchers">
						<PootsyCheckboxInput
							label={t("meal_voucher_automatic")}
							name="meal_voucher_automatic"
							checked={currAffiliate.mealVoucherConfig.automatic}
						/>
						<PootsyCheckboxInput
							label={t("meal_voucher_use_different_codes")}
							name="meal_voucher_use_different_codes"
							checked={currAffiliate.mealVoucherConfig.use_different_codes}
						/>
						<PootsySelectInput
							name="meal_voucher_rule"
							defaultText={t("meal_voucher_rule")}
							selected={currAffiliate.mealVoucherConfig.rule}
							options={[
								{ label: t("minimum_4_hours"), value: "minimum_4_hours" },
								{ label: t("minimum_1_hour"), value: "minimum_1_hour" },
								{ label: t("worked_days_7_60"), value: "worked_days_7_60" },
							]}
						/>
					</div>
					<div className="selection clothes-expenses">
						<PootsyCheckboxInput
							label={t("clothes_expenses_automatic")}
							name="clothes_expenses_automatic"
							checked={currAffiliate.clothesExpConfig.automatic}
						/>
						<PootsySelectInput
							name="clothes_expenses_rule"
							defaultText={t("clothes_expenses_rule")}
							selected={currAffiliate.clothesExpConfig.rule}
							options={[
								{ label: t("cp_038_per_day"), value: "cp_038_per_day" },
								{ label: t("cp_026_per_day"), value: "cp_026_per_day" },
								{ label: t("cp_012_per_day"), value: "cp_012_per_day" },
							]}
						/>
					</div>
					<div className="section travel-expenses">
						<PootsyCheckboxInput
							label={t("travel_expense_automatic")}
							name="travel_expense_automatic"
							checked={currAffiliate.travelExpConfig.automatic}
						/>
						<PootsyCheckboxInput
							label={t("last_customer_home_own_transport")}
							name="last_customer_home_own_transport"
							checked={currAffiliate.travelExpConfig.last_customer_home_own_transport}
						/>
						<PootsyCheckboxInput
							label={t("last_customer_home_public_transport")}
							name="last_customer_home_public_transport"
							checked={
								currAffiliate.travelExpConfig.last_customer_home_public_transport
							}
						/>
						<PootsyCheckboxInput
							label={t("last_customer_home_bike")}
							name="last_customer_home_bike"
							checked={currAffiliate.travelExpConfig.last_customer_home_bike}
						/>
						<PootsyCheckboxInput
							label={t("last_customer_home_company_car")}
							name="last_customer_home_company_car"
							checked={currAffiliate.travelExpConfig.last_customer_home_company_car}
						/>
						<PootsyCheckboxInput
							label={t("last_customer_home_others")}
							name="last_customer_home_others"
							checked={currAffiliate.travelExpConfig.last_customer_home_others}
						/>
						<PootsyTextInput
							label={t("bike_amount_per_km")}
							name="bike_amount_per_km"
							value={this.handleSeparator(
								currAffiliate.travelExpConfig.bike_amount_per_km
							)}
						/>
						<PootsyTextInput
							label={t("public_transport_amount_per_km")}
							name="public_transport_amount_per_km"
							value={this.handleSeparator(
								currAffiliate.travelExpConfig.public_transport_amount_per_km
							)}
						/>
						<PootsyTextInput
							label={t("car_home_client_rate")}
							name="car_home_client_rate"
							value={this.handleSeparator(
								currAffiliate.travelExpConfig.car_home_client_rate
							)}
						/>
						<PootsyTextInput
							label={t("cc_car_less_15_rate")}
							name="cc_car_less_15_rate"
							value={this.handleSeparator(
								currAffiliate.travelExpConfig.cc_car_less_15_rate
							)}
						/>
						<PootsyTextInput
							label={t("cc_car_more_15_rate")}
							name="cc_car_more_15_rate"
							value={this.handleSeparator(
								currAffiliate.travelExpConfig.cc_car_more_15_rate
							)}
						/>
						<PootsyTextInput
							label={t("travel_time_rate")}
							name="travel_time_rate"
							value={this.handleSeparator(
								currAffiliate.travelExpConfig.travel_time_rate
							)}
						/>
						<PootsyTextInput
							label={t("travel_time_minimum")}
							name="travel_time_minimum"
							value={this.handleSeparator(
								currAffiliate.travelExpConfig.travel_time_minimum
							)}
						/>
						<PootsyTextInput
							label={t("max_hours_between_clients")}
							name="max_hours_between_clients"
							value={this.handleSeparator(
								currAffiliate.travelExpConfig.max_hours_between_clients
							)}
						/>
					</div>
				</InputsChangeHandler>
				<InputsChangeHandler
					customClass="inputs-card contracts"
					onChange={this.handlePrefillChanges}
				>
					<PootsySubHeading text={t("settings_prefill_worker_contract_title")} />
					<div className="section sec-soc-prefill-worker-contract">
						{prefillableInputs.map(selectedInput => {
							selectedInput.required = false
							return (
								<SecSocInput
									key={selectedInput.label}
									input={selectedInput}
									values={currAffiliate.secSocPrefillWorkerContract}
									translationPrefix={translationPrefix}
								/>
							)
						})}
					</div>
				</InputsChangeHandler>
				{currAffiliate.secSocSpecificFields.length > 0 && (
					<InputsChangeHandler
						customClass="inputs-card specific-fields"
						onChange={this.handleFormChanges}
					>
						{currAffiliate.secSocSpecificFields.map(entry => (
							<PootsyTextInput
								key={entry.reference}
								label={t(entry.label)}
								name={entry.reference}
								maxLength={entry.length}
								value={currAffiliate[entry.reference] || ""}
							/>
						))}
					</InputsChangeHandler>
				)}
				{showIndexation && currUser.isMaster && (
					<InputsChangeHandler
						customClass="inputs-card expenses"
						onChange={this.handleIndexationChanges}
					>
						<PootsySubHeading text={t("indexation_salarial")} />
						<SalariesIndexingForm />
					</InputsChangeHandler>
				)}
			</ContentLayout>
		)
	}
}

SettingsSecSoc.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currAffiliate: state.redData.currentAffiliate,
	currUser: state.redData.currentUser,
})
export default connect(mapStateToProps)(SettingsSecSoc)
