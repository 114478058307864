const countriesList = [
	{ label: "BELGIE", value: "000" },
	{ label: "NEDERLAND", value: "001" },
	{ label: "DUITSLAND (BONDSREPUBLIEK)", value: "002" },
	{ label: "GROOTHERTOGDOM LUXEMBURG", value: "003" },
	{ label: "FRANKRIJK", value: "004" },
	{ label: "ITALIE", value: "005" },
	{ label: "GROOT-BRITTANNIE", value: "006" },
	{ label: "GHANA", value: "007" },
	{ label: "PANAMA", value: "008" },
	{ label: "NIGERIA (BONDSREPUBLIEK)", value: "009" },
	{ label: "DEMOCRATISCHE REPUBLIEK MADAGASCAR", value: "010" },
	{ label: "ZWITSERLAND", value: "013" },
	{ label: "POLEN", value: "015" },
	{ label: "GRIEKENLAND", value: "016" },
	{ label: "VERENIGDE STATEN (U.S.A.)", value: "017" },
	{ label: "PORTUGAL", value: "018" },
	{ label: "JOEGOSLAVIE", value: "019" },
	{ label: "DOMINIKAANSE REPUBLIEK", value: "020" },
	{ label: "HONGARIJE (REP)", value: "021" },
	{ label: "SPANJE", value: "022" },
	{ label: "NOORWEGEN", value: "024" },
	{ label: "LIBANON", value: "025" },
	{ label: "MAROKKO", value: "026" },
	{ label: "EGYPTE", value: "027" },
	{ label: "OOSTENRIJK", value: "028" },
	{ label: "ROEMENIE", value: "029" },
	{ label: "PARAGUAY", value: "030" },
	{ label: "LAOS", value: "031" },
	{ label: "ISRAEL", value: "032" },
	{ label: "MEXICO", value: "033" },
	{ label: "TURKIJE", value: "034" },
	{ label: "CANADA", value: "035" },
	{ label: "CHINA / VOLKSREP.", value: "036" },
	{ label: "ZWEDEN", value: "037" },
	{ label: "PERU", value: "038" },
	{ label: "IRAN", value: "039" },
	{ label: "ANGOLA", value: "040" },
	{ label: "CHILI", value: "041" },
	{ label: "ARGENTINIE", value: "042" },
	{ label: "BULGARIJE", value: "043" },
	{ label: "DENEMARKEN", value: "044" },
	{ label: "SAOEDI-ARABIE", value: "045" },
	{ label: "DEMOCRATISCHE REPUBLIEK CONGO", value: "046" },
	{ label: "FINLAND", value: "047" },
	{ label: "ALGERIJE", value: "048" },
	{ label: "BURUNDI", value: "050" },
	{ label: "CUBA", value: "051" },
	{ label: "AUSTRALIE", value: "052" },
	{ label: "IERLAND", value: "053" },
	{ label: "ECUADOR", value: "054" },
	{ label: "SAN MARINO", value: "055" },
	{ label: "TUNESIE", value: "056" },
	{ label: "INDONESIE", value: "057" },
	{ label: "URUGUAY", value: "058" },
	{ label: "TOGO", value: "059" },
	{ label: "RWANDA", value: "060" },
	{ label: "KAMEROEN", value: "061" },
	{ label: "ZUID-AFRIKA", value: "062" },
	{ label: "BRAZILIE", value: "063" },
	{ label: "MALEISIE", value: "064" },
	{ label: "NAMIBIE", value: "065" },
	{ label: "ZAMBIA", value: "066" },
	{ label: "NIEUW-CALEDONIE", value: "067" },
	{ label: "COLOMBIA", value: "068" },
	{ label: "FILIPPIJNEN", value: "069" },
	{ label: "HAITI", value: "070" },
	{ label: "LIBERIA", value: "071" },
	{ label: "LIBIE", value: "072" },
	{ label: "FRANS GUYANA", value: "074" },
	{ label: "AFGHANISTAN", value: "075" },
	{ label: "BOSNIE-HERZEGOVINA", value: "076" },
	{ label: "LA MARTINIQUE (F.)", value: "077" },
	{ label: "MONACO", value: "078" },
	{ label: "COSTA RICA", value: "079" },
	{ label: "MACEDONIE", value: "080" },
	{ label: "NIEUW-ZEELAND", value: "081" },
	{ label: "SINGAPORE", value: "082" },
	{ label: "DJIBOUTI (REPUBLIEK)", value: "083" },
	{ label: "ANDORRA", value: "084" },
	{ label: "ZIMBABWE", value: "085" },
	{ label: "ANTIGUA", value: "086" },
	{ label: "VENEZUELA", value: "087" },
	{ label: "JORDANIE", value: "089" },
	{ label: "VER.ARABISCHE EMIRATEN", value: "090" },
	{ label: "JEMEN", value: "091" },
	{ label: "INDIA", value: "093" },
	{ label: "PAKISTAN", value: "094" },
	{ label: "ANGUILLA", value: "095" },
	{ label: "AMERIK.SAMOA EILANDEN", value: "096" },
	{ label: "BARBADOS", value: "097" },
	{ label: "BAHREIN", value: "098" },
	{ label: "BENIN", value: "099" },
	{ label: "BRUNEI", value: "100" },
	{ label: "BHUTAN", value: "101" },
	{ label: "SYRIE", value: "102" },
	{ label: "SENEGAL", value: "103" },
	{ label: "BOTSWANA", value: "104" },
	{ label: "BOLIVIE", value: "105" },
	{ label: "BELIZE", value: "106" },
	{ label: "CENTRAALAFRIKAANSE REPUBLIEK", value: "107" },
	{ label: "ALBANIE", value: "108" },
	{ label: "COOK (N-Z)", value: "110" },
	{ label: "CYPRUS", value: "111" },
	{ label: "KAAPVERDISCHE EILANDEN", value: "112" },
	{ label: "MAURITIUS", value: "113" },
	{ label: "ERITHREA", value: "114" },
	{ label: "ETHIOPIE", value: "115" },
	{ label: "FIJI", value: "116" },
	{ label: "GRENADA", value: "117" },
	{ label: "GUINEA", value: "118" },
	{ label: "IRAK", value: "119" },
	{ label: "EQUATORIAL GUINEA", value: "120" },
	{ label: "GUATEMALA", value: "121" },
	{ label: "GUINEA-BISSAU", value: "122" },
	{ label: "GUYANA", value: "123" },
	{ label: "HONDURAS", value: "124" },
	{ label: "JAMAICA", value: "125" },
	{ label: "SINT KITTS & NEVIS", value: "126" },
	{ label: "SRI LANKA", value: "127" },
	{ label: "LESOTHO", value: "128" },
	{ label: "MALI", value: "129" },
	{ label: "IJSLAND", value: "131" },
	{ label: "MAURETANIE", value: "132" },
	{ label: "SOCIALISTISCHE REPUBLIEK VIETNAM", value: "133" },
	{ label: "JAPAN", value: "134" },
	{ label: "BAHAMAS", value: "135" },
	{ label: "MONTSERRAT", value: "136" },
	{ label: "MALTA", value: "137" },
	{ label: "MALEDIVEN", value: "138" },
	{ label: "MALAWI", value: "139" },
	{ label: "NEPAL", value: "140" },
	{ label: "NAURU", value: "141" },
	{ label: "NIUE EILAND", value: "142" },
	{ label: "PALAU", value: "143" },
	{ label: "QATAR", value: "144" },
	{ label: "SEYCHELLES", value: "145" },
	{ label: "SINT HELENA", value: "146" },
	{ label: "SURINAME", value: "147" },
	{ label: "SWAZILAND", value: "148" },
	{ label: "TOKELAU EILAND", value: "149" },
	{ label: "NED. ANTILLEN", value: "150" },
	{ label: "TONGA", value: "151" },
	{ label: "TRINIDAD EN TOBAGO", value: "153" },
	{ label: "TUVALU", value: "154" },
	{ label: "SAINT VINCENT", value: "155" },
	{ label: "VANUATU", value: "156" },
	{ label: "WALLIS EN FUTUNA", value: "157" },
	{ label: "WEST SAMOA", value: "158" },
	{ label: "GABON", value: "159" },
	{ label: "TANZANIA", value: "160" },
	{ label: "MONGOLIE (VOLKSREPUBLIEK)", value: "162" },
	{ label: "NIGER", value: "163" },
	{ label: "MONTENEGRO", value: "164" },
	{ label: "SERVIE", value: "165" },
	{ label: "WIT-RUS", value: "166" },
	{ label: "ARMENIE (REP)", value: "200" },
	{ label: "AZERBEIDZJAN (REP)", value: "201" },
	{ label: "BIELORUSLAND", value: "202" },
	{ label: "CAMBODJA", value: "203" },
	{ label: "BANGLADESH", value: "204" },
	{ label: "CHINA / TAIWAN (REP)", value: "205" },
	{ label: "CROATIE (REP)", value: "206" },
	{ label: "GEORGIE (REP)", value: "207" },
	{ label: "VOLKSREPUBLIEK CHINA", value: "208" },
	{ label: "KAZAKHSTAN (REP)", value: "209" },
	{ label: "KIRGIZSTAN (REP)", value: "211" },
	{ label: "ZUID-KOREA (REP)", value: "212" },
	{ label: "LITOUWEN", value: "213" },
	{ label: "SULTANAAT OMAN", value: "214" },
	{ label: "MOLDAVIE (REP)", value: "215" },
	{ label: "NOORD-KOREA (VOLKSREP)", value: "216" },
	{ label: "OEKRAINE", value: "217" },
	{ label: "OEZBEKISTAN (REP)", value: "218" },
	{ label: "RUSLAND (FEDERATIE VAN)", value: "219" },
	{ label: "SLOVAAKSE REPUBLIEK", value: "220" },
	{ label: "SLOVENIE (REP)", value: "221" },
	{ label: "TADZJIKISTAN (REP)", value: "222" },
	{ label: "TJECHISCHE REPUBLIEK", value: "223" },
	{ label: "TURKMENISTAN (REP)", value: "224" },
	{ label: "LIECHTENSTEIN", value: "225" },
	{ label: "LETLAND", value: "226" },
	{ label: "THAILAND", value: "227" },
	{ label: "KOEWEIT", value: "228" },
	{ label: "SIERRA LEONE", value: "229" },
	{ label: "BERMUDA", value: "230" },
	{ label: "BURKINA-FASO", value: "232" },
	{ label: "AFKOMSTIG VER.ARABISCHE EMIRATEN", value: "236" },
	{ label: "ARCHIPEL VAN DE COMOREN", value: "238" },
	{ label: "BIRMA", value: "239" },
	{ label: "HEILIGE STOEL", value: "240" },
	{ label: "KAAIMAN EILANDEN", value: "243" },
	{ label: "EL SALVADOR", value: "248" },
	{ label: "EQUATORIAAL GUINEA", value: "249" },
	{ label: "ESTLAND", value: "250" },
	{ label: "FALKLAND EILANDEN", value: "251" },
	{ label: "FIDJI", value: "253" },
	{ label: "GABOEN", value: "255" },
	{ label: "GAMBIA", value: "256" },
	{ label: "GIBRALTAR", value: "257" },
	{ label: "GROENLAND (D.K.)", value: "259" },
	{ label: "GUAM", value: "260" },
	{ label: "HONG-KONG", value: "262" },
	{ label: "KENYA", value: "263" },
	{ label: "LA GUADELOUPE (F.)", value: "264" },
	{ label: "MACAO", value: "266" },
	{ label: "MOZAMBIQUE", value: "268" },
	{ label: "NICARAGUA", value: "272" },
	{ label: "UGANDA", value: "274" },
	{ label: "OEZBEKISTAN", value: "275" },
	{ label: "PALESTINIE", value: "277" },
	{ label: "PITCAIRN", value: "278" },
	{ label: "POLYNESIE", value: "279" },
	{ label: "PORTO RICO EILAND", value: "280" },
	{ label: "SANTA LUCIA", value: "282" },
	{ label: "REUNION", value: "283" },
	{ label: "SALOMON EILANDEN", value: "284" },
	{ label: "SOEDAN", value: "285" },
	{ label: "SOMALIE", value: "286" },
	{ label: "TSJAAD", value: "287" },
	{ label: "SAO TOME EN PRINCIPE", value: "290" },
	{ label: "WEST-SAMOA", value: "291" },
	{ label: "ANTARCTICA", value: "293" },
	{ label: "KOSOVO", value: "294" },
	{ label: "MYANMAR", value: "295" },
	{ label: "IVOORKUST", value: "309" },
	{ label: "ONBEPAALD", value: "995" },
]

export default [
	{
		categoryName: "personal_data",
		inputs: [
			{
				label: "civil_status",
				inputType: "select",
				required: true,
				options: [
					{ label: "celibataire", value: 1 },
					{ label: "marie", value: 2 },
					{ label: "veuf", value: 3 },
					{ label: "divorce", value: 4 },
					{ label: "separe_de_corps", value: 5 },
					{ label: "separe_de_fait", value: 6 },
					{ label: "cohabitant_legal", value: 7 },
				],
			},
			{
				label: "birthdate",
				inputType: "datepicker",
				required: true,
			},
			{
				label: "nationality",
				inputType: "select",
				required: true,
				skipOptionsTranslationPrefix: true,
				options: countriesList,
			},
			{
				label: "birthplace_locality",
				inputType: "text",
				required: true,
			},
			{
				label: "birthplace_country",
				inputType: "select",
				required: true,
				skipOptionsTranslationPrefix: true,
				options: countriesList,
			},
			{
				label: "number_labour_licence",
				inputType: "text",
				required: false,
			},
			{
				label: "date_start_labour_licence",
				inputType: "datepicker",
				required: false,
				show: values => !!values.number_labour_licence,
			},
			{
				label: "date_end_labour_licence",
				inputType: "datepicker",
				required: false,
				show: values => !!values.number_labour_licence,
			},
		],
	},
	{
		categoryName: "partner_data",
		show: values => ["2", "5", "6", "7"].includes(values.civil_status),
		inputs: [
			{
				label: "spouse_name",
				inputType: "text",
				required: false,
			},
			{
				label: "spouse_birthdate",
				inputType: "datepicker",
				required: false,
			},
			{
				label: "spouse_profesionnal_category",
				inputType: "select",
				options: [
					{ label: "spc_ouvrier", value: 1 },
					{ label: "gens_de_maison", value: 2 },
					{ label: "sp_employe", value: 3 },
					{ label: "independant", value: 4 },
					{ label: "spc_mineur", value: 5 },
					{ label: "marin", value: 6 },
					{ label: "spc_fonctionnaire", value: 7 },
					{ label: "autres", value: 8 },
					{ label: "sans_profession", value: 9 },
				],
				required: false,
			},
			{
				label: "dependant_spouse",
				inputType: "select",
				options: [
					{ label: "no", value: "0" },
					{ label: "yes", value: "1" },
				],
				required: false,
			},
			{
				label: "dependant_disabled_spouse",
				inputType: "select",
				options: [
					{ label: "no", value: "0" },
					{ label: "yes", value: "1" },
				],
				required: false,
				show: values => values.dependant_spouse === "1",
			},
		],
	},
	{
		categoryName: "contract",
		inputs: [
			{
				label: "documents_language",
				inputType: "select",
				required: true,
				secSocPrefillable: true,
				options: [
					{ label: "dlv_neerlandais", value: "N" },
					{ label: "dlv_francais", value: "F" },
					{ label: "dlv_allemand", value: "D" },
				],
				default: (currentWorker, secSocPrefill) => secSocPrefill.documents_language,
			},
			{
				label: "contract_type",
				inputType: "select",
				required: true,
				options: [
					{ label: "ct_pas_application", value: 0 },
					{ label: "duree_indeterminee", value: 1 },
					{ label: "duree_determinee", value: 2 },
					{ label: "travail_determine", value: 3 },
					{ label: "contrat_de_remplacement", value: 4 },
					{ label: "contrat_intermediaire", value: 5 },
					{ label: "inconnu", value: 9 },
				],
			},
			{
				label: "entry_date",
				inputType: "datepicker",
				required: true,
			},
			{
				label: "seniority_company_date",
				inputType: "datepicker",
				required: true,
			},
			{
				label: "seniority_sector_date",
				inputType: "datepicker",
				required: true,
			},
			{
				label: "reason_entry",
				inputType: "select",
				required: true,
				options: [
					{ label: "nouveau_travailleur", value: "01" },
					{ label: "pas_calcul_acc_emploi", value: "03" },
					{ label: "changement_dep_cat_bilan_soc.", value: "04" },
				],
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) => secSocPrefill.reason_entry,
			},
			{
				label: "code_effectively_started",
				inputType: "select",
				required: true,
				options: [
					{ label: "contrat_pas_commence", value: 0 },
					{ label: "contrat_commence", value: 1 },
				],
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) => secSocPrefill.code_effectively_started,
			},
			{
				label: "special_status",
				inputType: "select",
				required: true,
				options: [
					{ label: "spv_aucun", value: "00" },
					{ label: "titres_services", value: "01" },
				],
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) => secSocPrefill.special_status,
			},
			{
				label: "foreseen_contract_end_date",
				inputType: "datepicker",
				required: false,
				show: values => values.contract_type === "2",
			},
		],
	},
	{
		categoryName: "end_of_contract",
		show: values => values.endDate && values.endDate.isValid(),
		inputs: [
			{
				label: "end_reason",
				inputType: "select",
				required: (values, currentAffiliate, currentContract, workerContracts) =>
					end_reason_required(currentAffiliate, values, currentContract, workerContracts),
				options: [
					{ label: "force_majeure", value: "B" },
					{ label: "de_commun_accord", value: "C" },
					{ label: "licenciement_collectif", value: "D" },
					{ label: "mod_unilat_employeur", value: "E" },
					{ label: "mod_unilat_travailleur", value: "F" },
					{ label: "faillite", value: "G" },
					{ label: "pr_pens_sans_remun", value: "H" },
					{ label: "pension_avec_rem", value: "I" },
					{ label: "raison_inconnue", value: "0" },
					{ label: "preavis_employeur", value: "1" },
					{ label: "preavis_travailleur", value: "2" },
					{ label: "rupture_contrat", value: "3" },
					{ label: "pension_sans_ind", value: "4" },
					{ label: "deces", value: "5" },
					{ label: "prepension_avec_ind", value: "6" },
					{ label: "fin_du_contrat", value: "7" },
					{ label: "autre_firme_dep_cat", value: "8" },
					{ label: "faute_grave", value: "9" },
				],
			},
			{
				label: "date_end_prior_notice",
				inputType: "datepicker",
				required: false,
				show: values => ["3", "C"].includes(values.end_reason),
			},
		],
	},
	{
		categoryName: "fiscal_data",
		inputs: [
			{
				label: "social_system",
				inputType: "select",
				required: true,
				options: [
					{ label: "travailleur_assujetti_complet", value: "01" },
					{ label: "fonctionnaire", value: "02" },
					{ label: "mineur", value: "03" },
					{ label: "territoires_outre_mer", value: "04" },
					{ label: "batellerie", value: "05" },
				],
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) => secSocPrefill.social_system,
			},
			{
				label: "fiscal_status",
				inputType: "select",
				required: true,
				options: [
					{ label: "fs_pas_application", value: "00" },
					{ label: "regime_impots_normal", value: "01" },
					{ label: "cadre_etranger", value: "02" },
					{ label: "travailleur_frontalier", value: "03" },
					{ label: "pensionne", value: "04" },
					{ label: "prepensionne", value: "05" },
					{ label: "travailleur_detache", value: "06" },
					{ label: "dirigeant_dentreprise", value: "07" },
					{ label: "honoraires", value: "09" },
					{ label: "aide_independant", value: "10" },
					{ label: "jetons_de_presence", value: "11" },
				],
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) => secSocPrefill.fiscal_status,
			},
			{
				label: "category",
				inputType: "select",
				required: true,
				options: [
					{ label: "pas_de_categorie_specific", value: "00" },
					{ label: "ouvrier", value: "10" },
					{ label: "ouvrier_moins_valide", value: "11" },
					{ label: "ouvrier_contractual_subventionne", value: "12" },
					{ label: "travailleur_domestic_interne", value: "13" },
					{ label: "travailleur_domestic_externe", value: "14" },
					{ label: "ovurier_paye_au_forfait", value: "15" },
					{ label: "superextra_hoerca_ouvrier", value: "16" },
					{ label: "employe", value: "20" },
					{ label: "employe_moins_valide", value: "21" },
					{ label: "emloye_contractual_subventionne", value: "22" },
					{ label: "superextra_horeca_employe", value: "23" },
					{ label: "boursier_autre_eat", value: "24" },
					{ label: "employe_paye_au_forfait", value: "25" },
					{ label: "sportif_remunere_apd_2008", value: "26" },
				],
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) => secSocPrefill.category,
			},
			{
				label: "subcategory",
				inputType: "select",
				required: true,
				options: [
					{ label: "pas_de_subcategory", value: "00" },
					{ label: "soumis_obligation_scolaire", value: "44" },
				],
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) => secSocPrefill.subcategory,
			},
			{
				label: "number_of_dependent_children",
				inputType: "number",
				required: true,
			},
			{
				label: "number_of_dependent_disabled_children",
				inputType: "number",
				required: false,
				show: values => Number(values.number_of_dependent_children) > 0,
			},
			{
				label: "other_dependant_person_younger_65",
				inputType: "number",
				required: true,
			},
			{
				label: "other_dependant_disabled_person_younger_65",
				inputType: "text",
				required: false,
				show: values => Number(values.other_dependant_person_younger_65) > 0,
			},
			{
				label: "number_of_other_dependant_older_65",
				inputType: "number",
				required: true,
			},
			{
				label: "number_of_other_dependant_disabled_older_65",
				inputType: "number",
				required: true,
				show: values => Number(values.number_of_other_dependant_older_65) > 0,
			},
			{
				label: "choice_profesional_expenses",
				inputType: "select",
				required: true,
				options: [
					{ label: "frais_reels", value: "01" },
					{ label: "frais_forfaitaires", value: "02" },
				],
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) =>
					secSocPrefill.choice_profesional_expenses,
			},
		],
	},
	{
		categoryName: "bank_data",
		inputs: [
			{
				label: "cbw_number",
				inputType: "select",
				required: true,
				options: [
					{ label: "paiem_elect", value: "0101" },
					{ label: "especes", value: "0501" },
					{ label: "listing", value: "0601" },
					{ label: "email", value: "0701" },
				],
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) => secSocPrefill.cbw_number,
			},
			{
				label: "iban",
				inputType: "text",
				required: false,
				show: values => ["0101", "0601", "0701"].includes(values.cbw_number),
			},
			{
				label: "bic",
				inputType: "text",
				required: false,
				show: values => ["0101", "0601", "0701"].includes(values.cbw_number),
			},
		],
	},
	{
		categoryName: "schedule",
		inputs: [
			{
				label: "work_interruption",
				inputType: "select",
				required: false,
				options: [
					{ label: "int_partielle_carriere_prof", value: "05" },
					{ label: "interrupt_carriere_soins_palliatifs_temps_plein", value: "08" },
					{ label: "force_majeure_temporaire", value: "09" },
					{ label: "conge_politique", value: "10" },
					{ label: "maladie_longue_duree", value: "11" },
					{ label: "conge_parental", value: "12" },
					{ label: "int_partielle_carriere_prof_soins_palliatifs", value: "13" },
					{ label: "demi_conge_parental", value: "14" },
					{ label: "credit_temps_temps_plein", value: "15" },
					{ label: "demi_credit_temps", value: "16" },
					{ label: "cinquieme_credit_temps", value: "17" },
					{ label: "cinquieme_conge_parental", value: "18" },
					{ label: "icp_soins_maladie_grave", value: "19" },
					{ label: "demi_icp_soins_maladie_grave", value: "20" },
					{ label: "cinquieme_icp_soins_maladie_grave", value: "21" },
					{ label: "reprise_temporaire_travail_aut_med_conseil", value: "22" },
					{ label: "deeltelijk_werkhervat", value: "23" },
					{ label: "travail_adapte_avec_perte_de_rem", value: "26" },
					{ label: "gedeeltelijk_arbeidsongeval", value: "30" },
					{ label: "one_ten_de_ouderschapsverlof", value: "31" },
				],
			},
			{
				label: "work_interruption_start_date",
				inputType: "datepicker",
				required: false,
				show: values => !!values.work_interruption,
			},
			{
				label: "work_interruption_end_date",
				inputType: "datepicker",
				required: false,
				show: values => !!values.work_interruption,
			},
			{
				label: "work_interruption_number_of_hours",
				inputType: "number",
				required: false,
				show: values =>
					!!values.work_interruption &&
					["05", "09", "10", "13", "22", "26", "30"].includes(values.work_interruption),
			},
			{
				label: "onss_regimen",
				inputType: "select",
				required: true,
				options: [
					{ label: "rg_pas_dapplication", value: "00" },
					{ label: "cinq_jours_semaine", value: "01" },
					{ label: "deux_12_heures", value: "02" },
					{ label: "autre_systeme", value: "03" },
				],
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) => secSocPrefill.onss_regimen,
			},
			{
				label: "onss_rythm",
				inputType: "select",
				required: true,
				options: [
					{ label: "ne_pas_appliquer", value: "00" },
					{ label: "temps_plein", value: "01" },
					{ label: "mi_temps", value: "02" },
					{ label: "travail_occasionnel", value: "03" },
					{ label: "travailleur_saisonnier", value: "05" },
					{ label: "temps_part_maintien_des_droits", value: "06" },
					{ label: "temps_partiel_volontaire", value: "07" },
					{ label: "temps_part_avec_cotisation_patr", value: "08" },
					{ label: "temps_partiel_sans_cot_patronale", value: "09" },
					{ label: "extra", value: "10" },
					{ label: "temps_part_maint_droits_sand_doc", value: "11" },
					{ label: "prepension_mi_temps_95_96", value: "12" },
					{ label: "preprension_a_mi_temps_sup_31_12_96", value: "13" },
					{ label: "contrat_courte_duree", value: "14" },
				],
			},
		],
	},
	{
		categoryName: "salary",
		inputs: [
			{
				label: "education",
				inputType: "select",
				required: true,
				options: [
					{ label: "primaires", value: "001" },
					{ label: "secondaires", value: "002" },
					{ label: "sup_non_univ", value: "003" },
					{ label: "sup_univ", value: "004" },
					{ label: "ev_inconnu", value: "999" },
				],
			},
			{
				label: "function_code",
				inputType: "select",
				required: false,
				options: [
					{ label: "dc_algemeen_huishoudelijk", value: "0501" },
					{ label: "dc_werkbegeleider", value: "0502" },
				],
			},
			{
				label: "gross_salary",
				inputType: "text",
				required: true,
			},
			{
				label: "barema_code",
				inputType: "select",
				required: true,
				options: [{ label: "bcv_no_control_barema", value: "0" }],
			},
			{
				label: "has_meal_voucher",
				inputType: "select",
				required: "true",
				options: [
					{ label: "yes", value: "true" },
					{ label: "no", value: "false" },
				],
			},
			{
				label: "meal_voucher_employer_part",
				inputType: "text",
				required: false,
				show: values => values.has_meal_voucher === "true",
			},
			{
				label: "meal_voucher_employee_part",
				inputType: "text",
				required: false,
				show: values => values.has_meal_voucher === "true",
			},
		],
	},
	{
		categoryName: "cost_center",
		inputs: [
			{
				label: "cost_center_number",
				inputType: "text",
				required: true,
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) => secSocPrefill.cost_center_number,
			},
			{
				label: "cost_center_percentage",
				inputType: "number",
				required: true,
				secSocPrefillable: true,
				default: (currentWorker, secSocPrefill) => secSocPrefill.cost_center_percentage,
			},
		],
	},
]

function end_reason_required(affiliate, values, currentContract, workerContracts) {
	if (affiliate.secSocName === "Group S" || affiliate.secSocName === "Securex") {
		let hasEndDate = values.endDate && values.endDate.isValid()
		let childContract = workerContracts.find(e => e.parentID === currentContract.id)
		if (hasEndDate && !childContract) {
			return true
		}
	}
	return false
}
