import {
	simpleFetch,
	SUPPORTED_NOTIFICATIONS,
	ONE_BOOKING_CANCELLED,
	ALL_BOOKINGS_CANCELLED,
	CUSTOMER_CHAT_MESSAGE,
	WORKER_CHAT_MESSAGE,
	NEW_CUSTOMER_UNAVAILABILITY,
	UNAVAILABILITY_APPROVAL,
	ASK_AFFILIATE_TO_FORGET_CUSTOMER,
	CONVENTION_SEND,
	UNAVAILABILITY_DEMAND,
	NEW_TASK_ASSIGNEE,
} from "./index"
import { history } from "../history"
import * as acTasks from "./acTasks"

export const NOTIFICATIONS_STATE_CHANGE = "NOTIFICATIONS_STATE_CHANGE"
export const notificationStateChange = changes => ({ type: NOTIFICATIONS_STATE_CHANGE, changes })

export const MARK_NOTIFICATIONS_AS_READ = "MARK_NOTIFICATIONS_AS_READ"
export const MARK_NOTIFICATIONS_AS_READ_REQUEST = "MARK_NOTIFICATIONS_AS_READ_REQUEST"
export const MARK_NOTIFICATIONS_AS_READ_SUCCESS = "MARK_NOTIFICATIONS_AS_READ_SUCCESS"
export const MARK_NOTIFICATIONS_AS_READ_FAILURE = "MARK_NOTIFICATIONS_AS_READ_FAILURE"
export const markNotificationsAsRead = n => (dispatch, getState) => {
	let init = { method: "POST" }
	let url = "/front/affiliates/mark_as_read"

	let body = {
		supported_events: SUPPORTED_NOTIFICATIONS.map(entry => entry),
	}
	if (n) {
		body.notification_id = n.id
	}
	init.body = JSON.stringify(body)
	return dispatch(simpleFetch(url, init, MARK_NOTIFICATIONS_AS_READ))
}

export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS"
export const FETCH_NOTIFICATIONS_REQUEST = "FETCH_NOTIFICATIONS_REQUEST"
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS"
export const FETCH_NOTIFICATIONS_FAILURE = "FETCH_NOTIFICATIONS_FAILURE"
export const fetchNotifications = () => (dispatch, getState) => {
	let init = { method: "GET" }
	let url =
		"/front/affiliates/notifications?" +
		SUPPORTED_NOTIFICATIONS.map(entry => "&supported_events[]=" + entry).join("")
	return dispatch(simpleFetch(url, init, FETCH_NOTIFICATIONS))
}

export const processNotificationClick = n => (dispatch, getState) => {
	switch (n.event) {
		case ONE_BOOKING_CANCELLED:
		case ALL_BOOKINGS_CANCELLED:
			if (n.subject) {
				history.push({ pathname: "/bookings/" + n.subject.booking_id })
			}
			break
		case CUSTOMER_CHAT_MESSAGE:
			history.push({ pathname: "/customers/" + n.subject.customer_contract_id + "/chat" })
			break
		case WORKER_CHAT_MESSAGE:
			history.push({ pathname: "/cleaners/" + n.subject.affiliate_worker_id + "/chat" })
			break
		case NEW_CUSTOMER_UNAVAILABILITY:
			history.push({ pathname: "/customers/" + n.subject.customer_contract_id + "/absences" })
			break
		case UNAVAILABILITY_APPROVAL:
		case UNAVAILABILITY_DEMAND:
			history.push({ pathname: "/cleaners/ " + n.subject.affiliate_worker_id + "/absences" })
			break
		case ASK_AFFILIATE_TO_FORGET_CUSTOMER:
		case CONVENTION_SEND:
			history.push({ pathname: "/customers/ " + n.subject.customer_contract_id + "/profile" })
			break
		case NEW_TASK_ASSIGNEE:
			dispatch(acTasks.fetchTasksWithId(n.subject.task_id))
			break
		default:
			console.log("do nothing for ", n.event)
	}
	dispatch(notificationStateChange({ show: false }))
	dispatch(markNotificationsAsRead(n))
}
