import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import * as actions from "../actions"

import ChatRoom from "./ChatRoom"
import { ContentLayout, OptionBox } from "./FunctionalDesign"
import { PootsyTextArea } from "./FunctionalInputs"

class CustomerChat extends Component {
	UNSAFE_componentWillReceiveProps = newProps => {
		if (newProps.match.params.id !== this.props.match.params.id) {
			this.fetchMessages(newProps.match.params.id)
		}
	}
	componentDidMount = () => {
		this.fetchMessages(this.props.match.params.id)
	}
	fetchMessages = id => {
		let init = { method: "GET" }
		let url = "/front/affiliates/customers/messages?customer_contract_id=" + id
		this.props.dispatch(actions.simpleFetch(url, init, "FETCHCUSTOMERMESSAGES"))
	}
	sendMessage = (currMess, chatImg) => {
		let { dispatch, currentCustomer } = this.props
		if (currMess === "" && chatImg === "") {
			return
		}
		let data = {
			customer_contract_id: currentCustomer.id,
			content: currMess,
		}
		if (chatImg) {
			data.images = [{ base64_content: chatImg.file, filename: chatImg.filename }]
		}
		let init = { method: "POST", body: JSON.stringify(data) }
		dispatch(
			actions.simpleFetch(
				"/front/affiliates/customers/messages",
				init,
				"SUBMITCUSTOMERMESSAGE"
			)
		)
	}
	render() {
		let { t } = this.context
		let {
			chat,
			currentCustomer: { affiliateCustomerNote },
		} = this.props
		let optionsBoxes = (
			<OptionBox boxTitle={t("customer_bio")}>
				<PootsyTextArea
					label={t("affiliate_customer_note")}
					name="affiliateCustomerNote"
					value={affiliateCustomerNote}
				/>
			</OptionBox>
		)
		return (
			<ContentLayout optionsBoxes={optionsBoxes} customClass="customer-chat">
				<ChatRoom
					customClass="cleaner-chat-room"
					messages={chat.messages}
					sendMessage={this.sendMessage}
				/>
			</ContentLayout>
		)
	}
}

CustomerChat.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currentCustomer: state.redData.currentCustomer,
	chat: state.redData.customerChat,
})
export default connect(mapStateToProps)(CustomerChat)
