import { API_ROOT } from "../config.js"
import * as actions from "../actions"
import { Sentry } from "../sentryInit"
import { normalizeError } from "../normalizers/Normalizers"
export const CALL_API = Symbol("Call API")

if (process.env.NODE_ENV === "test") {
	process.env.REACT_APP_COMMIT_HASH_AND_DATE = "123__321"
}
const LOCAL_PAI_VERSION = process.env.REACT_APP_COMMIT_HASH_AND_DATE.split("__")[1]

const callApi = (endpoint, init) => {
	const fullUrl = API_ROOT + endpoint
	return fetch(fullUrl, init)
}

let perf
if (process.env.NODE_ENV === "test") {
	perf = () => 0
} else if (window.performance) {
	perf = performance.now.bind(performance)
} else {
	perf = Date.now
}

// This variable will be incremented by one at each request,
// to give an id to each of them, and thus be able to keep track.
let requestIncrementalTracker = 0

export default store => next => action => {
	if (typeof action[CALL_API] === "undefined") {
		return next(action)
	}

	let { endpoint, init, types } = action[CALL_API]
	let [requestType, successType, failureType] = types
	const requestMeta = action.requestMeta || {}

	init.headers = {
		"Content-Type": "application/json",
		"Iam-PAI-Commit": process.env.REACT_APP_COMMIT_HASH_AND_DATE,
	}

	let currentAffiliate = null
	if (requestType !== "AUTH_REQUEST") {
		let {
			appMeta,
			auth,
			redData: { currentAffiliate: currAff },
		} = store.getState()
		currentAffiliate = currAff
		if (appMeta.frozen) {
			// if app is frozen don't make any call
			return { actionType: undefined }
		}
		init.headers.Authorization = "Bearer " + auth.token
		init.headers.Affiliate = currentAffiliate.id
	}
	if (requestType === "CHECKTOKEN_REQUEST") {
		init.headers.Authorization = "Bearer " + requestMeta.token
		init.headers.Affiliate = requestMeta.affiliateID
	}

	requestIncrementalTracker += 1
	const currentRequestId = requestIncrementalTracker
	next({ type: requestType, requestId: currentRequestId, requestMeta })
	let networkCallStart = perf()
	let networkCallEnd

	let filename

	if (process.env.NODE_ENV === "development") {
		console.groupCollapsed(
			`%c${requestType}`,
			"font-weight:bold;text-decoration:underline;",
			init.method,
			endpoint
		)
		console.log(
			"%cData sent:",
			"color: darkslategray",
			init.body ? JSON.parse(init.body) : init
		)
		console.groupEnd("%cRequest ID", "color:lightblue", currentRequestId)
	}
	return callApi(endpoint, init)
		.then(response => {
			networkCallEnd = perf()

			let paiRemoteVersion = response.headers.get("pai-last-seen")
			if (paiRemoteVersion !== LOCAL_PAI_VERSION) {
				next(actions.warningOldVersionUsage())
			}
			if (!response.ok) {
				return response.json().then(
					json => {
						return Promise.reject({ json, status: response.status })
					},
					err => {
						return Promise.reject({ json: "", status: response.status })
					}
				)
			}

			if (response.headers.get("Content-Type").includes("application/json")) {
				requestMeta.fileRequest = false
			}
			if (requestMeta.fileRequest) {
				// application/octet-stream
				filename = response.headers.get("Content-Disposition").split('"')[1]
				return response.blob()
			}
			return response.json()
		})
		.then(
			data => {
				if (requestMeta.fileRequest) {
					let fileUrl = URL.createObjectURL(data)

					data = { [requestMeta.fileKeyName]: fileUrl, filename }
				}
				let fetchTime = (networkCallEnd - networkCallStart) / 1000
				let action = {
					type: successType,
					requestId: currentRequestId,
					requestMeta,
					fetchTime,
					...data,
				}
				if (process.env.NODE_ENV === "development") {
					console.groupCollapsed(
						`%c${successType}`,
						"color:#bada55;font-weight:bold;text-decoration:underline;"
					)
					console.log("%cData:", "color:steelblue;", data)
					console.log("%cFetch time was:", "color: tomato", fetchTime, "seconds")
					console.log("%cAction dispatched", "color:steelblue", action)
					console.groupEnd()
				}
				if (data.meta && data.meta.flash_message) {
					next(actions.flashMessage(data.meta.flash_message))
					setTimeout(() => {
						next(actions.flashMessage(""))
					}, 4000)
				}
				next(actions.hideSpinner(currentRequestId))
				next(action)
				return { actionType: successType, data, requestMeta }
			},
			error => {
				networkCallEnd = perf()
				let fetchTime = (networkCallEnd - networkCallStart) / 1000
				let action = { type: failureType, requestId: currentRequestId, requestMeta, error }
				if (process.env.NODE_ENV === "development") {
					console.groupCollapsed(
						`%c${failureType}`,
						"color:tomato;font-weight:bold;text-decoration:underline;"
					)
					console.log("%cErrors:", "color:red;font-size:1.1em;font-weight:bold;", error)
					console.log("%cAction dispatched", "color:steelblue", action)
					console.log("%cFetch time was:", "color: tomato", fetchTime, "seconds")
					console.groupEnd()
				}

				next(actions.backendError(error.json, error.status))
				if (error.status === 412) {
					next(actions.handleRequiredActions({ triggerAction: failureType, error }))
					next(actions.acknowledgeErrors())
				}
				next(actions.hideSpinner(currentRequestId))
				next(action)
				let rejectedErrorStatuses = [412]
				if (
					!rejectedErrorStatuses.includes(error.status) &&
					currentAffiliate &&
					currentAffiliate.id
				) {
					let obj = normalizeError(error.json)
					Object.keys(obj).forEach(key =>
						Sentry.setTag("error Message", key + " : " + obj[key])
					)
					Sentry.captureException(new Error(error.status), {
						tags: {
							endpoint: endpoint,
							affiliateId: currentAffiliate.id,
							affiliateName: currentAffiliate.commercialName,
						},
					})
				}
				return {
					actionType: failureType,
					error,
					status: error.status,
					requestMeta,
				}
			}
		)
}
