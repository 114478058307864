import React, { Component } from "react"
import { ContentLayout } from "./FunctionalDesign"
import * as actions from "../actions"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import InputsChangeHandler from "./InputsChangeHandler"
import { PootsyCheckboxInput, PootsySelectInput, PootsyTextInput } from "./FunctionalInputs"
import { PootsySubHeading, PootsyButton, SimpleLine } from "./FunctionalDesign"

import PootsyAddressBlockInputs from "./PootsyAddressBlockInputs"

class SettingsDimona extends Component {
	handleChanges = changes => {
		this.props.dispatch(actions.settingsDimonaStateChanges(changes))
	}
	handleHQChanges = changes => {
		let {
			component: { new_headquarter },
		} = this.props

		let newHQ = {
			...new_headquarter,
		}
		Object.entries(changes).forEach(([key, value]) => {
			newHQ[key] = value
		})
		this.handleChanges({ new_headquarter: newHQ })
	}
	handleHQAddressChanges = changes => {
		let {
			component: {
				new_headquarter: { address },
			},
		} = this.props
		let newAddress = {
			...address,
		}
		Object.entries(changes).forEach(([key, value]) => {
			newAddress[key] = value
		})

		this.handleChanges({ new_headquarter: { address: newAddress } })
	}
	submitHeadquarter = () => {
		this.props.dispatch(actions.submitNewHeadquarter())
	}
	handleFormChanges = changes => {
		this.props.dispatch(actions.editSettings(changes))
	}

	handleDimonaSettingsChange = changes => {
		this.handleFormChanges({
			dimonaSettings: { ...this.props.currAffiliate.dimonaSettings, ...changes },
		})
	}

	handleEcoUnemploymentSettingsChange = changes => {
		this.handleFormChanges({
			ecoUnemploymentSettings: {
				...this.props.currAffiliate.ecoUnemploymentSettings,
				...changes,
			},
		})
	}
	render() {
		let { t } = this.context
		let {
			currAffiliate,
			component: {
				new_headquarter: { address },
			},
		} = this.props
		return (
			<ContentLayout customClass="settings-dimona">
				<PootsySubHeading text={t("dimona_settings_title")} />
				<InputsChangeHandler
					customClass="settings-dimona-inputs"
					onChange={this.handleDimonaSettingsChange}
				>
					<div className="inputs-dimona">
						<PootsyCheckboxInput
							name="dimonaAutoSyncOnEndContract"
							label={t("dimona_auto_sync_on_end_contract")}
							checked={currAffiliate.dimonaSettings.dimonaAutoSyncOnEndContract}
						/>
						<PootsySelectInput
							name="dimonaWorkerType"
							defaultText={t("dimona_worker_type")}
							selected={currAffiliate.dimonaSettings.dimonaWorkerType}
							options={[
								{ label: t("dimona_worker_tri"), value: "TRI" },
								{ label: t("dimona_worker_oth"), value: "OTH" },
								{ label: t("dimona_worker_stu"), value: "STU" },
								{ label: t("dimona_worker_flx"), value: "FLX" },
							]}
						/>
					</div>
				</InputsChangeHandler>
				<PootsySubHeading text={t("eco_unemployment_title")} />
				<InputsChangeHandler
					customClass="settings-eco_unemployment-inputs"
					onChange={this.handleFormChanges}
				>
					<div className="inputs-onss-info">
						<PootsyTextInput
							name="onssReference"
							label={t("onss_number")}
							value={currAffiliate.onssReference}
						/>
						<PootsyTextInput
							name="onssContactFirstName"
							label={t("onss_contact_first_name")}
							value={currAffiliate.onssContactFirstName}
						/>
						<PootsyTextInput
							name="onssContactLastName"
							label={t("onss_contact_last_name")}
							value={currAffiliate.onssContactLastName}
						/>
						<PootsyTextInput
							name="onssContactTelephone"
							label={t("onss_contact_telephone")}
							value={currAffiliate.onssContactTelephone}
						/>
						<PootsyTextInput
							name="bceReference"
							label={t("bce_number")}
							value={currAffiliate.bceReference}
						/>

						<PootsyTextInput
							name="commission"
							label={t("commission")}
							value={currAffiliate.commission.toString().replace(".", ",")}
						/>

						<PootsySelectInput
							name="languageONSS"
							defaultText={t("language_onss")}
							selected={currAffiliate.languageONSS}
							options={[
								{ label: "FR", value: "FR" },
								{ label: "NL", value: "NL" },
							]}
						/>
						<PootsyTextInput
							name="reasonSmallEcoUnemployment"
							label={t("reasonSmallEcoUnemployment")}
							value={currAffiliate.reasonSmallEcoUnemployment}
						/>
						<PootsyTextInput
							name="reasonBigEcoUnemployment"
							label={t("reasonBigEcoUnemployment")}
							value={currAffiliate.reasonBigEcoUnemployment}
						/>
					</div>
				</InputsChangeHandler>
				<PootsySubHeading text={t("operating_headquarters")} />

				<PootsyAddressBlockInputs
					handleChanges={this.handleHQAddressChanges}
					setValues={this.handleHQAddressChanges}
					values={{
						city: address.city,
						zip: address.zip,
						box: address.box,
						streetNumber: address.streetNumber,
						street: address.street,
					}}
				/>
				<InputsChangeHandler
					customClass="settings-operating-headquarters"
					onChange={this.handleHQChanges}
				>
					<PootsySelectInput
						name="language"
						defaultText={t("language_hq")}
						selected={this.props.component.new_headquarter.language}
						options={[
							{ label: "FR", value: "FR" },
							{ label: "NL", value: "NL" },
						]}
					/>
					<PootsyTextInput
						name="reference_number"
						label={t("SE_number")}
						value={this.props.component.new_headquarter.reference_number}
					/>
					<PootsyButton text={t("create_hq")} onClick={this.submitHeadquarter} />
				</InputsChangeHandler>
				<PootsySubHeading text={t("operating_headquarters_list")} />

				<div className="headquarters-list">
					{currAffiliate.operatingHeadquarters.data &&
						currAffiliate.operatingHeadquarters.data.map(hq => (
							<SimpleLine key={hq.id} noLink={true} customClass="line-hq">
								<div> {hq.attributes.address.street_name} </div>
								<div> {hq.attributes.address.street_number} </div>
								<div> {hq.attributes.address.town} </div>

								<div> {hq.attributes.reference_number} </div>
								<div> {hq.attributes.language} </div>
							</SimpleLine>
						))}
				</div>
			</ContentLayout>
		)
	}
}

SettingsDimona.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currAffiliate: state.redData.currentAffiliate,
	component: state.redComponents.settingsDimona,
})
export default connect(mapStateToProps)(SettingsDimona)
