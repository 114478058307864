import React, { Component } from "react"
import PropTypes from "prop-types"

import InputsChangeHandler from "./InputsChangeHandler"
import { SecSocInput } from "./SecSocInput"
import { PootsySubHeading } from "./FunctionalDesign"

import { socialSecretariesFields, translationPrefixes } from "../constants/socialSecretariesFields"

export default class SocialSecretaryWorkerSpecificFields extends Component {
	handleSpecificFieldChange = changes => {
		if (Object.keys(changes)[0] === "") {
			return
		}
		this.props.handleSecSocSpecificFieldChange(changes)
	}
	renderCategory = (category, translationPrefix) => {
		let { t } = this.context
		let { values, currAffiliate, currentContract, workerContracts } = this.props
		if (category.inputs.length === 0) {
			return null
		}
		if (category.show && !category.show(values)) {
			return null
		}
		return (
			<div key={category.categoryName} className="category">
				<PootsySubHeading text={t(category.categoryName)} />
				{category.inputs.map((entry, index) => (
					<SecSocInput
						input={entry}
						currAffiliate={currAffiliate}
						currentContract={currentContract}
						workerContracts={workerContracts}
						values={values}
						onChange={this.handleSpecificFieldChange}
						translationPrefix={translationPrefix}
						key={index}
					/>
				))}
			</div>
		)
	}

	render() {
		let { socialSecretary } = this.props
		let inputs = socialSecretariesFields[socialSecretary] || []
		let translationPrefix = translationPrefixes[socialSecretary]
		return (
			<InputsChangeHandler
				style={{}}
				onChange={this.handleSpecificFieldChange}
				customClass="social-secretary-worker-specific-fields"
			>
				{inputs.map(entry => this.renderCategory(entry, translationPrefix))}
			</InputsChangeHandler>
		)
	}
}

SocialSecretaryWorkerSpecificFields.contextTypes = { t: PropTypes.func }
