import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"

import arrowRightIcon from "../static/images/arrow-right-icon.png"

import { PootsySubHeading } from "./FunctionalDesign"
import Tasks from "./Tasks"
import { NotifIcon, TasksIcon } from "../svg/gIcons"

import { showNotifications, showTasks } from "../config"

export default class UserDropDown extends Component {
	state = {
		dropClass: "user-dropdown",
	}
	toggleDrop = () => {
		this.setState(state => ({
			dropClass:
				state.dropClass === "user-dropdown" ? "user-dropdown active" : "user-dropdown",
		}))
	}
	onSettingsClick = () => {
		this.props.push({ pathname: "/settings/profile" })
		this.toggleDrop()
	}
	logout = () => {
		this.props.logout()
	}
	stopPropagation = e => {
		e.stopPropagation()
	}
	render() {
		let { t } = this.context
		if (!this.props.currentUser) return null
		let {
			currentUser,
			currentUser: { unreadNotificationsCount, dueTasksCount, userName, isAffiliate },
			affiliateName,
			affiliateLogoUrl,
			lang,
			changeLanguage,
			toggleNotificationCenter,
			notificationsComponent,
			notifications,
			handleNotificationClick,
			markNotificationsAsRead,
			toggleTasksOverlay,
		} = this.props
		return (
			<div className={this.state.dropClass}>
				<div className="user-dropdown-left" onClick={this.toggleDrop}>
					{!currentUser.isWorkshopLimited && (
						<div className="tasks-block" onClick={this.stopPropagation}>
							{showTasks && isAffiliate && (
								<Fragment>
									<TasksIcon
										unreadCount={dueTasksCount}
										onClick={toggleTasksOverlay}
									/>
									<Tasks />
								</Fragment>
							)}
						</div>
					)}
					{!currentUser.isWorkshopLimited && (
						<div className="notifications-block">
							{showNotifications && (
								<NotifIcon
									unreadCount={unreadNotificationsCount}
									onClick={toggleNotificationCenter}
								/>
							)}
							<div
								className={
									"pootsy-notifications-overlay " +
									(notificationsComponent &&
										notificationsComponent.show &&
										"active")
								}
								onClick={this.stopPropagation}
							>
								<div className="top-banner">
									<PootsySubHeading text={t("notifications_overlay_title")} />
									<div className="mark-as-read" onClick={markNotificationsAsRead}>
										{t("mark_all_as_read")}
									</div>
								</div>
								<div className="notifications">
									{notificationsComponent &&
										notificationsComponent.show &&
										notifications.map(n => (
											<div
												key={n.id}
												className={"notification " + (n.isRead && "read")}
												onClick={e => handleNotificationClick(e, n)}
											>
												<img src={n.imageSource} alt="" />
												<div className="content">{n.message}</div>
												<div className="timestamp">
													{n.createdAt.fromNow()}
												</div>
											</div>
										))}
								</div>
							</div>
						</div>
					)}
					<div className="name-block">
						<span className="username">{userName}</span>
						<span className="affiliate-name">{affiliateName}</span>
					</div>
					<img alt="profile-pic" className="profile-pic" src={affiliateLogoUrl} />
					<img alt="arrow-bottom" className="arrow-bottom" src={arrowRightIcon} />
				</div>
				<div className="user-dropdown-right">
					{!currentUser.isWorkshopLimited && (
						<span className="user-dropdown-item" onClick={this.onSettingsClick}>
							{t("settings")}
						</span>
					)}
					<span className="user-dropdown-item" onClick={this.logout}>
						{t("logout")}
					</span>
					<span className="user-dropdown-item language-chooser">
						<div
							className={"lang " + (lang === "en" ? "active" : "")}
							onClick={changeLanguage}
							data-lang="en"
						>
							En
						</div>
						<div
							className={"lang " + (lang === "fr" ? "active" : "")}
							onClick={changeLanguage}
							data-lang="fr"
						>
							Fr
						</div>
						<div
							className={"lang " + (lang === "nl" ? "active" : "")}
							onClick={changeLanguage}
							data-lang="nl"
						>
							Nl
						</div>
					</span>
				</div>
			</div>
		)
	}
}
UserDropDown.contextTypes = { t: PropTypes.func }
