import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { history } from "../history"
import * as actions from "../actions"

import {
	ContentLayout,
	OptionBox,
	PootsyButton,
	SimpleLine,
	PootsySubHeading,
} from "./FunctionalDesign"
import ModalLayout from "./ModalLayout"
import { PootsyTextArea } from "./FunctionalInputs"
import SearchCustomer from "./SearchCustomer"

class WorkerIncompatibilities extends Component {
	state = {
		showCreateIncompatibilityModal: false,
		chosenCustomer: {},
		incompatibilityNote: "",
	}

	UNSAFE_componentWillMount = () => {
		let {
			currentWorker: { id },
		} = this.props
		let init = { method: "GET" }
		let url = "/front/affiliates/affiliate_workers/" + id + "/customer_incompatibilities"
		this.props.dispatch(actions.simpleFetch(url, init, "FETCH_WORKER_INCOMPATIBILITIES"))
	}
	componentWillUnmount = () => {
		this.resetModal()
	}
	resetModal = () => {
		this.props.dispatch(actions.resetCustomerSuggestions())
		this.setState({ chosenCustomer: {}, customerSearchValue: "" })
	}
	toggleCreateIncompatibilityModal = () => {
		this.resetModal()
		this.setState(state => ({
			showCreateIncompatibilityModal: !state.showCreateIncompatibilityModal,
		}))
	}
	handleCustomerClick = customer => {
		let { dispatch } = this.props
		this.setState({ chosenCustomer: customer })
		dispatch(actions.resetCustomerSuggestions())
	}
	handleNoteChange = e => {
		e.stopPropagation()
		this.setState({ incompatibilityNote: e.target.value })
	}
	goToCustomer = id => {
		history.push("/cleaners/" + id + "/profile")
	}
	submitIncompatibility = () => {
		let {
			currentWorker: { id },
		} = this.props
		let { chosenCustomer, incompatibilityNote } = this.state
		let init = {
			method: "POST",
			body: JSON.stringify({
				customer_contract_id: chosenCustomer.id,
				note: incompatibilityNote,
			}),
		}
		let url = "/front/affiliates/worker_contracts/" + id + "/customer_incompatibilities"
		chosenCustomer.attributes.note = incompatibilityNote
		this.props.dispatch(actions.simpleFetch(url, init, "SUBMIT_WORKER_INCOMPATIBILITY"))
		this.toggleCreateIncompatibilityModal()
	}
	submitRemoveIncompatibility = e => {
		e.stopPropagation()
		let { id } = e.target.dataset
		let { currentWorker } = this.props
		let init = { method: "DELETE" }
		let url = `/front/affiliates/worker_contracts/${currentWorker.id}/customer_incompatibilities/${id}`
		this.props.dispatch(
			actions.simpleFetch(url, init, "SUBMIT_REMOVE_WORKER_INCOMPATIBILITY", {
				toRemoveID: id,
			})
		)
	}
	render() {
		let { t } = this.context
		let { currentWorker } = this.props
		let { showCreateIncompatibilityModal, chosenCustomer, incompatibilityNote } = this.state
		let optionsBoxes = (
			<OptionBox>
				<PootsyButton
					text={t("create_worker_incompatibility_button")}
					onClick={this.toggleCreateIncompatibilityModal}
				/>
			</OptionBox>
		)
		let createIncompatibilityModalButtons = [
			<PootsyButton key="a" text={t("submit")} onClick={this.submitIncompatibility} />,
			<PootsyButton
				key="b"
				text={t("cancel")}
				theme="cancel"
				onClick={this.toggleCreateIncompatibilityModal}
			/>,
		]
		return (
			<ContentLayout optionsBoxes={optionsBoxes} customClass="worker-incompatibilities">
				<div className="worker-incompatibilities">
					{currentWorker.incompatibilities && (
						<PootsySubHeading text={t("worker_incompatibilities_title")} />
					)}
					{currentWorker.incompatibilities &&
						currentWorker.incompatibilities.length === 0 && (
							<div className="no-incompatibilities">{t("no_incompatibilities")}</div>
						)}
					{currentWorker.incompatibilities &&
						currentWorker.incompatibilities.map(ci => (
							<SimpleLine
								key={ci.id}
								onClick={this.goToCustomer.bind(
									this,
									ci.attributes.worker_contract_id
								)}
							>
								<div className="incompatibility-line-contact">
									<span className="worker-name">
										{ci.attributes.customer_name}
									</span>
								</div>
								<div className="incompatibility-note">{ci.attributes.note}</div>
								<div className="remove-worker-incompatibility">
									<PootsyButton
										text={t("remove_worker_incompatibility")}
										theme="red"
										onClick={this.submitRemoveIncompatibility}
										dataset={{ "data-id": ci.id }}
									/>
								</div>
							</SimpleLine>
						))}
				</div>
				{showCreateIncompatibilityModal && (
					<ModalLayout
						formDivClass="create-worker-incompatibility-modal"
						title={t("create_worker_incompatibility_modal_title")}
						closeModal={this.toggleCreateIncompatibilityModal}
						buttons={createIncompatibilityModalButtons}
					>
						<div className="customer-search-box">
							<SearchCustomer handleCustomerClick={this.handleCustomerClick} />
						</div>
						{chosenCustomer.id && (
							<div className="chosen-customer">
								{t("customer_to_be_incompatible_confirmation")}
								<div className="customer-to-be-incompatible">
									<div>{chosenCustomer.attributes.display_name}</div>
									<div>></div>
									<div>{currentWorker.displayName}</div>
								</div>
							</div>
						)}
						<PootsyTextArea
							label={t("note")}
							value={incompatibilityNote}
							onChange={this.handleNoteChange}
						/>
					</ModalLayout>
				)}
			</ContentLayout>
		)
	}
}

WorkerIncompatibilities.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currentWorker: state.redData.currentWorker,
})
export default connect(mapStateToProps)(WorkerIncompatibilities)
