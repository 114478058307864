import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"

import * as actions from "../actions"

export const useChangeHandler = () => {
	const dispatch = useDispatch()
	const handler = useCallback(
		changes => {
			dispatch(actions.searchSodexoWorkStateChange(changes))
		},
		[dispatch]
	)
	return handler
}

export const useSodexoWorkFetcher = () => {
	const dispatch = useDispatch()
	const handler = useCallback(
		e => {
			if (e.key === "Enter") {
				dispatch(actions.fetchSodexoWork(e.target.value))
			}
		},
		[dispatch]
	)
	return handler
}

export const useUnlinkSodexoWorkSubmit = () => {
	const dispatch = useDispatch()
	let sodexoWork = useSelector(state => state.redComponents.searchSodexoWorkComponent.sodexoWork)
	const handler = useCallback(() => {
		dispatch(actions.unlinkSodexoWork(sodexoWork.sodexoBookingId))
	}, [dispatch, sodexoWork])
	return handler
}
