import React, { Fragment, useState } from "react"
import { useSelector } from "react-redux"

import InputsChangeHandler from "./InputsChangeHandler"
import { PootsyButton, LabeledDateTime, PootsySubHeading } from "./FunctionalDesign"
import ModalLayout from "./ModalLayout"
import {
	PootsyTextInput,
	PootsySelectInput,
	PootsyTextArea,
	PootsyCheckboxInput,
} from "./FunctionalInputs"

import moment from "moment"

export const AbsenceModal = ({
	editMode,
	toggleModal,
	activityCode,
	absenceStart,
	absenceEnd,
	absenceNote,
	absenceType,
	activityCategory,
	lastEuAbsenceDate,
	formC32ANumber,
	mustCallOnss,
	onChange,
	submit,
	t,
}) => {
	const [times, setTimes] = useState({
		startDate: absenceStart,
		startTime: absenceStart,
		endDate: absenceEnd,
		endTime: absenceEnd,
	})

	const [showRequired, setShowRequired] = useState({
		activityCode: false,
		activityCategory: false,
		absenceStatus: false,
		absenceType: false,
	})

	const [messages, setMessages] = useState({
		c32aInvalidMessage: false,
		isSaveButtonDisabled: false,
	})

	function handleChanges(changes) {
		onChange(changes)
	}

	const currAffiliate = useSelector(state => state.redData.currentAffiliate)

	function handleStartTimeChanges(changes) {
		let newTimes = Object.assign({}, times)
		if (changes.absenceStartTime.isAfter(times.endTime)) {
			let newEndTime = newTimes.endTime
				.clone()
				.add(changes.absenceStartTime.diff(times.endTime, "minutes") + 5, "minutes")
			newTimes["endTime"] = newEndTime
		}
		newTimes["startTime"] = changes.absenceStartTime
		onChange({
			absenceStart: newTimes.startTime,
			absenceEnd: newTimes.endTime,
		})
		setTimes(newTimes)
	}

	function handleEndTimeChanges(changes) {
		let newTimes = Object.assign({}, times)
		if (changes.absenceEndTime.isBefore(times.startTime)) {
			newTimes["startTime"] = changes.absenceEndTime
		}
		newTimes["endTime"] = changes.absenceEndTime
		onChange({
			absenceStart: newTimes.startTime,
			absenceEnd: newTimes.endTime,
		})
		setTimes(newTimes)
	}

	function handleStartDateChanges(changes) {
		let newTimes = Object.assign({}, times)
		let diff = Math.round(changes.absenceStartDate.diff(times.startTime, "seconds") / 60)
		let newStartDate = newTimes.startTime.clone().add(diff, "minutes")
		if (newStartDate.isAfter(times.endTime)) {
			let newEndDate = newTimes.endTime
				.clone()
				.add(Math.floor(newStartDate.diff(times.endTime, "minutes") / 1440) + 1, "days")
			newTimes["endTime"] = newEndDate
			newTimes["endDate"] = newEndDate
		}
		newTimes["startTime"] = newStartDate
		newTimes["startDate"] = newStartDate
		onChange({
			absenceStart: newTimes.startTime,
			absenceEnd: newTimes.endTime,
		})
		setTimes(newTimes)
	}

	function handleEndDateChanges(changes) {
		let newTimes = Object.assign({}, times)
		let diff = Math.round(changes.absenceEndDate.diff(times.endTime, "seconds") / 60)
		let newEndDate = newTimes.endTime.clone().add(diff, "minutes")
		newTimes["endTime"] = newEndDate
		newTimes["endDate"] = newEndDate
		onChange({
			absenceStart: newTimes.startTime,
			absenceEnd: newTimes.endTime,
		})
		setTimes(newTimes)
	}

	function handleBeginningOfDay() {
		let newTimes = Object.assign({}, times)
		newTimes["startTime"] = newTimes.startTime.clone().set({ hours: 0, minutes: 0, seconds: 0 })
		onChange({
			absenceStart: newTimes.startTime,
			absenceEnd: newTimes.endTime,
		})
		setTimes(newTimes)
	}

	function handleEndOfDay() {
		let newTimes = Object.assign({}, times)
		newTimes["endTime"] = newTimes.endTime.clone().set({ hours: 23, minutes: 55, seconds: 0 })
		onChange({
			absenceStart: newTimes.startTime,
			absenceEnd: newTimes.endTime,
		})
		setTimes(newTimes)
	}

	function showRequiredUpdater(toRequire) {
		let newRequired = { ...showRequired }
		toRequire.forEach(entry => {
			newRequired[entry] = true
		})
		setShowRequired(newRequired)
	}

	function canCallOnss() {
		return mustCallOnss && currAffiliate.dimonaAddOn && currAffiliate.hasONSSSettings
	}

	function submitAbsence() {
		let toCheck = [
				{ activityCode: activityCode },
				{ activityCategory: activityCategory },
				{ absenceStatus: showRequired.absenceStatus },
			],
			valid = true
		let notValid = []
		toCheck.forEach(entry => {
			if (Object.values(entry)[0] === "") {
				notValid.push(Object.keys(entry)[0])
				valid = false
			}
		})
		if (valid) {
			submit()
		} else showRequiredUpdater(notValid)
	}

	function checkAbsenceType() {
		let filteredCodes = currAffiliate.secSocEvents.codes
		let id = filteredCodes.find(({ label }) => label === "ssc_sickness")
		if (id) {
			return activityCode === id.id
		} else {
			return false
		}
	}

	function checkFormC32A(e) {
		if (e.target.value.match(/^[a-zA-Z]{2}\d{7}$/) || e.target.value === "") {
			setMessages({ c32aInvalidMessage: false, isSaveButtonDisabled: false })
			handleChanges({ formC32ANumber: e.target.value })
		} else {
			setMessages({
				c32aInvalidMessage: t("wrong_format_form_c32a"),
				isSaveButtonDisabled: true,
			})
		}
	}

	let buttons = [
		<PootsyButton
			key="a"
			text={t("save")}
			onClick={submitAbsence}
			disabled={messages.isSaveButtonDisabled}
		/>,
		<PootsyButton key="b" text={t("cancel")} theme="cancel" onClick={toggleModal} />,
	]
	let filteredCodes = currAffiliate.secSocEvents.codes
	if (activityCategory) {
		filteredCodes = filteredCodes.filter(entry => entry.category_id === activityCategory)
	}
	const absenceTypes = [
		{ label: t("no_motif"), value: 0 },
		{ label: t("no_recurrence"), value: 1 },
		{ label: t("late_submission"), value: 2 },
		{ label: t("no_recurrence_late_filing"), value: 3 },
		{ label: t("work_eligible"), value: 4 },
	]

	const possibleCategories = currAffiliate.secSocEvents.categories.filter(
		category => category.id !== "group_s_benefit"
	)

	return (
		<InputsChangeHandler onChange={handleChanges} customClass="cleaner-absence-modal">
			<ModalLayout
				title={editMode ? t("edit_absence") : t("create_new_absence")}
				formDivClass="absence-modal-inputs"
				buttons={buttons}
				closeModal={toggleModal}
			>
				<div className="start-date-group">
					<LabeledDateTime
						label={t("start_date")}
						timePickerProps={{
							value: times.startTime,
							name: "absenceStartTime",
							onChange: handleStartTimeChanges,
						}}
						datePickerProps={{
							value: times.startDate,
							name: "absenceStartDate",
							onChange: handleStartDateChanges,
						}}
					/>
					<PootsyButton
						text={t("beginning_of_day")}
						theme="cancel"
						size="small"
						onClick={handleBeginningOfDay}
					/>
				</div>
				<div className="end-date-group">
					<LabeledDateTime
						label={t("end_date")}
						timePickerProps={{
							value: times.endTime,
							name: "absenceEndTime",
							onChange: handleEndTimeChanges,
						}}
						datePickerProps={{
							value: times.endDate,
							name: "absenceEndDate",
							onChange: handleEndDateChanges,
							disableDay: current => current.isBefore(times.startTime, "day"),
						}}
					/>
					<PootsyButton
						text={t("end_of_day")}
						theme="cancel"
						size="small"
						onClick={handleEndOfDay}
					/>
				</div>
				<PootsyTextArea label={t("comment")} name="absenceNote" value={absenceNote} />
				<PootsySubHeading text={t("select_impacted_bookings_action")} />
				<PootsySelectInput
					name="activityCategory"
					defaultText={t("activity_category")}
					selected={activityCategory}
					showRequired={showRequired.activityCategory}
					options={possibleCategories.map(entry => ({
						label: t(entry.label),
						value: entry.id,
					}))}
				/>
				<PootsySelectInput
					name="activityCode"
					defaultText={t("activity_type")}
					customClass="ss-code"
					selected={activityCode}
					showRequired={showRequired.activityCode}
					options={filteredCodes
						.sort((a, b) => t(a.label).localeCompare(t(b.label)))
						.map(entry => ({
							label: entry.id + " - " + t(entry.label),
							value: entry.id,
						}))}
				/>
				{checkAbsenceType() && activityCategory === "group_s_sickness" && (
					<PootsySelectInput
						name="absenceType"
						defaultText={t("absence_type")}
						selected={absenceType}
						showRequired={showRequired.absenceType}
						options={absenceTypes}
					/>
				)}
				{activityCode === currAffiliate.euSSCode &&
					canCallOnss() &&
					(!lastEuAbsenceDate || !absenceEnd.isSame(lastEuAbsenceDate, "month")) && (
						<Fragment>
							<PootsyTextInput
								name="formC32ANumber"
								label={t("form_c32a_number")}
								value={formC32ANumber}
								onChange={checkFormC32A}
								errorMessage={messages.c32aInvalidMessage}
							/>
							<PootsyCheckboxInput
								name="mustCallOnss"
								label={t("economic_unemployment_must_be_done_on_onss")}
								checked={mustCallOnss}
							/>
							{times.startTime.isAfter(moment().add(7, "days")) && (
								<div className="warning-eco_unemployment">
									{" "}
									{t("over_7_days_eco_unemployment")}
								</div>
							)}
						</Fragment>
					)}
			</ModalLayout>
		</InputsChangeHandler>
	)
}
