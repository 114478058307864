import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import * as actions from "../actions"

import { PootsyButton, LabeledDateTime } from "./FunctionalDesign"
import { PootsyRadioInputs, PootsySelectInput } from "./FunctionalInputs"
import SearchWorker from "./SearchWorker"
import ModalLayout from "./ModalLayout"

export const WorkshopHourModal = ({
	submit,
	data,
	closeModal,
	t,
	hideDayPeriodSelector,
	showWorkerPicker,
	hideWorkshopSelector,
	showStartDatePicker,
}) => {
	const [recurrence, setRecurrence] = useState("")
	const dispatch = useDispatch()
	const affiliate = useSelector(state => state.redData.currentAffiliate)
	const component = useSelector(state => state.redComponents.workshopHourModalComponent)

	useEffect(() => {
		return () => {
			dispatch(actions.resetWorkshopHourModal())
		}
	}, [dispatch])

	function handleChanges(changes) {
		if (changes.recurrence) {
			setRecurrence(changes.recurrence)
		}
		dispatch(actions.workshopHourModalStateChange(changes))
	}

	function handleSearchWorker(workerName) {
		handleChanges({ chosenWorker: { name: workerName, sodexo: "" } })
	}

	function handleWorkerClick(worker) {
		let newChosenWorker = {
			name: worker.attributes.display_name,
			sodexo: worker.attributes.sodexo_reference,
			affiliateWorkerId: Number(worker.id),
		}
		handleChanges({ chosenWorker: newChosenWorker })
		dispatch(actions.resetWorkerSuggestions())
	}

	let reachOptions = [
		{
			label: t("modify_one_workshop"),
			value: "one_occurrence",
		},
	]
	if (data && data.dayPeriod !== 0) {
		reachOptions.push({
			label: t("modify_all_workshop_hours"),
			value: "all_occurrences_from_date",
		})
	}

	let options = [
		{ id: "weekly", value: "7", label: t("every_week") },
		{ id: "bimonthly", value: "14", label: t("every_two_weeks") },
		{ id: "monthly", value: "28", label: t("every_four_weeks") },
	]

	if (!data) {
		options = [{ id: "once", value: "0", label: t("once") }].concat(options)
	}

	return (
		<ModalLayout
			title={t("new_workshop_hour")}
			formDivClass="WorkshopHourModal"
			closeModal={closeModal}
			withInputsChangeHandler={true}
			handleChanges={handleChanges}
		>
			{data && (
				<PootsySelectInput
					name="recurrence"
					defaultText={t("modification_reach")}
					selected={recurrence}
					options={reachOptions}
				/>
			)}
			{!hideWorkshopSelector && (
				<PootsySelectInput
					name="workshopId"
					defaultText={t("workshop")}
					selected={component.workshopId}
					options={affiliate.workshops.map(entry => ({
						label: entry.workshopName,
						value: entry.id,
					}))}
				/>
			)}
			{showWorkerPicker && (
				<SearchWorker
					onChange={handleSearchWorker}
					onClick={handleWorkerClick}
					value={component.chosenWorker.name}
				/>
			)}
			<div className="dates">
				{showStartDatePicker && (
					<LabeledDateTime
						label={t("start_date")}
						showTimePicker={false}
						showRequired={component.showRequired.startDate}
						datePickerProps={{
							name: "startDate",
							value: component.startDate,
							onChange: handleChanges,
							calendarStyle: { top: "150%", left: 0 },
						}}
					/>
				)}
				<LabeledDateTime
					label={t("start_time")}
					showDatePicker={false}
					timePickerProps={{
						value: component.start,
						name: "start",
						onChange: handleChanges,
					}}
				/>
				<LabeledDateTime
					label={t("end_time")}
					showDatePicker={false}
					timePickerProps={{
						value: component.end,
						name: "end",
						onChange: handleChanges,
						filterHours: h => h >= component.start.hours(),
						filterMinutes: m =>
							component.start.hours() < component.end.hours()
								? m >= 0 && m <= 59
								: m > component.start.minute(),
					}}
				/>
			</div>
			<div className="recurrence">
				{((data && data.dayPeriod > 0 && recurrence === "all_occurrences_from_date") ||
					!data) && (
					<PootsyRadioInputs
						groupLabel={t("recurrence")}
						groupName="dayPeriod"
						showRequired={component.showRequired.dayPeriod}
						selected={component.dayPeriod}
						options={options}
					/>
				)}
			</div>
			{component.warning && <div className="warning">{t(component.warning)}</div>}
			<PootsyButton text={t("submit")} onClick={submit} />
		</ModalLayout>
	)
}
