import React, { Component } from "react"
import { connect } from "react-redux"
import * as actions from "../actions"
// import { API_ROOT } from '../config'

import { PootsyButton, ErrorBox } from "./FunctionalDesign"

import logo from "../static/images/pootsy-pink-logo.png"

export class Login extends Component {
	handleSubmit = e => {
		if (e.key === undefined || e.key === "Enter") {
			let { email, password } = this.refs
			this.props.dispatch(actions.authenticate(email.value, password.value))
		}
	}
	acknowledgeErrors = () => {
		this.props.dispatch(actions.acknowledgeErrors())
	}
	render() {
		let { backendErrors } = this.props
		return (
			<div className="login">
				{backendErrors.show && (
					<ErrorBox
						errors={backendErrors.errors}
						errorStatus={backendErrors.status}
						acknowledge={this.acknowledgeErrors}
					/>
				)}
				<img alt="logo" className="pootsy-logo" src={logo} />
				<div className="login-box">
					<span className="title">LOGIN</span>
					<input ref="email" type="text" placeholder="Login" />
					<input
						ref="password"
						type="password"
						placeholder="Password"
						onKeyPress={this.handleSubmit}
					/>
					<PootsyButton text="Login" onClick={this.handleSubmit} />
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	backendErrors: state.backendErrors,
})
export default connect(mapStateToProps)(Login)
