import React from "react"

export const Arrow = props => (
	<svg width={11} height={11} viewBox="0 0 11 11" {...props}>
		<title>arrow copy 18</title>
		<g fill="none" fillRule="evenodd">
			<circle fill="#F2916C" cx={5.5} cy={5.5} r={5.5} />
			<path
				d="M7.383 5.811L4.68 8.514a.44.44 0 1 1-.623-.623L6.45 5.5 4.058 3.109a.44.44 0 0 1 .622-.623L7.383 5.19a.439.439 0 0 1 0 .622z"
				fill="#FFF"
				fillRule="nonzero"
			/>
		</g>
	</svg>
)
