import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"

import * as actions from "../actions"

import CustomerPaymentReminderModal from "./CustomerPaymentReminderModal"
import { ContentLayout, OptionBox, PootsyButton } from "./FunctionalDesign"
import DateFilterer from "./DateFilterer"
import Paginator from "./Paginator"
import BookingItem from "./BookingItem"
import { simpleFetch } from "../actions"

export class CustomerPayments extends Component {
	state = {
		showReminderModal: false,
		filterVoucherType: null,
	}
	componentDidMount = async () => {
		let {
			match: { params },
		} = this.props
		await this.props.dispatch(
			actions.addQueries({
				pageQuery: {
					urlName: "page",
					queryName: "page",
					mustBeSet: true,
					defaultValue: "1",
				},
				fromDateQuery: {
					urlName: "from",
					queryName: "from_date",
					mustBeSet: true,
					defaultValue: moment().subtract(2, "month").format("YYYY-MM-DD"),
				},
				toDateQuery: {
					urlName: "to",
					queryName: "to_date",
					mustBeSet: true,
					defaultValue: moment().format("YYYY-MM-DD"),
				},
			})
		)
		this.props.dispatch(actions.fetchCustomerPayments(params.id))
	}
	componentWillUnmount = () => {
		this.props.dispatch(actions.resetQuery())
	}
	toggleReminderModal = (filterVoucherType = null) => {
		this.setState(state => ({
			showReminderModal: !state.showReminderModal,
			filterVoucherType: filterVoucherType,
		}))
	}
	handleDateFiltersChange = dateFilters => {
		this.updateQuery([
			{ name: "fromDateQuery", value: dateFilters.from.format("YYYY-MM-DD") },
			{ name: "toDateQuery", value: dateFilters.to.format("YYYY-MM-DD") },
		])
	}
	updateQuery = async changes => {
		let {
			dispatch,
			match: { params },
		} = this.props
		if (changes.name !== "pageQuery") {
			if (Array.isArray(changes)) {
				changes = [...changes, { name: "pageQuery", value: 1 }]
			} else {
				changes = [changes, { name: "pageQuery", value: 1 }]
			}
		}
		await dispatch(actions.updateQuery(changes))
		dispatch(actions.fetchCustomerPayments(params.id))
	}
	sendReminder = () => {
		let {
			dispatch,
			currentCustomer: { id },
			currentQuery: {
				urlQuery: { from, to },
			},
		} = this.props
		let { filterVoucherType } = this.state
		let mFrom = moment(from).format("YYYY-MM-DD")
		let mTo = moment(to).format("YYYY-MM-DD")
		let url = "/front/affiliates/customer_contracts/" + id + "/payment_reminder"
		let init = {
			method: "POST",
			body: JSON.stringify({
				from_date: mFrom,
				to_date: mTo,
				filter_voucher_type: filterVoucherType,
			}),
		}
		dispatch(actions.simpleFetch(url, init, "SEND_CUSTOMER_PAYMENT_REMINDER"))
		this.toggleReminderModal()
	}

	generatePaymentReminderPDF = () => {
		let {
			dispatch,
			currentQuery: {
				urlQuery: { from, to },
			},
		} = this.props
		let mFrom = moment(from).format("YYYY-MM-DD")
		let mTo = moment(to).format("YYYY-MM-DD")
		let { filterVoucherType } = this.state
		let init = {
			method: "POST",
			body: JSON.stringify({
				from_date: mFrom,
				to_date: mTo,
				filter_voucher_type: filterVoucherType,
			}),
		}
		let reminderId = this.props.currentCustomer.id
		let requestMeta = { fileRequest: true, fileKeyName: "pdfUrl" }
		let mappingsURL = `/front/affiliates/customer_contracts/${reminderId}/payment_reminder/generate_pdf`
		return dispatch(simpleFetch(mappingsURL, init, "SCHEDULE_PDF", requestMeta))
	}
	render() {
		let { t } = this.context
		let {
			currentCustomer,
			currentQuery: {
				urlQuery: { from, to, page },
			},
		} = this.props
		let { showReminderModal } = this.state
		let mFrom = moment(from)
		let mTo = moment(to)
		let optionsBoxes = (
			<OptionBox boxTitle={t("customer_bio")}>
				<DateFilterer
					key="datefilter"
					onChange={this.handleDateFiltersChange}
					from={mFrom}
					to={mTo}
					rangeLimitEnd={moment()}
				/>
			</OptionBox>
		)
		return (
			<ContentLayout optionsBoxes={optionsBoxes} customClass="customer-payments">
				{showReminderModal && (
					<CustomerPaymentReminderModal
						toggleModal={this.toggleReminderModal}
						sendReminder={this.sendReminder}
						customerHasEmail={!!currentCustomer.email}
						generatePaymentReminderPDF={this.generatePaymentReminderPDF}
					/>
				)}
				{currentCustomer.paymentsStatus && (
					<div className="payments-table">
						<div className="labels">
							<div className="label">{t("voucher_type")}</div>
							<div className="label">{t("due_count")}</div>
							<div className="label">{t("missing_count")}</div>
							<div className="label">{t("reminder")}</div>
						</div>
						<div className="content">
							<div className="row">
								<div className="column">{t("electronic")}</div>
								<div className="column">
									{currentCustomer.paymentsStatus.due_electronic_sv}
								</div>
								<div className="column">
									{currentCustomer.paymentsStatus.missing_electronic_sv}
								</div>
								<div className="column">
									{currentCustomer.paymentsStatus.missing_electronic_sv !== 0 && (
										<PootsyButton
											size="small"
											text={t("send_reminder")}
											onClick={() => this.toggleReminderModal("electronic")}
										/>
									)}
								</div>
							</div>
							<div className="row">
								<div className="column">{t("paper")}</div>
								<div className="column">
									{currentCustomer.paymentsStatus.due_paper_sv}
								</div>
								<div className="column">
									{currentCustomer.paymentsStatus.missing_paper_sv}
								</div>
								<div className="column">
									{currentCustomer.paymentsStatus.missing_paper_sv !== 0 && (
										<PootsyButton
											size="small"
											text={t("send_reminder")}
											onClick={() => this.toggleReminderModal("paper")}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				)}
				{currentCustomer.pastBookings && (
					<div className="bookings">
						{currentCustomer.pastBookings.bookings.map(entry => (
							<BookingItem key={entry.id} booking={entry} />
						))}
						<Paginator
							name="pageQuery"
							currPage={page}
							totalPages={currentCustomer.pastBookings.totalPages}
							goToPage={this.updateQuery}
						/>
					</div>
				)}
			</ContentLayout>
		)
	}
}

CustomerPayments.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currentCustomer: state.redData.currentCustomer,
	currentQuery: state.currentURLQuery,
})
export default connect(mapStateToProps)(CustomerPayments)
