import React, { Fragment, useState } from "react"
import { useSelector } from "react-redux"

import * as actions from "../actions"

import { PootsyButton, LabeledDateTime } from "./FunctionalDesign"
import { PootsyTextArea, PootsyCheckboxInput } from "./FunctionalInputs"
import ModalLayout from "./ModalLayout"
import ImpactedBookingsModal from "./ImpactedBookingsModal"

import moment from "moment"

export const CustomerAbsenceModal = ({
	dispatch,
	absenceStart,
	absenceEnd,
	closeModal,
	customerId,
	linkedCustomers,
	t,
}) => {
	const [times, setTimes] = useState({
		startDate: absenceStart || moment().set({ hours: 0, minutes: 0, seconds: 0 }),
		startTime: absenceStart || moment().set({ hours: 0, minutes: 0, seconds: 0 }),
		endDate: absenceEnd || moment().set({ hours: 23, minutes: 55, seconds: 0 }),
		endTime: absenceEnd || moment().set({ hours: 23, minutes: 55, seconds: 0 }),
	})

	const component = useSelector(state => state.redComponents.customerAbsenceModalComponent)
	const impactedBookings = useSelector(state => state.redData.impactedBookings)
	const impactedBookingsModal = useSelector(state => state.redComponents.impactedBookingsModal)

	function handleStartTimeChanges(changes) {
		let newTimes = Object.assign({}, times)
		if (changes.absenceStartTime.isAfter(times.endTime)) {
			let newEndTime = newTimes.endTime
				.clone()
				.add(changes.absenceStartTime.diff(times.endTime, "minutes") + 5, "minutes")
			newTimes["endTime"] = newEndTime
		}
		newTimes["startTime"] = changes.absenceStartTime
		setTimes(newTimes)
	}

	function handleEndTimeChanges(changes) {
		let newTimes = Object.assign({}, times)
		if (changes.absenceEndTime.isBefore(times.startTime)) {
			newTimes["startTime"] = changes.absenceEndTime
		}
		newTimes["endTime"] = changes.absenceEndTime
		setTimes(newTimes)
	}

	function handleStartDateChanges(changes) {
		let newTimes = Object.assign({}, times)
		let diff = changes.absenceStartDate.diff(times.startTime, "minutes")
		let newStartDate = newTimes.startTime.clone().add(diff + 1, "minutes")
		if (newStartDate.isAfter(times.endTime)) {
			let newEndDate = newTimes.endTime
				.clone()
				.add(newStartDate.diff(times.endTime, "minutes") + 1441, "minutes")
			newTimes["endTime"] = newEndDate
			newTimes["endDate"] = newEndDate
		}
		newTimes["startTime"] = newStartDate
		newTimes["startDate"] = newStartDate
		setTimes(newTimes)
	}

	function handleEndDateChanges(changes) {
		let newTimes = Object.assign({}, times)
		let diff = changes.absenceEndDate.diff(times.endTime, "minutes")
		let newEndDate = newTimes.endTime.clone().add(diff + 1, "minutes")
		newTimes["endTime"] = newEndDate
		newTimes["endDate"] = newEndDate
		setTimes(newTimes)
	}

	function handleBeginningOfDay() {
		let newTimes = Object.assign({}, times)
		newTimes["startTime"] = newTimes.startTime.clone().set({ hours: 0, minutes: 0, seconds: 0 })
		setTimes(newTimes)
	}

	function handleEndOfDay() {
		let newTimes = Object.assign({}, times)
		newTimes["endTime"] = newTimes.endTime.clone().set({ hours: 23, minutes: 55, seconds: 0 })
		setTimes(newTimes)
	}

	function handleChanges(changes) {
		dispatch(actions.customerAbsenceModalStateChange(changes))
	}

	async function submitAbsence() {
		let init = {
			method: "POST",
			body: JSON.stringify({
				start_time: times.startTime.format(),
				end_time: times.endTime.format(),
				note: component.absenceNote,
				add_to_all_linked_customers: component.addToAllLinkedCustomers,
				force_unlink: impactedBookingsModal.forceUnlink,
				impacted_bookings_actions: actions.denormalizeImpactedBookingActions(
					impactedBookings
				),
			}),
		}
		let url = "/front/affiliates/customers/unavailabilities/?customer_id=" + customerId
		let { actionType } = await dispatch(
			actions.simpleFetch(url, init, "CREATE_CUSTOMER_ABSENCE")
		)
		if (actionType === actions.CREATE_CUSTOMER_ABSENCE_SUCCESS) {
			closeModal()
		}
	}

	let modalButtons = [
		<PootsyButton key="a" text={t("save")} onClick={submitAbsence} />,
		<PootsyButton key="b" text={t("cancel")} theme="cancel" onClick={closeModal} />,
	]

	return (
		<ModalLayout
			customClass="CustomerAbsenceModal"
			title={t("create_new_absence")}
			buttons={modalButtons}
			closeModal={closeModal}
			withInputsChangeHandler={true}
			handleChanges={handleChanges}
		>
			{impactedBookingsModal.showImpactedBookingsModal && (
				<ImpactedBookingsModal
					onSubmit={submitAbsence}
					triggerActionExpected={actions.CREATE_CUSTOMER_ABSENCE_FAILURE}
				/>
			)}
			<div className="start-date-group">
				<LabeledDateTime
					label={t("start_date")}
					timePickerProps={{
						value: times.startTime,
						name: "absenceStartTime",
						onChange: handleStartTimeChanges,
					}}
					datePickerProps={{
						value: times.startDate,
						name: "absenceStartDate",
						onChange: handleStartDateChanges,
					}}
				/>
				<PootsyButton
					text={t("beginning_of_day")}
					theme="cancel"
					size="small"
					onClick={handleBeginningOfDay}
				/>
			</div>
			<div className="end-date-group">
				<LabeledDateTime
					label={t("end_date")}
					timePickerProps={{
						value: times.endTime,
						name: "absenceEndTime",
						onChange: handleEndTimeChanges,
					}}
					datePickerProps={{
						value: times.endDate,
						name: "absenceEndDate",
						onChange: handleEndDateChanges,
						disableDay: day => day.isBefore(times.startTime, "day"),
					}}
				/>
				<PootsyButton
					text={t("end_of_day")}
					theme="cancel"
					size="small"
					onClick={handleEndOfDay}
				/>
			</div>

			<PootsyTextArea label={t("comment")} name="absenceNote" value={component.absenceNote} />

			{linkedCustomers.length > 0 && (
				<Fragment>
					<PootsyCheckboxInput
						label={t("add_absence_to_all_linked_customers")}
						name="addToAllLinkedCustomers"
						checked={component.addToAllLinkedCustomers}
					/>
					<div className="linked-customers">
						{linkedCustomers.map(entry => (
							<div key={entry.id} className="linked-customer">
								{entry.attributes.display_name}
							</div>
						))}
					</div>
				</Fragment>
			)}
		</ModalLayout>
	)
}
