import iso3166 from "../iso-3166-1.alpha-2.js"

export const countryCodes = iso3166
const yesNoOptions = [
	{ label: "no", value: "0" },
	{ label: "yes", value: "1" },
]

let text = "text"
let select = "select"
let number = "number"
let date = "datepicker"

let niss_categories_options = [
	{ value: "010", label: "horticulture_blue_collar" },
	{ value: "011", label: "blue_collar_forfait" },
	{ value: "012", label: "handicap_blue_collar" },
	{ value: "013", label: "taxi_driver" },
	{ value: "014", label: "blue_collar_cat_1_cat_2" },
	{ value: "015", label: "ordinary_blue_collar" },
	{ value: "020", label: "student_blue_collar_special_category" },
	{ value: "022", label: "student_blue_c_spec_cat" },
	{ value: "023", label: "blue_collar_subv" },
	{ value: "024", label: "blue_collar_subv_real" },
	{ value: "025", label: "blue_collar_subv_handicap" },
	{ value: "026", label: "blue_colar_student_014" },
	{ value: "027", label: "blue_colar_student_015" },
	{ value: "028", label: "blue_collar_subv_handicap_forfait" },
	{ value: "029", label: "blue_collar_subv_forfait" },
	{ value: "035", label: "apprentice_blue_collar" },
	{ value: "043", label: "house_personel_over_19" },
	{ value: "044", label: "house_personel_under_19" },
	{ value: "045", label: "domestic_blue_collar" },
	{ value: "046", label: "artist" },
	{ value: "047", label: "part_time_student" },
	{ value: "099", label: "out_of_category_niss" },
	{ value: "439", label: "apprentice" },
	{ value: "480", label: "student_employee_special_category" },
	{ value: "484", label: "contractual_employee_subv" },
	{ value: "485", label: "contractual_employee_subv_handicap" },
	{ value: "486", label: "student_employee_horeca_forfait" },
	{ value: "487", label: "student_employee_apprenticeship" },
	{ value: "490", label: "occasionnal_employee_horeca" },
	{ value: "492", label: "employee_less_valid" },
	{ value: "493", label: "academic_personal" },
	{ value: "494", label: "paid_sportsman" },
	{ value: "495", label: "employee_normal_category" },
	{ value: "496", label: "employee_forfait_horeca" },
	{ value: "497", label: "recognized_host_parents" },
	{ value: "498", label: "doctorat_beneficiary_outside_eu" },
	{ value: "671", label: "civil_servant_health_benefit_fam_alloc" },
	{ value: "675", label: "civil_servant_health_benefit" },
	{ value: "840", label: "blue_collar_student" },
	{ value: "841", label: "student_employee" },
	{ value: "876", label: "lincence_mal_inv" },
	{ value: "877", label: "licence_benefit" },
	{ value: "879", label: "prepension_879" },
	{ value: "883", label: "prepension_883" },
]

export default [
	{
		categoryName: "identification",
		inputs: [
			{
				label: "birth_country",
				inputType: select,
				required: true,
				options: countryCodes,
			},
			{
				label: "birth_place",
				inputType: text,
				required: true,
			},
			{
				label: "worker_category",
				inputType: select,
				options: [
					{ label: "director", value: "00" },
					{ label: "pensionner", value: "01" },
					{ label: "blue_collar", value: "02" },
					{ label: "blue_collar_apprentice", value: "03" },
					{ label: "white_collar_apprentice", value: "04" },
					{ label: "other_unsubmitted", value: "05" },
					{ label: "other_unsubmitted_with_schedule", value: "06" },
					{ label: "domestic", value: "07" },
					{ label: "blue_collar_student", value: "08" },
					{ label: "employee", value: "09" },
					{ label: "prepensionner", value: "10" },
					{ label: "statutory_stagg", value: "12" },
					{ label: "apl_other", value: "13" },
					{ label: "artist", value: "14" },
					{ label: "student_employee", value: "15" },
				],
			},
		],
	},
	{
		categoryName: "personnal_information",
		inputs: [
			{
				label: "handicap_worker",
				inputType: select,
				options: yesNoOptions,
			},
			{
				label: "residence_status",
				inputType: select,
				options: [
					{ label: "resident", value: "0" },
					{ label: "resident_expat", value: "1" },
					{ label: "borderer", value: "2" },
					{ label: "non_resident", value: "3" },
				],
			},
			{
				label: "pp_modalities",
				inputType: select,
				options: [
					{ label: "precompte_pro_resident", value: "0" },
					{ label: "no_precompte_pro", value: "1" },
				],
			},
			{
				label: "handicap_eta",
				inputType: select,
				options: yesNoOptions,
			},
			{
				label: "expatrie",
				inputType: select,
				options: yesNoOptions,
			},
			{
				label: "regime_frais_depl",
				inputType: select,
				options: [
					{ label: "all_inclusive", value: "F" },
					{ label: "real", value: "R" },
				],
			},
			{
				label: "montant_immunise",
				inputType: text,
			},
			{
				label: "mobile_phone",
				inputType: text,
			},
			{
				label: "level_studies",
				inputType: select,
				options: [
					{ label: "primary_educ", value: "1" },
					{ label: "lower_secondary_educ", value: "1A" },
					{ label: "2nd_degree_4th_gen_educ_tech_art_prof", value: "1B" },
					{ label: "apprentice_mid_class_3rd_lower_secondary_educ", value: "1C" },
					{ label: "6th_secondary_educ_vocational", value: "1D" },
					{ label: "7th_diploma_specialization", value: "1F" },
					{ label: "cert_qualification_4th_gen_tec_art_prof", value: "1G" },
					{ value: "2", label: "upper_secondary" },
					{ value: "2A", label: "2a _3rd_y_3rd_degree" },
					{ value: "2B", label: "4th_vocation_secondary" },
					{ value: "2C", label: "cert_apprentice_business_upper_secondary" },
					{ value: "3A", label: "superior_short_type" },
					{ value: "3B", label: "non_academic_long_type" },
					{ value: "4", label: "university_studies" },
					{ value: "5", label: "other" },
				],
			},
		],
	},
	{
		categoryName: "family",
		inputs: [
			{
				label: "civil_status",
				inputType: select,
				options: [
					{ label: "single", value: "0" },
					{ label: "married", value: "1" },
					{ label: "divorced", value: "2" },
					{ label: "legally_separated", value: "3" },
					{ label: "widowed", value: "4" },
					{ label: "separated", value: "5" },
					{ label: "legal_cohabitant", value: "7" },
				],
			},
			{
				label: "attributaire_alloc",
				inputType: select,
				options: yesNoOptions,
			},
			{
				label: "num_alloc_familial",
				inputType: text,
				required: true,
				show: values => values.attributaire_alloc === "2",
			},

			{
				label: "first_name_spouse",
				inputType: select,
				options: yesNoOptions,
				show: values => values.civil_status === "1" || values.civil_status === "7",
			},

			{
				label: "last_name_spouse",
				inputType: select,
				options: yesNoOptions,
				show: values => values.civil_status === "1" || values.civil_status === "7",
			},
			{
				label: "handicap_spouse",
				inputType: select,
				options: yesNoOptions,
				show: values => values.civil_status === "1" || values.civil_status === "7",
			},
			{
				label: "dependent_spouse",
				inputType: select,
				options: yesNoOptions,
				show: values => values.civil_status === "1" || values.civil_status === "7",
			},
			{
				label: "number_dependent_children",
				inputType: number,
			},
			{
				label: "number_handicap_children",
				inputType: number,
			},
			{
				label: "over65_dependent",
				inputType: number,
			},
			{
				label: "over65_handicap",
				inputType: number,
			},
			{
				label: "other_dependent",
				inputType: number,
			},
			{
				label: "other_handicap",
				inputType: number,
			},
		],
	},
	{
		categoryName: "bank",
		inputs: [
			{
				label: "payment_type",
				inputType: select,
				options: [
					{ label: "bank_list_isabel", value: "2" },
					{ label: "comptant", value: "3" },
				],
			},
			{
				label: "dependent_children_immu",
				inputType: number,
			},
			{
				label: "iban",
				inputType: text,
				required: true,
				show: values => values.payment_type === "2",
			},
		],
	},
	{
		categoryName: "contrat",
		inputs: [
			{
				label: "contract_number",
				type: text,
			},
			{
				label: "type_contract",
				type: select,
				options: [
					{ label: "contract_indefinite_term", value: "01" },
					{ label: "contract_definite_term_under_3_months", value: "02" },
					{ label: "contract_definite_term_over_3_months", value: "03" },
					{ label: "work_under_3_months", value: "04" },
					{ label: "work_over_3_months", value: "05" },
					{ label: "replacement_under_3_months", value: "06" },
					{ label: "replacement_over_3_months", value: "07" },
				],
			},
			{
				label: "start_contract_date",
				inputType: date,
			},
			{
				label: "real_start_contract_date",
				inputType: date,
			},
			{
				label: "end_contract_date",
				inputType: date,
			},
			{
				label: "end_reason",
				inputType: select,
				options: [
					{ label: "cancelled_contract", value: "A" },
					{ label: "change_fraction_occupation", value: "CFO" },
					{ label: "status_change", value: "CST" },
					{ label: "deceased", value: "D" },
					{ label: "employers_deceased", value: "DE" },
					{ label: "prepension", value: "F" },
					{ label: "serious_misconduct", value: "FG" },
					{ label: "major_force", value: "FM" },
					{ label: "medical_major_force", value: "FMM" },
					{ label: "end_of_contract", value: "H" },
					{ label: "pension", value: "P" },
					{ label: "anticip_pension", value: "PA" },
					{ label: "medical_anticip_pension", value: "PAM" },
					{ label: "dismissal", value: "SI" },
					{ label: "quit", value: "SV" },
					{ label: "legal_transformation", value: "TJE" },
					{ label: "unknown", value: "Y" },
				],
				show: values => values.end_contract_date,
			},
			{
				label: "date_notice_end_contract",
				inputType: date,
			},
			{
				label: "prepension_type",
				inputType: text,
				show: values => values.worker_category === "10",
			},
			{
				label: "date_prepension",
				inputType: date,
				show: values => values.worker_category === "10" || values.end_reason === "F",
			},
			{
				label: "foreign_pension_benefit",
				inputType: select,
				options: yesNoOptions,
			},
			{
				label: "legal_pension",
				inputType: select,
				options: yesNoOptions,
			},
			{
				label: "indemnity_type",
				inputType: text,
				show: values => values.worker_category === "10",
			},
			{
				label: "desactivation_cotisation_preparation",
				inputType: select,
				options: yesNoOptions,
				show: values => values.worker_category === "10",
			},
			{
				label: "replacing_prepension",
				inputType: select,
				options: yesNoOptions,
				show: values => values.worker_category === "10",
			},
			{
				label: "remuneration_before_prepension",
				inputType: text,
				show: values => values.worker_category === "10" || values.end_reason === "F",
			},
			{
				label: "commission_paritaire",
				inputType: text,
			},
			{
				label: "contract_index",
				inputType: text,
			},
			{
				label: "status_hired",
				inputType: select,
				options: [
					{ label: "other", value: "00" },
					{ label: "contractual_apprentice", value: "02" },
					{ label: "changing_status", value: "01" },
					{ label: "freelancer", value: "03" },
					{ label: "job_seeker", value: "04" },
					{ label: "complete_unemployment", value: "05" },
					{ label: "partial_unemployment", value: "06" },
					{ label: "minimex_beneficiary", value: "07" },
					{ label: "social_benefitor", value: "08" },
					{ label: "old_interim_same_employer", value: "14" },
				],
			},
			{
				label: "category_niss",
				inputType: select,
				options: niss_categories_options,
			},
			{
				label: "niss_primary_status",
				inputType: select,
				options: [
					{ label: "normal", value: "AA" },
					{ label: "contractual_subv", value: "CS" },
				],
			},
			{
				label: "niss_secondary_status",
				inputType: select,
				options: [
					{ label: "normal", value: "AA" },
					{ label: "contractual_subv", value: "CS" },
					{ label: "student_worker", value: "ET" },
					{ label: "health_benefit_fam_alloc", value: "SA" },
					{ label: "none", value: "SO" },
					{ label: "health_benefit", value: "SS" },
					{ label: "other", value: "ZZ" },
				],
			},
			{
				label: "niss_special_status",
				inputType: select,
				options: [
					{ label: "artist_other", value: "AA" },
					{ label: "artist_singer", value: "AC" },
					{ label: "artist_musician", value: "AM" },
					{ label: "apprentice_middle_class", value: "A1" },
					{ label: "apprentice_industrial", value: "A2" },
					{ label: "apprentice_company_manager", value: "A3" },
					{ label: "student_soc_pro_insert", value: "A4" },
					{ label: "apprenticeship_immersion_pro", value: "A5" },
					{ label: "voluntary_firefighter", value: "B" },
					{ label: "caretaker", value: "C" },
					{ label: "fbi_worker", value: "FBI" },
					{ label: "work_reduce_prest", value: "LP" },
					{ label: "doctor", value: "M" },
					{ label: "police_staff", value: "P" },
					{ label: "civil_police_staff", value: "PC" },
					{ label: "educ_instit_staff", value: "PE" },
					{ label: "prepension_employee_head_fam", value: "Q1" },
					{ label: "prepension_employee", value: "Q2" },
					{ label: "prepension_employee_part_time_head_fam", value: "Q3" },
					{ label: "prepension_employee_part_time", value: "Q4" },
					{ label: "none", value: "SO" },
					{ label: "definite_firefighter", value: "SP" },
					{ label: "temporary_worker", value: "T" },
					{ label: "health_worker", value: "V" },
					{ label: "prepensionned_worker_head_fam", value: "Y1" },
					{ label: "prepensionned_worker", value: "Y2" },
					{ label: "prepensionned_worker_part_time_head_fam", value: "Y3" },
					{ label: "prepensionned_worker_part_time", value: "Y4" },
				],
			},
			{
				label: "complementary_unemployment",
				inputType: select,
				options: yesNoOptions,
			},
			{
				label: "representant",
				inputType: select,
				options: yesNoOptions,
			},
			{
				label: "real_function",
				inputType: text,
				required: true,
			},
			{
				label: "unit_length_trial_period",
				inputType: select,
				options: [
					{ label: "days", value: "J" },
					{ label: "months", value: "M" },
				],
			},
			{
				label: "length_trial_period",
				inputType: number,
				required: true,
			},

			{
				label: "end_date_trial_eriod",
				inputType: date,
			},
			{
				label: "type_niss_reduction",
				inputType: select,
				options: [
					{ label: "none", value: "NONE" },
					{ label: "difficult_unemployed_reinsert", value: "BC" },
					{ label: "scientific_research", value: "WO" },
					{ label: "eco_soc_insert_45y_18m_9m", value: "3240" },
					{ label: "eco_soc_insert_45y_36m_18m", value: "3241" },
					{ label: "eco_soc_insert_45y_9m", value: "3250" },
					{ label: "long_term_unemployed_45y_18m", value: "3200" },
					{ label: "long_term_unemployed_45y_36m", value: "3201" },
					{ label: "long_term_unemployed_45y_54m", value: "3202" },
					{ label: "long_term_unemployed_45y_90m", value: "3203" },
					{ label: "long_term_unemployed_45y_9m", value: "3210" },
					{ label: "long_term_unemployed_over_45y_18m", value: "3211" },
					{ label: "PTP_45y_12m", value: "3220" },
					{ label: "PTP_45y_24m", value: "3221" },
					{ label: "PTP_45_y_12m", value: "3230" },
					{ label: "PTP_45_y_24m", value: "3231" },
					{ label: "CPE_1_less_qualified", value: "3410" },
					{ label: "CPE_young_worker_18_y", value: "3430" },
					{ label: "convention_1rst_job", value: "K" },
					{ label: "restructuration", value: "X600" },
					{ label: "CPE_not_qualified", value: "3411" },
					{ label: "APS_longterm_unemployed_45y_36m", value: "3204" },
					{ label: "APS_longterm_unemployed_45y_9m", value: "3212" },
					{ label: "young_worker_19to29y", value: "3413" },
					{ label: "reduce_cotis_staff_restruct", value: "601" },
					{ label: "restructuration_45y", value: "3601" },
					{ label: "restructuration_45y", value: "3611" },
					{ label: "4_days_week", value: "3510" },
					{ label: "work_time_reduction_36", value: "3500_36" },
					{ label: "work_time_reduction_37", value: "3500_37" },
					{ label: "CPE_qualified", value: "3412" },
					{ label: "reduction_replacement_public_sector", value: "4100" },
					{ label: "reduction_house_staff", value: "4200" },
					{ label: "reduction_artist", value: "4300" },
					{ label: "reduction_child_caretaker", value: "4400" },
					{ label: "reduction_horeca", value: "3900" },
				],
			},
			{
				label: "direction_level",
				inputType: select,
				options: yesNoOptions,
				default: () => "0",
			},
			{
				label: "paid_sodexo_vouchers",
				inputType: select,
				options: yesNoOptions,
			},
			{
				label: "risk_class",
				inputType: select,
				options: [
					{ label: "worker_no_movement", value: "001" },
					{ label: "worker_on_site", value: "002" },
					{ label: "caretaker", value: "003" },
					{ label: "cleaning_maintenance_staff", value: "004" },
					{ label: "kitchen_staff", value: "005" },
					{ label: "driver", value: "006" },
					{ label: "employee_no_movement", value: "401" },
					{ label: "employee_occasionnal_missions", value: "402" },
					{ label: "employee_frequent_missions", value: "403" },
					{ label: "representant_coursier", value: "404" },
					{ label: "manual_worker_employee_supervisor", value: "405" },
					{ label: "home_working_employee", value: "406" },
					{ label: "health_staff", value: "407" },
					{ label: "sales_staff", value: "408" },
					{ label: "footballer_subject_renumeration", value: "409" },
					{ label: "footballer_not_subject_renumeration_under_1240e", value: "410" },
					{ label: "footballer_not_subject_renumeration_over_1240e", value: "411" },
					{ label: "other_sports_than_football", value: "412" },
				],
			},
			{
				label: "gross_salary",
				inputType: "text",
				required: true,
			},
			{
				label: "type_rate",
				inputType: select,
				options: [
					{ label: "hourly_salary", value: "0" },
					{ label: "periodic_package", value: "1" },
				],
				show: values =>
					[
						"00",
						"13",
						"14",
						"05",
						"06",
						"02",
						"03",
						"04",
						"07",
						"09",
						"10",
						"01",
						"08",
						"12",
					].includes(values.worker_category),
				required: true,
			},
			{
				label: "variable_renumeration",
				inputType: select,
				options: yesNoOptions,
				show: values =>
					["13", "02", "07", "09", "11", "12"].includes(values.worker_category),
				required: true,
			},
			{
				label: "renumeration_js",
				inputType: select,
				options: yesNoOptions,
				required: true,
			},
			{
				label: "renumation_other_days",
				inputType: select,
				options: yesNoOptions,
				required: true,
			},
			{
				label: "variable_schedule",
				inputType: select,
				options: yesNoOptions,
			},
			{
				label: "schedule_type",
				inputType: select,
				options: [
					{ label: "cyclic", value: "C" },
					{ label: "weekly", value: "H" },
				],
			},
		],
	},
	{
		categoryName: "Activa",
		inputs: [
			{
				label: "activa",
				inputType: select,
				options: yesNoOptions,
			},
			{
				label: "start_date_activa",
				inputType: "datepicker",
				show: values => values.activa,
			},
			{
				label: "real_start_date_activa",
				inputType: "datepicker",
				show: values => values.activa,
			},
			{
				label: "activa_duration",
				inputType: select,
				options: [
					{ value: "NONE", label: "none" },
					{ value: "6", label: "6_months_350_euros" },
					{ value: "06_1", label: "6_months_500_euros" },
					{ value: "12", label: "12_months_500_euros" },
					{ value: "12_1", label: "12_months_1100_euros" },
					{ value: "12_2", label: "12_months_1000_euros" },
					{ value: "12_3", label: "12_months_750_euros" },
					{ value: "12_4", label: "12_months_700_euros" },
					{ value: "16", label: "16_months_500_euros" },
					{ value: "24", label: "24_months_500_euros" },
					{ value: "24_1", label: "24_months_1100_euros" },
					{ value: "24_2", label: "24_months_1000_euros" },
					{
						value: "24_3",
						label: "12_months_500_euros_6_months_250_euros_6_months_125_euros",
					},
					{ value: "28_3", label: "12_months_750_euros_16_months_500_euros" },
					{ value: "30", label: "30_months_500_euros" },
					{
						value: "30_1",
						label: "6_months_350_euros_12_months_800_euros_12_months_350_euros",
					},
					{ value: "36", label: "36_months_500_euros" },
					{
						value: "36_1",
						label: "24_months_500_euros_6_months_250_euros_6_months_125_euros",
					},
					{ value: "36_2", label: "12_months_750_euros_24_months_600_euros " },
				],
			},
		],
	},
	{
		categoryName: "Sine",
		inputs: [
			{
				label: "sine_number",
				inputType: "select",
				options: [
					{ label: "sine_3240", value: "3240" },
					{ label: "sine_3241", value: "3241" },
					{ label: "sine_9240", value: "9240" },
					{ label: "sine_9241", value: "9241" },
				],
			},
			{
				label: "sine_duration",
				inputType: select,
				options: [
					{ label: 12, value: "12" },
					{ label: 18, value: "18" },
					{ label: 24, value: "24" },
					{ label: 36, value: "36" },
				],
				show: values => values.sine_number,
			},
			{
				label: "sine_start_date",
				inputType: "datepicker",
				show: values => values.sine_number,
			},
			{
				label: "sine_end_date",
				inputType: "datepicker",
				show: values => values.sine_number,
			},
		],
	},
	{
		categoryName: "Apprenticeship",
		inputs: [
			{
				label: "apprenticeship_contract_number",
				inputType: text,
				show: values => values.worker_category === "4",
			},
			{
				label: "apprenticeship_year",
				inputType: text,
				show: values => values.worker_category === "4",
			},
			{
				label: "apprenticeship_duration",
				inputType: text,
				show: values => values.worker_category === "4",
			},
			{
				label: "apprenticeship_language",
				inputType: text,
				show: values => values.worker_category === "4",
			},
			{
				label: "apprenticeship_category",
				inputType: text,
				show: values => values.worker_category === "4",
			},
		],
	},
]
