import React, { Component } from "react"

import { PootsyTextInput } from "./FunctionalInputs"

class CustomSelect extends Component {
	state = {
		opened: false,
		inputValue: "",
		initialList: [],
		displayedList: [],
	}
	UNSAFE_componentWillMount = () => {
		let { valuesGroups, valuesList } = this.props
		this.updateInitialList(valuesGroups, valuesList)
	}
	UNSAFE_componentWillReceiveProps = newProps => {
		let { valuesGroups, valuesList } = newProps
		this.updateInitialList(valuesGroups, valuesList)
	}
	componentWillUnmount = () => {
		document.removeEventListener("click", this.handleClickOutside)
	}
	updateInitialList = (valuesGroups, valuesList) => {
		const initialList = [
			...valuesGroups.map(group => ({
				label: group.label,
				type: "group",
				values: group.values,
			})),
			...valuesList.map(entry => ({
				label: entry.label,
				type: "value",
				value: entry.value,
			})),
		]
		this.setState({ displayedList: initialList, initialList })
	}
	handleInputFocus = () => {
		this.setState({ opened: true })
		document.addEventListener("click", this.handleClickOutside)
	}
	handleClickOutside = e => {
		e.stopPropagation()
		let wrapper = this.refs.wrapper
		let isClickInside = wrapper.contains(e.target)
		if (!isClickInside) {
			this.setState({ opened: false })
			document.removeEventListener("click", this.handleClickOutside)
		}
	}
	handleInputChange = e => {
		e.stopPropagation()
		let { value } = e.target
		let { displayedList, initialList } = this.state
		let newDisplayedList
		if (value !== "") {
			newDisplayedList = displayedList.filter(
				entry => entry.type !== "group" && entry.value.toString().indexOf(value) > -1
			)
		} else {
			newDisplayedList = initialList
		}
		this.setState({ inputValue: value, displayedList: newDisplayedList })
	}
	handleEntryClick = e => {
		let { index } = e.target.dataset
		let { currentValues, onChange, name, valuesList } = this.props
		let { displayedList, initialList } = this.state
		let entry = displayedList[Number(index)]
		let newValues = [...currentValues]
		if (entry.type === "group") {
			let flatValuesList = valuesList.map(entry => entry.value)
			entry.values.forEach(value => {
				if (!newValues.includes(value) && flatValuesList.includes(value)) {
					newValues.push(value)
				}
			})
			onChange({ [name]: newValues })
		} else if (entry.type === "value") {
			if (!newValues.includes(entry.value)) {
				newValues.push(entry.value)
			}
			onChange({ [name]: newValues })
		}
		this.setState({ opened: false, inputValue: "", displayedList: initialList })
	}
	handleRemoveCurrentValue = e => {
		let { value } = e.target.dataset
		let { currentValues, name, onChange } = this.props
		let newValues = currentValues.filter(currValue => currValue.toString() !== value)
		onChange({ [name]: newValues })
	}
	render() {
		let { selectLabel, currentValues, showRequired, isRequired, valuesList } = this.props
		let { opened, inputValue, displayedList } = this.state
		return (
			<div className="custom-select" ref="wrapper">
				<PootsyTextInput
					label={selectLabel}
					value={inputValue}
					onChange={this.handleInputChange}
					onFocus={this.handleInputFocus}
					showRequired={showRequired}
					isRequired={isRequired}
				/>
				{opened && (
					<div className="select-dropdown">
						{displayedList.map((entry, index) => (
							<div
								key={index}
								className="dropdown-entry"
								onClick={this.handleEntryClick}
								data-index={index}
							>
								{entry.label}
							</div>
						))}
					</div>
				)}
				<div className="current-values">
					{valuesList
						.filter(entry => currentValues.includes(entry.value))
						.map(currentValue => (
							<div
								key={currentValue.value}
								className="current-value"
								data-value={currentValue.value}
								onClick={this.handleRemoveCurrentValue}
							>
								{currentValue.label}
							</div>
						))}
				</div>
			</div>
		)
	}
}

CustomSelect.defaultProps = {
	selectLabel: "",
	valuesGroups: [],
	valuesList: [],
	currentValues: [],
	showRequired: false,
	isRequired: false,
}

export default CustomSelect
