import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import * as actions from "../actions"

import ChatRoom from "./ChatRoom"
import { ContentLayout } from "./FunctionalDesign"

class WorkerChat extends Component {
	UNSAFE_componentWillReceiveProps = newProps => {
		if (newProps.match.params.id !== this.props.match.params.id) {
			this.fetchMessages(newProps.match.params.id)
		}
	}
	componentDidMount = () => {
		this.fetchMessages(this.props.match.params.id)
	}
	fetchMessages = id => {
		let init = { method: "GET" }
		let url = "/front/affiliates/affiliate_workers/" + id + "/messages"
		this.props.dispatch(actions.simpleFetch(url, init, "FETCHWORKERMESSAGES"))
	}
	sendMessage = (message, chatImg) => {
		let { dispatch, currentWorker } = this.props
		if (message === "" && chatImg === "") {
			return
		}
		let data = { content: message }
		if (chatImg) {
			data.images = [{ base64_content: chatImg.file, filename: chatImg.filename }]
		}
		let init = { method: "POST", body: JSON.stringify(data) }
		dispatch(
			actions.simpleFetch(
				"/front/affiliates/affiliate_workers/" + currentWorker.id + "/messages",
				init,
				"SUBMITWORKERMESSAGE"
			)
		)
	}
	render() {
		let { chat } = this.props
		return (
			<ContentLayout customClass="cleaner-chat">
				<ChatRoom
					customClass="cleaner-chat-room"
					messages={chat.messages}
					sendMessage={this.sendMessage}
				/>
			</ContentLayout>
		)
	}
}

WorkerChat.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currentWorker: state.redData.currentWorker,
	chat: state.redData.cleanerChat,
})
export default connect(mapStateToProps)(WorkerChat)
