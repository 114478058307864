import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { ContentLayout, OptionBox, PootsyButton, LabeledDateTime } from "./FunctionalDesign"
import { PootsySelectInput } from "./FunctionalInputs"
import SodexoConnectionSettings from "./SodexoConnectionSettings"
import SodexoConnectionStatus from "./SodexoConnectionStatus"
import SodexoConnectionTokenButton from "./SodexoConnectionTokenButton"
import { parseQuery, stringifyQuery } from "../helpers/qs"
import * as actions from "../actions"
import ModalLayout from "../components/ModalLayout"
import * as ActionTypes from "../actions"

import moment from "moment"

/**
 * Allow you to view the status, connect to sodexo and select specific settings
 */
class SettingsSodexo extends Component {
	constructor(props) {
		super(props)
		let parsed = parseQuery(props.location.search)
		if ("code" in parsed) {
			let url = "/front/affiliates/sodexo/sodexo_token"
			let init = {
				method: "POST",
				body: JSON.stringify({ code: parsed.code }),
			}
			this.props.dispatch(actions.simpleFetch(url, init, "SODEXO_CONNECTION_TOKEN"))
		}
		this.state = {
			showManualCSVModal: false,
			date: moment().subtract(1, "day"),
			countNonEncodedBookings: null,
			countNonEncodedLaundryOrders: null,
		}
	}

	handleChangeSquad = e => {
		let squadId = e.target.value

		this.handleChanges({ selectedSquadId: squadId })
		this.fetchNonEncodedBookingsAndLaundryOrders(squadId)
	}

	handleChanges = e => {
		this.setState({ ...e }, () => {
			if (!e.selectedSquadId) {
				// the request when the user changes the squad is already taken by handleChangeSquad
				let squadId = this.state.selectedSquadId
				this.fetchNonEncodedBookingsAndLaundryOrders(squadId)
			}
		})
	}

	fetchNonEncodedBookingsAndLaundryOrders = async squad => {
		let { t } = this.context

		let data = { date: this.state.date.format("YYYY-MM-DD") }
		if (squad) {
			if (squad === t("all_squads")) {
				squad = "all_squads"
			} else {
				data.filter_squad_ids = [squad]
			}
		}

		this.props.dispatch(ActionTypes.showSpinner("sodexo_non_encoded_data", 0))

		let url =
			"/front/affiliates/sodexo/sodexo_manual_csv/non_encoded_data" + stringifyQuery(data)
		let init = {
			method: "GET",
		}
		let { data: responseData, actionType } = await this.props.dispatch(
			actions.simpleFetch(url, init, "SODEXO_MANUAL_CSV_NON_ENCODED")
		)

		this.props.dispatch(ActionTypes.hideSpinner(0))

		if (actionType === "SODEXO_MANUAL_CSV_NON_ENCODED_SUCCESS") {
			this.setState({
				countNonEncodedBookings: responseData.bookings,
				countNonEncodedLaundryOrders: responseData.laundry_orders,
			})
		}
	}

	renderSyncButton = () => {
		let { sodexoConnectionStatus } = this.props
		let { t } = this.context
		if (sodexoConnectionStatus.authToken) {
			return (
				<PootsyButton
					key="sync"
					text={t("sodexo_conn_manual_sync")}
					onClick={this.submitManualSync}
				/>
			)
		}
	}

	submitManualSync = () => {
		let url = "/front/affiliates/sodexo/sodexo_sync"
		let init = {
			method: "POST",
			body: "{}",
		}
		this.props.dispatch(actions.simpleFetch(url, init, "SODEXO_MANUAL_SYNC"))
	}

	optionBox = () => {
		let { t } = this.context
		let optionBoxTitle = <div className="box-title"> {t("sodexo_conn_status_title")} </div>
		return (
			<OptionBox boxTitle={optionBoxTitle}>
				<SodexoConnectionStatus />
				<SodexoConnectionTokenButton />
				{this.renderSyncButton()}
				{this.renderManualEmailCSVButton()}
			</OptionBox>
		)
	}

	renderManualEmailCSVButton = () => {
		let { t } = this.context
		return (
			<PootsyButton
				key="emailcsv"
				text={t("sodexo_conn_manual_email_csv")}
				onClick={this.toggleManualEmailCSVModal}
			/>
		)
	}

	toggleManualEmailCSVModal = () => {
		this.setState({ showManualCSVModal: !this.state.showManualCSVModal })
	}

	render() {
		return (
			<ContentLayout optionsBoxes={this.optionBox()} customClass="SettingsSodexo">
				{this.state.showManualCSVModal && this.renderManualCSVModal()}
				<SodexoConnectionSettings />
			</ContentLayout>
		)
	}

	renderManualCSVModal = () => {
		let { t } = this.context
		let { date, selectedSquadId } = this.state
		let { currAffiliate } = this.props
		return (
			<ModalLayout
				customClass="manual-csv-modal"
				title={t("sodexo_manual_csv_title")}
				buttons={[
					<PootsyButton
						key="a"
						text={t("sodexo_create_manual_csv")}
						onClick={this.submitManualCSV}
						disabled={!selectedSquadId}
					/>,
					<PootsyButton
						key="b"
						text={t("cancel")}
						theme="cancel"
						onClick={this.toggleManualEmailCSVModal}
					/>,
				]}
			>
				<div className="dates">
					<LabeledDateTime
						label={t("date")}
						allowEmptyTime={false}
						showTimePicker={false}
						datePickerProps={{
							name: "date",
							value: date,
							onChange: this.handleChanges,
							calendarStyle: { top: "150%", left: 0 },
						}}
					/>
					<PootsySelectInput
						name="squad"
						allowEmpty={true}
						defaultText={t("squads")}
						options={[{ label: t("all_squads"), value: null }].concat(
							currAffiliate.squads.map(entry => ({
								label: entry.name,
								value: entry.id,
							}))
						)}
						selected={selectedSquadId}
						onChange={this.handleChangeSquad}
					/>
					{this.state.countNonEncodedBookings !== null && (
						<div>
							<div>
								{t("bookings")} : {this.state.countNonEncodedBookings}
							</div>
							<div>
								{t("laundry_orders")} : {this.state.countNonEncodedLaundryOrders}
							</div>
						</div>
					)}
				</div>
			</ModalLayout>
		)
	}

	submitManualCSV = async () => {
		let data = { date: this.state.date }
		let { t } = this.context

		if (this.state.selectedSquadId && this.state.selectedSquadId !== t("all_squads")) {
			data.filter_squad_ids = [this.state.selectedSquadId]
		}
		let url = "/front/affiliates/sodexo/sodexo_manual_csv"
		let init = {
			method: "POST",
			body: JSON.stringify(data),
		}
		await this.props.dispatch(actions.simpleFetch(url, init, "SODEXO_MANUAL_CSV"))
		this.setState({
			countNonEncodedBookings: null,
			countNonEncodedLaundryOrders: null,
			selectedSquadId: null,
		})
		this.toggleManualEmailCSVModal()
	}
}

SettingsSodexo.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	sodexoConnectionStatus: state.redData.currentAffiliate.sodexoConnectionStatus,
	currAffiliate: state.redData.currentAffiliate,
})
export default connect(mapStateToProps)(SettingsSodexo)
