import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"
import _ from "lodash"
import {
	Editor,
	EditorState,
	CompositeDecorator,
	RichUtils,
	convertToRaw,
	convertFromRaw,
	Modifier,
} from "draft-js"

import { UI_ROOT } from "../config.js"
import { history } from "../history"
import * as actions from "../actions"

import { WorkerLine, CustomerLine } from "./FunctionalDesign"
import BookingItem from "./BookingItem"
import { PootsyTextInput } from "./FunctionalInputs"
import { DraftEditorStyleButton } from "./DraftEditor"

import { PersonIcon, PersonOutlineIcon, EventIcon, PeopleIcon } from "../svg/gIcons"
import { BoldSymbol } from "../svg/boldSymbol"
import { ItalicSymbol } from "../svg/italicSymbol"
import { UnorderedListSymbol } from "../svg/ulSymbol"



const BLOCK_TYPES = [{ icon: <UnorderedListSymbol />, style: "unordered-list-item" }]
const INLINE_STYLES = [
	{ icon: <BoldSymbol />, style: "BOLD" },
	{ icon: <ItalicSymbol />, style: "ITALIC" },
]

const findVariableEntities = (contentBlock, callback, contentState) => {
	return contentBlock.findEntityRanges(character => {
		const entityKey = character.getEntity()
		return entityKey !== null && contentState.getEntity(entityKey).getType() === "VARIABLE"
	}, callback)
}

const variableStyle = {
	marginRight: "5px",
	marginLeft: "5px",
	background: "#fdba88",
	borderRadius: "3px",
}
const PootsyObjectLink = ({ contentState, entityKey, children, ...rest }, { t }) => {
	const entity = contentState.getEntity(entityKey)
	const data = entity.getData()
	return (
		<span
			className="editor-variable"
			style={variableStyle}
			contentEditable={false}
			readOnly
			onClick={() => {
				history.push({ pathname: data.url })
			}}
		>
			{data.translationArgs ? t(...data.translationArgs) : data.displayedText}
		</span>
	)
}
PootsyObjectLink.contextTypes = { t: PropTypes.func }

const variableDecorator = new CompositeDecorator([
	{ strategy: findVariableEntities, component: PootsyObjectLink },
])

class DraftTaskEditor extends Component {
	state = {
		id: -1,
		mounted: false,
		editorState: EditorState.createEmpty(variableDecorator),
		autoSaveEnabled: false,
	}
	static getDerivedStateFromProps(nextProps, state) {
		let { preloadedBody, id } = nextProps
		if (state.mounted && state.id === id) {
			return state
		}
		if (preloadedBody) {
			let preloadedContent = convertFromRaw(preloadedBody)
			return {
				id,
				mounted: true,
				editorState: EditorState.createWithContent(preloadedContent, variableDecorator),
			}
		} else {
			return {
				id,
				mounted: true,
				editorState: EditorState.createEmpty(variableDecorator),
			}
		}
	}
	handleChanges = changes => {
		this.props.dispatch(actions.draftTaskEditorStateChange(changes))
	}
	handleOnFocus = () => {
		this.setState({ autoSaveEnabled: true })
	}
	handleOnBlur = () => {
		this.setState({ autoSaveEnabled: false })
	}
	handleEditorStateChanges = editorState => {
		this.setState({ editorState })
		this.autoSave()
	}
	autoSave = () => {
		let { editorState, autoSaveEnabled } = this.state
		if (!autoSaveEnabled) return
		this.props.autoSave({ taskBody: convertToRaw(editorState.getCurrentContent()) })
	}
	toggleObjectSearch = objectType => {
		let {
			component: { showObjectSearch, currentObjectSearchType },
		} = this.props
		let activate = !showObjectSearch
		if (currentObjectSearchType !== objectType) activate = true
		this.handleChanges({
			objectSearchResults: [],
			showObjectSearch: activate,
			currentObjectSearchType: activate ? objectType : "",
		})
	}
	toggleBlockType = blockType => {
		this.setState({
			editorState: RichUtils.toggleBlockType(this.state.editorState, blockType),
		})
	}
	toggleInlineStyle = inlineStyle => {
		this.setState({
			editorState: RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle),
		})
	}
	handleObjectSearchChange = e => {
		e.stopPropagation()
		let value = e.target.value
		if (value === "") {
			this.handleChanges(
				{
					objectSearchResults: [],
					currentObjectSearch: value,
					objectRequestEnabled: false,
				},
				false
			)
		} else {
			this.handleChanges(
				{
					currentObjectSearch: value,
					objectRequestEnabled: true,
				},
				false
			)
			this.searchObject()
		}
	}
	searchObject = _.debounce(() => {
		let {
			dispatch,
			component: { objectRequestEnabled },
		} = this.props
		if (objectRequestEnabled) {
			dispatch(actions.searchTaskObject())
		}
	}, 2000)
	handlePastedText = pastedText => {
		/* eslint-disable */
		const POOTSY_LINK_REGEX = new RegExp(`^${UI_ROOT}([^\s]*)`, "g")
		/* eslint-enable */
		if (POOTSY_LINK_REGEX.test(pastedText)) {
			let splits = pastedText.split("/")
			let firstPath = splits[3]
			let secondPath = splits[4]
			if (firstPath && secondPath) {
				let map = { bookings: "booking", customers: "customer", cleaners: "worker" }
				let variableType = map[firstPath]
				this.findObjectAndInsertVariable({ variableType, secondPath })
				return true
			}
		}
	}
	findObjectAndInsertVariable = async ({ variableType, secondPath }) => {
		let { t } = this.context
		let { dispatch } = this.props
		if (variableType === "booking") {
			let url = "/front/affiliates/bookings/" + secondPath
			let init = { method: "GET" }
			let { actionType, data } = await dispatch(
				actions.simpleFetch(url, init, "FIND_BOOKING_FROM_LINK")
			)
			if (actionType === "FIND_BOOKING_FROM_LINK_SUCCESS") {
				this.insertVariable({
					id: data.data.id,
					url: "/bookings/" + data.data.id,
					translationArgs: [
						"task_booking_variable_text",
						{
							id: data.data.id,
							date: moment(data.data.attributes.delivery_date).format("DD/MM/YY"),
							customerName: data.data.attributes.customer.display_name,
							workerName: data.data.attributes.worker.display_name,
							status: t(data.data.attributes.service_delivery_status),
						},
					],
				})
			}
		} else if (variableType === "customer") {
			let url = "/front/affiliates/customer_contracts/" + secondPath
			let init = { method: "GET" }
			let { actionType, data } = await dispatch(
				actions.simpleFetch(url, init, "FIND_CUSTOMER_FROM_LINK")
			)
			if (actionType === "FIND_CUSTOMER_FROM_LINK_SUCCESS") {
				this.insertVariable({
					id: data.data.id,
					displayedText: data.data.attributes.display_name,
					url: "/customers/" + data.data.id + "/profile",
				})
			}
		} else if (variableType === "worker") {
			let url = "/front/affiliates/affiliate_workers/" + secondPath
			let init = { method: "GET" }
			let { actionType, data } = await dispatch(
				actions.simpleFetch(url, init, "FIND_WORKER_FROM_LINK")
			)
			if (actionType === "FIND_WORKER_FROM_LINK_SUCCESS") {
				this.insertVariable({
					id: data.data.id,
					displayedText:
						data.data.attributes.first_name + " " + data.data.attributes.last_name,
					url: "/cleaners/" + data.data.id + "/profile",
				})
			}
		}
	}
	selectObject = id => {
		let { t } = this.context
		let {
			component: { objectSearchResults, currentObjectSearchType },
		} = this.props
		this.handleChanges(
			{
				objectSearchResults: [],
				currentObjectSearch: "",
				objectRequestEnabled: false,
			},
			false
		)

		let object = objectSearchResults.find(entry => entry.id === id)
		let displayedText = "",
			url = "",
			translationArgs = undefined

		if (currentObjectSearchType === "booking") {
			translationArgs = [
				"task_booking_variable_text",
				{
					id: object.id,
					date: moment(object.deliveryDate).format("DD/MM/YY"),
					customerName: object.customerName,
					workerName: object.workerName,
					status: t(object.serviceDeliveryStatus),
				},
			]
			url = "/bookings/" + object.id
		} else if (currentObjectSearchType === "customer") {
			displayedText = object.attributes.display_name
			url = "/customers/" + object.id + "/profile"
		} else if (currentObjectSearchType === "worker") {
			displayedText = object.attributes.display_name
			url = "/cleaners/" + object.id + "/profile"
		} else if (currentObjectSearchType === "squad") {
			displayedText = object.name
			url = "/settings/teams"
		}
		this.insertVariable({ variable: id, displayedText, url, translationArgs })
	}
	insertVariable = ({ variable, displayedText, url, translationArgs }) => {
		const { editorState } = this.state
		const contentState = editorState.getCurrentContent()
		const selection = editorState.getSelection()
		const contentStateWithEntity = contentState.createEntity("VARIABLE", "IMMUTABLE", {
			variable,
			displayedText,
			url,
			translationArgs,
		})
		const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
		let newContent = Modifier.insertText(
			contentStateWithEntity,
			selection,
			`${variable}`,
			null,
			entityKey
		)
		newContent = Modifier.insertText(newContent, newContent.getSelectionAfter(), " ")
		const newEditorState = EditorState.push(editorState, newContent, "insert-characters")
		this.editorRef.focus()
		this.setState({ editorState: EditorState.moveFocusToEnd(newEditorState) })
	}
	render() {
		let { t } = this.context
		const { editorState } = this.state
		let {
			component: {
				currentObjectSearch,
				objectSearchResults,
				showObjectSearch,
				currentObjectSearchType,
			},
		} = this.props

		const selection = editorState.getSelection()
		const blockType = editorState
			.getCurrentContent()
			.getBlockForKey(selection.getStartKey())
			.getType()
		const currentStyle = editorState.getCurrentInlineStyle()

		return (
			<div className="DraftTaskEditor">
				<div className="search-buttons">
					<EventIcon
						active={currentObjectSearchType === "booking"}
						onClick={() => this.toggleObjectSearch("booking")}
					/>
					<PersonIcon
						active={currentObjectSearchType === "worker"}
						onClick={() => this.toggleObjectSearch("worker")}
					/>
					<PeopleIcon
						active={currentObjectSearchType === "squad"}
						onClick={() => this.toggleObjectSearch("squad")}
					/>
					<PersonOutlineIcon
						active={currentObjectSearchType === "customer"}
						onClick={() => this.toggleObjectSearch("customer")}
					/>
				</div>
				{showObjectSearch && (
					<div className="search-box">
						<PootsyTextInput
							placeholder={t("search_" + currentObjectSearchType)}
							name="currentObjectSearch"
							value={currentObjectSearch}
							onChange={this.handleObjectSearchChange}
						/>
						<div className="object-search-results">
							{objectSearchResults.map(entry => {
								switch (currentObjectSearchType) {
									case "booking":
										return (
											<BookingItem
												key={entry.id}
												booking={entry}
												noLink={true}
												onClick={() => this.selectObject(entry.id)}
											/>
										)
									case "worker":
										return (
											<WorkerLine
												key={entry.id}
												worker={entry}
												noLink={true}
												areaLimit={4}
												onClick={() => this.selectObject(entry.id)}
											/>
										)
									case "customer":
										return (
											<CustomerLine
												key={entry.id}
												customer={entry}
												noLink={true}
												onClick={() => this.selectObject(entry.id)}
											/>
										)
									case "squad":
										return (
											<div
												key={entry.id}
												className="squad"
												onClick={() => this.selectObject(entry.id)}
											>
												{entry.name}
											</div>
										)
									default:
										return null
								}
							})}
						</div>
					</div>
				)}
				<div className="editor-container">
					<div className="style-buttons">
						{BLOCK_TYPES.map(type => (
							<DraftEditorStyleButton
								key={type.style}
								icon={type.icon}
								active={type.style === blockType}
								label={t(type.label)}
								onToggle={this.toggleBlockType}
								style={type.style}
							/>
						))}
						{INLINE_STYLES.map(type => (
							<DraftEditorStyleButton
								key={type.style}
								active={currentStyle.has(type.style)}
								icon={type.icon}
								onToggle={this.toggleInlineStyle}
								style={type.style}
							/>
						))}
					</div>
					<Editor
						ref={ref => (this.editorRef = ref)}
						editorState={editorState}
						onFocus={this.handleOnFocus}
						onBlur={this.handleOnBlur}
						blockRendererFn={this.mediaBlockRenderer}
						onChange={this.handleEditorStateChanges}
						keyBindingFn={this.mapKeyToEditorCommand}
						handleKeyCommand={this.handleKeyCommand}
						handlePastedText={this.handlePastedText}
					/>
				</div>
			</div>
		)
	}
}

DraftTaskEditor.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.draftTaskEditorComponent,
})
export default connect(mapStateToProps)(DraftTaskEditor)
