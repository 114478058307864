import React from "react"

export const OrderedListSymbol = props => (
	<svg viewBox="0 0 18 18" {...props}>
		<path className="ql-stroke" d="M7 4h8M7 9h8M7 14h8" />
		<path className="ql-stroke ql-thin" d="M2.5 5.5h2" />
		<path
			className="ql-fill"
			d="M3.5 6a.5.5 0 0 1-.5-.5V3.085l-.276.138A.5.5 0 0 1 2.053 3c-.124-.247-.023-.324.224-.447l1-.5A.5.5 0 0 1 4 2.5v3a.5.5 0 0 1-.5.5z"
		/>
		<path
			className="ql-stroke ql-thin"
			d="M4.5 10.5h-2c0-.234 1.85-1.076 1.85-2.234a.959.959 0 0 0-1.85-.11M2.5 14.846a.959.959 0 0 0 1.85-.109.7.7 0 0 0-.6-.737.688.688 0 0 0 .6-.736.959.959 0 0 0-1.85-.109"
		/>
	</svg>
)
