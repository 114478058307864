import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment"

import { PootsyButton, ConfirmationModal } from "./FunctionalDesign"
import { PootsyTextInput } from "./FunctionalInputs"

// import "../stylesheets/TimesheetsCosts.styl"
import binIcon from "../static/images/bin-icon.png"

export default class TimesheetsCostLines extends Component {
	state = {
		showDeleteManualEntryModal: false,
		manualEntryStagedForDeletion: "",
	}
	openDeleteManualEntryModal = e => {
		let { id } = e.target.dataset
		this.setState({ showDeleteManualEntryModal: true, manualEntryStagedForDeletion: id })
	}
	closeDeleteManualEntryModal = () => {
		this.setState({ showDeleteManualEntryModal: false, manualEntryStagedForDeletion: "" })
	}
	removeManualEntry = e => {
		let { manualEntryStagedForDeletion } = this.state
		this.props.removeManualEntry(manualEntryStagedForDeletion)
		this.closeDeleteManualEntryModal()
	}
	handleExtraFieldChange = e => {
		let { disableChanges } = this.props
		if (disableChanges) {
			return
		}
		let { currTimesheet, handleCostLineChange } = this.props
		let { name, value } = e.target
		value = value.replace(/,/g, ".")
		let [costLineType, costLineID, extraFieldID] = name.split("::")
		costLineID = Number(costLineID)
		let costLine = currTimesheet.costsInfos.costsLines.find(
			entry => entry.id === costLineID && entry.type === costLineType
		)

		let newCostLine = {
			...costLine,
			isEdited: true,
			sstefv: costLine.sstefv.map(entry => {
				if (entry.id === Number(extraFieldID)) {
					return { ...entry, value }
				}
				return entry
			}),
		}
		handleCostLineChange({
			scheduleID: currTimesheet.timesheetInfo.timesheetID,
			costLineType,
			costLineID,
			newCostLine,
		})
	}
	submitChanges = e => {
		let { costlineid, costlinetype } = e.target.dataset
		let {
			submitChanges,
			currTimesheet: { timesheetInfo, costsInfos },
		} = this.props
		let costLine = costsInfos.costsLines.find(
			entry => entry.id === Number(costlineid) && entry.type === costlinetype
		)
		if (!costLine.isEdited) {
			return
		}
		let inits = costLine.sstefv.map(entry => {
			return {
				method: "POST",
				body: JSON.stringify({
					month_timesheet_id: timesheetInfo.timesheetID,
					expense_type: costLine.type,
					expense_id: costLine.id,
					extra_field_id: entry.id,
					new_extra_field: { ...entry },
				}),
			}
		})

		submitChanges(inits)
	}
	render() {
		let { t } = this.context
		let { currTimesheet, secSocEvents, disableChanges } = this.props
		let { showDeleteManualEntryModal } = this.state
		if (!currTimesheet.costsInfos) {
			return null
		}
		let lineLabels = [
			"amount",
			"sec_soc_code",
			"type",
			"comment",
			"monthly_recurrence_input_title",
			"",
		]
		return (
			<div className="costs-lines">
				{showDeleteManualEntryModal && (
					<ConfirmationModal
						title={t("delete_manual_entry")}
						yesButton={
							<PootsyButton text={t("yes")} onClick={this.removeManualEntry} />
						}
						noButton={
							<PootsyButton
								text={t("no")}
								theme="cancel"
								onClick={this.closeDeleteManualEntryModal}
							/>
						}
					/>
				)}
				<div className="labels">
					{lineLabels.map(entry => (
						<div key={entry} className="label">
							{t(entry)}
						</div>
					))}
				</div>
				{currTimesheet.costsInfos.costsLines.length === 0 && (
					<div className="no-costs-lines">{t("timesheet_costs_no_manual_entries")}</div>
				)}
				{currTimesheet.costsInfos.costsLines.map((entry, index) => {
					let secSocEventString
					if (entry.social_secretary_code) {
						let secSocEvent = secSocEvents.codes.find(
							code => code.id === entry.social_secretary_code
						)
						secSocEventString = secSocEvent.id + " - " + t(secSocEvent.label)
					}
					return (
						<div key={index} className="cost-line">
							<div className="column">
								{/*entry.amount.toFixed(2).toString().replace(".", ",")*/}
							</div>
							<div className="column">
								{secSocEventString
									? secSocEventString
									: entry.social_secretary_code}
							</div>
							<div className="column">{t(entry.type)}</div>
							<div className="column">{entry.comment}</div>
							<div className="column">
								{entry.recurring ? t("recurrent") : t("ponctual")}
							</div>
							<div className="column bin">
								{entry.type === "manual_entry" && (
									<img
										src={binIcon}
										alt="bin"
										onClick={this.openDeleteManualEntryModal}
										data-id={entry.id}
									/>
								)}
							</div>
							{entry.sstefv && entry.sstefv.length > 0 && (
								<div className="extra-fields-values">
									{(!["meal_vouchers", "manual_entry"].includes(entry.type) ||
										entry.social_secretary_code === "U78") &&
										entry.sstefv
											.sort((a, b) => {
												return b.field_name.localeCompare(a.field_name)
											})
											.map(exfv => (
												<div key={exfv.id} className="extra-fields-value">
													{t(exfv.field_name)}:{" "}
													{(Math.round(Number(exfv.value) * 100) / 100)
														.toString()
														.replace(".", ",")}
												</div>
											))}
									{["meal_vouchers", "manual_entry"].includes(entry.type) &&
										entry.social_secretary_code !== "U78" &&
										entry.sstefv.map(exfv => {
											let name = entry.type + "::" + entry.id + "::" + exfv.id
											return exfv.field_name === "groups_extra_date" ? (
												<div key={exfv.id} className="extra-fields-value">
													{t(exfv.field_name)}:{" "}
													{moment(exfv.value).format("DD/MM/YYYY")}
												</div>
											) : (
												<PootsyTextInput
													key={exfv.id}
													label={t(exfv.field_name)}
													name={name}
													value={exfv.value.toString().replace(".", ",")}
													onChange={this.handleExtraFieldChange}
													disabled={disableChanges}
												/>
											)
										})}
									<div className="save-button">
										{entry.isEdited && (
											<PootsyButton
												text={t("save_changes")}
												theme="green"
												dataset={{
													"data-costlinetype": entry.type,
													"data-costlineid": entry.id,
												}}
												onClick={this.submitChanges}
											/>
										)}
									</div>
								</div>
							)}
						</div>
					)
				})}
			</div>
		)
	}
}

TimesheetsCostLines.contextTypes = { t: PropTypes.func }
