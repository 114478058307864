import React, { Component } from "react"
import moment from "moment"
import PropTypes from "prop-types"

import _ from "lodash"

export class PootsyDateInput extends Component {
	textInput = React.createRef()
	state = {
		stagedDate: undefined,
		validDateFormat: true,
	}
	sendChanges = _.debounce(newDate => {
		if (this.props.onChange) {
			this.props.onChange({ [this.props.name]: newDate })
		}
		this.props.closeCalendarAndCleanup()
	}, 1000)
	componentDidMount = () => {
		if (!this.props.disableAutoFocus) {
			this.textInput.current.select()
		}
	}
	checkValidity = value => {
		// TO START -- OUGHt TO RECEIVE oTher INFO FROM PROPS
		// LIKE:  RULES FOR VALIDATIONS  e.g. not before today
		// return false if  disableDay(moment(value))
		if (!moment(value, "DD/MM/YYYY", true).isValid()) return false
		let unDisabledDay =
			typeof this.props.disableDay === "function"
				? !this.props.disableDay(moment(value, "DD/MM/YYYY", true))
				: true
		if (!unDisabledDay) return false
		let validated =
			typeof this.props.checkValidity === "function" ? this.props.checkValidity(value) : true

		if (!validated) return false

		return true
	}
	checkMonthChange = _.debounce(value => {
		if (
			moment(value, "DD/MM/YYYY", true).isValid() &&
			moment(value, "DD/MM/YYYY").month() !== this.props.value.month()
		) {
			this.props.onMonthChange && this.props.onMonthChange(moment(value, "DD/MM/YYYY"))
			this.setState({ referenceMonth: moment(value, "DD/MM/YYYY").month() })
			return true
		}
		return false
	}, 1000)
	findNextDay = (e, dateValue) => {
		let newDate = moment(dateValue, "DD/MM/YYYY")
		let foundDate = false
		let days = 0
		let below50Days = true
		newDate.add(1, "days")

		while (!foundDate && below50Days) {
			days += 1
			if (days > 50) {
				below50Days = false
			}
			if (this.props.disableDay && !this.props.disableDay(newDate)) {
				foundDate = true
			} else if (this.props.disableDay && this.props.disableDay(newDate)) {
				newDate.add(1, "days")
				foundDate = this.checkMonthChange(newDate.format("DD/MM/YYYY")) ? true : false
			} else {
				foundDate = true
			}
		}
		this.props.changeProposedDay && this.props.changeProposedDay(newDate)
	}
	findPreviousDay = (e, dateValue) => {
		let foundDate = false
		let newDate = moment(dateValue, "DD/MM/YYYY")
		let days = 0
		let below50Days = true
		newDate.subtract(1, "days")
		while (!foundDate && below50Days) {
			days -= 1
			if (days < -50) {
				below50Days = false
			}
			if (this.props.disableDay && !this.props.disableDay(newDate)) {
				foundDate = true
			} else if (this.props.disableDay && this.props.disableDay(newDate)) {
				newDate.subtract(1, "days")
				foundDate = this.checkMonthChange(newDate.format("DD/MM/YYYY")) ? true : false
			} else {
				foundDate = true
			}
		}
		this.props.changeProposedDay && this.props.changeProposedDay(newDate)
	}
	pressKey = e => {
		if (e.keyCode === 38) {
			// this.findNextDay(e, value)
		} else if (e.keyCode === 40) {
			// this.findPreviousDay(e, value)
		} else if (e.keyCode === 13) {
			let newDate = moment(e.target.value, "DD/MM/YYYY")
			if (this.checkValidity(newDate)) {
				this.sendChanges(newDate)
			}
		} else {
			this.setState({ stagedDate: e.target.value })
		}
	}
	render() {
		let { t } = this.context
		let { value } = this.props
		let { stagedDate } = this.state
		let displayValue = value ? value.format("DD/MM/YYYY") : ""
		if (stagedDate) {
			displayValue = stagedDate
		}
		return (
			<div className="date-input-textstyle ">
				{!this.props.disableInput && (
					<input
						// type="text"
						onChange={this.pressKey}
						onKeyDown={this.pressKey}
						value={displayValue}
						ref={this.textInput}
						// readOnly={!this.props.disableCalendar}
						// autoFocus={this.props.autoFocus ? this.props.autoFocus : true}
					/>
				)}
				{!this.state.validDateFormat && (
					<div className="warning-text-input">{t("not a valid date")}</div>
				)}
			</div>
		)
	}
}

PootsyDateInput.contextTypes = { t: PropTypes.func }
