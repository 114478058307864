import React, { Component } from "react"
import { PootsyTable } from "./FunctionalDesign"
import PropTypes from "prop-types"

class WorkshopOrderQuoteDisplayer extends Component {
	removeItem = e => {
		let { itemid, allowEdit = true } = e.target.dataset
		if (!allowEdit) return
		this.props.handleOrderItemChange({ avItemID: Number(itemid), quantity: 0 })
	}
	categoryLabels = category => {
		let { t } = this.context
		let { allowEdit = true } = this.props
		let labels = [
			t("label"),
			t(category.categoryName + "_price_label"),
			t("quantity"),
			t("total"),
		]
		if (allowEdit) {
			labels = labels.concat([t("remove")])
		}
		return labels
	}
	categoryRow = (category, item) => {
		let { allowEdit = true } = this.props
		let row = [
			item.longName,
			item.price.toString().replace(".", ",") + " " + category.unitSymbol,
			item.quantity,
			item.cost.toString().replace(".", ",") + " " + category.unitSymbol,
		]

		if (allowEdit) {
			row = row.concat([
				<div
					className="remove-item-button"
					onClick={this.removeItem}
					data-itemid={item.avItemID}
				>
					x
				</div>,
			])
		}
		return row
	}
	categoryTotalRow = category => {
		let { t } = this.context
		let { allowEdit = true } = this.props
		let totalRows = [
			{
				columns: [
					t("total"),
					"",
					"",
					category.totalDetails.category_total.toString().replace(".", ",") +
						" " +
						category.unitSymbol,
				],
				style: { fontWeight: "bolder" },
			},
		]
		if (category.categoryName === "SERVICE_VOUCHER") {
			totalRows.push({
				columns: [
					t("service_voucher_count"),
					"",
					"",
					category.totalDetails.estimated_billable_service_voucher,
				],
				style: { fontWeight: "bolder" },
			})
		}
		if (allowEdit) {
			totalRows = totalRows.map(row => ({ ...row, columns: [...row.columns, ""] }))
		}
		return totalRows
	}
	render() {
		let { t } = this.context
		let { quote, categoryFilter = "", loading } = this.props
		return (
			<div className="WorkshopOrderQuoteDisplayer">
				{loading && (
					<PootsyTable loading={true} title="" labels={[]} rows={[{ columns: [""] }]} />
				)}
				{!loading &&
					quote.details
						.filter(
							entry => entry.items.length > 0 && entry.categoryName !== categoryFilter
						)
						.map(entry => (
							<div key={entry.categoryName} className="quote-category">
								<PootsyTable
									title={t(entry.categoryName)}
									labels={this.categoryLabels(entry)}
									rows={entry.items
										.map(item => ({ columns: this.categoryRow(entry, item) }))
										.concat(this.categoryTotalRow(entry))}
								/>
							</div>
						))}
			</div>
		)
	}
}

WorkshopOrderQuoteDisplayer.contextTypes = { t: PropTypes.func }
export default WorkshopOrderQuoteDisplayer
