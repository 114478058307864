import React, { useContext } from "react"
import { useSelector } from "react-redux"

import { LocaleContext } from "../index.js"
import {
	// useChangeHandler,
	useInitBookingWishes,
	useFetchBookingWishes,
	useCustomerFilterHandler,
	useRemoveCustomerFilter,
	useWorkerFilterHandler,
	useSquadFilterHandler,
	useRemoveWorkerFilter,
	useDateFilterHandler,
	useSearchTimeslotsForCurrentHighlight,
	useAgendaToggling,
	useStatusFilterHandler,
	useAgendaSortingHandler,
	useSquadFilterForSearchHandler,
	usePageChangeHandler,
	useBadgeFilterHandler,
	useAgendaSearchWeekNavigation,
	useUpdateBookingWishGroupStatus,
	useCloseDismissalModalHandler,
	useClosePendingModalHandler,
	useBwLeaveWorkerFullMonth,
	useChangeHandler,
} from "./BookingWishesHooks"
import { PootsyButton, OptionBox, PageLayout, ConfirmationModal } from "./FunctionalDesign"
import { PootsySelectInput, PootsyTextInput } from "./FunctionalInputs"

import AgendaNavigation from "./AgendaNavigation"
import Paginator from "./Paginator"
import FilterFromSearch from "./FilterFromSearch"
import DateFilterer from "./DateFilterer"
import Filter from "./Filter"
import SearchWorker from "./SearchWorker"
import SearchCustomer from "./SearchCustomer"
import Fagenda from "./Fagenda"
import BookingWish from "./BookingWish"

const BookingWishes = () => {
	const t = useContext(LocaleContext)
	const {
		workersFilters,
		customersFilters,
		squadsFilters,
		rangeStart,
		rangeEnd,
		showAgenda,
		statusFilter,
		agendasSorting,
		squadsFiltersForSearch,
		page,
		pagination,
		currentHighlights,
		allBadges,
		badgesFilters,
		showRequired,
		fetching,
		showDismissConfirmationModal,
		showPendingConfirmationModal,
		minReplacementDuration,
	} = useSelector(state => state.redComponents.bookingWishesComponent)
	let currAffiliate = useSelector(state => state.redData.currentAffiliate)
	const agendaData = useSelector(state => state.redData.agenda)
	const agendaComponent = useSelector(state => state.redComponents.agenda)
	const currUser = useSelector(state => state.redData.currentUser)
	const bookingWishGroups = useSelector(state => state.redData.bookingWishes)

	const fetchBookingWishes = useFetchBookingWishes()
	useInitBookingWishes(fetchBookingWishes, currUser)
	const handleChanges = useChangeHandler()
	const toggleAgenda = useAgendaToggling()
	const handleAgendaWeekChange = useAgendaSearchWeekNavigation()
	const handleCustomerFilter = useCustomerFilterHandler(fetchBookingWishes)
	const handleRemoveCustomerFilter = useRemoveCustomerFilter(fetchBookingWishes)
	const handleWorkerFilter = useWorkerFilterHandler(fetchBookingWishes)
	const handleSquadFilter = useSquadFilterHandler(fetchBookingWishes)
	const handleSquadFilterForSearch = useSquadFilterForSearchHandler()
	const handleBadgeFilterChange = useBadgeFilterHandler()
	const handleStatusFilterChange = useStatusFilterHandler(fetchBookingWishes)
	const handleDateFilterChange = useDateFilterHandler(fetchBookingWishes)
	const handleAgendaSortingChange = useAgendaSortingHandler()
	const handleRemoveWorkerFilter = useRemoveWorkerFilter(fetchBookingWishes)
	const searchTimeslotsForCurrentHighlights = useSearchTimeslotsForCurrentHighlight()
	const updateBookingWishGroupStatus = useUpdateBookingWishGroupStatus()
	const handleChangePage = usePageChangeHandler(fetchBookingWishes)
	const handleCloseDismissalModal = useCloseDismissalModalHandler()
	const handleClosePendingModal = useClosePendingModalHandler()
	const leaveBwWorkerFullMonth = useBwLeaveWorkerFullMonth()
	let highlightedWishes = Object.entries(currentHighlights).reduce(
		(acc, [bookingWishGroupId, value]) => {
			if (value.isHighlighted) {
				acc.push(value)
			}
			return acc
		},
		[]
	)

	const agendaControls = (
		<div className="agenda-navigation">
			<AgendaNavigation
				handleWeekChange={handleAgendaWeekChange}
				showTssLink={false}
				disabled={!showAgenda}
				leaveWorkerFullMonthMode={leaveBwWorkerFullMonth}
			/>
		</div>
	)

	return (
		<PageLayout
			customClass="BookingWishes"
			headerLeft={<h1 className="BookingWishes page-title">{t("booking_wishes")}</h1>}
			headerRight={agendaControls}
		>
			{showDismissConfirmationModal && (
				<ConfirmationModal
					title={t("confirm_dismiss_booking_wish")}
					closeModal={handleCloseDismissalModal}
					yesButton={
						<PootsyButton text={t("yes")} onClick={updateBookingWishGroupStatus} />
					}
					noButton={
						<PootsyButton
							text={t("no")}
							theme="cancel"
							onClick={handleCloseDismissalModal}
						/>
					}
				/>
			)}
			{showPendingConfirmationModal && (
				<ConfirmationModal
					title={t("confirm_pending_booking_wish")}
					closeModal={handleClosePendingModal}
					yesButton={
						<PootsyButton text={t("yes")} onClick={updateBookingWishGroupStatus} />
					}
					noButton={
						<PootsyButton
							text={t("no")}
							theme="cancel"
							onClick={handleClosePendingModal}
						/>
					}
				/>
			)}
			<div className={"wrapper " + (showAgenda ? "show-agenda" : "")}>
				<div className="left-section">
					<div className="wishes-filters">
						<OptionBox key="b" boxTitle={t("booking_wishes_filters")}>
							<DateFilterer
								alwaysOpened={true}
								from={rangeStart}
								to={rangeEnd}
								// rangeLimitEnd={}
								onChange={handleDateFilterChange}
							/>
							<PootsySelectInput
								name="statusFilter"
								defaultText={t("status_filter")}
								selected={statusFilter}
								onChange={handleStatusFilterChange}
								options={[
									{ label: t("all"), value: "all" },
									{ label: t("pending"), value: "PENDING" },
									{ label: t("replacement_found"), value: "REPLACEMENT_FOUND" },
									{ label: t("dismissed"), value: "DISMISSED" },
								]}
							/>
							<FilterFromSearch
								currentValues={workersFilters}
								handleRemoveValue={handleRemoveWorkerFilter}
								searchComponent={
									<SearchWorker
										onChange={() => {}}
										searchOnlyActiveWorkers={true}
										onClick={handleWorkerFilter}
									/>
								}
							/>
							<FilterFromSearch
								currentValues={customersFilters}
								handleRemoveValue={handleRemoveCustomerFilter}
								searchComponent={
									<SearchCustomer handleCustomerClick={handleCustomerFilter} />
								}
							/>
							<Filter
								label={t("search_squads")}
								name="squadsFilters"
								possibleValues={currAffiliate.squads.map(entry => ({
									label: entry.name,
									value: entry.id,
								}))}
								currentFilters={squadsFilters}
								onChange={handleSquadFilter}
							/>
						</OptionBox>
					</div>
					<div className="wishes">
						{Object.keys(bookingWishGroups).length === 0 && !fetching && (
							<div className="no-data">{t("no_booking_wishes")}</div>
						)}
						{Object.entries(bookingWishGroups).map(([groupId, bookingWishGroup]) => (
							<BookingWish
								key={bookingWishGroup.id}
								bookingWishGroup={bookingWishGroup}
								currentHighlights={currentHighlights}
								expanded={!showAgenda}
							/>
						))}
						<Paginator
							name="pageQuery"
							currPage={page}
							totalPages={pagination.total_pages}
							goToPage={handleChangePage}
						/>
					</div>
				</div>
				<div className="switch-handle">
					<div className="controls">
						<PootsyButton
							text={
								showAgenda
									? "<< " + t("show_booking_wishes")
									: t("show_agenda") + " >>"
							}
							size="small"
							theme="white"
							onClick={toggleAgenda}
						/>
						<Filter
							label={t("search_squads")}
							name="squadsFilters"
							possibleValues={currAffiliate.squads.map(entry => ({
								label: entry.name,
								value: entry.id,
							}))}
							currentFilters={squadsFiltersForSearch}
							onChange={handleSquadFilterForSearch}
							showRequired={
								currAffiliate.squads.length >= 2 && showRequired.squadsFilters
							}
						/>
						<PootsySelectInput
							defaultText={t("search_badges")}
							name="badgesFilters"
							options={allBadges.map(entry => ({
								label: t(entry.label),
								value: entry.value,
							}))}
							selected={badgesFilters}
							onChange={handleBadgeFilterChange}
							allowEmpty={true}
						/>
						<PootsyTextInput
							type="number"
							min="1"
							max="10"
							label={t("min_replacement_duration")}
							onChange={e =>
								handleChanges({ minReplacementDuration: e.target.value })
							}
							value={minReplacementDuration}
						/>
						<PootsyButton
							text={t("search_timeslots_for_highlighted_wish")}
							size="small"
							theme="green"
							disabled={
								highlightedWishes.length !== 1 ||
								(currAffiliate.squads.length >= 2 &&
									squadsFiltersForSearch.length === 0)
							}
							onClick={searchTimeslotsForCurrentHighlights}
						/>
						<div className="separator" />
						<PootsySelectInput
							name="agendasSorting"
							defaultText={t("agendas_sorting")}
							selected={agendasSorting}
							onChange={handleAgendaSortingChange}
							disabled={!showAgenda || !!agendaData.fullMonthAffiliateWorkerId}
							options={[
								{ label: t("sort_by_distance"), value: "distance" },
								{ label: t("sort_by_badge"), value: "badge" },
							]}
						/>
					</div>
				</div>
				<div className="agendas">
					{agendaData.schedules.length === 0 && !agendaComponent.fetching && (
						<div className="no-data">
							<div className="title">{t("no_booking_wish_search_results_title")}</div>
							<div className="desc">{t("no_booking_wish_search_results_desc")}</div>
						</div>
					)}
					<Fagenda detachedMode={true} controls={null} />
				</div>
			</div>
		</PageLayout>
	)
}

export default BookingWishes
