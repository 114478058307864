import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import * as actions from "../actions"

import InputsChangeHandler from "./InputsChangeHandler"
import PootsyAddressBlockInputs from "./PootsyAddressBlockInputs"
import {
	PageLayout,
	ContentLayout,
	PootsyButton,
	NavLinkArrowBack,
	PootsySubHeading,
} from "./FunctionalDesign"
import {
	PootsyCheckboxInput,
	PootsyTextInput,
	PootsyRadioInputs,
	PootsySelectInput,
} from "./FunctionalInputs"

export class NewWorker extends Component {
	UNSAFE_componentWillMount = () => {
		let { dispatch, currAffiliate, history } = this.props
		let init = { method: "GET" }
		if (currAffiliate.visibleWorkerCount >= currAffiliate.tariffPlan.maximum_worker) {
			dispatch(actions.workersStateChange({ showWorkerLimitReachedModal: true }))
			history.push({ pathname: "/cleaners" })
			return
		}
		this.props.dispatch(actions.fetchTeams())
		this.props.dispatch(
			actions.simpleFetch(
				"/front/affiliates/languages",
				init,
				"FETCHAVAILABLEWORKERLANGUAGES"
			)
		)
	}
	toggleRequiredColor = (el, makeItRed) => {
		this.props.dispatch(actions.newWorkerRequireField(el, makeItRed))
	}
	handleFormChanges = changes => {
		let { formData } = this.props

		let key = Object.keys(changes)

		if (key[0] === "mainLanguage") {
			let langValue = Object.values(changes)

			delete formData["en"]
			delete formData["nl"]
			delete formData["fr"]

			formData[langValue] = true
		}
		this.props.dispatch(actions.newWorkerDataChanges(changes))
	}
	validateStepOne = () => {
		let { formData } = this.props
		let toValidate = [
			"sodexoNumber",
			"cleanerTitle",
			"firstName",
			"lastName",
			"mobilePhone",
			"street",
			"streetNumber",
			"zip",
			"city",
			"mainLanguage",
		]
		let valid = true
		toValidate.forEach(entry => {
			if (formData[entry] === "") {
				valid = false
				this.toggleRequiredColor(entry, true)
			}
		})
		if (valid) {
			this.goToStep(2)
		}
	}
	submit = () => {
		let {
			dispatch,
			allWorkerLanguages,
			formData,
			formData: {
				cleanerTitle,
				mainLanguage,
				sodexoNumber,
				nissNumber,
				streetNumber,
				country,
				zip,
				street,
				box,
				city,
				email,
				firstName,
				lastName,
				mobilePhone,
				contractStartDate,
				contractEndDate,
				coveredAreas,
				secSocIdentifier,
				sodexoPin,
				squadId,
				needsUpfrontPayment,
			},
		} = this.props

		let spokenLanguages = []
		allWorkerLanguages.forEach(e => {
			let formKeys = Object.keys(formData)
			if (formKeys.indexOf(e.ref) > -1 && formData[e.ref]) {
				spokenLanguages.push(e.ref)
			}
		})

		let data = {
			affiliate_worker: {
				sodexo_reference: sodexoNumber,
				niss: nissNumber,
				title: cleanerTitle,
				social_secretary_identifier: secSocIdentifier,
				first_name: firstName,
				last_name: lastName,
				default_language: mainLanguage,
				spoken_languages: spokenLanguages,
				email: email,
				telephone: mobilePhone,
				sodexo_pin: sodexoPin,
				squad_id: squadId,
				address: {
					street_name: street,
					street_number: streetNumber,
					street_box: box,
					zip_code: zip,
					town: city,
					country: country,
				},
				start_date: contractStartDate.format(),
				end_date: contractEndDate ? contractEndDate.format() : null,
				area_references: coveredAreas,
				needs_upfront_payment: needsUpfrontPayment,
			},
		}
		let init = { method: "POST", body: JSON.stringify(data) }
		dispatch(actions.simpleFetch("/front/affiliates/affiliate_workers", init, "CREATEWORKER"))
	}
	render() {
		let { t } = this.context
		let { allWorkerLanguages, formData, teams } = this.props
		let pageHeaderLeft = (
			<div className="page-header-left">
				<NavLinkArrowBack to="/cleaners" />
				<h1 className="page-title">{t("new_cleaner")}</h1>
			</div>
		)
		let requireArray = [
			formData.firstName,
			formData.lastName,
			formData.nissNumber,
			formData.mainLanguage,
		]
		if (formData.showRequired.cleanerTitle) {
			requireArray.push(formData.cleanerTitle)
		}
		let areAllMandatoryFieldsFilledIn = requireArray.every(function(e) {
			return e !== ""
		})
		let pageHeaderRight = (
			<PootsyButton
				text={t("submit")}
				theme="green"
				onClick={this.submit}
				disabled={!areAllMandatoryFieldsFilledIn}
			/>
		)
		return (
			<PageLayout headerLeft={pageHeaderLeft} headerRight={pageHeaderRight}>
				<ContentLayout customClass="new-worker">
					<InputsChangeHandler setParentState={this.handleFormChanges}>
						<div className="new-worker-inputs-col">
							<PootsySubHeading text={t("personal_details")} />
							<PootsyRadioInputs
								groupName="cleanerTitle"
								groupLabel={t("cleaner_title")}
								showRequired={formData.showRequired.cleanerTitle}
								selected={formData.cleanerTitle}
								options={[
									{ id: "radio-mr", value: "mr", label: t("mr") },
									{ id: "radio-mrs", value: "mrs", label: t("mrs") },
								]}
							/>
							<PootsyTextInput
								name="firstName"
								label={t("first_name")}
								showRequired={formData.showRequired.firstName}
								value={formData.firstName}
							/>
							<PootsyTextInput
								name="lastName"
								label={t("last_name")}
								showRequired={formData.showRequired.lastName}
								value={formData.lastName}
							/>
							<PootsyTextInput
								name="email"
								label={t("email")}
								showRequired={formData.showRequired.email}
								value={formData.email}
							/>
							<PootsyTextInput
								name="mobilePhone"
								label={t("phone")}
								showRequired={formData.showRequired.mobilePhone}
								value={formData.mobilePhone}
							/>
							<PootsyTextInput
								name="sodexoNumber"
								type="number"
								label={t("sodexo_number")}
								showRequired={formData.showRequired.sodexoNumber}
								value={formData.sodexoNumber}
							/>
							<PootsyTextInput
								name="nissNumber"
								type="text"
								label={t("niss_number")}
								showRequired={formData.showRequired.nissNumber}
								value={formData.nissNumber}
							/>
							<PootsyTextInput
								name="secSocIdentifier"
								type="text"
								label={t("cleaner_sec_soc_identifier")}
								showRequired={formData.showRequired.secSocIdentifier}
								value={formData.secSocIdentifier}
							/>
							<PootsyTextInput
								name="sodexoPin"
								type="text"
								label={t("cleaner_sodexo_pin")}
								showRequired={formData.showRequired.sodexoPin}
								value={formData.sodexoPin}
							/>
							<PootsyCheckboxInput
								name="needsUpfrontPayment"
								label={t("needs_upfront_payment")}
								checked={formData.needsUpfrontPayment}
							/>
						</div>
						<div className="new-worker-inputs-col">
							<PootsySubHeading text={t("address")} />
							<PootsyAddressBlockInputs
								setValues={this.handleFormChanges}
								showRequired={formData.showRequired}
								values={{
									city: formData.city,
									zip: formData.zip,
									box: formData.box,
									streetNumber: formData.streetNumber,
									street: formData.street,
									country: formData.country,
								}}
								showCountry={true}
							/>
						</div>
						<div className="new-worker-inputs-col">
							<PootsySubHeading text={t("languages")} />
							<PootsySelectInput
								name="mainLanguage"
								defaultText={t("cleaner_main_language")}
								customClass="main-language"
								selected={formData.mainLanguage}
								showRequired={formData.showRequired.mainLanguage}
								options={allWorkerLanguages.map(e => ({
									label: t(e.ref),
									value: e.ref,
								}))}
							/>
							<div className="spoken-languages">
								<label className="spoken-languages-label">
									{t("spoken_languages")}
								</label>
								{allWorkerLanguages
									.filter(e => ["nl", "en", "fr"].indexOf(e.ref) > -1)
									.map(e => (
										<PootsyCheckboxInput
											key={e.ref}
											name={e.ref}
											label={t(e.ref)}
											checked={formData[e.ref]}
										/>
									))}
							</div>
							<PootsySelectInput
								name="squadId"
								allowEmpty={true}
								defaultText={t("squad")}
								selected={formData.squadId}
								options={teams.map(e => ({
									label: e.attributes.name,
									value: e.id,
								}))}
							/>
						</div>
					</InputsChangeHandler>
				</ContentLayout>
			</PageLayout>
		)
	}
}

NewWorker.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currAffiliate: state.redData.currentAffiliate,
	formData: state.redData.currentNewWorkerFormData,
	allWorkerLanguages: state.redData.allWorkerLanguages,
	teams: state.redData.teams,
})
export default connect(mapStateToProps)(NewWorker)
