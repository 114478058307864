import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"

import WeekTimeranges from "./WeekTimeranges"

import {
	workerWeekUIChange,
	fetchWorkerWeek,
	submitDirectBooking,
	submitOtherActivityForRelevantContract,
	injectCurrentWeekIntoNewContract,
} from "../actions"

import { ContentLayout, OptionBox, PootsyButton, WeekBadge } from "./FunctionalDesign"
import WeekPicker from "./WeekPicker"
import DirectBookingModal from "./DirectBookingModal"
import { OtherActivityModal } from "./OtherActivityModal"
import CalendarWeekPicker from "./CalendarWeekPicker"
import ModalLayout from "./ModalLayout"

const days = [
	{ tKey: "Monday", number: 0 },
	{ tKey: "Tuesday", number: 1 },
	{ tKey: "Wednesday", number: 2 },
	{ tKey: "Thursday", number: 3 },
	{ tKey: "Friday", number: 4 },
	{ tKey: "Saturday", number: 5 },
]

class WorkerWeek extends Component {
	state = {
		requestSent: false,
	}
	componentDidMount = () => {
		this.handleWeekChange({ value: moment() })
	}
	componentDidUpdate = prevProps => {
		let { requestSent } = this.state
		let { reports, workerContracts, workerWeekComponent } = this.props
		let report = reports.find(entry =>
			entry.week.isSame(workerWeekComponent.currentWeek, "day")
		)
		if (!requestSent && report && !report.contractHours && workerContracts.length > 0) {
			this.handleWeekChange(moment())
		}
	}
	handleWeekChange = week => {
		week = week.weekFilter ? week.weekFilter : moment(week)
		this.props.dispatch(fetchWorkerWeek(week))
		this.setState({ requestSent: true })
	}
	toggleWeekPicker = () => {
		let { dispatch, workerWeekComponent } = this.props
		dispatch(workerWeekUIChange({ showWeekPicker: !workerWeekComponent.showWeekPicker }))
	}
	openNewBookingModal = e => {
		let { day } = e.target.dataset
		let { dispatch, workerWeekComponent } = this.props
		day = parseInt(day, 10)
		let bookingStartDate = moment(workerWeekComponent.currentWeek).add(day, "days")
		dispatch(workerWeekUIChange({ directBookingInitialDate: bookingStartDate }))
		this.toggleDirectBookingModal()
	}
	openOtherActivityModal = e => {
		let { day } = e.target.dataset
		let { dispatch, workerWeekComponent } = this.props
		day = parseInt(day, 10)
		let otherActivityStartDate = moment(workerWeekComponent.currentWeek).add(day, "days")
		dispatch(workerWeekUIChange({ otherActivityInit: { startDate: otherActivityStartDate } }))
		this.toggleOtherActivityModal()
	}
	toggleDirectBookingModal = () => {
		let { workerWeekComponent, dispatch } = this.props
		dispatch(
			workerWeekUIChange({
				showDirectBookingModal: !workerWeekComponent.showDirectBookingModal,
			})
		)
	}
	toggleOtherActivityModal = () => {
		let { workerWeekComponent, dispatch } = this.props
		dispatch(
			workerWeekUIChange({
				showOtherActivityModal: !workerWeekComponent.showOtherActivityModal,
			})
		)
	}
	toggleDeleteOtherActivityModal = () => {
		let { workerWeekComponent, dispatch } = this.props
		dispatch(
			workerWeekUIChange({
				showOtherActivityDeletionConfirmationModal: !workerWeekComponent.showOtherActivityDeletionConfirmationModal,
			})
		)
	}
	handleRemoveTimeRange = timerange => {
		if (timerange.type === "other") {
			this.toggleDeleteOtherActivityModal()
		}
	}
	handleEditTimeRange = timerange => {
		// console.log(timerange)
	}
	injectCurrentWeekIntoNewSingleWeekContract = () => {
		this.props.dispatch(injectCurrentWeekIntoNewContract({ forTwoWeeksSchedule: false }))
	}
	injectCurrentWeekIntoNewDoubleWeekContract = () => {
		this.props.dispatch(injectCurrentWeekIntoNewContract({ forTwoWeeksSchedule: true }))
	}
	submitDirectBooking = directBooking => {
		this.props.dispatch(submitDirectBooking(directBooking))
	}
	submitOtherActivity = otherActivity => {
		this.props.dispatch(submitOtherActivityForRelevantContract(otherActivity))
	}
	submitDeleteOtherActivity = () => {}
	render() {
		let { t } = this.context
		let { workerWeekComponent, timeranges, reports, workerContracts } = this.props
		let optionsBoxes = (
			<OptionBox boxTitle={t("contracts")} customClass="explainer">
				{t("real_planning_explanation")}
			</OptionBox>
		)
		let otherActivityDeletionModalButtons = (
			<Fragment>
				<PootsyButton text={t("yes")} onClick={this.submitDeleteOtherActivity} />
				<PootsyButton
					text={t("no")}
					theme="cancel"
					onClick={this.toggleDeleteOtherActivityModal}
				/>
			</Fragment>
		)
		let isTwoWeeksSchedule = false
		let isFirstWeek = false
		let report = reports.find(entry =>
			entry.week.isSame(workerWeekComponent.currentWeek, "day")
		)
		let badge
		if (report && report.metadata) {
			badge = report.metadata.display_data.find(entry => entry.translation_key === "badge")
			let contract = workerContracts.find(entry => entry.id === report.workerContractID)
			if (!contract) {
				return null
			}
			isTwoWeeksSchedule = contract.schedulePeriods.length > 1
			let schedulePeriod = contract.schedulePeriods.find(
				entry => moment(entry.startDate).diff(report.week, "days") % entry.dayPeriod < 7
			)
			if (!schedulePeriod) {
				// https://sentry.io/pootsy/pootsy-webapp/issues/840963671/
				return null
			}
			isFirstWeek = schedulePeriod.startDate.isSame(
				moment(contract.startDate).startOf("isoweek"),
				"day"
			)
		}
		timeranges = timeranges.filter(entry => {
			// This because timeranges spanning 2 weeks will be fetched in case of a
			// 2 weeks schedule
			return moment(entry.startDate)
				.startOf("isoweek")
				.isSame(workerWeekComponent.currentWeek, "day")
		})
		return (
			<ContentLayout customClass="worker-week" optionsBoxes={optionsBoxes}>
				{workerWeekComponent.showDirectBookingModal && (
					<DirectBookingModal
						initialStartDate={workerWeekComponent.directBookingInitialDate}
						closeModal={this.toggleDirectBookingModal}
						submit={this.submitDirectBooking}
					/>
				)}
				{workerWeekComponent.showOtherActivityModal && (
					<OtherActivityModal
						init={workerWeekComponent.otherActivityInit}
						closeModal={this.toggleOtherActivityModal}
						submit={this.submitOtherActivity}
						t={t}
					/>
				)}
				{workerWeekComponent.showOtherActivityDeletionConfirmationModal && (
					<ModalLayout
						title={t("other_activity_deletion_modal_title")}
						buttons={otherActivityDeletionModalButtons}
						closeModal={this.toggleDeleteOtherActivityModal}
						formDivClass="other-activity-deletion-modal"
					>
						{t("other_activity_deletion_confirmation")}
					</ModalLayout>
				)}
				<div className="inject-weeks-button">
					<PootsyButton
						text={t("inject_real_planning_into_new_single_week_contract_button")}
						onClick={this.injectCurrentWeekIntoNewSingleWeekContract}
					/>
					<PootsyButton
						text={t("inject_real_planning_into_new_double_week_contract_button")}
						onClick={this.injectCurrentWeekIntoNewDoubleWeekContract}
					/>
				</div>
				{isTwoWeeksSchedule &&
					(isFirstWeek ? (
						<div className="week-schedule-period">{t("first_week")}</div>
					) : (
						<div className="week-schedule-period">{t("second_week")}</div>
					))}
				<div className="top-line">
					{report &&
						report.metadata &&
						report.metadata.display_data.map(entry => {
							if (entry.translation_key === "badge") {
								return null
							}
							let label = ""
							if (entry.translation_variables) {
								label = t(entry.translation_key, entry.translation_variables)
							} else {
								label = t(entry.translation_key)
							}
							return (
								<div key={entry.translation_key} className="info">
									<div className="label">{label}</div>
									<div className="value">{entry.value}</div>
								</div>
							)
						})}
					{badge && <WeekBadge key="badge" badge={badge.value} />}
					<div className="week-selectors">
						<CalendarWeekPicker
							week={workerWeekComponent.currentWeek}
							togglePicker={this.toggleWeekPicker}
							calendarProps={{
								name: "weekFilter",
								controlToggle: true,
								parentShowPicker: workerWeekComponent.showWeekPicker,
								calendarStyle: { top: "100%", left: 0 },
								onChange: this.handleWeekChange,
							}}
						/>
						<WeekPicker
							onChange={this.handleWeekChange}
							currentDate={workerWeekComponent.currentWeek}
							name="weekFilter"
						/>
					</div>
				</div>
				<WeekTimeranges
					top={
						<div className="worker-week-timranges-top">
							{days.map(entry => (
								<div key={entry.number} className="dates">
									{moment(workerWeekComponent.currentWeek)
										.add(entry.number, "days")
										.format("DD MMMM YYYY")}
								</div>
							))}
						</div>
					}
					timeranges={timeranges}
					handleRemoveTimeRange={this.handleRemoveTimeRange}
					handleEditTimeRange={this.handleEditTimeRange}
					allowDeletion={false}
				/>
			</ContentLayout>
		)
	}
}

WorkerWeek.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	timeranges: state.redData.workerPlannedWeekTimeRanges,
	reports: state.redData.workerPlannedWeekReport,
	workerContracts: state.redData.workerContracts,
	workerWeekComponent: state.redComponents.workerWeekComponent,
})
export default connect(mapStateToProps)(WorkerWeek)
