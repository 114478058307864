import React from "react"

export const BoldSymbol = props => (
	<svg viewBox="0 0 18 18" {...props}>
		<path
			className="ql-stroke"
			d="M5 4h4.5A2.5 2.5 0 0 1 12 6.5 2.5 2.5 0 0 1 9.5 9H5V4zM5 9h5.5a2.5 2.5 0 0 1 2.5 2.5 2.5 2.5 0 0 1-2.5 2.5H5V9z"
		/>
	</svg>
)
