import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment"

import ModalLayout from "./ModalLayout"

export default class ActivityCell extends Component {
	constructor() {
		super()
		this.state = {
			toDisplay: {},
			shouldRender: true,
			showImageModal: false,
			modalImageUrl: "",
		}
	}
	UNSAFE_componentWillMount = () => {
		this.formatData()
	}
	formatData = () => {
		let { t } = this.context
		let { event, parameters } = this.props
		let toDisplay = {}
		let shouldRender = true
		if (event === "service_delivery.assigned") {
			toDisplay = {
				[t("residence_type")]: t(parameters.residence_type || ""),
				[t("address")]: parameters.address,
				[t("pets")]: parameters.residence_pets ? t("yes") : t("no"),
				[t("surface")]: parameters.residence_surface,
			}
			if (parameters.residence_how_to_get_in) {
				toDisplay[t("how_to_get_in")] = parameters.residence_how_to_get_in
			}
			if (parameters.residence_other_info) {
				toDisplay[t("other_info")] = parameters.residence_other_info
			}
		} else if (event === "service_delivery.cancelled") {
			toDisplay = {
				[t("cancelled")]: t(parameters.cancel_reason),
			}
		} else if (event === "service_delivery.start") {
			toDisplay = {
				[t("start_time")]: parameters.start_time,
			}
		} else if (event === "service_delivery.end") {
			toDisplay = {
				[t("end_time")]: parameters.end_time,
			}
		} else if (event === "service_delivery.message") {
			toDisplay = {
				[t("from")]: parameters.sender,
				[t("message")]: parameters.message,
			}
		} else if (event === "service_delivery.review") {
			toDisplay = {
				[t("review_rating")]: parameters.rating,
				[t("message")]: parameters.comment,
			}
		} else if (parameters.new_status) {
			toDisplay = {
				[t("old_status")]: t(parameters.old_status || ""),
				[t("new_status")]: t(parameters.new_status || ""),
			}
		} else if (event === "service_delivery.reassigned") {
		} else {
			shouldRender = false
		}

		if (parameters.images && parameters.images.length > 0) {
			toDisplay.images = parameters.images
		}
		if (parameters.note) {
			toDisplay.note = parameters.note
		}
		this.setState({ toDisplay, shouldRender })
	}
	showImage = e => {
		let { imageindex } = e.target.dataset
		let { parameters } = this.props
		this.setState({
			showImageModal: true,
			modalImageUrl: parameters.images[parseInt(imageindex, 10)],
		})
	}
	closeImageModal = () => {
		this.setState({ showImageModal: false, modalImageUrl: "" })
	}
	render() {
		if (!this.state.shouldRender) {
			return null
		}
		let { t } = this.context
		let { event, created_at } = this.props
		let { toDisplay, showImageModal, modalImageUrl } = this.state
		let title = t(event + "_event_title")
		return (
			<div className="activity-cell">
				{showImageModal && (
					<ModalLayout closeModal={this.closeImageModal}>
						<img className="activity-cell-modal-image" alt="i" src={modalImageUrl} />
					</ModalLayout>
				)}
				<span className="pink-dot" />
				<span className="vertical-line" />
				<span className="activity-time">
					{moment(created_at).format("dddd, MMMM Do YYYY, HH:mm")}
				</span>
				<div className="activity-box">
					<h3 className="activity-title">{title}</h3>
					{Object.keys(toDisplay).map(key => {
						if (key === "images") {
							return (
								<div key={key} className="activity-parameter">
									<label>{t("images")}</label>
									<div className="message-images">
										{toDisplay[key].map((e, i) => (
											<img
												key={i}
												alt="i"
												src={e}
												onClick={this.showImage}
												data-imageindex={i}
											/>
										))}
									</div>
								</div>
							)
						}
						return (
							<div key={key} className="activity-parameter">
								<label>{key}</label>
								<span>{toDisplay[key]}</span>
							</div>
						)
					})}
				</div>
			</div>
		)
	}
}

ActivityCell.contextTypes = { t: PropTypes.func }
