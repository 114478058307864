export const publicTransportLogic = (distance, amount, worker_vehicle, lang) => {
	switch (lang) {
		case "en":
			return enDescription(distance, amount, worker_vehicle)
		case "fr":
			return frDescription(distance, amount, worker_vehicle)
		case "nl":
			return nlDescription(distance, amount, worker_vehicle)
		default:
			return `No description for that case`
	}
}

function enDescription(distance, amount, worker_vehicle) {
	switch (worker_vehicle) {
		case "public_transport":
			return `
                PUBLIC TRANSPORT
                Total distance is ${distance}km
                Total amount for this distance - ${amount}
                *NOTE: for any distance more that 150km amount will stay the same
                Each day calculated as:
                    distance_for_day / ${distance} * ${amount}
            `
		case "car":
			return `
                CAR
                Total distance is ${distance}km
                Home-Customer:
                    If affiliate's settings contain info about car_home_client_rate - get this amount.
                    Calculate daily amount as:
                        car_home_client_rate * daily_distance
                    Otherwise daily_amount calculated based on government info and depends on daily_distance.
                
                Customer-Customer:
                    If affiliate's settings contain info about "Compensation rate by KM Customer-Customer < 15km" and 
                    "Compensation rate by KM Customer-Customer >15km" - these values are used as car_rate.
                    Otherwise default values is used - 0.28€/km
                    Calculate daily amount as:
                        car_rate * daily_distance
                
            `
		case "bike":
			return `
                BIKE
                Amount per day taken from affiliate's settings.
                If the value is not specified, the default is used - 0.25€/km.
                Each day calculated as:
                    distance_per_day * amount_per_km
            `
		case "other":
			return `
                OTHER TYPE
                Always return 0
            `
		case "company_car":
			return `
                COMPANY CAR
                Always return 0
            `
		default:
			return `No description for that case`
	}
}

function nlDescription(distance, amount, worker_vehicle) {
	switch (worker_vehicle) {
		case "public_transport":
			return `
                Totale afstand is ${distance} km
                Totale vergoeding voor deze afstand - ${amount}
                *OPMERKING: voor elke afstand van meer dan 150 km blijft het bedrag hetzelfde
                Elke dag wordt berekend als:
                    afstand_per_dag / ${distance} * ${amount}
            `
		case "car":
			return `
                AUTO
                Totale afstand is ${distance} FIETS
                Van thuis naar klant:
                    Als de instellingen van de van de klant informatie bevatten over het tarief voor auto_thuis_klant, wordt dit bedrag gebruikt.
                    Bereken het dagbedrag als:
                        auto_thuis_klant_tarief * dagelijkse afstand
                    Anders wordt het dagbedrag berekend op basis van overheidsinformatie en is afhankelijk van de dagelijkse afstand.
                Van klant naar klant:
                    Als de instellingen van de klant informatie bevatten over "Vergoeding per KM Klant-Klant < 15 km" en "Vergoeding per KM Klant-Klant > 15 km", worden deze waarden gebruikt als het tarief voor de auto.
                    Anders wordt de standaardwaarde gebruikt - 0,28€/km
                    Bereken het dagbedrag als:
                        car_rate * dagelijkse afstand
                
            `
		case "bike":
			return `
                FIETS
                Bedrag per dag, afkomstig uit de affiliate-instellingen.
                Als de waarde niet is opgegeven, wordt de standaardwaarde gebruikt: 0,25€/km.
                Elke dag wordt berekend als:
                    afstand_per_dag * bedrag_per_km
            `
		case "other":
			return `
                ANDERE
                Altijd 0 retourneren
            `
		case "company_car":
			return `
                Bedrijfswagen
                Altijd 0 retourneren
            `
		default:
			return `No description for that case`
	}
}

function frDescription(distance, amount, worker_vehicle) {
	switch (worker_vehicle) {
		case "public_transport":
			return `
                TRANSPORT EN COMMUN
                Distance totale : ${distance} km  
                Remboursement total pour cette distance : ${amount}  
                *REMARQUE : Pour toute distance supérieure à 150 km, le montant reste le même.
                Chaque jour est calculé comme suit :  
                    distance_par_jour / ${distance} * ${amount}
            `
		case "car":
			return `
                VOITURE
                Distance totale : ${distance} km
                De la maison au client :
                    Si les paramètres de l'affilié contiennent des informations sur le tarif pour voiture_maison_client, ce montant sera utilisé.
                    Le montant journalier est calculé comme suit :  
                        tarif_voiture_maison_client * distance quotidienne
                    Sinon, le montant journalier est calculé en fonction des informations gouvernementales et dépend de la distance quotidienne.
                
                Du client au client :
                    Si les paramètres de l'affilié contiennent des informations sur « Remboursement par KM Client-Client < 15 km » et « Remboursement par KM Client-Client > 15 km », ces valeurs seront utilisées comme tarif pour la voiture.
                    Sinon, la valeur standard est utilisée - 0,28 €/km  
                    Le montant journalier est calculé comme suit :  
                        tarif_voiture * distance quotidienne
                
            `
		case "bike":
			return `
                Bicyclette
                Montant par jour extrait des paramètres de l'affilié.
                Si la valeur n'est pas spécifiée, la valeur par défaut est utilisée - 0,25 €/km.
                Each day calculated as:
                    distance_per_day * amount_per_km
            `
		case "other":
			return `
                AUTRE
                Toujours renvoyer 0
            `
		case "company_car":
			return `
                Voiture de société
                Toujours renvoyer 0
            `
		default:
			return `No description for that case`
	}
}
