import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"

import * as actions from "../actions"

import {
	ContentLayout,
	SodexoBookingDatedLine,
	PootsyTable,
	PootsyButton,
} from "./FunctionalDesign"
import Paginator from "./Paginator"
import BookingFilters from "./BookingFilters"

class SodexoValidate extends Component {
	state = {
		intervalId: "",
	}
	componentDidMount = () => {
		let { dispatch } = this.props
		dispatch(actions.checkSodexoValidationStatus())
	}
	componentWillUnmount = () => {
		clearInterval(this.state.intervalId)
	}

	startRequestsIntervals = () => {
		let { dispatch } = this.props
		let intervalId = setInterval(() => {
			if (this.props.component.validationsStatus !== "no_validations_in_progress")
				dispatch(actions.checkSodexoValidationStatus())
		}, 30000)
		this.setState({ intervalId })
	}
	handleChanges = changes => {
		this.props.dispatch(actions.sodexoValidateStateChange(changes))
	}
	handleChangePage = changes => {
		this.props.dispatch(actions.bookingFiltersStateChange({ page: changes.value }))
	}
	validateAllVouchers = () => {
		let {
			dispatch,
			currentQuery: { realQuery },
		} = this.props
		let url = "/front/affiliates/sodexo/sodexo_update_booking_status/batch_validate"
		let init = {
			method: "POST",
			body: JSON.stringify(realQuery),
		}
		dispatch(actions.simpleFetch(url, init, actions.BATCH_VALIDATE_SODEXO_BOOKINGS))
		this.startRequestsIntervals()
	}
	render() {
		let { t } = this.context
		let {
			bookings,
			match,
			location,
			history,
			page,
			pagination,
			component: { svData, validationsStatus },
		} = this.props
		let optionsBoxes = (
			<BookingFilters
				match={match}
				location={location}
				history={history}
				filteredFilters={["voucher_type_filter", "sodexo_status_filter"]}
				customQueries={{
					sodexoLink: {
						urlName: "sl",
						queryName: "filter_sodexo_linkage",
						mustBeSet: true,
						defaultValue: "linked",
					},
					fromDateQuery: {
						urlName: "from",
						queryName: "from_date",
						mustBeSet: true,
						defaultValue: moment()
							.subtract(1, "month")
							.format("YYYY-MM-DD"),
					},
					toDateQuery: {
						urlName: "to",
						queryName: "to_date",
						mustBeSet: true,
						defaultValue: moment().format("YYYY-MM-DD"),
					},
					svData: {
						urlName: "svd",
						queryName: "with_sv_data",
						mustBeSet: true,
						defaultValue: true,
					},
					voucherType: {
						urlName: "voucherType",
						queryName: "filter_voucher_type",
						mustBeSet: true,
						defaultValue: "electronic",
					},
					sodexoStatus: {
						urlName: "vt",
						queryName: "filter_sodexo_status_only_electronic",
						mustBeSet: true,
						defaultValue: ["Created", "ModifiedByProvider", "CorrectedByProvider"],
					},
					cancelledOccurrences: {
						urlName: "c",
						queryName: "reject_cancelled_occurrences",
						mustBeSet: true,
						defaultValue: true,
					},
				}}
			/>
		)
		return (
			<ContentLayout optionsBoxes={optionsBoxes} customClass="SodexoValidate">
				<PootsyTable
					labels={[
						t("total_vouchers_to_validate"),
						t("Created"),
						t("ModifiedByProvider"),
						t("CorrectedByProvider"),
						"",
					]}
					rows={[
						{
							columns: [
								svData.Created_count +
									svData.ModifiedByProvider_count +
									svData.CorrectedByProvider_count,
								svData.Created_count,
								svData.ModifiedByProvider_count,
								svData.CorrectedByProvider_count,
								<div>
									<PootsyButton
										text={t("validate_all_vouchers")}
										onClick={this.validateAllVouchers}
										fetching={
											validationsStatus !== "no_validations_in_progress"
										}
									/>
									<div className="validations-status">{t(validationsStatus)}</div>
								</div>,
							],
						},
					]}
				/>
				{bookings.map(entry => (
					<SodexoBookingDatedLine key={entry.id} booking={entry} />
				))}
				<Paginator
					name="pageQuery"
					currPage={page}
					totalPages={pagination.total_pages}
					goToPage={this.handleChangePage}
				/>
			</ContentLayout>
		)
	}
}

SodexoValidate.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.sodexoValidateComponent,
	bookings: state.redData.bookings,
	page: state.redComponents.bookingFiltersComponent.page,
	pagination: state.redComponents.bookingFiltersComponent.pagination,
	currentQuery: state.currentURLQuery,
})
export default connect(mapStateToProps)(SodexoValidate)
