import moment from "moment"
import { history } from "../history"
import { simpleFetch } from "./index"
import { stringifyQuery } from "../helpers/qs"
import {
	contractV2MetaChange,
	processAddNewContract,
	contractV2UIChange,
	removeNewContract,
} from "./acWorkerContract"
import { addTheoreticalHour, toggleTwoWeeksSchedule, newTimerangeObject } from "./acWorkerSchedule"

export const WORKER_WEEK_UICHANGE = "WORKER_WEEK_UICHANGE"
export const workerWeekUIChange = changes => ({ type: WORKER_WEEK_UICHANGE, changes })

export const RESET_WORKER_WEEK_STATE = "RESET_WORKER_WEEK_STATE"
export const resetWorkerWeekState = () => ({ type: RESET_WORKER_WEEK_STATE })

const activeContractForDate = (workerContracts, date) => {
	return workerContracts.find(entry => {
		// select the contract that starts during the week (?)
		if (entry.id === "new-contract") {
			return false
		}
		let startDateCheck = moment(entry.startDate)
			.startOf("isoweek")
			.isSameOrBefore(date)
		let endDateCheck = !entry.endDate.isValid() || entry.endDate.isSameOrAfter(date)
		return startDateCheck && endDateCheck
	})
}

const FETCH_WORKER_WEEK = "FETCH_WORKER_WEEK"
export const FETCH_WORKER_WEEK_REQUEST = "FETCH_WORKER_WEEK_REQUEST"
export const FETCH_WORKER_WEEK_SUCCESS = "FETCH_WORKER_WEEK_SUCCESS"
export const FETCH_WORKER_WEEK_FAILURE = "FETCH_WORKER_WEEK_FAILURE"
export const fetchWorkerWeek = week => async (dispatch, getState) => {
	let {
		redData: { workerContracts },
	} = getState()
	if (workerContracts.length === 0) {
		console.warn("contracts not loaded yet")
	}
	let activeContract = activeContractForDate(workerContracts, week)
	if (!activeContract) {
		return
	}
	dispatch(resetWorkerWeekState())
	let weeks = [week, moment(week).add(1, "week")]
	weeks.forEach(week => {
		activeContract = activeContractForDate(workerContracts, week)
		if (!activeContract) {
			return
		}
		let params = {
			worker_contract_ids: activeContract.id,
			date_for_week: week.format(),
			slot_splitting: false,
			page: 1,
		}
		let url = "/front/affiliates/events" + stringifyQuery(params)
		let init = { method: "GET" }
		dispatch(simpleFetch(url, init, FETCH_WORKER_WEEK))
	})
	dispatch(workerWeekUIChange({ currentWeek: moment(week) }))
}

export const injectCurrentWeekIntoNewContract = ({ forTwoWeeksSchedule }) => async (
	dispatch,
	getState
) => {
	let {
		redData: { currentWorker, workerPlannedWeekTimeRanges },
		redComponents: { workerWeekComponent },
	} = getState()

	dispatch(removeNewContract())
	dispatch(processAddNewContract())
	dispatch(contractV2MetaChange({ selectedContractID: "new-contract" }))
	dispatch(contractV2UIChange({ selectedSchedulePeriod: 0 }))
	if (forTwoWeeksSchedule) {
		dispatch(toggleTwoWeeksSchedule())
	}
	let timerangesToInject = workerPlannedWeekTimeRanges.filter(entry => {
		let allowedType = ["booking", "theo-hour", "other"].includes(entry.type)
		let allowedWeek =
			forTwoWeeksSchedule ||
			moment(entry.startDate).isSame(workerWeekComponent.currentWeek, "isoweek")
		return allowedType && allowedWeek
	})

	let startOfBaseWeek = moment(workerWeekComponent.currentWeek).startOf("isoweek") // should already be a monday but just making sure...
	let nextWeek = moment(workerWeekComponent.currentWeek, "isoweek").add(7, "days")
	for (let i = 0; i < timerangesToInject.length; i++) {
		// Using a for loop instead of forEach because
		// this needs to wait for each iteration to finish before starting the next one.
		// forEach will just fire everything as fast as possible, so that ain't workin'...
		// Also, the contract needs to be refetched everytime, which sucks I know...
		let {
			redData: { workerContracts },
		} = getState()
		let newContract = workerContracts.find(entry => entry.id === "new-contract")
		let entry = timerangesToInject[i]
		let newStartDate = moment(newContract.startDate).isoWeekday(entry.startDate.isoWeekday())

		let entryShouldBeInSecondSP = undefined
		if (forTwoWeeksSchedule && newContract.schedulePeriods.length === 2) {
			// if the contract was already a two weeks schedule then the SP
			//managing the timerange will be determined following exactly what the
			// original data looked like
			entryShouldBeInSecondSP =
				moment(entry.startDate)
					.startOf("isoweek")
					.diff(startOfBaseWeek, "day") > 6
		} else if (forTwoWeeksSchedule && newContract.schedulePeriods.length === 1) {
			entryShouldBeInSecondSP = moment(entry.start).isSame(nextWeek, "isoweek")
		}

		if (entryShouldBeInSecondSP) {
			newStartDate.add(7, "day")
		}
		let newTheoreticalHour = newTimerangeObject({
			type: "theo-hour",
			startDate: newStartDate,
			start: moment(entry.start),
			end: moment(entry.end),
			allowEdition: true,
			allowDeletion: true,
		})
		await dispatch(
			addTheoreticalHour({
				contract: newContract,
				schedulePeriodID: entryShouldBeInSecondSP
					? newContract.schedulePeriods[1].id
					: newContract.schedulePeriods[0].id,
				newTheoreticalHour,
			})
		)
	}
	history.push("/cleaners/" + currentWorker.id + "/contract")
}

const SUBMIT_DIRECT_BOOKING_FROM_WORKER_WEEK = "SUBMIT_DIRECT_BOOKING_FROM_WORKER_WEEK"
export const SUBMIT_DIRECT_BOOKING_FROM_WORKER_WEEK_REQUEST =
	"SUBMIT_DIRECT_BOOKING_FROM_WORKER_WEEK_REQUEST"
export const SUBMIT_DIRECT_BOOKING_FROM_WORKER_WEEK_SUCCESS =
	"SUBMIT_DIRECT_BOOKING_FROM_WORKER_WEEK_SUCCESS"
export const SUBMIT_DIRECT_BOOKING_FROM_WORKER_WEEK_FAILURE =
	"SUBMIT_DIRECT_BOOKING_FROM_WORKER_WEEK_FAILURE"
export const submitDirectBooking = directBooking => (dispatch, getState) => {
	let url = "thing/stuff/whatever"
	let init = {
		method: "POST",
		body: JSON.stringify({
			force: true,
			delivery_date: directBooking.startDate.format(),
			recurrence: true,
			recurrence_delay: directBooking.dayPeriod === 7 ? "1" : "2",
			start_time: directBooking.start.format(),
			end_time: directBooking.end.format(),
			day_period: directBooking.dayPeriod,
			residence_id: directBooking.residenceID,
			zip_code: directBooking.zip,
			voucher_type: directBooking.voucherType,
			customer_contract_id: directBooking.chosenCustomer.id,
		}),
	}
	dispatch(simpleFetch(url, init, SUBMIT_DIRECT_BOOKING_FROM_WORKER_WEEK))
}

const SUBMIT_OTHER_ACTIVITY = "SUBMIT_OTHER_ACTIVITY"
export const SUBMIT_OTHER_ACTIVITY_REQUEST = "SUBMIT_OTHER_ACTIVITY_REQUEST"
export const SUBMIT_OTHER_ACTIVITY_SUCCESS = "SUBMIT_OTHER_ACTIVITY_SUCCESS"
export const SUBMIT_OTHER_ACTIVITY_FAILURE = "SUBMIT_OTHER_ACTIVITY_FAILURE"
export const submitOtherActivity = (otherActivity, contractID, affiliateWorkerId) => (
	dispatch,
	getState
) => {
	if (!contractID) {
		return
	}
	let url = "/front/affiliates/worker_contracts/" + contractID + "/other_activities"
	let init = {
		method: "POST",
		body: JSON.stringify({
			affiliate_worker_id: affiliateWorkerId,
			worker_contract_id: contractID,
			start_date: otherActivity.startDate.format(),
			end_date: otherActivity.endDate.isValid() ? otherActivity.endDate.format() : undefined,
			start_time: otherActivity.start.format("HH:mm"),
			end_time: otherActivity.end.format("HH:mm"),
			day_period: otherActivity.dayPeriod,
			affiliate_note: otherActivity.note,
			activity_category: otherActivity.activityCategory,
			activity_code: otherActivity.activityCode,
			form_c32a_number: otherActivity.formC32ANumber,
			must_call_onss: otherActivity.mustCallOnss,
			is_workshop_hour: false,
			address: otherActivity.address,
			selected_customer: otherActivity.selectedCustomer,
		}),
	}
	return dispatch(simpleFetch(url, init, SUBMIT_OTHER_ACTIVITY, { otherActivity: otherActivity }))
}

export const submitEditOtherActivity = (otherActivity, contractID, affiliateWorkerId) => (
	dispatch,
	getState
) => {
	if (!contractID) {
		return
	}
	let url = "/front/affiliates/worker_contracts/0/other_activities/" + otherActivity.realEventId
	let init = {
		method: "PUT",
		body: JSON.stringify({
			affiliate_worker_id: affiliateWorkerId,
			worker_contract_id: contractID,
			start_date: otherActivity.startDate.format(),
			end_date: otherActivity.endDate.isValid() ? otherActivity.endDate.format() : undefined,
			start_time: otherActivity.start.format("HH:mm"),
			end_time: otherActivity.end.format("HH:mm"),
			day_period: otherActivity.dayPeriod,
			affiliate_note: otherActivity.note,
			activity_category: otherActivity.activityCategory,
			activity_code: otherActivity.activityCode,
			form_c32a_number: otherActivity.formC32ANumber,
			must_call_onss: otherActivity.mustCallOnss,
			is_workshop_hour: false,
			address: otherActivity.address,
			recurrence: otherActivity.recurrence,
			selected_customer: otherActivity.selectedCustomer,
		}),
	}
	return dispatch(simpleFetch(url, init, SUBMIT_OTHER_ACTIVITY, { otherActivity: otherActivity }))
}

export const submitOtherActivityForActiveContract = otherActivity => (dispatch, getState) => {
	let {
		redData: { workerContracts },
	} = getState()
	let activeContract = workerContracts.find(entry => entry.activeContract)
	dispatch(submitOtherActivity(otherActivity, activeContract.id))
}

export const submitOtherActivityForRelevantContract = otherActivity => (dispatch, getState) => {
	let {
		redData: { workerContracts },
	} = getState()
	let contract = workerContracts.find(entry => {
		let startDateCheck = otherActivity.startDate.isSameOrAfter(entry.startDate)
		let endDateCheck = !entry.endDate || otherActivity.startDate.isBefore(entry.endDate)
		return startDateCheck && endDateCheck
	})
	dispatch(submitOtherActivity(otherActivity, contract.id))
}

const SUBMIT_DELETE_OTHER_ACTIVITY = "SUBMIT_DELETE_OTHER_ACTIVITY"
export const SUBMIT_DELETE_OTHER_ACTIVITY_REQUEST = "SUBMIT_DELETE_OTHER_ACTIVITY_REQUEST"
export const SUBMIT_DELETE_OTHER_ACTIVITY_SUCCESS = "SUBMIT_DELETE_OTHER_ACTIVITY_SUCCESS"
export const SUBMIT_DELETE_OTHER_ACTIVITY_FAILURE = "SUBMIT_DELETE_OTHER_ACTIVITY_FAILURE"
export const submitDeleteOtherActivity = otherActivity => (dispatch, getState) => {
	let {
		redData: { workerContracts },
	} = getState()

	let activeContract = workerContracts.find(entry => entry.activeContract)
	let url = "/front/affiliates/worker_contracts/" + activeContract.id + "/other_activities"
	let init = {
		method: "DELETE",
		body: JSON.stringify({
			worker_contract_id: activeContract.id,
			other_activity_id: activeContract.id,
		}),
	}
	dispatch(simpleFetch(url, init, SUBMIT_DELETE_OTHER_ACTIVITY))
}
