import React, { Component } from "react"
import { connect } from "react-redux"
import { Route } from "react-router-dom"
import PropTypes from "prop-types"

import * as actions from "../actions"

import { PageLayout } from "./FunctionalDesign"
import { NavLink } from "./NavLink"
import Dimonas from "./Dimonas"
import EconomicSuspensions from "./EconomicSuspensions"
import UnemploymentDeclarations from "./UnemploymentDeclarations"
import UnemploymentBooks from "./UnemploymentBooks"
// import "../stylesheets/Documents.styl"

class Documents extends Component {
	handleChanges = changes => {
		this.props.dispatch(actions.documentsStateChange(changes))
	}

	render() {
		let { t } = this.context
		let { match } = this.props
		let pageHeaderLeft = <h1 className="page-title customers">{t("documents")}</h1>

		let linksArray = [
			{ label: t("worker-register"), link: "worker-register" },
			{ label: t("economic_suspensions"), link: "eco-suspensions" },
			{ label: t("economic_unemployment_declarations"), link: "eco-declarations" },

			{ label: t("economic_unemployment_books"), link: "eco-unemployment-books" },
		]
		let subNavLinks = linksArray.map(entry => (
			<NavLink key={entry.link} to={"/documents/" + entry.link}>
				{entry.icon} {entry.label}
			</NavLink>
		))
		return (
			<PageLayout
				customClass="Documents"
				headerLeft={pageHeaderLeft}
				subNavLinks={subNavLinks}
			>
				<Route path={match.path + "/worker-register"} component={Dimonas} />
				<Route path={match.path + "/eco-suspensions"} component={EconomicSuspensions} />
				<Route
					path={match.path + "/eco-declarations"}
					component={UnemploymentDeclarations}
				/>
				<Route
					path={match.path + "/eco-unemployment-books"}
					component={UnemploymentBooks}
				/>
			</PageLayout>
		)
	}
}

Documents.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.documentsComponent,
})
export default connect(mapStateToProps)(Documents)
