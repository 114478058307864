export const DASHBOARDDATA_REQUEST = "DASHBOARDDATA_REQUEST"
export const DASHBOARDDATA_SUCCESS = "DASHBOARDDATA_SUCCESS"
export const DASHBOARDDATA_FAILURE = "DASHBOARDDATA_FAILURE"

export const DASHBOARD_CUSTOMERS_REQUEST = "DASHBOARD_CUSTOMERS_REQUEST"
export const DASHBOARD_CUSTOMERS_SUCCESS = "DASHBOARD_CUSTOMERS_SUCCESS"
export const DASHBOARD_CUSTOMERS_FAILURE = "DASHBOARD_CUSTOMERS_FAILURE"

export const DASHBOARD_QUALITY_REQUEST = "DASHBOARD_QUALITY_REQUEST"
export const DASHBOARD_QUALITY_SUCCESS = "DASHBOARD_QUALITY_SUCCESS"
export const DASHBOARD_QUALITY_FAILURE = "DASHBOARD_QUALITY_FAILURE"

export const DASHBOARD_SODEXO_REQUEST = "DASHBOARD_SODEXO_REQUEST"
export const DASHBOARD_SODEXO_SUCCESS = "DASHBOARD_SODEXO_SUCCESS"
export const DASHBOARD_SODEXO_FAILURE = "DASHBOARD_SODEXO_FAILURE"

export const DASHBOARD_COMPANY_KPI_REQUEST = "DASHBOARD_COMPANY_KPI_REQUEST"
export const DASHBOARD_COMPANY_KPI_SUCCESS = "DASHBOARD_COMPANY_KPI_SUCCESS"
export const DASHBOARD_COMPANY_KPI_FAILURE = "DASHBOARD_COMPANY_KPI_FAILURE"
