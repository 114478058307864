import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import * as actions from "../actions"

import InputsChangeHandler from "./InputsChangeHandler"
import { PootsyButton, PootsySubHeading } from "./FunctionalDesign"
import { PootsyFileInput } from "./FunctionalInputs"
import ModalLayout from "./ModalLayout"

import fileIcon from "../static/images/file-icon.png"


class WorkerDocuments extends Component {
	state = {
		currentDocument: "",
		showDeleteDocumentModal: false,
		stagedForDeletionID: "",
	}
	handleFormChanges = changes => {
		this.setState(changes)
	}
	toggleDeleteDocumentModal = e => {
		if (this.state.showDeleteDocumentModal) {
			this.setState({
				showDeleteDocumentModal: false,
				stagedForDeletionID: "",
			})
		} else {
			e.stopPropagation()
			this.setState({
				showDeleteDocumentModal: true,
				stagedForDeletionID: parseInt(e.target.dataset.docid, 10),
			})
		}
	}
	saveDocument = () => {
		let { dispatch, contractID } = this.props
		let { currentDocument } = this.state
		let url = "/front/affiliates/worker_contracts/" + contractID + "/documents"
		let init = {
			method: "POST",
			body: JSON.stringify({
				files: [
					{
						attribute: "file",
						filename: currentDocument.filename,
						base64_content: currentDocument.file,
					},
				],
			}),
		}
		dispatch(actions.simpleFetch(url, init, "SAVE_WORKER_DOCUMENT", { contractID }))
		this.setState({ currentDocument: "" })
	}
	deleteFile = () => {
		let { dispatch, contractID } = this.props
		let { stagedForDeletionID } = this.state
		let url =
			"/front/affiliates/worker_contracts/" + contractID + "/documents/" + stagedForDeletionID
		let init = { method: "DELETE" }
		dispatch(
			actions.simpleFetch(url, init, "DELETE_WORKER_DOCUMENT", {
				contractID,
				deletedID: stagedForDeletionID,
			})
		)
		this.setState({ stagedForDeletionID: "", showDeleteDocumentModal: false })
	}
	render() {
		let { t } = this.context
		let { documents } = this.props
		let { showDeleteDocumentModal, currentDocument } = this.state
		return (
			<InputsChangeHandler
				style={{}}
				customClass="cleaner-documents"
				onChange={this.handleFormChanges}
			>
				{showDeleteDocumentModal && (
					<ModalLayout
						title={t("delete_document_modal_title")}
						closeModal={this.toggleDeleteDocumentModal}
						buttons={[
							<PootsyButton key="a" text={t("yes")} onClick={this.deleteFile} />,
							<PootsyButton
								key="b"
								text={t("no")}
								onClick={this.toggleDeleteDocumentModal}
							/>,
						]}
						formDivClass="delete-worker-document-modal"
					>
						{t("delete_document_prompt")}
					</ModalLayout>
				)}
				<PootsySubHeading text={t("cleaner_documents")} />
				<div className="current-document">
					<PootsyFileInput name="currentDocument" label={t("upload_cleaner_document")} />
					{currentDocument && (
						<Fragment>
							<div className="current-document-name">{currentDocument.filename}</div>
							<PootsyButton text={t("save_document")} onClick={this.saveDocument} />
						</Fragment>
					)}
				</div>
				{documents.length > 0 && (
					<div className="documents">
						{documents.map(entry => (
							<div key={entry.id} className="uploaded-file-link">
								<div
									className="delete-file-button"
									onClick={this.toggleDeleteDocumentModal}
									data-docid={entry.id}
								>
									<div className="tooltip">{t("delete_document")}</div>X
								</div>
								<a
									target="_blank"
									rel="noopener noreferrer"
									href={entry.file.url}
								>
									<img alt="icon" src={fileIcon} />
									{entry.file.filename}
								</a>
							</div>
						))}
					</div>
				)}
			</InputsChangeHandler>
		)
	}
}

WorkerDocuments.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({})
export default connect(mapStateToProps)(WorkerDocuments)
