import React from "react"
import App from "./App.js"
import { connect } from "react-redux"
import Login from "../components/Login"
import Spinner from "../components/Spinner"
import AffiliatePicker from "../components/AffiliatePicker"

export const Root = props => {
	let { auth, showSpinner } = props
	return (
		<div className="root">
			{showSpinner.show && <Spinner messages={showSpinner.messages} />}
			{auth.loggedIn ? (
				auth.currentAffiliateID ? (
					<App {...props} />
				) : (
					<AffiliatePicker />
				)
			) : (
				<Login />
			)}
		</div>
	)
}

const mapStateToProps = state => state
export default connect(mapStateToProps)(Root)
