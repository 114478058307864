import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import { OptionBox, PootsyMultipleValuesBox, PootsyButton } from "./FunctionalDesign"
import { PootsySearchBox, PootsyRadioInputs } from "./FunctionalInputs"
import Filter from "./Filter"

export default class TimesheetsLeftColumn extends Component {
	render() {
		let { t } = this.context
		let {
			currTimesheet,
			timesheetFiltering,
			selectedSquads,
			cleanerSearchResults,
			closedTimesheets,
			openedTimesheets,
			upfrontPaymentTimesheets,
			handleTimesheetFilteringChange,
			handleSquadFilterChange,
			searchWorkers,
			selectWorker,
			currAffiliate,
			timesheetsNeedingCorrection,
			timesheetsClosedAfterLastTsc,
		} = this.props
		return (
			<div className="secsoc-schedules left-part">
				<OptionBox
					key="selector"
					boxTitle={t("schedule_selector_title")}
					customClass="secsoc-schedules-data-selector"
				>
					<Filter
						label={t("search_squads")}
						name="squadsQuery"
						possibleValues={currAffiliate.squads.map(entry => ({
							label: entry.name,
							value: entry.id,
						}))}
						currentFilters={selectedSquads}
						onChange={handleSquadFilterChange}
					/>
					<PootsyRadioInputs
						groupName="timesheetFiltering"
						groupLabel={t("timesheet_filtering")}
						selected={timesheetFiltering}
						onChange={handleTimesheetFilteringChange}
						options={[
							{
								id: "closed",
								value: "closed",
								label: t("closed_timesheets") + ` (${closedTimesheets.length})`,
							},
							{
								id: "opened",
								value: "opened",
								label: t("unclosed_timesheets") + ` (${openedTimesheets.length})`,
							},
							{
								id: "upfrontPayment",
								value: "upfrontPayment",
								label:
									t("upfront_payment_timesheets") +
									` (${upfrontPaymentTimesheets.length})`,
							},
							{
								id: "needingCorrection",
								value: "needingCorrection",
								label:
									t("timesheets_needing_correction") +
									` (${timesheetsNeedingCorrection.length})`,
							},
							{
								id: "closedAfterLastTsc",
								value: "closedAfterLastTsc",
								label:
									t("timesheets_closed_after_last_tsc") +
									` (${timesheetsClosedAfterLastTsc.length})`,
							},
							{ id: "all", value: "all", label: t("all_timesheets") },
						]}
					/>
					<PootsySearchBox
						label={t("search_cleaners")}
						name="selectedWorker"
						onChange={searchWorkers}
						searchResults={cleanerSearchResults}
						onResultClick={selectWorker}
					/>
				</OptionBox>
				<OptionBox key="info" boxTitle={t("cleaner_info")}>
					<PootsyMultipleValuesBox
						values={[
							{ label: t("address"), values: [currTimesheet.worker.address] },
							{ label: t("phone"), values: [currTimesheet.worker.phone] },
							{ label: t("email"), values: [currTimesheet.worker.email] },
							{ label: t("niss"), values: [currTimesheet.worker.nissNumber] },
							// employee number (?)
						]}
					/>
					<Link
						to={"/cleaners/" + currTimesheet.worker.affiliateWorkerID + "/profile"}
						target="_blank"
					>
						<PootsyButton text={t("go_to_worker_page")} />
					</Link>
				</OptionBox>
				{openedTimesheets.length > 0 && (
					<OptionBox
						key="unexportable"
						customClass="unexportable-timesheets"
						boxTitle={t("unexportable_timesheets")}
					>
						{openedTimesheets.map((ot, index) => (
							<div
								className="worker-with-unexportable-timesheet"
								key={index + ":" + ot.worker_contract_id}
								onClick={selectWorker}
								data-value={ot.worker_contract_id}
							>
								{ot.worker_name}
								{ot.displayContractDates && (
									<div
										className="contract-dates"
										data-value={ot.worker_contract_id}
									>
										{ot.contractStart.format("DD/MM/YYYY")} -{" "}
										{ot.contractEnd ? ot.contractEnd.format("DD/MM/YYYY") : ""}
									</div>
								)}
							</div>
						))}
					</OptionBox>
				)}
			</div>
		)
	}
}

TimesheetsLeftColumn.contextTypes = { t: PropTypes.func }
