import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"

import * as actions from "../actions"

import { ContentLayout, OptionBox, PootsyButton, SimpleLine } from "./FunctionalDesign"

import arrowRightIcon from "../static/images/arrow-right-icon.png"

class CustomerAbsences extends Component {
	UNSAFE_componentWillMount = () => {
		let { id } = this.props.match.params
		let init = { method: "GET" }
		let url = "/front/affiliates/customers/unavailabilities/?customer_id=" + id
		this.props.dispatch(actions.simpleFetch(url, init, "FETCH_CUSTOMER_ABSENCES"))
	}
	toggleCreateAbsenceModal = () => {
		let { showCreateAbsenceModal } = this.props
		this.props.dispatch(
			actions.customerDetailsStateChange({
				showCreateAbsenceModal: !showCreateAbsenceModal,
			})
		)
	}
	deleteAbsence = e => {
		let { id } = this.props.match.params
		let { absenceid } = e.target.dataset
		let init = { method: "DELETE" }
		let url = "/front/affiliates/customers/unavailabilities/" + absenceid + "?customer_id=" + id
		this.props.dispatch(
			actions.simpleFetch(url, init, "DELETE_CUSTOMER_ABSENCE", { deletedID: absenceid })
		)
	}
	render() {
		let { t } = this.context
		let { absences } = this.props
		let optionsBoxes = (
			<OptionBox boxTitle={t("customer_bio")}>
				<PootsyButton text={t("add_new_absence")} onClick={this.toggleCreateAbsenceModal} />
			</OptionBox>
		)
		return (
			<ContentLayout optionsBoxes={optionsBoxes} customClass="customer-absences">
				<div className="absences">
					{absences.map(entry => (
						<SimpleLine key={entry.id} customClass="customer-absence">
							<div className="customer-absence-start-end">
								<div className="start-time">
									<label>{t("start")}:</label>
									<span>
										{moment(entry.attributes.start_time).format(
											"DD/MM/YY HH:mm"
										)}
									</span>
								</div>
								<img alt="presentation" src={arrowRightIcon} />
								<div className="end-time">
									<label>{t("end")}:</label>
									<span>
										{moment(entry.attributes.end_time).format("DD/MM/YY HH:mm")}
									</span>
								</div>
							</div>
							<div>{entry.attributes.note}</div>
							<PootsyButton
								text={t("delete")}
								theme="cancel"
								onClick={this.deleteAbsence}
								dataset={{ "data-absenceid": entry.id }}
							/>
						</SimpleLine>
					))}
				</div>
			</ContentLayout>
		)
	}
}

CustomerAbsences.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	absences: state.redData.currentCustomerAbsences,
	showDataExportExplanationModal:
		state.redComponents.customerDetailsComponent.showDataExportExplanationModal,
})
export default connect(mapStateToProps)(CustomerAbsences)
