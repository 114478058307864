import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment"

import { PootsyButton } from "./FunctionalDesign"

const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
export default class CompleteMonthPicker extends Component {
	state = {
		displayedDate: undefined,
		showPicker: false,
	}
	componentDidMount = () => {
		this.setState({ displayedDate: this.props.currentDate.startOf("month") })
	}
	componentWillUnmount = () => {
		document.removeEventListener("click", this.handleClickOutside)
	}
	goToPrevYear = () => {
		let { displayedDate } = this.state
		this.setState({ displayedDate: moment(displayedDate).subtract(1, "year") })
	}
	goToNextYear = () => {
		let { filter } = this.props
		let { displayedDate } = this.state
		const newYear = moment(displayedDate)
			.add(1, "year")
			.startOf("year")
		if (filter && !filter(newYear)) {
			return
		}
		this.setState({ displayedDate: newYear })
	}
	goToCurrentMonth = () => {
		this.setState({ displayedDate: moment().startOf("month") })
	}
	submitMonth = newMonth => {
		this.setState({ displayedDate: newMonth })
		this.props.onChange(newMonth)
		this.togglePicker()
	}
	handleClickOutside = e => {
		if (!this.state.showPicker) {
			return
		}
		e.stopPropagation()
		let wrapper = this.refs.wrapper
		let isClickInside = wrapper.contains(e.target)
		if (!isClickInside) {
			this.togglePicker()
		}
	}
	togglePicker = () => {
		let { showPicker } = this.state
		if (showPicker) {
			document.removeEventListener("click", this.handleClickOutside)
		} else {
			this.setState({ displayedDate: this.props.currentDate })
			document.addEventListener("click", this.handleClickOutside)
		}
		this.setState({ showPicker: !showPicker })
	}
	render() {
		// let { t } = this.context
		let { currentDate, filter } = this.props
		let { displayedDate, showPicker } = this.state
		if (!displayedDate) {
			return null
		}
		let displayedMonth = moment({
			month: currentDate.month(),
			year: currentDate.year(),
		}).format("MMMM YYYY")
		return (
			<div ref="wrapper" className="CompleteMonthPicker">
				<div className="displayed" onClick={this.togglePicker}>
					{displayedMonth}
				</div>
				{showPicker && (
					<div className="picker">
						<div className="top">
							<PootsyButton
								text="<"
								onClick={this.goToPrevYear}
								size="small"
								theme="cancel"
							/>
							<div className="year">{displayedDate.format("YYYY")}</div>
							<PootsyButton
								text=">"
								onClick={this.goToNextYear}
								size="small"
								theme="cancel"
							/>
						</div>
						<div className="months">
							{months.map(month => {
								const m = moment({ month, year: displayedDate.year() })
								const selected = m.isSame(currentDate, "month")
								const disabled = filter && !filter(m)
								return (
									<div
										key={month}
										className={`month ${selected ? "selected" : ""} ${
											disabled ? "disabled" : ""
										}`}
										onClick={e => {
											if (!disabled) {
												this.submitMonth(m)
											}
										}}
									>
										{m.format("MMMM")}
									</div>
								)
							})}
						</div>
					</div>
				)}
			</div>
		)
	}
}

CompleteMonthPicker.contextTypes = { t: PropTypes.func }
