export const numberOfHours = currentBooking => {
	return currentBooking.sodexoStatus ? currentBooking.sodexoDuration : currentBooking.voucherCount
}

export const bookingHasHoursDifference = currentBooking => {
	let startTime = new Date(currentBooking.startTime)
	let endTime = new Date(currentBooking.endTime)
	let diff = Math.round((endTime - startTime) / (1000 * 60))
	return numberOfHours(currentBooking) * 60 !== diff
}
export const getEndTime = currentBooking => {
	if (!bookingHasHoursDifference(currentBooking)) {
		return currentBooking.endTime
	}
	let endTime = new Date(currentBooking.startTime)
	return endTime.setTime(endTime.getTime() + numberOfHours(currentBooking) * 3_600_000)
}
