import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import * as actions from "../actions"

import { PootsyTextArea } from "./FunctionalInputs"
import { PootsySubHeading, PootsyButton } from "./FunctionalDesign"
import InputsChangeHandler from "./InputsChangeHandler"

class TimesheetsCommunication extends Component {
	handleChanges = changes => {
		let { currTimesheet } = this.props
		// sanitize changes into ISO-8859-1 (latin1) encoding
		if (changes.secSocComment)
			changes.secSocComment = changes.secSocComment.replace(/[^\x00-\xFF]/g, "") // eslint-disable-line no-control-regex
		if (changes.workerComment)
			changes.workerComment = changes.workerComment.replace(/[^\x00-\xFF]/g, "") // eslint-disable-line no-control-regex
		this.props.dispatch(
			actions.timesheetCommentsChange(currTimesheet.timesheetInfo.timesheetID, changes)
		)
	}
	submitChanges = () => {
		this.props.dispatch(actions.submitTimesheetCommentsChanges())
	}
	render() {
		let { t } = this.context
		let { currTimesheet } = this.props
		return (
			<InputsChangeHandler
				onChange={this.handleChanges}
				customClass="timesheets-communication"
			>
				<div className="salary-slip-message">
					<PootsySubHeading text={t("salary_slip_message_description")} />
					<PootsyTextArea
						label={t("salary_slip_message")}
						name="workerComment"
						value={currTimesheet.timesheetInfo.workerComment}
					/>
				</div>
				<div className="payroll-consultant-message">
					<PootsySubHeading text={t("payroll_consultant_message_description")} />
					<PootsyTextArea
						label={t("payroll_consultant_message")}
						name="secSocComment"
						value={currTimesheet.timesheetInfo.secSocComment}
					/>
				</div>
				<PootsyButton text={t("save_changes")} onClick={this.submitChanges} />
			</InputsChangeHandler>
		)
	}
}

TimesheetsCommunication.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({})
export default connect(mapStateToProps)(TimesheetsCommunication)
