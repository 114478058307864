import React from "react"
import PropTypes from "prop-types"
import moment from "moment"

import { NoCSSInfoBox } from "./FunctionalDesign"

const TimesheetInfos = ({ timesheet }, { t }) => {
	if (!timesheet.timesheetInfo) {
		return null
	}

	const hasClosureDate = !!timesheet.timesheetInfo.closureDate
	const hasIntermediateClosureDate =
		timesheet.timesheetInfo.intermediateClosureDates &&
		timesheet.timesheetInfo.intermediateClosureDates.length > 0 &&
		!hasClosureDate
	const hasTscs = timesheet.timesheetInfo.tscs && timesheet.timesheetInfo.tscs.length > 0
	return (
		<div className="TimesheetInfos">
			{hasIntermediateClosureDate && (
				<NoCSSInfoBox
					label={t("intermediate_closure_date")}
					value={timesheet.timesheetInfo.intermediateClosureDates[0].format("DD/MM/YY")}
				/>
			)}
			{hasClosureDate && (
				<NoCSSInfoBox
					label={t("timesheet_closed_on")}
					value={moment(timesheet.timesheetInfo.closureDate).format("DD/MM/YY")}
				/>
			)}
			{timesheet.timesheetInfo.lastExportDate && (
				<NoCSSInfoBox
					label={t("last_exported_date")}
					value={moment(timesheet.timesheetInfo.lastExportDate).format("DD/MM/YY")}
				/>
			)}
			{hasTscs && (
				<div className="tscs">
					<div className="title">{t("tsc_list_title")}</div>
					{timesheet.timesheetInfo.tscs.map(e => (
						<div key={e.id} className="tsc">
							{moment(e.created_at).format("DD/MM/YY")} - {t(e.action)}
						</div>
					))}
				</div>
			)}
		</div>
	)
}

TimesheetInfos.contextTypes = { t: PropTypes.func }
export default TimesheetInfos
