import React, { Component } from "react"
import PropTypes from "prop-types"
import { Route } from "react-router-dom"
import { connect } from "react-redux"
import { showWorkshop } from "../config"

import * as actions from "../actions"

// import CustomerPreview from './CustomerPreview'
import CustomerBookings from "./CustomerBookings"
import CustomerProfile from "./CustomerProfile"
import CustomerChat from "./CustomerChat"
import CustomerLinkedAccounts from "./CustomerLinkedAccounts"
import CustomerPayments from "./CustomerPayments"
import CustomerIncompatibilities from "./CustomerIncompatibilities"
import CustomerAbsences from "./CustomerAbsences"
import CustomerWorkshopOrders from "./CustomerWorkshopOrders"

import { CustomerAbsenceModal } from "./CustomerAbsenceModal"
import ModalLayout from "./ModalLayout"
import { PageLayout, PootsyButton } from "./FunctionalDesign"
import { NavLink } from "./NavLink"

import arrowBack from "../static/images/arrowback-icon.png"
import moment from "moment"

export class CustomerDetails extends Component {
	componentDidMount = () => {
		let {
			match: { params },
			currentCustomer,
		} = this.props
		if (params.id !== currentCustomer.id) {
			this.fetchCustomer(params.id)
		}
	}
	UNSAFE_componentWillReceiveProps = newProps => {
		let {
			match: { params },
		} = this.props
		if (params.id !== newProps.match.params.id) {
			this.fetchCustomer(newProps.match.params.id)
		}
	}
	handleStateChanges = changes => {
		this.props.dispatch(actions.customerDetailsStateChange(changes))
	}
	fetchCustomer = id => {
		this.props.dispatch(actions.fetchCustomer(id))
	}
	backInHistory = () => {
		this.props.history.goBack()
	}
	prefillNewBooking = () => {
		let {
			dispatch,
			history,
			match: { params },
			currentCustomer: {
				allResidences,
				customerTitle,
				displayName,
				sodexoNumber,
				defaultLanguage,
			},
		} = this.props
		let newBookingPrefill = {
			chosenCustomer: {
				id: params.id,
				name: displayName,
				title: customerTitle,
				residences: allResidences,
				sodexo: sodexoNumber,
			},
			language: defaultLanguage,
		}
		if (allResidences.length === 1) {
			newBookingPrefill.zip = allResidences[0].attributes.zip_code
		}
		history.push({ pathname: "/bookings/new" })
		dispatch(actions.prefillNewBookingPage(newBookingPrefill))
	}
	inviteCustomer = () => {
		let {
			dispatch,
			currentCustomer: { id },
		} = this.props
		let init = { method: "POST" }
		let url = `/front/affiliates/customer_contracts/${id}/invitation`
		dispatch(actions.simpleFetch(url, init, "INVITECUSTOMER"))
	}
	getCustomerDataExport = () => {
		let {
			dispatch,
			match: { params },
		} = this.props
		let url = "/front/affiliates/customer_contracts/" + params.id + "/data_exports"
		let init = { method: "POST" }
		dispatch(actions.simpleFetch(url, init, "GET_CUSTOMER_DATA_EXPORT"))
		this.toggleDataExportExplanationModal()
	}
	toggleDataExportExplanationModal = () => {
		let {
			component: { showDataExportExplanationModal },
		} = this.props
		this.handleStateChanges({
			showDataExportExplanationModal: !showDataExportExplanationModal,
		})
	}
	toggleCreateAbsenceModal = () => {
		let {
			component: { showCreateAbsenceModal },
		} = this.props
		this.handleStateChanges({
			showCreateAbsenceModal: !showCreateAbsenceModal,
		})
	}
	render() {
		let { t } = this.context
		let {
			currentCustomer,
			match,
			currAffiliate,
			component: { showDataExportExplanationModal, showCreateAbsenceModal },
		} = this.props
		let { displayName } = currentCustomer
		let pageHeaderLeft = (
			<div className="page-header-left customer-details">
				<img
					alt="arrow back"
					className="arrow-back"
					src={arrowBack}
					onClick={this.backInHistory}
				/>
				<h1 className="page-title customer-details">{displayName}</h1>
			</div>
		)
		let pageHeaderRight = [
			<PootsyButton
				key="a"
				text={t("export_customer_data_button")}
				onClick={this.getCustomerDataExport}
			/>,
			<PootsyButton key="b" text={t("add_new_booking")} onClick={this.prefillNewBooking} />,
			<PootsyButton
				key="c"
				text={t("add_new_absence")}
				onClick={this.toggleCreateAbsenceModal}
			/>,
		]
		if (currentCustomer.invited === false) {
			pageHeaderRight.push(
				<PootsyButton
					key="d"
					text={t("invite_customer")}
					customClass="invite-customer-button"
					onClick={this.inviteCustomer}
				/>
			)
		}
		let links = [
			/*
			{ label: t("preview"),  link: "preview" },
		*/
			{ label: t("bookings"), link: "bookings" },
			{ label: t("profile"), link: "profile" },
			{ label: t("linked_accounts"), link: "linked_accounts" },
			{ label: t("customer_payments"), link: "payments" },
			{ label: t("incompatibilities"), link: "incompatibilities" },
			{ label: t("absences"), link: "absences" },
			{ label: t("chat"), link: "chat" },
		]
		if (currAffiliate.workshopAddOn && showWorkshop) {
			links.push({ label: t("workshop_orders"), link: "workshop-orders" })
		}
		let subNavLinks = links.map(entry => (
			<NavLink key={entry.label} to={match.url + "/" + entry.link}>
				{entry.label}
			</NavLink>
		))
		return (
			<PageLayout
				headerLeft={pageHeaderLeft}
				headerRight={
					<div className="page-header-right customer-details">{pageHeaderRight}</div>
				}
				subNavLinks={subNavLinks}
			>
				{showCreateAbsenceModal && (
					<CustomerAbsenceModal
						closeModal={this.toggleCreateAbsenceModal}
						linkedCustomers={currentCustomer.linkedCustomers}
						customerId={this.props.match.params.id}
						t={t}
						dispatch={this.props.dispatch}
					/>
				)}
				{currentCustomer.scheduledJobs && currentCustomer.scheduledJobs.length > 0 && (
					<div className="customer-scheduled-jobs">
						{currentCustomer.scheduledJobs.map(entry => (
							<div className="scheduled-job">
								{entry.scheduledDate.isAfter(moment()) &&
									t("customer_scheduled_job." + entry.jobType.toLowerCase(), {
										scheduledDate: entry.scheduledDate.format("DD/MM/YYYY"),
									})}
							</div>
						))}
					</div>
				)}
				{/*
				<Route path={match.path + "/preview"} component={CustomerPreview}/>
			*/}
				{showDataExportExplanationModal && (
					<ModalLayout
						closeModal={this.toggleDataExportExplanationModal}
						formDivClass="data-export-explanation-modal"
						buttons={[
							<PootsyButton
								key="a"
								text={t("dismiss")}
								onClick={this.toggleDataExportExplanationModal}
							/>,
						]}
					>
						{t("data_export_explanation_modal")}
					</ModalLayout>
				)}
				<Route path={match.path + "/bookings"} component={CustomerBookings} />
				<Route path={match.path + "/profile"} component={CustomerProfile} />
				<Route path={match.path + "/chat"} component={CustomerChat} />
				<Route path={match.path + "/linked_accounts"} component={CustomerLinkedAccounts} />
				<Route path={match.path + "/payments"} component={CustomerPayments} />
				<Route
					path={match.path + "/incompatibilities"}
					component={CustomerIncompatibilities}
				/>
				<Route path={match.path + "/absences"} component={CustomerAbsences} />
				{showWorkshop && (
					<Route
						path={match.path + "/workshop-orders"}
						component={CustomerWorkshopOrders}
					/>
				)}
			</PageLayout>
		)
	}
}

CustomerDetails.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currentCustomer: state.redData.currentCustomer,
	currAffiliate: state.redData.currentAffiliate,
	component: state.redComponents.customerDetailsComponent,
})
export default connect(mapStateToProps)(CustomerDetails)
