import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { showWorkshop } from "../config"

import * as actions from "../actions"
import { Link } from "react-router-dom"

import moment from "moment"

import InputsChangeHandler from "./InputsChangeHandler"
import UpdateResidenceModal from "./UpdateResidenceModal"
import NewResidenceModal from "./NewResidenceModal"
import CustomerTemplates from "./CustomerTemplates"
import {
	ContentLayout,
	OptionBox,
	PootsyButton,
	NotificationCard,
	PootsySubHeading,
	LabeledDateTime,
	CustomerBalanceDisplayer,
	ConfirmationModal,
} from "./FunctionalDesign"
import ModalLayout from "./ModalLayout"
import {
	PootsyTextInput,
	PootsySelectInput,
	PootsyRadioInputs,
	PootsyTextArea,
} from "./FunctionalInputs"

class CustomerProfile extends Component {
	constructor() {
		super()
		this.state = {
			showNewResidenceModal: false,
			showUpdateResidenceModal: false,
			showEndCustomerContractModal: false,
			showSetCustomerInactiveModal: false,
			showForgetCustomerModal: false,
			residenceToUpdateIndex: -1,
			selectedContractEndDate: moment().add(1, "day"),
			selectedContractForgetDate: moment().add(1, "day"),
			selectedInactiveDate: moment().add(1, "day"),
			showReactivateCustomerContractModal: false,
			showRequired: {
				sodexoNumber: false,
				customerTitle: false,
				customerPets: false,
				region: false,
				language: false,
				residenceType: false,
				box: false,
				city: false,
				email: false,
				firstName: false,
				lastName: false,
				mobilePhone: false,
				streetNumber: false,
				surface: false,
				zip: false,
				street: false,
				howToGetIn: false,
				otherInfo: false,
				origin: false,
				niss: false,
				externalPartnerId: false,
			},
		}
		// ( to be able to pass it down to <InputsChangeHandler/> )
		this.setState = this.setState.bind(this)
	}
	handleSodexoChanges = e => {
		let { value } = e.target
		if (value.length > 11) {
			this.checkSodexoNr(value)
			this.setState({ sodexoNumber: value })
		}
	}
	checkSodexoNr = sodexoToCheck => {
		let init = {
			method: "POST",
			body: JSON.stringify({ sodexo_reference: sodexoToCheck }),
		}
		this.props.dispatch(
			actions.fetchCheckSodexo(
				"/front/affiliates/customers/check_sodexo",
				init,
				sodexoToCheck
			)
		)
	}
	toggleNewResidenceModal = () => {
		this.setState(state => ({
			showNewResidenceModal: !state.showNewResidenceModal,
		}))
	}
	openUpdateResidenceModal = e => {
		let { residenceindex } = e.target.dataset
		this.setState({
			showUpdateResidenceModal: true,
			residenceToUpdateIndex: parseInt(residenceindex, 10),
		})
	}
	closeUpdateResidenceModal = () => {
		this.setState({
			showUpdateResidenceModal: false,
			residenceToUpdateIndex: -1,
		})
	}
	toggleEndCustomerContractModal = () => {
		this.setState(state => ({
			showEndCustomerContractModal: !state.showEndCustomerContractModal,
		}))
	}
	toggleForgetCustomerModal = () => {
		this.setState(state => ({ showForgetCustomerModal: !state.showForgetCustomerModal }))
	}
	toggleInactiveModal = () => {
		this.setState(state => ({
			showSetCustomerInactiveModal: !state.showSetCustomerInactiveModal,
		}))
	}
	toggleReactivateCustomerContractModal = () => {
		let { showReactivateCustomerContractModal } = this.state
		this.setState({ showReactivateCustomerContractModal: !showReactivateCustomerContractModal })
	}
	onChange = changes => {
		this.props.dispatch(actions.editCustomerProfile(changes))
	}
	handleInputChange = changes => {
		this.setState(changes)
	}
	resetCustomerData = () => {
		this.props.dispatch(actions.resetCustomerData())
	}
	submitCustomerUpdate = () => {
		let {
			match: { params },
			currentCustomer: {
				sodexoNumber,
				region,
				voucherTypePreference,
				contractStartDate,
				customerTitle,
				firstName,
				lastName,
				defaultLanguage,
				mainAddress,
				email,
				mobilePhone,
				secondaryPhone,
				landlinePhone,
				affiliateCustomerNote,
				origin,
				workshopBalanceInitialValue,
				niss,
				externalPartnerId,
			},
		} = this.props

		let init = {
			method: "PUT",
			body: JSON.stringify({
				sodexo_reference: sodexoNumber,
				voucher_type_preference: voucherTypePreference || null,
				sodexo_region: region,
				title: customerTitle,
				first_name: firstName,
				last_name: lastName,
				email: email,
				default_language: defaultLanguage,
				telephone: mobilePhone,
				secondary_phone: secondaryPhone,
				landline_phone: landlinePhone,
				start_date: contractStartDate,
				// The back will send back an "address" field attached to the customer_contract data
				// SEPARATE FROM the "residences" field. It will complain if the update does not
				// include the address, hence the need to put it back in the update data.
				// the mainAddress is NOT displayed on the UI.
				street_name: mainAddress.street_name,
				town: mainAddress.town,
				zip_code: mainAddress.zip_code,
				country: mainAddress.country,
				affiliate_note: affiliateCustomerNote,
				origin: origin,
				niss: niss,
				external_partner_id: externalPartnerId,
				workshop_balance_initial_order_minutes: workshopBalanceInitialValue,
			}),
		}
		this.props.dispatch(
			actions.simpleFetch(
				"/front/affiliates/customer_contracts/" + params.id,
				init,
				"UPDATECUSTOMER"
			)
		)
	}
	endCustomerContract = () => {
		let { dispatch, currentCustomer } = this.props
		let { selectedContractEndDate } = this.state
		let init = {
			method: "PUT",
			body: JSON.stringify({
				end_date: selectedContractEndDate.format("YYYY-MM-DD"),
			}),
		}
		dispatch(
			actions.simpleFetch(
				`/front/affiliates/customer_contracts/${currentCustomer.id}/end_contract`,
				init,
				"ENDCUSTOMERCONTRACT"
			)
		)
	}
	forgetCustomerContract = () => {
		let { dispatch, currentCustomer } = this.props
		let { selectedContractForgetDate } = this.state
		let init = {
			method: "PUT",
			body: JSON.stringify({
				scheduled_date: selectedContractForgetDate.format("YYYY-MM-DD"),
			}),
		}
		dispatch(
			actions.simpleFetch(
				`/front/affiliates/customer_contracts/${currentCustomer.id}/forget`,
				init,
				"FORGET_CUSTOMER_CONTRACT"
			)
		)
		this.setState({ showForgetCustomerModal: false })
	}
	setCustomerInactive = () => {
		let { dispatch, currentCustomer } = this.props
		let { selectedInactiveDate } = this.state
		let init = {
			method: "PUT",
			body: JSON.stringify({
				scheduled_date: selectedInactiveDate.format("YYYY-MM-DD"),
			}),
		}
		dispatch(
			actions.simpleFetch(
				`/front/affiliates/customer_contracts/${currentCustomer.id}/disable`,
				init,
				"SET_CUSTOMER_CONTRACT_INACTIVE"
			)
		)
		this.setState({ showSetCustomerInactiveModal: false })
	}
	submitReactivateCustomerContract = () => {
		let { dispatch, currentCustomer } = this.props
		dispatch(
			actions.simpleFetch(
				`/front/affiliates/customer_contracts/${currentCustomer.id}/reactivate`,
				{ method: "PUT" },
				"REACTIVATE_CUSTOMER_CONTRACT"
			)
		)
		this.setState({ showReactivateCustomerContractModal: false })
	}
	render() {
		let { t } = this.context
		let {
			currentAffiliate,
			currentCustomer: {
				editedKeys,
				sodexoNumber,
				region,
				contractStartDate,
				contractEndDate,
				voucherTypePreference,
				customerTitle,
				firstName,
				lastName,
				defaultLanguage,
				mobilePhone,
				secondaryPhone,
				landlinePhone,
				email,
				allResidences,
				id,
				origin,
				affiliateCustomerNote,
				knownWorkers,
				notificationPreferences,
				scheduledJobs,
				workshopBalance,
				workshopBalanceInitialValue,
				niss,
				externalPartnerId,
			},
		} = this.props
		let {
			showRequired,
			showNewResidenceModal,
			showUpdateResidenceModal,
			showForgetCustomerModal,
			showEndCustomerContractModal,
			showReactivateCustomerContractModal,
			residenceToUpdateIndex,
			showSetCustomerInactiveModal,
			selectedInactiveDate,
			selectedContractForgetDate,
			selectedContractEndDate,
		} = this.state
		if (!id) {
			return null
		}

		let optionsBoxes = (
			<OptionBox boxTitle={t("customer_bio")}>
				<div className="known-workers">
					{knownWorkers && knownWorkers.all && (
						<Fragment>
							{knownWorkers.all.length !== 0 && (
								<div className="known-workers-title">
									{t("known_workers_title")}
								</div>
							)}
							{knownWorkers.all.map(kw => (
								<div key={kw.id} className="known-worker">
									{knownWorkers.active.map(kw => kw.id).includes(kw.id) ? (
										<Link to={`/cleaners/${kw.id}/profile`}>
											{kw.display_name}
											{t("active_known_worker")}
										</Link>
									) : (
										<Fragment>{kw.display_name}</Fragment>
									)}
								</div>
							))}
						</Fragment>
					)}
				</div>
				{notificationPreferences && notificationPreferences.length > 0 && (
					<div className="notification-preferences">
						<div className="notification-preferences-title">
							{t("notification_preferences")}
						</div>
						<div className="notification-category-title">{t("email")}</div>
						{notificationPreferences
							.filter(entry => entry.notification_type === "email")
							.map(entry => (
								<div key={entry.label} className="notification-preference">
									<div className="label">- {t(entry.label)}</div>
									{entry.enabled ? (
										<div className="value opted-in">{t("yes")}</div>
									) : (
										<div className="value opted-out">{t("no")}</div>
									)}
								</div>
							))}
						<div className="notification-category-title">{t("app")}</div>
						{notificationPreferences
							.filter(entry => entry.notification_type === "push_notification")
							.map(entry => (
								<div key={entry.label} className="notification-preference">
									<div className="label">- {t(entry.label)}</div>
									{entry.enabled ? (
										<div className="value opted-in">{t("yes")}</div>
									) : (
										<div className="value opted-out">{t("no")}</div>
									)}
								</div>
							))}
					</div>
				)}
				{showWorkshop && currentAffiliate.workshopAddOn && (
					<Fragment>
						<div className="workshop-infos-title">
							{t("workshop_customer_profile_infos_title")}
						</div>
						<PootsyTextInput
							name="workshopBalanceInitialValue"
							label={t("workshop_balance_initial_value_minutes")}
							showRequired={showRequired.workshopBalanceInitialValue}
							value={workshopBalanceInitialValue}
							type="number"
						/>
						<CustomerBalanceDisplayer balance={workshopBalance} />
					</Fragment>
				)}
				<PootsyTextArea
					label={t("affiliate_customer_note")}
					name="affiliateCustomerNote"
					value={affiliateCustomerNote}
				/>
				<CustomerTemplates />
			</OptionBox>
		)
		let notifButtons = [
			<PootsyButton key="a" text={t("save_changes")} onClick={this.submitCustomerUpdate} />,
			<PootsyButton
				key="b"
				text={t("reset_changes")}
				theme="cancel"
				onClick={this.resetCustomerData}
			/>,
		]
		let endCustomerContractModalButtons = [
			<PootsyButton key="a" text={t("yes")} onClick={this.endCustomerContract} />,
			<PootsyButton
				key="b"
				text={t("no")}
				theme="cancel"
				onClick={this.toggleEndCustomerContractModal}
			/>,
		]
		let forgetCustomerContractModalButtons = [
			<PootsyButton key="a" text={t("yes")} onClick={this.forgetCustomerContract} />,
			<PootsyButton
				key="b"
				text={t("no")}
				theme="cancel"
				onClick={this.toggleForgetCustomerModal}
			/>,
		]
		let setInactiveCustomerModalButtons = [
			<PootsyButton key="a" text={t("yes")} onClick={this.setCustomerInactive} />,
			<PootsyButton
				key="b"
				text={t("no")}
				theme="cancel"
				onClick={this.toggleInactiveModal}
			/>,
		]
		let willBeDisabled = false
		let willBeForgotten = false
		if (scheduledJobs) {
			willBeForgotten = scheduledJobs.some(
				entry =>
					entry.scheduledDate.isAfter(moment()) && entry.jobType === "FORGET_CUSTOMER"
			)
			willBeDisabled = scheduledJobs.some(
				entry =>
					entry.scheduledDate.isAfter(moment()) && entry.job_type === "DISABLE_CUSTOMER"
			)
		}
		return (
			<InputsChangeHandler customClass="CustomerProfile" onChange={this.onChange}>
				<ContentLayout optionsBoxes={optionsBoxes} customClass="customer-profile">
					<NotificationCard
						text={t("notif_unsaved_changes")}
						active={editedKeys.length > 0}
						buttons={notifButtons}
					/>
					{showNewResidenceModal && (
						<NewResidenceModal
							customerId={id}
							context="customerProfile"
							onCancel={this.toggleNewResidenceModal}
						/>
					)}
					{showUpdateResidenceModal && residenceToUpdateIndex > -1 && (
						<UpdateResidenceModal
							customerId={id}
							onCancel={this.closeUpdateResidenceModal}
							residence={allResidences[residenceToUpdateIndex]}
						/>
					)}
					{showEndCustomerContractModal && (
						<ModalLayout
							title={t("end_customer_contract")}
							formDivClass="end-customer-contract-modal"
							buttons={endCustomerContractModalButtons}
							closeModal={this.toggleEndCustomerContractModal}
						>
							<LabeledDateTime
								label={t("end_date")}
								showTimePicker={false}
								datePickerProps={{
									name: "selectedContractEndDate",
									value: selectedContractEndDate,
									onChange: this.handleInputChange,
									disableDay: current => current.isSameOrBefore(moment(), "day"),
								}}
							/>
							<div className="description">
								{t("end_customer_contract_confirmation")}
							</div>
						</ModalLayout>
					)}
					{showForgetCustomerModal && (
						<ModalLayout
							title={t("forget_customer_contract")}
							formDivClass="forget-customer-contract-modal"
							buttons={forgetCustomerContractModalButtons}
							closeModal={this.toggleForgetCustomerModal}
						>
							<LabeledDateTime
								label={t("forget_date")}
								showTimePicker={false}
								datePickerProps={{
									name: "selectedContractForgetDate",
									value: selectedContractForgetDate,
									onChange: this.handleInputChange,
									disableDay: current => current.isSameOrBefore(moment(), "day"),
								}}
							/>
							<div className="description">
								{t("forget_customer_contract_confirmation")}
							</div>
						</ModalLayout>
					)}
					{showSetCustomerInactiveModal && (
						<ModalLayout
							title={t("set_customer_contract_inactive")}
							formDivClass="set-customer-contract-inactive-modal"
							buttons={setInactiveCustomerModalButtons}
							closeModal={this.toggleInactiveModal}
						>
							<LabeledDateTime
								label={t("set_inactive_date")}
								showTimePicker={false}
								datePickerProps={{
									name: "selectedInactiveDate",
									value: selectedInactiveDate,
									onChange: this.handleInputChange,
									disableDay: current => current.isBefore(moment()),
								}}
							/>
							<div className="description">
								{t("set_customer_contract_inactive_confirmation")}
							</div>
						</ModalLayout>
					)}
					{showReactivateCustomerContractModal && (
						<ConfirmationModal
							title={t("reactivate_customer_contract")}
							closeModal={this.toggleReactivateCustomerContractModal}
							yesButton={
								<PootsyButton
									text={t("yes")}
									onClick={this.submitReactivateCustomerContract}
								/>
							}
							noButton={
								<PootsyButton
									text={t("no")}
									theme="cancel"
									onClick={this.toggleReactivateCustomerContractModal}
								/>
							}
						/>
					)}
					<div className="customer-profile-details">
						<PootsySubHeading text={t("customer.convention.details.title")} />
						<div className="customer-profile-contract-start">
							{t("customer.convention.details.start")}:{" "}
							{moment(contractStartDate).format("DD/MM/YYYY")}
						</div>
						{contractEndDate && (
							<div className="customer-profile-contract-end">
								{t("contract_end")}: {moment(contractEndDate).format("DD/MM/YYYY")}
							</div>
						)}
						<PootsySubHeading text={t("sodexo_details")} />
						<PootsyTextInput
							name="sodexoNumber"
							type="number"
							label={t("sodexo_number")}
							onChange={this.handleSodexoChanges}
							showRequired={showRequired.sodexoNumber}
							value={sodexoNumber}
						/>
						<PootsySelectInput
							name="region"
							defaultText={t("region")}
							selected={region}
							showRequired={showRequired.region}
							options={[
								{ label: t("wallonie"), value: "Wallonie" },
								{ label: t("brussels"), value: "Bruxelles" },
								{ label: t("flanders"), value: "Vlaanderen" },
							]}
						/>
						<PootsySelectInput
							name="voucherTypePreference"
							defaultText={t("voucher_type_preference")}
							selected={voucherTypePreference}
							allowEmpty={true}
							showRequired={showRequired.voucherTypePreference}
							options={[
								{ label: t("electronic"), value: "electronic" },
								{ label: t("paper"), value: "paper" },
							]}
						/>
						<PootsySubHeading text={t("personal_details")} />
						<PootsyRadioInputs
							groupName="customerTitle"
							groupLabel={t("customer_title")}
							showRequired={showRequired.customerTitle}
							selected={customerTitle}
							options={[
								{ id: "radio-mr", value: "mr", label: t("mr") },
								{ id: "radio-mrs", value: "mrs", label: t("mrs") },
								{ id: "radio-mrs", value: "other", label: t("other") },
							]}
						/>
						<PootsyTextInput
							name="firstName"
							label={t("first_name")}
							showRequired={showRequired.firstName}
							value={firstName}
						/>
						<PootsyTextInput
							name="lastName"
							label={t("last_name")}
							showRequired={showRequired.lastName}
							value={lastName}
						/>
						<PootsyTextInput
							name="email"
							label={t("email")}
							showRequired={showRequired.email}
							value={email}
						/>
						<PootsySelectInput
							name="defaultLanguage"
							defaultText={t("language")}
							selected={defaultLanguage}
							showRequired={showRequired.defaultLanguage}
							options={[
								{ label: t("english"), value: "en" },
								{ label: t("nederlands"), value: "nl" },
								{ label: t("français"), value: "fr" },
							]}
						/>
						<PootsyTextInput
							name="mobilePhone"
							label={t("phone")}
							showRequired={showRequired.mobilePhone}
							value={mobilePhone}
						/>
						<PootsyTextInput
							name="secondaryPhone"
							label={t("secondary_phone")}
							showRequired={showRequired.secondaryPhone}
							value={secondaryPhone}
						/>
						<PootsyTextInput
							name="landlinePhone"
							label={t("landline_phone")}
							showRequired={showRequired.landlinePhone}
							value={landlinePhone}
						/>
						<PootsyTextInput
							name="origin"
							label={t("customer_origin")}
							showRequired={showRequired.origin}
							value={origin}
						/>
						<PootsyTextInput
							name="niss"
							label={t("niss")}
							showRequired={showRequired.niss}
							value={niss}
						/>
						<PootsyTextInput
							name="externalPartnerId"
							label={t("external_partner_id")}
							showRequired={showRequired.externalPartnerId}
							value={externalPartnerId}
						/>
					</div>
					<div className="customer-profile-addresses">
						<PootsySubHeading text={t("customer_addresses")} />
						{allResidences &&
							allResidences.map((e, index) => (
								<div
									className={`customer-profile-address ${
										e.attributes.deleted ? "disabled" : ""
									}`}
									key={e.id}
								>
									{e.attributes.residence_type && (
										<div>{t(e.attributes.residence_type)}</div>
									)}
									<div>{e.attributes.address}</div>
									<div>{e.attributes.surface}m2</div>
									<div>
										{e.attributes.pets
											? t("pets_in_residence")
											: t("no_pets_in_residence")}
									</div>
									<div>{e.attributes.howToGetIn}</div>
									<div>{e.attributes.other}</div>
									<PootsyButton
										text={t("update_residence")}
										onClick={this.openUpdateResidenceModal}
										theme="cancel"
										dataset={{ "data-residenceindex": index }}
									/>
								</div>
							))}
					</div>
					<PootsyButton
						text={t("add_residence")}
						onClick={this.toggleNewResidenceModal}
					/>
					<div className="customer-profile-danger-zone">
						<div className="button-box">
							<PootsySubHeading text={t("danger_zone")} />
							{contractEndDate ? (
								<PootsyButton
									text={t("reactivate_customer_contract")}
									theme="red"
									onClick={this.toggleReactivateCustomerContractModal}
								/>
							) : (
								<PootsyButton
									text={t("end_customer_contract")}
									theme="red"
									onClick={this.toggleEndCustomerContractModal}
								/>
							)}
							{!willBeForgotten && (
								<Fragment>
									{!willBeDisabled && (
										<PootsyButton
											text={t("set_customer_inactive_button")}
											theme="red"
											onClick={this.toggleInactiveModal}
										/>
									)}
									<PootsyButton
										text={t("forget_customer_button")}
										theme="red"
										onClick={this.toggleForgetCustomerModal}
									/>
								</Fragment>
							)}
						</div>
					</div>
				</ContentLayout>
			</InputsChangeHandler>
		)
	}
}

CustomerProfile.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currentCustomer: state.redData.currentCustomer,
	currentAffiliate: state.redData.currentAffiliate,
})
export default connect(mapStateToProps)(CustomerProfile)
