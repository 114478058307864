import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import * as actions from "../actions"

import { PootsySelectInput } from "./FunctionalInputs"
import { PootsyButton } from "./FunctionalDesign"
import InputsChangeHandler from "./InputsChangeHandler"
import DraftTemplateToPDF from "./DraftTemplateToPDF"

class CustomerTemplates extends Component {
	handleChanges = changes => {
		this.props.dispatch(actions.customerTemplatesStateChange(changes))
	}
	processChanges = changes => {
		if (changes.selectedTemplate) {
			changes.selectedTemplate = Number(changes.selectedTemplate)
		}
		this.props.dispatch(actions.processCustomerTemplatesStateChange(changes))
	}
	componentDidMount = () => {
		this.props.dispatch(actions.fetchAvailableDocumentTemplatesList())
		this.handleChanges({ selectedLang: this.props.currentCustomer.defaultLanguage })
	}
	componentWillUnmount = () => {
		this.props.dispatch(actions.resetCustomerTemplatesState())
	}
	generatePdfClicked = () => {
		setTimeout(() => {
			this.props.dispatch(
				actions.customerTemplatesStateChange({
					selectedTemplate: "",
					renderableTemplate: {},
				})
			)
		}, 1000)
	}
	render() {
		let { t } = this.context
		let {
			currentCustomer,
			component: { selectedTemplate, selectedLang, renderableTemplate, availableTemplates },
		} = this.props
		let templateName = ""
		if (selectedTemplate) {
			templateName = availableTemplates.find(entry => entry.id === selectedTemplate)
				.templateName
		}
		return (
			<InputsChangeHandler customClass="CustomerTemplates" onChange={this.processChanges}>
				<div className="template-selection">
					<PootsySelectInput
						name="selectedTemplate"
						defaultText={t("template")}
						selected={selectedTemplate}
						options={availableTemplates.map(entry => ({
							label: entry.templateName,
							value: entry.id,
						}))}
					/>
					<PootsySelectInput
						name="selectedLang"
						defaultText={t("language")}
						selected={selectedLang}
						options={[
							{ label: "Fr", value: "fr" },
							{ label: "Nl", value: "nl" },
							{ label: "En", value: "en" },
						]}
					/>
				</div>
				<DraftTemplateToPDF
					mode="download"
					fileName={
						currentCustomer.displayName.replace(/ /g, "-") +
						"-" +
						templateName.replace(/ /g, "-")
					}
					disable={renderableTemplate.id !== selectedTemplate}
					template={renderableTemplate}
				>
					<PootsyButton
						text={t("render_template")}
						fetching={selectedTemplate && renderableTemplate.id !== selectedTemplate}
						theme={renderableTemplate.id !== selectedTemplate ? "cancel" : "green"}
						onClick={this.generatePdfClicked}
					/>
				</DraftTemplateToPDF>
			</InputsChangeHandler>
		)
	}
}

CustomerTemplates.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.customerTemplatesComponent,
	currentCustomer: state.redData.currentCustomer,
})
export default connect(mapStateToProps)(CustomerTemplates)
