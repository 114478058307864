import PropTypes from "prop-types"
import React, { useState, useEffect, useCallback, useContext } from "react"
import ReactDOM from "react-dom"

import { PootsyButton } from "./FunctionalDesign"
import { PootsyCheckboxInput, PootsyTextArea } from "./FunctionalInputs"
import ModalLayout from "./ModalLayout"
import { LocaleContext } from "../index.js"

const LabelValuePair = ({ label, value }) => {
	return (
		<div style={{ display: "flex", justifyContent: "space-between" }}>
			<div>{label}</div>
			<div>{value}</div>
		</div>
	)
}

const TicketMain = ({ children }) => (
	<div
		className="order-payment-ticket"
		style={{
			fontSize: ".7em",
			fontFamily: "sans-serif",
			margin: 0,
			padding: 10,
		}}
	>
		{children}
	</div>
)

const Banner = ({ children }) => (
	<div
		className="banner"
		style={{
			background: "grey",
			textTransform: "uppercase",
			fontWeight: "bolder",
			marginBottom: ".5em",
			fontSize: "1.5em",
		}}
	>
		{children}
	</div>
)

const Block = ({ children }) => (
	<div
		className="customer-infos"
		style={{ border: "1px solid black", marginBottom: ".5em", padding: ".4em" }}
	>
		{children}
	</div>
)

const CashItems = ({ items, order, t }) =>
	items.length > 0 && (
		<Block>
			<div
				style={{
					fontWeight: "bolder",
					borderBottom: "1px solid black",
					fontSize: "1.5em",
				}}
			>
				{t("order_payment_ticket_cash_infos")}
			</div>
			<div className="cash-items-table">
				<div
					className="labels"
					style={{
						display: "flex",
						textAlign: "center",
						borderBottom: "1px solid black",
					}}
				>
					<div className="label" style={{ flex: "3 0 0" }}>
						{t("name")}
					</div>
					<div className="label" style={{ flex: "1 0 0" }}>
						{t("quantity_abbrev")}
					</div>
					<div className="label" style={{ flex: "1 0 0" }}>
						{t("price_per_unit_abbrev")}
					</div>
					<div className="label" style={{ flex: "1 0 0" }}>
						{t("total")}
					</div>
				</div>
				<div className="item-rows">
					{items.map(entry => (
						<div
							key={entry.id}
							className="row"
							style={{ display: "flex", textAlign: "center" }}
						>
							<div
								className="column"
								style={{
									flex: "3 0 0",
									borderRight: "1px solid black",
								}}
							>
								{entry.longName}
							</div>
							<div
								className="column"
								style={{
									flex: "1 0 0",
									borderRight: "1px solid black",
								}}
							>
								{entry.quantity}
							</div>
							<div
								className="column"
								style={{
									flex: "1 0 0",
									borderRight: "1px solid black",
								}}
							>
								{entry.price}
								{entry.unitSymbol}
							</div>
							<div className="column" style={{ flex: "1 0 0" }}>
								{entry.cost}
								{entry.unitSymbol}
							</div>
						</div>
					))}
				</div>
				<div className="total-row" style={{ borderTop: "1px solid black" }}>
					<LabelValuePair label={t("total")} value={order.estimatedPrice + "€"} />
				</div>
			</div>
		</Block>
	)

const AffiliateInfos = ({ affiliate }) => (
	<div className="affiliate-infos" style={{ display: "flex" }}>
		<div className="logo" style={{ flex: "1 0 0" }}>
			<img alt="profile-pic" className="profile-pic" src={affiliate.logoUrl} />
		</div>
		<div className="infos-block" style={{ flex: "1 0 0" }}>
			<div className="affiliate" style={{ fontWeight: "bolder" }}>
				{affiliate.commercialName}
			</div>
			<div className="address">{affiliate.commercialAddress.display}</div>
			<div className="phone">{affiliate.phone}</div>
			<div className="vat">{affiliate.VatNumber}</div>
		</div>
	</div>
)
AffiliateInfos.contextTypes = { t: PropTypes.func }

export const OrderTicketPrint = ({ order, affiliate, t, ticketType }) => {
	const [showCustomizationModal, setShowCustomizationModal] = useState(false)
	const translate = useContext(LocaleContext)
	const balanceInfo = useCallback(() => {
		return `${t("customer_workshop_total_minutes")}: ${
			order.customer.balance.order_minutes_total
		} (${order.customer.balance.total_due_service_vouchers} ${t("service_vouchers")})\n${t(
			"workshop_service_vouchers_total_paid"
		)}: ${order.customer.balance.service_vouchers_total}\n${t(
			"customer_workshop_voucher_credit"
		)}: ${order.customer.balance.current_minutes_balance}`
	}, [
		order.customer.balance.current_minutes_balance,
		order.customer.balance.order_minutes_total,
		order.customer.balance.service_vouchers_total,
		order.customer.balance.total_due_service_vouchers,
		t,
	])

	const [customerBalanceInfos, setCustomerBalanceInfos] = useState(balanceInfo)
	const [includeCustomerBalance, setIncludeCustomerBalance] = useState(true)
	const [includeAffiliateSignature, setIncludeAffiliateSignature] = useState(true)
	const [affiliateCustomTicketSignature, setAffiliateCustomTicketSignature] = useState(
		affiliate.workshopSettings.orderTicketSignature || ""
	)

	useEffect(() => {
		if (order.id === window.location.pathname.split("/").pop()) {
			setCustomerBalanceInfos(balanceInfo())
		}
	}, [order, balanceInfo])

	function toggleCustomizationModal() {
		setShowCustomizationModal(!showCustomizationModal)
	}

	function renderQuoteTicket() {
		let t = (key, params) => translate(key, params, order.customer.lang)
		let cashItems = order.items.filter(entry => entry.category === "CASH")
		let svItems = order.items.filter(entry => entry.category === "SERVICE_VOUCHER")
		let svCategoryQuote = order.quote.details.find(
			entry => entry.categoryName === "SERVICE_VOUCHER"
		)
		return (
			<TicketMain>
				<Banner>{t("quote_ticket_title", { orderId: order.id })}</Banner>
				<Block>
					<div style={{ fontWeight: "bolder", fontSize: "1.5em" }}>{t("customer")}</div>
					<LabelValuePair label={t("name")} value={order.customerName} />
					<LabelValuePair label={t("sodexo")} value={order.customer.sodexoRef} />
					<LabelValuePair label={t("telephone")} value={order.customer.telephone} />
				</Block>
				{includeCustomerBalance && (
					<Block>
						<div
							style={{
								fontWeight: "bolder",
								fontSize: "1.5em",
							}}
						>
							{t("customer_balance")}
						</div>
						{customerBalanceInfos.split("\n").map(s => (
							<div style={{ minHeight: 15 }}>{s}</div>
						))}
					</Block>
				)}
				<Block>
					<div
						style={{
							fontWeight: "bolder",
							borderBottom: "1px solid black",
							fontSize: "1.5em",
						}}
					>
						{t("order_payment_ticket_sv_infos")}
					</div>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<LabelValuePair
							label={t("drop_off_date")}
							value={order.dropOffDate.format("DD/MM/YYYY")}
						/>
						<LabelValuePair
							label={t("pick_up_date")}
							value={order.scheduledPickupDate.format("DD/MM/YYYY")}
						/>
					</div>
					<div className="sv-items-table">
						<div
							className="labels"
							style={{
								display: "flex",
								textAlign: "center",
								borderBottom: "1px solid black",
							}}
						>
							<div className="label" style={{ flex: "3 0 0" }}>
								{t("name")}
							</div>
							<div className="label" style={{ flex: "1 0 0" }}>
								{t("quantity_abbrev")}
							</div>
							<div className="label" style={{ flex: "1 0 0" }}>
								{t("price_per_unit_abbrev")}
							</div>
							<div className="label" style={{ flex: "1 0 0" }}>
								{t("total")}
							</div>
						</div>
						<div className="item-rows">
							{svItems.map(entry => (
								<div
									key={entry.id}
									className="row"
									style={{ display: "flex", textAlign: "center" }}
								>
									<div
										className="column"
										style={{
											flex: "3 0 0",
											borderRight: "1px solid black",
										}}
									>
										{entry.longName}
									</div>
									<div
										className="column"
										style={{
											flex: "1 0 0",
											borderRight: "1px solid black",
										}}
									>
										{entry.quantity}
									</div>
									<div
										className="column"
										style={{
											flex: "1 0 0",
											borderRight: "1px solid black",
										}}
									>
										{entry.price}
										{entry.unitSymbol}
									</div>
									<div className="column" style={{ flex: "1 0 0" }}>
										{entry.cost}
										{entry.unitSymbol}
									</div>
								</div>
							))}
						</div>
						<div className="total-row" style={{ borderTop: "1px solid black" }}>
							<LabelValuePair
								label={t("total")}
								value={order.quote.estimatedDuration + "m"}
							/>
							<LabelValuePair
								label={t("service_voucher_count")}
								value={
									svCategoryQuote.totalDetails.estimated_billable_service_voucher
								}
							/>
						</div>
					</div>
				</Block>
				{includeAffiliateSignature && (
					<Block>
						{affiliateCustomTicketSignature.split("\n").map(s => (
							<div style={{ minHeight: 15 }}>{s}</div>
						))}
					</Block>
				)}
				<CashItems items={cashItems} order={order} t={t} />
				<AffiliateInfos affiliate={affiliate} />
			</TicketMain>
		)
	}

	function renderPaymentTicket() {
		let t = (key, params) => translate(key, params, order.customer.lang)
		let cashItems = order.items.filter(entry => entry.category === "CASH")
		let svItems = order.items.filter(entry => entry.category === "SERVICE_VOUCHER")
		return (
			<TicketMain>
				<Banner>{t("payment_ticket_title", { orderId: order.displayId })}</Banner>
				<Block>
					<div style={{ fontWeight: "bolder", fontSize: "1.5em" }}>{t("customer")}</div>
					<LabelValuePair label={t("name")} value={order.customerName} />
					<LabelValuePair label={t("sodexo")} value={order.customer.sodexoRef} />
					<LabelValuePair label={t("telephone")} value={order.customer.telephone} />
				</Block>
				{includeCustomerBalance && (
					<Block>
						<div
							style={{
								fontWeight: "bolder",
								fontSize: "1.5em",
							}}
						>
							{t("customer_balance")}
						</div>
						{customerBalanceInfos.split("\n").map(s => (
							<div key={s} style={{ minHeight: 15 }}>
								{s}
							</div>
						))}
					</Block>
				)}
				<Block>
					<div style={{ fontWeight: "bolder", fontSize: "1.5em" }}>
						{t("order_payment_ticket_sv_infos")}
					</div>
					<LabelValuePair
						label={t("drop_off_date")}
						value={order.dropOffDate.format("DD/MM/YYYY")}
					/>
					<LabelValuePair
						label={t("pick_up_date")}
						value={order.scheduledPickupDate.format("DD/MM/YYYY")}
					/>
					<LabelValuePair
						label={t("minutes_total")}
						value={order.executedDurationMinutes + "m"}
					/>
					<div className="sv-items-table">
						<div
							className="labels"
							style={{
								display: "flex",
								textAlign: "center",
								borderBottom: "1px solid black",
							}}
						>
							<div className="label" style={{ flex: "3 0 0" }}>
								{t("name")}
							</div>
							<div className="label" style={{ flex: "1 0 0" }}>
								{t("quantity_abbrev")}
							</div>
							<div className="label" style={{ flex: "1 0 0" }}>
								{t("price_per_unit_abbrev")}
							</div>
							<div className="label" style={{ flex: "1 0 0" }}>
								{t("total")}
							</div>
						</div>
						<div className="item-rows">
							{svItems.map(entry => (
								<div
									key={entry.id}
									className="row"
									style={{ display: "flex", textAlign: "center" }}
								>
									<div
										className="column"
										style={{
											flex: "3 0 0",
											borderRight: "1px solid black",
										}}
									>
										{entry.longName}
									</div>
									<div
										className="column"
										style={{
											flex: "1 0 0",
											borderRight: "1px solid black",
										}}
									>
										{entry.quantity}
									</div>
									<div
										className="column"
										style={{
											flex: "1 0 0",
											borderRight: "1px solid black",
										}}
									>
										{entry.price}
										{entry.unitSymbol}
									</div>
									<div className="column" style={{ flex: "1 0 0" }}>
										{entry.cost}
										{entry.unitSymbol}
									</div>
								</div>
							))}
						</div>
						<LabelValuePair
							label={t("final_total")}
							value={order.billableMinutes + "m"}
						/>
						<LabelValuePair
							label={t("service_voucher_count")}
							value={order.billableSV}
						/>
					</div>
				</Block>
				{includeAffiliateSignature && (
					<Block>
						{affiliateCustomTicketSignature.split("\n").map(s => (
							<div key={s} style={{ minHeight: 15 }}>
								{s}
							</div>
						))}
					</Block>
				)}
				<CashItems items={cashItems} order={order} t={t} />
				<AffiliateInfos affiliate={affiliate} />
			</TicketMain>
		)
	}

	function setChanges(changes) {
		if ("includeCustomerBalance" in changes) {
			setIncludeCustomerBalance(!includeCustomerBalance)
		} else if (changes.customerBalanceInfos) {
			setCustomerBalanceInfos(changes.customerBalanceInfos)
		} else if ("includeAffiliateSignature" in changes) {
			setIncludeAffiliateSignature(!includeAffiliateSignature)
		} else if (changes.affiliateCustomTicketSignature) {
			setAffiliateCustomTicketSignature(changes.affiliateCustomTicketSignature)
		}
	}

	function printTicket() {
		let printDiv = document.createElement("div")
		ReactDOM.render(
			ticketType === "quote_ticket" ? renderQuoteTicket() : renderPaymentTicket(),
			printDiv
		)
		let winPrint = window.open("", "_blank", "")
		winPrint.document.write(printDiv.innerHTML)
		winPrint.document.close()
		winPrint.focus()
		winPrint.print()
		// winPrint.close()
	}

	return (
		<div className="OrderTickerPrint">
			<PootsyButton text={t("print_order_ticket")} onClick={toggleCustomizationModal} />
			{showCustomizationModal && (
				<ModalLayout
					title={t("workshop_order_ticket_customization")}
					customClass="order-ticket-customization-modal"
					closeModal={toggleCustomizationModal}
					withInputsChangeHandler={true}
					handleChanges={changes => {
						setChanges(changes)
					}}
				>
					<div className="customer-balance">
						<PootsyCheckboxInput
							name="includeCustomerBalance"
							label={t("include_customer_balance_in_workshop_order_ticket")}
							checked={includeCustomerBalance}
						/>
						<PootsyTextArea
							name="customerBalanceInfos"
							label={t("customer_balance")}
							value={customerBalanceInfos}
						/>
					</div>
					<div className="affiliate-signature">
						<PootsyCheckboxInput
							name="includeAffiliateSignature"
							label={t("include_affiliate_signature_in_workshop_order_ticket")}
							checked={includeAffiliateSignature}
						/>
						<PootsyTextArea
							name="affiliateCustomTicketSignature"
							label={t("affiliate_custom_workshop_ticket_signature")}
							value={affiliateCustomTicketSignature}
						/>
					</div>
					<PootsyButton text={t("print_order_ticket")} onClick={printTicket} />
				</ModalLayout>
			)}
		</div>
	)
}

// }

OrderTicketPrint.contextTypes = { t: PropTypes.func }
