import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"

import * as actions from "../actions"

import { ContentLayout, SimpleLine, OptionBox, NoCSSInfoBox } from "./FunctionalDesign"
import DateFilterer from "./DateFilterer"
import SearchCustomer from "./SearchCustomer"

class CustomersPaymentReminders extends Component {
	componentDidMount = async () => {
		let { dispatch } = this.props
		await dispatch(
			actions.addQueries({
				fromDateQuery: {
					urlName: "from",
					queryName: "from_date",
					mustBeSet: true,
					defaultValue: moment().format("YYYY-MM-DD"),
				},
				toDateQuery: {
					urlName: "to",
					queryName: "to_date",
					mustBeSet: true,
					defaultValue: moment()
						.add(2, "week")
						.format("YYYY-MM-DD"),
				},
				customerContractQuery: {
					urlName: "customerId",
					queryName: "customer_contract_id",
					mustBeSet: false,
				},
			})
		)
		dispatch(actions.fetchPaymentReminders())
	}
	handleChanges = changes => {
		this.props.dispatch(actions.customersPaymentRemindersStateChange(changes))
	}
	handleDateFiltersChange = dateFilters => {
		let query = [
			{ name: "fromDateQuery", value: dateFilters.from.format("YYYY-MM-DD") },
			{ name: "toDateQuery", value: dateFilters.to.format("YYYY-MM-DD") },
		]
		this.props.dispatch(actions.updateQuery(query))
		this.props.dispatch(actions.fetchPaymentReminders())
	}
	handleCustomerClick = customer => {
		let { dispatch } = this.props
		this.handleChanges({ currentCustomerFilter: customer.attributes.display_name })
		dispatch(actions.updateQuery({ name: "customerContractQuery", value: customer.id }))
		dispatch(actions.fetchPaymentReminders())
	}
	removeCustomerFilter = () => {
		let { dispatch } = this.props
		this.handleChanges({ currentCustomerFilter: "" })
		dispatch(actions.updateQuery({ name: "customerContractQuery", value: "" }))
		dispatch(actions.fetchPaymentReminders())
	}
	render() {
		let { t } = this.context
		let {
			paymentReminders,
			component: { currentCustomerFilter },
			currentQuery: {
				urlQuery: { from, to },
			},
		} = this.props
		let mFrom = moment(from)
		let mTo = moment(to)
		let optionsBoxes = (
			<OptionBox boxTitle={t("filters")} customClass="payment-reminders-filters">
				<SearchCustomer handleCustomerClick={this.handleCustomerClick} />
				{currentCustomerFilter && (
					<div className="current-customer-filter" onClick={this.removeCustomerFilter}>
						{currentCustomerFilter}
					</div>
				)}
				<DateFilterer onChange={this.handleDateFiltersChange} from={mFrom} to={mTo} />
			</OptionBox>
		)
		return (
			<ContentLayout
				customClass="CustomersPaymentReminders"
				optionsBoxes={optionsBoxes}
				// contentTop={contentTop}
			>
				{paymentReminders.map(entry => (
					<SimpleLine
						key={entry.id}
						customClass="payment-reminder"
						link={"/customers/payment_reminders/" + entry.id}
					>
						<NoCSSInfoBox
							label={t("creation_date")}
							value={moment(entry.attributes.created_at).format("DD/MM/YYYY")}
						/>
						<NoCSSInfoBox
							label={t("missing_sv_total")}
							value={entry.attributes.missing_sv_total}
						/>
						<NoCSSInfoBox
							label={t("total_booking_occurrences")}
							value={entry.attributes.total_booking_occurrences}
						/>
						<NoCSSInfoBox label={t("origin")} value={t(entry.attributes.origin)} />
						<NoCSSInfoBox label={t("author")} value={t(entry.attributes.author)} />
					</SimpleLine>
				))}
			</ContentLayout>
		)
	}
}

CustomersPaymentReminders.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.customersPaymentRemindersComponent,
	paymentReminders: state.redData.paymentReminders,
	currentQuery: state.currentURLQuery,
})
export default connect(mapStateToProps)(CustomersPaymentReminders)
