import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { sortArrayByName } from "../helpers/arraySortByName"
import * as actions from "../actions"

import { PootsyTextInput } from "./FunctionalInputs"

class SearchCustomer extends Component {
	state = {
		queuedCall: "",
	}
	componentWillUnmount = () => {
		this.props.dispatch(actions.resetCustomerSuggestions())
	}
	handleSearchCustomerChange = e => {
		e.stopPropagation()
		let { queuedCall } = this.state
		let { value } = e.target
		clearTimeout(queuedCall)
		let futureCall
		this.props.onChange(e.target.value)
		if (value === "") {
			this.props.dispatch(actions.resetCustomerSuggestions())
		} else if (value.length > 2) {
			futureCall = setTimeout(() => this.fetchCustomerSuggestions(value), 800)
		}
		this.setState({ queuedCall: futureCall })
	}
	fetchCustomerSuggestions = search => {
		let query = "?filter_active=active&filter_name=" + search
		let init = { method: "GET" }
		this.props.dispatch(
			actions.simpleFetch(
				"/front/affiliates/customer_contracts" + query,
				init,
				"CUSTOMERSUGGESTION"
			)
		)
	}
	handleCustomerClick = e => {
		let { customerid } = e.target.dataset
		let customer = this.props.customerSuggestions.find(e => e.id === customerid)
		this.props.handleCustomerClick(customer)
		this.props.dispatch(actions.resetCustomerSuggestions())
	}
	render() {
		let { t } = this.context
		let {
			customerSuggestions,
			suggestionCallback,
			value = null,
			showRequired = false,
			customLabel = null,
		} = this.props
		customerSuggestions = sortArrayByName(customerSuggestions, "attributes", "display_name")
		return (
			<div className="SearchCustomer">
				<PootsyTextInput
					label={customLabel ? customLabel : t("search_for_customer")}
					onChange={this.handleSearchCustomerChange}
					showRequired={showRequired}
					{...(value ? { value } : {})}
				/>
				{customerSuggestions.length > 0 && (
					<div className="customer-search-results">
						{customerSuggestions.map(entry => {
							let finalClass = "customer-search-result"
							let { divTitle = "", disable = false } = suggestionCallback
								? suggestionCallback(entry)
								: {}
							if (disable) finalClass += " disabled"
							return (
								<div key={entry.id} className={finalClass} title={divTitle}>
									<div className="customer-name">
										{entry.attributes.display_name}
									</div>
									<div className="customer-zips">
										{entry.attributes.residences.data.length > 0 &&
											entry.attributes.residences.data.map(e => (
												<span key={e.id} className="customer-zip">
													{e.attributes.zip_code}
												</span>
											))}
									</div>
									<div
										className="customer-search-result-clickable-area"
										data-customerid={entry.id}
										onClick={this.handleCustomerClick}
									/>
								</div>
							)
						})}
					</div>
				)}
			</div>
		)
	}
}

SearchCustomer.defaultProps = {
	onChange: () => {},
	suggestionCallback: () => ({}),
	customerSuggestions: [],
}
SearchCustomer.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currentAffiliate: state.redData.currentAffiliate,
	customerSuggestions: state.redData.customerSuggestions,
})
export default connect(mapStateToProps)(SearchCustomer)
