import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import _ from "lodash"
import PropTypes from "prop-types"

import * as actions from "../actions"

import Filter from "./Filter"
import { PootsyTextInput } from "./FunctionalInputs"
import {
	PootsyButton,
	SimpleLine,
	NoCSSInfoBox,
	PootsyHeading,
	PootsySubHeading,
	LabeledDateTime,
} from "./FunctionalDesign"
import ModalLayout from "./ModalLayout"

class WorkerMultiSelect extends Component {
	componentDidMount = () => {
		this.props.dispatch(
			actions.simpleFetch(
				"/front/affiliates/worker_filter",
				{ method: "GET" },
				"WORKERSFILTERS"
			)
		)
	}
	componentWillUnmount = () => {
		this.props.dispatch(actions.resetWorkerMultiSelectState())
	}
	fetchWorkers = (customQuery = {}) => {
		let {
			dispatch,
			component: { filters, squadsFilters, zipFilters, activeAtDateFilter },
		} = this.props
		let query = {
			paginate: false,
			[filters.zip.queryName]: zipFilters,
			[filters.squads.queryName]: squadsFilters,
			filter_active_contract_at_date: activeAtDateFilter.format("YYYY-MM-DD"),
		}
		let finalQuery = { ...query, ...customQuery }
		this.handleChanges({
			lastFilteringUsed: {
				[filters.zip.label]: filters.zip.list
					.filter(entry => zipFilters.includes(entry.value.toString()))
					.map(entry => entry.label),
				[filters.squads.label]: filters.squads.list
					.filter(entry => squadsFilters.includes(entry.value.toString()))
					.map(entry => entry.label),
				active_at_date_filter: [activeAtDateFilter.format("DD/MM/YYYY")],
			},
		})
		dispatch(actions.fetchWorkers(finalQuery))
	}
	debouncedFetchWorkers = _.debounce(customQuery => {
		this.fetchWorkers(customQuery)
	}, 1500)
	refreshWorkerList = () => {
		this.fetchWorkers({})
	}
	handleChanges = async changes => {
		await this.props.dispatch(actions.workerMultiSelectStateChange(changes))
	}
	handleNameSearchChange = e => {
		let { value } = e.target
		if (value.length === 0 || value.length > 2) {
			this.debouncedFetchWorkers({ filter_name: value })
		}
	}
	handleActiveAtDateFilterChange = changes => {
		this.handleChanges(changes)
	}
	handleFilterChange = change => {
		this.handleChanges({ [change.name]: change.value })
	}
	handleWorkerClick = worker => {
		let {
			component: { stagedWorkers },
		} = this.props
		this.handleChanges({ stagedWorkers: [...stagedWorkers, worker] })
	}
	handleRemoveStagedWorker = worker => {
		let {
			component: { stagedWorkers },
		} = this.props
		this.handleChanges({ stagedWorkers: stagedWorkers.filter(entry => entry.id !== worker.id) })
	}
	handleSelectAll = () => {
		let { workers } = this.props
		this.handleChanges({ stagedWorkers: [...workers] })
	}
	handleUnselectAll = () => {
		this.handleChanges({ stagedWorkers: [] })
	}
	submit = () => {
		let {
			submitAction,
			component: { stagedWorkers },
		} = this.props
		submitAction(stagedWorkers)
	}
	render() {
		let { t } = this.context
		let {
			closeModal,
			workers,
			submitButtonText,
			retrieveExtraWorkerInfo,
			layout,
			component: {
				stagedWorkers,
				filters,
				zipFilters,
				squadsFilters,
				lastFilteringUsed,
				activeAtDateFilter,
			},
		} = this.props
		if (filters.zip === undefined) {
			return null
		}
		let filterComponents = [
			{
				queryId: "zipFilters",
				currValues: zipFilters,
				label: t(filters.zip.label),
				filtersList: filters.zip.list,
			},
			{
				queryId: "squadsFilters",
				currValues: squadsFilters,
				label: t(filters.squads.label),
				filtersList: filters.squads.list,
			},
		].map(entry => {
			let possibleValues
			entry.filtersList === undefined
				? (possibleValues = [])
				: (possibleValues = entry.filtersList)
			return (
				<Filter
					key={entry.queryId}
					label={entry.label}
					name={entry.queryId}
					possibleValues={possibleValues}
					currentFilters={entry.currValues}
					onChange={this.handleFilterChange}
				/>
			)
		})
		let content = (
			<Fragment>
				<PootsyHeading text={t("worker_multiselect_title")} />
				<div className="filters">
					<PootsyTextInput
						label={t("search_cleaners")}
						name="search"
						onChange={this.handleNameSearchChange}
					/>
					{filterComponents}
					<LabeledDateTime
						label={t("active_at_date_filter")}
						showTimePicker={false}
						datePickerProps={{
							name: "activeAtDateFilter",
							value: activeAtDateFilter,
							onChange: this.handleActiveAtDateFilterChange,
						}}
					/>
					<PootsyButton
						text={t("refresh_list")}
						onClick={this.refreshWorkerList}
						size="small"
					/>
				</div>
				<div className="last-applied-filters">
					<PootsySubHeading text={t("last_applied_filters")} />
					<div className="filters">
						{Object.entries(lastFilteringUsed).map(([key, value]) => (
							<NoCSSInfoBox
								key={key}
								label={t(key)}
								value={value.map(entry => (
									<div key={entry} className="val">
										{entry}
									</div>
								))}
							/>
						))}
					</div>
				</div>
				<div className="workers-list">
					<div className="top">
						<PootsySubHeading text={t("workers_results")} />
						<PootsyButton
							text={t("select_all")}
							onClick={this.handleSelectAll}
							size="small"
						/>
					</div>
					<div className="list">
						{workers
							.filter(entry => !stagedWorkers.some(sw => sw.id === entry.id))
							.map(entry => (
								<SimpleLine
									key={entry.id}
									noLink={true}
									onClick={() => this.handleWorkerClick(entry)}
								>
									<div className="column">{entry.attributes.display_name}</div>
									{retrieveExtraWorkerInfo(entry)}
								</SimpleLine>
							))}
					</div>
				</div>
				<div className="staged-workers">
					<div className="top">
						<PootsySubHeading text={t("selected_workers")} />
						<PootsyButton
							text={t("unselect_all")}
							onClick={this.handleUnselectAll}
							size="small"
						/>
					</div>
					<div className="list">
						{stagedWorkers.map(entry => (
							<SimpleLine
								key={entry.id}
								noLink={true}
								onClick={() => this.handleRemoveStagedWorker(entry)}
							>
								<div className="column">{entry.attributes.display_name}</div>
								{retrieveExtraWorkerInfo(entry)}
							</SimpleLine>
						))}
					</div>
				</div>
				<div className="worker-selection-infos">
					<NoCSSInfoBox
						label={t("workers_selected_count")}
						value={stagedWorkers.length}
					/>
					<PootsyButton
						text={submitButtonText}
						onClick={this.submit}
						size="small"
						theme="green"
					/>
				</div>
			</Fragment>
		)
		return layout === "modal" ? (
			<ModalLayout customClass="WorkerMultiSelect" closeModal={closeModal}>
				{content}
			</ModalLayout>
		) : (
			<div className="WorkerMultiSelect">
				<div className="content-div">{content}</div>
			</div>
		)
	}
}

WorkerMultiSelect.contextTypes = { t: PropTypes.func }
WorkerMultiSelect.defaultProps = {
	retrieveExtraWorkerInfo: w => <div></div>,
	layout: "modal",
}
const mapStateToProps = state => ({
	component: state.redComponents.workerMultiSelectComponent,
	workers: state.redData.cleaners,
})
export default connect(mapStateToProps)(WorkerMultiSelect)
