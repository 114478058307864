import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"

import { PCW_ROOT } from "../config"
import * as actions from "../actions"

import InputsChangeHandler from "./InputsChangeHandler"
import {
	ContentLayout,
	OptionBox,
	PootsySubHeading,
	LabeledDateTime,
	PootsyButton,
} from "./FunctionalDesign"
import {
	PootsyTextInput,
	PootsyTextArea,
	PootsySelectInput,
	PootsyFileInput,
} from "./FunctionalInputs"

class SettingsAffiliatePage extends Component {
	state = {
		currentOpeningDay: "1",
		currentOpeningStart: moment({ year: 2000, month: 0, day: 1, hour: 8 }),
		currentOpeningEnd: moment({ year: 2000, month: 0, day: 1, hour: 12 }),
	}
	handleLocalisedFieldsChange = data => {
		this.props.dispatch(actions.affiliateLocalisedFieldDataChange(data))
	}
	handleOpeningHourDayChange = e => {
		e.stopPropagation()
		this.setState({ currentOpeningDay: e.target.value })
	}
	handleOpeningHourChange = changes => {
		this.setState(changes)
	}
	handleAddOpeningHour = () => {
		let { currentOpeningDay, currentOpeningStart, currentOpeningEnd } = this.state
		this.props.dispatch(
			actions.addOpeningHour({ currentOpeningDay, currentOpeningStart, currentOpeningEnd })
		)
	}
	handleRemoveOpeningHour = e => {
		let { id } = e.target.dataset
		let {
			dispatch,
			currAffiliate: { openingHours },
		} = this.props
		let newOpeningHours = openingHours.filter(oh => oh.id !== id)
		dispatch(actions.editSettings({ openingHours: newOpeningHours }))
	}
	handleCoverPicChange = e => {
		e.stopPropagation()
		let { dispatch } = this.props
		let file = e.target.files[0]
		if (!file) {
			return
		}
		let reader = new FileReader()
		let splitFileName = file.name.split(".")
		let extension = splitFileName[splitFileName.length - 1]
		reader.onload = upload => {
			dispatch(
				actions.editSettings({
					coverPictureFile: {
						filename: file.name,
						extension,
						file: upload.target.result,
					},
				})
			)
		}
		reader.readAsDataURL(file)
	}
	removePicture = () => {
		this.props.dispatch(actions.editSettings({ coverPictureFile: "", coverPictureUrl: "" }))
	}
	render() {
		let { t } = this.context
		let {
			currAffiliate,
			currAffiliate: { openingHours, coverPictureUrl, coverPictureFile },
			currLocalFields: { slogans, bios },
		} = this.props
		let { currentOpeningDay, currentOpeningStart, currentOpeningEnd } = this.state

		let optionBox = (
			<OptionBox boxTitle={t("affiliate_page")}>
				<a
					href={
						PCW_ROOT +
						"/" +
						currAffiliate.id +
						"-" +
						currAffiliate.commercialName.replace(" ", "-")
					}
					target="_blank"
					rel="noopener noreferrer"
				>
					<PootsyButton text={t("link_to_affiliate_page")} />
				</a>
			</OptionBox>
		)
		return (
			<ContentLayout optionsBoxes={optionBox}>
				<InputsChangeHandler
					onChange={this.handleLocalisedFieldsChange}
					customClass="settings-affiliate-page"
				>
					<div className="settings-affiliate-page-wrapper">
						<PootsySubHeading text={t("settings_cover_title")} />
						<div className="cover-picture-upload-block">
							<PootsyFileInput
								name="coverPictureFile"
								label={t("upload_cover_picture_file")}
								accept="image/*"
								onChange={this.handleCoverPicChange}
							/>
							<div className="expected-sizes">
								{t("ideal_dimensions") + " 1400x500"}
							</div>
							{(coverPictureUrl || coverPictureFile) && (
								<img
									alt="cover"
									className="to-be-uploaded-cover-picture"
									src={coverPictureFile ? coverPictureFile.file : coverPictureUrl}
								/>
							)}
							<div className="remove-cover-picture" onClick={this.removePicture}>
								x
							</div>
						</div>
						<PootsySubHeading text={t("settings_slogan_title")} />
						<div className="settings-affiliate-page-slogan">
							{slogans.map(slogan => (
								<PootsyTextInput
									key={slogan.lang}
									name={`slogans:${slogan.lang}`}
									label={t(`affiliate_slogan_${slogan.lang}`)}
									value={slogan.value}
								/>
							))}
						</div>
						<PootsySubHeading text={t("settings_bio_title")} />
						<div className="settings-affiliate-page-bio">
							{bios.map(bio => (
								<PootsyTextArea
									key={bio.lang}
									name={`bios:${bio.lang}`}
									label={t(`affiliate_slogan_${bio.lang}`)}
									value={bio.value}
								/>
							))}
						</div>
						<PootsySubHeading text={t("opening_hours")} />
						<div className="settings-affiliate-page-opening-hours">
							<div className="opening-hours-schedule">
								{["1", "2", "3", "4", "5", "6", "7"].map(weekDay => {
									let dayOpeningHours = openingHours.filter(
										oh => oh.day === weekDay
									)
									return (
										<div key={weekDay} className="weekday">
											<div className="field weekday">
												{moment(weekDay, "E").format("dddd")}
											</div>
											<div className="opening-hours">
												{dayOpeningHours.length === 0 && (
													<div className="closed">{t("closed")}</div>
												)}
												{dayOpeningHours.map(oh => (
													<div
														key={oh.start.format() + oh.end.format()}
														className="opening-hour"
														onClick={this.handleRemoveOpeningHour}
														data-id={oh.id}
													>
														{`${oh.start.format(
															"HH:mm"
														)} - ${oh.end.format("HH:mm")}`}
														<div className="tooltip">
															{t("click_to_remove")}
														</div>
													</div>
												))}
											</div>
										</div>
									)
								})}
							</div>
							<div className="opening-hour-form">
								<PootsySelectInput
									name="day"
									defaultText={t("day")}
									selected={currentOpeningDay}
									onChange={this.handleOpeningHourDayChange}
									options={[
										{ label: t("Monday"), value: "1" },
										{ label: t("Tuesday"), value: "2" },
										{ label: t("Wednesday"), value: "3" },
										{ label: t("Thursday"), value: "4" },
										{ label: t("Friday"), value: "5" },
										{ label: t("Saturday"), value: "6" },
										{ label: t("Sunday"), value: "7" },
									]}
								/>
								<LabeledDateTime
									label={t("opening_hour_start")}
									showDatePicker={false}
									timePickerProps={{
										name: "currentOpeningStart",
										value: currentOpeningStart,
										onChange: this.handleOpeningHourChange,
									}}
								/>
								<LabeledDateTime
									label={t("opening_hour_end")}
									showDatePicker={false}
									timePickerProps={{
										name: "currentOpeningEnd",
										value: currentOpeningEnd,
										onChange: this.handleOpeningHourChange,
										filterHours: h => h >= currentOpeningStart.hours(),
										filterMinutes: m =>
											currentOpeningStart.hours() < currentOpeningEnd.hours()
												? m >= 0 && m <= 59
												: m > currentOpeningStart.minute(),
									}}
								/>
								<PootsyButton
									text={t("add_opening_hour")}
									onClick={this.handleAddOpeningHour}
								/>
							</div>
						</div>
					</div>
				</InputsChangeHandler>
			</ContentLayout>
		)
	}
}

SettingsAffiliatePage.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currAffiliate: state.redData.currentAffiliate,
	currLocalFields: state.redData.currentAffiliateLocalisedFields,
})
export default connect(mapStateToProps)(SettingsAffiliatePage)
