import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"

import * as actions from "../actions"

import Filter from "./Filter"
import {
	OptionBox,
	ContentLayout,
	LabeledSimpleLine,
	PootsySubHeading,
	OnssErrorModal,
} from "./FunctionalDesign"
import { PootsySelectInput } from "./FunctionalInputs"
import DateFilterer from "./DateFilterer"

import SearchWorker from "./SearchWorker"

class UnemploymentBooks extends Component {
	UNSAFE_componentWillMount = () => {
		this.props.dispatch(actions.fetchUnemploymentBooks())
		if (this.props.teams.length === 0) {
			this.props.dispatch(actions.fetchTeams())
		}
	}
	handleChanges = changes => {
		this.props.dispatch(actions.unemploymentBooksStateChange(changes))
	}
	handleFilterWorker = workerName => {
		this.handleChanges({ searchedAffiliateWorker: { name: workerName } })
		if (workerName === "" || workerName === null) {
			this.handleChanges({ searchedAffiliateWorkerId: null })
			this.props.dispatch(actions.fetchUnemploymentBooks())
		}
	}
	handleFilterStatus = e => {
		let { value } = e.target
		this.handleChanges({ status: value })
		this.props.dispatch(actions.fetchUnemploymentBooks())
	}
	handleSquadsFilterChange = changes => {
		this.handleChanges({ squadsFilter: changes.value })
		this.props.dispatch(actions.fetchUnemploymentBooks())
	}
	toggleOnssErrorModal = id => {
		let {
			component: { unemployment_books },
		} = this.props
		let ub = unemployment_books.find(entry => entry.id === id)
		let changes = {}
		if (!ub || !id || !ub.attributes.errors) {
			changes = { showOnssErrorModal: false, onssErrors: "" }
		} else {
			changes = {
				showOnssErrorModal: true,
				onssErrors: ub.attributes.errors,
				onssErrorsTradKeys: ub.attributes.error_translation_keys,
			}
		}
		this.handleChanges(changes)
	}
	handleWorkerClick = worker => {
		let newChosenWorker = {
			name: worker.attributes.display_name,
			firstName: worker.attributes.first_name,
			lastName: worker.attributes.last_name,
			sodexo: worker.attributes.sodexo_reference,
			niss: worker.attributes.niss,
			affiliateWorkerId: Number(worker.id),
		}
		this.handleChanges({ chosenWorker: newChosenWorker })
		this.props.dispatch(actions.resetWorkerSuggestions())
	}

	handleFilterWorkerClick = worker => {
		this.handleChanges({
			searchedAffiliateWorkerId: Number(worker.id),
			searchedAffiliateWorker: { name: worker.attributes.display_name },
		})
		this.props.dispatch(actions.fetchUnemploymentBooks())
	}
	handleDateFilterChange = changes => {
		let newObj = { rangeStart: changes.from, rangeEnd: changes.to }
		this.handleChanges(newObj)
		this.props.dispatch(actions.fetchUnemploymentBooks())
	}
	render() {
		let { t } = this.context
		let {
			teams,
			component: {
				unemployment_books,
				rangeStart,
				rangeEnd,
				searchedAffiliateWorker,
				showOnssErrorModal,
				onssErrors,
				onssErrorsTradKeys,
				squadsFilter,
				status,
			},
		} = this.props
		let optionsBoxes = (
			<OptionBox
				boxTitle={t("eco_unemployment_unemployment_books")}
				customClass="worker-contracts-list"
			>
				<PootsySubHeading text={t("date_filters")} />
				<DateFilterer
					alwaysOpened={true}
					from={rangeStart}
					to={rangeEnd}
					// rangeLimitEnd={}
					onChange={this.handleDateFilterChange}
				/>
				<SearchWorker
					onChange={this.handleFilterWorker}
					onClick={this.handleFilterWorkerClick}
					value={searchedAffiliateWorker.name}
				/>
				<PootsySelectInput
					name="status"
					defaultText={t("status")}
					selected={status}
					options={actions.ONSS_STATUSES.map(e => ({ label: t(e), value: e }))}
					onChange={this.handleFilterStatus}
					allowEmpty={true}
				/>
				<Filter
					label={t("squads")}
					name="squadsFilter"
					possibleValues={teams.map(entry => ({
						label: entry.attributes.name,
						value: entry.id,
					}))}
					currentFilters={squadsFilter}
					onChange={this.handleSquadsFilterChange}
				/>
			</OptionBox>
		)

		return (
			<ContentLayout optionsBoxes={optionsBoxes} customClass="UnemploymentBooks">
				{showOnssErrorModal && (
					<OnssErrorModal
						errors={onssErrors}
						errorsTradKeys={onssErrorsTradKeys}
						closeModal={this.toggleOnssErrorModal}
					/>
				)}
				{unemployment_books &&
					unemployment_books.map(entry => (
						<LabeledSimpleLine
							key={entry.id}
							simpleLineProps={{
								noLink: true,
								customClass: "unemployment-declaration-line",
								onClick: () => this.toggleOnssErrorModal(entry.id),
							}}
							columns={[
								{ noLabel: true, content: "#" + entry.id, customClass: "id" },
								{
									label: t("onss_ref_number"),
									value: entry.attributes.onss_ref_number,
								},
								{
									label: t("form_c32a_number"),
									value: entry.attributes.form_c32a_number,
								},
								{
									label: t("start_date"),
									value: moment(entry.attributes.start_date).format("MMMM"),
								},
								{
									label: t("status"),
									value: entry.attributes.status
										? t(entry.attributes.status)
										: t("onss_connection_not_yet"),
								},
								{ label: t("worker"), value: entry.attributes.affiliate_worker },
							]}
						/>
					))}
				{unemployment_books && unemployment_books.length === 0 && (
					<div> {t("empty_list")} </div>
				)}
			</ContentLayout>
		)
	}
}

UnemploymentBooks.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.unemploymentBooksComponent,
	teams: state.redData.teams,
})
export default connect(mapStateToProps)(UnemploymentBooks)
