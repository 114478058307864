import React, { useContext, Fragment } from "react"
import { useSelector } from "react-redux"
import { LocaleContext } from "../index.js"

import {
	useChangeHandler,
	useSodexoWorkFetcher,
	useUnlinkSodexoWorkSubmit,
} from "./SearchSodexoWorkHooks"

import { PootsyButton, SodexoWorkLine, WorkshopOrderLine } from "./FunctionalDesign"
import { PootsyTextInput } from "./FunctionalInputs"
import ModalLayout from "./ModalLayout"
import BookingItem from "./BookingItem"

// 124989006

const SearchSodexoWork = () => {
	const { showModal, sodexoWork } = useSelector(
		state => state.redComponents.searchSodexoWorkComponent
	)
	const handleChanges = useChangeHandler()
	const submitUnlinkSodexoWork = useUnlinkSodexoWorkSubmit()
	const fetchSodexoWorkById = useSodexoWorkFetcher()
	const t = useContext(LocaleContext)
	return (
		<div className="SearchSodexoWork">
			<PootsyButton
				text={t("search_sodexo_work")}
				onClick={() => handleChanges({ showModal: true })}
			/>
			{showModal && (
				<ModalLayout
					title={t("search_sodexo_work_modal_title")}
					closeModal={() => handleChanges({ showModal: false })}
					customClass="search-sodexo-work-modal"
				>
					<PootsyTextInput label={t("sodexo_work_id")} onKeyPress={fetchSodexoWorkById} />
					{sodexoWork.id && (
						<div className="records">
							<SodexoWorkLine sodexoWork={sodexoWork} />
							{sodexoWork.subject && (
								<Fragment>
									<div className="link">
										<div className="line" />
										<PootsyButton
											text={t("unlink")}
											onClick={submitUnlinkSodexoWork}
											size="small"
										/>
										<div className="line" />
									</div>
									{sodexoWork.subjectType === "LaundryOrder" ? (
										<WorkshopOrderLine workshopOrder={sodexoWork.subject} />
									) : (
										<BookingItem booking={sodexoWork.subject} />
									)}
								</Fragment>
							)}
						</div>
					)}
				</ModalLayout>
			)}
		</div>
	)
}

export default SearchSodexoWork
