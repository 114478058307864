import React, { Component } from "react"

export default class InputsChangeHandler extends Component {
	handleChanges = e => {
		e.stopPropagation()
		let { setParentState, onChange } = this.props
		let { name, type } = e.target
		if (type === "file") {
			this.handleFile(name, e.target.files[0])
			return
		}
		let value = type === "checkbox" ? e.target.checked : e.target.value
		onChange({ [name]: value })
		setParentState({ [name]: value })
	}
	handleFile = (name, file) => {
		if (!file) {
			return
		}
		let { setParentState, onChange } = this.props
		let reader = new FileReader()
		let splitFileName = file.name.split(".")
		let extension = splitFileName[splitFileName.length - 1]
		reader.onload = upload => {
			onChange({ [name]: { filename: file.name, extension, file: upload.target.result } })
			setParentState({
				[name]: { filename: file.name, extension, file: upload.target.result },
			})
		}
		reader.readAsDataURL(file)
	}
	render() {
		let { customClass, style } = this.props
		return (
			<div
				className={"inputs-change-handler " + customClass}
				onChange={this.handleChanges}
				style={style}
			>
				{this.props.children}
			</div>
		)
	}
}

InputsChangeHandler.defaultProps = {
	customClass: "",
	setParentState: () => {},
	onChange: () => {},
	style: { flex: "1 0 0", display: "flex" },
}
