import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"

import * as actions from "../actions"

import CustomSelect from "./CustomSelect"
import InputsChangeHandler from "./InputsChangeHandler"
import {
	ContentLayout,
	OptionBox,
	PootsySubHeading,
	PootsyMultipleValuesBox,
	LabeledDateTime,
	PootsyButton,
} from "./FunctionalDesign"
import { PootsyTextInput, PootsyCheckboxInput, PootsySelectInput } from "./FunctionalInputs"

class SettingsCompany extends Component {
	constructor() {
		super()
		this.state = {
			allAreas: [],
			showRequired: { minBookingMins: false, minIntraBookingMins: false },
			customHolidayStart: "",
			customHolidayEnd: "",
			customHolidaySsCode: "",
		}
	}
	UNSAFE_componentWillMount = () => {
		this.fetchAllAreas()
		this.fetchSuperAreas()
	}
	fetchSuperAreas = () => {
		let { dispatch } = this.props
		dispatch(
			actions.simpleFetch("/public/super_area_list", { method: "GET" }, "FETCH_SUPERAREAS")
		)
	}
	fetchAllAreas = () => {
		this.props.dispatch(actions.simpleFetch("/public/area_list", { method: "GET" }, "ALLAREAS"))
	}
	handleFormChanges = data => {
		this.props.dispatch(actions.editSettings(data))
	}
	togglePublicHoliday = e => {
		e.stopPropagation()
		let { name, checked } = e.target
		let {
			dispatch,
			currAffiliate: { currYearHolidaysTimeRanges },
		} = this.props
		let date = moment(name, "YYYY-MM-DD")
		let start = moment(date).hour(8),
			end = moment(date).hour(20)
		if (checked) {
			let duration = end.diff(start, "minutes")
			let timeRangeToAdd = { duration, type: "affiliateHoliday", start, end }
			dispatch(
				actions.editSettings({
					currYearHolidaysTimeRanges: [
						timeRangeToAdd,
						...currYearHolidaysTimeRanges,
					].sort((a, b) => a.start.isAfter(b.start)),
				})
			)
		} else if (!checked) {
			dispatch(
				actions.editSettings({
					currYearHolidaysTimeRanges: currYearHolidaysTimeRanges.filter(
						entry => !(entry.start.isSame(start, "day") && entry.end.isSame(end, "day"))
					),
				})
			)
		}
	}
	handleHolidayDateChange = changes => {
		this.setState(changes)
	}
	addCustomHoliday = () => {
		let {
			dispatch,
			currAffiliate: { currYearHolidaysTimeRanges },
		} = this.props
		let { customHolidayStart, customHolidayEnd, customHolidaySsCode } = this.state
		if (moment.isMoment(customHolidayStart) && moment.isMoment(customHolidayEnd)) {
			customHolidayStart.hour(8)
			customHolidayEnd.hour(20)
			let duration = customHolidayEnd.diff(customHolidayStart, "minutes")
			let timeRangeToAdd = {
				duration,
				type: "affiliateHoliday",
				start: customHolidayStart,
				end: customHolidayEnd,
				custom_ss_code: customHolidaySsCode,
			}
			dispatch(
				actions.editSettings({
					currYearHolidaysTimeRanges: [
						timeRangeToAdd,
						...currYearHolidaysTimeRanges,
					].sort((a, b) => a.start.isAfter(b.start)),
				})
			)
			this.setState({ customHolidayEnd: "", customHolidayStart: "", customHolidaySsCode: "" })
		}
	}
	handleRemoveHoliday = e => {
		let { start, end } = e.target.dataset
		let {
			dispatch,
			currAffiliate: { currYearHolidaysTimeRanges },
		} = this.props
		start = moment(start, "DD/MM/YYYY")
		end = moment(end, "DD/MM/YYYY")
		dispatch(
			actions.editSettings({
				currYearHolidaysTimeRanges: currYearHolidaysTimeRanges.filter(
					entry => !(entry.start.isSame(start, "day") && entry.end.isSame(end, "day"))
				),
			})
		)
	}
	handleCoveredAreasChange = changes => {
		this.props.dispatch(
			actions.editSettings({
				activeAreas: changes.coveredAreas.map(entry => ({
					label: entry,
					value: entry,
				})),
			})
		)
	}
	render() {
		let { t } = this.context
		let { customHolidayStart, customHolidayEnd, customHolidaySsCode } = this.state
		let {
			lang,
			allAreas,
			superAreas,
			currAffiliate: {
				secSocEvents,
				commercialName,
				companyName,
				minBookingMins,
				minIntraBookingMins,
				minDaysBeforeNewBooking,
				minDaysBeforeBookingCancel,
				acceptsPonctualBookings,
				minDaysBeforeEndingCustomerContract,
				sodexoAffiliationNb,
				sodexoAgreementNbBrussels,
				sodexoAgreementNbFlanders,
				sodexoAgreementNbWallonia,
				workAllowedSaturday,
				workAllowedSunday,
				VatNumber,
				activeAreas,
				publicHolidaysOfCurrentYear,
				currYearHolidaysTimeRanges,
				hideUnfulfillingTimeslotsToCustomers,
			},
		} = this.props
		let { showRequired } = this.state
		let optionBoxTitle = (
			<div className="box-title">
				{commercialName}
				{/*<img src={logo.thumb.url} alt="company-logo"/>*/}
			</div>
		)
		let optionBox = (
			<OptionBox boxTitle={optionBoxTitle}>
				<PootsyMultipleValuesBox
					values={[
						{ label: t("company_name"), values: [companyName] },
						{ label: t("vat_number"), values: [VatNumber] },
					]}
				/>
			</OptionBox>
		)
		let selectedPublicHolidays = publicHolidaysOfCurrentYear.reduce((acc, entry) => {
			if (currYearHolidaysTimeRanges.find(e => e.start.format("YYYY-MM-DD") === entry.date)) {
				acc[entry.date] = true
			} else {
				acc[entry.date] = false
			}
			return acc
		}, {})
		return (
			<ContentLayout optionsBoxes={optionBox}>
				<InputsChangeHandler
					onChange={this.handleFormChanges}
					customClass="settings-company"
				>
					<PootsySubHeading text={t("booking_settings")} fontSize=".8em" />
					<PootsyTextInput
						name="minBookingMins"
						type="number"
						label={t("min_booking_duration_mins")}
						showRequired={showRequired.minBookingMins}
						value={minBookingMins}
					/>
					<PootsySelectInput
						name="minIntraBookingMins"
						defaultText={t("min_inter_booking_duration_mins")}
						selected={minIntraBookingMins}
						showRequired={showRequired.minIntraBookingMins}
						options={[
							{ label: "0", value: "0" },
							{ label: "30", value: "30" },
							{ label: "60", value: "60" },
							{ label: "90", value: "90" },
						]}
					/>
					<PootsyTextInput
						name="minDaysBeforeNewBooking"
						type="number"
						label={t("min_days_before_new_booking")}
						showRequired={showRequired.minDaysBeforeNewBooking}
						value={minDaysBeforeNewBooking}
					/>
					<PootsyTextInput
						name="minDaysBeforeBookingCancel"
						type="number"
						label={t("min_days_before_booking_cancel")}
						showRequired={showRequired.minDaysBeforeBookingCancel}
						value={minDaysBeforeBookingCancel}
					/>
					<PootsyTextInput
						name="minDaysBeforeEndingCustomerContract"
						type="number"
						label={t("min_days_before_ending_customer_contract")}
						showRequired={showRequired.minDaysBeforeEndingCustomerContract}
						value={minDaysBeforeEndingCustomerContract}
					/>
					<PootsyCheckboxInput
						name="acceptsPonctualBookings"
						label={t("accepts_ponctual_bookings")}
						checked={acceptsPonctualBookings}
					/>
					<PootsyCheckboxInput
						name="hideUnfulfillingTimeslotsToCustomers"
						label={t("hide_unfulfilling_timeslots_to_customers")}
						checked={hideUnfulfillingTimeslotsToCustomers}
					/>
					<PootsySubHeading text={t("sodexo")} fontSize=".8em" />
					<PootsyTextInput
						name="sodexoAffiliationNb"
						type="number"
						label={t("sodexo_affiliation_number")}
						showRequired={showRequired.sodexoAffiliationNb}
						value={sodexoAffiliationNb}
					/>
					<PootsyTextInput
						name="sodexoAgreementNbBrussels"
						type="number"
						label={t("sodexo_agreement_number_brussels")}
						showRequired={showRequired.sodexoAgreementNbBrussels}
						value={sodexoAgreementNbBrussels}
					/>
					<PootsyTextInput
						name="sodexoAgreementNbFlanders"
						type="number"
						label={t("sodexo_agreement_number_flanders")}
						showRequired={showRequired.sodexoAgreementNbFlanders}
						value={sodexoAgreementNbFlanders}
					/>
					<PootsyTextInput
						name="sodexoAgreementNbWallonia"
						type="number"
						label={t("sodexo_agreement_number_wallonia")}
						showRequired={showRequired.sodexoAgreementNbWallonia}
						value={sodexoAgreementNbWallonia}
					/>
					<PootsySubHeading text={t("week_ends")} fontSize=".8em" />
					<PootsyCheckboxInput
						name="workAllowedSaturday"
						label={t("work_allowed_saturdays")}
						checked={workAllowedSaturday}
					/>
					<PootsyCheckboxInput
						name="workAllowedSunday"
						label={t("work_allowed_sundays")}
						checked={workAllowedSunday}
					/>
					<PootsySubHeading text={t("areas_covered")} fontSize=".8em" />
					<CustomSelect
						name="coveredAreas"
						selectLabel={t("areas_covered")}
						valuesGroups={superAreas.map(superArea => ({
							label: superArea.attributes["name_" + lang],
							values: superArea.attributes.areas,
						}))}
						valuesList={allAreas}
						currentValues={activeAreas.map(entry => entry.value)}
						onChange={this.handleCoveredAreasChange}
					/>
					<PootsySubHeading text={t("holidays")} fontSize=".8em" />
					<span className="public-holidays-description">{t("public_holidays")}</span>
					<div className="public-holidays">
						{publicHolidaysOfCurrentYear.map(entry => {
							let date = moment(entry.date).format("DD/MM/YYYY")
							return (
								<PootsyCheckboxInput
									key={date}
									name={entry.date}
									label={date}
									checked={selectedPublicHolidays[entry.date]}
									onChange={this.togglePublicHoliday}
								/>
							)
						})}
					</div>
					<div className="new-holiday-form">
						<LabeledDateTime
							label={t("start")}
							showTimePicker={false}
							datePickerProps={{
								name: "customHolidayStart",
								value: customHolidayStart,
								onChange: this.handleHolidayDateChange,
								calendarStyle: { bottom: "200%", left: "0", zIndex: "2" },
							}}
						/>
						<LabeledDateTime
							label={t("end")}
							showTimePicker={false}
							datePickerProps={{
								name: "customHolidayEnd",
								value: customHolidayEnd,
								onChange: this.handleHolidayDateChange,
								calendarStyle: { bottom: "200%", left: "0", zIndex: "2" },
								disableDay: day => day.isBefore(customHolidayStart),
							}}
						/>
						<PootsySelectInput
							name="activityCode"
							defaultText={t("activity_code")}
							showRequired={showRequired.activityCode}
							selected={customHolidaySsCode}
							allowEmpty={true}
							onChange={e => {
								e.stopPropagation()
								this.setState({ customHolidaySsCode: e.target.value })
							}}
							options={secSocEvents.codes
								.filter(entry => entry.timesheet_category !== "expense")
								.map(entry => ({
									label: entry.id + " - " + t(entry.label),
									value: entry.id,
								}))}
						/>
						<PootsyButton
							size="small"
							text={t("add_custom_holiday")}
							onClick={this.addCustomHoliday}
						/>
					</div>
					<div className="effective-holidays-description">{t("effective_holidays")}</div>
					<div className="effective-holidays">
						{currYearHolidaysTimeRanges.length === 0 && (
							<span className="no-holidays-warning">
								{t("no_added_holidays_warning")}
							</span>
						)}
						{currYearHolidaysTimeRanges.map(entry => {
							let start = moment(entry.start).format("DD/MM/YYYY")
							let end = moment(entry.end).format("DD/MM/YYYY")
							return (
								<div
									key={start + end}
									className="effective-holiday"
									onClick={this.handleRemoveHoliday}
									data-start={start}
									data-end={end}
								>
									<div>{`${start} > ${end}`}</div>
									{entry.customSsCode && (
										<div className="custom-ss-code">{entry.customSsCode}</div>
									)}
									<div className="delete-mark">X</div>
								</div>
							)
						})}
					</div>
				</InputsChangeHandler>
			</ContentLayout>
		)
	}
}

SettingsCompany.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currAffiliate: state.redData.currentAffiliate,
	allAreas: state.redData.allAreas,
	superAreas: state.redData.superAreas,
	lang: state.i18nState.lang,
})
export default connect(mapStateToProps)(SettingsCompany)
