import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import ModalLayout from "./ModalLayout"
import { PootsySubHeading, LabeledDateTime, PootsyButton } from "./FunctionalDesign"
import { PootsySelectInput, PootsyTextInput } from "./FunctionalInputs"

import * as actions from "../actions"

class NewTimesheetEventModal extends Component {
	handleChanges = changes => {
		this.props.dispatch(actions.newTimesheetEventModalStateChange(changes))
	}
	submitNewEvent = () => {
		let {
			dispatch,
			closeModal,
			currTimesheet,
			component: {
				newEventModalDay,
				newEventSecSocTerm,
				newEventStart,
				newEventEnd,
				newEventDistance,
			},
		} = this.props
		let changedTimesheetId = currTimesheet.timesheetInfo.timesheetID
		let newEvent = {
			event_id: "event_added_by_affiliate__" + Math.floor(Math.random() * 1000000000000),
			social_secretary_code: newEventSecSocTerm,
			event_type: "added_by_affiliate",
			start_time: newEventStart,
			end_time: newEventEnd,
			distance_in_km: newEventDistance,
		}
		dispatch(
			actions.addTimesheetEvent(
				newEvent,
				newEventModalDay.format("YYYY-MM-DD"),
				currTimesheet.worker.id
			)
		)
		closeModal()
		dispatch(
			actions.submitTimesheetUpdate(
				changedTimesheetId,
				newEventModalDay.format("YYYY-MM-DD"),
				newEvent
			)
		)
	}
	render() {
		let { t } = this.context
		let {
			currentAffiliate,
			closeModal,
			component: {
				newEventModalDay,
				newEventSecSocTerm,
				newEventStart,
				newEventEnd,
				newEventDistance,
			},
		} = this.props
		let newEventModalButton = (
			<Fragment>
				<PootsyButton theme="green" text={t("add_event")} onClick={this.submitNewEvent} />
				<PootsyButton theme="cancel" text={t("cancel")} onClick={closeModal} />
			</Fragment>
		)
		return (
			<ModalLayout
				formDivClass="NewTimesheetEventModal"
				title={t("secsoc_new_event_modal")}
				buttons={newEventModalButton}
				closeModal={closeModal}
				withInputsChangeHandler={true}
				handleChanges={this.handleChanges}
			>
				<PootsySubHeading text={newEventModalDay.format("DD/MM/YYYY")} />
				<PootsySelectInput
					name="newEventSecSocTerm"
					defaultText={t("event_type")}
					selected={newEventSecSocTerm}
					options={currentAffiliate.secSocEvents.codes.map(term => ({
						label: t(term.label),
						value: term.id,
					}))}
				/>
				<LabeledDateTime
					label={t("start_time")}
					showDatePicker={false}
					showLabel={false}
					timePickerProps={{
						name: "newEventStart",
						value: newEventStart,
						onChange: this.handleChanges,
					}}
				/>
				<LabeledDateTime
					label={t("end_time")}
					showDatePicker={false}
					showLabel={false}
					timePickerProps={{
						name: "newEventEnd",
						value: newEventEnd,
						onChange: this.handleChanges,
					}}
				/>
				<PootsyTextInput
					customClass="column km"
					type="number"
					name="newEventDistance"
					label="KM"
					min="0"
					value={newEventDistance}
				/>
			</ModalLayout>
		)
	}
}

NewTimesheetEventModal.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.newTimesheetEventModalComponent,
	currentAffiliate: state.redData.currentAffiliate,
})
export default connect(mapStateToProps)(NewTimesheetEventModal)
