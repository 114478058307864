import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import * as actions from "../actions"

import ModalLayout from "../components/ModalLayout"
import CustomerSelector from "./CustomerSelector"
import { PootsySelectInput, PootsyTextInput } from "./FunctionalInputs"
import { PootsyButton } from "./FunctionalDesign"

class MissingLaundryOrderModal extends Component {
	handleChanges = changes => {
		this.props.dispatch(actions.missingLaundryOrderModalStateChange(changes))
	}
	handleCustomerClick = customer => {
		this.handleChanges({
			svType: customer.attributes.voucher_type_preference || "",
			chosenCustomer: {
				id: Number(customer.id),
				name: customer.attributes.display_name,
				sodexo: customer.attributes.sodexo_reference,
			},
		})
		this.props.dispatch(actions.resetCustomerSuggestions())
	}
	submitMissingLaundryOrder = () => {
		let { contractID, week } = this.props
		this.props.dispatch(actions.submitPastWorkshopOrder(contractID, week))
	}
	render() {
		let { t } = this.context
		let {
			closeModal,
			currentAffiliate,
			component: {
				workshopId,
				svType,
				chosenCustomer,
				workerDisplayName,
				executionDate,
				executedDurationMinutes,
				billableHoursOverride,
			},
		} = this.props
		return (
			<ModalLayout
				customClass="MissingLaundryOrderModal"
				title={t("add_missing_laundry_order_modal_title")}
				closeModal={closeModal}
				withInputsChangeHandler={true}
				handleChanges={this.handleChanges}
				buttons={[
					<PootsyButton
						key="a"
						text={t("submit")}
						onClick={this.submitMissingLaundryOrder}
					/>,
					<PootsyButton key="b" text={t("cancel")} theme="cancel" onClick={closeModal} />,
				]}
			>
				<div className="worker-date">
					<div className="worker">{workerDisplayName}</div>
					<div className="execution-date">{executionDate.format("DD/MM/YYYY")}</div>
				</div>
				<PootsySelectInput
					name="workshopId"
					defaultText={t("workshop")}
					selected={workshopId}
					options={currentAffiliate.workshops.map(entry => ({
						label: entry.workshopName,
						value: entry.id,
					}))}
				/>
				<CustomerSelector
					chosenCustomer={chosenCustomer}
					svType={svType}
					handleCustomerClick={this.handleCustomerClick}
				/>
				<PootsyTextInput
					type="number"
					label={t("executed_duration_minutes")}
					name="executedDurationMinutes"
					value={executedDurationMinutes}
				/>
				<PootsyTextInput
					type="number"
					label={t("billable_hours_override")}
					name="billableHoursOverride"
					value={billableHoursOverride}
				/>
			</ModalLayout>
		)
	}
}

MissingLaundryOrderModal.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.missingLaundryOrderModalComponent,
	workshopComponent: state.redComponents.workshopComponent,
	workerSuggestions: state.redData.workerSuggestions,
	customerSuggestions: state.redData.customerSuggestions,
	availableOrderItems: state.redData.avOrderItemsList,
	currentAffiliate: state.redData.currentAffiliate,
})
export default connect(mapStateToProps)(MissingLaundryOrderModal)
