import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import moment from "moment"

import { ContentLayout, OptionBox, PootsyButton, PSVSlipLine } from "./FunctionalDesign"
import { PootsySelectInput } from "./FunctionalInputs"
import * as actions from "../actions"
import Spinner from "./Spinner"
import SearchPSV from "./SearchPSV"
import SearchSodexoWork from "./SearchSodexoWork"
import Paginator from "./Paginator"
import CompleteMonthPicker from "./CompleteMonthPicker"

const pvdgPageQueries = {
	pageQuery: {
		urlName: "page",
		queryName: "page",
		mustBeSet: true,
		defaultValue: "1",
	},
	statusQuery: {
		urlName: "status",
		queryName: "status",
		mustBeSet: false,
	},
	regionQuery: {
		urlName: "region",
		queryName: "sodexo_region",
		mustBeSet: false,
	},
	monthQuery: {
		urlName: "month",
		queryName: "month_start",
		defaultValue: moment()
			.startOf("month")
			.format("YYYY-MM-DD"),
		mustBeSet: true,
	},
}
class SodexoPSVDeliveries extends Component {
	componentDidMount = () => {
		this.props.dispatch(actions.replaceQueries(pvdgPageQueries))
		this.fetchPvdg()
	}
	handleChanges = changes => {
		this.props.dispatch(actions.paperDeliveriesStateChange(changes))
	}
	fetchPvdg = () => {
		this.props.dispatch(actions.fetchPootsyPaperDeliveriesForSodexo())
	}
	handleChangePage = async changes => {
		await this.props.dispatch(
			actions.updateQuery({
				name: "pageQuery",
				value: changes.value,
			})
		)
		this.fetchPvdg()
	}
	handleStatusQueryChange = async e => {
		await this.props.dispatch(
			actions.updateQuery({
				name: "statusQuery",
				value: e.target.value,
			})
		)
		this.fetchPvdg()
	}
	handleRegionQueryChange = async e => {
		await this.props.dispatch(
			actions.updateQuery({
				name: "regionQuery",
				value: e.target.value,
			})
		)
		this.fetchPvdg()
	}
	handleMonthQueryChange = async m => {
		await this.props.dispatch(
			actions.updateQuery({
				name: "monthQuery",
				value: m.format("YYYY-MM-DD"),
			})
		)
		this.fetchPvdg()
	}
	render() {
		let {
			component: { pagination, paperDeliveryGroups },
			currentQuery: {
				urlQuery: { page, status, region, month },
			},
		} = this.props
		let { t } = this.context
		let optionsBoxes = [
			<OptionBox key="a" boxTitle={t("sodexo_info")}>
				<Link to="/new_paper_delivery_group">
					<PootsyButton text={`${t("new")} ${t("return_slip")}`} />
				</Link>
				<SearchPSV />
				<SearchSodexoWork />
			</OptionBox>,
			<OptionBox key="b" boxTitle={t("filters")} customClass="filters-box">
				{month && (
					<CompleteMonthPicker
						currentDate={moment(month)}
						onChange={this.handleMonthQueryChange}
					/>
				)}
				<PootsySelectInput
					name="statusQuery"
					defaultText={t("status")}
					allowEmpty={true}
					onChange={this.handleStatusQueryChange}
					selected={status}
					options={[
						{ label: t("cancelled"), value: "cancelled" },
						{ label: t("open"), value: "open" },
						{ label: t("sent"), value: "sent" },
						{ label: t("re_opened"), value: "re_opened" },
					]}
				/>
				<PootsySelectInput
					name="regionQuery"
					defaultText={t("region")}
					allowEmpty={true}
					onChange={this.handleRegionQueryChange}
					selected={region}
					options={[
						{ label: t("region_brussels"), value: "73" },
						{ label: t("region_wallonia"), value: "72" },
						{ label: t("region_flanders"), value: "71" },
					]}
				/>
			</OptionBox>,
		]
		return (
			<ContentLayout optionsBoxes={optionsBoxes} customClass="SodexoPSVDeliveries">
				{this.props.showSpinner && <Spinner />}

				{paperDeliveryGroups.map(item => (
					<PSVSlipLine key={item.id} psvSlip={item} />
				))}
				<Paginator
					currPage={page}
					totalPages={pagination.total_pages}
					goToPage={this.handleChangePage}
				/>
			</ContentLayout>
		)
	}
}

SodexoPSVDeliveries.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.PSVDeliveryComponent,
	currAffiliate: state.redData.currentAffiliate,
	currentQuery: state.currentURLQuery,
})
export default connect(mapStateToProps)(SodexoPSVDeliveries)
