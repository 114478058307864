import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import * as actions from "../actions"

import { PootsySelectInput } from "./FunctionalInputs"
import { PootsyButton } from "./FunctionalDesign"
import PootsyAddressBlockInputs from "./PootsyAddressBlockInputs"
import ModalLayout from "./ModalLayout"

class WorkshopLine extends Component {
	state = {
		showSSCodeForm: false,
		SSCode: undefined,
		showAddresForm: false,
		city: "",
		zip: "",
		box: "",
		streetNumber: "",
		street: "",
	}
	componentDidMount = () => {
		let { entry } = this.props
		this.setState({
			city: entry.address.town,
			zip: entry.address.zip_code,
			box: entry.address.street_box,
			streetNumber: entry.address.street_number,
			street: entry.address.street_name,
		})
	}
	updateWorkshop = async () => {
		let { dispatch, entry } = this.props
		let { SSCode } = this.state
		await dispatch(actions.updateWorkshop({ id: entry.id, ss_code: SSCode }))
		this.setState({ showSSCodeForm: false })
	}
	updateWorkshopAddress = async () => {
		let { dispatch, entry } = this.props
		let { city, zip, box, streetNumber, street } = this.state
		await dispatch(
			actions.updateWorkshopAddress({
				id: entry.id,
				address: {
					town: city,
					zip_code: zip,
					street_box: box,
					street_number: streetNumber,
					street_name: street,
				},
			})
		)
		this.setState({ showAddresForm: false })
	}
	handleExpandLine = () => {
		this.setState({ showSSCodeForm: !this.state.showSSCodeForm })
	}
	changeSSCode = event => {
		this.setState({ SSCode: event.target.value })
	}
	toggleAddresModal = () => {
		this.setState({ showAddresForm: !this.state.showAddresForm })
	}
	render() {
		let { t } = this.context
		let {
			SSCode,
			showSSCodeForm,
			showAddresForm,
			city,
			zip,
			box,
			streetNumber,
			street,
		} = this.state
		let { currAffiliate, entry } = this.props

		return (
			<div className="workshop">
				{showAddresForm && (
					<ModalLayout
						title={t("update_workshop_address")}
						formDivClass="update-workshop-address-modal"
						closeModal={this.toggleAddresModal}
						buttons={[
							<PootsyButton
								key="a"
								text={t("confirm")}
								size="small"
								onClick={this.updateWorkshopAddress}
							/>,
							<PootsyButton
								key="q"
								text={t("cancel")}
								size="small"
								theme="cancel"
								onClick={this.toggleAddresModal}
							/>,
						]}
					>
						<PootsyAddressBlockInputs
							setValues={change => this.setState(change)}
							handleChanges={change => this.setState(change)}
							values={{ city, zip, box, streetNumber, street }}
						/>
					</ModalLayout>
				)}
				<div className="workshop-name">{entry.workshopName}</div>
				<div className="address">{entry.address.address_inline}</div>
				{showSSCodeForm && (
					<Fragment>
						<PootsySelectInput
							defaultText={t("workshop_ss_code")}
							name="SSCode"
							selected={SSCode || entry.SSCode}
							options={currAffiliate.secSocEvents.codes
								.filter(entry => entry.timesheet_category === "timesheet")
								.map(entry => ({
									label: entry.id + " " + t(entry.label),
									value: entry.id,
								}))}
							onChange={this.changeSSCode}
						/>
						<PootsyButton
							text={t("update_workshop")}
							size="small"
							onClick={this.updateWorkshop}
						/>
					</Fragment>
				)}
				{!showSSCodeForm && (
					<Fragment>
						<div className="ss-code">{entry.SSCode}</div>
						<PootsyButton
							text={t("add_soc_sec_code")}
							size="small"
							onClick={this.handleExpandLine}
						/>
					</Fragment>
				)}
				<PootsyButton
					text={t("update_address")}
					size="small"
					onClick={this.toggleAddresModal}
				/>
			</div>
		)
	}
}
WorkshopLine.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	// mappings: state.redData.secSocTermsMappings,
	pootsyEvents: state.redData.pootsyEvents,
	pootsyExpenses: state.redData.pootsyExpenses,
	secSocTerms: state.redData.secSocTerms,
	currAffiliate: state.redData.currentAffiliate,
})
export default connect(mapStateToProps)(WorkshopLine)
