import React, { useState } from "react"
import { PootsyMultiSelect } from "./FunctionalInputs"
import { PootsyMultiSelectCurrentFilter } from "./PootsyMultiSelectCurrentFilter"

export const BookingFilter = ({ updateFilters, label, name, possibleValues, currentValues }) => {
	const [search, setSearch] = useState("")
	const [searchResults, setSearchResults] = useState([])

	function handleFilterChange(e) {
		e.stopPropagation()
		let search = e.target.value.toLowerCase()
		if (search.length < 1) {
			setSearch("")
			setSearchResults([])
			return
		}
		let searchResults = possibleValues.filter(entry =>
			entry.label.toLowerCase().includes(search)
		)
		setSearch(e.target.value)
		setSearchResults(searchResults)
	}

	function findValues(value) {
		let currentName = possibleValues.find(
			possibleValue => possibleValue.value.toString() === value
		)

		return currentName
	}

	let currentFields = []

	function clickHandler(e) {
		setSearch("")
		setSearchResults([])
		let value = e.target.attributes[0].value
		if (currentFields.filter(entry => entry.value === value).length === 0) {
			currentFields.push({
				comp: (
					<PootsyMultiSelectCurrentFilter
						key={value}
						name={e.target.innerText}
						value={value}
						removeField={removeField}
					/>
				),
				value: value,
			})
			updateFilters({ name: name, value: currentFields.map(result => result.value) })
		}
	}

	const removeField = value => {
		let newFields = currentFields.filter(obj => obj.value !== value)
		updateFilters({ name: name, value: newFields.map(result => result.value) })
	}

	if (currentValues && currentValues.length !== currentFields.length) {
		currentValues.forEach(value => {
			let currentName = findValues(value)
			currentFields.push({
				comp: (
					<PootsyMultiSelectCurrentFilter
						key={value}
						name={currentName.label}
						value={value}
						removeField={removeField}
					/>
				),
				value: value,
			})
		})
	}

	let selectedFields = (
		<>
			{currentFields.map(field => {
				return field.comp
			})}
		</>
	)

	return (
		<div className="pootsy-multiselect">
			<PootsyMultiSelect
				label={label}
				name={name}
				searchValue={search}
				searchResults={searchResults}
				onChange={handleFilterChange}
				onResultClick={clickHandler}
				selectedValues={[]}
			/>
			<div className="pootsy-multiselect-current-filters">
				<div name={name} value="" className="pootsy-multiselect-results-label">
					{selectedFields}
				</div>
			</div>
		</div>
	)
}

BookingFilter.defaultProps = {
	currentFilters: [],
	showRequired: false,
}
