import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import InputsChangeHandler from "./InputsChangeHandler"
import { PootsyTextInput, PootsySelectInput } from "./FunctionalInputs"
import { PootsySubHeading, PootsyButton } from "./FunctionalDesign"
import * as actions from "../actions"

class AvailableOrderItemCreator extends Component {
	handleChanges = changes => {
		this.props.dispatch(actions.availableOrderItemCreatorStateChange(changes))
	}

	submitAvOrderItem = () => {
		this.props.dispatch(actions.submitNewAvailableOrderItem())
	}

	render() {
		let { t } = this.context
		let { avOrderItem } = this.props
		return (
			<div className="AvailableOrderItemCreator">
				<PootsySubHeading text={t("workshop_settings_creator")} fontSize=".8em" />
				<InputsChangeHandler customClass="inputs" onChange={this.handleChanges}>
					<PootsyTextInput
						label={t("av_order_item_short_name")}
						name="shortName"
						value={avOrderItem.shortName}
						maxLength="20"
					/>
					<PootsyTextInput
						label={t("av_order_item_long_name")}
						name="longName"
						value={avOrderItem.longName}
					/>
					<PootsySelectInput
						name="category"
						defaultText={t("av_order_item_category")}
						selected={avOrderItem.category}
						options={[
							{ label: t("service_voucher"), value: "SERVICE_VOUCHER" },
							{ label: t("cash"), value: "CASH" },
						]}
					/>
					<PootsyTextInput
						label={t("av_order_item_price")}
						name="price"
						value={avOrderItem.price.toString().replace(".", ",")}
						type="text"
					/>
					<PootsyButton text={t("add")} onClick={this.submitAvOrderItem} />
				</InputsChangeHandler>
			</div>
		)
	}
}

AvailableOrderItemCreator.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	avOrderItem: state.redComponents.availableOrderItemCreatorComponent,
})
export default connect(mapStateToProps)(AvailableOrderItemCreator)
