import React from "react"
import moment from "moment"
import PropTypes from "prop-types"
import { PootsyTextInput, PootsySelectInput } from "./FunctionalInputs"
import { LabeledDateTime } from "./FunctionalDesign"

let display0If0 = (value, label) => {
	if ((label === "gross_salary" || label === "work_interruption_percentage") && value) {
		value = value.toString().replace(".", ",")
	}
	return value || value === 0 ? value : ""
}

export const SecSocInput = (
	{
		values,
		input,
		translationPrefix = "",
		onChange,
		currAffiliate,
		currentContract,
		workerContracts,
	},
	context
) => {
	let { t } = context
	if (input.show && !input.show(values)) {
		return null
	}
	if (input.hideInput && input.hideInput(currAffiliate)) {
		return null
	}
	let labelForTranslation = input.label
	if (!input.skipTranslationPrefix) {
		labelForTranslation = translationPrefix + labelForTranslation
	}
	let isRequired = input.required
	if (typeof isRequired === "function") {
		isRequired = input.required(values, currAffiliate, currentContract, workerContracts)
	}
	switch (input.inputType) {
		case "select":
			let value = ""
			let optionsTranslationPrefix = ""
			if (!input.skipOptionsTranslationPrefix) {
				optionsTranslationPrefix = translationPrefix
			}
			let options = input.options.map(entry => ({
				label: t(optionsTranslationPrefix + entry.label),
				value: entry.value,
			}))
			if (values[input.label] !== null && values[input.label] !== undefined) {
				// Making sure true & false values are getting through
				value = values[input.label].toString()
			}
			if (input.filterOptions) {
				let optionsToFilter = input.filterOptions(values)
				options = options.filter(entry => !optionsToFilter.includes(entry.value))
			}
			return (
				<PootsySelectInput
					key={input.label}
					name={input.label}
					defaultText={t(labelForTranslation)}
					allowEmpty={true}
					selected={value}
					isRequired={isRequired}
					options={options}
					// showRequired={}
				/>
			)
		case "text":
			return (
				<PootsyTextInput
					key={input.label}
					name={input.label}
					label={t(labelForTranslation)}
					value={
						input.value
							? input.value(currentContract)
							: display0If0(values[input.label], input.label)
					}
					isRequired={isRequired}
					disabled={input.disabled}
				/>
			)
		case "number":
			return (
				<PootsyTextInput
					key={input.label}
					name={input.label}
					disabled={input.disabled ? input.disabled : false}
					label={t(labelForTranslation)}
					type="number"
					value={display0If0(values[input.label])}
					isRequired={isRequired}
					// min="0"
				/>
			)
		case "datepicker":
			return (
				<LabeledDateTime
					key={input.label}
					label={t(labelForTranslation)}
					showTimePicker={false}
					isRequired={isRequired}
					datePickerProps={{
						name: input.label,
						value: moment(values[input.label] || null),
						onChange: onChange,
						calendarStyle: { top: "-200%", left: 0 },
						isRequired: isRequired,
						allowUnset: input.allowUnset || false,
					}}
				/>
			)
		default:
			return null
	}
}

SecSocInput.contextTypes = { t: PropTypes.func }
