import { simpleFetch } from "./index"
import moment from "moment"
import { stringifyQuery } from "../helpers/qs"
import * as actions from "../actions"

const TO_BE_SENT = "TO_BE_SENT"
const SENT = "SENT"
const ACRF_RECEIVED_OK = "ACRF_RECEIVED_OK"
const ACRF_RECEIVED_NOK = "ACRF_RECEIVED_NOK"
const NOTI_RECEIVED_OK = "NOTI_RECEIVED_OK"
const NOTI_RECEIVED_NOK = "NOTI_RECEIVED_NOK"

export const ONSS_STATUSES = [
	TO_BE_SENT,
	SENT,
	ACRF_RECEIVED_NOK,
	ACRF_RECEIVED_OK,
	NOTI_RECEIVED_NOK,
	NOTI_RECEIVED_OK,
]

export const RESET_ECONOMIC_UNEMPLOYMENT_STATE = "RESET_ECONOMIC_UNEMPLOYMENT_STATE"
export const resetEconomicSuspensionsState = () => ({
	type: RESET_ECONOMIC_UNEMPLOYMENT_STATE,
})

export const ECONOMIC_UNEMPLOYMENT_STATE_CHANGE = "ECONOMIC_UNEMPLOYMENT_STATE_CHANGE"
export const economicUnemploymentStateChange = changes => ({
	type: ECONOMIC_UNEMPLOYMENT_STATE_CHANGE,
	changes,
})

export const RESET_NEW_ECONOMIC_SUSPENSION_MODAL_STATE = "RESET_NEW_ECONOMIC_SUSPENSION_MODAL_STATE"
export const resetNewEconomicSuspensionsModalState = () => ({
	type: RESET_NEW_ECONOMIC_SUSPENSION_MODAL_STATE,
})

export const NEW_ECONOMIC_SUSPENSION_MODAL_STATE_CHANGE =
	"NEW_ECONOMIC_SUSPENSION_MODAL_STATE_CHANGE"
export const newEconomicSuspensionModalStateChange = changes => ({
	type: NEW_ECONOMIC_SUSPENSION_MODAL_STATE_CHANGE,
	changes,
})

export const FETCH_DIMONAS = "FETCH_DIMONAS"
export const FETCH_DIMONAS_REQUEST = "FETCH_DIMONAS_REQUEST"
export const FETCH_DIMONAS_SUCCESS = "FETCH_DIMONAS_SUCCESS"
export const FETCH_DIMONAS_FAILURE = "FETCH_DIMONAS_FAILURE"
export const fetchDimonas = (customQuery = {}) => (dispatch, getState) => {
	let {
		currentURLQuery: { realQuery },
	} = getState()
	let finalQuery = Object.keys(realQuery).reduce((acc, key) => {
		let value = realQuery[key]
		if (!value) return acc
		acc[key] = value
		if (moment.isMoment(value)) {
			acc[key] = value.format("YYYY-MM-DD")
		}
		return acc
	}, {})
	let init = { method: "GET" }
	let url = "/front/affiliates/dimonas" + stringifyQuery({ ...finalQuery, ...customQuery })
	return dispatch(simpleFetch(url, init, FETCH_DIMONAS))
}

export const DIMONAS_FILTERS = "DIMONAS_FILTERS"
export const DIMONAS_FILTERS_REQUEST = "DIMONAS_FILTERS_REQUEST"
export const DIMONAS_FILTERS_SUCCESS = "DIMONAS_FILTERS_SUCCESS"
export const DIMONAS_FILTERS_FAILURE = "DIMONAS_FILTERS_FAILURE"
export const fetchDimonasFilters = () => (dispatch, getState) => {
	let {
		redComponents: { dimonasFiltersComponent },
	} = getState()
	if (dimonasFiltersComponent.fetched) return
	let init = { method: "GET" }
	let url = "/front/affiliates/dimonas_filters"
	dispatch(simpleFetch(url, init, DIMONAS_FILTERS))
}

export const DELETE_DIMONA = "DELETE_DIMONA"
export const DELETE_DIMONA_REQUEST = "DELETE_DIMONA_REQUEST"
export const DELETE_DIMONA_SUCCESS = "DELETE_DIMONA_SUCCESS"
export const DELETE_DIMONA_FAILURE = "DELETE_DIMONA_FAILURE"
export const deleteDimona = id => async (dispatch, getState) => {
	let init = { method: "DELETE" }
	let url = "/front/affiliates/dimonas/" + id
	await dispatch(simpleFetch(url, init, DELETE_DIMONA))
	dispatch(fetchDimonas())
}

export const DIMONAS_STATE_CHANGE = "DIMONAS_STATE_CHANGE"
export const dimonasStateChange = changes => ({
	type: DIMONAS_STATE_CHANGE,
	changes,
})

export const DIMONAS_FILTERS_STATE_CHANGE = "DIMONAS_FILTERS_STATE_CHANGE"
export const dimonasFiltersStateChange = changes => ({
	type: DIMONAS_FILTERS_STATE_CHANGE,
	changes,
})

export const DOCUMENTS_STATE_CHANGE = "DOCUMENTS_STATE_CHANGE"
export const documentsStateChange = changes => ({
	type: DOCUMENTS_STATE_CHANGE,
	changes,
})

export const NEW_DIMONA_STATE_CHANGE = "NEW_DIMONA_STATE_CHANGE"
export const newDimonaStateChange = changes => ({ type: NEW_DIMONA_STATE_CHANGE, changes })

export const FETCH_POTENTIAL_CORRESPONDING_DIMONA_IN = "FETCH_POTENTIAL_CORRESPONDING_DIMONA_IN"
export const FETCH_POTENTIAL_CORRESPONDING_DIMONA_IN_REQUEST =
	"FETCH_POTENTIAL_CORRESPONDING_DIMONA_IN_REQUEST"
export const FETCH_POTENTIAL_CORRESPONDING_DIMONA_IN_SUCCESS =
	"FETCH_POTENTIAL_CORRESPONDING_DIMONA_IN_SUCCESS"
export const FETCH_POTENTIAL_CORRESPONDING_DIMONA_IN_FAILURE =
	"FETCH_POTENTIAL_CORRESPONDING_DIMONA_IN_FAILURE"
export const checkCorrespondingDimonaIn = () => (dispatch, getState) => {
	let {
		redComponents: {
			newDimonaModalComponent: { chosenWorker },
		},
	} = getState()
	dispatch(newDimonaStateChange({ onssRefNumber: "", potentialCorrespondingDimonaIn: [] }))
	let url =
		"/front/affiliates/dimonas" +
		stringifyQuery({
			filter_type: "IN",
			filter_dimona_out_presence: true,
			filter_affiliate_worker_ids: [chosenWorker.affiliateWorkerId],
		})
	return dispatch(simpleFetch(url, { method: "GET" }, FETCH_POTENTIAL_CORRESPONDING_DIMONA_IN))
}

export const processNewDimonaStateChange = changes => async (dispatch, getState) => {
	dispatch(actions.newDimonaStateChange(changes))
	let {
		redComponents: {
			newDimonaModalComponent: { onssRefNumber, dimonaType, chosenWorker },
		},
	} = getState()
	if (!onssRefNumber && dimonaType === "OUT" && chosenWorker.affiliateWorkerId) {
		dispatch(checkCorrespondingDimonaIn())
	}
}

export const RESET_NEW_DIMONA = "RESET_NEW_DIMONA"
export const resetDimonaModalState = changes => ({ type: RESET_NEW_DIMONA, changes })

export const SUBMIT_NEW_DIMONA = "SUBMIT_NEW_DIMONA"
export const SUBMIT_NEW_DIMONA_REQUEST = "SUBMIT_NEW_DIMONA_REQUEST"
export const SUBMIT_NEW_DIMONA_SUCCESS = "SUBMIT_NEW_DIMONA_SUCCESS"
export const SUBMIT_NEW_DIMONA_FAILURE = "SUBMIT_NEW_DIMONA_FAILURE"
export const submitNewDimona = () => async (dispatch, getState) => {
	let {
		redComponents: {
			newDimonaModalComponent: {
				dateOut,
				dateIn,
				commission,
				dimonaWorkerType,
				dimonaType,
				chosenWorker,
				afterContractCreation,
				onssRefNumber,
			},
		},
	} = getState()
	let date_out
	let date_in
	if (dimonaType === "OUT") {
		date_out = dateOut.format("YYYY-MM-DD")
		date_in = null
	} else {
		date_out = dateOut ? dateOut.format("YYYY-MM-DD") : null
		date_in = dateIn ? dateIn.format("YYYY-MM-DD") : null
	}

	let data = {
		chosen_worker_id: chosenWorker.affiliateWorkerId,
		date_in: date_in,
		date_out: date_out,
		commission: commission,
		onss_ref_number: onssRefNumber.replace(/ /g, ""),
		worker_type: dimonaWorkerType,
		dimona_type: dimonaType,
		after_contract_creation_id: afterContractCreation,
		create_dimona: true,
	}

	let init = { method: "POST", body: JSON.stringify(data) }
	let url = "/front/affiliates/dimonas"
	let response = await dispatch(simpleFetch(url, init, SUBMIT_NEW_DIMONA))
	if (response.actionType === SUBMIT_NEW_DIMONA_SUCCESS) {
		dispatch(resetDimonaModalState())
		dispatch(actions.fetchDimonas())
	}
	return response
}

export const DO_NOT_CREATE_NEW_DIMONA = "DO_NOT_CREATE_NEW_DIMONA"
export const DO_NOT_CREATE_NEW_DIMONA_REQUEST = "DO_NOT_CREATE_NEW_DIMONA_REQUEST"
export const DO_NOT_CREATE_NEW_DIMONA_SUCCESS = "DO_NOT_CREATE_NEW_DIMONA_SUCCESS"
export const DO_NOT_CREATE_NEW_DIMONA_FAILURE = "DO_NOT_CREATE_NEW_DIMONA_FAILURE"
export const doNotCreateDimona = () => async (dispatch, getState) => {
	let {
		redComponents: {
			newDimonaModalComponent: { afterContractCreation },
		},
	} = getState()
	let init = {
		method: "POST",
		body: JSON.stringify({
			create_dimona: false,
			after_contract_creation_id: afterContractCreation,
		}),
	}
	let url = "/front/affiliates/dimonas"
	let { actionType } = await dispatch(simpleFetch(url, init, SUBMIT_NEW_DIMONA))
	if (actionType === DO_NOT_CREATE_NEW_DIMONA_SUCCESS) {
		dispatch(resetDimonaModalState())
		dispatch(actions.fetchDimonas())
	}
}

export const UNEMPLOYMENT_DECLARATIONS_STATE_CHANGE = "UNEMPLOYMENT_DECLARATIONS_STATE_CHANGE"
export const unemploymentDeclarationsStateChange = changes => ({
	type: UNEMPLOYMENT_DECLARATIONS_STATE_CHANGE,
	changes,
})

export const FETCH_UNEMPLOYMENT_DECLARATIONS = "FETCH_UNEMPLOYMENT_DECLARATIONS"
export const FETCH_UNEMPLOYMENT_DECLARATIONS_REQUEST = "FETCH_UNEMPLOYMENT_DECLARATIONS_REQUEST"
export const FETCH_UNEMPLOYMENT_DECLARATIONS_SUCCESS = "FETCH_UNEMPLOYMENT_DECLARATIONS_SUCCESS"
export const FETCH_UNEMPLOYMENT_DECLARATIONS_FAILURE = "FETCH_UNEMPLOYMENT_DECLARATIONS_FAILURE"
export const fetchUnemploymentDeclarations = () => (dispatch, getState) => {
	let init = { method: "GET" }
	let {
		redComponents: {
			unemploymentDeclarationsComponent: {
				page,
				rangeStart,
				rangeEnd,
				searchedAffiliateWorkerId,
				status,
				squadsFilter,
			},
		},
	} = getState()
	let params = {
		page: page,
		start_date: rangeStart ? rangeStart.format("YYYY-MM-DD") : null,
		end_date: rangeEnd ? rangeEnd.format("YYYY-MM-DD") : null,
		affiliate_worker_id: searchedAffiliateWorkerId,
		status: status,
		squad_ids: squadsFilter,
	}
	let url = "/front/affiliates/economic_unemployment_declarations" + stringifyQuery(params)
	return dispatch(simpleFetch(url, init, FETCH_UNEMPLOYMENT_DECLARATIONS))
}

export const CANCEL_UNEMPLOYMENT_DECLARATION = "CANCEL_UNEMPLOYMENT_DECLARATION"
export const CANCEL_UNEMPLOYMENT_DECLARATION_REQUEST = "CANCEL_UNEMPLOYMENT_DECLARATION_REQUEST"
export const CANCEL_UNEMPLOYMENT_DECLARATION_SUCCESS = "CANCEL_UNEMPLOYMENT_DECLARATION_SUCCESS"
export const CANCEL_UNEMPLOYMENT_DECLARATION_FAILURE = "CANCEL_UNEMPLOYMENT_DECLARATION_FAILURE"
export const submitCancelUd = () => (dispatch, getState) => {
	let {
		redComponents: {
			unemploymentDeclarationsComponent: { udStagedForCancelling },
		},
	} = getState()
	let url =
		"/front/affiliates/economic_unemployment_declarations/" + udStagedForCancelling + "/cancel"
	return dispatch(simpleFetch(url, { method: "GET" }, CANCEL_UNEMPLOYMENT_DECLARATION))
}

export const UNEMPLOYMENT_BOOKS_STATE_CHANGE = "UNEMPLOYMENT_BOOKS_STATE_CHANGE"
export const unemploymentBooksStateChange = changes => ({
	type: UNEMPLOYMENT_BOOKS_STATE_CHANGE,
	changes,
})

export const FETCH_UNEMPLOYMENT_BOOKS = "FETCH_UNEMPLOYMENT_BOOKS"
export const FETCH_UNEMPLOYMENT_BOOKS_REQUEST = "FETCH_UNEMPLOYMENT_BOOKS_REQUEST"
export const FETCH_UNEMPLOYMENT_BOOKS_SUCCESS = "FETCH_UNEMPLOYMENT_BOOKS_SUCCESS"
export const FETCH_UNEMPLOYMENT_BOOKS_FAILURE = "FETCH_UNEMPLOYMENT_BOOKS_FAILURE"
export const fetchUnemploymentBooks = () => (dispatch, getState) => {
	let init = { method: "GET" }
	let {
		redComponents: {
			unemploymentBooksComponent: {
				rangeStart,
				rangeEnd,
				searchedAffiliateWorkerId,
				status,
				squadsFilter,
			},
		},
	} = getState()
	let params = {
		start_date: rangeStart ? rangeStart.format("YYYY-MM-DD") : null,
		end_date: rangeEnd ? rangeEnd.format("YYYY-MM-DD") : null,
		affiliate_worker_id: searchedAffiliateWorkerId,
		status: status,
		squad_ids: squadsFilter,
	}
	let url = "/front/affiliates/economic_unemployment_books" + stringifyQuery(params)
	return dispatch(simpleFetch(url, init, FETCH_UNEMPLOYMENT_BOOKS))
}

export const FETCH_ECONOMIC_SUSPENSIONS = "FETCH_ECONOMIC_SUSPENSIONS"
export const FETCH_ECONOMIC_SUSPENSIONS_REQUEST = "FETCH_ECONOMIC_SUSPENSIONS_REQUEST"
export const FETCH_ECONOMIC_SUSPENSIONS_SUCCESS = "FETCH_ECONOMIC_SUSPENSIONS_SUCCESS"
export const FETCH_ECONOMIC_SUSPENSIONS_FAILURE = "FETCH_ECONOMIC_SUSPENSIONS_FAILURE"
export const fetchEconomicSuspensions = () => (dispatch, getState) => {
	let init = { method: "GET" }
	let {
		redComponents: {
			economicSuspensionsComponent: {
				rangeStart,
				rangeEnd,
				searchedAffiliateWorkerId,
				status,
				squadsFilter,
			},
		},
	} = getState()
	let params = {
		start_date: rangeStart ? rangeStart.format("YYYY-MM-DD") : null,
		end_date: rangeEnd ? rangeEnd.format("YYYY-MM-DD") : null,
		affiliate_worker_id: searchedAffiliateWorkerId,
		status: status,
		squad_ids: squadsFilter,
	}
	let url = "/front/affiliates/economic_suspensions" + stringifyQuery(params)
	return dispatch(simpleFetch(url, init, FETCH_ECONOMIC_SUSPENSIONS))
}

export const SUBMIT_BIG_ECONOMIC_SUSPENSION = "SUBMIT_BIG_ECONOMIC_SUSPENSION"
export const SUBMIT_BIG_ECONOMIC_SUSPENSION_REQUEST = "SUBMIT_BIG_ECONOMIC_SUSPENSION_REQUEST"
export const SUBMIT_BIG_ECONOMIC_SUSPENSION_SUCCESS = "SUBMIT_BIG_ECONOMIC_SUSPENSION_SUCCESS"
export const SUBMIT_BIG_ECONOMIC_SUSPENSION_FAILURE = "SUBMIT_BIG_ECONOMIC_SUSPENSION_FAILURE"
export const createSuspension = () => async (dispatch, getState) => {
	let {
		redComponents: {
			newEconomicSuspensionsModalComponent: {
				startDate,
				endDate,
				chosenWorker,
				suspensionType,
				declarationOnHonour,
				jointCommissionLevel,
			},
		},
	} = getState()
	let postParams = {
		economic_suspension_type: suspensionType,
		affiliate_worker_id: chosenWorker.affiliateWorkerId,
		start_date: startDate && startDate.isValid() ? startDate.format("YYYY-MM-DD") : undefined,
		end_date: endDate && endDate.isValid() ? endDate.format("YYYY-MM-DD") : undefined,
		declaration_on_honour: declarationOnHonour,
		joint_commission_level: jointCommissionLevel,
	}
	let init = {
		method: "POST",
		body: JSON.stringify(postParams),
	}
	let url = "/front/affiliates/economic_suspensions"
	await dispatch(simpleFetch(url, init, SUBMIT_BIG_ECONOMIC_SUSPENSION))
	dispatch(fetchEconomicSuspensions())
	dispatch(
		economicUnemploymentStateChange({
			showSmallSuspensionModal: false,
			showBigSuspensionModal: false,
		})
	)
}

export const SUBMIT_BATCH_ECONOMIC_SUSPENSION = "SUBMIT_BATCH_ECONOMIC_SUSPENSION"
export const SUBMIT_BATCH_ECONOMIC_SUSPENSION_REQUEST = "SUBMIT_BATCH_ECONOMIC_SUSPENSION_REQUEST"
export const SUBMIT_BATCH_ECONOMIC_SUSPENSION_SUCCESS = "SUBMIT_BATCH_ECONOMIC_SUSPENSION_SUCCESS"
export const SUBMIT_BATCH_ECONOMIC_SUSPENSION_FAILURE = "SUBMIT_BATCH_ECONOMIC_SUSPENSION_FAILURE"
export const batchCreateSuspensions = ({ workers, suspension }) => async (dispatch, getState) => {
	let postParams = {
		affiliate_worker_ids: workers.map(worker => worker.id),
		economic_suspension: {
			declaration_on_honour: suspension.declarationOnHonour,
			economic_suspension_type: suspension.suspensionType,
			start_date:
				suspension.startDate && suspension.startDate.isValid()
					? suspension.startDate.format("YYYY-MM-DD")
					: undefined,
			end_date:
				suspension.endDate && suspension.endDate.isValid()
					? suspension.endDate.format("YYYY-MM-DD")
					: undefined,
			joint_commission_level: suspension.jointCommissionLevel || "000",
		},
	}
	let init = {
		method: "POST",
		body: JSON.stringify(postParams),
	}
	let url = "/front/affiliates/economic_suspensions/batch_create"
	await dispatch(simpleFetch(url, init, SUBMIT_BATCH_ECONOMIC_SUSPENSION))
	dispatch(fetchEconomicSuspensions())
	dispatch(economicUnemploymentStateChange({ showEcoSuspModalForBatchCreation: false }))
}

export const FETCH_ECONOMIC_SUSPENSION_PDF = "FETCH_ECONOMIC_SUSPENSION_PDF"
export const FETCH_ECONOMIC_SUSPENSION_PDF_REQUEST = "FETCH_ECONOMIC_SUSPENSION_PDF_REQUEST"
export const FETCH_ECONOMIC_SUSPENSION_PDF_SUCCESS = "FETCH_ECONOMIC_SUSPENSION_PDF_SUCCESS"
export const FETCH_ECONOMIC_SUSPENSION_PDF_FAILURE = "FETCH_ECONOMIC_SUSPENSION_PDF_FAILURE"
export const fetchEconomicSuspensionPDF = id => (dispatch, getState) => {
	let url = "/front/affiliates/economic_suspensions/download_pdf"
	let init = {
		method: "POST",
		body: JSON.stringify({ id }),
	}
	dispatch(simpleFetch(url, init, FETCH_ECONOMIC_SUSPENSION_PDF))
}

export const FETCH_DECLARATION_PDF = "FETCH_DECLARATION_PDF"
export const FETCH_DECLARATION_PDF_REQUEST = "FETCH_DECLARATION_PDF_REQUEST"
export const FETCH_DECLARATION_PDF_SUCCESS = "FETCH_DECLARATION_PDF_SUCCESS"
export const FETCH_DECLARATION_PDF_FAILURE = "FETCH_DECLARATION_PDF_FAILURE"
export const fetchDeclarationPDF = id => (dispatch, getState) => {
	let url = "/front/affiliates/economic_unemployment_declarations/download_pdf"
	let init = {
		method: "POST",
		body: JSON.stringify({ id }),
	}
	dispatch(simpleFetch(url, init, FETCH_DECLARATION_PDF))
}
