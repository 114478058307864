let groupS = require("./social-secretaries-fields/groupS")
let hdp = require("./social-secretaries-fields/hdp")
let acerta = require("./social-secretaries-fields/acerta")
let securex = require("./social-secretaries-fields/securex")
let partena = require("./social-secretaries-fields/partena")
let ucm = require("./social-secretaries-fields/ucm")
let sdWorx = require("./social-secretaries-fields/sdWorx")
export const translationPrefixes = {
	Acerta: "acerta_",
	Securex: "securex_",
	Partena: "sam_",
	UCM: "ucm_",
}
export const socialSecretariesFields = {
	"Group S": groupS.default,
	"Partena HDP": hdp.default,
	Acerta: acerta.default,
	Pootsy: hdp.default,
	Securex: securex.default,
	Partena: partena.default,
	UCM: ucm.default,
	SDWorx: sdWorx.default,
}
