import React from "react"



const Tooltip = ({ tooltipText }) => {
	return <div className="gIcon-tooltip">{tooltipText}</div>
}

export const AddIcon = ({ onClick }) => (
	<svg className="gIcon AddIcon" viewBox="0 0 24 24" onClick={onClick}>
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
	</svg>
)

export const PersonOutlineIcon = ({ onClick, active, customClass = "" }) => (
	<svg
		className={"gIcon PersonOutlineIcon " + customClass + (active ? " active" : "")}
		viewBox="0 0 24 24"
		onClick={onClick}
	>
		<path d="M12 5.9a2.1 2.1 0 1 1 0 4.2 2.1 2.1 0 0 1 0-4.2m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z" />
		<path d="M0 0h24v24H0z" fill="none" />
	</svg>
)

export const BinIcon = ({ onClick }) => (
	<svg className="gIcon BinIcon " viewBox="0 0 24 24" onClick={onClick}>
		<path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
		<path d="M0 0h24v24H0z" fill="none" />
	</svg>
)

export const EventIcon = ({ onClick, active, customClass = "" }) => (
	<svg
		className={"gIcon EventIcon " + customClass + (active ? " active" : "")}
		viewBox="0 0 24 24"
		onClick={onClick}
	>
		<path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z" />
		<path d="M0 0h24v24H0z" fill="none" />
	</svg>
)

export const NotifIcon = ({ unreadCount, onClick }) => (
	<div className="NotifIcon" onClick={onClick}>
		{unreadCount !== 0 && <div className="unread-count">{unreadCount}</div>}
		<svg className="notification-icon-svg" viewBox="0 0 24 24">
			<path d="M0 0h24v24H0z" fill="none" />
			<path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z" />
		</svg>
	</div>
)

export const PeopleIcon = ({ onClick, active, customClass = "" }) => (
	<svg
		className={"gIcon PeopleIcon " + customClass + (active ? " active" : "")}
		viewBox="0 0 24 24"
		onClick={onClick}
	>
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z" />
	</svg>
)

export const PersonIcon = ({ onClick, active, customClass = "" }) => (
	<svg
		className={"gIcon PersonIcon " + customClass + (active ? " active" : "")}
		viewBox="0 0 24 24"
		onClick={onClick}
	>
		<path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
		<path d="M0 0h24v24H0z" fill="none" />
	</svg>
)

export const TasksIcon = ({ unreadCount, onClick }) => (
	<div className="TasksIcon" onClick={onClick}>
		{unreadCount !== 0 && <div className="unread-count">{unreadCount}</div>}
		<svg className="tasks-icon-svg" viewBox="0 0 24 24">
			<path d="M0 0h24v24H0z" fill="none" />
			<path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z" />
		</svg>
	</div>
)

export const PersonPinIcon = ({ active, onClick, customClass = "", tooltipText }) => (
	<div className="gIcon-wrapper">
		<svg
			className={"gIcon PersonPin " + customClass + (active ? " active" : "")}
			viewBox="0 0 24 24"
			onClick={onClick}
		>
			<path d="M12 2a9 9 0 0 0-9 9c0 4.17 2.84 7.67 6.69 8.69L12 22l2.31-2.31C18.16 18.67 21 15.17 21 11a9 9 0 0 0-9-9zm0 2c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.3a7.2 7.2 0 0 1-6-3.22c.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08a7.2 7.2 0 0 1-6 3.22z" />
			<path fill="none" d="M0 0h24v24H0z" />
		</svg>
		{tooltipText && <Tooltip tooltipText={tooltipText} />}
	</div>
)

export const CategoryIcon = ({ active, onClick, customClass = "", tooltipText }) => (
	<div className="gIcon-wrapper">
		<svg
			className={"gIcon CategoryIcon " + customClass + (active ? " active" : "")}
			viewBox="0 0 24 24"
			onClick={onClick}
		>
			<path d="M12 2l-5.5 9h11z" />
			<circle cx={17.5} cy={17.5} r={4.5} />
			<path d="M3 13.5h8v8H3z" />
			<path fill="none" d="M0 0h24v24H0z" />
		</svg>
		{tooltipText && <Tooltip tooltipText={tooltipText} />}
	</div>
)

export const FilterIcon = ({ active, onClick, customClass = "", tooltipText }) => (
	<div className="gIcon-wrapper">
		<svg
			className={"gIcon FilterIcon " + customClass + (active ? " active" : "")}
			viewBox="0 0 24 24"
			onClick={onClick}
		>
			<path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
			<path d="M0 0h24v24H0z" fill="none" />
		</svg>
		{tooltipText && <Tooltip tooltipText={tooltipText} />}
	</div>
)
