import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import {
	PootsyTable,
	OptionBox,
	ContentLayout,
	NavLinkArrowBack,
	LabeledDateTime,
	PootsyButton,
	PageLayout,
} from "./FunctionalDesign"

import CompleteMonthPicker from "./CompleteMonthPicker"
import { PootsyTextInput, PootsySelectInput } from "./FunctionalInputs"
import * as actions from "../actions"

import moment from "moment"

class NewPSVGroup extends Component {
	state = {
		sodexoRegionLabel: "",
		sodexoRegionValue: "",
	}
	UNSAFE_componentWillMount() {
		this.props.dispatch(actions.fetchAvailablePSVForDeliveryForNew())
	}
	handleChanges = changes => {
		this.props.dispatch(actions.newPaperDeliveryStateChange(changes))
		this.filterPSVScreation()
	}
	createDelivery = async () => {
		let {
			component: { monthStartFilter, monthStartGroup, monthEndFilter },
		} = this.props
		let month_start_filter = monthStartFilter
			? monthStartFilter.format("YYYY-MM-DD")
			: monthStartGroup.format("YYYY-MM-DD")
		let month_end_filter = monthEndFilter ? monthEndFilter.format("YYYY-MM-DD") : null

		let { data } = await this.props.dispatch(
			actions.createNewPaperDelivery({
				month_start_filter: month_start_filter,
				sodexo_region: this.state.sodexoRegionValue,
				squad_id: this.props.component.squad,
				month_end_filter: month_end_filter,
				month_start: this.props.component.monthStartGroup.format("YYYY-MM-DD"),
				subject_type: this.props.component.subjectType,
			})
		)

		this.props.history.push({
			pathname: "/paper_voucher_delivery_group/" + data.data.id,
		})
	}
	yearMonths = () => {
		let year = moment().year()
		let array = []
		while (year >= 2019) {
			let y = year
			array = array.concat(
				[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
					.reverse()
					.map(entry => moment({ date: 1, month: entry, year: y }))
			)
			year = year - 1
		}
		return array
	}
	filterPSVScreation = () => {
		this.props.dispatch(actions.fetchAvailablePSVForDeliveryForNew())
	}
	render() {
		let { t } = this.context
		let { sodexoRegionValue, sodexoRegionLabel } = this.state
		let {
			currAffiliate,
			component: {
				monthStartFilter,
				monthEndFilter,
				squad,
				subjectType,
				monthStartGroup,
				monthEnd,
				newPSVDelivery,
			},
		} = this.props
		let disabledButton = !(sodexoRegionValue && monthStartGroup)
		let pageHeaderLeft = (
			<div className="page-header-left">
				<NavLinkArrowBack to={"/sodexo/exports"} />
				<h1 className="page-title">{t("new_return_slip")}</h1>
			</div>
		)
		let translate_regions = {
			[`${t("wallonia")}`]: "Wallonie",
			[`${t("flanders")}`]: "Vlaanderen",
			[`${t("brussels")}`]: "Bruxelles",
		}
		let optionsBoxes = (
			<OptionBox boxTitle={t("sodexo_info")}>
				<div className="dates">
					<LabeledDateTime
						showTimePicker={false}
						label={t("start_date")}
						value={monthStartFilter}
						datePickerProps={{
							name: "monthStartFilter",
							value: monthStartFilter,
							onChange: this.handleChanges,
							calendarStyle: { transform: `scale(.8) translate(-50px, -33px)` },
						}}
					/>

					<LabeledDateTime
						showTimePicker={false}
						label={t("end_date")}
						value={monthEndFilter}
						datePickerProps={{
							name: "monthEndFilter",
							value: monthEndFilter,
							onChange: this.handleChanges,
							calendarStyle: { transform: `scale(.8) translate(-171px, -32px)` },
						}}
					/>
				</div>
				{currAffiliate.squads && (
					<PootsySelectInput
						name="squad"
						defaultText={t("squad")}
						selected={squad}
						allowEmpty={true}
						options={currAffiliate.squads.map(e => ({
							label: e.name,
							value: e.id,
						}))}
						onChange={e => {
							this.handleChanges({ squad: e.target.value })
						}}
					/>
				)}
				<PootsySelectInput
					name="subject_type"
					defaultText={t("subject_type")}
					selected={subjectType}
					allowEmpty={true}
					options={["booking", "laundry_order"].map(e => ({
						label: t(e),
						value: e,
					}))}
					onChange={e => {
						this.handleChanges({ subjectType: e.target.value })
					}}
				/>

				<PootsyButton
					text={`${t("create")} ${t("return_slip")}`}
					onClick={this.createDelivery}
					disabled={disabledButton}
				/>
			</OptionBox>
		)
		return (
			<PageLayout headerLeft={pageHeaderLeft} customClass="NewPSVGroup">
				<ContentLayout optionsBoxes={optionsBoxes}>
					<div className="create-delivery-line">
						{monthEnd && <PootsyTextInput value={monthEnd.format("MMM YYYY")} />}
						<PootsySelectInput
							defaultText={t("region")}
							name="region"
							options={[
								{ label: t("brussels"), value: "73" },
								{ label: t("flanders"), value: "71" },
								{ value: "72", label: t("wallonia") },
							]}
							selected={sodexoRegionValue}
							onChange={event => {
								let label = event.target.querySelector(
									`option[value='${event.target.value}']`
								).text
								let value = event.target.value
								this.setState({
									sodexoRegionLabel: label,
									sodexoRegionValue: value,
								})
								// let dateStart = monthStartFilter.format()
							}}
						/>
						<CompleteMonthPicker
							currentDate={monthStartGroup}
							filter={m => m.isSameOrBefore(moment(), "year")}
							onChange={m => {
								this.handleChanges({ monthStartGroup: m })
							}}
						/>
						<div className="sv-to-be-grouped-count">
							{sodexoRegionValue && monthStartGroup && (
								<Fragment>
									<div className="label">{t("sv_in_delivery_group_count")}</div>
									<div className="value">
										{
											newPSVDelivery[translate_regions[sodexoRegionLabel]][
												"total_to_send"
											]
										}
									</div>
								</Fragment>
							)}
						</div>
					</div>
					<PootsyTable
						title={t("return_slips")}
						labels={[t("region"), t("total_sent"), t("total_to_send")]}
						rows={Object.entries(newPSVDelivery).map(([key, value]) => ({
							columns: [t(key), value.total_sent, value.total_to_send],
						}))}
					/>
				</ContentLayout>
			</PageLayout>
		)
	}
}

NewPSVGroup.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.newPaperDeliveryComponent,
	currAffiliate: state.redData.currentAffiliate,
})
export default connect(mapStateToProps)(NewPSVGroup)
