import { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"

class I18n extends Component {
	missingKeys = [] // Because of the way missing keys are collected, they cannot be kept in the regular state
	lastLog = moment()
	state = {
		debug: false,
	}
	getChildContext = () => {
		return { t: this.renderTranslation }
	}
	componentDidMount = async () => {
		await this.setDebugMode()
		this.validateKeyPresence()
	}
	setDebugMode = () => {
		if (false && process.env.NODE_ENV === "development") this.setState({ debug: true })
	}
	validateKeyPresence = () => {
		if (!this.state.debug) {
			return
		}
		let {
			data: { translations },
		} = this.props
		let langKeys = Object.keys(translations)
		let allTKeys = langKeys.reduce((acc, langKey) => {
			Object.keys(translations[langKey]).forEach(tKey => {
				if (acc.indexOf(tKey) === -1) {
					acc.push(tKey)
				}
			})
			return acc
		}, [])
		let keysNotTranslatedEverywhere = {}
		allTKeys.forEach(tKey => {
			let missingLangs = []
			langKeys.forEach(langKey => {
				if (!translations[langKey][tKey]) {
					missingLangs.push(langKey)
				}
			})
			if (missingLangs.length > 0) {
				keysNotTranslatedEverywhere[tKey] =
					" is missing from language object " + missingLangs.join(" and ")
			}
		})
		console.groupCollapsed("Keys missing from languages")
		console.log(JSON.stringify(keysNotTranslatedEverywhere))
		console.groupEnd()
	}
	renderTranslation = (key, inserts, langOverride) => {
		let {
			data: { lang, translations },
		} = this.props
		let l = langOverride || lang
		let string = translations[l][key]
		if (!string) {
			this.processMissingKey(key)
			return key
		}
		string = this.interpolateInserts(string, inserts)
		return string
	}
	interpolateInserts = (string, inserts) => {
		if (inserts === undefined) {
			return string
		}
		const children = string.split(/({[^}]+})/g).map(child => {
			const match = /{(.+)}/g.exec(child)
			if (match) {
				const insert = inserts[match[1]]
				return insert ? insert : String(insert)
			}
			return child
		})
		return children.join("")
	}
	processMissingKey = missingKey => {
		let { debug } = this.state
		let {
			data: { translations },
		} = this.props
		if (!this.missingKeys.some(entry => entry.key === missingKey)) {
			let langsMissingKey = Object.keys(translations).reduce((acc, langKey) => {
				if (!translations[langKey][missingKey]) {
					acc.push(langKey)
				}
				return acc
			}, [])
			this.missingKeys = [...this.missingKeys, { key: missingKey, langs: langsMissingKey }]
			if (debug) {
				this.printMissingKeys()
			}
		}
	}
	printMissingKeys = () => {
		console.warn(
			"The following keys are missing\n",
			this.missingKeys
				.map(entry => `- key: \`${entry.key}\` for langs \`${entry.langs.join(" ")}\`\n`)
				.join("")
		)
	}
	render() {
		return this.props.children
	}
}

I18n.childContextTypes = {
	t: PropTypes.func.isRequired,
}
const mapStateToProps = state => ({
	data: state.locale,
	auth: state.auth,
})
export default connect(mapStateToProps)(I18n)
