import { handleEditWorkerContract, contractV2UIChange } from "./index"
import { isOverlapping } from "../helpers/isOverlapping"
import moment from "moment"

// export const WORKER_SCHEDULE_UI_CHANGE = "WORKER_SCHEDULE_UI_CHANGE"
// export const workertimesheetUIChange = changes => ({ type: WORKER_SCHEDULE_UI_CHANGE, changes })
const NO_TH_VARIABILITIES = ["variable", "variable_variable"]

export const PDF_WORKER_SCHEDULE_STATE_CHANGE = "PDF_WORKER_SCHEDULE_STATE_CHANGE"
export const pDFWorkerScheduleStateChange = changes => ({
	type: PDF_WORKER_SCHEDULE_STATE_CHANGE,
	changes,
})

const timeRangesKeysMap = {
	avail: "availabilities",
	"theo-hour": "theoreticalHours",
	"other-act": "otherActivities",
	"unsaved-booking": "unsavedBookings",
}
const associationArrayMap = {
	avail: "managedAvails",
	"theo-hour": "managedTheoHours",
	"other-act": "managedOtherActivities",
}

export const newTimerangeObject = ({
	id = Math.floor(Math.random() * 1000000000000),
	type,
	startDate,
	start,
	end,
	allowEdition,
	allowDeletion,
	dayPeriod,
	fitWhenOverlap = false,
	...rest
}) => {
	return {
		id,
		duration: Math.round(end.diff(start, "seconds") / 60),
		type,
		allowEdition,
		allowDeletion,
		startDate,
		start: start.set({ year: 2000, month: 0, date: 1, seconds: 0 }),
		end: end.set({ year: 2000, month: 0, date: 1, seconds: 0 }),
		dayPeriod,
		fitWhenOverlap,
		...rest,
	}
}

export const isTimerangeForSchedule = ({ timerange, schedulePeriod, schedulePeriods }) => {
	if (timerange.dayPeriod === 7 || schedulePeriods.length === 1) {
		return true
	}
	let diff = timerange.startDate.diff(schedulePeriod.startDate, "days")
	let offset = diff % 14
	// return offset >= 0 && offset < 7
	if (timerange.startDate.isSameOrAfter(schedulePeriod.startDate, "day")) {
		return offset >= 0 && offset < 7
	} else {
		return offset === 0 || (offset < -7 && offset > -14) // schedulePeriod will apply "two weeks ago" from its start date
	}
}

export const schedulePeriodApplies = ({ schedulePeriod, startDate, dayPeriod }) => {
	// needs to take 4 cases into account:
	// - 2 schedulePeriods, their dayPeriods are both 14
	//	- other dayPeriod = 7
	//	- other dayPeriod = 14
	// - 1 schedulePeriods, its dayPeriod is 7
	//	- other dayPeriod = 7
	//	- other dayPeriod = 14
	let diff = startDate.diff(schedulePeriod.startDate, "days")
	let offset = diff % dayPeriod
	return schedulePeriod.dayPeriod === 7 || dayPeriod === 7 || (offset >= 0 && offset < 7)
}

const mergeOrAddTimerange = ({ changes, newTimeRange, schedulePeriodID }) => {
	let timeRangesKey = timeRangesKeysMap[newTimeRange.type]
	let associationArrayKey = associationArrayMap[newTimeRange.type]
	let dayTimeRanges = changes[timeRangesKey].filter(entry =>
		entry.startDate.isSame(newTimeRange.startDate, "day")
	)
	let { timeRangeWithinAnother, overlappingEntryIndices } = isOverlapping(
		dayTimeRanges,
		newTimeRange
	)
	if (timeRangeWithinAnother) {
		return changes
	}
	changes[timeRangesKey] = [...changes[timeRangesKey], newTimeRange]
	changes.schedulePeriods = changes.schedulePeriods.map(entry => {
		if (entry.id === schedulePeriodID) {
			return {
				...entry,
				[associationArrayKey]: [...entry[associationArrayKey], newTimeRange.id],
			}
		}
		return entry
	})
	if (overlappingEntryIndices.length > 0) {
		// isOverlapping takes care of modifying the timeRange compared to merge it with an overlapping one
		// It's now possible to remove the old overlapping one
		let overlappingEntryIDs = overlappingEntryIndices.map(entry => dayTimeRanges[entry].id)
		changes[timeRangesKey] = changes[timeRangesKey].filter(
			entry => !overlappingEntryIDs.includes(entry.id)
		)
		changes.schedulePeriods = changes.schedulePeriods.map(entry => {
			if (entry.id === schedulePeriodID) {
				return {
					...entry,
					[associationArrayKey]: entry[associationArrayKey].filter(
						entry => !overlappingEntryIDs.includes(entry.id)
					),
				}
			}
			return entry
		})
	}
	return changes
}

const ensureCoveringTimerange = ({ changes, timeRangeToCover, schedulePeriodID, contract }) => {
	let rangeTypesToEnsure = []
	switch (timeRangeToCover.type) {
		case "avail":
			rangeTypesToEnsure = []
			break
		case "theo-hour":
			rangeTypesToEnsure = ["avail"]
			break
		case "other-act":
		case "unsaved-booking":
			rangeTypesToEnsure = ["avail"]
			if (!NO_TH_VARIABILITIES.includes(contract.contractVariability)) {
				rangeTypesToEnsure.push("theo-hour")
			}
			break
		default:
			break
	}

	rangeTypesToEnsure.forEach(type => {
		let containingTimeranges = changes[timeRangesKeysMap[type]].filter(
			entry =>
				entry.startDate.isSame(timeRangeToCover.startDate, "day") &&
				entry.start.isSameOrBefore(timeRangeToCover.start) &&
				entry.end.isSameOrAfter(timeRangeToCover.end)
		)
		if (containingTimeranges.length === 0) {
			let newTimeRange = newTimerangeObject({
				type,
				startDate: moment(timeRangeToCover.startDate),
				start: moment(timeRangeToCover.start),
				end: moment(timeRangeToCover.end),
				allowEdition: true,
				allowDeletion: true,
			})
			changes = mergeOrAddTimerange({ changes, schedulePeriodID, newTimeRange })
		}
	})

	return changes
}

export const processAppliedScheduleChange = (schedulePeriodID, changes) => (dispatch, getState) => {
	let {
		redData: { workerContracts },
		redComponents: { workerContractComponent },
	} = getState()
	let contract = workerContracts.find(
		entry => entry.id === workerContractComponent.selectedContractID
	)
	let newSchedulePeriods = contract.schedulePeriods.map(entry => {
		if (entry.id === schedulePeriodID) {
			return { ...entry, ...changes }
		}
		return entry
	})
	dispatch(handleEditWorkerContract({ schedulePeriods: newSchedulePeriods }))
}

const addAvailability = ({ contract, schedulePeriodID, newAvailability }) => (
	dispatch,
	getState
) => {
	let changes = {
		schedulePeriods: [...contract.schedulePeriods],
		availabilities: [...contract.availabilities],
	}

	changes = mergeOrAddTimerange({ changes, schedulePeriodID, newTimeRange: newAvailability })
	changes = ensureCoveringTimerange({
		changes,
		schedulePeriodID,
		timeRangeToCover: newAvailability,
		contract,
	})
	dispatch(handleEditWorkerContract(changes))
}

export const addTheoreticalHour = ({ contract, schedulePeriodID, newTheoreticalHour }) => (
	dispatch,
	getState
) => {
	let changes = {
		schedulePeriods: [...contract.schedulePeriods],
		availabilities: [...contract.availabilities],
		theoreticalHours: [...contract.theoreticalHours],
	}

	changes = mergeOrAddTimerange({ changes, schedulePeriodID, newTimeRange: newTheoreticalHour })
	changes = ensureCoveringTimerange({
		changes,
		schedulePeriodID,
		timeRangeToCover: newTheoreticalHour,
		contract,
	})
	return dispatch(handleEditWorkerContract(changes))
}

export const addOtherActivity = ({ contract, newOtherActivity }) => async (dispatch, getState) => {
	let {
		redComponents: { workerContractComponent },
	} = getState()

	let changes = {
		schedulePeriods: [...contract.schedulePeriods],
		availabilities: [...contract.availabilities],
		theoreticalHours: [...contract.theoreticalHours],
		otherActivities: [...contract.otherActivities],
	}

	// The filtered dates in the OtherActivityModal should prevent ever having more than
	// _one_ applied schedule here...
	let appliedSchedulePeriods = contract.schedulePeriods.filter(schedulePeriod => {
		return schedulePeriodApplies({
			schedulePeriod,
			startDate: newOtherActivity.startDate,
			dayPeriod: newOtherActivity.dayPeriod,
		})
	})

	for (let i = 0; i < appliedSchedulePeriods.length; i++) {
		// Can't use ensureCoveringTimerange here because
		// it will copy the startDate of the originally added timerange,
		// OAs's startDates are not bound like avails & theo-hours so it needs adjusting...

		let sp = appliedSchedulePeriods[i]

		mergeOrAddTimerange({ changes, schedulePeriodID: sp.id, newTimeRange: newOtherActivity })
		dispatch(handleEditWorkerContract(changes))

		let contract = getState().redData.workerContracts.find(
			entry => entry.id === workerContractComponent.selectedContractID
		)
		if (NO_TH_VARIABILITIES.includes(contract.contractVariability)) {
			return
		}
		await dispatch(
			addTheoreticalHour({
				contract,
				schedulePeriodID: sp.id,
				newTheoreticalHour: newTimerangeObject({
					type: "theo-hour",
					startDate: moment(sp.startDate).isoWeekday(
						newOtherActivity.startDate.isoWeekday()
					),
					start: moment(newOtherActivity.start),
					end: moment(newOtherActivity.end),
					allowEdition: true,
					allowDeletion: true,
				}),
			})
		)
	}
}

export const addScheduleTimeRange = (newTimeRange, schedulePeriodID) => (dispatch, getState) => {
	let {
		redData: { workerContracts },
		redComponents: { workerContractComponent },
	} = getState()
	let contract = workerContracts.find(
		entry => entry.id === workerContractComponent.selectedContractID
	)
	if (newTimeRange.type === "avail") {
		dispatch(addAvailability({ contract, schedulePeriodID, newAvailability: newTimeRange }))
	} else if (newTimeRange.type === "theo-hour") {
		dispatch(
			addTheoreticalHour({ contract, schedulePeriodID, newTheoreticalHour: newTimeRange })
		)
	} else if (newTimeRange.type === "other-act") {
		dispatch(addOtherActivity({ contract, newOtherActivity: newTimeRange }))
	}
}

const filterOverlappingTimerange = (timerange, list) => {
	return list.filter(
		entry =>
			// not on the same day:
			!entry.startDate.isSame(timerange.startDate, "day") ||
			// or not ("!") overlapping:
			!(entry.end.isSameOrAfter(timerange.start) && entry.start.isSameOrBefore(timerange.end))
	)
}

export const processRemoveScheduleTimeRange = timerange => (dispatch, getState) => {
	let {
		redData: { workerContracts },
		redComponents: { workerContractComponent },
	} = getState()
	let contract = workerContracts.find(
		entry => entry.id === workerContractComponent.selectedContractID
	)
	let timeRangesKey = timeRangesKeysMap[timerange.type]
	let changes = {
		[timeRangesKey]: contract[timeRangesKey].filter(tr => tr.id !== timerange.id),
		schedulePeriods: contract.schedulePeriods.map((schedulePeriod, index) => {
			let associationArray = associationArrayMap[timerange.type]
			if (index === workerContractComponent.selectedSchedulePeriod) {
				return {
					...schedulePeriod,
					[associationArray]: schedulePeriod[associationArray].filter(
						entry => entry !== timerange.id
					),
				}
			}
			return schedulePeriod
		}),
	}
	if (timerange.type === "avail" && !NO_TH_VARIABILITIES.includes(contract.contractVariability)) {
		// if removing an availability we need to remove any overlapping theoretical hours that was on it
		let removedAvail = timerange
		let newTheoreticalHours = filterOverlappingTimerange(
			removedAvail,
			contract.theoreticalHours
		)
		if (newTheoreticalHours.length !== contract.theoreticalHours.length) {
			// Include them in the changes only if one or more were removed
			changes.theoreticalHours = newTheoreticalHours
			// if a theoretical hour was removed, there may also be an other activity to remove
			let newOtherActivities = filterOverlappingTimerange(
				removedAvail,
				contract.otherActivities
			)
			changes.otherActivities = newOtherActivities
		}
	}
	if (
		timerange.type === "theo-hour" &&
		!NO_TH_VARIABILITIES.includes(contract.contractVariability)
	) {
		// if removing an TH we need to remove any overlapping OA that was on it
		let removedTheoHour = timerange
		let newOtherActivities = filterOverlappingTimerange(
			removedTheoHour,
			contract.otherActivities
		)
		if (newOtherActivities.length !== contract.otherActivities.length) {
			changes.otherActivities = newOtherActivities
		}
	}
	dispatch(handleEditWorkerContract(changes))
}

export const editScheduleTimeRange = (newTimeRange, timeRangeToEditID, schedulePeriodID) => (
	dispatch,
	getState
) => {
	let {
		redData: { workerContracts },
		redComponents: { workerContractComponent },
	} = getState()
	let timeRangesKey = timeRangesKeysMap[newTimeRange.type]
	let contract = workerContracts.find(
		entry => entry.id === workerContractComponent.selectedContractID
	)
	let changes = {
		schedulePeriods: [...contract.schedulePeriods],
		availabilities: [...contract.availabilities],
		theoreticalHours: [...contract.theoreticalHours],
		otherActivities: [...contract.otherActivities],
	}
	changes = {
		...changes,
		[timeRangesKey]: contract[timeRangesKey].map(entry => {
			if (entry.id === timeRangeToEditID) {
				newTimeRange.id = timeRangeToEditID
				newTimeRange.duration = newTimeRange.end.diff(newTimeRange.start, "minutes")
				newTimeRange.dayPeriod = entry.dayPeriod
				return { ...entry, ...newTimeRange }
			}
			return entry
		}),
	}
	changes = ensureCoveringTimerange({
		changes,
		schedulePeriodID,
		timeRangeToCover: newTimeRange,
		contract,
	})

	dispatch(handleEditWorkerContract(changes))
}

export const processAddBookingTimeRange = booking => async (dispatch, getState) => {
	let {
		redData: { workerContracts },
		redComponents: { workerContractComponent },
	} = getState()
	let contract = workerContracts.find(
		entry => entry.id === workerContractComponent.selectedContractID
	)
	let appliedSchedulePeriods = contract.schedulePeriods.filter(schedulePeriod => {
		return schedulePeriodApplies({
			schedulePeriod,
			startDate: booking.startDate,
			dayPeriod: booking.dayPeriod,
		})
	})
	let changes = {
		unsavedBookings: [...contract.unsavedBookings, booking],
	}
	dispatch(handleEditWorkerContract(changes))

	for (let i = 0; i < appliedSchedulePeriods.length; i++) {
		// Using a for loop instead of forEach because
		// this needs to wait for each iteration to finish before starting the next one.
		let sp = appliedSchedulePeriods[i]
		let contract = getState().redData.workerContracts.find(
			entry => entry.id === workerContractComponent.selectedContractID
		)
		if (!["variable", "variable_variable"].includes(contract.contractVariability)) {
			await dispatch(
				addTheoreticalHour({
					contract,
					schedulePeriodID: sp.id,
					newTheoreticalHour: newTimerangeObject({
						type: "theo-hour",
						startDate: moment(sp.startDate).isoWeekday(booking.startDate.isoWeekday()),
						start: moment(booking.start),
						end: moment(booking.end),
						allowEdition: true,
						allowDeletion: true,
					}),
				})
			)
		}
	}
	return new Promise((resolve, reject) => resolve())
}

export const processEditBookingTimeRange = editedBooking => async (dispatch, getState) => {
	let {
		redData: { workerContracts },
		redComponents: { workerContractComponent },
	} = getState()
	let contract = workerContracts.find(
		entry => entry.id === workerContractComponent.selectedContractID
	)
	let appliedSchedulePeriods = contract.schedulePeriods.filter(schedulePeriod => {
		return schedulePeriodApplies({
			schedulePeriod,
			startDate: editedBooking.startDate,
			dayPeriod: editedBooking.dayPeriod,
		})
	})
	let changes = {
		unsavedBookings: contract.unsavedBookings.map(entry => {
			if (entry.id === editedBooking.id) {
				return editedBooking
			}
			return entry
		}),
	}
	dispatch(handleEditWorkerContract(changes))

	for (let i = 0; i < appliedSchedulePeriods.length; i++) {
		let schedulePeriod = appliedSchedulePeriods[i]
		// Checking if it is still contained within a theoretical hour
		// for each relevant schedulePeriod, if not we'll create it
		let containedByTheoreticalHour = contract.theoreticalHours.filter(
			entry =>
				schedulePeriod.managedTheoHours.includes(entry.id) &&
				entry.startDate.weekday() === editedBooking.startDate.weekday() &&
				entry.start.isSameOrBefore(editedBooking.start) &&
				entry.end.isSameOrAfter(editedBooking.end)
		)
		if (containedByTheoreticalHour.length === 0) {
			// addTheoreticalHour will take care of merging overlapping availabilities
			await dispatch(
				addTheoreticalHour({
					contract,
					schedulePeriodID: schedulePeriod.id,
					newTheoreticalHour: newTimerangeObject({
						type: "theo-hour",
						startDate: moment(schedulePeriod.startDate).isoWeekday(
							editedBooking.startDate.isoWeekday()
						),
						start: moment(editedBooking.start),
						end: moment(editedBooking.end),
						allowEdition: true,
						allowDeletion: true,
					}),
				})
			)
		}
	}
}

export const processRemoveBookingTimerange = timerange => (dispatch, getState) => {
	let {
		redData: { workerContracts },
		redComponents: { workerContractComponent },
	} = getState()
	let contract = workerContracts.find(
		entry => entry.id === workerContractComponent.selectedContractID
	)
	let changes = {
		unsavedBookings: contract.unsavedBookings.filter(tr => tr.id !== timerange.id),
	}
	return dispatch(handleEditWorkerContract(changes))
}

const removeAndReAddAllUnsavedBookings = () => async (dispatch, getState) => {
	let {
		redData: { workerContracts },
		redComponents: { workerContractComponent },
	} = getState()
	let contract = workerContracts.find(
		entry => entry.id === workerContractComponent.selectedContractID
	)
	let bookingsToReAdd = [...contract.unsavedBookings]
	for (let i = 0; i < bookingsToReAdd.length; i++) {
		let booking = bookingsToReAdd[i]
		await dispatch(processRemoveBookingTimerange(booking))
	}
	for (let i = 0; i < bookingsToReAdd.length; i++) {
		let booking = bookingsToReAdd[i]
		await dispatch(processAddBookingTimeRange(booking))
	}
}

export const toggleTwoWeeksSchedule = () => async (dispatch, getState) => {
	let {
		redData: { workerContracts },
		redComponents: { workerContractComponent },
	} = getState()
	let contract = workerContracts.find(
		entry => entry.id === workerContractComponent.selectedContractID
	)
	let newSchedulePeriods
	let changes = {
		availabilities: [...contract.availabilities],
		theoreticalHours: [...contract.theoreticalHours],
		otherActivities: [...contract.otherActivities],
	}
	if (contract.schedulePeriods.length === 2) {
		newSchedulePeriods = contract.schedulePeriods.filter((e, i) => i !== 1)
		let removedSchedulePeriod = contract.schedulePeriods.find(
			entry => entry.id !== newSchedulePeriods[0].id
		)
		changes.availabilities = changes.availabilities.filter(
			entry => !removedSchedulePeriod.managedAvails.includes(entry.id)
		)
		changes.theoreticalHours = changes.theoreticalHours.filter(
			entry => !removedSchedulePeriod.managedTheoHours.includes(entry.id)
		)
		changes.otherActivities = changes.otherActivities.filter(
			entry => !removedSchedulePeriod.managedOtherActivities.includes(entry.id)
		)
		newSchedulePeriods[0].dayPeriod = 7
		dispatch(contractV2UIChange({ selectedSchedulePeriod: 0 }))
	} else if (contract.schedulePeriods.length === 1) {
		let newManagedAvails = []
		let newManagedTheoHours = []
		newSchedulePeriods = [
			{ ...contract.schedulePeriods[0], dayPeriod: 14 },
			{
				id: Math.floor(Math.random() * 1000000000000),
				startDate: moment(contract.schedulePeriods[0].startDate).add(7, "days"),
				hasMaxHoursPerDay: false,
				dayPeriod: 14,
				maxWorkHours: 38,
				maxExtraWorkHours: 0,
				daysMaxHours: [0, 0, 0, 0, 0, 0, 0],
				// availabilities & theo-hours will be prefilled
				// with weekly unsaved bookings added prior
				managedAvails: newManagedAvails,
				managedTheoHours: newManagedTheoHours,
				managedOtherActivities: [],
			},
		]
	}
	// Update all timeRanges with new dayPeriod as well:
	changes.schedulePeriods = newSchedulePeriods
	;["availabilities", "theoreticalHours", "otherActivities"].forEach(rangeType => {
		changes[rangeType] = changes[rangeType].map(entry => ({
			...entry,
			dayPeriod: newSchedulePeriods[0].dayPeriod,
		}))
	})
	await dispatch(handleEditWorkerContract(changes))
	// Removing, then re-adding all unsaved bookings
	// makes sure all bookings have the appropriate TH & Avails underneath them
	dispatch(removeAndReAddAllUnsavedBookings())
}

export const handleAddWorkshopHourToWorkerSchedule = () => (dispatch, getState) => {
	let {
		redComponents: {
			workshopHourModalComponent: {
				startDate,
				start,
				end,
				dayPeriod,
				activityCategory,
				activityCode,
				workshopId,
			},
		},
	} = getState()
	dispatch(
		addScheduleTimeRange(
			newTimerangeObject({
				startDate,
				start,
				end,
				dayPeriod,
				activityCategory,
				activityCode,
				workshopId,
				isWorkshopHour: true,
				type: "other-act",
				allowEdition: true,
				allowDeletion: true,
				customClass: "workshop-hour",
			})
		)
	)
}
