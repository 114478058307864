import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"

import {
	simpleFetch,
	handleEditWorkerContract,
	handleSecSocSpecificFieldChange,
	processAddNewContract,
	contractV2MetaChange,
	submitNewContract,
	copyLastContractDataToNewContract,
	copyLastContractTheoreticalSchedulesToNewContract,
	resetCurrentContractData,
	submitUpdateCurrentContract,
	submitForceUpdateCurrentContract,
	processRemoveNewContract,
	contractV2UIChange,
	toggleTwoWeeksSchedule,
	swapSchedulePeriods,
	forgetAffiliateWorker,
	getPreviousWorkerContract,
	isTimerangeForSchedule,
	deleteWorkerContract,
} from "../actions"
import NewDimonaModal from "./NewDimonaModal"
import NewEconomicSuspensionModal from "./NewEconomicSuspensionModal"
import ImpactedBookingsModal from "./ImpactedBookingsModal"
import WorkerContractInputs from "./WorkerContractInputs"
import WorkerSchedule from "./WorkerSchedule"
import WorkerDocuments from "./WorkerDocuments"
import NewWorkerContractActions from "./NewWorkerContractActions"
import InputsChangeHandler from "./InputsChangeHandler"
import WorkerTemplates from "./WorkerTemplates"
import ModalLayout from "./ModalLayout"
import SocialSecretaryWorkerSpecificFields from "./SocialSecretaryWorkerSpecificFields"
import WorkerContractSavingCard from "./WorkerContractSavingCard"
import * as actions from "../actions"
import { NavLink } from "./NavLink"

import {
	ContentLayout,
	PootsySubHeading,
	LabeledDateTime,
	OptionBox,
	PootsyButton,
} from "./FunctionalDesign"
import { PootsyTextInput, PootsyCheckboxInput, PootsyTwoTextInput } from "./FunctionalInputs"

export class WorkerContract extends Component {
	componentDidMount = () => {
		let { dispatch } = this.props
		dispatch(simpleFetch("/public/super_area_list", { method: "GET" }, "FETCH_SUPERAREAS"))
	}
	handleChanges = changes => {
		this.props.dispatch(actions.workerContractComponentStateChange(changes))
	}
	handleFormChanges = data => {
		if (data.hoursPerWeek) {
			data.hoursPerWeek = data.hoursPerWeek.replace(/,/g, ".")
		}
		this.props.dispatch(handleEditWorkerContract(data))
	}
	selectRefPeriodsStartDate = changes => {
		this.handleFormChanges(changes)
	}
	handleSecSocSpecificFieldChange = changes => {
		this.props.dispatch(handleSecSocSpecificFieldChange(changes))
	}
	handleAddNewContract = () => {
		document.querySelector(".pootsy-page-content").scrollTop = 0
		this.props.dispatch(processAddNewContract())
	}
	convertDecimalToHours = decimal => {
		if (decimal.toString().includes("u") || decimal.toString().includes("h")) {
			return decimal
		}
		let { t } = this.context
		let minutes = decimal * 60
		let remainder = minutes % 60
		return (
			Math.floor(minutes / 60) +
			t("hours_shorthand") +
			(remainder < 10 ? "0" + remainder : remainder)
		)
	}
	convertHoursToDecimal = string => {
		let { t } = this.context
		let parsed = string.split(t("hours_shorthand"))
		let hours = parsed[0] || "0"
		let minutes = parsed[1] || [0]
		return parseFloat(hours) + minutes / 60
	}
	calculateAverageHour = currentContract => {
		let { t } = this.context
		let maxWorkHours1 = currentContract.schedulePeriods[0].maxWorkHours.toString()
		if (currentContract.schedulePeriods.length === 1) {
			let hoursFormat = maxWorkHours1.includes(t("hours_shorthand"))
				? maxWorkHours1
				: this.convertDecimalToHours(maxWorkHours1)
			return hoursFormat
		} else {
			let firstTime = maxWorkHours1.includes(t("hours_shorthand"))
				? parseFloat(maxWorkHours1.split(t("hours_shorthand"))[0] * 60) +
				  parseFloat(maxWorkHours1.split(t("hours_shorthand"))[1])
				: maxWorkHours1 * 60
			let maxWorkHours2 = currentContract.schedulePeriods[1].maxWorkHours.toString()
			let secondTime = maxWorkHours2.includes(t("hours_shorthand"))
				? parseFloat(maxWorkHours2.split(t("hours_shorthand"))[0] * 60) +
				  parseFloat(maxWorkHours2.split(t("hours_shorthand"))[1])
				: maxWorkHours2 * 60
			let hoursFormat = this.convertDecimalToHours((firstTime + secondTime) / 120)
			return hoursFormat
		}
	}
	state = {
		averageWorkHours: "00h00",
		changed: false,
		showWarningSchedule: false,
	}
	UNSAFE_componentWillReceiveProps = params => {
		if (params.workerContracts.length > 0) {
			let currentContract = this.props.workerContracts.find(
				entry => entry.id === this.props.workerContractComponent.selectedContractID
			)
			if (currentContract && !this.state.changed) {
				this.setState({
					averageWorkHours: this.convertDecimalToHours(currentContract.hoursPerWeek),
					changed: true,
				})
			}
		}
	}
	componentDidUpdate = () => {
		let currentContract = this.props.workerContracts.find(
			entry => entry.id === this.props.workerContractComponent.selectedContractID
		)
		let editedKeys = this.props.workerContractComponent.editedKeys
		let currentEditedKeys = []
		if (currentContract && editedKeys[currentContract.id]) {
			currentEditedKeys = editedKeys[currentContract.id]
		}
		if (currentContract && this.state.changed) {
			let average = this.calculateAverageHour(currentContract)
			if (
				this.state.averageWorkHours !== average &&
				(currentEditedKeys.length > 0 || currentContract.id === "new-contract")
			) {
				this.setState({ averageWorkHours: average })
				this.props.dispatch(handleEditWorkerContract({ hoursPerWeek: average }))
			}
			if (
				currentEditedKeys.length === 0 &&
				this.state.averageWorkHours !== currentContract.hoursPerWeek
			) {
				this.setState({
					averageWorkHours: this.convertDecimalToHours(currentContract.hoursPerWeek),
					changed: false,
				})
			}
		}
	}
	selectContract = e => {
		let { dispatch } = this.props
		let { contractid } = e.target.dataset
		dispatch(
			contractV2MetaChange({
				selectedSchedulePeriod: 0,
				selectedContractID:
					contractid === "new-contract" ? contractid : parseInt(contractid, 10),
			})
		)
	}
	selectForgetDate = changes => {
		this.props.dispatch(contractV2MetaChange(changes))
	}
	toggleForgetAffiliateWorkerModal = () => {
		let { workerContractComponent, dispatch } = this.props
		dispatch(
			contractV2UIChange({
				showforgetAffiliateWorkerModal: !workerContractComponent.showforgetAffiliateWorkerModal,
			})
		)
	}
	toggleDeleteWorkerContractModal = () => {
		let { workerContractComponent, dispatch } = this.props
		dispatch(
			contractV2UIChange({
				showDeleteWorkerContractModal: !workerContractComponent.showDeleteWorkerContractModal,
			})
		)
	}
	toggleCheckBox = e => {
		e.stopPropagation()
		this.props.dispatch(contractV2UIChange({ [e.target.name]: e.target.checked }))
	}
	toggleRecurrentBookings = e => {
		e.stopPropagation()
		this.props.dispatch(contractV2UIChange({ showRecurrentBookings: e.target.checked }))
	}
	selectSchedulePeriod = e => {
		let { index } = e.target.dataset
		index = parseInt(index, 10)
		this.props.dispatch(contractV2UIChange({ selectedSchedulePeriod: index }))
	}
	handleTwoWeeksScheduleChange = (e, currentContract) => {
		if (currentContract.schedulePeriods.length === 2) {
			this.setState({ showWarningSchedule: true })
		}
		e.stopPropagation()
		this.props.dispatch(toggleTwoWeeksSchedule())
	}
	copyLastContractDataToNewContract = () => {
		this.props.dispatch(copyLastContractDataToNewContract())
	}
	copyLastContractTheoreticalSchedulesToNewContract = () => {
		this.props.dispatch(copyLastContractTheoreticalSchedulesToNewContract())
	}
	swapSchedules = () => {
		this.props.dispatch(swapSchedulePeriods())
	}
	handleRemoveNewContract = e => {
		e.stopPropagation()
		this.props.dispatch(processRemoveNewContract())
	}
	forgetAffiliateWorker = () => {
		this.props.dispatch(forgetAffiliateWorker())
	}
	deleteWorkerContract = () => {
		this.props.dispatch(deleteWorkerContract())
	}
	resetWorkerContractData = () => {
		this.props.dispatch(resetCurrentContractData())
	}
	handleUpdateWorkerContract = () => {
		this.props.dispatch(submitUpdateCurrentContract())
	}
	forceWorkerContractUpdate = () => {
		this.props.dispatch(submitForceUpdateCurrentContract())
	}
	submitNewContract = () => {
		this.props.dispatch(submitNewContract())
	}
	getContractListLineClassName = (contract, isOriginal) => {
		let { workerContractComponent } = this.props

		let className = "worker-contract"
		if (contract.id === workerContractComponent.selectedContractID && !isOriginal) {
			className += " selected"
		}
		if (contract.activeContract) {
			className += " active"
		}
		if (contract.addendum) {
			className += " addendum"
		}
		return className
	}
	renderContractListLine = contract => {
		let { workerContracts } = this.props
		let addendums = workerContracts
			.reduce(
				(acc, entry) => {
					let child = workerContracts.find(e => e.parentID === acc[acc.length - 1].id)
					if (child !== undefined) {
						acc.push(child)
					}
					return acc
				},
				[contract]
			)
			.sort((a, b) => a.startDate - b.startDate)
		let endDate =
			addendums.length > 0 ? addendums[addendums.length - 1].endDate : contract.endDate

		let { t } = this.context
		if (contract.id === "new-contract") {
			return (
				<div
					className={this.getContractListLineClassName(contract, false)}
					onClick={this.selectContract}
					data-contractid={contract.id}
				>
					{t("new_worker_contract")}
					<div className="remove-new-contract" onClick={this.handleRemoveNewContract}>
						X
					</div>
				</div>
			)
		}
		return (
			<div>
				<div
					className={this.getContractListLineClassName(contract, addendums.length > 1)}
					onClick={addendums.length === 1 ? this.selectContract : null}
					data-contractid={contract.id}
				>
					{contract.startDate.format("DD/MM/YYYY")} >{" "}
					{endDate.isValid()
						? endDate.format("DD/MM/YYYY")
						: addendums.length > 1
						? "…"
						: t("no_end_date")}
				</div>
				{addendums.length > 1 &&
					addendums.map(entry => (
						<div
							className={
								this.getContractListLineClassName(entry, false) + " addendum"
							}
							onClick={this.selectContract}
							data-contractid={entry.id}
							key={entry.id}
						>
							{entry.startDate.format("DD/MM/YYYY")} >{" "}
							{entry.endDate.isValid()
								? entry.endDate.format("DD/MM/YYYY")
								: t("no_end_date")}
						</div>
					))}
			</div>
		)
	}
	canSaveContract = () => {
		let {
			workerContractComponent: { errors, schedErrors },
		} = this.props

		return errors.length === 0 && schedErrors.length === 0
	}

	submitNewDimona = async () => {
		let { currentWorker } = this.props
		let { actionType } = await this.props.dispatch(actions.submitNewDimona())
		if (actionType === actions.SUBMIT_NEW_DIMONA_SUCCESS) {
			this.props.dispatch(
				actions.newEconomicSuspensionModalStateChange({
					chosenWorker: {
						name: currentWorker.displayName,
						firstName: currentWorker.firstName,
						lastName: currentWorker.lastName,
						sodexo: currentWorker.sodexoNumber,
						niss: currentWorker.nissNumber,
						affiliateWorkerId: Number(currentWorker.id),
					},
				})
			)
			this.handleChanges({ showEconomicSuspensionModal: true })
		}
	}
	render() {
		let { t } = this.context
		let {
			currentWorker,
			workerContracts,
			impactedBookingsModal: { showImpactedBookingsModal },
			workerContractComponent: {
				showRequired,
				selectedSchedulePeriod,
				showforgetAffiliateWorkerModal,
				showDeleteWorkerContractModal,
				selectedContractID,
				editedKeys,
				errors,
				schedErrors,
				selectedForgetDate,
				showRecurrentBookings,
				showDimonaModal,
				showEconomicSuspensionModal,
			},
			currAffiliate,
			superAreas,
			lang,
		} = this.props
		let currentContract = workerContracts.find(entry => entry.id === selectedContractID)
		let currentEditedKeys = []
		if (currentContract && editedKeys[currentContract.id]) {
			currentEditedKeys = editedKeys[currentContract.id]
		}
		let contractWarnings = []
		let previousContract
		let isVarVarContract = false
		let isVarContract = false
		let currentContractHasAddendum = false
		let currentContractIsLastAddendum = false
		if (currentContract) {
			currentContractHasAddendum = workerContracts.some(
				entry => entry.addendum && entry.parentID === currentContract.id
			)
			currentContractIsLastAddendum = currentContract.addendum && !currentContractHasAddendum
			isVarVarContract = currentContract.contractVariability === "variable_variable"
			isVarContract = currentContract.contractVariability === "variable"
		}
		if (currentContract && workerContracts.length > 1) {
			previousContract = getPreviousWorkerContract(currentContract, workerContracts)
			if (
				currentContract.id === "new-contract" &&
				previousContract &&
				previousContract.schedulePeriods.length === 2 &&
				currentContract.schedulePeriods.length === 2 &&
				previousContract.startDate.diff(currentContract.startDate, "week") % 2 === 0
			) {
				contractWarnings.push(["warning_schedules_may_have_been_swapped"])
			}
		}
		let optionsBoxes = (
			<Fragment>
				<OptionBox boxTitle={t("contracts")} customClass="worker-contracts-list">
					{workerContracts
						.filter(entry => !entry.addendum)
						.map(entry => (
							<div key={entry.id} className="contract-and-addendums">
								{this.renderContractListLine(entry)}
							</div>
						))}
					<PootsyButton
						text={t("add_new_worker_contract")}
						onClick={this.handleAddNewContract}
					/>
					{currentContract && currentContract.id === "new-contract" && (
						<PootsyButton
							text={t("save_contract")}
							theme="green"
							onClick={this.submitNewContract}
							disabled={!this.canSaveContract()}
						/>
					)}
					{currentContract && showDimonaModal && (
						<Fragment>
							<NewDimonaModal
								submitNewDimona={this.submitNewDimona}
								closeModal={() => this.handleChanges({ showDimonaModal: false })}
							/>
						</Fragment>
					)}
					{currentContract && showEconomicSuspensionModal && (
						<NewEconomicSuspensionModal
							closeModal={() =>
								this.handleChanges({ showEconomicSuspensionModal: false })
							}
						/>
					)}
				</OptionBox>

				{workerContracts.length > 0 && currentContract && (
					<OptionBox
						boxTitle={t("worker_contract_templates")}
						customClass="worker-contract-templates"
					>
						<WorkerTemplates currentContract={currentContract} />
					</OptionBox>
				)}
				{currentWorker && currentWorker.dimonas && currentWorker.dimonas.length > 0 && (
					<OptionBox boxTitle={t("dimonas")}>
						{currentWorker.dimonas.map(dimona => (
							<div key={dimona.id} className="dimona-item">
								<NavLink
									to={`/documents/worker-register?page=1&worker_filter=${currentWorker.id}`}
									className="worker-contract-link"
								>
									<div>
										{" "}
										#{dimona.id} - {dimona.dimona_type} -{" "}
										{moment(dimona.date_in).format("DD-MM-YYYY")}{" "}
										{dimona.date_out
											? "-> " + moment(dimona.date_out).format("DD-MM-YYYY")
											: ""}{" "}
										: {t(dimona.status)}
									</div>
								</NavLink>
							</div>
						))}
					</OptionBox>
				)}
				<OptionBox boxTitle={t("infos")}>{t("theoretical_hour_explanation")}</OptionBox>
				<OptionBox
					boxTitle={t("worker_contract_messages")}
					customClass="worker-contract-messages"
				>
					<div className="warnings">
						{contractWarnings.map((entry, index) => (
							<div key={entry + index} className="error">
								{t(entry)}
							</div>
						))}
					</div>
					<div className="errors">
						{errors.map((entry, index) => (
							<div key={entry + index} className="error">
								{t(entry)}
							</div>
						))}
					</div>
					<div className="scheds-errors">
						{schedErrors.map(entry => (
							<div key={entry.schedIndex} className="sched-errors">
								<div className="label">
									{entry.schedIndex === 0 ? t("in_week_nb1") : t("in_week_nb2")}
								</div>
								<div className="errors">
									{entry.errors.map((entry, index) => (
										<div key={entry + index} className="error">
											{t(entry)}
										</div>
									))}
								</div>
								<div className="warnings">
									{entry.warnings.map((entry, index) => (
										<div key={entry + index} className="warning">
											{t(entry)}
										</div>
									))}
								</div>
							</div>
						))}
					</div>
				</OptionBox>
				{currentContract && currentContract.id !== "new-contract" && (
					<div className="danger-buttons">
						<PootsyButton
							text={t("delete_worker_contract")}
							theme="red"
							onClick={this.toggleDeleteWorkerContractModal}
							disabled={
								(currentContract.addendum && !currentContractIsLastAddendum) ||
								currentContractHasAddendum
							}
						/>
						{currentContract.scheduledJobs.length < 1 && (
							<PootsyButton
								text={t("forget_worker_contract")}
								theme="red"
								onClick={this.toggleForgetAffiliateWorkerModal}
							/>
						)}
					</div>
				)}
			</Fragment>
		)

		let bannerMessage = t("worker_has_no_contract"),
			bannerClass = "top-banner"
		if (currentContract) {
			if (currentContract.id === "new-contract") {
				bannerMessage = t("new_contract_not_saved")
				bannerClass += " new-contract"
			} else {
				bannerMessage = t("the_current_contract_is_active_inactive", {
					activeContract: t(currentContract.activeContract ? "active" : "inactive"),
				})
				bannerClass += currentContract.activeContract ? " active" : " inactive"
			}
		}
		return (
			<InputsChangeHandler
				onChange={this.handleFormChanges}
				customClass="worker-contracts-wrapper"
			>
				<ContentLayout optionsBoxes={optionsBoxes} customClass="worker-contracts">
					{showDeleteWorkerContractModal && (
						<ModalLayout
							title={t("delete_worker_contract")}
							formDivClass="delete-worker-contract-modal"
							buttons={[
								{ text: t("yes"), onClick: this.deleteWorkerContract },
								{
									text: t("no"),
									onClick: this.toggleDeleteWorkerContractModal,
									theme: "cancel",
								},
							].map(entry => (
								<PootsyButton
									key={entry.text}
									text={entry.text}
									theme={entry.theme}
									onClick={entry.onClick}
								/>
							))}
							closeModal={this.toggleDeleteWorkerContractModal}
						>
							<div className="reset-password-modal-prompt">
								{t("delete_worker_contract_confirmation")}
							</div>
							{currAffiliate.secSocName === "Group S" && (
								<div className="reset-password-modal-prompt">
									{t("delete_worker_contract_reminder")}
								</div>
							)}
						</ModalLayout>
					)}
					{showforgetAffiliateWorkerModal && (
						<ModalLayout
							title={t("forget_worker_contract")}
							formDivClass="forget-worker-contract-modal"
							buttons={[
								{ text: t("yes"), onClick: this.forgetAffiliateWorker },
								{
									text: t("no"),
									onClick: this.toggleForgetAffiliateWorkerModal,
									theme: "cancel",
								},
							].map(entry => (
								<PootsyButton
									key={entry.text}
									text={entry.text}
									theme={entry.theme}
									onClick={entry.onClick}
								/>
							))}
							closeModal={this.toggleForgetAffiliateWorkerModal}
						>
							<LabeledDateTime
								label={t("forget_date")}
								showTimePicker={false}
								datePickerProps={{
									name: "selectedForgetDate",
									value: selectedForgetDate,
									onChange: this.selectForgetDate,
									disableDay: current => current.isSameOrBefore(moment(), "day"),
								}}
							/>
							<div className="reset-password-modal-prompt">
								{t("forget_worker_contract_confirmation")}
							</div>
						</ModalLayout>
					)}
					{this.state.showWarningSchedule && (
						<ModalLayout
							title={t("warning")}
							formDivClass="delete-worker-contract-modal"
							closeModal={() => this.setState({ showWarningSchedule: false })}
						>
							<div className="reset-password-modal-prompt">
								{t("manage_all_events_in_agenda")}
							</div>
						</ModalLayout>
					)}
					{showImpactedBookingsModal && (
						<ImpactedBookingsModal
							onSubmit={this.forgetAffiliateWorker}
							triggerActionExpected={actions.FORGET_WORKER_CONTRACT_FAILURE}
						/>
					)}
					{showImpactedBookingsModal && (
						<ImpactedBookingsModal
							onSubmit={this.deleteWorkerContract}
							triggerActionExpected={actions.DELETE_WORKER_CONTRACT_FAILURE}
						/>
					)}

					{currentContract && (
						<WorkerContractSavingCard
							currentEditedKeys={currentEditedKeys}
							showRequired={showRequired}
							currentContract={currentContract}
							handleFormChanges={this.handleFormChanges}
							handleUpdateWorkerContract={this.handleUpdateWorkerContract}
							resetWorkerContractData={this.resetWorkerContractData}
							forceWorkerContractUpdate={this.forceWorkerContractUpdate}
							canSaveContract={this.canSaveContract()}
						/>
					)}
					<div className={bannerClass}>{bannerMessage}</div>
					{workerContracts.length > 1 &&
						currentContract &&
						currentContract.id === "new-contract" && (
							<NewWorkerContractActions
								currentNewContract={currentContract}
								handleFormChanges={this.handleFormChanges}
								copyLastContractDataToNewContract={
									this.copyLastContractDataToNewContract
								}
								copyLastContractTheoreticalSchedulesToNewContract={
									this.copyLastContractTheoreticalSchedulesToNewContract
								}
							/>
						)}

					{currentContract && (
						<SocialSecretaryWorkerSpecificFields
							currAffiliate={currAffiliate}
							socialSecretary={currAffiliate.secSocName}
							handleSecSocSpecificFieldChange={this.handleSecSocSpecificFieldChange}
							values={{
								...currentContract.secSocSpecificData,
								endDate: currentContract.endDate,
								vehicleType: currentContract.vehicleType,
							}}
							workerContracts={workerContracts}
							currentContract={currentContract}
						/>
					)}
					{currentContract && (
						<WorkerContractInputs
							contract={currentContract}
							affiliateActiveAreas={currAffiliate.activeAreas}
							superAreas={superAreas}
							showRequired={showRequired}
							lang={lang}
							handleFormChanges={this.handleFormChanges}
							previousContract={previousContract}
							operatingHeadquarters={currAffiliate.operatingHeadquarters.data}
							dimonaAddOn={currAffiliate.dimonaAddOn}
							currentEditedKeys={currentEditedKeys}
						/>
					)}

					{currentContract && currentContract.id !== "new-contract" && (
						<WorkerDocuments
							documents={currentContract.documents}
							contractID={currentContract.id}
						/>
					)}
					{currentContract && (
						<div className="theoretical-schedule section">
							<PootsySubHeading text={t("theoretical_planning")} />
							<div className="general-schedule-controls">
								{currentContract.schedulePeriods.length > 1 && (
									<Fragment>
										<div className="tabs">
											{currentContract.schedulePeriods.map((entry, index) => (
												<div
													key={index}
													className={
														"tab" +
														(index === selectedSchedulePeriod
															? " active"
															: "")
													}
													data-index={index}
													onClick={this.selectSchedulePeriod}
												>
													{entry.startDate.isSame(
														moment(currentContract.startDate).startOf(
															"isoweek"
														),
														"day"
													)
														? t("first_week")
														: t("second_week")}
												</div>
											))}
										</div>
										<div className="current-week-indicator">
											{Math.abs(
												moment()
													.startOf("isoweek")
													.diff(
														currentContract.schedulePeriods.find(
															(e, i) => i === selectedSchedulePeriod
														).startDate,
														"days"
													)
											) %
												14 <
											7
												? t("current_week")
												: t("next_week")}
										</div>
									</Fragment>
								)}
								<div className="inputs">
									{!isVarVarContract && (
										<PootsyTwoTextInput
											name="hoursPerWeek"
											type="number"
											disabled={true}
											customClass="double-working-hours-per-week-input"
											separator={t("hours_shorthand")}
											label={t("global_nb_work_hours_per_week")}
											showRequired={showRequired.hoursPerWeek}
											values={this.state.averageWorkHours.split(
												t("hours_shorthand")
											)}
										/>
									)}
									{isVarVarContract && (
										<Fragment>
											{!currentContract.addendum &&
												!currentContract.newWorkerContractIsAddendum && (
													<LabeledDateTime
														label={t("ref_periods_start_date")}
														showTimePicker={false}
														datePickerProps={{
															name: "refPeriodsStartDate",
															value:
																currentContract.refPeriodsStartDate,
															onChange: this
																.selectRefPeriodsStartDate,
														}}
													/>
												)}
											<PootsyTextInput
												name="avgHoursPerWeekForRefPeriod"
												label={t("avg_hours_per_week_for_ref_period")}
												type="number"
												min="0"
												value={currentContract.avgHoursPerWeekForRefPeriod}
											/>
											<PootsyTextInput
												name="refPeriodWeekCount"
												label={t("ref_period_week_count")}
												type="number"
												min="0"
												value={currentContract.refPeriodWeekCount}
											/>
											<PootsyTextInput
												name="varvarMinWorkHoursPerWeek"
												label={t("min_work_hours_per_week")}
												type="number"
												min="0"
												value={currentContract.varvarMinWorkHoursPerWeek}
											/>
											<PootsyTextInput
												name="varvarMaxWorkHoursPerWeek"
												label={t("max_work_hours_per_week")}
												type="number"
												min="0"
												value={currentContract.varvarMaxWorkHoursPerWeek}
											/>
										</Fragment>
									)}
									<PootsyCheckboxInput
										name="showRecurrentBookings"
										label={t("show_recurrent_bookings")}
										onChange={this.toggleRecurrentBookings}
										checked={showRecurrentBookings}
									/>
									{!isVarVarContract && (
										<PootsyCheckboxInput
											name="twoWeeksSchedule"
											label={t("two_weeks_schedule")}
											onChange={e =>
												this.handleTwoWeeksScheduleChange(
													e,
													currentContract
												)
											}
											checked={currentContract.schedulePeriods.length === 2}
										/>
									)}
									{currentContract.schedulePeriods.length === 2 && (
										<PootsyButton
											text={t("switch_schedules")}
											onClick={this.swapSchedules}
										/>
									)}
								</div>
							</div>
							<div className="worker-schedules">
								{currentContract.schedulePeriods.map((schedulePeriod, index) => {
									let className = "worker-schedule-wrapper"
									className += selectedSchedulePeriod === index ? " active" : ""
									let translation = (selectedSchedulePeriod - index) * -110
									let availabilities = currentContract.availabilities.filter(
										availability =>
											schedulePeriod.managedAvails.includes(availability.id)
									)
									let theoreticalHours = currentContract.theoreticalHours.filter(
										theoreticalHour =>
											schedulePeriod.managedTheoHours.includes(
												theoreticalHour.id
											)
									)
									let otherActivities = currentContract.otherActivities.filter(
										oa =>
											!oa.isWorkshopHour &&
											schedulePeriod.managedOtherActivities.includes(oa.id)
									)
									let workshopHours = currentContract.otherActivities.filter(
										oa =>
											oa.isWorkshopHour &&
											schedulePeriod.managedOtherActivities.includes(oa.id)
									)
									let unsavedBookingsToDisplay = currentContract.unsavedBookings.filter(
										ub =>
											isTimerangeForSchedule({
												timerange: ub,
												schedulePeriod: schedulePeriod,
												schedulePeriods: currentContract.schedulePeriods,
											})
									)
									let recurrentBookings = []
									if (
										showRecurrentBookings &&
										currentContract.id !== "new-contract"
									) {
										recurrentBookings = currentWorker.recurrentBookings.filter(
											rb =>
												isTimerangeForSchedule({
													timerange: rb,
													schedulePeriod: schedulePeriod,
													schedulePeriods:
														currentContract.schedulePeriods,
												})
										)
									}
									return (
										<div
											key={index}
											className={className}
											style={{ transform: `translateX(${translation}%)` }}
										>
											<WorkerSchedule
												isVarVarContract={isVarVarContract}
												isVarContract={isVarContract}
												contractStartDate={currentContract.startDate}
												currentEditedKeys={currentEditedKeys}
												schedulePeriod={schedulePeriod}
												availabilities={availabilities}
												theoreticalHours={theoreticalHours}
												otherActivities={otherActivities}
												workshopHours={workshopHours}
												unsavedBookings={unsavedBookingsToDisplay}
												recurrentBookings={recurrentBookings}
												currentContract={currentContract}
												selectedPeriod={selectedSchedulePeriod}
												isTwoWeeksSchedule={
													currentContract.schedulePeriods.length === 2
												}
											/>
										</div>
									)
								})}
							</div>
						</div>
					)}
				</ContentLayout>
			</InputsChangeHandler>
		)
	}
}

WorkerContract.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currAffiliate: state.redData.currentAffiliate,
	superAreas: state.redData.superAreas,
	currentWorker: state.redData.currentWorker,
	workerContracts: state.redData.workerContracts,
	workerContractComponent: state.redComponents.workerContractComponent,
	lang: state.i18nState.lang,
	impactedBookingsModal: state.redComponents.impactedBookingsModal,
})
export default connect(mapStateToProps)(WorkerContract)
