import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"

import * as actions from "../actions"

import { ContentLayout, SodexoBookingDatedLine, PootsyButton } from "./FunctionalDesign"
import Paginator from "./Paginator"
import BookingFilters from "./BookingFilters"

class SodexoOpposed extends Component {
	handleChangePage = changes => {
		this.props.dispatch(actions.bookingFiltersStateChange({ page: changes.value }))
	}
	handleChanges = changes => {
		this.props.dispatch(actions.sodexoOpposedStateChange(changes))
	}
	render() {
		let { t } = this.context
		let { bookings, match, location, history, page, pagination } = this.props
		let optionsBoxes = (
			<BookingFilters
				match={match}
				location={location}
				history={history}
				filteredFilters={["voucher_type_filter", "sodexo_status_filter"]}
				customQueries={{
					sodexoLink: {
						urlName: "sl",
						queryName: "filter_sodexo_linkage",
						mustBeSet: true,
						defaultValue: "linked",
					},
					fromDateQuery: {
						urlName: "from",
						queryName: "from_date",
						mustBeSet: true,
						defaultValue: moment()
							.subtract(1, "month")
							.format("YYYY-MM-DD"),
					},
					toDateQuery: {
						urlName: "to",
						queryName: "to_date",
						mustBeSet: true,
						defaultValue: moment().format("YYYY-MM-DD"),
					},
					voucherType: {
						urlName: "voucherType",
						queryName: "filter_voucher_type",
						mustBeSet: true,
						defaultValue: "electronic",
					},
					sodexoStatus: {
						urlName: "vt",
						queryName: "filter_sodexo_status_only_electronic",
						mustBeSet: true,
						defaultValue: ["OpposedByCustomer"],
					},
					cancelledOccurrences: {
						urlName: "c",
						queryName: "reject_cancelled_occurrences",
						mustBeSet: true,
						defaultValue: true,
					},
				}}
			/>
		)
		return (
			<ContentLayout optionsBoxes={optionsBoxes} customClass="SodexoOpposed">
				{bookings.length === 0 && (
					<div className="no-data-placeholder">{t("no_opposed_sodexo_bookings")}</div>
				)}
				{bookings.map(entry => (
					<SodexoBookingDatedLine key={entry.id} booking={entry}>
						<div className="booking-line-actions-column">
							<PootsyButton text={t("✓")} theme="fadedGreen" />
							<PootsyButton text={t("✕")} theme="fadedRed" />
						</div>
					</SodexoBookingDatedLine>
				))}
				<Paginator
					name="pageQuery"
					currPage={page}
					totalPages={pagination.total_pages}
					goToPage={this.handleChangePage}
				/>
			</ContentLayout>
		)
	}
}

SodexoOpposed.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.sodexoOpposedComponent,
	bookings: state.redData.bookings,
	page: state.redComponents.bookingFiltersComponent.page,
	pagination: state.redComponents.bookingFiltersComponent.pagination,
})
export default connect(mapStateToProps)(SodexoOpposed)
