import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import * as actions from "../actions"
import { sortArrayByName } from "../helpers/arraySortByName"
import { PootsyTextInput } from "./FunctionalInputs"

class SearchWorker extends Component {
	state = {
		queuedCall: "",
	}
	handleSearchWorker = e => {
		e.stopPropagation()
		let { dispatch, onChange, searchOnlyActiveWorkers, currentAffiliate } = this.props
		let { value } = e.target
		if (searchOnlyActiveWorkers) {
			if (value.length === 0) {
				dispatch(actions.resetWorkerSuggestions())
				return
			}
			dispatch(
				actions.setWorkerSuggestions(
					currentAffiliate.allAffiliateWorkers.filter(entry => {
						return entry.attributes.display_name
							.toLowerCase()
							.includes(value.toLowerCase())
					})
				)
			)
			return
		}
		let { queuedCall } = this.state
		clearTimeout(queuedCall)
		let futureCall
		onChange(e.target.value)
		if (value.length < 2) {
			dispatch(actions.resetWorkerSuggestions())
		} else {
			futureCall = setTimeout(() => this.fetchWorkerSuggestions(value), 800)
		}
		this.setState({ queuedCall: futureCall })
	}
	fetchWorkerSuggestions = search => {
		let { dispatch } = this.props
		let query = "?filter_name=" + search
		let init = { method: "GET" }
		dispatch(
			actions.simpleFetch(
				"/front/affiliates/affiliate_workers" + query,
				init,
				"WORKERSUGGESTIONS"
			)
		)
	}
	handleWorkerClick = entry => {
		let { dispatch, onClick } = this.props
		onClick(entry)
		dispatch(actions.resetWorkerSuggestions())
	}
	render() {
		let { t } = this.context
		let { workerSuggestions, value } = this.props
		workerSuggestions = sortArrayByName(workerSuggestions, "attributes", "display_name")
		return (
			<div className="SearchWorker">
				<PootsyTextInput
					label={t("search_for_specific_cleaner")}
					value={value}
					onChange={this.handleSearchWorker}
				/>
				{workerSuggestions.length > 0 && (
					<div className="worker-suggestions">
						{workerSuggestions.map(entry => (
							<div
								key={entry.id}
								className="worker-suggestion"
								onClick={this.handleWorkerClick.bind(this, entry)}
							>
								{entry.attributes.display_name}
							</div>
						))}
					</div>
				)}
			</div>
		)
	}
}

SearchWorker.contextTypes = { t: PropTypes.func }
SearchWorker.defaultProps = {
	searchOnlyActiveWorkers: false,
	onChange: () => {},
}
const mapStateToProps = state => ({
	currentAffiliate: state.redData.currentAffiliate,
	workerSuggestions: state.redData.workerSuggestions,
})
export default connect(mapStateToProps)(SearchWorker)
