import React, { Component } from "react"
import PropTypes from "prop-types"

import InputsChangeHandler from "./InputsChangeHandler"
import { PootsyFileInput } from "./FunctionalInputs"


class PSVScanningZone extends Component {
	state = {
		codeBuffer: "",
		pastingZoneActive: false,
	}
	componentDidMount = () => {
		this.pastingZone.addEventListener("focus", this.handlePastingZoneFocus)
		this.pastingZone.addEventListener("blur", this.handlePastingZoneBlur)
		this.pastingZone.addEventListener("keypress", this.handleKeyPress)
		this.pastingZone.addEventListener("paste", this.handlePastingZonePaste)
	}
	componentWillUnmount = () => {
		this.pastingZone.removeEventListener("focus", this.handlePastingZoneFocus)
		this.pastingZone.removeEventListener("blur", this.handlePastingZoneBlur)
		this.pastingZone.removeEventListener("keypress", this.handleKeyPress)
		this.pastingZone.removeEventListener("paste", this.handlePastingZonePaste)
	}
	handlePastingZoneFocus = () => {
		this.setState({ pastingZoneActive: true })
	}
	handlePastingZoneBlur = () => {
		this.setState({ pastingZoneActive: false })
	}
	handlePastingZonePaste = e => {
		let code = e.clipboardData.getData("text/plain").substring(0, 33)
		this.props.onScan(code)
	}
	handleKeyPress = e => {
		if (e.key === "Enter") {
			this.props.onScan(this.state.codeBuffer)
			this.setState({ codeBuffer: "" })
			return
		} else if (!Number.isInteger(Number(e.key))) {
			return
		}
		this.setState({ codeBuffer: this.state.codeBuffer + e.key })
	}
	render() {
		let { t } = this.context
		let { pastingZoneActive } = this.state
		return (
			<div className="PSVScanningZone">
				<div ref={el => (this.pastingZone = el)} className="pastable-zone" tabIndex="-1">
					{pastingZoneActive ? (
						<div className="active-message">{t("active_psv_scanning_zone")}</div>
					) : (
						<div className="inactive-message">{t("inactive_psv_scanning_zone")}</div>
					)}
				</div>
				{this.props.showImportFile && (
					<InputsChangeHandler onChange={this.handleChanges}>
						<PootsyFileInput name="importFile" label={t("upload_psv_input")} />
					</InputsChangeHandler>
				)}
			</div>
		)
	}
}

PSVScanningZone.contextTypes = { t: PropTypes.func }
export default PSVScanningZone
