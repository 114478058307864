import React, { Component } from "react"
import PropTypes from "prop-types"

import { PageLayout } from "./FunctionalDesign"



import pootsyLogo from "../static/images/pootsy-pink-logo.png"

export default class About extends Component {
	render() {
		let { t } = this.context
		let pageHeaderLeft = <h1 className="page-title">{t("about")}</h1>
		return (
			<PageLayout headerLeft={pageHeaderLeft}>
				<div className="about-version-infos">
					<img alt="logo" className="about-pootsy-logo" src={pootsyLogo} />
					<div className="info-line">
						<span className="label">COMMIT HASH: </span>
						<span className="value">{process.env.REACT_APP_COMMIT_HASH_AND_DATE}</span>
					</div>
				</div>
			</PageLayout>
		)
	}
}

About.contextTypes = { t: PropTypes.func }
