import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"
import * as actions from "../actions"
import { history } from "../history"
import ImpactedBookingsModal from "./ImpactedBookingsModal"

import { numberOfHours, bookingHasHoursDifference, getEndTime } from "../actions/checkHours"
import InputsChangeHandler from "./InputsChangeHandler"
import { PootsyTextArea, PootsyFileInput } from "./FunctionalInputs"
import {
	PageLayout,
	ContentLayout,
	OptionBox,
	PootsySubHeading,
	DatedLine,
	SimpleLine,
	StartToEndTime,
	PootsyButton,
	MapMarker,
	PootsyHeading,
	PootsyMultipleValuesBox,
	FlexLineBreak,
} from "./FunctionalDesign"
import ModalLayout from "./ModalLayout"
import BookingDetailsOptionBox from "./BookingDetailsOptionBox"
import ChangeBookingStatusModal from "./ChangeBookingStatusModal"
import AddBookingSVModal from "./AddBookingSVModal"
import NewBooking from "./NewBooking"
import CancelBookingModal from "./CancelBookingModal"
import DeleteBookingModal from "./DeleteBookingModal"
import GoogleMapReact from "google-map-react"
import ActivityCell from "./ActivityCell"
import DirectBookingModificationModal from "./DirectBookingModificationModal"

import arrowBackIcon from "../static/images/arrowback-icon.png"
import monitorIcon from "../static/images/monitor-icon.png"
import fileIcon from "../static/images/file-icon.png"
import greenDotIcon from "../static/images/greendot-icon.png"
import clockIcon from "../static/images/clock-icon.png"
import orangeDotIcon from "../static/images/orangedot-icon.png"
import redDotIcon from "../static/images/reddot-icon.png"
import greyDotIcon from "../static/images/greydot-icon.png"
import cancelIcon from "../static/images/cancel-icon.png"
import recurrenceIcon from "../static/images/recurrence-icon.png"

const defaultMapCenter = { lat: 50.845, lng: 4.354 } // < defaults to centering on brussels
class BookingDetails extends Component {
	state = {
		statusIcon: {
			assigned: clockIcon,
			ongoing: orangeDotIcon,
			finished: greenDotIcon,
			finished_without_confirmation: greenDotIcon,
			problem: redDotIcon,
			no_information: greyDotIcon,
			cancel: cancelIcon,
		},
		draggingOverlayClass: "",
		droppedImages: [],
		draggingCounter: 0,
		timelineMessage: "",
		psvAwaitingDeletionID: "",
	}
	componentWillMount = () => {
		this.setState = this.setState.bind(this)
	}
	componentDidMount = () => {
		this.props.dispatch(actions.fetchBooking(this.props.match.params.id))
	}
	componentWillUnmount = () => {
		this.props.dispatch(actions.bookingDetailsResetUI())
	}
	toggleChangeStatusModal = () => {
		let {
			dispatch,
			bookingDetailsComponent: { showChangeStatusModal },
		} = this.props
		dispatch(actions.bookingDetailsUIChange({ showChangeStatusModal: !showChangeStatusModal }))
	}
	toggleNewBookingModal = () => {
		let {
			dispatch,
			bookingDetailsComponent: { showNewBookingModal },
			currentBooking: { id },
		} = this.props
		dispatch(actions.editBookingInit(id, false))
		dispatch(actions.bookingDetailsUIChange({ showNewBookingModal: !showNewBookingModal }))
	}
	toggleDeleteBookingModal = () => {
		let {
			dispatch,
			bookingDetailsComponent: { showDeleteBookingModal },
			currentBooking: { recurrence, deliveryDate, id, sodexoStatus },
		} = this.props
		dispatch(
			actions.deleteBookingModalInit({
				recurrentBooking: recurrence,
				sentToSodexo: !!sodexoStatus,
				bookingID: id,
				deliveryDate: deliveryDate,
				requestMeta: { pushToPath: "/bookings", pushQuery: "" },
			})
		)
		dispatch(
			actions.bookingDetailsUIChange({ showDeleteBookingModal: !showDeleteBookingModal })
		)
	}
	toggleCancelModal = () => {
		let {
			dispatch,
			bookingDetailsComponent: { showCancelModal },
			currentBooking: { recurrence, deliveryDate, id },
		} = this.props
		if (showCancelModal) {
			dispatch(actions.bookingDetailsUIChange({ showCancelModal: false }))
			return
		}
		dispatch(
			actions.cancelBookingModalInit({
				recurrentBooking: recurrence,
				bookingID: id,
				deliveryDate: deliveryDate,
				requestMeta: { pushToPath: "/bookings", pushQuery: "" },
			})
		)
		dispatch(actions.bookingDetailsUIChange({ showCancelModal: true }))
	}
	toggleAddSVModal = () => {
		let {
			dispatch,
			bookingDetailsComponent: { showAddSVModal },
		} = this.props
		dispatch(actions.bookingDetailsUIChange({ showAddSVModal: !showAddSVModal }))
	}
	toggleDeletePSVModal = e => {
		if (e && e.target.dataset.psvid !== undefined) {
			this.setState({ psvAwaitingDeletionID: e.target.dataset.psvid })
		} else {
			this.setState({ psvAwaitingDeletionID: "" })
		}
		let {
			dispatch,
			bookingDetailsComponent: { showDeletePSVModal },
		} = this.props
		dispatch(actions.bookingDetailsUIChange({ showDeletePSVModal: !showDeletePSVModal }))
	}
	togglePSVReminderModal = () => {
		let {
			dispatch,
			bookingDetailsComponent: { showPSVReminderModal },
		} = this.props
		dispatch(actions.bookingDetailsUIChange({ showPSVReminderModal: !showPSVReminderModal }))
	}
	toggleESVReminderModal = () => {
		let {
			dispatch,
			bookingDetailsComponent: { showESVReminderModal },
		} = this.props
		dispatch(actions.bookingDetailsUIChange({ showESVReminderModal: !showESVReminderModal }))
	}
	backInHistory = () => {
		history.goBack()
	}
	handleDragEnter = e => {
		let newDragCount = this.state.draggingCounter + 1
		this.setState({
			draggingOverlayClass: " dragging",
			draggingCounter: newDragCount,
		})
	}
	handleDragLeave = e => {
		let newDragCount = this.state.draggingCounter - 1
		this.setState(state => ({
			draggingOverlayClass: newDragCount === 0 ? "" : " dragging",
			draggingCounter: newDragCount,
		}))
	}
	handleMessageBoxDrag = e => {
		e.preventDefault()
	}
	handleMessageBoxDrop = e => {
		let { droppedImages } = this.state
		e.preventDefault()
		let dt = e.dataTransfer
		let file = dt.items[0].getAsFile()
		if (file.type.split("/")[0] !== "image") {
			return
		}
		let reader = new FileReader()
		reader.onload = i => {
			this.setState({ droppedImages: [...droppedImages, i.target.result] })
		}
		reader.readAsDataURL(file)
		this.setState({ draggingOverlayClass: "", draggingCounter: 0 })
	}
	handleImageButton = e => {
		let { droppedImages } = this.state
		e.stopPropagation()
		let file = e.target.files[0]
		if (!file) {
			return
		}
		if (file.type.split("/")[0] !== "image") {
			return
		}
		let reader = new FileReader()
		reader.onload = i => {
			this.setState({ droppedImages: [...droppedImages, i.target.result] })
		}
		reader.readAsDataURL(file)
	}
	handleRemoveImage = e => {
		e.stopPropagation()
		let { droppedImages } = this.state
		let { imgindex } = e.target.dataset
		imgindex = parseInt(imgindex, 10)
		this.setState({ droppedImages: droppedImages.filter((e, i) => i !== imgindex) })
	}
	changeBookingStatus = data => {
		let { currentBooking, dispatch } = this.props
		let url = "/front/affiliates/bookings/" + currentBooking.id + "/change_status/"
		let init = { method: "PUT", body: JSON.stringify(data) }
		dispatch(actions.simpleFetch(url, init, "CHANGEBOOKINGSTATUS"))
	}
	handlePSVInputChange = psv => {
		this.props.dispatch(actions.psvInputChange(psv))
	}
	togglePSVSkipCode = () => {
		this.props.dispatch(actions.psvToggleSkipCode())
	}
	getPVSRelatedBookings = PSVCode => {
		let { dispatch, currentBooking } = this.props
		let init = { method: "GET" }
		let url = "/front/affiliates/paper_service_vouchers/related_bookings"
		url += "?code=" + PSVCode + "&booking_id=" + currentBooking.id
		dispatch(actions.simpleFetch(url, init, "PSV_RELATED_BOOKINGS"))
	}
	submitPSV = PSVCode => {
		let { dispatch, currentBooking, PSVCodeCheck } = this.props
		let data = { code: PSVCode }
		if (PSVCodeCheck.skipCode) {
			data = { force: true }
		}
		let init = { method: "POST", body: JSON.stringify(data) }
		let url = `/front/affiliates/bookings/${currentBooking.id}/paper_service_vouchers`
		dispatch(actions.simpleFetch(url, init, "SUBMIT_PSV"))
	}
	handleBookingNoteChange = e => {
		e.stopPropagation()
		let { name, value } = e.target
		this.props.dispatch(actions.editCurrentBooking({ [name]: value }))
	}
	unlinkPsv = e => {
		let { psvAwaitingDeletionID } = this.state
		let { dispatch, currentBooking } = this.props
		let init = {
			method: "POST",
			body: JSON.stringify({ id: psvAwaitingDeletionID, booking_id: currentBooking.id }),
		}
		let url = `/front/affiliates/paper_service_vouchers/unlink`
		dispatch(actions.simpleFetch(url, init, "REMOVE_PSV"))
	}
	submitMessage = () => {
		let { currentBooking, dispatch } = this.props
		let { timelineMessage, droppedImages } = this.state
		let url = "/front/affiliates/bookings/" + currentBooking.id + "/messages/"
		let init = {
			method: "POST",
			body: JSON.stringify({
				message: { body: timelineMessage },
				files: droppedImages.map((e, i) => ({
					attribute: "images",
					filename: Math.random().toString(),
					base64_content: e,
				})),
			}),
		}
		dispatch(actions.simpleFetch(url, init, "SUBMITBOOKINGMESSAGE"))
		this.setState({ droppedImages: [], timelineMessage: "" })
	}
	submitNote = () => {
		let {
			dispatch,
			currentBooking: { id, note },
		} = this.props
		let url = `/front/affiliates/bookings/${id}/update_note`
		let init = { method: "PUT", body: JSON.stringify({ note }) }
		dispatch(actions.simpleFetch(url, init, "SUBMIT_BOOKINGNOTEUPDATE"))
	}
	sendPSVReminder = () => {
		let {
			dispatch,
			currentBooking: { id },
		} = this.props
		let url = `/front/affiliates/bookings/${id}/payment_reminder`
		let init = { method: "POST" }
		dispatch(actions.simpleFetch(url, init, "SEND_PSV_REMINDER"))
		dispatch(actions.bookingDetailsUIChange({ showPSVReminderModal: false }))
	}
	sendESVReminder = () => {
		let {
			dispatch,
			currentBooking: { id },
		} = this.props
		let url = `/front/affiliates/bookings/${id}/payment_reminder`
		let init = { method: "POST" }
		dispatch(actions.simpleFetch(url, init, "SEND_ESV_REMINDER"))
		dispatch(actions.bookingDetailsUIChange({ showESVReminderModal: false }))
	}
	validateSodexoCreationStatus = () => {
		let {
			dispatch,
			currentBooking: { id },
		} = this.props
		let url = `/front/affiliates/sodexo/sodexo_update_booking_status`
		let init = {
			method: "POST",
			body: JSON.stringify({ booking_id: id }),
		}
		dispatch(actions.simpleFetch(url, init, "UPDATE_BOOKING_SODEXO_STATUS"))
	}
	handleLinkedCustomerSwitch = event => {
		let { dispatch } = this.props
		let customerContractId = event.target.value
		dispatch(
			actions.bookingDetailsUIChange({
				showSwitchCustomerConfirmationModal: true,
				customerContractIdStagedForSwitching: customerContractId,
			})
		)
	}
	toggleDirectBookingModificationModal = () => {
		let {
			dispatch,
			currentBooking,
			bookingDetailsComponent: { showDirectBookingModificationModal },
		} = this.props
		if (!showDirectBookingModificationModal) {
			dispatch(
				actions.directBookingModificationStateChange({
					sentToSodexo: !!currentBooking.sodexoStatus,
					bookingID: currentBooking.id,
					startTime: moment(currentBooking.startTime),
					endTime: moment(currentBooking.endTime),
					dayPeriod: currentBooking.dayPeriod,
					voucherType: currentBooking.voucherType,
					customerContractId: currentBooking.customer.customer_contract_id,
					postSubmitAction: actions.fetchBooking(currentBooking.id),
				})
			)
		}
		dispatch(
			actions.bookingDetailsUIChange({
				showDirectBookingModificationModal: !showDirectBookingModificationModal,
			})
		)
	}
	render() {
		let { t } = this.context
		let { statusIcon, draggingOverlayClass, timelineMessage } = this.state
		let {
			dispatch,
			bookingDetailsComponent,
			currentBooking,
			PSVCodeCheck,
			paymentReminders,
			impactedBookings,
		} = this.props
		if (!currentBooking.customer) {
			return null
		}
		let pageHeaderLeft = (
			<div className="booking-details page-header-left">
				<img
					alt="arrow back"
					className="arrow-back"
					src={arrowBackIcon}
					onClick={this.backInHistory}
				/>
				<h1 className="page-title booking-details">
					{t("booking")} #{currentBooking.id.replace(/\D/g, "")}
				</h1>
			</div>
		)
		let pageHeaderRight = (
			<div className="booking-details page-header-right">
				<PootsyButton
					key="a"
					text={t("delete_booking")}
					onClick={this.toggleDeleteBookingModal}
				/>
				{currentBooking.serviceDeliveryStatus === "assigned" && (
					<PootsyButton
						key="b"
						text={t("modify_booking")}
						onClick={this.toggleNewBookingModal}
					/>
				)}
				<PootsyButton
					key="c"
					text={t("cancel_booking")}
					onClick={this.toggleCancelModal}
					disabled={
						currentBooking.sodexoStatus ||
						!["assigned", "ongoing", "finished_without_confirmation"].includes(
							currentBooking.serviceDeliveryStatus
						)
					}
				/>
				<PootsyButton
					key="d"
					text={t("change_booking_status")}
					onClick={this.toggleChangeStatusModal}
					disabled={currentBooking.sodexoStatus}
				/>
			</div>
		)
		const bookingDate = moment(currentBooking.deliveryDate, "YYYY-MM-DD")
		let optionBoxes = (
			<Fragment>
				<BookingDetailsOptionBox
					booking={currentBooking}
					toggleDeletePSVModal={this.toggleDeletePSVModal}
					toggleAddSVModal={this.toggleAddSVModal}
					togglePSVReminderModal={this.togglePSVReminderModal}
					toggleESVReminderModal={this.toggleESVReminderModal}
					validateSodexoCreationStatus={this.validateSodexoCreationStatus}
					paymentReminders={paymentReminders}
					linkedCustomers={bookingDetailsComponent.linkedCustomers}
					toggleDirectBookingModificationModal={this.toggleDirectBookingModificationModal}
				/>
				<OptionBox key="b" boxTitle={t("booking_note")}>
					<PootsyTextArea
						label={t("booking_note")}
						name="note"
						value={currentBooking.note}
						onChange={this.handleBookingNoteChange}
					/>
					{currentBooking.editedKeys.indexOf("note") > -1 && (
						<PootsyButton text={t("update_note")} onClick={this.submitNote} />
					)}
				</OptionBox>
			</Fragment>
		)
		let rightPart = (
			<div className="booking-details-right-part">
				<div className="maps-container">
					<GoogleMapReact
						zoom={13}
						defaultCenter={defaultMapCenter} // ( defined at top of file, otherwise there is a warning at each render because of redefining the variable )
						center={{
							lat: currentBooking.address.latitude,
							lng: currentBooking.address.longitude,
						}}
						bootstrapURLKeys={{ key: "AIzaSyBWy6oLUEJUQxvDllNSsMODOQYRFcycCO4" }}
					>
						<MapMarker
							lat={currentBooking.address.latitude}
							lng={currentBooking.address.longitude}
						/>
					</GoogleMapReact>
				</div>
				<div className="booking-address-and-services">
					<PootsyMultipleValuesBox
						values={[
							{
								label: t("booking_address"),
								values: [
									currentBooking.address.street_number,
									currentBooking.address.street_name,
									currentBooking.address.zip_code,
									currentBooking.address.town,
								],
							},
						]}
					/>
				</div>
			</div>
		)
		return (
			<PageLayout headerLeft={pageHeaderLeft} headerRight={pageHeaderRight}>
				<ContentLayout
					optionsBoxes={optionBoxes}
					rightPart={rightPart}
					customClass="booking-details"
				>
					{impactedBookings.showImpactedBookingsModal && (
						<ImpactedBookingsModal
							onSubmit={() => dispatch(actions.submitDirectBookingModification())}
							triggerActionExpected={actions.DIRECT_BOOKING_MODIFICATION_FAILURE}
						/>
					)}
					{bookingDetailsComponent.showChangeStatusModal && (
						<ChangeBookingStatusModal
							booking={currentBooking}
							onCancel={this.toggleChangeStatusModal}
							onSubmit={this.changeBookingStatus}
						/>
					)}
					{bookingDetailsComponent.showNewBookingModal && (
						<NewBooking closeModal={this.toggleNewBookingModal} />
					)}
					{bookingDetailsComponent.showCancelModal && (
						<CancelBookingModal closeModal={this.toggleCancelModal} />
					)}
					{bookingDetailsComponent.showDeleteBookingModal && (
						<DeleteBookingModal closeModal={this.toggleDeleteBookingModal} />
					)}
					{bookingDetailsComponent.showAddSVModal && (
						<AddBookingSVModal
							customerSodexo={currentBooking.customer.sodexo_reference}
							checkedPSV={PSVCodeCheck}
							closeModal={this.toggleAddSVModal}
							toggleSkipCode={this.togglePSVSkipCode}
							getPVSRelatedBookings={this.getPVSRelatedBookings}
							handlePSVInputChange={this.handlePSVInputChange}
							submitPSV={this.submitPSV}
						/>
					)}
					{bookingDetailsComponent.showDirectBookingModificationModal && (
						<DirectBookingModificationModal
							closeModal={this.toggleDirectBookingModificationModal}
						/>
					)}
					{bookingDetailsComponent.showDeletePSVModal && (
						<ModalLayout
							title={t("delete_psv_modal_title")}
							closeModal={this.toggleDeletePSVModal}
							buttons={[
								<PootsyButton
									key="a"
									text={t("confirm")}
									onClick={this.unlinkPsv}
								/>,
								<PootsyButton
									key="b"
									text={t("cancel")}
									theme="cancel"
									onClick={this.toggleDeletePSVModal}
								/>,
							]}
						>
							{t("delete_psv_modal_prompt")}
						</ModalLayout>
					)}
					{bookingDetailsComponent.showPSVReminderModal && (
						<ModalLayout
							title={t("send_reminder")}
							closeModal={this.togglePSVReminderModal}
							buttons={[
								<PootsyButton
									key="a"
									text={t("confirm")}
									onClick={this.sendPSVReminder}
								/>,
								<PootsyButton
									key="b"
									text={t("cancel")}
									theme="cancel"
									onClick={this.togglePSVReminderModal}
								/>,
							]}
						>
							{t("send_psv_reminder_modal_prompt")}
						</ModalLayout>
					)}
					{bookingDetailsComponent.showESVReminderModal && (
						<ModalLayout
							title={t("send_reminder")}
							closeModal={this.toggleESVReminderModal}
							buttons={[
								<PootsyButton
									key="a"
									text={t("confirm")}
									onClick={this.sendESVReminder}
								/>,
								<PootsyButton
									key="b"
									text={t("cancel")}
									theme="cancel"
									onClick={this.toggleESVReminderModal}
								/>,
							]}
						>
							{t("send_esv_reminder_modal_prompt")}
						</ModalLayout>
					)}
					<div className="booking-details-content-left">
						<DatedLine
							day={bookingDate.format("ddd")}
							date={bookingDate.date()}
							month={bookingDate.format("MMMM")}
							sideIcon={
								currentBooking.recurrence && (
									<img
										alt="recurrent"
										className="recurrence-icon"
										src={recurrenceIcon}
									/>
								)
							}
						>
							<div className="booking-details-line-customer">
								<div className="customer-name">
									{currentBooking.customer.display_name}
								</div>
								<div className="bottom">
									<div className="voucher-type">
										<img
											alt="presentation"
											src={
												currentBooking.voucherType &&
												currentBooking.voucherType.toLowerCase() ===
													"electronic"
													? monitorIcon
													: fileIcon
											}
										/>
										{t(currentBooking.voucherType)}
									</div>
									<div className="status" onClick={this.toggleChangeStatusModal}>
										<div className="tooltip">{t("click_to_change_status")}</div>
										<img
											alt="status-icon"
											src={
												statusIcon[currentBooking.serviceDeliveryStatus] ||
												statusIcon.no_information
											}
										/>
										{t(currentBooking.serviceDeliveryStatus)}
									</div>
								</div>
							</div>
							<div className="booking-details-line-time">
								<div className="start-time">
									<span>{t("start")}: </span>
									{currentBooking.fmtStartTime}
								</div>
								<div className="end-time">
									<span>{t("end")}: </span>
									{currentBooking.fmtEndTime}
								</div>
							</div>
						</DatedLine>
						{["finished", "ongoing", "finished_without_confirmation"].includes(
							currentBooking.serviceDeliveryStatus
						) && (
							<SimpleLine>
								<StartToEndTime
									start={currentBooking.startTime}
									end={getEndTime(currentBooking)}
									realStart={currentBooking.sdStartTime}
									realEnd={currentBooking.sdEndTime}
								/>
								{bookingHasHoursDifference(currentBooking) && (
									<label style={{ color: "red" }}>
										{t("number_of_hours_different")}
										<br />
										{t("number_of_planned_hours")}
									</label>
								)}
								<div className="booking-execution-voucher-count">
									<label>{t("service_vouchers")}:</label>
									<span>{numberOfHours(currentBooking)}</span>
								</div>
								<div className="booking-execution-encoded-collected">
									<label>
										{currentBooking.voucherType.toLowerCase() === "electronic"
											? t("encoded")
											: t("collected")}
									</label>
									<span>
										{currentBooking.voucherType === "electronic"
											? t(currentBooking.payStatus || "")
											: currentBooking.voucherCount ===
											  currentBooking.pServiceVouchersAffiliate.length
											? t("p_payment_encoded")
											: t(
													`p_payment_incomplete_${(() => {
														let missing =
															currentBooking.voucherCount -
															currentBooking.pServiceVouchersAffiliate
																.length
														return missing < 0 ? 0 : missing
													})()}`
											  )}
									</span>
								</div>
								<div className="booking-execution-rating">
									<label>{t("customer_rating")}</label>
									<span>{currentBooking.evaluation}</span>
								</div>
							</SimpleLine>
						)}
						<div className="booking-details-timeline">
							<PootsyHeading text={t("booking_timeline")} />
							<InputsChangeHandler setParentState={this.setState}>
								<div
									className={
										"booking-details-message-editor " + draggingOverlayClass
									}
									onDragOver={this.handleMessageBoxDrag}
									onDrop={this.handleMessageBoxDrop}
									onDragEnter={this.handleDragEnter}
									onDragLeave={this.handleDragLeave}
								>
									<PootsySubHeading text={t("booking_timeline_message_title")} />
									<PootsyTextArea
										label={t("booking_timeline_message_box")}
										name="timelineMessage"
										value={timelineMessage}
									/>
									<div className="prompt-container">
										<div className="prompt">
											{t("booking_timeline_image_dragging_message")}
										</div>
									</div>
									<div className="dropped-images-box">
										{this.state.droppedImages.map((e, i) => (
											<div key={i} className="dropped-image-box">
												<img
													alt="img"
													src={e}
													className="dropped-image"
													onClick={this.handleRemoveImage}
													data-imgindex={i}
												/>
											</div>
										))}
									</div>
									<FlexLineBreak />
									<PootsyFileInput
										name="x"
										accept="image/*"
										onChange={this.handleImageButton}
										label={t("upload_message_image_button")}
									/>
									<PootsyButton
										text={t("send_booking_timeline_message")}
										onClick={this.submitMessage}
									/>
								</div>
							</InputsChangeHandler>
							{currentBooking.activities.map(entry => (
								<ActivityCell event={entry.key} {...entry} key={entry.id} />
							))}
						</div>
					</div>
				</ContentLayout>
			</PageLayout>
		)
	}
}

BookingDetails.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currAffiliate: state.redData.currentAffiliate,
	currentBooking: state.redData.currentBooking,
	bookingDetailsComponent: state.redComponents.bookingDetailsComponent,
	PSVCodeCheck: state.redData.checkPSVCode,
	impactedBookings: state.redComponents.impactedBookingsModal,
})
export default connect(mapStateToProps)(BookingDetails)
