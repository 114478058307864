import React, { Component } from "react"
import PropTypes from "prop-types"

import ModalLayout from "../components/ModalLayout"
import { PootsyTextInput, PootsyRadioInputs } from "./FunctionalInputs"
import { PootsyButton, PootsySubHeading, SimpleInfoBox } from "./FunctionalDesign"

import PSVScanner from "./PSVScanner"

class OrderPaymentModal extends Component {
	renderPSVPaymentSection = () => {
		let { order } = this.props
		return (
			<PSVScanner
				scanningFor="laundry_order"
				subjectID={order.id}
				workerSodexoRef={order.worker.sodexoRef}
				customerSodexoRef={order.customer.sodexoRef}
				hideSendButton={true}
				counter={order.billableSV}
			/>
		)
	}
	renderESVPaymentSection = () => {
		let {
			order: { sodexoStatus },
		} = this.props
		let { t } = this.context
		return <SimpleInfoBox label={t("status")} value={t(sodexoStatus) || t("unknown")} />
	}
	render() {
		let { t } = this.context
		let { order, closeModal, handleChanges, submitOrderPayment } = this.props
		return (
			<ModalLayout
				customClass="OrderPaymentModal"
				title={t("workshop_order_payment_modal_title")}
				closeModal={closeModal}
				withInputsChangeHandler={true}
				handleChanges={handleChanges}
				buttons={[
					<PootsyButton
						key="a"
						text={t("pay_workshop_order")}
						onClick={submitOrderPayment}
					/>,
					<PootsyButton key="b" text={t("cancel")} theme="cancel" onClick={closeModal} />,
				]}
			>
				{!order.customer.sodexoRef && (
					<div className="customer-without-sodexo-warning">
						{t("customer_without_sodexo_warning")}
					</div>
				)}
				<div className="sv-section">
					<PootsySubHeading text={t("service_vouchers")} />
					<div className="infos">
						<SimpleInfoBox
							label={t("billable_service_voucher")}
							value={order.billableSV}
						/>
						{order.svType === "paper"
							? this.renderPSVPaymentSection()
							: this.renderESVPaymentSection()}
					</div>
				</div>
				<div className="cash-section">
					<PootsySubHeading text={t("cash_total")} />
					<div className="inputs">
						<SimpleInfoBox
							label={t("estimated_price")}
							value={
								order.quote.details.find(entry => entry.categoryName === "CASH")
									.totalDetails.category_total
							}
						/>
						<PootsyTextInput
							label={t("cash_total")}
							name="cashReceived"
							type="number"
							value={order.cashReceived}
						/>
						<PootsyRadioInputs
							groupName="paymentMethod"
							groupLabel={t("payment_method")}
							selected={order.paymentMethod}
							options={[
								{ id: "BANK_CARD", label: t("BANK_CARD"), value: "BANK_CARD" },
								{ id: "CASH", label: t("CASH"), value: "CASH" },
								{ id: "OTHER", label: t("OTHER"), value: "OTHER" },
							]}
						/>
					</div>
				</div>
			</ModalLayout>
		)
	}
}

OrderPaymentModal.contextTypes = { t: PropTypes.func }
export default OrderPaymentModal
