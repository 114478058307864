import React, { Component } from "react"
import PropTypes from "prop-types"

export default class Spinner extends Component {
	render() {
		let { t } = this.context
		let { messages } = this.props
		return (
			<div className="pootsy-spinner">
				<div className="spinner-wrapper">
					<div className="svg-container">
						<div className="spinning" />
						<svg
							className="logo-svg"
							width="100%"
							height="100%"
							viewBox="0 0 5800 9000"
						>
							<defs>
								<linearGradient id="grad3" x1="0%" y1="0%" x2="100%" y2="100%">
									<stop offset="5%" stopColor="#F663A2" />
									<stop offset="95%" stopColor="#FDBF8A" />
								</linearGradient>
							</defs>
							<g className="paths-group">
								<path
									fill="url(#grad3)"
									d="M2005 8786 c-112 -49 -290 -401 -517 -1021 -131 -355 -149 -410 -194 -570 -25 -88 -58 -200 -75 -250 -83 -249 -296 -1032 -414 -1525 -124 -517 -305 -1380 -305 -1455 0 -13 -4 -26 -9 -29 -14 -9 -41 -258 -66 -611 -31 -436 -12 -752 58 -944 38 -106 80 -155 150 -176 58 -17 214 -20 269 -5 41 12 92 50 110 84 6 11 17 104 24 206 14 193 63 629 90 785 18 106 92 413 128 534 15 47 26 95 26 108 0 30 91 301 192 573 115 309 270 691 563 1385 141 336 284 675 317 755 33 80 102 241 155 359 52 117 125 288 163 380 37 91 97 227 134 302 76 157 79 178 41 296 -97 302 -441 707 -681 801 -71 28 -124 34 -159 18z"
								/>
								<path
									fill="#F663A2"
									d="M325 2377 c-142 -112 -213 -212 -251 -352 -21 -79 -29 -281 -15 -371 59 -384 330 -770 768 -1098 67 -50 179 -125 250 -166 127 -75 451 -220 580 -260 89 -28 190 -30 229 -6 87 55 111 111 52 120 -200 31 -259 43 -360 72 -304 87 -596 227 -831 397 -73 53 -89 72 -30 35 540 -333 1189 -506 1593 -422 278 57 435 208 390 374 -15 54 -68 110 -104 110 -14 0 -77 -15 -139 -34 -63 -19 -174 -47 -248 -62 -118 -24 -156 -27 -319 -27 -102 0 -207 5 -235 10 -45 8 -35 10 95 17 601 31 979 168 1095 396 61 120 52 265 -19 322 -51 42 -124 21 -441 -125 -112 -51 -320 -127 -348 -127 -6 0 71 42 173 93 229 115 340 188 451 297 93 92 129 149 129 204 0 96 -78 176 -173 176 -39 0 -56 -8 -123 -56 -128 -92 -222 -154 -313 -206 -101 -58 -102 -59 59 70 69 55 146 121 173 146 42 41 47 50 47 89 0 55 -28 94 -73 102 -47 9 -70 -3 -202 -108 -66 -53 -154 -116 -195 -141 -78 -46 -215 -109 -170 -78 182 126 358 293 420 397 30 51 35 68 35 122 0 57 -3 65 -33 95 -27 27 -40 33 -76 33 -60 0 -85 -26 -148 -149 -95 -184 -217 -323 -336 -381 -114 -56 -262 -43 -317 29 -13 17 -44 88 -68 158 -51 146 -77 195 -148 272 -64 70 -78 72 -77 9 3 -127 -119 -208 -312 -207 -78 0 -105 4 -147 23 -87 38 -164 139 -179 230 -4 23 -12 41 -18 41 -6 0 -47 -28 -91 -63z"
								/>
								<path
									className="sparkles one"
									d="M3917 1958 c-52 -137 -121 -206 -262 -264 l-75 -30 79 -32 c137 -57 192 -112 255 -255 l33 -76 32 79 c54 132 119 197 251 251 l79 32 -76 33 c-156 68 -204 122 -279 312 -8 20 -12 14 -37 -50z"
								/>
								<path
									className="sparkles two"
									d="M4820 1860 c0 -29 -51 -90 -92 -111 l-41 -21 45 -25 c32 -17 54 -40 71 -71 l26 -47 11 30 c14 38 54 80 91 95 35 15 37 26 7 34 -34 9 -65 39 -93 88 -14 24 -25 36 -25 28z"
								/>
								<path
									className="sparkles three"
									d="M5332 1700 c-32 -83 -97 -148 -185 -184 l-67 -29 67 -29 c86 -36 144 -93 180 -175 15 -35 30 -63 33 -63 3 0 14 23 24 50 27 75 97 147 180 185 l69 32 -75 36 c-88 42 -129 85 -170 174 -15 35 -29 63 -31 63 -1 0 -13 -27 -25 -60z"
								/>
								<path
									className="sparkles four"
									d="M4565 1483 c-40 -106 -89 -158 -195 -206 l-65 -29 62 -25 c89 -35 136 -81 181 -173 l38 -78 24 62 c30 79 103 153 183 186 31 13 57 26 57 30 0 3 -23 14 -52 25 -79 31 -150 102 -186 186 l-29 70 -18 -48z"
								/>
								<path
									className="sparkles five"
									d="M3399 1399 c-17 -30 -39 -52 -72 -70 l-47 -25 44 -22 c31 -15 53 -35 72 -68 l27 -46 18 35 c21 41 70 84 106 93 24 6 23 7 -14 24 -42 19 -73 49 -95 94 l-15 29 -24 -44z"
								/>
								<path
									className="sparkles six"
									d="M4050 1085 c0 -23 -61 -87 -99 -103 l-35 -14 34 -14 c45 -20 68 -41 92 -88 l19 -38 21 41 c20 41 56 73 99 90 23 8 22 9 -18 29 -42 22 -77 59 -88 93 -7 22 -25 26 -25 4z"
								/>
								<path
									className="sparkles seven"
									d="M5100 1071 c-15 -37 -56 -76 -95 -91 l-30 -12 35 -14 c46 -20 68 -41 92 -88 l19 -38 21 41 c20 41 56 73 99 90 23 8 22 9 -18 29 -42 22 -77 59 -88 93 -9 27 -21 24 -35 -10z"
								/>
							</g>
						</svg>
					</div>
					{Object.keys(messages).length > 0 && (
						<div className="spinner-message">
							{Object.keys(messages).map(e => (
								<div key={e}>{t(messages[e])}</div>
							))}
						</div>
					)}
				</div>
			</div>
		)
	}
}

Spinner.contextTypes = { t: PropTypes.func }
