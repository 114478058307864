import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import * as actions from "../actions"

import {
	ContentLayout,
	OptionBox,
	PootsyButton,
	SquadLine,
	ConfirmationModal,
} from "./FunctionalDesign"
import { PootsyTextInput } from "./FunctionalInputs"
import ModalLayout from "./ModalLayout"
import UpdateSquadModal from "./UpdateSquadModal"
import InputsChangeHandler from "./InputsChangeHandler"

class SettingsTeams extends Component {
	state = {
		showCreateSquadModal: false,
		showUpdateSquadModal: false,
		showDeleteSquadConfirmationModal: false,
		squadStagedForDeletion: undefined,
		teamName: "",
		workerSearchValue: "",
		chosenWorkers: [],
		editedTeamID: "",
		workersStagedForRemovalIDs: [],
		teamSearch: "",
	}
	componentDidMount = () => {
		this.props.dispatch(actions.fetchTeams())
		this.fetchWorkersWithoutSquads()
	}
	fetchWorkersWithoutSquads = () => {
		let init = { method: "GET" }
		let url = "/front/affiliates/squads/affiliate_workers_without_squads"
		this.props.dispatch(actions.simpleFetch(url, init, "FETCH_WORKERS_WITHOUT_SQUADS"))
	}
	toggleCreateSquadModal = () => {
		this.setState(state => ({
			showCreateSquadModal: !state.showCreateSquadModal,
			teamName: "",
		}))
	}
	toggleUpdateSquadModal = e => {
		let editedTeamID = e ? e.target.dataset.teamid : ""
		let { showUpdateSquadModal } = this.state
		this.setState({
			showUpdateSquadModal: !showUpdateSquadModal,
			editedTeamID: editedTeamID,
		})
		this.resetUpdateSquadModal()
	}
	resetUpdateSquadModal = () => {
		this.setState({ chosenWorkers: [], workersStagedForRemovalIDs: [] })
		this.resetAllSuggestions()
	}
	resetAllSuggestions = () => {
		this.props.dispatch(actions.resetWorkerSuggestions())
	}
	handleCreationFormChanges = changes => {
		this.setState(changes)
	}
	handleSearchTeams = e => {
		let { value } = e.target
		this.setState({ teamSearch: value })
	}
	scheduleApiCall = (value, fetchMeta) => {
		let { queuedCall } = this.state
		clearTimeout(queuedCall)
		let futureCall
		if (value.length < 3) {
			return
		} else {
			futureCall = setTimeout(() => this.fetchSuggestions(value, fetchMeta), 800)
		}
		this.setState({ queuedCall: futureCall })
	}
	handleSearchWorkers = e => {
		let { value } = e.target
		let { dispatch } = this.props
		if (value === "") {
			dispatch(actions.resetWorkerSuggestions())
			this.setState({ workerSearchValue: "" })
			return
		}
		this.scheduleApiCall(value, {
			url: "/front/affiliates/affiliate_workers",
			action: "WORKERSUGGESTIONS",
		})
		this.setState({ workerSearchValue: value })
	}
	fetchSuggestions = (search, { url, action }) => {
		let query = "?filter_name=" + search
		let init = { method: "GET" }
		this.props.dispatch(actions.simpleFetch(url + query, init, action))
	}
	handleWorkerClick = worker => {
		let { dispatch } = this.props
		let newChosenWorkers = [...this.state.chosenWorkers, worker]
		this.setState({ chosenWorkers: newChosenWorkers, workerSearchValue: "" })
		dispatch(actions.resetWorkerSuggestions())
	}
	removeExistingWorker = e => {
		let { id } = e.target.dataset
		let { workersStagedForRemovalIDs } = this.state
		if (workersStagedForRemovalIDs.indexOf(id) === -1) {
			this.setState({ workersStagedForRemovalIDs: [...workersStagedForRemovalIDs, id] })
		} else {
			this.setState({
				workersStagedForRemovalIDs: workersStagedForRemovalIDs.filter(
					entry => entry !== id
				),
			})
		}
	}
	removeAddedWorker = e => {
		let { id } = e.target.dataset
		this.setState({ chosenWorkers: this.state.chosenWorkers.filter(entry => entry.id !== id) })
	}
	submitNewTeam = () => {
		let { teamName } = this.state
		let init = { method: "POST", body: JSON.stringify({ name: teamName }) }
		let url = "/front/affiliates/squads"
		this.props.dispatch(actions.simpleFetch(url, init, "CREATE_TEAM"))
		this.toggleCreateSquadModal()
	}
	submitUpdateTeam = () => {
		let { teams } = this.props
		let { chosenWorkers, workersStagedForRemovalIDs, editedTeamID } = this.state
		let editedTeam = teams.find(entry => entry.id === editedTeamID)
		let workersToAdd = chosenWorkers.concat(editedTeam.attributes.affiliate_workers)
		let init = {
			method: "PUT",
			body: JSON.stringify({
				affiliate_worker_ids: workersToAdd
					.filter(entry => workersStagedForRemovalIDs.indexOf(entry.id.toString()) === -1)
					.map(entry => entry.id),
				customer_contract_ids: [], // << not updatable for now
			}),
		}
		let url = "/front/affiliates/squads/" + editedTeamID
		this.props.dispatch(actions.simpleFetch(url, init, "UPDATE_TEAM"))
		this.toggleUpdateSquadModal()
	}
	subscribeToTeam = e => {
		let { name, checked } = e.target
		let id = name
		let init = { method: "POST", body: JSON.stringify({ value: checked.toString() }) }
		let url = "/front/affiliates/squads/" + id + "/subscribe"
		this.props.dispatch(actions.simpleFetch(url, init, "SUBSCRIBE_TO_TEAM"))
	}
	toggleDeleteSquadModal = e => {
		let { teamid } = e.target.dataset
		this.setState({
			showDeleteSquadConfirmationModal: !this.state.showDeleteSquadConfirmationModal,
			squadStagedForDeletion: teamid,
		})
	}
	deleteSquad = () => {
		let { squadStagedForDeletion } = this.state
		let init = { method: "DELETE" }
		let url = "/front/affiliates/squads/" + squadStagedForDeletion
		this.props.dispatch(
			actions.simpleFetch(url, init, "DELETE_TEAM", { deletedId: squadStagedForDeletion })
		)
		this.setState({ showDeleteSquadConfirmationModal: false })
	}
	render() {
		let { t } = this.context
		let { currentUser, teams, noTeamsWorkers, workerSuggestions } = this.props
		let {
			showCreateSquadModal,
			showUpdateSquadModal,
			workerSearchValue,
			teamName,
			chosenWorkers,
			editedTeamID,
			workersStagedForRemovalIDs,
			teamSearch,
			showDeleteSquadConfirmationModal,
		} = this.state

		let optionsBoxes = (
			<OptionBox boxTitle={t("squads")}>
				<PootsyButton text={t("create_new_squad")} onClick={this.toggleCreateSquadModal} />
				<PootsyTextInput
					label={t("search_squads")}
					value={teamSearch}
					onChange={this.handleSearchTeams}
				/>
				<div className="workers-without-team">
					<div className="workers-without-team-title">
						{t("workers_without_team_title")}
					</div>
					<div className="workers-without-team-list">
						{noTeamsWorkers.map(entry => (
							<div key={entry.id} className="no-team-worker">
								{entry.attributes.display_name}
							</div>
						))}
					</div>
				</div>
			</OptionBox>
		)
		let createModalButtons = [
			<PootsyButton key="a" text={t("save")} onClick={this.submitNewTeam} />,
			<PootsyButton
				key="b"
				text={t("cancel")}
				theme="cancel"
				onClick={this.toggleCreateSquadModal}
			/>,
		]
		if (teamSearch !== "") {
			teams = teams.filter(entry => {
				let workersMatch = entry.attributes.affiliate_workers.some(entry =>
					entry.name.toLowerCase().includes(teamSearch.toLowerCase())
				)
				let customersMatch = entry.attributes.customer_contracts.some(entry =>
					entry.name.toLowerCase().includes(teamSearch.toLowerCase())
				)
				let teamNameMatch = entry.attributes.name
					.toLowerCase()
					.includes(teamSearch.toLowerCase())
				if (workersMatch || customersMatch || teamNameMatch) {
					return true
				}
				return false
			})
		}
		return (
			<ContentLayout customClass="settings-team" optionsBoxes={optionsBoxes}>
				{showCreateSquadModal && (
					<ModalLayout
						title={t("create_new_squad")}
						buttons={createModalButtons}
						closeModal={this.toggleCreateSquadModal}
					>
						<InputsChangeHandler
							onChange={this.handleCreationFormChanges}
							customClass="create-squad-modal"
						>
							<PootsyTextInput label={t("name")} name="teamName" value={teamName} />
						</InputsChangeHandler>
					</ModalLayout>
				)}
				{showUpdateSquadModal && (
					<UpdateSquadModal
						workerSearchValue={workerSearchValue}
						workerSuggestions={workerSuggestions}
						chosenWorkers={chosenWorkers}
						workersStagedForRemovalIDs={workersStagedForRemovalIDs}
						submitUpdateTeam={this.submitUpdateTeam}
						toggleUpdateSquadModal={this.toggleUpdateSquadModal}
						handleSearchWorkers={this.handleSearchWorkers}
						handleWorkerClick={this.handleWorkerClick}
						editedTeam={teams.find(entry => entry.id === editedTeamID)}
						removeExistingWorker={this.removeExistingWorker}
						removeAddedWorker={this.removeAddedWorker}
					/>
				)}
				{showDeleteSquadConfirmationModal && (
					<ConfirmationModal
						title={t("delete_squad")}
						closeModal={this.toggleDeleteSquadModal}
						yesButton={<PootsyButton text={t("yes")} onClick={this.deleteSquad} />}
						noButton={
							<PootsyButton
								text={t("no")}
								theme="cancel"
								onClick={this.toggleDeleteSquadModal}
							/>
						}
					/>
				)}
				<div className="teams">
					{teams.map(entry => (
						<SquadLine
							key={entry.id}
							squad={entry}
							userIsInTeam={currentUser.teams.map(e => e.id).includes(entry.id)}
							subscribeToTeam={this.subscribeToTeam}
							toggleUpdateSquadModal={this.toggleUpdateSquadModal}
							deleteSquad={this.toggleDeleteSquadModal}
						/>
					))}
				</div>
			</ContentLayout>
		)
	}
}

SettingsTeams.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currentUser: state.redData.currentUser,
	teams: state.redData.teams,
	noTeamsWorkers: state.redData.noTeamsWorkers,
	workerSuggestions: state.redData.workerSuggestions,
})
export default connect(mapStateToProps)(SettingsTeams)
