import React from "react"

export const ListExpand = ({ link }) => (
	<a href={link} target="_blank" rel="noopener noreferrer">
		<svg className="list-expand-svg" width={13} height={13} viewBox="0 0 13 13">
			<g fill="#8C8C8C" fillRule="nonzero" stroke="#8C8C8C" strokeWidth={0.5}>
				<path d="M11.656 7.188a.351.351 0 0 0-.344.343v3.782H1.688V1.687h3.78a.354.354 0 0 0 .345-.343A.361.361 0 0 0 5.468 1H1.687A.687.687 0 0 0 1 1.688v9.624c0 .38.308.688.688.688h9.624c.38 0 .688-.308.688-.688V7.527a.33.33 0 0 0-.344-.338z" />
				<path d="M11.843 1H8.405a.344.344 0 0 0 0 .688h2.611L4.033 8.67a.341.341 0 0 0 .483.483l6.983-6.983v2.611a.344.344 0 0 0 .687 0V1.344A.35.35 0 0 0 11.843 1z" />
			</g>
		</svg>
	</a>
)
