import React, { Component } from "react"
import PropTypes from "prop-types"

import { PootsyButton } from "./FunctionalDesign"
import ModalLayout from "./ModalLayout"

import moment from "moment"

export default class DeleteAbsenceWarningModal extends Component {
	render() {
		let { t } = this.context
		let { toggleModal, impactedOAs, deleteAbsence } = this.props
		return (
			<ModalLayout
				formDivClass="delete-absence-warning-modal"
				title={t("delete_absence")}
				closeModal={toggleModal}
				buttons={[
					<PootsyButton
						key="a"
						text={t("absence_cancel.keep_booking_cancelled")}
						dataset={{ "data-kbc": true }}
						onClick={deleteAbsence}
					/>,
					<PootsyButton
						key="b"
						text={t("absence_cancel.uncancel_bookings")}
						dataset={{ "data-kbc": false }}
						onClick={deleteAbsence}
					/>,
				]}
			>
				<div className="warning-message">{t("absence_cancel.warning_modal_message")}</div>
				<div className="impacted-bookings">
					{impactedOAs.map(oa => (
						<div key={oa.id} className="impacted-booking">
							<div className="column date">
								{moment(oa.scheduled_date).format("dddd, Do MMMM YYYY")}
							</div>
							<div className="column start-end">
								{moment(oa.start_time).format("HH:mm")} -{" "}
								{moment(oa.end_time).format("HH:mm")}
							</div>
						</div>
					))}
				</div>
			</ModalLayout>
		)
	}
}

DeleteAbsenceWarningModal.contextTypes = { t: PropTypes.func }
