import { CALL_API } from "../middleware/api"
import moment from "moment"
import "moment/locale/fr"
import "moment/locale/nl"
import { history } from "../history"
import { parseQuery } from "../helpers/qs"
import { intercomLoader } from "../helpers/intercomLoader"
import * as actions from "./acURLQueries.js"
import { Sentry } from "../sentryInit"
import { env } from "../config"

// debug
export const REHYDRATE_FROZEN_STATE = "REHYDRATE_FROZEN_STATE"
export const rehydrateFrozenState = frozenState => ({ type: REHYDRATE_FROZEN_STATE, frozenState })

export const NEWWORKERFAKESTATE = "NEWWORKERFAKESTATE"
export const newWorkerQuickPrefill = prefill => ({ type: NEWWORKERFAKESTATE, ...prefill })

export const STORE_COMPONENT_STATE = "STORE_COMPONENT_STATE"
export const storeComponentState = (componentName, data) => ({
	data,
	componentName,
	type: STORE_COMPONENT_STATE,
})

// app
export const LOGOUT = "LOGOUT"
export const logout = () => ({ type: LOGOUT })

export const processLogout = () => (dispatch, getState) => {
	let {
		redComponents: {
			agenda: { workersFilters, customersFilters, economicUnemploymentFilters, zipFilters },
		},
	} = getState()
	dispatch(actions.storeQuery("Agenda"))
	dispatch(
		storeComponentState("Agenda", {
			workersFilters,
			customersFilters,
			economicUnemploymentFilters,
			zipFilters,
		})
	)
	dispatch(logout())
	history.push("/")
}

export const SET_LANG = "SET_LANG"
export const setLanguage = lang => ({ type: SET_LANG, lang })

export const SHOW_SPINNER = "SHOW_SPINNER"
export const HIDE_SPINNER = "HIDE_SPINNER"
export const showSpinner = (mess, requestId) => ({ type: SHOW_SPINNER, mess, requestId })
export const hideSpinner = requestId => ({ type: HIDE_SPINNER, requestId })

export const WARNING_OLD_VERSION_USAGE = "WARNING_OLD_VERSION_USAGE"
export const warningOldVersionUsage = () => ({ type: WARNING_OLD_VERSION_USAGE })

export const WARNING_NOT_CHROME = "WARNING_NOT_CHROME"
export const warningNotChrome = () => ({ type: WARNING_NOT_CHROME })

export const WARNING_MISSING_CONVENTIONS = "WARNING_MISSING_CONVENTIONS"
export const warningMissingConventions = () => ({ type: WARNING_MISSING_CONVENTIONS })

export const TOKENCHECKED = "TOKENCHECKED"
export const tokenChecked = () => ({ type: TOKENCHECKED })

export const SELECT_AFFILIATE = "SELECT_AFFILIATE"
export const selectAffiliate = chosenAffiliate => ({ type: SELECT_AFFILIATE, chosenAffiliate })

export const SODEXO_WAITING_FOR_VALIDATION = "SODEXO_WAITING_FOR_VALIDATION"
export const SodexoWaitingForValidation = number => ({
	type: SODEXO_WAITING_FOR_VALIDATION,
	number: number,
})

export const RESET_VALID_SODEXO = "RESET_VALID_SODEXO"
export const resetValidSodexo = () => ({ type: RESET_VALID_SODEXO })

export const ACKNOWLEDGE_REQUEST_SUCCESS = "ACKNOWLEDGE_REQUEST_SUCCESS"
export const acknowledgeRequestSuccess = successType => ({
	type: ACKNOWLEDGE_REQUEST_SUCCESS,
	successType,
})

export const ACKNOWLEDGE_ERRORS = "ACKNOWLEDGE_ERRORS"
export const acknowledgeErrors = () => ({ type: ACKNOWLEDGE_ERRORS })

export const BACKENDERROR = "BACKENDERROR"
export const backendError = (error, status) => ({ type: BACKENDERROR, error, status })

export const ACKNOWLEDGE_WARNINGS = "ACKNOWLEDGE_WARNINGS"
export const acknowledgeWarnings = () => ({ type: ACKNOWLEDGE_WARNINGS })

export const BACKEND_WARNINGS = "BACKEND_WARNINGS"
export const showBackendWarnings = messages => ({ type: BACKEND_WARNINGS, messages })

export const FLASH_MESSAGE = "FLASH_MESSAGE"
export const flashMessage = message => ({ type: FLASH_MESSAGE, message })

export const STORE_META_STATE = "STORE_META_STATE"
export const storeMetaState = (componentName, data) => ({
	data,
	componentName,
	type: STORE_META_STATE,
})

export const ONE_BOOKING_CANCELLED = "one_booking_cancelled"
export const ALL_BOOKINGS_CANCELLED = "all_bookings_cancelled"
export const CUSTOMER_CHAT_MESSAGE = "customer_chat_message"
export const WORKER_CHAT_MESSAGE = "worker_chat_message"
export const NEW_CUSTOMER_UNAVAILABILITY = "new_customer_unavailability"
export const ASK_AFFILIATE_TO_FORGET_CUSTOMER = "ask_affiliate_to_forget_customer"
export const CONVENTION_SEND = "convention_send"
export const UNAVAILABILITY_APPROVAL = "unavalability_approval"
export const UNAVAILABILITY_DEMAND = "unavailability_demand"
export const NEW_TASK_ASSIGNEE = "new_task_assignee"
export const SUPPORTED_NOTIFICATIONS = [
	ONE_BOOKING_CANCELLED,
	ALL_BOOKINGS_CANCELLED,
	CUSTOMER_CHAT_MESSAGE,
	WORKER_CHAT_MESSAGE,
	NEW_CUSTOMER_UNAVAILABILITY,
	ASK_AFFILIATE_TO_FORGET_CUSTOMER,
	CONVENTION_SEND,
	UNAVAILABILITY_APPROVAL,
	UNAVAILABILITY_DEMAND,
	NEW_TASK_ASSIGNEE,
]

/*
	Thunks
*/
// MULTI-PURPOSE FETCH ---------------------------------------------------------------------
export const ALLAREAS_REQUEST = "ALLAREAS_REQUEST"
export const ALLAREAS_SUCCESS = "ALLAREAS_SUCCESS"
export const ALLAREAS_FAILURE = "ALLAREAS_FAILURE"

export const FETCH_SUPERAREAS_REQUEST = "FETCH_SUPERAREAS_REQUEST"
export const FETCH_SUPERAREAS_SUCCESS = "FETCH_SUPERAREAS_SUCCESS"
export const FETCH_SUPERAREAS_FAILURE = "FETCH_SUPERAREAS_FAILURE"

export const SAVE_WORKER_DOCUMENT_REQUEST = "SAVE_WORKER_DOCUMENT_REQUEST"
export const SAVE_WORKER_DOCUMENT_SUCCESS = "SAVE_WORKER_DOCUMENT_SUCCESS"
export const SAVE_WORKER_DOCUMENT_FAILURE = "SAVE_WORKER_DOCUMENT_FAILURE"

export const DELETE_WORKER_DOCUMENT_REQUEST = "DELETE_WORKER_DOCUMENT_REQUEST"
export const DELETE_WORKER_DOCUMENT_SUCCESS = "DELETE_WORKER_DOCUMENT_SUCCESS"
export const DELETE_WORKER_DOCUMENT_FAILURE = "DELETE_WORKER_DOCUMENT_FAILURE"

export const SUBMIT_RENDER_TEMPLATE_REQUEST = "SUBMIT_RENDER_TEMPLATE_REQUEST"
export const SUBMIT_RENDER_TEMPLATE_SUCCESS = "SUBMIT_RENDER_TEMPLATE_SUCCESS"
export const SUBMIT_RENDER_TEMPLATE_FAILURE = "SUBMIT_RENDER_TEMPLATE_FAILURE"

export const FETCH_DOCUMENT_TEMPLATES_REQUEST = "FETCH_DOCUMENT_TEMPLATES_REQUEST"
export const FETCH_DOCUMENT_TEMPLATES_SUCCESS = "FETCH_DOCUMENT_TEMPLATES_SUCCESS"
export const FETCH_DOCUMENT_TEMPLATES_FAILURE = "FETCH_DOCUMENT_TEMPLATES_FAILURE"

export const SUBMIT_DOCUMENT_TEMPLATE_REQUEST = "SUBMIT_DOCUMENT_TEMPLATE_REQUEST"
export const SUBMIT_DOCUMENT_TEMPLATE_SUCCESS = "SUBMIT_DOCUMENT_TEMPLATE_SUCCESS"
export const SUBMIT_DOCUMENT_TEMPLATE_FAILURE = "SUBMIT_DOCUMENT_TEMPLATE_FAILURE"

export const UPDATE_DOCUMENT_TEMPLATE_REQUEST = "UPDATE_DOCUMENT_TEMPLATE_REQUEST"
export const UPDATE_DOCUMENT_TEMPLATE_SUCCESS = "UPDATE_DOCUMENT_TEMPLATE_SUCCESS"
export const UPDATE_DOCUMENT_TEMPLATE_FAILURE = "UPDATE_DOCUMENT_TEMPLATE_FAILURE"

export const DELETE_DOCUMENT_TEMPLATE_REQUEST = "DELETE_DOCUMENT_TEMPLATE_REQUEST"
export const DELETE_DOCUMENT_TEMPLATE_SUCCESS = "DELETE_DOCUMENT_TEMPLATE_SUCCESS"
export const DELETE_DOCUMENT_TEMPLATE_FAILURE = "DELETE_DOCUMENT_TEMPLATE_FAILURE"

const simpleFetchCall = (endpoint, init, types, requestMeta) => ({
	[CALL_API]: { types: types, endpoint: endpoint, init: init },
	requestMeta,
})
export const simpleFetch = (endpoint, init, type, requestMeta) => (dispatch, getState) => {
	let types = [type + "_REQUEST", type + "_SUCCESS", type + "_FAILURE"]
	return dispatch(simpleFetchCall(endpoint, init, types, requestMeta))
}

//------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------
export const AUTH_REQUEST = "AUTH_REQUEST"
export const AUTH_SUCCESS = "AUTH_SUCCESS"
export const AUTH_FAILURE = "AUTH_FAILURE"

export const FETCH_AFFILIATE = "FETCH_AFFILIATE"
export const FETCH_AFFILIATE_REQUEST = "FETCH_AFFILIATE_REQUEST"
export const FETCH_AFFILIATE_SUCCESS = "FETCH_AFFILIATE_SUCCESS"
export const FETCH_AFFILIATE_FAILURE = "FETCH_AFFILIATE_FAILURE"

export const CHECKTOKEN_REQUEST = "CHECKTOKEN_REQUEST"
export const CHECKTOKEN_SUCCESS = "CHECKTOKEN_SUCCESS"
export const CHECKTOKEN_FAILURE = "CHECKTOKEN_FAILURE"

export const CHECKSODEXO_REQUEST = "CHECKSODEXO_REQUEST"
export const CHECKSODEXO_SUCCESS = "CHECKSODEXO_SUCCESS"
export const CHECKSODEXO_FAILURE = "CHECKSODEXO_FAILURE"
const fetchCheckSodexoCall = (endpoint, init) => ({
	[CALL_API]: {
		types: [CHECKSODEXO_REQUEST, CHECKSODEXO_SUCCESS, CHECKSODEXO_FAILURE],
		endpoint: endpoint,
		init: init,
	},
})
export const fetchCheckSodexo = (endpoint, init, number) => (dispatch, getState) => {
	dispatch(SodexoWaitingForValidation(number))
	return dispatch(fetchCheckSodexoCall(endpoint, init))
}

// SEQUENCES -------------------------------------------------------------------------------

export const FETCH_REPLACEMENT_TRANSLATIONS = "FETCH_REPLACEMENT_TRANSLATIONS"
export const FETCH_REPLACEMENT_TRANSLATIONS_REQUEST = "FETCH_REPLACEMENT_TRANSLATIONS_REQUEST"
export const FETCH_REPLACEMENT_TRANSLATIONS_SUCCESS = "FETCH_REPLACEMENT_TRANSLATIONS_SUCCESS"
export const FETCH_REPLACEMENT_TRANSLATIONS_FAILURE = "FETCH_REPLACEMENT_TRANSLATIONS_FAILURE"
export const fetchRepacementTranslations = () => (dispatch, getState) => {
	let url = "/front/affiliates/translations"
	let init = { method: "GET" }
	dispatch(simpleFetch(url, init, FETCH_REPLACEMENT_TRANSLATIONS))
}

export const LONG_POLLING_CHANGE = "LONG_POLLING_CHANGE"
export const longPollingChange = changes => ({ changes, type: "LONG_POLLING_CHANGE" })

export const POLL_SERVER = "POLL_SERVER"
export const POLL_SERVER_REQUEST = "POLL_SERVER_REQUEST"
export const POLL_SERVER_SUCCESS = "POLL_SERVER_SUCCESS"
export const POLL_SERVER_FAILURE = "POLL_SERVER_FAILURE"
export const pollServer = () => (dispatch, getState) => {
	let {
		longPolling: { requestContent },
	} = getState()
	let url = "/front/affiliates/long_pollings?"
	if (requestContent) url += "with_content=true"
	url += SUPPORTED_NOTIFICATIONS.map(entry => "&supported_events[]=" + entry).join("")
	let init = { method: "GET" }
	return dispatch(simpleFetch(url, init, POLL_SERVER))
}

export const queueLongPollingTimeOut = () => (dispatch, getState) => {
	setTimeout(async () => {
		let {
			longPolling: { active },
		} = getState()
		if (!active) {
			return
		}
		let { actionType } = await dispatch(pollServer())
		if (actionType === POLL_SERVER_SUCCESS) {
			dispatch(queueLongPollingTimeOut())
		}
	}, 60000)
}

export const setPollingAfterAuthentification = () => (dispatch, getState) => {
	// initial poll
	dispatch(pollServer())
	// setup long polling
	if (false || process.env.NODE_ENV !== "development") {
		dispatch(queueLongPollingTimeOut())
	}
}

export const setAffiliate = affiliate => (dispatch, getState) => {
	let {
		redData: { currentUser },
	} = getState()
	Sentry.setUser({ id: currentUser.accountID, email: currentUser.email })
	Sentry.setTag("versionFromCommit", process.env.REACT_APP_COMMIT_HASH_AND_DATE)
	Sentry.setContext("affiliate", {
		affiliateID: affiliate.id,
		affiliateName: affiliate.commercialName,
	})
	if (process.env.NODE_ENV !== "test") {
		intercomLoader()
		window.Intercom("boot", {
			app_id: env === "production" ? "h5dihioz" : "ot27m7xx",
			name: currentUser.userName,
			email: currentUser.email,
			company: {
				id: affiliate.id,
				name: affiliate.commercialName,
			},
			affiliateID: affiliate.id,
			affiliateName: affiliate.commercialName,
			environment: env,
			versionFromCommit: process.env.REACT_APP_COMMIT_HASH_AND_DATE,
			language_override: affiliate.defaultLanguage,
		})
	}
	localStorage.setItem("pootsy-last-chosen-affiliate-id", affiliate.id)
	let currentRole = currentUser.accesses.find(access => access.affiliateID === affiliate.id).role
	if (currentRole === "affiliate_workshop_slave") {
		history.push("/workshop/com-center/today")
	}
	dispatch(selectAffiliate(affiliate))
	dispatch(setPollingAfterAuthentification())
}

const setAffiliateAfterAuthentication = () => (dispatch, getState) => {
	let { affiliates } = getState().redData
	let lastChosenAffiliateID = parseInt(
		localStorage.getItem("pootsy-last-chosen-affiliate-id"),
		10
	)
	if (affiliates.length === 1) {
		dispatch(setAffiliate(affiliates[0]))
	} else if (
		lastChosenAffiliateID &&
		affiliates.map(entry => entry.id).includes(lastChosenAffiliateID)
	) {
		dispatch(setAffiliate(affiliates.find(aff => aff.id === lastChosenAffiliateID)))
	}
}

export const authenticate = (email, password) => async (dispatch, getState) => {
	let init = { method: "POST", body: JSON.stringify({ email, password }) }
	let { actionType } = await dispatch(
		simpleFetch(
			"/front/sessions?" +
				SUPPORTED_NOTIFICATIONS.map(entry => "&supported_events[]=" + entry).join(""),
			init,
			"AUTH"
		)
	)
	if (actionType === AUTH_SUCCESS) {
		let {
			auth,
			redData: { affiliates },
		} = getState()
		if (process.env.NODE_ENV === "development") {
			console.log(auth)
		}
		localStorage.setItem("pootsy-token", auth.token)
		localStorage.setItem("pootsy-affiliateId", affiliates[0].id)
		dispatch(setAffiliateAfterAuthentication())
	}
}

export const checkToken = () => (dispatch, getState) => {
	let url = window.location.href
	let queryString = url.substring(url.indexOf("?") + 1)
	let queryObject = parseQuery(queryString)
	if (queryObject.impersonation) {
		let impersToken = queryObject.impersonation
		let tokenPayload = JSON.parse(atob(impersToken.split(".")[1]))
		let init = { method: "GET" }
		dispatch(
			simpleFetch(
				"/front/token?" +
					SUPPORTED_NOTIFICATIONS.map(entry => "&supported_events[]=" + entry).join(""),
				init,
				"CHECKTOKEN",
				{
					token: impersToken,
					affiliateID: tokenPayload.affiliate_id,
				}
			)
		).then(({ actionType }) => {
			if (actionType === CHECKTOKEN_SUCCESS) {
				dispatch(setAffiliateAfterAuthentication())
			}
			if (process.env.NODE_ENV === "development") {
				let {
					auth,
					redData: { affiliates },
				} = getState()
				localStorage.setItem("pootsy-token", auth.token)
				localStorage.setItem("pootsy-affiliateId", affiliates[0].id)
			}
		})
		return
	}
	if (!getState().auth.loggedIn) {
		let storedToken = localStorage.getItem("pootsy-token")
		let storedAffiliateId = localStorage.getItem("pootsy-affiliateId")
		if (process.env.NODE_ENV === "development") {
			console.log(
				"Found in localStorage, affiliate ID:",
				storedAffiliateId,
				"and token:",
				storedToken
			)
		}
		if (storedToken !== null) {
			let init = { method: "GET" }
			dispatch(
				simpleFetch("/front/token", init, "CHECKTOKEN", {
					token: storedToken,
					affiliateID: storedAffiliateId,
				})
			).then(({ actionType }) => {
				if (actionType === CHECKTOKEN_SUCCESS) {
					dispatch(setAffiliateAfterAuthentication())
				}
			})
			return
		}
		dispatch(tokenChecked())
	}
}

export const setLang = lang => (dispatch, getState) => {
	moment.locale(lang)
	dispatch(setLanguage(lang))
}

export const FETCH_AVAILABLE_DOCUMENT_TEMPLATES_LIST = "FETCH_AVAILABLE_DOCUMENT_TEMPLATES_LIST"
export const FETCH_AVAILABLE_DOCUMENT_TEMPLATES_LIST_REQUEST =
	"FETCH_AVAILABLE_DOCUMENT_TEMPLATES_LIST_REQUEST"
export const FETCH_AVAILABLE_DOCUMENT_TEMPLATES_LIST_SUCCESS =
	"FETCH_AVAILABLE_DOCUMENT_TEMPLATES_LIST_SUCCESS"
export const FETCH_AVAILABLE_DOCUMENT_TEMPLATES_LIST_FAILURE =
	"FETCH_AVAILABLE_DOCUMENT_TEMPLATES_LIST_FAILURE"
export const fetchAvailableDocumentTemplatesList = () => (dispatch, getState) => {
	let url = "/front/affiliates/document_templates/list_available_templates"
	let init = { method: "GET" }
	dispatch(simpleFetch(url, init, FETCH_AVAILABLE_DOCUMENT_TEMPLATES_LIST))
}

export const RETRIEVE_TEMPLATE_VARIABLES = "RETRIEVE_TEMPLATE_VARIABLES"
export const RETRIEVE_TEMPLATE_VARIABLES_REQUEST = "RETRIEVE_TEMPLATE_VARIABLES_REQUEST"
export const RETRIEVE_TEMPLATE_VARIABLES_SUCCESS = "RETRIEVE_TEMPLATE_VARIABLES_SUCCESS"
export const RETRIEVE_TEMPLATE_VARIABLES_FAILURE = "RETRIEVE_TEMPLATE_VARIABLES_FAILURE"
export const retrieveTemplateVariables = ({
	templateID,
	lang,
	customParams,
	// templateContent,
}) => async (dispatch, getState) => {
	let url = "/front/affiliates/document_templates/template_with_variables/"
	let init = {
		method: "POST",
		body: JSON.stringify({
			document_template_id: templateID,
			lang,
			...customParams,
		}),
	}
	return dispatch(simpleFetch(url, init, RETRIEVE_TEMPLATE_VARIABLES))
}

export * from "./acImpactedBookings"
export * from "./acWorkers"
export * from "./acAgenda"
export * from "./acTimesheets"
export * from "./acSodexo"
export * from "./acCustomers"
export * from "./acBookings"
export * from "./acSettings"
export * from "./acDashboard"
export * from "./acWorkerContract"
export * from "./acWorkerSchedule"
export * from "./acWorkerWeek"
export * from "./acWorkshop"
export * from "./acDimonas"
export * from "./acNotifications"
export * from "./acURLQueries"
export * from "./acTasks"
export * from "./acBookingWishes"
