import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment"

import ModalLayout from "./ModalLayout"
import { LabeledDateTime, PootsyButton } from "./FunctionalDesign"

export default class TimesheetIntermediateClosureModal extends Component {
	state = {
		intermediateClosureDate: undefined,
	}
	handleChanges = changes => {
		this.setState(changes)
	}
	submitIntermediateClosureDate = () => {
		this.props.submitIntermediateClosureDate(this.state.intermediateClosureDate)
	}
	render() {
		let { t } = this.context
		let { closeModal, currentMonth } = this.props
		let { intermediateClosureDate } = this.state
		return (
			<ModalLayout
				formDivClass="secsoc-intermediate-close-timesheet"
				title={t("secsoc_intermediate_close_timesheet_modal_title")}
				closeModal={closeModal}
				buttons={[
					<PootsyButton
						key="a"
						text={t("submit_intermediate_timesheet_closure_date")}
						onClick={this.submitIntermediateClosureDate}
						disabled={!intermediateClosureDate}
					/>,
					<PootsyButton key="b" text={t("cancel")} onClick={closeModal} />,
				]}
			>
				<LabeledDateTime
					label={t("intermediate_closure_date")}
					showTimePicker={false}
					datePickerProps={{
						name: "intermediateClosureDate",
						value: intermediateClosureDate,
						onChange: this.handleChanges,
						calendarStyle: { bottom: "200%", left: "100%", zIndex: "2" },
						disableDay: d =>
							d.isBefore(currentMonth, "month") ||
							d.isAfter(moment(currentMonth).endOf("month"), "month"),
					}}
				/>
			</ModalLayout>
		)
	}
}

TimesheetIntermediateClosureModal.contextTypes = { t: PropTypes.func }
