import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import * as actions from "../actions"

export const useChangeHandler = () => {
	const dispatch = useDispatch()
	const handler = useCallback(
		changes => {
			dispatch(actions.bookingWishesStateChange(changes))
		},
		[dispatch]
	)
	return handler
}

export const useFetchBookingWishes = () => {
	const dispatch = useDispatch()
	const fetch = useCallback(() => {
		dispatch(actions.fetchBookingWishes())
		dispatch(actions.bookingWishesStateChange({ currentHighlights: {} }))
		dispatch(actions.resetAgendaData())
	}, [dispatch])
	return fetch
}

export const useInitBookingWishes = (fetchBookingWishes, currUser) => {
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(
			actions.agendaStateChange({
				postFetchAction: actions.fetchBookingWishes,
				timerangeDropdownWhitelist: ["add_direct_booking"],
				fullMonthAffiliateWorkerId: undefined,
				customFullMonthRequest: () => {
					dispatch(actions.searchTimeslotsForCurrentHighlight())
				},
			})
		)
		dispatch(
			actions.directBookingModalStateChange({
				controlledState: true,
				customSubmit: data => {
					dispatch(actions.submitDirectReplacements(data))
				},
			})
		)
		let currUserTeams = currUser.teams.map(entry => entry.id)
		dispatch(
			actions.bookingWishesStateChange({
				squadsFilters: currUserTeams,
				squadsFiltersForSearch: currUserTeams,
			})
		)
		fetchBookingWishes()
		return () => {
			dispatch(actions.resetAgenda())
			dispatch(actions.resetDirectBookingForm())
			dispatch(
				actions.directBookingModalStateChange({
					controlledState: false,
					customSubmit: undefined,
				})
			)
		}
		// eslint-disable-next-line
	}, [dispatch])
}

export const useCustomerFilterHandler = fetchBookingWishes => {
	const dispatch = useDispatch()
	const { customersFilters } = useSelector(state => state.redComponents.bookingWishesComponent)
	const handler = useCallback(
		customer => {
			dispatch(
				actions.bookingWishesStateChange({
					page: 1,
					customersFilters: [
						...customersFilters,
						{
							customerContractId: customer.id,
							value: customer.id,
							label: customer.attributes.display_name,
						},
					],
				})
			)
			fetchBookingWishes()
		},
		[dispatch, fetchBookingWishes, customersFilters]
	)
	return handler
}

export const useRemoveCustomerFilter = fetchBookingWishes => {
	const dispatch = useDispatch()
	const { customersFilters } = useSelector(state => state.redComponents.bookingWishesComponent)
	const handler = useCallback(
		customer => {
			dispatch(
				actions.bookingWishesStateChange({
					page: 1,
					customersFilters: customersFilters.filter(
						entry => entry.value !== customer.value
					),
				})
			)
			fetchBookingWishes()
		},
		[dispatch, fetchBookingWishes, customersFilters]
	)
	return handler
}

export const useWorkerFilterHandler = fetchBookingWishes => {
	const dispatch = useDispatch()
	const { workersFilters } = useSelector(state => state.redComponents.bookingWishesComponent)
	const handler = useCallback(
		worker => {
			dispatch(
				actions.bookingWishesStateChange({
					page: 1,
					workersFilters: [
						...workersFilters,
						{
							affiliateWorkerId: worker.id,
							value: worker.id,
							label: worker.attributes.display_name,
						},
					],
				})
			)
			fetchBookingWishes()
		},
		[dispatch, fetchBookingWishes, workersFilters]
	)
	return handler
}

export const useDateFilterHandler = fetchBookingWishes => {
	const dispatch = useDispatch()
	const handler = useCallback(
		changes => {
			dispatch(
				actions.bookingWishesStateChange({
					page: 1,
					rangeStart: changes.from,
					rangeEnd: changes.to,
				})
			)
			fetchBookingWishes()
		},
		[dispatch, fetchBookingWishes]
	)
	return handler
}

export const useStatusFilterHandler = fetchBookingWishes => {
	const dispatch = useDispatch()
	const handler = useCallback(
		e => {
			dispatch(actions.bookingWishesStateChange({ page: 1, statusFilter: e.target.value }))
			fetchBookingWishes()
		},
		[dispatch, fetchBookingWishes]
	)
	return handler
}

export const useSquadFilterHandler = fetchBookingWishes => {
	const dispatch = useDispatch()
	const handler = useCallback(
		squadsFilters => {
			dispatch(
				actions.bookingWishesStateChange({ page: 1, squadsFilters: squadsFilters.value })
			)
			fetchBookingWishes()
		},
		[dispatch, fetchBookingWishes]
	)
	return handler
}

export const useSquadFilterForSearchHandler = () => {
	const dispatch = useDispatch()
	const handler = useCallback(
		squadsFilters => {
			dispatch(
				actions.bookingWishesStateChange({ squadsFiltersForSearch: squadsFilters.value })
			)
		},
		[dispatch]
	)
	return handler
}

export const useBadgeFilterHandler = () => {
	const dispatch = useDispatch()
	const handler = useCallback(
		e => {
			dispatch(actions.bookingWishesStateChange({ badgesFilters: e.target.value }))
		},
		[dispatch]
	)
	return handler
}

export const useRemoveWorkerFilter = fetchBookingWishes => {
	const dispatch = useDispatch()
	const { workersFilters } = useSelector(state => state.redComponents.bookingWishesComponent)
	const handler = useCallback(
		worker => {
			dispatch(
				actions.bookingWishesStateChange({
					page: 1,
					workersFilters: workersFilters.filter(entry => entry.value !== worker.value),
				})
			)
			fetchBookingWishes()
		},
		[dispatch, fetchBookingWishes, workersFilters]
	)
	return handler
}

export const useSubmitBookingWishDaterange = (
	bookingWishGroup,
	from,
	to,
	dayOfWeek,
	fromTime,
	toTime,
	isRecurrentTimerange,
	closeModal
) => {
	const dispatch = useDispatch()
	const handler = useCallback(() => {
		dispatch(
			actions.submitBookingWishDaterange({
				bookingWishGroup,
				from,
				to,
				dayOfWeek,
				fromTime,
				toTime,
				isRecurrentTimerange,
			})
		)
		closeModal()
	}, [
		dispatch,
		bookingWishGroup,
		from,
		to,
		dayOfWeek,
		fromTime,
		toTime,
		closeModal,
		isRecurrentTimerange,
	])
	return handler
}

export const useSubmitRemoveBookingWishDaterange = bookingWishGroup => {
	const dispatch = useDispatch()
	const handler = useCallback(
		wishedDaterangeId => {
			dispatch(
				actions.submitRemoveBookingWishDaterange({ bookingWishGroup, wishedDaterangeId })
			)
		},
		[dispatch, bookingWishGroup]
	)
	return handler
}

export const useSubmitBookingWishNoteUpdate = (bookingWishGroup, note, toggleNoteTooltip) => {
	const dispatch = useDispatch()
	const handler = useCallback(() => {
		dispatch(actions.updateBookingWishNote({ bookingWishGroup, note }))
		toggleNoteTooltip(false)
	}, [dispatch, bookingWishGroup, note, toggleNoteTooltip])
	return handler
}
export const useToggleBookingWishHighlighting = () => {
	const dispatch = useDispatch()
	const handler = useCallback(
		(bookingWishGroup, isHighlighted) => {
			isHighlighted
				? dispatch(actions.processRemoveHighlightedBookingWish(bookingWishGroup))
				: dispatch(actions.processHighlightBookingWish(bookingWishGroup))
		},
		[dispatch]
	)
	return handler
}

export const useCheckReplacedOccurrenceHandler = () => {
	const dispatch = useDispatch()
	const handler = useCallback(
		(occurrenceId, bookingWishGroup) => {
			dispatch(actions.processSelectedOccurrencesChange(occurrenceId, bookingWishGroup))
		},
		[dispatch]
	)
	return handler
}

export const useToggleCreateBookingsFromHighlightsModal = () => {
	const dispatch = useDispatch()
	const { showCreateBookingFromHighlightsModal } = useSelector(
		state => state.redComponents.bookingWishesComponent
	)
	const handler = useCallback(
		bookingWishGroup => {
			dispatch(
				actions.bookingWishesStateChange({
					showCreateBookingFromHighlightsModal: !showCreateBookingFromHighlightsModal,
				})
			)
			dispatch(actions.createBookingsFromHighlightsModalStateChange({ bookingWishGroup }))
		},
		[dispatch, showCreateBookingFromHighlightsModal]
	)
	return handler
}

export const useBwLeaveWorkerFullMonth = bookingWishGroup => {
	const dispatch = useDispatch()
	const handler = useCallback(() => {
		dispatch(actions.agendaStateChange({ fullMonthAffiliateWorkerId: undefined }))
		dispatch(actions.searchTimeslotsForCurrentHighlight())
	}, [dispatch])
	return handler
}

export const useSearchTimeslotsForCurrentHighlight = bookingWishGroup => {
	const dispatch = useDispatch()
	const handler = useCallback(
		e => {
			e.stopPropagation()
			dispatch(actions.searchTimeslotsForCurrentHighlight())
		},
		[dispatch]
	)
	return handler
}

export const useAgendaToggling = () => {
	const dispatch = useDispatch()
	const { showAgenda } = useSelector(state => state.redComponents.bookingWishesComponent)
	const handler = useCallback(() => {
		dispatch(actions.bookingWishesStateChange({ showAgenda: !showAgenda }))
	}, [dispatch, showAgenda])
	return handler
}

export const useAgendaSortingHandler = () => {
	const dispatch = useDispatch()
	const handler = useCallback(
		e => {
			e.stopPropagation()
			let newSorting = e.target.value
			if (newSorting === "badge") {
				dispatch(actions.sortAgendasByBadge())
			} else {
				dispatch(actions.sortAgendasByDistance())
			}
		},
		[dispatch]
	)
	return handler
}

export const useStageOccurrenceForDismissalHandler = bookingWishGroup => {
	const dispatch = useDispatch()
	const handler = useCallback(
		e => {
			e.stopPropagation()
			let occurrenceId = e.target.dataset.id
			dispatch(
				actions.bookingWishesStateChange({
					bookingWishStagedForDismissal: { occurrenceId, bookingWishGroup },
					showDismissConfirmationModal: true,
				})
			)
		},
		[bookingWishGroup, dispatch]
	)
	return handler
}

export const useStageOccurrenceForPendingHandler = bookingWishGroup => {
	const dispatch = useDispatch()
	const handler = useCallback(
		e => {
			let occurrenceId = e.target.dataset.id
			dispatch(
				actions.bookingWishesStateChange({
					bookingWishStagedForPending: { occurrenceId, bookingWishGroup },
					showPendingConfirmationModal: true,
				})
			)
		},
		[bookingWishGroup, dispatch]
	)
	return handler
}

export const useCloseDismissalModalHandler = () => {
	const dispatch = useDispatch()
	const handler = useCallback(() => {
		dispatch(
			actions.bookingWishesStateChange({
				bookingWishStagedForDismissal: undefined,
				showDismissConfirmationModal: false,
			})
		)
	}, [dispatch])
	return handler
}

export const useClosePendingModalHandler = () => {
	const dispatch = useDispatch()
	const handler = useCallback(() => {
		dispatch(
			actions.bookingWishesStateChange({
				bookingWishStagedForDismissal: undefined,
				showPendingConfirmationModal: false,
			})
		)
	}, [dispatch])
	return handler
}

export const useUpdateBookingWishGroupStatus = bookingWishGroup => {
	const dispatch = useDispatch()
	const handler = useCallback(
		e => {
			e.stopPropagation()
			let occurrenceId = e.target.dataset.id
			dispatch(actions.updateBookingWishGroupStatus(occurrenceId, bookingWishGroup))
		},
		[bookingWishGroup, dispatch]
	)
	return handler
}

export const usePageChangeHandler = fetchBookingWishes => {
	const dispatch = useDispatch()
	const handler = useCallback(
		page => {
			dispatch(actions.bookingWishesStateChange({ page: page.value }))
			fetchBookingWishes()
		},
		[dispatch, fetchBookingWishes]
	)
	return handler
}

export const useAgendaSearchWeekNavigation = () => {
	const dispatch = useDispatch()
	const handler = useCallback(
		changes => {
			dispatch(actions.searchTimeslotsForCurrentHighlight({ minDate: changes.agendaWeek }))
		},
		[dispatch]
	)
	return handler
}
