import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import {
	Document,
	Font,
	Image,
	Page,
	PDFDownloadLink,
	PDFViewer,
	Text,
	View,
} from "@react-pdf/renderer"
import { UI_ROOT } from "../config.js"
import Mustache from "mustache"

import { WorkerSchedulePdf } from "./WorkerSchedulePdfDownloader"

import * as actions from "../actions"

import Helvetica from "../static/fonts/Helvetica.ttf"
import HelveticaBold from "../static/fonts/Helvetica-Bold.ttf"
import HelveticaOblique from "../static/fonts/Helvetica-Oblique.ttf"
import HelveticaBoldOblique from "../static/fonts/Helvetica-BoldOblique.ttf"
import ModalLayout from "./ModalLayout"

Font.register({ family: "Helvetica", src: UI_ROOT + Helvetica })
Font.register({ family: "Helvetica-Bold", src: UI_ROOT + HelveticaBold })
Font.register({ family: "Helvetica-Oblique", src: UI_ROOT + HelveticaOblique })
Font.register({ family: "Helvetica-BoldOblique", src: UI_ROOT + HelveticaBoldOblique })

Mustache.escape = text => text // No need to escape as this is not an HTML format

const inlineStyleMap = {
	BOLD: style => {
		if (style.fontFamily && style.fontFamily === "Helvetica-Oblique") {
			style.fontFamily = "Helvetica-BoldOblique"
		} else {
			style.fontFamily = "Helvetica-Bold"
		}
		return style
	},
	ITALIC: style => {
		if (style.fontFamily && style.fontFamily === "Helvetica-Bold") {
			style.fontFamily = "Helvetica-BoldOblique"
		} else {
			style.fontFamily = "Helvetica-Oblique"
		}
		return style
	},
	UNDERLINE: style => {
		style.textDecoration = "underline"
		return style
	},
	STRIKETHROUGH: style => {
		style.textDecoration = "line-through"
		return style
	},
}

class DraftTemplateToPDF extends Component {
	state = {
		url: "",
		alignment: "",
		error: "",
		template: -1,
	}

	getBase64FromUrl = async url => {
		try {
			const data = await fetch(url)
			const blob = await data.blob()
			return new Promise(resolve => {
				const reader = new FileReader()
				reader.readAsDataURL(blob)
				reader.onloadend = () => {
					const base64data = reader.result
					resolve(base64data)
				}
			})
		} catch (e) {
			this.setState({ error: e.message, template: 1 })
		}
	}

	handleChanges = changes => {
		this.props.dispatch(actions.pdfEditorStateChange(changes))
	}
	processBlock = ({ block, entityMap, orderedListItemNb }) => {
		let viewStyle = { flexDirection: "row", minHeight: 11 }
		let text
		if (block.type === "atomic") {
			let { src, alignment } = entityMap[block.entityRanges[0].key].data
			if (this.state.url === "") {
				this.getBase64FromUrl(src).then(result => {
					this.setState({ url: result, alignment: alignment })
				})
			}
			return (
				<View key={block.key} style={{ alignItems: alignment }}>
					<Image key={block.key} src={this.state.url} style={{ width: "2cm" }} />
				</View>
			)
		} else if (block.type === "text_align_center") {
			viewStyle.justifyContent = "center"
			text = this.renderTextNodes(block)
		} else if (block.type === "text_align_right") {
			viewStyle.justifyContent = "flex-end"
			text = this.renderTextNodes(block)
		} else if (block.type === "unordered-list-item") {
			text = <Text> • {this.renderTextNodes(block)}</Text>
		} else if (block.type === "ordered-list-item") {
			text = (
				<Text>
					{" "}
					{orderedListItemNb}. {this.renderTextNodes(block)}
				</Text>
			)
		} else {
			text = this.renderTextNodes(block)
		}
		return (
			<View key={block.key} style={viewStyle}>
				{text}
			</View>
		)
	}

	renderTextNodes = block => {
		let {
			template: { variables = {} },
		} = this.props
		let currentStyle = {}
		let parsed = block.text.split("").reduce((acc, letter, index, array) => {
			let stylesForLetter = block.inlineStyleRanges.reduce((acc, isr) => {
				let rangeStop = isr.offset + isr.length
				if (index >= isr.offset && index < rangeStop) {
					acc.push(isr.style)
				}
				return acc
			}, [])
			if (index === 0) {
				// initialize for first iteration
				currentStyle = { toApply: stylesForLetter, text: "" }
			}
			if (stylesForLetter.sort().join() !== currentStyle.toApply.sort().join()) {
				acc.push({ ...currentStyle })
				currentStyle = { toApply: stylesForLetter, text: letter }
				if (index === array.length - 1) {
					acc.push({ ...currentStyle })
				}
			} else if (index === array.length - 1) {
				currentStyle.text += letter
				acc.push({ ...currentStyle })
			} else {
				currentStyle.text += letter
			}
			return acc
		}, [])
		let textNodes = parsed.map((entry, index) => {
			let style = entry.toApply.reduce((acc, entry) => inlineStyleMap[entry](acc), {})

			let t = entry.text
			if (t.split("{{").length - 1 !== t.split("}}").length - 1) {
				// in case we have the start of a variable, but not the end, we don't want to display it (it crashes)
				// https://github.com/Pootsy/spec/issues/1086
				// this can happen when a variable is half-stylished (ex, here; bold)
				return null
			}
			let finalText = Mustache.render(t, variables)
			return (
				<Text key={index} style={style}>
					{finalText.replace(/\s$/, "\u00a0")}
				</Text>
			)
		})
		// wrapping in a <Text> allows inline styling (see docs)
		return <Text>{textNodes}</Text>
	}

	hasImage = template => {
		if (template.templateBody) {
			for (const entity in template.templateBody.entityMap) {
				if (template.templateBody.entityMap[entity].type === "image") {
					return true
				}
			}
			return false
		}
	}

	closePreview = () => {
		this.setState({ template: -1 })
	}

	render() {
		let { template, mode, disable, fileName, workerScheduleData, children } = this.props
		if (disable) {
			return children
		}
		let orderedListItemNbCounter = 0
		let pdf = (
			<Document>
				<Page
					size="A4"
					style={{
						fontFamily: "Helvetica",
						fontSize: 11,
						padding: 30,
						lineHeight: 1.2,
					}}
				>
					{template.templateBody.blocks.map((entry, index) => {
						if (entry.type === "worker_schedule") {
							if (!workerScheduleData) return null
							return (
								<WorkerSchedulePdf
									key="worker_schedule"
									t={this.context.t}
									contractStart={workerScheduleData.currentContract.startDate}
									timeranges={workerScheduleData.timeranges}
									schedulePeriods={
										workerScheduleData.currentContract.schedulePeriods
									}
								/>
							)
						} else if (entry.type === "ordered-list-item") {
							orderedListItemNbCounter += 1
						} else {
							orderedListItemNbCounter = 0
						}
						return this.processBlock({
							block: entry,
							entityMap: template.templateBody.entityMap,
							orderedListItemNb: orderedListItemNbCounter,
						})
					})}
				</Page>
			</Document>
		)
		return (
			<div className="DraftTemplateToPDF">
				{mode === "download" &&
					this.state.error === "" &&
					(this.state.url.length > 1 || !this.hasImage(this.props.template)) && (
						<PDFDownloadLink fileName={fileName.replace(".", "-")} document={pdf}>
							{children}
						</PDFDownloadLink>
					)}
				{mode === "preview" &&
					this.state.error === "" &&
					(this.state.url.length > 1 || !this.hasImage(this.props.template)) && (
						<PDFViewer width={400} height={700}>
							{pdf}
						</PDFViewer>
					)}
				{this.state.error !== "" && this.state.template > -1 && (
					<ModalLayout closeModal={this.closePreview}>{this.state.error}</ModalLayout>
				)}
			</div>
		)
	}
}

DraftTemplateToPDF.propTypes = {
	mode: PropTypes.oneOf(["download", "preview"]),
}
DraftTemplateToPDF.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.pdfEditorComponent,
})
export default connect(mapStateToProps)(DraftTemplateToPDF)
