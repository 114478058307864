import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"

import * as actions from "../actions"

import InputsChangeHandler from "./InputsChangeHandler"
import { PootsyButton, ConfirmationModal } from "./FunctionalDesign"
import ModalLayout from "./ModalLayout"
import { PootsySelectInput } from "./FunctionalInputs"

class DeleteBookingModal extends Component {
	constructor() {
		super()
		this.state = { reach: "", showRequired: { reach: false }, showConfirmationModal: false }
		// ( to be able to pass it down to <InputsChangeHandler/> )
		this.setState = this.setState.bind(this)
	}
	toggleConfirmationModal = () => {
		let {
			deleteBookingModal: { sentToSodexo },
		} = this.props
		if (sentToSodexo) {
			this.setState({ showConfirmationModal: !this.state.showConfirmationModal })
		} else {
			this.submitDeleteBooking()
		}
	}
	submitDeleteBooking = () => {
		let { deleteBookingModal } = this.props
		let { reach } = this.state
		if (deleteBookingModal.recurrentBooking && reach === "") {
			this.setState({ showRequired: { reach: true } })
			return
		}
		let data = {}
		if (deleteBookingModal.recurrentBooking) {
			data.deletion_type = reach
			if (reach === "custom") {
				let date = moment(deleteBookingModal.deliveryDate, "YYYY-MM-DD")
				data.deletion_start_date = date.format()
				data.deletion_end_date = date.add(1, "day").format()
			}
		}
		this.submit(data)
	}
	submit = data => {
		let { dispatch, deleteBookingModal, closeModal } = this.props
		let id = deleteBookingModal.bookingID
		let init = { method: "DELETE", body: JSON.stringify(data) }
		dispatch(
			actions.simpleFetch(
				"/front/affiliates/bookings/" + id,
				init,
				"DELETEBOOKING",
				deleteBookingModal.requestMeta
			)
		)
		closeModal()
	}
	render() {
		let { t } = this.context
		let { closeModal, deleteBookingModal } = this.props
		let { showRequired, reach, showConfirmationModal } = this.state
		let buttons = [
			<PootsyButton key="a" text={t("yes")} onClick={this.toggleConfirmationModal} />,
			<PootsyButton key="b" text={t("no")} theme="cancel" onClick={closeModal} />,
		]
		let warning
		if (deleteBookingModal.recurrentBooking) {
			if (reach === "all_occurrences") {
				warning = t("delete_booking_warning_recurrence_all_occurrences")
			} else if (reach === "custom") {
				warning = t("delete_booking_warning_recurrence_one_occurrence")
			}
		} else {
			warning = t("delete_booking_warning_ponctual")
		}
		return (
			<InputsChangeHandler setParentState={this.setState}>
				<ModalLayout
					title={t("delete_booking")}
					buttons={buttons}
					closeModal={closeModal}
					formDivClass="delete-booking-modal"
				>
					{showConfirmationModal && (
						<ConfirmationModal
							title={t("confirm_update_booking_linked_to_sodexo")}
							closeModal={this.toggleConfirmationModal}
							yesButton={
								<PootsyButton text={t("yes")} onClick={this.submitDeleteBooking} />
							}
							noButton={
								<PootsyButton
									text={t("no")}
									theme="cancel"
									onClick={this.toggleConfirmationModal}
								/>
							}
						/>
					)}
					{deleteBookingModal.recurrentBooking && (
						<PootsySelectInput
							name="reach"
							defaultText={t("deletion_reach")}
							selected={reach}
							showRequired={showRequired.reach}
							options={[
								{ label: t("delete_all_occurrences"), value: "all_occurrences" },
								{ label: t("delete_one_occurrence"), value: "custom" },
							]}
						/>
					)}
					<div className="deletion-prompt">{warning}</div>
				</ModalLayout>
			</InputsChangeHandler>
		)
	}
}

const mapStateToProps = state => ({
	deleteBookingModal: state.redComponents.deleteBookingModal,
})
DeleteBookingModal.contextTypes = { t: PropTypes.func }
export default connect(mapStateToProps)(DeleteBookingModal)
