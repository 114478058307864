import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment"

import InputsChangeHandler from "./InputsChangeHandler"
import { PootsyTextArea, PootsyFileInput } from "./FunctionalInputs"
import { PootsyButton } from "./FunctionalDesign"
import ModalLayout from "./ModalLayout"

import sendIcon from "../static/images/send-icon.png"
import attachIcon from "../static/images/attach-icon.png"

export default class ChatRoom extends Component {
	state = {
		currMess: "",
		showImgModal: false,
		modalImgSrc: "",
		chatImg: "",
		contentType: "",
	}
	componentDidMount = () => {
		this.scrollToBottom()
	}
	componentDidUpdate = () => {
		this.scrollToBottom()
	}
	scrollToBottom = () => {
		let messBox = this.refs.messagesBox
		messBox.scrollTop = messBox.scrollHeight
	}
	handleChanges = changes => {
		this.setState({ ...changes })
	}
	openImgModal = e => {
		let { img, contentType } = e.currentTarget.dataset
		e.stopPropagation()
		this.setState(state => ({
			showImgModal: !state.showImgModal,
			modalImgSrc: img,
			contentType: contentType,
		}))
	}
	closeImgModal = () => {
		this.setState({ showImgModal: false })
	}
	sendMessage = e => {
		e.stopPropagation()
		let { currMess, chatImg } = this.state
		this.props.sendMessage(currMess, chatImg)
		this.setState({ currMess: "", chatImg: "" })
	}
	downloadImg = () => {
		let { modalImgSrc } = this.state
		fetch(modalImgSrc)
			.then(response => response.blob())
			.then(blob => {
				var reader = new FileReader()
				reader.onload = () => {
					let downloadTriggerer = document.createElement("a")
					downloadTriggerer.href = "data:application/pdf;base64," + this.result
					downloadTriggerer.download = modalImgSrc.split("/").pop()
					downloadTriggerer.target = "_blank"
					downloadTriggerer.click()
					downloadTriggerer.remove()
				}
				reader.readAsDataURL(blob)
			})
	}
	render() {
		let { t } = this.context
		let { messages, customClass } = this.props
		let { currMess, chatImg, showImgModal, modalImgSrc, contentType } = this.state
		let imgModalButton = <PootsyButton text="save_img_button" onClick={this.downloadImg} />
		return (
			<InputsChangeHandler
				customClass={"pootsy-chat-room " + customClass}
				onChange={this.handleChanges}
			>
				{showImgModal && (
					<ModalLayout
						closeModal={this.closeImgModal}
						buttons={imgModalButton}
						formDivClass="chat-room-img-modal"
					>
						{contentType === "application/pdf" ? (
							<object
								data={modalImgSrc}
								type="application/pdf"
								className="pdf-preview"
								width="100%"
								height="100%"
							>
								<embed src={modalImgSrc} type="application/pdf" />
							</object>
						) : (
							<img alt="img-prev" className="img-preview" src={modalImgSrc} />
						)}
					</ModalLayout>
				)}
				<div className="messages" ref="messagesBox">
					{messages.length === 0 && (
						<div className="no-messages-warning">{t("no_chat_messages_warning")}</div>
					)}
					{messages.map((e, i) => (
						<div key={i} className={"message " + e.senderType}>
							<div className="text">{e.content}</div>
							{e.images.map((img, index) => {
								const isPdf = img.content_type === "application/pdf"
								return isPdf ? (
									<div
										style={{ position: "relative" }}
										onClick={this.openImgModal}
										data-img={img.url}
										data-content-type={img.content_type}
									>
										<embed
											key={index}
											src={img.url}
											type="application/pdf"
											width="100%"
											height="100%"
										/>
										<div
											style={{
												position: "absolute",
												top: 0,
												left: 0,
												right: 0,
												bottom: 0,
												cursor: "pointer",
											}}
										/>
									</div>
								) : (
									<div
										key={index}
										className="img"
										style={{ backgroundImage: "url(" + (img.url || img) + ")" }}
										data-img={img.url || img}
										data-content-type={img.content_type}
										onClick={this.openImgModal}
									/>
								)
							})}
							<div className="sender-name">{e.senderName}</div>
							<div className="message-created-at">
								{moment(e.createdAt).format("DD/MM/YYYY HH:mm")}
							</div>
						</div>
					))}
				</div>
				<div className="message-input">
					<PootsyTextArea name="currMess" value={currMess} />
					{chatImg &&
					(chatImg.filename.endsWith(".jpg") || chatImg.filename.endsWith(".png")) ? (
						<div className="current-image">
							<img alt="messimg" src={chatImg.file} />
						</div>
					) : chatImg && chatImg.filename.endsWith(".pdf") ? (
						<div className="current-pdf">
							<object data={chatImg.file} type="application/pdf">
								<p>This browser does not support inline PDFs.</p>
							</object>
						</div>
					) : null}

					<div className="message-buttons">
						<div className="send-button" onClick={this.sendMessage}>
							<div className="prompt">{t("send_chat_message")}</div>
							<img alt="send-icon" src={sendIcon} />
						</div>
						<PootsyFileInput
							label={<img alt="ai" src={attachIcon} />}
							name="chatImg"
							accept="image/*,application/pdf"
						/>
					</div>
				</div>
			</InputsChangeHandler>
		)
	}
}

ChatRoom.defaultProps = {
	customClass: "",
}
ChatRoom.contextTypes = { t: PropTypes.func }
