// import "core-js/es6/map"
// import "core-js/es6/set"
import ReactDOM from "react-dom"
import React from "react"
import { Provider } from "react-redux"
import { Router, Route } from "react-router-dom"
import I18n from "./components/I18n"

import { arrayFindPolyfill } from "./helpers/arrayFindPolyfill"
import { isChrome } from "./helpers/browserDetection"

import { history } from "./history"
import configureStore from "./store/configureStore"
import "./index.css"
import * as actions from "./actions"
import Root from "./containers/Root"

import Appsignal from "@appsignal/javascript"
import { ErrorBoundary } from "@appsignal/react"

const appsignal = new Appsignal({
	key: "3fc176a6-52bd-4afa-99d3-69a18d4af6f0", // staging
})

arrayFindPolyfill()

export const store = configureStore()
store.dispatch(actions.checkToken())
if (!isChrome) {
	store.dispatch(actions.warningNotChrome())
}

export const LocaleContext = React.createContext(() => {})

ReactDOM.render(
	<ErrorBoundary instance={appsignal}>
		<Provider store={store}>
			<I18n>
				<Router history={history}>
					<Route path="/" component={Root} />
				</Router>
			</I18n>
		</Provider>
	</ErrorBoundary>,
	document.getElementById("root")
)
