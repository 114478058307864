export const sortArrayByName = (values, firstKeyField, secondKeyField) => {
	values.sort((a, b) => {
		let nameA = getValue(a, firstKeyField, secondKeyField)
		let nameB = getValue(b, firstKeyField, secondKeyField)
		if (nameA < nameB) {
			return -1
		}
		if (nameA > nameB) {
			return 1
		}
		return 0
	})

	return values
}

const getValue = (param, firstKey, secondKey) => {
	return secondKey ? param[firstKey][secondKey].toUpperCase() : param[firstKey].toUpperCase()
}
