import React, { Component } from "react"
import { PootsyButton, PootsySubHeading, PootsyTable } from "./FunctionalDesign"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import * as actions from "../actions"

class AvailableOrderItemList extends Component {
	constructor(props) {
		super(props)
		this.props.dispatch(actions.fetchAvailableOrderItemsList())
	}

	deleteItem = id => {
		this.props.dispatch(actions.deleteAvailableOrderItem(id))
	}

	render() {
		let { t } = this.context
		let { avOrderItemsList, category } = this.props
		return (
			<div className="AvailableOrderItemList">
				<PootsySubHeading text={t(`workshop_item_list_${category}`.toLowerCase())} />
				<PootsyTable
					labels={[
						t("av_order_item_short_name"),
						t("av_order_item_long_name"),
						t("av_order_item_category"),
						t("av_order_item_price"),
						t("av_order_item_actions"),
					]}
					rows={avOrderItemsList
						.filter(aoi => aoi.categoryName === category)
						.map(avorderitem => ({
							columns: [
								avorderitem.name,
								avorderitem.longName,
								avorderitem.category,
								`${avorderitem.price.toString().replace(".", ",")} ${t(
									avorderitem.unit
								)}`,
								<PootsyButton
									text={t("action_av_item_delete")}
									onClick={() => this.deleteItem(avorderitem.id)}
								/>,
							],
						}))}
				/>
			</div>
		)
	}
}

AvailableOrderItemList.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	avOrderItemsList: state.redData.avOrderItemsList,
})
export default connect(mapStateToProps)(AvailableOrderItemList)
