import React, { Component } from "react"

import { newTimerangeObject, schedulePeriodApplies } from "../actions"

import DirectBookingModal from "./DirectBookingModal"

export class BookingFromWorkerScheduleModal extends Component {
	addBookingToSchedule = newBooking => {
		let bookingObject = newTimerangeObject({
			...newBooking,
			type: "unsaved-booking",
			allowEdition: true,
			allowDeletion: true,
			fitWhenOverlap: true,
			extraInfo: [
				{ label: "unsaved_booking", value: "" },
				{ label: "start_date", value: newBooking.startDate.format("DD/MM/YY") },
				{ label: "customer", value: newBooking.chosenCustomer.attributes.display_name },
				{
					label: "recurrence",
					value: {
						0: "once",
						7: "every_week",
						14: "every_two_weeks",
						28: "every_four_weeks",
					}[newBooking.dayPeriod],
				},
			],
		})
		if (this.props.editedBooking.id) {
			bookingObject.id = this.props.editedBooking.id
			this.props.editScheduleBooking(bookingObject)
		} else {
			this.props.addBookingToSchedule(bookingObject)
		}
	}
	render() {
		let {
			initialStartDate,
			contractStartDate,
			schedulePeriod,
			isTwoWeeksSchedule,
			editedBooking,
			closeModal,
		} = this.props
		return (
			<DirectBookingModal
				initialStartDate={initialStartDate}
				disableDay={(bookingDayPeriod, day) => {
					return (
						day.isoWeekday() !== initialStartDate.isoWeekday() ||
						day.isBefore(contractStartDate) ||
						(isTwoWeeksSchedule &&
							bookingDayPeriod === 14 &&
							!schedulePeriodApplies({
								schedulePeriod,
								startDate: day,
								dayPeriod: bookingDayPeriod,
							}))
					)
				}}
				editedBooking={editedBooking}
				closeModal={closeModal}
				submit={this.addBookingToSchedule}
			/>
		)
	}
}

export default BookingFromWorkerScheduleModal
