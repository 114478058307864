import React, { Component } from "react"
import { connect } from "react-redux"
import { Route, NavLink } from "react-router-dom"
import PropTypes from "prop-types"

import * as actions from "../actions"

import { ContentLayout, OptionBox, PootsySubHeading, PootsyButton } from "./FunctionalDesign"
import WorkshopDayOrders from "./WorkshopDayOrders"
import WorkshopOrdersToRetrieve from "./WorkshopOrdersToRetrieve"



class WorkshopCommandCenter extends Component {
	UNSAFE_componentWillMount = () => {
		this.props.dispatch(actions.fetchDayReport())
	}
	handleChanges = changes => {
		this.props.dispatch(actions.workshopCommandCenterStateChange(changes))
	}
	handleWorkerClick = async affWorkerId => {
		let { dispatch } = this.props
		await dispatch(actions.updateQuery({ name: "workers_filter", value: [affWorkerId] }))
		dispatch(actions.fetchWorkshopOrders())
	}
	removeWorkerFilter = async () => {
		let { dispatch } = this.props
		await dispatch(actions.updateQuery({ name: "workers_filter", value: [] }))
		dispatch(actions.fetchWorkshopOrders())
	}
	render() {
		let { t } = this.context
		let { match, component, currentQuery } = this.props
		let showRemoveWorkerFilterButton = false
		let filteredAffWorkerId = 0
		if (
			currentQuery.urlQuery.workers_filter &&
			currentQuery.urlQuery.workers_filter.length !== 0
		) {
			showRemoveWorkerFilterButton = true
			filteredAffWorkerId = Number(currentQuery.urlQuery.workers_filter[0])
		}
		return (
			<ContentLayout customClass="WorkshopCommandCenter">
				<OptionBox customClass="day-report">
					{(component.dayReport.report_items || []).map(group => (
						<div key={group.group_title} className="report-group">
							<PootsySubHeading text={t(group.group_title)} />
							{group.group_items.map(entry => (
								<div key={entry.label} className="report-item">
									<div className="label">{t(entry.label)}</div>
									<div className="value">{entry.value}</div>
								</div>
							))}
						</div>
					))}
				</OptionBox>
				<div className="content">
					<div className="workers-report">
						{showRemoveWorkerFilterButton && (
							<PootsyButton
								size="small"
								theme="fadedRed"
								text={t("remove_filter")}
								onClick={this.removeWorkerFilter}
							/>
						)}
						{(component.dayReport.workers || []).map(entry => (
							<div
								key={entry.worker_name}
								className={
									"worker" +
									(filteredAffWorkerId === entry.affiliate_worker_id
										? " active-filter"
										: "")
								}
								onClick={() => this.handleWorkerClick(entry.affiliate_worker_id)}
							>
								<PootsySubHeading text={entry.worker_name} />
								{entry.worker_report_items.map(entry => (
									<div key={entry.label} className="worker-report-item">
										<div className="label">{t(entry.label)}</div>
										<div className="value">{entry.value}</div>
									</div>
								))}
							</div>
						))}
					</div>
					<div className="tabs">
						<NavLink className="tab-link" to={match.path + "/today"}>
							{t("day_orders")}
						</NavLink>
						<NavLink className="tab-link" to={match.path + "/to-retrieve"}>
							{t("orders_ready_for_retrieval")}
						</NavLink>
					</div>
					<Route path={match.path + "/today"} component={WorkshopDayOrders} />
					<Route
						path={match.path + "/to-retrieve"}
						component={WorkshopOrdersToRetrieve}
					/>
				</div>
			</ContentLayout>
		)
	}
}

WorkshopCommandCenter.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.workshopCommandCenterComponent,
	currentQuery: state.currentURLQuery,
})
export default connect(mapStateToProps)(WorkshopCommandCenter)
