import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"

import {
	PootsyTable,
	OptionBox,
	ContentLayout,
	LabeledDateTime,
	PootsyButton,
	PageLayout,
	NavLinkArrowBack,
} from "./FunctionalDesign"
import { PootsySelectInput } from "./FunctionalInputs"
import * as actions from "../actions"

class UpdatePSVDeliveryGroup extends Component {
	componentDidMount = async () => {
		await this.props.dispatch(actions.fetchPaperDelivery(this.props.match.params.id))
		this.props.dispatch(actions.fetchAvailablePSVForDeliveryForUpdate())
	}
	handleChanges = changes => {
		this.props.dispatch(actions.addPaperDeliveryStateChange(changes))
		this.filterPSVScreation()
	}
	addPSVsToDelivery = async () => {
		let {
			component: {
				PSVDelivery,
				newPSVDelivery,
				monthStartFilter,
				monthEndFilter,
				squad,
				subjectType,
			},
		} = this.props
		let monthStart = monthStartFilter ? monthStartFilter.format("YYYY-MM-DD") : null
		let monthEnd = monthEndFilter ? monthEndFilter.format("YYYY-MM-DD") : null

		let { actionType } = await this.props.dispatch(
			actions.addToPaperDelivery({
				id: PSVDelivery.id,
				month_start: monthStart,
				month_start_filter: monthStartFilter,
				squad_id: squad,
				month_end_filter: monthEnd,
				subject_type: subjectType,
				psv_ids: newPSVDelivery[PSVDelivery.attributes.sodexo_region].total_to_send_ids,
			})
		)
		if (actionType === actions.ADD_PAPER_SERVICE_VOUCHER_TO_DELIVERY_SUCCESS) {
			this.props.history.push({
				pathname: "/paper_voucher_delivery_group/" + PSVDelivery.id,
			})
		}
	}
	changeStartDate = date => {
		this.handleChanges(date)
		this.props.dispatch(actions.fetchAvailablePSVForDelivery())
	}
	filterPSVScreation = () => {
		this.props.dispatch(actions.fetchAvailablePSVForDeliveryForUpdate())
	}
	render() {
		let { t } = this.context
		let {
			currAffiliate,
			component: {
				PSVDelivery,
				newPSVDelivery,
				monthStartFilter,
				monthEndFilter,
				squad,
				subjectType,
			},
		} = this.props
		let pageHeaderLeft = (
			<div className="page-header-left">
				<NavLinkArrowBack to={"/sodexo/exports"} />
				<h1 className="page-title">
					{PSVDelivery.attributes && PSVDelivery.attributes.psv_group_affiliate_reference}
				</h1>
			</div>
		)
		let optionsBoxes = (
			<OptionBox boxTitle={t("sodexo_info")}>
				<div className="dates">
					<LabeledDateTime
						showTimePicker={false}
						label={t("start_date")}
						value={monthStartFilter}
						datePickerProps={{
							name: "monthStartFilter",
							value: monthStartFilter,
							onChange: this.handleChanges,
							calendarStyle: {
								transform: `scale(.8) translate(-50px, -33px)`,
							},
						}}
					/>
					<LabeledDateTime
						showTimePicker={false}
						label={t("end_date")}
						value={monthEndFilter}
						datePickerProps={{
							name: "monthEndFilter",
							value: monthEndFilter,
							onChange: this.handleChanges,
							calendarStyle: {
								transform: `scale(.8) translate(-171px, -32px)`,
							},
						}}
					/>
				</div>
				{currAffiliate.squads && (
					<PootsySelectInput
						name="squad"
						defaultText={t("squad")}
						selected={squad}
						allowEmpty={true}
						options={currAffiliate.squads.map(e => ({
							label: e.name,
							value: e.id,
						}))}
						onChange={e => this.handleChanges({ squad: e.target.value })}
					/>
				)}
				<PootsySelectInput
					name="subject_type"
					defaultText={t("subject_type")}
					selected={subjectType}
					allowEmpty={true}
					options={["booking", "laundry_order"].map(e => ({
						label: t(e),
						value: e,
					}))}
					onChange={e => {
						this.handleChanges({ subjectType: e.target.value })
					}}
				/>
				<div className="action-buttons">
					<PootsyButton text={t("add_return_slip")} onClick={this.addPSVsToDelivery} />
				</div>
			</OptionBox>
		)
		let deliveryMonthStart = PSVDelivery.attributes
			? moment(PSVDelivery.attributes.month_start).format("MMM YYYY")
			: moment()
		return (
			<PageLayout headerLeft={pageHeaderLeft} customClass={"UpdatePSVDeliveryGroup"}>
				<ContentLayout optionsBoxes={optionsBoxes}>
					<div className="create-delivery-line">
						{PSVDelivery.attributes && (
							<Fragment>
								<PootsySelectInput
									label={t("start_date")}
									options={[
										{ label: deliveryMonthStart, value: deliveryMonthStart },
									]}
									disabled={true}
									selected={deliveryMonthStart}
								/>
							</Fragment>
						)}
						{PSVDelivery.attributes && (
							<PootsySelectInput
								label={t("region")}
								name={t("region")}
								options={[
									{
										label: PSVDelivery.attributes.sodexo_region,
										value: PSVDelivery.attributes.sodexo_region,
									},
								]}
								disabled={true}
								selected={PSVDelivery.attributes.sodexo_region}
							/>
						)}
						<div className="sv-to-be-grouped-count">
							{newPSVDelivery.Wallonie && PSVDelivery.attributes && (
								<Fragment>
									<div className="label">{t("sv_in_delivery_group_count")}</div>
									<div className="value">
										{
											newPSVDelivery[PSVDelivery.attributes.sodexo_region][
												"total_to_send"
											]
										}
									</div>
								</Fragment>
							)}
						</div>
					</div>
					<div className="ColumnPaperDelivery">
						{Object.keys(newPSVDelivery) && (
							<PootsyTable
								title={t("vouchers_left_to_add")}
								labels={[t("region"), t("total_sent"), t("total_to_send")]}
								rows={Object.keys(newPSVDelivery).map(key => {
									let info_data = newPSVDelivery[key]
									return {
										columns: [
											key,
											info_data.total_sent,
											info_data.total_to_send,
										],
									}
								})}
							/>
						)}
					</div>
				</ContentLayout>
			</PageLayout>
		)
	}
}

UpdatePSVDeliveryGroup.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.updatePSVdeliveryGroupComponent,
	currAffiliate: state.redData.currentAffiliate,
})
export default connect(mapStateToProps)(UpdatePSVDeliveryGroup)
