import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"

import { API_BASE_URL } from "../config.js"

import * as actions from "../actions"

import InputsChangeHandler from "./InputsChangeHandler"
import {
	ContentLayout,
	OptionBox,
	SimpleLine,
	PootsyMultipleValuesBox,
	PootsyButton,
} from "./FunctionalDesign"
import { PootsyCheckboxInput } from "./FunctionalInputs"
import ModalLayout from "./ModalLayout"

class SettingsUsers extends Component {
	state = {
		showRevokeUserModal: false,
		dudeWithoutAJob: "",
		showResetPasswordModal: false,
		dudeResetPassword: "",
	}
	UNSAFE_componentWillMount = () => {
		// ( to be able to pass it down to <InputsChangeHandler/> )
		this.setState = this.setState.bind(this)
	}
	toggleRevokeUserModal = (e = { target: { dataset: "" } }) => {
		let { userIndex = "" } = e.target.dataset
		this.setState(state => ({
			showRevokeUserModal: !state.showRevokeUserModal,
			dudeWithoutAJob: userIndex,
		}))
	}
	toggleResetPasswordModal = (e = { target: { dataset: "" } }) => {
		let { userIndex = "" } = e.target.dataset
		this.setState(state => ({
			showResetPasswordModal: !state.showResetPasswordModal,
			dudeResetPassword: userIndex,
		}))
	}
	submitRevokeAccess = () => {
		let {
			dispatch,
			currAffiliate: { users },
		} = this.props
		let init = { method: "DELETE" }
		let userId = users[this.state.dudeWithoutAJob].account_id
		dispatch(
			actions.simpleFetch("/front/affiliates/users/" + userId, init, "REVOKEAFFILIATEUSER")
		)
		this.toggleRevokeUserModal()
	}
	submitResetPassword = () => {
		let {
			currAffiliate: { users },
		} = this.props
		let user = users[this.state.dudeResetPassword]
		let init = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ email: user.email }),
		}
		fetch(API_BASE_URL + "/accounts/password", init)
		this.toggleResetPasswordModal()
	}
	makeMasterUser = async e => {
		let { accountid } = e.target.dataset
		let { dispatch } = this.props
		accountid = parseInt(accountid, 10)
		let data = { new_master_user_account_id: accountid }
		let init = {
			method: "POST",
			body: JSON.stringify(data),
		}
		let response = await dispatch(
			actions.simpleFetch(
				`/front/affiliates/affiliates/change_master_user`,
				init,
				"UPDATEMASTERUSER",
				{ newMasterID: accountid }
			)
		)
		let jsonData = await response
		if (jsonData) {
			window.location.reload()
		}
	}
	submitRoleUpdate = e => {
		let {
			dispatch,
			currAffiliate: { users },
		} = this.props
		let name = e.target.name
		let id = name.split("::")[0]
		let user = users.find(entry => entry.id === id)
		let url = "/front/affiliates/users/" + user.id
		let init = {
			method: "PUT",
			body: JSON.stringify({
				...user,
				role:
					user.role === "affiliate_workshop_slave"
						? "affiliate_slave_user"
						: "affiliate_workshop_slave",
			}),
		}
		dispatch(actions.simpleFetch(url, init, "UPDATE_AFFILIATE_USER_ROLE"))
	}
	render() {
		let { t } = this.context
		let {
			currAffiliate: { users = [] },
			currUser,
		} = this.props
		let {
			showRevokeUserModal,
			dudeWithoutAJob,
			showResetPasswordModal,
			dudeResetPassword,
		} = this.state
		let optionBox = (
			<OptionBox boxTitle={t("users")}>
				<PootsyMultipleValuesBox
					values={[{ label: t("users_count"), values: [users.length] }]}
				/>
			</OptionBox>
		)
		return (
			<InputsChangeHandler customClass="SettingsUsers" setParentState={this.setState}>
				<ContentLayout optionsBoxes={optionBox}>
					{showRevokeUserModal && (
						<ModalLayout
							title={t("confirm_revoke_access_for")}
							formDivClass="settings-users-revoke-access-modal"
							closeModal={this.toggleRevokeUserModal}
						>
							{users[dudeWithoutAJob].first_name +
								" " +
								users[dudeWithoutAJob].last_name}{" "}
							?
							<div className="buttons">
								<PootsyButton text={t("yes")} onClick={this.submitRevokeAccess} />
								<PootsyButton
									text={t("cancel")}
									theme="cancel"
									onClick={this.toggleRevokeUserModal}
								/>
							</div>
						</ModalLayout>
					)}
					{showResetPasswordModal && (
						<ModalLayout
							title={t("confirm_revoke_access_for")}
							formDivClass="settings-users-revoke-access-modal"
							closeModal={this.toggleResetPasswordModal}
						>
							{t("confirm_reset_password")}{" "}
							{users[dudeResetPassword].first_name +
								" " +
								users[dudeResetPassword].last_name}{" "}
							?
							<div className="buttons">
								<PootsyButton text={t("yes")} onClick={this.submitResetPassword} />
								<PootsyButton
									text={t("cancel")}
									theme="cancel"
									onClick={this.toggleResetPasswordModal}
								/>
							</div>
						</ModalLayout>
					)}
					{users.map((entry, index) => (
						<SimpleLine key={entry.id} noLink={true}>
							<div className="settings-users-line-name">
								{entry.title + " " + entry.first_name + " " + entry.last_name}
							</div>
							<div className="settings-users-line-email">{entry.email}</div>
							<div className="settings-users-line-created-at">
								{t("created_at")}: {moment(entry.created_at).format("DD/MM/YYYY")}
							</div>
							<div className="settings-users-line-last-login">
								{entry.last_login !== null
									? moment(entry.last_login).format("DD/MM/YYYY HH:mm")
									: t("never_logged_in")}
							</div>
							<div className="settings-users-line-active">
								{entry.active ? t("active") : t("inactive")}
								{entry.email !== currUser.email && (
									<PootsyButton
										text={t("revoke_access")}
										onClick={this.toggleRevokeUserModal}
										dataset={{ "data-user-index": index }}
									/>
								)}
							</div>
							{entry.role !== "affiliate_master_user" && (
								<div className="workshop-limited">
									<PootsyCheckboxInput
										name={`${entry.id}::workshop_limited_user`}
										label={t("workshop_limited_user")}
										checked={entry.role === "affiliate_workshop_slave"}
										onChange={this.submitRoleUpdate}
									/>
								</div>
							)}
							{entry.role !== "affiliate_master_user" && (
								<PootsyButton
									text="Make master user"
									onClick={this.makeMasterUser}
									dataset={{ "data-accountid": entry.account_id }}
								/>
							)}
							{entry.role === "affiliate_master_user" && (
								<PootsyButton
									text="Make slave user"
									onClick={this.makeMasterUser}
									dataset={{ "data-accountid": entry.account_id }}
								/>
							)}
							<div className="settings-users-line-reset-password">
								<PootsyButton
									text={t("reset_password")}
									onClick={this.toggleResetPasswordModal}
									dataset={{ "data-user-index": index }}
								/>
							</div>
						</SimpleLine>
					))}
				</ContentLayout>
			</InputsChangeHandler>
		)
	}
}

SettingsUsers.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currAffiliate: state.redData.currentAffiliate,
	currUser: state.redData.currentUser,
})
export default connect(mapStateToProps)(SettingsUsers)
