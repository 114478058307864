import React, { Fragment, useState, useEffect, useCallback } from "react"
import { useSelector } from "react-redux"
import moment from "moment"
import * as actions from "../actions"
import { useDispatch } from "react-redux"
import SearchCustomer from "./SearchCustomer"
import { stringifyQuery } from "../helpers/qs"
import { PootsyButton, LabeledDateTime, SmallSpinnerSection } from "./FunctionalDesign"
import {
	PootsyRadioInputs,
	PootsySelectInput,
	PootsyTextArea,
	PootsyTextInput,
	PootsyCheckboxInput,
} from "./FunctionalInputs"

import ModalLayout from "./ModalLayout"
import InputsChangeHandler from "./InputsChangeHandler"

export const OtherActivityModal = ({
	init,
	closeModal,
	submit,
	hideDayPeriodSelector,
	disableDay,
	otherActivity,
	t,
	blockCodes,
	workInterruption,
	cleanerId,
}) => {
	const dispatch = useDispatch()
	const blockedCodes = [
		"ssc_conge_parental_ar_291097",
		"ssc_soins_grand_malade",
		"ssc_interr_carriere_credit_temps_partiel",
	]
	let activityCode = otherActivity ? otherActivity.code : ""
	const loading = useSelector(state => state.redData.cancelledEvents.loading)
	const cancelledEvents = useSelector(state => state.redData.cancelledEvents.data)
	const [recurrence, setRecurrence] = useState("")
	const [requestDone, setRequestDone] = useState(false)
	const [changesDone, setChangesDone] = useState(false)
	const [state, setState] = useState({
		showRequired: {},
		start: moment().set({ year: 2000, month: 0, date: 1, hours: 7, minutes: 0, second: 0 }),
		end: moment().set({ year: 2000, month: 0, date: 1, hours: 19, minutes: 0, second: 0 }),
		dayPeriod: "",
		activityCategory: "",
		activityCode: activityCode,
		note: "",
		formC32ANumber: "",
		mustCallOnss: true,
		endDate: "",
		lastEuAbsenceDate: "",
		address: "office",
		selectedCustomer: {},
	})
	const [c32aInvalidMessage, setC32aInvalidMessage] = useState("")

	const currAffiliate = useSelector(selector => selector.redData.currentAffiliate)

	const updateStateParams = useCallback(
		params => {
			let newState = { ...state }
			Object.keys(params).forEach(key => {
				newState[key] = params[key]
			})
			return setState(newState)
		},
		[state]
	)

	useEffect(() => {
		const fetchCancelledEvents = async () => {
			setRequestDone(true)
			let params = {
				data: init.startDate.format("DD-MM-YYYY"),
				cleaner_id: cleanerId,
			}
			await dispatch(
				actions.simpleFetch(
					"/front/affiliates/events/cancelled_events" + stringifyQuery(params),
					{ method: "GET" },
					"CANCELLEDEVENTS"
				)
			)
		}
		if (!state.startDate) {
			updateStateParams(init)
		}
		if (
			state.activityCode === currAffiliate.euSSCode &&
			state.activityCategory === "group_s_absence" &&
			currAffiliate.hasONSSSettings &&
			currAffiliate.dimonaAddOn &&
			!requestDone
		) {
			fetchCancelledEvents()
		}
	}, [
		init,
		requestDone,
		updateStateParams,
		state.startDate,
		cancelledEvents,
		state,
		cleanerId,
		dispatch,
		currAffiliate.hasONSSSettings,
		currAffiliate.euSSCode,
		currAffiliate.dimonaAddOn,
		state.activityCategory,
		state.activityCode,
	])

	function handleFormChanges(changes) {
		if (changes.address) {
			changes.selectedCustomer = {}
			setChangesDone(true)
		}
		if (changes.recurrence) {
			setRecurrence(changes.recurrence)
		}
		if (changes.activityCategory) {
			changes.activityCode = currAffiliate.secSocEvents.codes
				.filter(entry => entry.timesheet_category !== "expense")
				.filter(entry => entry.category_id === changes.activityCategory)[0].id
		}
		if (Object.keys(changes)[0] === "start" && Object.keys(changes).length === 1) {
			if (changes["start"]._d > state.end) {
				changes["end"] = changes["start"].add(5, "m")
			}
		}
		updateStateParams(changes)
	}
	function handleCustomerClick(customer) {
		let changes = { chosenCustomer: customer }
		if (customer.attributes.residences.data.length === 1) {
			changes.residenceIndex = "0"
		}
		if (customer.attributes.voucher_type_preference) {
			changes.voucherType = customer.attributes.voucher_type_preference
		} else {
			changes.voucherType = ""
		}
		let selectedCustomer = {
			selectedCustomer: {
				name: customer.attributes.display_name,
				address: customer.attributes.residences.data[0],
			},
		}
		updateStateParams(selectedCustomer)
	}

	function disableEndDays(day) {
		if (day.isBefore(state.startDate)) {
			return true
		}

		if (state.dayPeriod === "7") {
			return (day.diff(state.startDate, "days") + 1) % Number(state.dayPeriod) !== 0
		} else if (state.dayPeriod === "14") {
			return (day.diff(state.startDate, "days") + 1) % Number(state.dayPeriod) !== 0
		}
	}

	let reachOptions = [
		{
			label: t("modify_one_oa"),
			value: "one_occurrence",
		},
	]
	if (otherActivity && otherActivity.dayPeriod !== 0) {
		reachOptions.push({
			label: t("modify_all_oa"),
			value: "all_occurrences_from_date",
		})
	}

	function validateState() {
		let activityCode = state.activityCode
		let lastEuAbsenceDate = state.lastEuAbsenceDate
		let end = state.end
		let toCheck = ["startDate", "start", "end", "activityCategory", "activityCode"],
			valid = true
		if (recurrence === "all_occurrences_from_date" || !otherActivity) {
			toCheck.push("dayPeriod")
		}
		if (
			activityCode === currAffiliate.euSSCode &&
			state.activityCategory === "group_s_absence" &&
			currAffiliate.hasONSSSettings &&
			currAffiliate.dimonaAddOn &&
			(!lastEuAbsenceDate || !end.isSame(lastEuAbsenceDate, "month"))
		) {
			toCheck.push("address")
		}
		let toRequire = []
		toCheck.forEach(entry => {
			if (!state[entry]) {
				valid = false
				toRequire.push(entry)
			}
		})
		let newRequired = { ...state.showRequired }
		toRequire.forEach(entry => {
			newRequired[entry] = true
		})
		let required = { showRequired: newRequired }
		updateStateParams(required)

		if (state.address === "client" && Object.keys(state.selectedCustomer).length === 0) {
			valid = false
		}

		return valid
	}

	function submitActivity() {
		let start = state.start
		let end = state.end
		let startDate = state.startDate
		let endDate = state.endDate
		let dayPeriod = state.dayPeriod
		let activityCategory = state.activityCategory
		let activityCode = state.activityCode
		let note = state.note
		let formC32ANumber = state.formC32ANumber
		let mustCallOnss = state.mustCallOnss
		let address = state.address
		let selectedCustomer = state.selectedCustomer
		let valid = validateState()
		if (!valid) {
			return
		}

		if (address === "cancelled") {
			address = "client"
		}
		let duration = Math.round(end.diff(start, "seconds") / 60)
		let eventId = otherActivity ? otherActivity.realEventId : ""
		submit({
			startDate: moment(startDate),
			endDate: moment(endDate).add(1, "day"),
			start: moment(start),
			end: moment(end),
			dayPeriod,
			duration,
			activityCategory,
			activityCode,
			note,
			formC32ANumber,
			mustCallOnss,
			isWorkshopHour: false,
			address: address,
			realEventId: eventId,
			recurrence: recurrence,
			selectedCustomer: selectedCustomer,
		})
		closeModal()
	}

	function checkFormC32A(e) {
		if (e.target.value.match(/^[a-zA-Z]{2}\d{7}$/)) {
			return setC32aInvalidMessage(false)
		} else {
			return setC32aInvalidMessage(t("wrong_format_form_c32a"))
		}
	}

	function handleBorderColor(event) {
		let selectedCustomer = {
			selectedCustomer: {
				name: event.attributes.display_name,
				address: event.attributes.address,
			},
		}
		updateStateParams(selectedCustomer)
	}

	function getBorderColor(addressId) {
		if (state.selectedCustomer.address && addressId === state.selectedCustomer.address.id) {
			return "gold"
		} else {
			return "black"
		}
	}

	let filteredCodes = currAffiliate.secSocEvents.codes.filter(
		entry => entry.timesheet_category !== "expense"
	)

	let addresses = [
		{ label: t("office"), id: "office" },
		{ label: t("customer"), id: "client" },
	]

	if (requestDone && cancelledEvents && cancelledEvents.length > 0) {
		addresses.push({ label: t("cancelled_events"), id: "cancelled" })
		if (!changesDone) {
			state.address = "cancelled"
		}
	}

	const possibleCategories = currAffiliate.secSocEvents.categories.filter(
		category => category.id !== "group_s_benefit"
	)

	if (state.activityCategory) {
		filteredCodes = filteredCodes.filter(entry => entry.category_id === state.activityCategory)
	}
	if (blockCodes && workInterruption !== "05") {
		filteredCodes = filteredCodes.filter(entry => !blockedCodes.includes(entry.label))
	}

	return (
		<InputsChangeHandler onChange={handleFormChanges}>
			<ModalLayout
				title={t("new_other_activity")}
				formDivClass="other-activity-modal"
				closeModal={closeModal}
			>
				{otherActivity && (
					<PootsySelectInput
						name="recurrence"
						defaultText={t("modification_reach")}
						selected={recurrence}
						options={reachOptions}
					/>
				)}
				<PootsySelectInput
					name="activityCategory"
					defaultText={t("activity_category")}
					showRequired={state.showRequired.activityCategory}
					selected={state.activityCategory}
					options={possibleCategories.map(entry => ({
						label: t(entry.label),
						value: entry.id,
					}))}
				/>
				<PootsySelectInput
					name="activityCode"
					defaultText={t("activity_code")}
					customClass="ss-code"
					showRequired={state.showRequired.activityCode}
					selected={state.activityCode}
					options={filteredCodes
						.sort((a, b) => t(a.label).localeCompare(t(b.label)))
						.map(entry => ({
							label: entry.id + " - " + t(entry.label),
							value: entry.id,
						}))}
				/>
				<div className="dates">
					<LabeledDateTime
						label={t("start_date")}
						showTimePicker={false}
						datePickerProps={{
							name: "startDate",
							value: state.startDate,
							onChange: handleFormChanges,
							calendarStyle: { top: "150%", left: 0 },
							disableDay: disableDay,
						}}
					/>
					<LabeledDateTime
						label={t("start_time")}
						showDatePicker={false}
						timePickerProps={{
							value: state.start,
							name: "start",
							onChange: handleFormChanges,
						}}
					/>
					<LabeledDateTime
						label={t("end_time")}
						showDatePicker={false}
						timePickerProps={{
							value: state.end,
							name: "end",
							onChange: handleFormChanges,
							filterHours: h => h >= state.start.hours(),
							filterMinutes: m =>
								state.end.hours() > state.start.hours()
									? m >= 0 && m <= 59
									: m > state.start.minute(),
						}}
					/>
				</div>
				{state.activityCode === currAffiliate.euSSCode &&
					state.activityCategory === "group_s_absence" &&
					currAffiliate.hasONSSSettings &&
					currAffiliate.dimonaAddOn &&
					(!state.lastEuAbsenceDate ||
						!state.end.isSame(state.lastEuAbsenceDate, "month")) && (
						<Fragment>
							<PootsyTextInput
								name="formC32ANumber"
								label={t("form_c32a_number")}
								value={state.formC32ANumber}
								onChange={e => {
									checkFormC32A(e)
								}}
								errorMessage={c32aInvalidMessage}
							/>
							<PootsyCheckboxInput
								name="mustCallOnss"
								label={t("economic_unemployment_must_be_done_on_onss")}
								checked={state.mustCallOnss}
							/>
							{state.startDate.isAfter(moment().add(7, "days")) && (
								<div className="warning-eco_unemployment">
									{" "}
									{t("over_7_days_eco_unemployment")}
								</div>
							)}
							<PootsySelectInput
								name="address"
								defaultText={t("address")}
								showRequired={state.showRequired.address}
								selected={state.address}
								options={addresses.map(entry => ({
									label: t(entry.label),
									value: entry.id,
								}))}
							/>
							<SmallSpinnerSection
								sectionClass={"loadCancelledEvents"}
								isLoading={loading}
							>
								{state.address === "client" && (
									<div className="search-or-create-customer">
										<SearchCustomer
											handleCustomerClick={handleCustomerClick}
											showRequired={true}
										/>
									</div>
								)}
								{state.address === "client" && state.selectedCustomer.name && (
									<div>
										{state.selectedCustomer.name}
										<br />
										{state.selectedCustomer.address.attributes.address}
									</div>
								)}
								{state.address === "cancelled" && cancelledEvents && (
									<div className="cancelled_events">
										{cancelledEvents.map(event => (
											<div
												className="cancelled_event"
												style={{
													"border-color": getBorderColor(
														event.attributes.address.id
													),
												}}
												onClick={() => handleBorderColor(event)}
											>
												<div className="cancelledAddress">
													{event.attributes.address.address_inline}
												</div>
												<div className="displayName">
													{event.attributes.display_name}
												</div>
												<div className="cancelledTime">
													{event.attributes.start_time.hour.toLocaleString(
														"en-US",
														{
															minimumIntegerDigits: 2,
															useGrouping: false,
														}
													)}
													:
													{event.attributes.start_time.minute.toLocaleString(
														"en-US",
														{
															minimumIntegerDigits: 2,
															useGrouping: false,
														}
													)}{" "}
													-
													{event.attributes.end_time.hour.toLocaleString(
														"en-US",
														{
															minimumIntegerDigits: 2,
															useGrouping: false,
														}
													)}
													:
													{event.attributes.end_time.minute.toLocaleString(
														"en-US",
														{
															minimumIntegerDigits: 2,
															useGrouping: false,
														}
													)}
												</div>
											</div>
										))}
									</div>
								)}
							</SmallSpinnerSection>
						</Fragment>
					)}
				{((otherActivity &&
					otherActivity.dayPeriod > 0 &&
					recurrence === "all_occurrences_from_date") ||
					!otherActivity) && (
					<div className="recurrence">
						{!hideDayPeriodSelector && (
							<PootsyRadioInputs
								groupLabel={t("recurrence")}
								groupName="dayPeriod"
								showRequired={state.showRequired.dayPeriod}
								selected={state.dayPeriod}
								options={[
									{ id: "once", value: "0", label: t("once") },
									{ id: "weekly", value: "7", label: t("every_week") },
									{ id: "bimonthly", value: "14", label: t("every_two_weeks") },
									{ id: "monthly", value: "28", label: t("every_four_weeks") },
								]}
							/>
						)}
					</div>
				)}
				{state.dayPeriod !== "0" && (
					<LabeledDateTime
						showTimePicker={false}
						label={t("optional_end_date")}
						datePickerProps={{
							name: "endDate",
							value: state.endDate,
							alwaysOpened: false,
							disableAutoFocus: true,
							onChange: handleFormChanges,
							disableDay: disableEndDays,
							disableInput: true,
						}}
					/>
				)}
				<PootsyTextArea label={t("comment")} name="note" value={state.note} />
				<PootsyButton text={t("submit")} onClick={submitActivity} />
			</ModalLayout>
		</InputsChangeHandler>
	)
}
