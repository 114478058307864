import React from "react"

export const QuestionMark = ({ tooltipText }) => (
	<div className="question-mark-svg">
		<div className="question-mark-svg tooltip">{tooltipText}</div>
		<svg className="question-mark-svg svg" width={14} height={15} viewBox="0 0 14 15">
			<g fill="none" fillRule="evenodd">
				<circle fill="#8C8C8C" cx={7} cy={7.933} r={7} />
				<text fontFamily="Hind-SemiBold, Hind" fontSize={11} fontWeight={500} fill="#FFF">
					<tspan x={4.667} y={12}>
						?
					</tspan>
				</text>
			</g>
		</svg>
	</div>
)
