import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"

import * as actions from "../actions"

import { PootsyButton, PootsySubHeading, LabeledDateTime } from "./FunctionalDesign"
import { PootsySelectInput, PootsyTextInput } from "./FunctionalInputs"
import ModalLayout from "../components/ModalLayout"
import WorkshopOrderQuoteDisplayer from "./WorkshopOrderQuoteDisplayer"
import AvailableWorkshopOrderItemInput from "./AvailableWorkshopOrderItemInput"

import arrowRightIcon from "../static/images/arrow-right-icon.png"

class UpdateWorkshopOrderModal extends Component {
	state = {
		queuedCall: "",
	}
	componentDidMount = async () => {
		let {
			dispatch,
			currentWorkshopOrder: {
				status,
				scheduledPickupDate,
				items,
				quote: originalQuote,
				workshopId,
				billableHoursOverride,
				customer,
			},
		} = this.props
		dispatch(actions.fetchAvailableOrderItemsList())
		dispatch(actions.fetchWorkshopOrderLinkedCustomerContracts(customer.id))
		await this.handleChanges({
			workshopId,
			scheduledPickupDate,
			currentQuote: { ...originalQuote },
			items: items,
			billableHoursOverride,
		})
		if (items.length > 0) {
			this.fetchOrderQuote()
		}
		if (["DONE", "COLLECTED"].includes(status)) return
		this.checkEarliestPossiblePickUpDate()
	}
	checkEarliestPossiblePickUpDate = async () => {
		let { dispatch, currentWorkshopOrder } = this.props
		let { actionType, data } = await dispatch(
			actions.fetchEarliestPickUpDate({
				workshopId: currentWorkshopOrder.workshopId,
				duration: currentWorkshopOrder.quote.estimatedDuration,
			})
		)
		if (actionType === actions.FETCH_EARLIEST_PICK_UP_DATE_FAILURE) return
		this.handleChanges({ earliestPossiblePickUpDate: data.earliest_possible_pick_up_date })
	}
	componentWillUnmount = () => {
		this.props.dispatch(actions.resetUpdateWorkshopOrderModalState())
	}
	handleChanges = changes => {
		this.props.dispatch(actions.updateWorkshopOrderModalStateChange(changes))
	}
	handleOrderItemChange = async ({ avItemID, quantity }) => {
		let {
			component: { items },
		} = this.props
		let existingObj = items.find(entry => entry.avItemID === avItemID)
		let newChosenOrderItems = []
		if (existingObj) {
			newChosenOrderItems = items.map(entry =>
				entry.avItemID === avItemID ? { avItemID, quantity } : entry
			)
		} else {
			newChosenOrderItems = [...items, { avItemID, quantity }]
		}
		await this.handleChanges({ items: newChosenOrderItems })
		this.fetchOrderQuote()
	}
	fetchOrderQuote = () => {
		let {
			dispatch,
			currentWorkshopOrder,
			component: { items },
		} = this.props
		let { queuedCall } = this.state
		clearTimeout(queuedCall)
		let futureCall
		futureCall = setTimeout(() => {
			dispatch(actions.fetchOrderQuote(items, currentWorkshopOrder.customer))
		}, 1000)
		this.setState({ queuedCall: futureCall })
	}
	toggleOrderItems = () => {
		let {
			component: { showOrderItems },
		} = this.props
		this.handleChanges({ showOrderItems: !showOrderItems })
	}
	submitUpdateWorkshopOrder = () => {
		let {
			currentWorkshopOrder,
			submitUpdate,
			component: {
				workshopId,
				scheduledPickupDate,
				items,
				billableHoursOverride,
				newCustomerContractId,
			},
		} = this.props
		submitUpdate({
			...currentWorkshopOrder,
			workshopId,
			scheduledPickupDate,
			items,
			newCustomerContractId,
			billableHoursOverride,
		})
	}
	render() {
		let {
			availableOrderItems,
			currentAffiliate,
			currentWorkshopOrder: { status },
			component: {
				workshopId,
				scheduledPickupDate,
				items,
				earliestPossiblePickUpDate,
				currentQuote,
				showOrderItems,
				fetchingQuote,
				billableHoursOverride,
				linkedCustomers,
				newCustomerContractId,
			},
			closeModal,
		} = this.props
		let { t } = this.context
		return (
			<ModalLayout
				customClass="UpdateWorkshopOrderModal"
				title={t("update_workshop_order")}
				closeModal={closeModal}
				withInputsChangeHandler={true}
				handleChanges={this.handleChanges}
				buttons={[
					<PootsyButton
						key="a"
						text={t("update_workshop_order")}
						onClick={this.submitUpdateWorkshopOrder}
					/>,
					<PootsyButton key="b" text={t("cancel")} theme="cancel" onClick={closeModal} />,
				]}
			>
				<div className="top-part">
					{status === "PLANNED" && (
						<Fragment>
							<PootsySelectInput
								name="workshopId"
								defaultText={t("workshop")}
								selected={workshopId}
								options={currentAffiliate.workshops.map(entry => ({
									label: entry.workshopName,
									value: entry.id,
								}))}
								onChange={this.handleChanges}
							/>
							<PootsyTextInput
								label={t("billable_hours_override")}
								name="billableHoursOverride"
								value={billableHoursOverride}
								type="number"
								min="0"
							/>
						</Fragment>
					)}
					{!["DONE", "COLLECTED"].includes(status) && (
						<LabeledDateTime
							label={t("pick_up_date")}
							showTimePicker={false}
							datePickerProps={{
								name: "scheduledPickupDate",
								value: scheduledPickupDate,
								disableDay: day =>
									day.isBefore(moment(), "day") ||
									(!!earliestPossiblePickUpDate &&
										day.isBefore(earliestPossiblePickUpDate)),
								onChange: this.handleChanges,
								calendarStyle: { top: "150%", right: 0 },
								// onMonthChange: this.handleDisplayedMonthChange,
							}}
						/>
					)}
				</div>
				<WorkshopOrderQuoteDisplayer
					quote={currentQuote}
					categoryFilter="SERVICE_VOUCHER"
					handleOrderItemChange={this.handleOrderItemChange}
					loading={fetchingQuote}
				/>
				<div
					className={"collapser" + (showOrderItems ? "" : " toggled")}
					onClick={this.toggleOrderItems}
				>
					<img className="arrow-img" src={arrowRightIcon} alt="arrow" />{" "}
					<PootsySubHeading text={t("toggle_order_items")} />
				</div>
				{linkedCustomers.length > 0 && (
					<PootsySelectInput
						name="newCustomerContractId"
						defaultText={t("linked_customers_title")}
						selected={newCustomerContractId}
						options={linkedCustomers.map(lc => ({
							label: lc.attributes.display_name,
							value: lc.id,
						}))}
					/>
				)}
				<div className="order-items">
					{showOrderItems && (
						<div className="order-item-category">
							<PootsySubHeading text={t("CASH")} />
							<div className="items">
								{availableOrderItems
									.filter(entry => entry.categoryName === "CASH")
									.map(avOrderItem => {
										let chosen = items.find(
											entry => entry.avItemID === avOrderItem.id
										)
										let quantity = chosen ? chosen.quantity : 0
										return (
											<AvailableWorkshopOrderItemInput
												key={avOrderItem.id}
												orderItem={avOrderItem}
												quantity={quantity}
												onChange={this.handleOrderItemChange}
											/>
										)
									})}
							</div>
						</div>
					)}
				</div>
			</ModalLayout>
		)
	}
}

UpdateWorkshopOrderModal.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currentWorkshopOrder: state.redData.currentWorkshopOrder,
	currentAffiliate: state.redData.currentAffiliate,
	component: state.redComponents.updateWorkshopOrderModalComponent,
	availableOrderItems: state.redData.avOrderItemsList,
})
export default connect(mapStateToProps)(UpdateWorkshopOrderModal)
