import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment"

import { DatedLine, StartToEndTime } from "./FunctionalDesign"

import { bookingHasHoursDifference, getEndTime } from "../actions/checkHours"
import monitorIcon from "../static/images/monitor-icon.png"
import fileIcon from "../static/images/file-icon.png"
import greenDotIcon from "../static/images/greendot-icon.png"
import clockIcon from "../static/images/clock-icon.png"
import orangeDotIcon from "../static/images/orangedot-icon.png"
import redDotIcon from "../static/images/reddot-icon.png"
import greyDotIcon from "../static/images/greydot-icon.png"
import cancelIcon from "../static/images/cancel-icon.png"
import recurrenceIcon from "../static/images/recurrence-icon.png"

export default class BookingItem extends Component {
	state = {
		statusIcon: {
			assigned: greenDotIcon,
			ongoing: orangeDotIcon,
			finished: greenDotIcon,
			finished_without_confirmation: greenDotIcon,
			delayed: clockIcon,
			problem: redDotIcon,
			no_information: greyDotIcon,
			cancelled: cancelIcon,
		},
	}
	render() {
		let { t } = this.context
		let { booking, onClick, noLink = false } = this.props
		let deliveryDate = moment(booking.deliveryDate)
		return (
			<DatedLine
				customClass="BookingItem"
				noLink={noLink}
				onClick={onClick}
				link={"/bookings/" + booking.id}
				day={deliveryDate.format("ddd")}
				date={deliveryDate.date()}
				month={deliveryDate.format("MMMM")}
				sideIcon={
					booking.recurrence && (
						<img alt="recurrent" className="recurrence-icon" src={recurrenceIcon} />
					)
				}
			>
				<div className="booking-item-names">
					<div className="booking-names-container">
						<span className="customer-name">{booking.customerName}</span>
						<span className="worker-name">
							{t("cleaner")}: {booking.workerName}
						</span>
					</div>
					<div className="booking-voucher-type-container">
						<label>{t(booking.voucherType)}</label>
						<img
							alt="presentation"
							src={
								booking.voucherType &&
								booking.voucherType.toLowerCase() === "electronic"
									? monitorIcon
									: fileIcon
							}
						/>
					</div>
				</div>
				{(booking.voucherType === "paper" || booking.sodexoStatus) &&
					["finished", "finished_without_confirmation"].includes(
						booking.serviceDeliveryStatus
					) &&
					booking.voucherCount !== null && (
						<div className="booking-item-voucher-count">
							{booking.sodexoStatus ? (
								<div className="sodexo-status">
									<div className="sodexo-duration">
										{t("service_voucher_count")}: {booking.sodexoDuration}
									</div>
									<span className="sodexo-status-value">
										{t(booking.sodexoStatus)}
									</span>
								</div>
							) : (
								<div className="voucher-count">
									{t("service_voucher_count")}: {booking.voucherCount}
								</div>
							)}
							{booking.voucherType === "paper" &&
								booking.pServiceVouchersAffiliate.length ===
									booking.voucherCount && (
									<div className="psv-status">{t("p_payment_encoded")}</div>
								)}
							{booking.voucherType === "paper" &&
								booking.pServiceVouchersAffiliate.length < booking.voucherCount && (
									<div className="psv-status">
										{t("missing_vouchers")}:{" "}
										{booking.voucherCount -
											booking.pServiceVouchersAffiliate.length}
									</div>
								)}
						</div>
					)}
				<StartToEndTime
					start={booking.startTime}
					end={getEndTime(booking)}
					realStart={booking.sdStartTime}
					realEnd={booking.sdEndTime}
				/>
				{bookingHasHoursDifference(booking) && (
					<label style={{ color: "red" }}>{t("number_of_hours_different")}</label>
				)}
				<div className="booking-item-status">
					<img
						alt="presentation"
						className="booking-status-icon"
						src={
							this.state.statusIcon[
								booking.serviceDeliveryStatus &&
									booking.serviceDeliveryStatus.toLowerCase()
							]
						}
					/>
					<label>{t(booking.serviceDeliveryStatus)}</label>
				</div>
			</DatedLine>
		)
	}
}

BookingItem.contextTypes = { t: PropTypes.func }
