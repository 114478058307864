import React from "react"

export const RightAlignedTextIcon = props => (
	<svg viewBox="0 0 1000 1000" {...props}>
		<path
			className="ql-stroke fill"
			d="M15.28 150.43c-3.38 3.17-5.28 7.6-5.28 11.62 0 4.01 1.9 8.45 5.28 11.62l5.07 5.28H979.7l5.07-5.28c6.97-6.76 6.97-16.47 0-23.23l-5.07-5.28H20.35l-5.07 5.27zM150.46 319.41c-3.38 3.17-5.28 7.6-5.28 11.62 0 4.01 1.9 8.45 5.28 11.62l5.07 5.28H979.7l5.07-5.28c6.97-6.76 6.97-16.47 0-23.23l-5.07-5.28H155.53l-5.07 5.27zM285.64 488.38c-3.38 3.17-5.28 7.6-5.28 11.62 0 4.01 1.9 8.45 5.28 11.62l5.07 5.28h689l5.07-5.28c6.97-6.76 6.97-16.47 0-23.23l-5.08-5.29h-689l-5.06 5.28zM82.87 657.36c-3.38 3.17-5.28 7.6-5.28 11.62s1.9 8.45 5.28 11.62l5.07 5.28H979.7l5.07-5.28c6.97-6.76 6.97-16.47 0-23.23l-5.07-5.28H87.94l-5.07 5.27zM15.28 826.33c-3.38 3.17-5.28 7.6-5.28 11.62s1.9 8.45 5.28 11.62l5.07 5.28H979.7l5.07-5.28c6.97-6.76 6.97-16.47 0-23.23l-5.07-5.28H20.35l-5.07 5.27z"
		/>
	</svg>
)
