import React, { Component } from "react"

class FilterFromSearch extends Component {
	handleRemoveValue = e => {
		let { currentValues, handleRemoveValue } = this.props
		let value = currentValues.find(entry => entry.value.toString() === e.target.dataset.value)
		if (!value) {
			return
		}
		handleRemoveValue(value)
	}
	render() {
		let { searchComponent, currentValues } = this.props
		return (
			<div className="FilterFromSearch">
				{searchComponent}
				<div className="values">
					{currentValues.map(entry => (
						<span
							key={entry.value}
							data-value={entry.value}
							data-name={entry.name}
							className="value"
							onClick={this.handleRemoveValue}
						>
							{entry.label}
						</span>
					))}
				</div>
			</div>
		)
	}
}

export default FilterFromSearch
