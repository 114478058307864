import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import SearchWorker from "./SearchWorker"
import * as actions from "../actions"
import { PootsyButton, LabeledDateTime, LabeledSimpleLine } from "./FunctionalDesign"
import { PootsyTextInput, PootsySelectInput } from "./FunctionalInputs"
import ModalLayout from "../components/ModalLayout"

class NewDimonaModal extends Component {
	handleChanges = changes => {
		this.props.dispatch(actions.processNewDimonaStateChange(changes))
	}
	UNSAFE_componentWillMount = () => {
		this.props.dispatch(actions.processNewDimonaStateChange())
	}
	componentWillUnmount = () => {
		this.props.dispatch(actions.resetDimonaModalState())
	}
	handleSearchWorker = workerName => {
		this.handleChanges({ chosenWorker: { name: workerName, sodexo: "" } })
	}
	handleWorkerClick = worker => {
		let newChosenWorker = {
			name: worker.attributes.display_name,
			sodexo: worker.attributes.sodexo_reference,
			affiliateWorkerId: Number(worker.id),
		}
		this.handleChanges({ chosenWorker: newChosenWorker })
		this.props.dispatch(actions.resetWorkerSuggestions())
	}
	handleCancelButtonClick = () => {
		let {
			dispatch,
			closeModal,
			component: { afterContractCreation },
		} = this.props
		if (afterContractCreation) {
			dispatch(actions.doNotCreateDimona())
		}
		closeModal()
	}
	selectCorrespondingDimonaIn = dimona => {
		this.handleChanges({
			onssRefNumber: dimona.onssRefNumber,
			potentialCorrespondingDimonaIn: [],
		})
	}
	submitNewDimona = () => {
		let {
			submitNewDimona: providedSubmit,
			component: { commission, dimonaWorkerType, chosenWorker, dimonaType, onssRefNumber },
		} = this.props
		let valid = true
		let newShowRequired = {}
		if (!commission) {
			valid = false
			newShowRequired.commission = true
		}
		if (!dimonaWorkerType) {
			valid = false
			newShowRequired.dimonaWorkerType = true
		}
		if (
			dimonaType === "OUT" &&
			(!onssRefNumber || onssRefNumber.replace(/ /g, "").length !== 12)
		) {
			valid = false
			newShowRequired.onssRefNumber = true
		}
		if (!chosenWorker.affiliateWorkerId) {
			valid = false
			newShowRequired.chosenWorker = true
		}
		if (!dimonaType) {
			valid = false
			newShowRequired.dimonaType = true
		}
		if (!valid) {
			this.handleChanges({ showRequired: newShowRequired })
			return
		}
		if (providedSubmit) {
			providedSubmit()
		} else {
			this.props.dispatch(actions.submitNewDimona())
		}
		this.props.closeModal()
	}
	render() {
		let { t } = this.context
		let {
			component: {
				commission,
				dimonaWorkerType,
				dateIn,
				dateOut,
				chosenWorker,
				dimonaType,
				contractOut,
				contractIn,
				onssRefNumber,
				showRequired,
				potentialCorrespondingDimonaIn,
			},
		} = this.props
		let valuesDimonaType = []
		if (!contractOut) {
			valuesDimonaType.push({ label: "IN", value: "IN" })
		}
		if (!contractIn) {
			valuesDimonaType.push({ label: "OUT", value: "OUT" })
		}
		return (
			<ModalLayout
				customClass="new-dimona-modal"
				title={t("new_dimona")}
				buttons={[
					<PootsyButton
						key="a"
						text={t("create_dimona")}
						onClick={this.submitNewDimona}
					/>,
					<PootsyButton
						key="b"
						text={t("cancel")}
						theme="cancel"
						onClick={this.handleCancelButtonClick}
					/>,
				]}
				withInputsChangeHandler={true}
				handleChanges={this.handleChanges}
			>
				<SearchWorker
					onChange={this.handleSearchWorker}
					onClick={this.handleWorkerClick}
					value={chosenWorker.name}
				/>
				<div className="dates">
					{!contractOut && (
						<LabeledDateTime
							label={t("date_in")}
							allowEmptyTime={true}
							showTimePicker={false}
							datePickerProps={{
								name: "dateIn",
								value: dateIn,
								onChange: this.handleChanges,
								calendarStyle: { top: "150%", left: 0 },
							}}
						/>
					)}
					<LabeledDateTime
						label={t("date_out")}
						showTimePicker={false}
						allowEmptyTime={true}
						datePickerProps={{
							name: "dateOut",
							value: dateOut,
							onChange: this.handleChanges,
							calendarStyle: { top: "150%", left: 0 },
						}}
					/>
				</div>
				<PootsyTextInput
					name="commission"
					label={t("commission_paritaire")}
					type="text"
					showRequired={showRequired.commission}
					value={commission}
				/>
				<PootsySelectInput
					name="dimonaType"
					defaultText={t("dimona__type")}
					selected={dimonaType}
					showRequired={showRequired.dimonaType}
					options={valuesDimonaType}
				/>
				{dimonaType === "OUT" && (
					<div className="onss-ref-number-section">
						{potentialCorrespondingDimonaIn.length > 0 && (
							<div className="potential-corresponding-dimonas-in">
								<div className="label">
									{t("potential_corresponding_dimonas_in_list_label")}
								</div>
								<div className="list">
									{potentialCorrespondingDimonaIn.map(entry => (
										<LabeledSimpleLine
											key={entry.id}
											simpleLineProps={{
												noLink: true,
												onClick: () =>
													this.selectCorrespondingDimonaIn(entry),
											}}
											columns={[
												{
													label: t("dimona__type"),
													value: entry.dimonaType,
												},
												{
													label: t("worker"),
													value: entry.workerDisplayName,
												},
												{
													label: t("date_in"),
													value: entry.dateIn.format("DD/MM/YY"),
												},
												{
													label: t("onss_ref_number"),
													value: entry.onssRefNumber,
												},
											]}
										/>
									))}
								</div>
							</div>
						)}
						<PootsyTextInput
							name="onssRefNumber"
							label={t("onss_ref_number")}
							type="text"
							showRequired={showRequired.onssRefNumber}
							value={onssRefNumber}
						/>
						<div className="onss-ref-number-description">
							{t("dimonas_onss_ref_number_description")}
						</div>
					</div>
				)}
				<PootsySelectInput
					name="dimonaWorkerType"
					defaultText={t("dimona_worker_type")}
					selected={dimonaWorkerType}
					showRequired={showRequired.dimonaWorkerType}
					options={[
						{ label: t("dimona_worker_tri"), value: "TRI" },
						{ label: t("dimona_worker_oth"), value: "OTH" },
						{ label: t("dimona_worker_stu"), value: "STU" },
						{ label: t("dimona_worker_flx"), value: "FLX" },
					]}
				/>
			</ModalLayout>
		)
	}
}

NewDimonaModal.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.newDimonaModalComponent,
	dimonas: state.redData.dimonas,
})
export default connect(mapStateToProps)(NewDimonaModal)
