import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import * as actions from "../actions"
import Paginator from "./Paginator"
import { ContentLayout, WorkshopOrderLine } from "./FunctionalDesign"
import { PootsyTextInput } from "./FunctionalInputs"
import WorkshopOrdersFilters from "./WorkshopOrdersFilters"

class WorkshopOrders extends Component {
	handleSearchChange = e => {
		this.props.dispatch(actions.workshopOrdersFiltersStateChange({ search: e.target.value }))
	}
	handleChanges = changes => {
		this.props.dispatch(actions.workshopOrdersStateChange(changes))
	}
	handleChangePage = changes => {
		this.props.dispatch(actions.workshopOrdersFiltersStateChange({ page: changes.value }))
	}
	render() {
		let { t } = this.context
		let { match, location, history, workshopOrders, page, pagination } = this.props
		let optionsBoxes = (
			<WorkshopOrdersFilters
				match={match}
				location={location}
				history={history}
				hideFromToFilter={false}
				hideExecutionDateFilter={false}
				customQueries={{
					searchQuery: { urlName: "s", queryName: "filter_name", mustBeSet: false },
					executionDateQuery: {
						urlName: "executionDate",
						queryName: "filter_execution_date",
						mustBeSet: false,
					},
					pickUpDateQuery: {
						urlName: "pickUpDate",
						queryName: "filter_pick_up_date",
						mustBeSet: false,
					},
					dropOffDateQuery: {
						urlName: "dropOffDate",
						queryName: "filter_drop_off_date",
						mustBeSet: false,
					},
					limboOrdersQuery: {
						urlName: "limbo",
						queryName: "filter_limbo",
						mustBeSet: true,
						defaultValue: "false",
					},
					fromDateQuery: {
						urlName: "from",
						queryName: "from_date",
						mustBeSet: false,
					},
					toDateQuery: {
						urlName: "to",
						queryName: "to_date",
						mustBeSet: false,
					},
				}}
			/>
		)
		let contentTop = (
			<PootsyTextInput
				customClass="workshop-orders-search-input"
				label={t("search_workshop_orders")}
				onChange={this.handleSearchChange}
			/>
		)
		return (
			<ContentLayout
				customClass="WorkshopOrders"
				optionsBoxes={optionsBoxes}
				contentTop={contentTop}
			>
				{workshopOrders.map(entry => (
					<WorkshopOrderLine key={entry.id} workshopOrder={entry} />
				))}
				<Paginator
					name="pageQuery"
					currPage={page}
					totalPages={pagination.total_pages}
					goToPage={this.handleChangePage}
				/>
			</ContentLayout>
		)
	}
}

WorkshopOrders.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.workshopOrdersComponent,
	workshopOrders: state.redData.workshopOrders,
	page: state.redComponents.workshopOrdersFiltersComponent.page,
	pagination: state.redComponents.workshopOrdersFiltersComponent.pagination,
})
export default connect(mapStateToProps)(WorkshopOrders)
