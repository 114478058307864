import React, { Component } from "react"
import PropTypes from "prop-types"

import { PootsyButton } from "./FunctionalDesign"

import ModalLayout from "./ModalLayout"
class DeleteLaundryOrderModal extends Component {
	submitDeleteOrder = () => {
		this.props.submit(this.props.id)
	}
	render() {
		let { t } = this.context
		let { closeModal } = this.props
		return (
			<ModalLayout
				title={t("delete_workshop_order")}
				closeModal={closeModal}
				customClass="delete-workshop-order-modal"
				buttons={[
					<PootsyButton key="a" text={t("delete")} onClick={this.submitDeleteOrder} />,
					<PootsyButton key="b" text={t("cancel")} theme="cancel" onClick={closeModal} />,
				]}
			>
				<div className="warning">{t("delete_workshop_order_warning")}</div>
			</ModalLayout>
		)
	}
}

DeleteLaundryOrderModal.contextTypes = { t: PropTypes.func }
export default DeleteLaundryOrderModal
