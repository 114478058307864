import React, { useContext } from "react"
import moment from "moment"
import { useSelector } from "react-redux"

import { LocaleContext } from "../index.js"
import { PootsyButton, ConfirmationModal } from "./FunctionalDesign"
import {
	useTimerangeDropdownClosing,
	useLastActiveTimerange,
	useAbsenceModalToggling,
	useEditAbsenceModalToggling,
	useDeleteAbsenceModalToggling,
	useDeleteHolidayModalToggling,
	useRestoreHolidayModalToggling,
	useExtraAvailabilityModalToggling,
	useDirectBookingModalToggling,
	useBookingPageLink,
	useAbsencesPageLink,
	useDeleteExtraAvailabilityModalToggling,
	useEditBookingModalToggling,
	useCancelBookingModalToggling,
	useOtherActivityModalToggling,
	useEditOtherActivityModalToggling,
	useWorkshopHourModalToggling,
	useDeleteOtherActivityModalToggling,
	useEditWorkshopHourModalToggling,
	useDirectBookingModificationModalToggling,
	useAddMissingLaundryOrderModalToggling,
	useHandleAbsenceModalChange,
	useSubmitAbsenceWithoutImpactedBookings,
	useSubmitDeleteExtraAvail,
	useSubmitDeleteAbsence,
	useSumitWorkshopHour,
	useEditSumitWorkshopHour,
	useSubmitOtherActivity,
	useEditSubmitOtherActivity,
	useSubmitDeleteHoliday,
	useSubmitRestoreHoliday,
} from "./AgendaHooks"
import ModalLayout from "./ModalLayout"
import CancelBookingModal from "./CancelBookingModal"
// import DeleteBookingModal from "./DeleteBookingModal"
import NewDirectBookingModal from "./NewDirectBookingModal"
import ExtraAvailabilityModal from "./ExtraAvailabilityModal"
import { AbsenceModal } from "./AbsenceModal"
import { OtherActivityModal } from "./OtherActivityModal"
import { WorkshopHourModal } from "./WorkshopHourModal"
import DeleteOtherActivityModal from "./DeleteOtherActivityModal"
import DirectBookingModificationModal from "./DirectBookingModificationModal"
import MissingLaundryOrderModal from "./MissingLaundryOrderModal"
import NewBooking from "./NewBooking"

const AgendaTimerangeDropdown = ({ tr, timeRangeWidth, cleaner }) => {
	const t = useContext(LocaleContext)
	const agenda = useSelector(state => state.redComponents.agenda)
	const data = useSelector(state => state.redData.agenda)
	const { workshopAddOn } = useSelector(state => state.redData.currentAffiliate)
	let excludedHoliday = null
	if (cleaner.excludedHolidays)
		excludedHoliday = cleaner.excludedHolidays[tr.start.format("YYYY-MM-DD")]?.[0]
	const closeRangeDropdown = useTimerangeDropdownClosing()
	const getLastActiveTimerange = useLastActiveTimerange()
	const toggleAbsenceModal = useAbsenceModalToggling(getLastActiveTimerange, closeRangeDropdown)
	const toggleEditAbsenceModal = useEditAbsenceModalToggling(
		getLastActiveTimerange,
		closeRangeDropdown
	)
	const toggleDeleteAbsenceModal = useDeleteAbsenceModalToggling(closeRangeDropdown)
	const toggleExtraAvailabilityModal = useExtraAvailabilityModalToggling(closeRangeDropdown)
	const toggleDirectBookingModal = useDirectBookingModalToggling(
		closeRangeDropdown,
		getLastActiveTimerange
	)
	const toggleDeleteHolidayModal = useDeleteHolidayModalToggling(closeRangeDropdown)
	const toggleRestoreHolidayModal = useRestoreHolidayModalToggling(closeRangeDropdown)
	const goToBooking = useBookingPageLink(getLastActiveTimerange)
	const goToAbsences = useAbsencesPageLink(getLastActiveTimerange)
	const toggleDeleteExtraAvailModal = useDeleteExtraAvailabilityModalToggling(closeRangeDropdown)
	const toggleEditBookingModal = useEditBookingModalToggling(
		closeRangeDropdown,
		getLastActiveTimerange
	)
	const toggleCancelBookingModal = useCancelBookingModalToggling(
		closeRangeDropdown,
		getLastActiveTimerange
	)
	const toggleOtherActivityModal = useOtherActivityModalToggling(
		closeRangeDropdown,
		getLastActiveTimerange
	)
	const toggleEditOtherActivityModal = useEditOtherActivityModalToggling(
		closeRangeDropdown,
		getLastActiveTimerange
	)
	const toggleWorkshopHourModal = useWorkshopHourModalToggling(
		closeRangeDropdown,
		getLastActiveTimerange
	)
	const toggleEditWorkshopHourModal = useEditWorkshopHourModalToggling(
		closeRangeDropdown,
		getLastActiveTimerange
	)
	const toggleDeleteOtherActivityModal = useDeleteOtherActivityModalToggling(
		closeRangeDropdown,
		getLastActiveTimerange
	)
	const toggleDirectBookingModificationModal = useDirectBookingModificationModalToggling(
		closeRangeDropdown,
		getLastActiveTimerange
	)
	const toggleAddMissingLaundryOrderModal = useAddMissingLaundryOrderModalToggling(
		closeRangeDropdown,
		getLastActiveTimerange
	)
	const handleAbsenceModalChange = useHandleAbsenceModalChange()
	const submitAbsenceWithoutImpactedBookings = useSubmitAbsenceWithoutImpactedBookings()
	const submitDeleteExtraAvail = useSubmitDeleteExtraAvail(getLastActiveTimerange)
	const submitDeleteAbsence = useSubmitDeleteAbsence(
		getLastActiveTimerange,
		toggleDeleteAbsenceModal
	)
	const submitDeleteHoliday = useSubmitDeleteHoliday(
		getLastActiveTimerange,
		toggleDeleteHolidayModal
	)
	const submitRestoreHoliday = useSubmitRestoreHoliday(
		getLastActiveTimerange,
		excludedHoliday,
		toggleRestoreHolidayModal
	)
	const submitWorkshopHour = useSumitWorkshopHour()
	const submitEditWorkshopHour = useEditSumitWorkshopHour()
	const submitOtherActivity = useSubmitOtherActivity()
	const submitEditOtherActivity = useEditSubmitOtherActivity()
	let buttons = {
		emptyRange: [
			{ label: "add_direct_booking", action: toggleDirectBookingModal },
			{ label: "add_new_absence", action: toggleAbsenceModal },
			{ label: "add_new_other_activity", action: toggleOtherActivityModal },
			{ label: "add_extra_availability", action: toggleExtraAvailabilityModal },
			...(excludedHoliday
				? [{ label: "restore_deleted_holiday", action: toggleRestoreHolidayModal }]
				: []),
		],
		booking: [
			{ label: "see_booking_detail", action: goToBooking },
			{ label: "add_new_absence", action: toggleAbsenceModal },
			{ label: "add_new_other_activity", action: toggleOtherActivityModal },
			{ label: "modify_booking", action: toggleEditBookingModal },
			{ label: "cancel_booking", action: toggleCancelBookingModal },
			{
				label: "direct_booking_modification",
				action: toggleDirectBookingModificationModal,
			},
			// { label: "delete_booking", action: toggleDeleteBookingModal },
		],
		absence: [
			{ label: "add_new_other_activity", action: toggleOtherActivityModal },
			{ label: "see_absence_detail", action: goToAbsences },
			{ label: "edit_absence", action: toggleEditAbsenceModal },
			{ label: "delete_absence", action: toggleDeleteAbsenceModal },
		],
		daily_schedule: [
			{ label: "add_direct_booking", action: toggleDirectBookingModal },
			{ label: "add_new_absence", action: toggleAbsenceModal },
			{ label: "add_new_other_activity", action: toggleOtherActivityModal },
		],
		availability_cancellation: [
			{ label: "add_direct_booking", action: toggleDirectBookingModal },
			{ label: "add_new_absence", action: toggleAbsenceModal },
			{ label: "add_new_other_activity", action: toggleOtherActivityModal },
		],
		extra_availability: [
			{ label: "delete_extra_availability", action: toggleDeleteExtraAvailModal },
			{ label: "add_direct_booking", action: toggleDirectBookingModal },
		],
		other_activity: [
			{ label: "delete_other_activity", action: toggleDeleteOtherActivityModal },
			{ label: "direct_booking_modification", action: toggleEditOtherActivityModal },
		],
		theoretical_hour: [
			{ label: "add_direct_booking", action: toggleDirectBookingModal },
			{ label: "add_new_absence", action: toggleAbsenceModal },
			{ label: "add_new_other_activity", action: toggleOtherActivityModal },
		],
		holiday: [{ label: "delete_holiday", action: toggleDeleteHolidayModal }],
		affiliateHoliday: [],
		workshop_shift: [
			{ label: "delete_other_activity", action: toggleDeleteOtherActivityModal },
			{ label: "direct_booking_modification", action: toggleEditWorkshopHourModal },
		],
	}
	if (workshopAddOn) {
		;["booking", "emptyRange", "daily_schedule", "theoretical_hour"].forEach(entry => {
			buttons[entry].push({
				label: "add_new_workshop_hour",
				action: toggleWorkshopHourModal,
			})
		})
	}
	if (process.env.NODE_ENV === "development") {
		Object.keys(buttons).forEach(key => {
			buttons[key].push({
				label: "log object",
				action: () => console.log(getLastActiveTimerange()),
			})
		})
	}
	let buttonsToRender = buttons[tr.type]
	if (agenda.timerangeDropdownWhitelist.length > 0) {
		buttonsToRender = buttonsToRender.filter(entry =>
			agenda.timerangeDropdownWhitelist.includes(entry.label)
		)
	}
	if (tr.type === "workshop_shift" && tr.end.isBefore(moment())) {
		buttonsToRender = [
			...buttonsToRender,
			{
				label: "add_missing_laundry_order",
				action: toggleAddMissingLaundryOrderModal,
			},
		]
	}
	if (!buttonsToRender) return null
	if (buttonsToRender.length === 0) {
		return null
	}
	let deleteExtraAvailModalButtons
	if (agenda.showDeleteExtraAvailModal) {
		deleteExtraAvailModalButtons = [
			<PootsyButton key="a" text={t("yes")} onClick={submitDeleteExtraAvail} />,
			<PootsyButton
				key="b"
				text={t("no")}
				theme="cancel"
				onClick={toggleDeleteExtraAvailModal}
			/>,
		]
	}
	return (
		<div className="agenda-timerange-dropdown">
			<div className="arrow" />
			<div className="close-button" onClick={closeRangeDropdown}>
				X
			</div>
			{buttonsToRender.map((entry, index) => (
				<div key={index} className="range-dropdown-button" onClick={entry.action}>
					{t(entry.label)}
				</div>
			))}
			{agenda.showDirectBookingModal && (
				<NewDirectBookingModal
					closeModal={toggleDirectBookingModal}
					affiliateWorkerId={agenda.lastActiveRange.awi}
					workerContractId={agenda.lastActiveRange.ci}
				/>
			)}
			{agenda.showDirectBookingModificationModal && (
				<DirectBookingModificationModal closeModal={toggleDirectBookingModificationModal} />
			)}
			{agenda.showAbsenceModal && (
				<AbsenceModal
					activityCode={data.currentAbsence.activityCode}
					activityCategory={data.currentAbsence.activityCategory}
					absenceStatus="approved"
					absenceStart={data.currentAbsence.absenceStart}
					absenceType={data.currentAbsence.absenceType}
					absenceEnd={data.currentAbsence.absenceEnd}
					absenceNote={data.currentAbsence.absenceNote}
					lastEuAbsenceDate={data.currentAbsence.lastEuAbsenceDate}
					formC32ANumber={data.currentAbsence.formC32ANumber}
					mustCallOnss={data.currentAbsence.mustCallOnss}
					toggleModal={toggleAbsenceModal}
					editMode={data.currentAbsence.editedAbsenceID}
					onChange={handleAbsenceModalChange}
					submit={submitAbsenceWithoutImpactedBookings}
					t={t}
				/>
			)}
			{agenda.showDeleteAbsenceModal && (
				<ConfirmationModal
					title={t("delete_absence")}
					closeModal={toggleDeleteAbsenceModal}
					yesButton={<PootsyButton text={t("yes")} onClick={submitDeleteAbsence} />}
					noButton={
						<PootsyButton
							text={t("no")}
							theme="cancel"
							onClick={toggleDeleteAbsenceModal}
						/>
					}
				/>
			)}
			{agenda.showDeleteHolidayModal && (
				<ConfirmationModal
					title={t("delete_holiday")}
					prompt="delete_holiday_confirmation"
					closeModal={toggleDeleteHolidayModal}
					yesButton={<PootsyButton text={t("yes")} onClick={submitDeleteHoliday} />}
					noButton={
						<PootsyButton
							text={t("no")}
							theme="cancel"
							onClick={toggleDeleteHolidayModal}
						/>
					}
				/>
			)}
			{agenda.showRestoreHolidayModal && (
				<ConfirmationModal
					title={t("restore_deleted_holiday")}
					closeModal={toggleDeleteHolidayModal}
					yesButton={<PootsyButton text={t("yes")} onClick={submitRestoreHoliday} />}
					noButton={
						<PootsyButton
							text={t("no")}
							theme="cancel"
							onClick={toggleRestoreHolidayModal}
						/>
					}
				/>
			)}
			{agenda.showExtraAvailabilityModal && (
				<ExtraAvailabilityModal
					closeModal={toggleExtraAvailabilityModal}
					prefillRange={
						data.schedules.find(sched => sched.cleaner.id === agenda.lastActiveRange.ci)
							.timeRanges[agenda.lastActiveRange.ti]
					}
					cleanerId={agenda.lastActiveRange.ci}
				/>
			)}
			{agenda.showDeleteExtraAvailModal && (
				<ModalLayout
					title={t("delete_extra_avail_modal_title")}
					closeModal={toggleDeleteExtraAvailModal}
					buttons={deleteExtraAvailModalButtons}
					formDivClass="delete-extra-availability-modal"
				>
					{t("delete_extra_avail_prompt")}
				</ModalLayout>
			)}
			{agenda.showWorkshopHourModal && (
				<WorkshopHourModal
					closeModal={toggleWorkshopHourModal}
					submit={submitWorkshopHour}
					t={t}
				/>
			)}
			{agenda.showEditWorkshopHourModal && (
				<WorkshopHourModal
					data={tr}
					closeModal={toggleEditWorkshopHourModal}
					submit={submitEditWorkshopHour}
					t={t}
				/>
			)}
			{agenda.showOtherActivityModal && (
				<OtherActivityModal
					init={agenda.otherActivityInit}
					closeModal={toggleOtherActivityModal}
					submit={submitOtherActivity}
					t={t}
					blockCodes={true}
					workInterruption={cleaner.workInterruption}
					cleanerId={agenda.lastActiveRange.ci}
				/>
			)}
			{agenda.showEditOtherActivityModal && (
				<OtherActivityModal
					init={agenda.otherActivityInit}
					closeModal={toggleEditOtherActivityModal}
					submit={submitEditOtherActivity}
					otherActivity={getLastActiveTimerange()}
					t={t}
					blockCodes={true}
					workInterruption={cleaner.workInterruption}
				/>
			)}
			{agenda.showEditBookingModal && <NewBooking closeModal={toggleEditBookingModal} />}
			{/*agenda.showDeleteBookingModal && (
				<DeleteBookingModal closeModal={toggleDeleteBookingModal} />
			)*/}
			{agenda.showCancelBookingModal && (
				<CancelBookingModal closeModal={toggleCancelBookingModal} />
			)}
			{agenda.showDeleteOtherActivityModal && (
				<DeleteOtherActivityModal closeModal={toggleDeleteOtherActivityModal} />
			)}
			{agenda.showMissingLaundryOrderModal && (
				<MissingLaundryOrderModal
					closeModal={toggleAddMissingLaundryOrderModal}
					contractID={agenda.lastActiveRange.ci}
					week={agenda.lastActiveRange.tid.split("__").pop()}
				/>
			)}
		</div>
	)
}

export default AgendaTimerangeDropdown
