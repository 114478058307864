import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import moment from "moment"

import InputsChangeHandler from "./InputsChangeHandler"
import { PootsyButton, LabeledDateTime } from "./FunctionalDesign"
import { PootsyTextInput, PootsySelectInput, PootsyRadioInputs } from "./FunctionalInputs"

export default class ManualEntryForm extends Component {
	state = {
		date: moment(),
		secSocCode: "",
		comment: "",
		monthlyRecurrence: "no",
		showDeleteManualEntryModal: false,
		manualEntryStagedForDeletion: "",
	}
	handleStateChange = changes => {
		this.setState(changes)
	}
	addManualEntry = () => {
		let { addManualEntry, secSocEvents } = this.props
		let { date, secSocCode, comment, monthlyRecurrence } = this.state
		let manualEntry = {
			date,
			comment,
			social_secretary_code: secSocCode,
			recurring: monthlyRecurrence === "yes" ? true : false,
			extra_field_values: [],
		}
		let chosenTerm = secSocEvents.codes.find(code => code.id === secSocCode)
		chosenTerm.extra_fields.forEach(extra_field => {
			manualEntry.extra_field_values.push({
				field_name: extra_field.field_name,
				value: this.state[extra_field.field_name].toString().replace(",", "."),
			})
		})
		addManualEntry(manualEntry)
		this.setState({
			date: moment(),
			secSocCode: "",
			comment: "",
			monthlyRecurrence: "no",
		})
	}
	render() {
		let { t } = this.context
		let { secSocEvents } = this.props
		let { date, comment, secSocCode, monthlyRecurrence } = this.state
		let costsTerms = secSocEvents.codes.filter(entry => entry.timesheet_category === "expense")
		let chosenTerm = secSocEvents.codes.find(code => code.id === secSocCode)

		return (
			<InputsChangeHandler onChange={this.handleStateChange} customClass="new-cost-line">
				<LabeledDateTime
					label={t("date")}
					showTimePicker={false}
					datePickerProps={{
						value: date,
						name: "date",
						onChange: this.handleStateChange,
					}}
				/>
				<PootsySelectInput
					name="secSocCode"
					defaultText={t("sec_soc_code")}
					selected={secSocCode}
					options={costsTerms.map(term => ({
						label: term.id + " - " + t(term.label),
						value: term.id,
					}))}
				/>
				<PootsyTextInput name="comment" label={t("comment")} value={comment} />
				{chosenTerm?.extra_fields[0]?.field_name !== "groups_extra_date" && (
					<PootsyRadioInputs
						groupName="monthlyRecurrence"
						groupLabel={t("monthly_recurrence_input_title")}
						selected={monthlyRecurrence}
						options={[
							{ id: "yes", value: "yes", label: t("yes") },
							{ id: "no", value: "no", label: t("no") },
						]}
					/>
				)}
				{secSocCode &&
					chosenTerm &&
					chosenTerm.extra_fields.length > 0 &&
					chosenTerm.extra_fields.map(exfv => (
						<Fragment key={exfv.field_name}>
							{exfv.field_name === "groups_extra_date" && (
								<LabeledDateTime
									label={t(exfv.field_name)}
									key={exfv.field_name}
									showTimePicker={false}
									datePickerProps={{
										value: this.state[exfv.field_name] || moment(),
										name: exfv.field_name,
										onChange: this.handleStateChange,
										calendarStyle: { top: "-200%", right: "115%" },
									}}
								/>
							)}
							{(!exfv.field_type || exfv.field_type === "text") &&
								exfv.field_name !== "groups_extra_date" && (
									<PootsyTextInput
										label={t(exfv.field_name)}
										name={exfv.field_name}
										value={this.state[exfv.field_name]}
									/>
								)}
							{exfv.field_type === "select" && (
								<PootsySelectInput
									key={exfv.field_name}
									label={t(exfv.field_name)}
									name={exfv.field_name}
									selected={this.state[exfv.field_name]}
									options={exfv.options}
									defaultText={t(exfv.field_name)}
								/>
							)}
						</Fragment>
					))}
				<PootsyButton text={t("add")} onClick={this.addManualEntry} />
			</InputsChangeHandler>
		)
	}
}

ManualEntryForm.contextTypes = { t: PropTypes.func }
