if (process.env.REACT_APP_DEMO) {
	module.exports = {
		env: "demo",
		API_ROOT: "https://api.demo.pootsy.be/api/v1",
		API_BASE_URL: "https://api.demo.pootsy.be",
		UI_ROOT: "https://demo.pro.pootsy.be",
		PCW_ROOT: "https://demo.pootsy.be",
		sodexoConnectionConstants: {
			sodexoUrl: "https://tsdc-identity-acc.sodexo.be/connect/authorize",
			role: "soeasy",
			responseType: "code",
			clientId: "acc-provider-pootsy",
			display: "touch",
			redirectUri: "https://staging.pro.pootsy.be/sodexo/sodexo_login",
			scope: "openid service-vouchers.provider.profile sv.provider.access offline_access",
		},
		showSodexo: false,
		showWorkshop: true,
		showNotifications: true,
		showTasks: true,
		showDimona: true,
		showIndexation: true,
		activatePageViewLogging: false,
		showScanBtn: true,
		tabTitle: "Pootsy",
	}
} else if (process.env.REACT_APP_STAGING) {
	module.exports = {
		env: "staging",
		API_ROOT: "https://api.staging.pootsy.be/api/v1",
		API_BASE_URL: "https://api.staging.pootsy.be",
		UI_ROOT: "https://staging.pro.pootsy.be",
		PCW_ROOT: "https://staging.pootsy.be",
		sodexoConnectionConstants: {
			sodexoUrl: "https://tsdc-identity-acc.sodexo.be/connect/authorize",
			role: "soeasy",
			responseType: "code",
			clientId: "acc-provider-pootsy",
			display: "touch",
			redirectUri: "https://staging.pro.pootsy.be/sodexo/sodexo_login",
			scope: "openid service-vouchers.provider.profile sv.provider.access offline_access",
		},
		showSodexo: true,
		showWorkshop: true,
		showNotifications: true,
		showTasks: true,
		showDimona: true,
		showScanBtn: true,
		showIndexation: true,
		activatePageViewLogging: false,
		tabTitle: "Pootsy STAGING",
	}
} else if (process.env.REACT_APP_PRODUCTION) {
	module.exports = {
		env: "production",
		API_ROOT: "https://api.pootsy.be/api/v1",
		API_BASE_URL: "https://api.pootsy.be",
		UI_ROOT: "https://pro.pootsy.be",
		PCW_ROOT: "https://pootsy.be",
		sodexoConnectionConstants: {
			sodexoUrl: "https://identity-soeasy.sodexo.be/connect/authorize",
			role: "soeasy",
			responseType: "code",
			clientId: "prod-provider-pootsy",
			display: "touch",
			redirectUri: "https://pro.pootsy.be/sodexo/sodexo_login",
			scope: "openid service-vouchers.provider.profile sv.provider.access offline_access",
		},
		showSodexo: false,
		showWorkshop: true,
		showNotifications: true,
		showTasks: true,
		showDimona: true,
		showScanBtn: true,
		showIndexation: true,
		activatePageViewLogging: true,
		tabTitle: "Pootsy",
	}
} else {
	module.exports = {
		env: "development",
		API_ROOT: "http://localhost:3000/api/v1",
		API_BASE_URL: "http://localhost:3000",
		UI_ROOT: "http://localhost:3001",
		PCW_ROOT: "http://localhost:8000",
		sodexoConnectionConstants: {
			sodexoUrl: "https://tsdc-identity-acc.sodexo.be/connect/authorize",
			role: "soeasy",
			responseType: "code",
			clientId: "acc-provider-pootsy",
			display: "touch",
			redirectUri: "https://staging.pro.pootsy.be/sodexo/sodexo_login",
			scope: "openid service-vouchers.provider.profile sv.provider.access offline_access",
		},
		showSodexo: true,
		showWorkshop: true,
		showNotifications: true,
		showTasks: true,
		showDimona: true,
		showScanBtn: true,

		showIndexation: true,
		activatePageViewLogging: false,
		tabTitle: "Pootsy DEV",
	}
}
