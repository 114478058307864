export default [
	{ value: "ABW", label: "aruba" },
	{ value: "AFG", label: "afghanistan" },
	{ value: "AGO", label: "angola" },
	{ value: "AIA", label: "anguilla" },
	{ value: "ALA", label: "aland_islands" },
	{ value: "ALB", label: "albania" },
	{ value: "AND", label: "andorra" },
	{ value: "ARE", label: "anited_arab_emirates" },
	{ value: "ARG", label: "argentina" },
	{ value: "ARM", label: "armenia" },
	{ value: "ASM", label: "american_samoa" },
	{ value: "ATA", label: "antarctica" },
	{ value: "ATF", label: "french_southern_territories" },
	{ value: "ATG", label: "antigua_and_barbuda" },
	{ value: "AUS", label: "australia" },
	{ value: "AUT", label: "austria" },
	{ value: "AZE", label: "azerbaijan" },
	{ value: "BDI", label: "burundi" },
	{ value: "BEL", label: "belgium" },
	{ value: "BEN", label: "benin" },
	{ value: "BES", label: "bonaire-sint_eustatius_and_saba" },
	{ value: "BFA", label: "burkina_faso" },
	{ value: "BGD", label: "bangladesh" },
	{ value: "BGR", label: "bulgaria" },
	{ value: "BHR", label: "bahrain" },
	{ value: "BHS", label: "bahamas" },
	{ value: "BIH", label: "bosnia_and_herzegovina" },
	{ value: "BLM", label: "saint_barthelemy" },
	{ value: "BLR", label: "belarus" },
	{ value: "BLZ", label: "belize" },
	{ value: "BMU", label: "bermuda" },
	{ value: "BOL", label: "bolivia-plurinational_state_of" },
	{ value: "BRA", label: "brazil" },
	{ value: "BRB", label: "barbados" },
	{ value: "BRN", label: "brunei_darussalam" },
	{ value: "BTN", label: "bhutan" },
	{ value: "BVT", label: "bouvet_island" },
	{ value: "BWA", label: "botswana" },
	{ value: "CAF", label: "central_african_republic" },
	{ value: "CAN", label: "canada" },
	{ value: "CCK", label: "cocos_keeling_islands" },
	{ value: "CHE", label: "switzerland" },
	{ value: "CHL", label: "chile" },
	{ value: "CHN", label: "china" },
	{ value: "CIV", label: "cote_d'ivoire" },
	{ value: "CMR", label: "cameroon" },
	{ value: "COD", label: "congo_the_democratic_republic_of_the" },
	{ value: "COG", label: "congo" },
	{ value: "COK", label: "cook_islands" },
	{ value: "COL", label: "colombia" },
	{ value: "COM", label: "comoros" },
	{ value: "CPV", label: "cabo_verde" },
	{ value: "CRI", label: "costa_rica" },
	{ value: "CUB", label: "cuba" },
	{ value: "CUW", label: "curacao" },
	{ value: "CXR", label: "christmas_island" },
	{ value: "CYM", label: "cayman_islands" },
	{ value: "CYP", label: "cyprus" },
	{ value: "CZE", label: "czechia" },
	{ value: "DEU", label: "germany" },
	{ value: "DJI", label: "djibouti" },
	{ value: "DMA", label: "dominica" },
	{ value: "DNK", label: "denmark" },
	{ value: "DOM", label: "dominican_republic" },
	{ value: "DZA", label: "algeria" },
	{ value: "ECU", label: "ecuador" },
	{ value: "EGY", label: "egypt" },
	{ value: "ERI", label: "eritrea" },
	{ value: "ESH", label: "western_sahara" },
	{ value: "ESP", label: "spain" },
	{ value: "EST", label: "estonia" },
	{ value: "ETH", label: "ethiopia" },
	{ value: "FIN", label: "finland" },
	{ value: "FJI", label: "fiji" },
	{ value: "FLK", label: "falkland_islands_malvinas" },
	{ value: "FRA", label: "france" },
	{ value: "FRO", label: "faroe_islands" },
	{ value: "FSM", label: "micronesia_federated_states_of" },
	{ value: "GAB", label: "gabon" },
	{ value: "GBR", label: "united_kingdom" },
	{ value: "GEO", label: "gorgia" },
	{ value: "GGY", label: "guernsey" },
	{ value: "GHA", label: "ghana" },
	{ value: "GIB", label: "gibraltar" },
	{ value: "GIN", label: "guinea" },
	{ value: "GLP", label: "guadeloupe" },
	{ value: "GMB", label: "gambia" },
	{ value: "GNB", label: "guinea-bissau" },
	{ value: "GNQ", label: "equatorial_guinea" },
	{ value: "GRC", label: "greece" },
	{ value: "GRD", label: "grenada" },
	{ value: "GRL", label: "greenland" },
	{ value: "GTM", label: "guatemala" },
	{ value: "GUF", label: "french_guiana" },
	{ value: "GUM", label: "guam" },
	{ value: "GUY", label: "guyana" },
	{ value: "HKG", label: "hong_kong" },
	{ value: "HMD", label: "heard_island_and_mcdonald_islands" },
	{ value: "HND", label: "honduras" },
	{ value: "HRV", label: "croatia" },
	{ value: "HTI", label: "haiti" },
	{ value: "HUN", label: "hungary" },
	{ value: "IDN", label: "indonesia" },
	{ value: "IMN", label: "isle_of_man" },
	{ value: "IND", label: "india" },
	{ value: "IOT", label: "british_indian_ocean_territory" },
	{ value: "IRL", label: "ireland" },
	{ value: "IRN", label: "iran_islamic_republic_of" },
	{ value: "IRQ", label: "iraq" },
	{ value: "ISL", label: "iceland" },
	{ value: "ISR", label: "israel" },
	{ value: "ITA", label: "italy" },
	{ value: "JAM", label: "jamaica" },
	{ value: "JEY", label: "jersey" },
	{ value: "JOR", label: "jordan" },
	{ value: "JPN", label: "japan" },
	{ value: "KAZ", label: "kazakhstan" },
	{ value: "KEN", label: "kenya" },
	{ value: "KGZ", label: "kyrgyzstan" },
	{ value: "KHM", label: "cambodia" },
	{ value: "KIR", label: "kiribati" },
	{ value: "KNA", label: "saint_kitts_and_nevis" },
	{ value: "KOR", label: "korea_republic_of" },
	{ value: "KWT", label: "kuwait" },
	{ value: "LAO", label: "lao_people_s_democratic_republic" },
	{ value: "LBN", label: "lebanon" },
	{ value: "LBR", label: "liberia" },
	{ value: "LBY", label: "libya" },
	{ value: "LCA", label: "saint_lucia" },
	{ value: "LIE", label: "liechtenstein" },
	{ value: "LKA", label: "sri_lanka" },
	{ value: "LSO", label: "lesotho" },
	{ value: "LTU", label: "lithuania" },
	{ value: "LUX", label: "luxembourg" },
	{ value: "LVA", label: "latvia" },
	{ value: "MAC", label: "macao" },
	{ value: "MAF", label: "saint_martin_french_part" },
	{ value: "MAR", label: "morocco" },
	{ value: "MCO", label: "monaco" },
	{ value: "MDA", label: "moldova_republic_of" },
	{ value: "MDG", label: "madagascar" },
	{ value: "MDV", label: "maldives" },
	{ value: "MEX", label: "mexico" },
	{ value: "MHL", label: "marshall_islands" },
	{ value: "MKD", label: "macedonia_the_former_yugoslav_republic_of" },
	{ value: "MLI", label: "mali" },
	{ value: "MLT", label: "malta" },
	{ value: "MMR", label: "myanmar" },
	{ value: "MNE", label: "montenegro" },
	{ value: "MNG", label: "mongolia" },
	{ value: "MNP", label: "northern_mariana_islands" },
	{ value: "MOZ", label: "mozambique" },
	{ value: "MRT", label: "mauritania" },
	{ value: "MSR", label: "montserrat" },
	{ value: "MTQ", label: "martinique" },
	{ value: "MUS", label: "mauritius" },
	{ value: "MWI", label: "malawi" },
	{ value: "MYS", label: "malaysia" },
	{ value: "MYT", label: "mayotte" },
	{ value: "NAM", label: "namibia" },
	{ value: "NCL", label: "new_caledonia" },
	{ value: "NER", label: "niger" },
	{ value: "NFK", label: "norfolk_island" },
	{ value: "NGA", label: "nigeria" },
	{ value: "NIC", label: "nicaragua" },
	{ value: "NIU", label: "niue" },
	{ value: "NLD", label: "netherlands" },
	{ value: "NOR", label: "norway" },
	{ value: "NPL", label: "nepal" },
	{ value: "NRU", label: "nauru" },
	{ value: "NZL", label: "new_zealand" },
	{ value: "OMN", label: "oman" },
	{ value: "PAK", label: "pakistan" },
	{ value: "PAN", label: "panama" },
	{ value: "PCN", label: "pitcairn" },
	{ value: "PER", label: "peru" },
	{ value: "PHL", label: "philippines" },
	{ value: "PLW", label: "palau" },
	{ value: "PNG", label: "papua_new_guinea" },
	{ value: "POL", label: "poland" },
	{ value: "PRI", label: "puerto_rico" },
	{ value: "PRK", label: "korea_democratic_people_s_republic_of" },
	{ value: "PRT", label: "portugal" },
	{ value: "PRY", label: "paraguay" },
	{ value: "PSE", label: "palestine_state_of" },
	{ value: "PYF", label: "french_polynesia" },
	{ value: "QAT", label: "qatar" },
	{ value: "REU", label: "reunion" },
	{ value: "ROU", label: "romania" },
	{ value: "RUS", label: "russian_federation" },
	{ value: "RWA", label: "rwanda" },
	{ value: "SAU", label: "saudi_arabia" },
	{ value: "SDN", label: "sudan" },
	{ value: "SEN", label: "senegal" },
	{ value: "SGP", label: "singapore" },
	{ value: "SGS", label: "south_georgia_and_the_south_sandwich_islands" },
	{ value: "SHN", label: "saint_helena_ascension_and_tristan_da_cunha" },
	{ value: "SJM", label: "svalbard_and_jan_mayen" },
	{ value: "SLB", label: "solomon_islands" },
	{ value: "SLE", label: "sierra_leone" },
	{ value: "SLV", label: "el_salvador" },
	{ value: "SMR", label: "san_marino" },
	{ value: "SOM", label: "somalia" },
	{ value: "SPM", label: "saint_pierre_and_miquelon" },
	{ value: "SRB", label: "serbia" },
	{ value: "SSD", label: "south_sudan" },
	{ value: "STP", label: "sao_tome_and_principe" },
	{ value: "SUR", label: "suriname" },
	{ value: "SVK", label: "slovakia" },
	{ value: "SVN", label: "slovenia" },
	{ value: "SWE", label: "sweden" },
	{ value: "SWZ", label: "eswatini" },
	{ value: "SXM", label: "sint_maarten_dutch_part" },
	{ value: "SYC", label: "seychelles" },
	{ value: "SYR", label: "syrian_arab_republic" },
	{ value: "TCA", label: "turks_and_caicos_islands" },
	{ value: "TCD", label: "chad" },
	{ value: "TGO", label: "togo" },
	{ value: "THA", label: "thailand" },
	{ value: "TJK", label: "tajikistan" },
	{ value: "TKL", label: "tokelau" },
	{ value: "TKM", label: "turkmenistan" },
	{ value: "TLS", label: "timor-leste" },
	{ value: "TON", label: "tonga" },
	{ value: "TTO", label: "trinidad_and_tobago" },
	{ value: "TUN", label: "tunisia" },
	{ value: "TUR", label: "turkey" },
	{ value: "TUV", label: "tuvalu" },
	{ value: "TWN", label: "taiwan_province_of_china" },
	{ value: "TZA", label: "tanzania_united_republic_of" },
	{ value: "UGA", label: "uganda" },
	{ value: "UKR", label: "ukraine" },
	{ value: "UMI", label: "united_states_minor_outlying_islands" },
	{ value: "URY", label: "uruguay" },
	{ value: "USA", label: "united_states_of_america" },
	{ value: "UZB", label: "uzbekistan" },
	{ value: "VAT", label: "holy_see" },
	{ value: "VCT", label: "saint_vincent_and_the_grenadines" },
	{ value: "VEN", label: "venezuela_bolivarian_republic_of" },
	{ value: "VGB", label: "virgin_islands_british" },
	{ value: "VIR", label: "virgin_islands_us" },
	{ value: "VNM", label: "viet_nam" },
	{ value: "VUT", label: "vanuatu" },
	{ value: "WLF", label: "wallis_and_futuna" },
	{ value: "WSM", label: "samoa" },
	{ value: "YEM", label: "yemen" },
	{ value: "ZAF", label: "south_africa" },
	{ value: "ZMB", label: "zambia" },
	{ value: "ZWE", label: "zimbabwe" },
]
