const availValidation = (totalAvailsHours, schedulePeriod, errors) => {
	let workMinutes = converDurationToMin(schedulePeriod.maxWorkHours)
	let extraWorkMinutes = converDurationToMin(schedulePeriod.maxExtraWorkHours)
	if (totalAvailsHours < workMinutes + extraWorkMinutes) {
		errors.push("sched_error_avails_lower_than_work_hours_plus_extra_hours")
	}
}

const converDurationToMin = duration => {
	let formatedMaxHours = duration.toString()
	let separator = formatedMaxHours.includes("h") ? "h" : "u"
	return formatedMaxHours.includes(separator)
		? parseFloat(formatedMaxHours.split(separator)[0] * 60) +
				parseFloat(formatedMaxHours.split(separator)[1])
		: formatedMaxHours * 60
}

export const workerScheduleValidator = contract => {
	let errors = []
	let schedErrors = []

	if (contract.contractVariability === "variable") {
		let totalAvailsHours = 0
		contract.schedulePeriods.forEach((schedulePeriod, index) => {
			contract.availabilities
				.filter(entry => schedulePeriod.managedAvails.includes(entry.id))
				.forEach(entry => {
					totalAvailsHours += Math.round(entry.end.diff(entry.start, "seconds") / 60)
				})
			availValidation(totalAvailsHours, schedulePeriod, errors)
		})

		return { errors, schedErrors }
	}
	if (contract.contractVariability === "variable_variable") {
		let totalAvailsMinutes = 0
		contract.schedulePeriods.forEach((schedulePeriod, index) => {
			contract.availabilities
				.filter(entry => schedulePeriod.managedAvails.includes(entry.id))
				.forEach(entry => {
					totalAvailsMinutes += entry.duration
				})
			if (totalAvailsMinutes < (contract.avgHoursPerWeekForRefPeriod || 0)) {
				errors.push("sched_error_avails_lower_than_work_hours_plus_extra_hours")
			}
		})

		return { errors, schedErrors }
	}
	contract.schedulePeriods.forEach((schedulePeriod, index) => {
		let errors = []
		let warnings = []
		let totalAvailsHours = 0
		let totalAvailsPerDay = { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 }

		let totalTheoreticalHours = contract.theoreticalHours
			.filter(entry => schedulePeriod.managedTheoHours.includes(entry.id))
			.reduce(
				(acc, entry) => acc + Math.round(entry.end.diff(entry.start, "seconds") / 60),
				0
			)
		contract.availabilities
			.filter(entry => schedulePeriod.managedAvails.includes(entry.id))
			.forEach(entry => {
				totalAvailsHours += Math.round(entry.end.diff(entry.start, "seconds") / 60)
				totalAvailsPerDay[entry.startDate.weekday() - 1] += entry.duration
			})
		let convertedMaxWorkHours = converDurationToMin(schedulePeriod.maxWorkHours)
		if (totalTheoreticalHours !== convertedMaxWorkHours) {
			errors.push("sched_error_theo_hours_different_than_work_hours")
		}
		availValidation(totalAvailsHours, schedulePeriod, errors)

		if (schedulePeriod.hasMaxHoursPerDay) {
			schedulePeriod.daysMaxHours.forEach((entry, index) => {
				if (entry > totalAvailsPerDay[index]) {
					errors.push("sched_error_day_hours_higher_than_availabilities")
				}
			})
		}
		if (errors.length > 0 || warnings.length > 0) {
			schedErrors.push({ schedIndex: index, errors, warnings })
		}
	})

	let averageSchedulesMaxWorkHours =
		contract.schedulePeriods.reduce(
			(acc, entry) => acc + converDurationToMin(entry.maxWorkHours),
			0
		) / contract.schedulePeriods.length
	if (averageSchedulesMaxWorkHours !== (converDurationToMin(contract.hoursPerWeek) || 0)) {
		errors.push("sched_error_work_hours_average_different_than_contract")
	}

	return { errors, schedErrors }
}
