import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"

import * as actions from "../actions"

import { OptionBox, LabeledDateTime } from "./FunctionalDesign"
import DateFilterer from "./DateFilterer"
import BackEndFilters from "./BackEndFilters"

class WorkshopOrdersFilters extends Component {
	state = {
		queuedCall: "",
	}
	componentDidMount = async () => {
		let { dispatch, customQueries } = this.props
		dispatch(actions.fetchWorkshopOrdersFilters())
		await dispatch(
			actions.addQueries({
				...customQueries,
				pageQuery: {
					urlName: "page",
					queryName: "page",
					mustBeSet: true,
					defaultValue: "1",
				},
			})
		)
	}
	fetchWorkshopOrders = () => {
		this.props.dispatch(
			actions.fetchWorkshopOrders({
				computeTotalVouchers: this.props.computeTotalVouchers,
			})
		)
	}
	componentWillUnmount = () => {
		this.props.dispatch(
			actions.workshopOrdersFiltersStateChange({
				workshopOrdersNeedRefetch: true,
				page: 1,
			})
		)
		this.props.dispatch(actions.resetQuery())
	}
	UNSAFE_componentWillReceiveProps = async newProps => {
		let {
			component: { search: oldSearch, page: oldPage, fetched: oldFetched },
			computeTotalVouchers,
		} = this.props
		let {
			workshopOrdersFilters,
			component: {
				search: newSearch,
				page: newPage,
				fetched: newFetched,
				workshopOrdersNeedRefetch,
			},
		} = newProps
		if (oldSearch !== newSearch && (newSearch.length === 0 || newSearch.length > 1)) {
			this.scheduleApiCall(newSearch)
		}
		if (oldPage !== newPage) {
			this.updateQuery({ name: "pageQuery", value: newPage })
		}
		if ((!oldFetched && newFetched) || workshopOrdersNeedRefetch) {
			let newQueries = workshopOrdersFilters.reduce((acc, filter) => {
				acc[filter.name] = {
					urlName: filter.name,
					queryName: filter.url_param,
					mustBeSet: false,
				}
				return acc
			}, {})
			this.handleChanges({ workshopOrdersNeedRefetch: false })
			await this.props.dispatch(actions.addQueries(newQueries))
			this.props.dispatch(
				actions.fetchWorkshopOrders({ computeTotalVouchers: computeTotalVouchers })
			)
		}
	}
	scheduleApiCall = value => {
		let { queuedCall } = this.state
		clearTimeout(queuedCall)
		let futureCall = setTimeout(
			() => this.updateQuery({ name: "searchQuery", value: value }),
			800
		)
		this.setState({ queuedCall: futureCall })
	}
	handleChanges = changes => {
		this.props.dispatch(actions.workshopOrdersFiltersStateChange(changes))
	}
	updateQuery = async changes => {
		let { dispatch } = this.props
		if (changes.name !== "pageQuery") {
			if (Array.isArray(changes)) {
				changes = [...changes, { name: "pageQuery", value: 1 }]
			} else {
				changes = [changes, { name: "pageQuery", value: 1 }]
			}
		}
		await dispatch(actions.updateQuery(changes))
		this.fetchWorkshopOrders()
	}
	handleDateFilterChange = changes => {
		let key = Object.keys(changes)[0]
		let newQuery = { name: key }
		if (!changes[key].isValid()) {
			newQuery.value = ""
		} else {
			newQuery.value = changes[key].format("YYYY-MM-DD")
		}
		this.updateQuery(newQuery)
	}
	handleExecutionDateFiltersChange = dateFilters => {
		let query = [
			{ name: "fromDateQuery", value: dateFilters.from.format("YYYY-MM-DD") },
			{ name: "toDateQuery", value: dateFilters.to.format("YYYY-MM-DD") },
		]
		this.updateQuery(query)
	}
	handleSelectFilterChange = e => {
		let { name, value } = e.target
		this.updateQuery({ name, value })
	}
	handleUpdateFilters = changes => {
		if (changes.name === "cleanerQuery" || changes.name === "customerQuery") {
			changes = [changes, { name: "pageQuery", value: 1 }]
		}
		this.updateQuery(changes)
	}
	handleShortcutNext = () => {
		this.updateQuery([
			{ name: "fromDateQuery", value: moment().format("YYYY-MM-DD") },
			{
				name: "toDateQuery",
				value: moment()
					.add(2, "month")
					.format("YYYY-MM-DD"),
			},
		])
	}
	handleShortcutPrevious = () => {
		this.updateQuery([
			{
				name: "fromDateQuery",
				value: moment()
					.subtract(2, "month")
					.format("YYYY-MM-DD"),
			},
			{
				name: "toDateQuery",
				value: moment()
					.subtract(1, "day")
					.format("YYYY-MM-DD"),
			},
		])
	}
	render() {
		let { t } = this.context
		let {
			workshopOrdersFilters,
			filteredFilters,
			hideFromToFilter,
			hideAllFilters,
			hideExecutionDateFilter,
			currentQuery: {
				urlQuery,
				urlQuery: { from, to, executionDate, dropOffDate, pickUpDate },
			},
		} = this.props
		if (hideAllFilters) {
			return null
		}
		let mFrom = moment(from)
		let mTo = moment(to)
		let mExecutionDate = executionDate ? moment(executionDate) : undefined
		let mDropOffDate = dropOffDate ? moment(dropOffDate) : undefined
		let mPickUpDate = pickUpDate ? moment(pickUpDate) : undefined
		// enrich filters with their value
		workshopOrdersFilters.forEach(entry => (entry.value = urlQuery[entry.name]))
		let today = moment()
		let onlyFutureOrders = mFrom.isSameOrAfter(today, "day") && mTo.isAfter(today, "day")
		let onlyPastOrders = mFrom.isBefore(today, "day") && mTo.isBefore(today, "day")
		if (hideAllFilters) {
			return null
		}
		return (
			<OptionBox boxTitle={t("filters")} customClass="WorkshopOrdersFilters">
				{!hideFromToFilter && (
					<Fragment>
						<div
							className={"type-query-option" + (onlyFutureOrders ? " active" : "")}
							data-type="next"
							onClick={this.handleShortcutNext}
						>
							{t("today_and_future_filter")}
						</div>
						<div
							className={"type-query-option" + (onlyPastOrders ? " active" : "")}
							data-type="previous"
							onClick={this.handleShortcutPrevious}
						>
							{t("past_filter")}
						</div>
						<DateFilterer
							onChange={this.handleExecutionDateFiltersChange}
							from={mFrom}
							to={mTo}
						/>
					</Fragment>
				)}
				{!hideExecutionDateFilter && (
					<LabeledDateTime
						label={t("order_execution_date")}
						showTimePicker={false}
						datePickerProps={{
							name: "executionDateQuery",
							value: mExecutionDate,
							onChange: this.handleDateFilterChange,
							allowUnset: true,
							calendarStyle: { top: "120%", left: -45, transform: "scale(.8, .8)" },
						}}
					/>
				)}
				<LabeledDateTime
					label={t("order_drop_off_date")}
					showTimePicker={false}
					datePickerProps={{
						name: "dropOffDateQuery",
						value: mDropOffDate,
						onChange: this.handleDateFilterChange,
						allowUnset: true,
						calendarStyle: { top: "120%", left: -45, transform: "scale(.8, .8)" },
					}}
				/>
				<LabeledDateTime
					label={t("order_pick_up_date")}
					showTimePicker={false}
					datePickerProps={{
						name: "pickUpDateQuery",
						value: mPickUpDate,
						onChange: this.handleDateFilterChange,
						allowUnset: true,
						calendarStyle: { top: "120%", left: -45, transform: "scale(.8, .8)" },
					}}
				/>
				<BackEndFilters
					filters={workshopOrdersFilters.filter(
						entry => !filteredFilters.includes(entry.name)
					)}
					filterInputChange={this.handleUpdateFilters}
					selectInputChange={this.handleSelectFilterChange}
				/>
			</OptionBox>
		)
	}
}

WorkshopOrdersFilters.defaultProps = {
	customQueries: [],
	filteredFilters: [],
	hideAllFilters: false,
	hideFromToFilter: false,
	hideExecutionDateFilter: true,
	computeTotalVouchers: undefined,
}
WorkshopOrdersFilters.propTypes = {
	match: PropTypes.shape({
		path: PropTypes.string,
		url: PropTypes.string,
		isExact: PropTypes.bool,
		params: PropTypes.object,
	}).isRequired,
	location: PropTypes.shape({
		pathname: PropTypes.string,
		search: PropTypes.string,
		hash: PropTypes.string,
		state: PropTypes.object,
		key: PropTypes.string,
	}).isRequired,
	history: PropTypes.shape({
		length: PropTypes.number,
		action: PropTypes.string,
		block: PropTypes.func,
		createHred: PropTypes.func,
		go: PropTypes.func,
		goBack: PropTypes.func,
		goForward: PropTypes.func,
		listen: PropTypes.func,
		location: PropTypes.object,
		push: PropTypes.func,
		replace: PropTypes.func,
	}).isRequired,
}
WorkshopOrdersFilters.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	workshopOrdersFilters: state.redData.workshopOrdersFilters,
	component: state.redComponents.workshopOrdersFiltersComponent,
	currentQuery: state.currentURLQuery,
})

export default connect(mapStateToProps)(WorkshopOrdersFilters)
