import React from "react"
import { Route, Link } from "react-router-dom"

export const NavLink = ({ to, exact, className = "", newTab = false, children }) => (
	<Route
		path={to}
		exact={exact}
		children={({ match }) => (
			<div className={"navlink " + className + (match ? " active" : "")}>
				<Link to={to} target={newTab ? "_blank" : ""}>
					{children}
				</Link>
			</div>
		)}
	/>
)
