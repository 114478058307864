import React from "react"

const CheckMark = ({ doubleMark = true, blueFill = false, customFill = undefined }) => {
	let fill = blueFill ? "rgb(1, 119, 249)" : customFill ? customFill : "black"
	return (
		<svg className="CheckMark" viewBox="0 0 650 650">
			<path
				stroke={fill}
				fill={fill}
				d="M477.29 105.46l.52.04.52.07.51.09.52.1.51.13.5.15.5.16.5.19.49.21.48.23.48.24.47.27.46.29.45.31.45.34.43.35.42.37.4.38.38.4.36.41.35.42.32.43.31.44.29.45.26.46.25.47.23.47.21.49.19.49.17.49.16.5.13.51.11.51.1.51.07.52.05.52.04.52.01.52v.52l-.03.52-.04.53-.07.51-.09.52-.1.52-.13.51-.14.51-.17.5-.19.5-.2.49-.23.49-.25.48-.27.47-.29.46-.3.46-.34.44-.35.44-304.54 362.95-.37.42-.38.4-.39.38-.41.37-.42.34-.43.33-.43.3-.45.29-.42.24-.05.04-.41.23-.42.22-.42.2-.43.18-.43.17-.44.15-.45.13-.44.11-.45.1-.46.08-.45.06-.46.04-.46.03-.46.01-.18-.01-.12.01-.52-.01-.52-.03-.52-.04-.52-.07-.51-.08-.51-.11-.51-.13-.51-.14-.5-.17-.5-.19-.49-.2-.48-.23-.48-.25-.47-.27-.46-.29-.45-.31-.44-.33-.44-.36-.42-.37-.4-.38-.38-.4-.36-.41-.35-.42-.32-.43-.31-.44-.28-.45-.27-.46-.25-.47-.05-.11L17.02 346.06l-.34-.35-.32-.36-.31-.38-.29-.38-.27-.39-.25-.4-.24-.41-.22-.41-.2-.42-.18-.43-.17-.43-.15-.44-.13-.44-.11-.45-.1-.44-.08-.45-.06-.46-.04-.45-.03-.46-.01-.46.01-.45.03-.46.04-.45.06-.46.08-.45.1-.45.11-.44.13-.44.15-.44.17-.43.18-.43.2-.42.22-.41.24-.41.25-.4.27-.39.29-.39.31-.37.32-.36.34-.36.36-.34.36-.32.38-.3.38-.29.4-.27.4-.25.41-.24.42-.22.42-.2.43-.18.43-.17.44-.15.44-.13.45-.11.45-.1.45-.07.46-.07.46-.04.45-.03h.92l.46.03.46.04.45.07.46.07.45.1.44.11.45.13.44.15.43.17.43.18.42.2.42.22.41.24.4.25.39.27.39.29.37.3.37.32.36.34 135.35 134.65 296.75-353.65.36-.42.38-.4.4-.38.4-.36.42-.35.43-.32.44-.31.45-.29.45-.26.47-.25.47-.23.48-.21.49-.19.49-.18.5-.15.51-.13.5-.11.52-.1.51-.07.52-.05.51-.04.52-.01h.52l.52.03z"
			/>
			{doubleMark && (
				<path
					stroke={fill}
					fill={fill}
					d="M610.76 105.46l.52.04.52.07.51.09.52.1.51.13.5.15.5.16.5.19.49.21.48.23.48.24.47.27.46.29.45.31.44.34.44.35.42.37.4.38.38.4.36.41.35.42.32.43.31.44.28.45.27.46.25.47.23.47.21.49.19.49.17.49.16.5.13.51.11.51.09.51.08.52.05.52.04.52.01.52-.01.52-.02.52-.05.53-.06.51-.09.52-.1.52-.13.51-.14.51-.17.5-.19.5-.2.49-.23.49-.25.48-.27.47-.29.46-.31.46-.33.44-.35.44-304.54 362.95-.37.42-.38.4-.4.38-.4.37-.42.34-.43.33-.44.3-.44.29-.46.27-.47.25-.47.23-.48.21-.49.19-.49.17-.5.15-.51.13-.5.12-.51.09-.52.07-.51.06-.52.03-.52.02-.52-.01-.52-.03-.52-.04-.52-.07-.51-.08-.21-.05-.02.02-.16.05-.15.05-.16.04-.15.04-.16.02-.16.03-.16.01-.16.01-.16.01-.16-.01-.16-.01-.16-.01-.16-.03-.16-.02-.16-.04-.15-.04-.16-.05-.15-.05-.15-.07-.15-.06-.15-.08-.14-.08-.14-.08-.14-.1-.13-.1-.14-.1-.12-.11-.13-.12-48.11-47.86-.12-.13-.11-.12-.11-.13-.1-.14-.09-.14-.09-.14-.08-.14-.07-.14-.07-.15-.06-.15-.05-.15-.05-.16-.04-.15-.04-.16-.03-.16-.02-.16-.02-.15-.01-.16v-.32l.01-.16.02-.16.02-.16.03-.16.04-.15.04-.16.05-.15.05-.15.06-.15.07-.15.07-.15.08-.14.09-.14.09-.14.1-.13.11-.13.11-.13.12-.13 11.29-11.23.13-.12.13-.11.13-.11.13-.1.14-.09.14-.09.15-.08.14-.07.15-.07.15-.06.16-.05.15-.05.16-.04.15-.04.16-.03.16-.02.16-.02.16-.01h.32l.16.01.16.02.16.02.16.03.16.04.15.04.16.05.15.05.15.06.15.07.14.07.15.08.14.09.14.09.13.1.13.11.13.11.13.12 35.04 34.86 297.3-354.33.37-.42.38-.4.4-.38.4-.36.42-.35.43-.32.44-.31.44-.29.46-.26.47-.25.47-.23.48-.21.49-.19.49-.18.5-.15.5-.13.51-.11.51-.1.52-.07.51-.05.52-.04.52-.01h.52l.52.03z"
				/>
			)}
		</svg>
	)
}

export default CheckMark
