import React, { Component } from "react"
import PropTypes from "prop-types"

import { PootsyTextInput, PootsySelectInput } from "./FunctionalInputs"

class PootsyAddressBlockInputs extends Component {
	state = {
		queuedCall: "",
		gSuggestions: [],
		dataIsFromGMaps: false,
		showSpinner: false,
	}
	componentDidMount = () => {
		this.suggestionsWrapper = React.createRef()
	}
	UNSAFE_componentWillReceiveProps = () => {
		this.setState({ gSuggestions: [] })
	}
	componentWillUnmount = () => {
		document.removeEventListener("click", this.handleClickOutside)
	}
	scheduleApiCall = value => {
		let { queuedCall } = this.state
		clearTimeout(queuedCall)
		let futureCall
		if (value.length < 3) {
			this.setState({ showSpinner: false })
			return
		} else {
			this.setState({ showSpinner: true })
			futureCall = setTimeout(() => this.fetchGoogleSuggestions(value), 800)
		}
		this.setState({ queuedCall: futureCall })
	}
	fetchGoogleSuggestions = value => {
		let autocompleteService = new window.google.maps.places.AutocompleteService()
		autocompleteService.getPlacePredictions(
			{ input: value, componentRestrictions: { country: "be" } },
			(suggestions, status) => {
				if (status === "OK") {
					this.setState({ gSuggestions: suggestions || [] })
					document.addEventListener("click", this.handleClickOutside)
				}
				this.setState({ showSpinner: false })
			}
		)
	}
	handleClickOutside = e => {
		e.stopPropagation()
		let node = this.suggestionsWrapper.current
		let isClickInside = node && node.contains(e.target)
		if (!isClickInside) {
			this.setState({ gSuggestions: [] })
			document.removeEventListener("click", this.handleClickOutside)
		}
	}
	handleSuggestionClick = e => {
		if (e.key !== undefined && e.key !== "Enter") {
			return
		}
		let { placeid } = e.target.dataset
		let geocoder = new window.google.maps.Geocoder()
		geocoder.geocode({ placeId: placeid, language: "be-fr" }, (place, status) => {
			if (status === "OK") {
				document.removeEventListener("click", this.handleClickOutside)
				this.normalizePlace(place)
			}
		})
	}
	normalizePlace = place => {
		let { setValues } = this.props
		let addressComponents = place[0].address_components
		let gMapsTypesMapper = {
			street_number: "streetNumber",
			route: "street",
			postal_code: "zip",
			locality: "city",
		}
		let newValues = {}
		addressComponents.forEach(ac => {
			ac.types.forEach(type => {
				if (Object.keys(gMapsTypesMapper).includes(type)) {
					newValues[gMapsTypesMapper[type]] = ac.long_name
				}
			})
		})
		this.setState({ dataIsFromGMaps: true })
		setValues(newValues)
	}
	handleChanges = e => {
		let { handleChanges, setValues } = this.props
		let { dataIsFromGMaps } = this.state
		let { name, value } = e.target
		if (dataIsFromGMaps && name !== "streetNumber" && name !== "box") {
			setValues({ streetNumber: "", box: "", city: "", zip: "", street: "" })
			handleChanges({ streetNumber: "", box: "", city: "", zip: "", street: "" })
			this.setState({ dataIsFromGMaps: false })
		}
		if (value === "") {
			this.setState({ gSuggestions: [] })
		}
		if (name === "street" && value !== "") {
			this.scheduleApiCall(value)
		}
		handleChanges({ [name]: value })
	}
	render() {
		let { t } = this.context
		let { values, showRequired = {} } = this.props
		let { gSuggestions, showSpinner } = this.state
		return (
			<div className="pootsy-address-block">
				<PootsyTextInput
					name="street"
					label={t("street")}
					customClass="address-block street"
					onChange={this.handleChanges}
					showRequired={showRequired.street}
					value={values.street}
				/>
				{showSpinner && (
					<div className="address-spinner">
						<div className="spinning-arc" />
					</div>
				)}
				{gSuggestions.length !== 0 && (
					<div className="g-suggestions" ref={this.suggestionsWrapper}>
						{gSuggestions.map((suggestion, index) => (
							<div
								key={suggestion.place_id}
								tabIndex={0}
								className="g-suggestion"
								onClick={this.handleSuggestionClick}
								data-placeid={suggestion.place_id}
								onKeyPress={this.handleSuggestionClick}
							>
								{suggestion.description}
							</div>
						))}
					</div>
				)}
				<PootsyTextInput
					name="streetNumber"
					label={t("nb")}
					customClass="address-block street-number"
					onChange={this.handleChanges}
					showRequired={showRequired.streetNumber}
					value={values.streetNumber}
				/>
				<PootsyTextInput
					name="box"
					label={t("box")}
					customClass="address-block box"
					onChange={this.handleChanges}
					value={values.box}
				/>
				<PootsyTextInput
					name="zip"
					type={this.props.showCountry ? "text" : "number"}
					label={t("postal_code")}
					customClass="address-block zip"
					onChange={this.handleChanges}
					showRequired={showRequired.zip}
					value={values.zip}
				/>
				<PootsyTextInput
					name="city"
					label={t("city")}
					customClass="address-block city"
					onChange={this.handleChanges}
					showRequired={showRequired.city}
					value={values.city}
				/>
				{this.props.showCountry && (
					<PootsySelectInput
						name="country"
						defaultText={t("country_be")}
						selected={values.country || "be"}
						customClass="address-block country"
						options={[
							{ value: "be", label: t("country_be") },
							{ value: "nl", label: t("country_nl") },
						]}
						onChange={this.handleChanges}
					/>
				)}
			</div>
		)
	}
}

PootsyAddressBlockInputs.contextTypes = { t: PropTypes.func }
PootsyAddressBlockInputs.defaultProps = {
	handleChanges: () => {},
}
export default PootsyAddressBlockInputs
