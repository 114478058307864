import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import * as actions from "../actions"

import { PootsySelectInput } from "./FunctionalInputs"
import { PootsyButton } from "./FunctionalDesign"
import InputsChangeHandler from "./InputsChangeHandler"
import WorkerSchedulePdfDownloader from "./WorkerSchedulePdfDownloader"
import DraftTemplateToPDF from "./DraftTemplateToPDF"

class WorkerTemplates extends Component {
	componentDidMount = () => {
		this.props.dispatch(actions.fetchAvailableDocumentTemplatesList())
		this.props.dispatch(
			actions.workerTemplatesStateChange({
				selectedLang: [
					this.props.currentWorker.mainLanguage,
					this.props.currAffiliate.defaultLanguage,
					"fr",
				].find(entry => ["fr", "nl", "en"].includes(entry)),
			})
		)
	}
	componentWillUnmount = () => {
		this.handleChanges({
			selectedTemplate: "",
			variablesReadyForTemplate: -1,
			enrichedTemplateContent: {},
		})
	}
	handleChanges = changes => {
		if (changes.selectedTemplate) {
			changes.selectedTemplate = Number(changes.selectedTemplate)
		}
		this.props.dispatch(actions.processWorkerTemplateStateChange(changes))
	}
	generatePdfClicked = () => {
		setTimeout(() => {
			this.props.dispatch(
				actions.customerTemplatesStateChange({
					selectedTemplate: "",
					renderableTemplate: {},
				})
			)
		}, 1000)
	}
	render() {
		let { t } = this.context
		let {
			currentWorker,
			currentContract,
			component: { selectedTemplate, selectedLang, renderableTemplate, availableTemplates },
		} = this.props
		if (availableTemplates.length === 0) {
			return <div className="no-templates">{t("no_document_templates")}</div>
		}
		let templateName = ""
		if (selectedTemplate) {
			templateName = availableTemplates.find(entry => entry.id === selectedTemplate)
				.templateName
		}
		return (
			<InputsChangeHandler customClass="WorkerTemplates" onChange={this.handleChanges}>
				<div className="template-selection">
					<PootsySelectInput
						name="selectedTemplate"
						defaultText={t("template")}
						selected={selectedTemplate}
						options={availableTemplates.map(entry => ({
							label: entry.templateName,
							value: entry.id,
						}))}
					/>
					<PootsySelectInput
						name="selectedLang"
						defaultText={t("language")}
						selected={selectedLang}
						options={[
							{ label: "Fr", value: "fr" },
							{ label: "Nl", value: "nl" },
							{ label: "En", value: "en" },
						]}
					/>
				</div>
				<DraftTemplateToPDF
					mode="download"
					fileName={
						currentWorker.displayName.replace(/ /g, "-") +
						"-" +
						templateName.replace(/ /g, "-")
					}
					disable={renderableTemplate.id !== selectedTemplate}
					template={renderableTemplate}
					workerScheduleData={{
						currentContract: currentContract,
						timeranges: currentContract.theoreticalHours.concat(
							currentWorker.recurrentBookings
						),
					}}
				>
					<PootsyButton
						text={t("render_template")}
						fetching={selectedTemplate && renderableTemplate.id !== selectedTemplate}
						theme={renderableTemplate.id !== selectedTemplate ? "cancel" : "green"}
					/>
				</DraftTemplateToPDF>
				<WorkerSchedulePdfDownloader
					currentContract={currentContract}
					timeranges={currentContract.theoreticalHours.concat(
						currentWorker.recurrentBookings
					)}
				>
					<PootsyButton
						text={t("render_contract_schedule_to_pdf")}
						onClick={this.generatePdfClicked}
					/>
				</WorkerSchedulePdfDownloader>
			</InputsChangeHandler>
		)
	}
}

WorkerTemplates.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.workerTemplatesComponent,
	currentWorker: state.redData.currentWorker,
	currAffiliate: state.redData.currentAffiliate,
})
export default connect(mapStateToProps)(WorkerTemplates)
