export default [
	{ value: "AW", label: "aruba" },
	{ value: "AF", label: "afghanistan" },
	{ value: "AO", label: "angola" },
	{ value: "AI", label: "anguilla" },
	{ value: "AX", label: "aland_islands" },
	{ value: "AL", label: "albania" },
	{ value: "AD", label: "andorra" },
	{ value: "AE", label: "anited_arab_emirates" },
	{ value: "AR", label: "argentina" },
	{ value: "AM", label: "armenia" },
	{ value: "AS", label: "american_samoa" },
	{ value: "AQ", label: "antarctica" },
	{ value: "TF", label: "french_southern_territories" },
	{ value: "AG", label: "antigua_and_barbuda" },
	{ value: "AU", label: "australia" },
	{ value: "AT", label: "austria" },
	{ value: "AZ", label: "azerbaijan" },
	{ value: "BI", label: "burundi" },
	{ value: "BE", label: "belgium" },
	{ value: "BJ", label: "benin" },
	{ value: "BQ", label: "bonaire-sint_eustatius_and_saba" },
	{ value: "BF", label: "burkina_faso" },
	{ value: "BD", label: "bangladesh" },
	{ value: "BG", label: "bulgaria" },
	{ value: "BH", label: "bahrain" },
	{ value: "BS", label: "bahamas" },
	{ value: "BA", label: "bosnia_and_herzegovina" },
	{ value: "BL", label: "saint_barthelemy" },
	{ value: "BY", label: "belarus" },
	{ value: "BZ", label: "belize" },
	{ value: "BM", label: "bermuda" },
	{ value: "BO", label: "bolivia-plurinational_state_of" },
	{ value: "BR", label: "brazil" },
	{ value: "BB", label: "barbados" },
	{ value: "BN", label: "brunei_darussalam" },
	{ value: "BT", label: "bhutan" },
	{ value: "BV", label: "bouvet_island" },
	{ value: "BW", label: "botswana" },
	{ value: "CF", label: "central_african_republic" },
	{ value: "CA", label: "canada" },
	{ value: "CC", label: "cocos_keeling_islands" },
	{ value: "CH", label: "switzerland" },
	{ value: "CL", label: "chile" },
	{ value: "CN", label: "china" },
	{ value: "CI", label: "cote_d'ivoire" },
	{ value: "CM", label: "cameroon" },
	{ value: "CD", label: "congo_the_democratic_republic_of_the" },
	{ value: "CG", label: "congo" },
	{ value: "CK", label: "cook_islands" },
	{ value: "CO", label: "colombia" },
	{ value: "KM", label: "comoros" },
	{ value: "CV", label: "cabo_verde" },
	{ value: "CR", label: "costa_rica" },
	{ value: "CU", label: "cuba" },
	{ value: "CW", label: "curacao" },
	{ value: "CX", label: "christmas_island" },
	{ value: "KY", label: "cayman_islands" },
	{ value: "CY", label: "cyprus" },
	{ value: "CZ", label: "czechia" },
	{ value: "DE", label: "germany" },
	{ value: "DJ", label: "djibouti" },
	{ value: "DM", label: "dominica" },
	{ value: "DK", label: "denmark" },
	{ value: "DO", label: "dominican_republic" },
	{ value: "DZ", label: "algeria" },
	{ value: "EC", label: "ecuador" },
	{ value: "EG", label: "egypt" },
	{ value: "ER", label: "eritrea" },
	{ value: "EH", label: "western_sahara" },
	{ value: "ES", label: "spain" },
	{ value: "EE", label: "estonia" },
	{ value: "ET", label: "ethiopia" },
	{ value: "FI", label: "finland" },
	{ value: "FJ", label: "fiji" },
	{ value: "FK", label: "falkland_islands_malvinas" },
	{ value: "FR", label: "france" },
	{ value: "FO", label: "faroe_islands" },
	{ value: "FM", label: "micronesia_federated_states_of" },
	{ value: "GA", label: "gabon" },
	{ value: "GB", label: "united_kingdom" },
	{ value: "GE", label: "gorgia" },
	{ value: "GG", label: "guernsey" },
	{ value: "GH", label: "ghana" },
	{ value: "GI", label: "gibraltar" },
	{ value: "GN", label: "guinea" },
	{ value: "GP", label: "guadeloupe" },
	{ value: "GM", label: "gambia" },
	{ value: "GW", label: "guinea-bissau" },
	{ value: "GQ", label: "equatorial_guinea" },
	{ value: "GR", label: "greece" },
	{ value: "GD", label: "grenada" },
	{ value: "GL", label: "greenland" },
	{ value: "GT", label: "guatemala" },
	{ value: "GF", label: "french_guiana" },
	{ value: "GU", label: "guam" },
	{ value: "GY", label: "guyana" },
	{ value: "HK", label: "hong_kong" },
	{ value: "HM", label: "heard_island_and_mcdonald_islands" },
	{ value: "HN", label: "honduras" },
	{ value: "HR", label: "croatia" },
	{ value: "HT", label: "haiti" },
	{ value: "HU", label: "hungary" },
	{ value: "ID", label: "indonesia" },
	{ value: "IM", label: "isle_of_man" },
	{ value: "IN", label: "india" },
	{ value: "IO", label: "british_indian_ocean_territory" },
	{ value: "IE", label: "ireland" },
	{ value: "IR", label: "iran_islamic_republic_of" },
	{ value: "IQ", label: "iraq" },
	{ value: "IS", label: "iceland" },
	{ value: "IL", label: "israel" },
	{ value: "IT", label: "italy" },
	{ value: "JM", label: "jamaica" },
	{ value: "JE", label: "jersey" },
	{ value: "JO", label: "jordan" },
	{ value: "JP", label: "japan" },
	{ value: "KZ", label: "kazakhstan" },
	{ value: "KE", label: "kenya" },
	{ value: "KG", label: "kyrgyzstan" },
	{ value: "KH", label: "cambodia" },
	{ value: "KI", label: "kiribati" },
	{ value: "KN", label: "saint_kitts_and_nevis" },
	{ value: "KR", label: "korea_republic_of" },
	{ value: "KW", label: "kuwait" },
	{ value: "LA", label: "lao_people_s_democratic_republic" },
	{ value: "LB", label: "lebanon" },
	{ value: "LR", label: "liberia" },
	{ value: "LY", label: "libya" },
	{ value: "LC", label: "saint_lucia" },
	{ value: "LI", label: "liechtenstein" },
	{ value: "LK", label: "sri_lanka" },
	{ value: "LS", label: "lesotho" },
	{ value: "LT", label: "lithuania" },
	{ value: "LU", label: "luxembourg" },
	{ value: "LV", label: "latvia" },
	{ value: "MO", label: "macao" },
	{ value: "MF", label: "saint_martin_french_part" },
	{ value: "MA", label: "morocco" },
	{ value: "MC", label: "monaco" },
	{ value: "MD", label: "moldova_republic_of" },
	{ value: "MG", label: "madagascar" },
	{ value: "MV", label: "maldives" },
	{ value: "MX", label: "mexico" },
	{ value: "MH", label: "marshall_islands" },
	{ value: "MK", label: "macedonia_the_former_yugoslav_republic_of" },
	{ value: "ML", label: "mali" },
	{ value: "MT", label: "malta" },
	{ value: "MM", label: "myanmar" },
	{ value: "ME", label: "montenegro" },
	{ value: "MN", label: "mongolia" },
	{ value: "MP", label: "northern_mariana_islands" },
	{ value: "MZ", label: "mozambique" },
	{ value: "MR", label: "mauritania" },
	{ value: "MS", label: "montserrat" },
	{ value: "MQ", label: "martinique" },
	{ value: "MU", label: "mauritius" },
	{ value: "MW", label: "malawi" },
	{ value: "MY", label: "malaysia" },
	{ value: "YT", label: "mayotte" },
	{ value: "NA", label: "namibia" },
	{ value: "NC", label: "new_caledonia" },
	{ value: "NE", label: "niger" },
	{ value: "NF", label: "norfolk_island" },
	{ value: "NG", label: "nigeria" },
	{ value: "NI", label: "nicaragua" },
	{ value: "NU", label: "niue" },
	{ value: "NL", label: "netherlands" },
	{ value: "NO", label: "norway" },
	{ value: "NP", label: "nepal" },
	{ value: "NR", label: "nauru" },
	{ value: "NZ", label: "new_zealand" },
	{ value: "OM", label: "oman" },
	{ value: "PK", label: "pakistan" },
	{ value: "PA", label: "panama" },
	{ value: "PN", label: "pitcairn" },
	{ value: "PE", label: "peru" },
	{ value: "PH", label: "philippines" },
	{ value: "PW", label: "palau" },
	{ value: "PG", label: "papua_new_guinea" },
	{ value: "PL", label: "poland" },
	{ value: "PR", label: "puerto_rico" },
	{ value: "KP", label: "korea_democratic_people_s_republic_of" },
	{ value: "PT", label: "portugal" },
	{ value: "PY", label: "paraguay" },
	{ value: "PS", label: "palestine_state_of" },
	{ value: "PF", label: "french_polynesia" },
	{ value: "QA", label: "qatar" },
	{ value: "RE", label: "reunion" },
	{ value: "RO", label: "romania" },
	{ value: "RU", label: "russian_federation" },
	{ value: "RW", label: "rwanda" },
	{ value: "SA", label: "saudi_arabia" },
	{ value: "SD", label: "sudan" },
	{ value: "SN", label: "senegal" },
	{ value: "SG", label: "singapore" },
	{ value: "GS", label: "south_georgia_and_the_south_sandwich_islands" },
	{ value: "SH", label: "saint_helena_ascension_and_tristan_da_cunha" },
	{ value: "SJ", label: "svalbard_and_jan_mayen" },
	{ value: "SB", label: "solomon_islands" },
	{ value: "SL", label: "sierra_leone" },
	{ value: "SV", label: "el_salvador" },
	{ value: "SM", label: "san_marino" },
	{ value: "SO", label: "somalia" },
	{ value: "PM", label: "saint_pierre_and_miquelon" },
	{ value: "RS", label: "serbia" },
	{ value: "SS", label: "south_sudan" },
	{ value: "ST", label: "sao_tome_and_principe" },
	{ value: "SR", label: "suriname" },
	{ value: "SK", label: "slovakia" },
	{ value: "SI", label: "slovenia" },
	{ value: "SE", label: "sweden" },
	{ value: "SZ", label: "eswatini" },
	{ value: "SX", label: "sint_maarten_dutch_part" },
	{ value: "SC", label: "seychelles" },
	{ value: "SY", label: "syrian_arab_republic" },
	{ value: "TC", label: "turks_and_caicos_islands" },
	{ value: "TD", label: "chad" },
	{ value: "TG", label: "togo" },
	{ value: "TH", label: "thailand" },
	{ value: "TJ", label: "tajikistan" },
	{ value: "TK", label: "tokelau" },
	{ value: "TM", label: "turkmenistan" },
	{ value: "TL", label: "timor-leste" },
	{ value: "TO", label: "tonga" },
	{ value: "TT", label: "trinidad_and_tobago" },
	{ value: "TN", label: "tunisia" },
	{ value: "TR", label: "turkey" },
	{ value: "TV", label: "tuvalu" },
	{ value: "TW", label: "taiwan_province_of_china" },
	{ value: "TZ", label: "tanzania_united_republic_of" },
	{ value: "UG", label: "uganda" },
	{ value: "UA", label: "ukraine" },
	{ value: "UM", label: "united_states_minor_outlying_islands" },
	{ value: "UY", label: "uruguay" },
	{ value: "US", label: "united_states_of_america" },
	{ value: "UZ", label: "uzbekistan" },
	{ value: "VA", label: "holy_see" },
	{ value: "VC", label: "saint_vincent_and_the_grenadines" },
	{ value: "VE", label: "venezuela_bolivarian_republic_of" },
	{ value: "VG", label: "virgin_islands_british" },
	{ value: "VI", label: "virgin_islands_us" },
	{ value: "VN", label: "viet_nam" },
	{ value: "VU", label: "vanuatu" },
	{ value: "WF", label: "wallis_and_futuna" },
	{ value: "WS", label: "samoa" },
	{ value: "YE", label: "yemen" },
	{ value: "ZA", label: "south_africa" },
	{ value: "ZM", label: "zambia" },
	{ value: "ZW", label: "zimbabwe" },
	{ value: "GKO", label: "kosovo" },
	{ value: "JEZ", label: "jerusalem" },
	{ value: "TPA", label: "palestinian_territory" },
	{ value: "GSE", label: "senegambia" },
	{ value: "GCA", label: "cabinda" },
	{ value: "GST", label: "santhome_island" },
	{ value: "GTK", label: "transkey" },
	{ value: "GBO", label: "bophutatswana" },
	{ value: "GIC", label: "canary_islands" },
	{ value: "GMD", label: "madeira" },
	{ value: "GAB", label: "british_west_indies" },
	{ value: "GID", label: "dominica_island" },
	{ value: "GAF", label: "french_antilles" },
	{ value: "GAA", label: "american_caribbean" },
	{ value: "GHW", label: "hawaii" },
	{ value: "GIP", label: "pacific_islands" },
	{ value: "GTA", label: "tahiti" },
	{ value: "GDA", label: "territoire_dep_de_l_australie" },
	{ value: "GNZ", label: "territoire_dep_de_la_nouvelle_zelande" },
	{ value: "GRF", label: "refugee" },
	{ value: "GXX", label: "undetermined" },
	{ value: "GSH", label: "shape" },
	{ value: "GOT", label: "nato" },
	{ value: "GRO", label: "onu_refugee" },
	{ value: "GOU", label: "onu_undetermined" },
	{ value: "GAP", label: "stateless" },
	{ value: "GXY", label: "not_yet_definitely_established" },
	{ value: "GRE", label: "strip_for_foreign" },
	{ value: "GEM", label: "at_sea" },
	{ value: "GSI", label: "group_s_unknown" },
]
