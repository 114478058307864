import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment"

import { PootsyButton } from "./FunctionalDesign"

export default class WeekPicker extends Component {
	goToPrevWeek = () => {
		let { name, onChange, currentDate } = this.props
		let newWeek = moment(currentDate, "YYYY-MM-DD")
		newWeek.subtract(1, "month")
		onChange({ [name]: newWeek })
	}
	goToNextMonth = () => {
		let { name, onChange, currentDate } = this.props
		let newWeek = moment(currentDate, "YYYY-MM-DD")
		newWeek.add(1, "month")
		onChange({ [name]: newWeek })
	}
	goToCurrentMonth = () => {
		let { name, onChange } = this.props
		let newWeek = moment()
		onChange({ [name]: newWeek })
	}
	render() {
		let { t } = this.context
		return (
			<div className="pootsy-week-picker">
				<PootsyButton text="<" onClick={this.goToPrevWeek} />
				<PootsyButton
					text={t("agenda_go_to_current_week")}
					onClick={this.goToCurrentMonth}
				/>
				<PootsyButton text=">" onClick={this.goToNextMonth} />
			</div>
		)
	}
}

WeekPicker.contextTypes = { t: PropTypes.func }
