import React, { Component } from "react"
import PropTypes from "prop-types"

import { PootsyTextInput } from "./FunctionalInputs"

import { CartSvg } from "../svg/cart"

class AvailableWorkshopOrderItemInput extends Component {
	addOne = () => {
		let {
			onChange,
			quantity,
			orderItem: { id },
		} = this.props
		onChange({ avItemID: Number(id), quantity: Number(quantity || 0) + 1 })
	}
	handleChange = e => {
		e.stopPropagation()
		let { value } = e.target
		let {
			onChange,
			orderItem: { id },
		} = this.props
		onChange({ avItemID: Number(id), quantity: Number(value) })
	}
	render() {
		let { t } = this.context
		let { orderItem, quantity } = this.props
		return (
			<div className="AvailableWorkshopOrderItemInput">
				<div className="top-part">
					<div className="item-name">{orderItem.name}</div>
					<div className="price">
						{orderItem.price.toString().replace(".", ",") +
							" " +
							t(orderItem.unitSymbol)}
					</div>
				</div>
				<div className="inputs">
					<PootsyTextInput
						min="0"
						type="number"
						label=""
						value={quantity}
						onChange={this.handleChange}
					/>
					<CartSvg onClick={this.addOne} />
				</div>
			</div>
		)
	}
}

AvailableWorkshopOrderItemInput.contextTypes = { t: PropTypes.func }
export default AvailableWorkshopOrderItemInput
