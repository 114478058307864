import moment from "moment"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { simpleFetch, denormalizeImpactedBookingActions } from "./index"
import { contractV2MetaChange } from "./acWorkerContract"
import { workerScheduleValidator } from "../helpers/workerScheduleValidator"
import { stringifyQuery } from "../helpers/qs"

/// action creators

export const WORKER_SUGGESTIONS_RESET = "WORKER_SUGGESTIONS_RESET"
export const resetWorkerSuggestions = () => ({ type: WORKER_SUGGESTIONS_RESET })

export const SET_WORKER_SUGGESTIONS = "SET_WORKER_SUGGESTIONS"
export const setWorkerSuggestions = suggestions => ({ type: SET_WORKER_SUGGESTIONS, suggestions })

export const NEW_WORKER_DATACHANGE = "NEW_WORKER_DATACHANGE"
export const newWorkerDataChanges = changes => ({ type: NEW_WORKER_DATACHANGE, changes })

export const NEW_WORKER_REQUIREFIELD = "NEW_WORKER_REQUIREFIELD"
export const newWorkerRequireField = (field, makeItRed) => ({
	type: NEW_WORKER_REQUIREFIELD,
	field,
	makeItRed,
})

export const EDIT_WORKER_CONTRACT = "EDIT_WORKER_CONTRACT"
export const editWorkerContract = data => ({ type: EDIT_WORKER_CONTRACT, data: data })

export const WORKER_ABSENCES_STATE_CHANGE = "WORKER_ABSENCES_STATE_CHANGE"
export const workerAbsencesStateChange = changes => ({
	type: WORKER_ABSENCES_STATE_CHANGE,
	changes,
})

export const WORKER_ABSENCES_CANCEL_WARNING = "WORKER_ABSENCES_CANCEL_WARNING"
export const workerAbsencesCancelWarning = absenceIndex => ({
	type: WORKER_ABSENCES_CANCEL_WARNING,
	absenceIndex,
})

export const RESET_WORKER_DATA = "RESET_WORKER_DATA"
export const resetWorkerData = () => ({ type: RESET_WORKER_DATA })

export const WORKER_PROFILE_UICHANGE = "WORKER_PROFILE_UICHANGE"
export const workerProfileUIChange = changes => ({ type: WORKER_PROFILE_UICHANGE, changes })

export const WORKERS_STATE_CHANGE = "WORKERS_STATE_CHANGE"
export const workersStateChange = changes => ({ type: WORKERS_STATE_CHANGE, changes })

export const WORKER_PAGE_STATE_CHANGE = "WORKER_PAGE_STATE_CHANGE"
export const workerPageStateChange = changes => ({ type: WORKER_PAGE_STATE_CHANGE, changes })

export const WORKER_WORKSHOP_ORDERS_STATE_CHANGE = "WORKER_WORKSHOP_ORDERS_STATE_CHANGE"
export const workerWorkshopOrdersStateChange = changes => ({
	type: WORKER_WORKSHOP_ORDERS_STATE_CHANGE,
	changes,
})

export const WORKER_MULTI_SELECT_STATE_CHANGE = "WORKER_MULTI_SELECT_STATE_CHANGE"
export const workerMultiSelectStateChange = changes => ({
	type: WORKER_MULTI_SELECT_STATE_CHANGE,
	changes,
})

export const WORKER_MULTI_SELECT_RESET_STATE = "WORKER_MULTI_SELECT_RESET_STATE"
export const resetWorkerMultiSelectState = () => ({
	type: WORKER_MULTI_SELECT_RESET_STATE,
})

///// fetch actions
export const WORKERSFILTERS_REQUEST = "WORKERSFILTERS_REQUEST"
export const WORKERSFILTERS_SUCCESS = "WORKERSFILTERS_SUCCESS"
export const WORKERSFILTERS_FAILURE = "WORKERSFILTERS_FAILURE"

export const CREATEABSENCE = "CREATEABSENCE"
export const CREATEABSENCE_REQUEST = "CREATEABSENCE_REQUEST"
export const CREATEABSENCE_SUCCESS = "CREATEABSENCE_SUCCESS"
export const CREATEABSENCE_FAILURE = "CREATEABSENCE_FAILURE"

export const UPDATEABSENCE_REQUEST = "UPDATEABSENCE_REQUEST"
export const UPDATEABSENCE_SUCCESS = "UPDATEABSENCE_SUCCESS"
export const UPDATEABSENCE_FAILURE = "UPDATEABSENCE_FAILURE"

export const UPDATEWORKER_REQUEST = "UPDATEWORKER_REQUEST"
export const UPDATEWORKER_SUCCESS = "UPDATEWORKER_SUCCESS"
export const UPDATEWORKER_FAILURE = "UPDATEWORKER_FAILURE"

export const APPROVEABSENCE = "APPROVEABSENCE"
export const APPROVEABSENCE_REQUEST = "APPROVEABSENCE_REQUEST"
export const APPROVEABSENCE_SUCCESS = "APPROVEABSENCE_SUCCESS"
export const APPROVEABSENCE_FAILURE = "APPROVEABSENCE_FAILURE"

export const REFUSEABSENCE_REQUEST = "REFUSEABSENCE_REQUEST"
export const REFUSEABSENCE_SUCCESS = "REFUSEABSENCE_SUCCESS"
export const REFUSEABSENCE_FAILURE = "REFUSEABSENCE_FAILURE"

export const CANCELABSENCE_REQUEST = "CANCELABSENCE_REQUEST"
export const CANCELABSENCE_SUCCESS = "CANCELABSENCE_SUCCESS"
export const CANCELABSENCE_FAILURE = "CANCELABSENCE_FAILURE"

export const WORKERCONTRACTEND_REQUEST = "WORKERCONTRACTEND_REQUEST"
export const WORKERCONTRACTEND_SUCCESS = "WORKERCONTRACTEND_SUCCESS"
export const WORKERCONTRACTEND_FAILURE = "WORKERCONTRACTEND_FAILURE"

export const REMOVEWORKERCONTRACTEND_REQUEST = "REMOVEWORKERCONTRACTEND_REQUEST"
export const REMOVEWORKERCONTRACTEND_SUCCESS = "REMOVEWORKERCONTRACTEND_SUCCESS"
export const REMOVEWORKERCONTRACTEND_FAILURE = "REMOVEWORKERCONTRACTEND_FAILURE"

export const RESET_WORKER_PASSWORD_REQUEST = "RESET_WORKER_PASSWORD_REQUEST"
export const RESET_WORKER_PASSWORD_SUCCESS = "RESET_WORKER_PASSWORD_SUCCESS"
export const RESET_WORKER_PASSWORD_FAILURE = "RESET_WORKER_PASSWORD_FAILURE"

export const FETCHWORKER_REQUEST = "FETCHWORKER_REQUEST"
export const FETCHWORKER_SUCCESS = "FETCHWORKER_SUCCESS"
export const FETCHWORKER_FAILURE = "FETCHWORKER_FAILURE"

export const FETCHWORKERABSENCES_REQUEST = "FETCHWORKERABSENCES_REQUEST"
export const FETCHWORKERABSENCES_SUCCESS = "FETCHWORKERABSENCES_SUCCESS"
export const FETCHWORKERABSENCES_FAILURE = "FETCHWORKERABSENCES_FAILURE"

export const FETCHWORKERBOOKINGS_REQUEST = "FETCHWORKERBOOKINGS_REQUEST"
export const FETCHWORKERBOOKINGS_SUCCESS = "FETCHWORKERBOOKINGS_SUCCESS"
export const FETCHWORKERBOOKINGS_FAILURE = "FETCHWORKERBOOKINGS_FAILURE"

export const WORKERSUGGESTIONS_REQUEST = "WORKERSUGGESTIONS_REQUEST"
export const WORKERSUGGESTIONS_SUCCESS = "WORKERSUGGESTIONS_SUCCESS"
export const WORKERSUGGESTIONS_FAILURE = "WORKERSUGGESTIONS_FAILURE"

export const CREATEWORKER_REQUEST = "CREATEWORKER_REQUEST"
export const CREATEWORKER_SUCCESS = "CREATEWORKER_SUCCESS"
export const CREATEWORKER_FAILURE = "CREATEWORKER_FAILURE"

export const FETCHWORKERS = "FETCHWORKERS"
export const FETCHWORKERS_REQUEST = "FETCHWORKERS_REQUEST"
export const FETCHWORKERS_SUCCESS = "FETCHWORKERS_SUCCESS"
export const FETCHWORKERS_FAILURE = "FETCHWORKERS_FAILURE"

export const DELETEEXTRAAVAIL_REQUEST = "DELETEEXTRAAVAIL_REQUEST"
export const DELETEEXTRAAVAIL_SUCCESS = "DELETEEXTRAAVAIL_SUCCESS"
export const DELETEEXTRAAVAIL_FAILURE = "DELETEEXTRAAVAIL_FAILURE"

export const FETCHAVAILABLEWORKERLANGUAGES_REQUEST = "FETCHAVAILABLEWORKERLANGUAGES_REQUEST"
export const FETCHAVAILABLEWORKERLANGUAGES_SUCCESS = "FETCHAVAILABLEWORKERLANGUAGES_SUCCESS"
export const FETCHAVAILABLEWORKERLANGUAGES_FAILURE = "FETCHAVAILABLEWORKERLANGUAGES_FAILURE"

export const FETCHWORKERMESSAGES_REQUEST = "FETCHWORKERMESSAGES_REQUEST"
export const FETCHWORKERMESSAGES_SUCCESS = "FETCHWORKERMESSAGES_SUCCESS"
export const FETCHWORKERMESSAGES_FAILURE = "FETCHWORKERMESSAGES_FAILURE"

export const FETCHBATCHWORKERMESSAGES = "FETCHBATCHWORKERMESSAGES"
export const FETCHBATCHWORKERMESSAGES_REQUEST = "FETCHBATCHWORKERMESSAGES_REQUEST"
export const FETCHBATCHWORKERMESSAGES_SUCCESS = "FETCHBATCHWORKERMESSAGES_SUCCESS"
export const FETCHBATCHWORKERMESSAGES_FAILURE = "FETCHBATCHWORKERMESSAGES_FAILURE"

export const CANCELLEDEVENTS = "CANCELLEDEVENTS"
export const CANCELLEDEVENTS_REQUEST = "CANCELLEDEVENTS_REQUEST"
export const CANCELLEDEVENTS_SUCCESS = "CANCELLEDEVENTS_SUCCESS"
export const CANCELLEDEVENTS_FAILURE = "CANCELLEDEVENTS_FAILURE"

export const POSTBATCHWORKERMESSAGES = "POSTBATCHWORKERMESSAGES"
export const POSTBATCHWORKERMESSAGES_REQUEST = "POSTBATCHWORKERMESSAGES_REQUEST"
export const POSTBATCHWORKERMESSAGES_SUCCESS = "POSTBATCHWORKERMESSAGES_SUCCESS"
export const POSTBATCHWORKERMESSAGES_FAILURE = "POSTBATCHWORKERMESSAGES_FAILURE"

export const SUBMITWORKERMESSAGE_REQUEST = "SUBMITWORKERMESSAGE_REQUEST"
export const SUBMITWORKERMESSAGE_SUCCESS = "SUBMITWORKERMESSAGE_SUCCESS"
export const SUBMITWORKERMESSAGE_FAILURE = "SUBMITWORKERMESSAGE_FAILURE"

export const FETCH_REGULAR_WORKER_SCHEDULES_REQUEST = "FETCH_REGULAR_WORKER_SCHEDULES_REQUEST"
export const FETCH_REGULAR_WORKER_SCHEDULES_SUCCESS = "FETCH_REGULAR_WORKER_SCHEDULES_SUCCESS"
export const FETCH_REGULAR_WORKER_SCHEDULES_FAILURE = "FETCH_REGULAR_WORKER_SCHEDULES_FAILURE"

export const FETCH_WORKER_INCOMPATIBILITIES_REQUEST = "FETCH_WORKER_INCOMPATIBILITIES_REQUEST"
export const FETCH_WORKER_INCOMPATIBILITIES_SUCCESS = "FETCH_WORKER_INCOMPATIBILITIES_SUCCESS"
export const FETCH_WORKER_INCOMPATIBILITIES_FAILURE = "FETCH_WORKER_INCOMPATIBILITIES_FAILURE"

export const SUBMIT_WORKER_INCOMPATIBILITY_REQUEST = "SUBMIT_WORKER_INCOMPATIBILITY_REQUEST"
export const SUBMIT_WORKER_INCOMPATIBILITY_SUCCESS = "SUBMIT_WORKER_INCOMPATIBILITY_SUCCESS"
export const SUBMIT_WORKER_INCOMPATIBILITY_FAILURE = "SUBMIT_WORKER_INCOMPATIBILITy_FAILURE"

export const SUBMIT_REMOVE_WORKER_INCOMPATIBILITY_REQUEST =
	"SUBMIT_REMOVE_WORKER_INCOMPATIBILITY_REQUEST"
export const SUBMIT_REMOVE_WORKER_INCOMPATIBILITY_SUCCESS =
	"SUBMIT_REMOVE_WORKER_INCOMPATIBILITY_SUCCESS"
export const SUBMIT_REMOVE_WORKER_INCOMPATIBILITY_FAILURE =
	"SUBMIT_REMOVE_WORKER_INCOMPATIBILITY_FAILURE"

export const WORKERS_LIST_EXPORT_REQUEST = "WORKERS_LIST_EXPORT_REQUEST"
export const WORKERS_LIST_EXPORT_SUCCESS = "WORKERS_LIST_EXPORT_SUCCESS"
export const WORKERS_LIST_EXPORT_FAILURE = "WORKERS_LIST_EXPORT_FAILURE"

export const FETCH_WORKERS_WITHOUT_SQUADS_REQUEST = "FETCH_WORKERS_WITHOUT_SQUADS_REQUEST"
export const FETCH_WORKERS_WITHOUT_SQUADS_SUCCESS = "FETCH_WORKERS_WITHOUT_SQUADS_SUCCESS"
export const FETCH_WORKERS_WITHOUT_SQUADS_FAILURE = "FETCH_WORKERS_WITHOUT_SQUADS_FAILURE"

export const FORGET_WORKER_CONTRACT = "FORGET_WORKER_CONTRACT"
export const FORGET_WORKER_CONTRACT_REQUEST = "FORGET_WORKER_CONTRACT_REQUEST"
export const FORGET_WORKER_CONTRACT_SUCCESS = "FORGET_WORKER_CONTRACT_SUCCESS"
export const FORGET_WORKER_CONTRACT_FAILURE = "FORGET_WORKER_CONTRACT_FAILURE"
export const WORKER_CONTRACT_STATE_CHANGE = "WORKER_CONTRACT_STATE_CHANGE"
export const workerContractComponentStateChange = changes => ({
	type: WORKER_CONTRACT_STATE_CHANGE,
	changes,
})

export const fetchWorkers = (customQuery = {}) => (dispatch, getState) => {
	let {
		currentURLQuery: { realQuery },
	} = getState()
	let url =
		"/front/affiliates/affiliate_workers" + stringifyQuery({ ...realQuery, ...customQuery })
	let init = { method: "GET" }
	dispatch(simpleFetch(url, init, FETCHWORKERS))
}

export const fetchWorkersCommunication = (customQuery = {}) => (dispatch, getState) => {
	let {
		currentURLQuery: { realQuery },
	} = getState()
	let url =
		"/front/affiliates/affiliate_workers/0/communication" +
		stringifyQuery({ ...realQuery, ...customQuery })
	let init = { method: "GET" }
	dispatch(simpleFetch(url, init, FETCHBATCHWORKERMESSAGES))
}

export const fetchWorker = id => async (dispatch, getState) => {
	let url = "/front/affiliates/affiliate_workers/" + id
	let init = { method: "GET" }

	await dispatch(simpleFetch(url, init, "FETCHWORKER"))

	let {
		redData: { workerContracts },
		redComponents: {
			workerContractComponent: { selectedContractID },
		},
	} = getState()
	let contract = workerContracts.find(entry => entry.id === selectedContractID)
	if (contract) {
		let { errors, schedErrors } = workerScheduleValidator(contract)
		dispatch(contractV2MetaChange({ errors, schedErrors }))
	}
}

export const forgetAffiliateWorker = () => (dispatch, getState) => {
	let {
		redData: { impactedBookings, currentWorker },
		redComponents: {
			workerContractComponent: { selectedForgetDate },
			impactedBookingsModal: { forceUnlink },
		},
	} = getState()
	let url = `/front/affiliates/affiliate_workers/${currentWorker.id}/forget`
	let init = {
		method: "PUT",
		body: JSON.stringify({ scheduled_date: selectedForgetDate }),
		impacted_bookings_actions: denormalizeImpactedBookingActions(impactedBookings),
		force_unlink: forceUnlink,
	}
	dispatch(simpleFetch(url, init, FORGET_WORKER_CONTRACT))
}

export const submitAbsenceFromWorkerPage = () => (dispatch, getState) => {
	let {
		redData: { currentWorker, impactedBookings },
		redComponents: {
			workerPage,
			impactedBookingsModal: { forceUnlink },
		},
	} = getState()
	let init = {
		method: "POST",
		body: JSON.stringify({
			activity_code: workerPage.activityCode,
			activity_category: workerPage.activityCategory,
			motif_type: Number(workerPage.absenceType),
			start_time: workerPage.absenceStart.format(),
			end_time: workerPage.absenceEnd.format(),
			affiliate_note: workerPage.absenceNote,
			form_c32a_number: workerPage.formC32ANumber,
			must_call_onss: workerPage.mustCallOnss,
			force_unlink: forceUnlink,
			impacted_bookings_actions: denormalizeImpactedBookingActions(impactedBookings),
		}),
	}
	let url = "/front/affiliates/affiliate_workers/" + currentWorker.id + "/unavailabilities"
	dispatch(simpleFetch(url, init, CREATEABSENCE))
}

export const approveAbsence = ({
	absenceToApproveID,
	activityCode,
	activityCategory,
	absenceType,
	formC32ANumber,
	mustCallOnss,
}) => (dispatch, getState) => {
	let {
		redData: { currentWorker, impactedBookings },
		redComponents: {
			impactedBookingsModal: { forceUnlink },
		},
	} = getState()
	let url =
		"/front/affiliates/affiliate_workers/" +
		currentWorker.id +
		"/unavailabilities/" +
		absenceToApproveID +
		"/approve"
	let init = {
		method: "POST",
		body: JSON.stringify({
			activity_category: activityCategory,
			activity_code: activityCode,
			motif_type: absenceType,
			form_c32a_number: formC32ANumber,
			must_call_onss: mustCallOnss,
			force_unlink: forceUnlink,
			impacted_bookings_actions: denormalizeImpactedBookingActions(impactedBookings),
		}),
	}
	dispatch(simpleFetch(url, init, APPROVEABSENCE))
}

export const FETCH_ALL_WORKERS = "FETCH_ALL_WORKERS"
export const FETCH_ALL_WORKERS_REQUEST = "FETCH_ALL_WORKERS_REQUEST"
export const FETCH_ALL_WORKERS_SUCCESS = "FETCH_ALL_WORKERS_SUCCESS"
export const FETCH_ALL_WORKERS_FAILURE = "FETCH_ALL_WORKERS_FAILURE"
export const createWorkersListPDF = (translate, currentQuery) => async (dispatch, getState) => {
	let url = "/front/affiliates/lists/workers_list" + currentQuery
	let init = { method: "GET" }
	let { actionType, data } = await dispatch(simpleFetch(url, init, FETCH_ALL_WORKERS))
	if (actionType === FETCH_ALL_WORKERS_SUCCESS) {
		let customersListForPDF = {
			showHead: "firstPage",
			margin: { top: 15, left: 5, bottom: 5, right: 5 },
			styles: {
				fontSize: 9,
				cellPadding: 1,
				halign: "left",
				valign: "middle",
				cellWidth: "wrap",
			},
			columnStyles: {
				id: { cellWidth: "wrap" },
				language: { cellWidth: "wrap" },
				phone: { cellWidth: "wrap" },
			},
			columns: [
				{ header: translate("id"), dataKey: "id" },
				{ header: translate("first_name"), dataKey: "first_name" },
				{ header: translate("last_name"), dataKey: "last_name" },
				{ header: translate("email"), dataKey: "email" },
				{ header: translate("language_abbrev"), dataKey: "language" },
				{ header: translate("phone"), dataKey: "phone" },
				{
					header: translate("start_active_contract_abbrev"),
					dataKey: "start_active_contract",
				},
				{ header: translate("end_active_contract_abbrev"), dataKey: "end_active_contract" },
			],
			body: data.data.map(entry => ({
				id: entry.id,
				first_name: entry.attributes.first_name,
				last_name: entry.attributes.last_name,
				email: entry.attributes.email,
				language: entry.attributes.default_language,
				phone: entry.attributes.telephone,
				start_active_contract: entry.attributes.original_contract_start,
				end_active_contract: entry.attributes.end_of_last_addendum,
			})),
		}
		let doc = new jsPDF("landscape")
		doc.text(`${moment().format("DD/MM/YY")} ${translate("workers_lists_pdf_header")}`, 5, 10)
		doc.autoTable(customersListForPDF)
		doc.save(`${translate("workers_lists")}.pdf`)
	}
}
