import React, { Component } from "react"
import { PootsyMultipleValuesBox } from "./FunctionalDesign"
import PropTypes from "prop-types"



class LinkedPSVsDisplayer extends Component {
	render() {
		let { t } = this.context
		let { expectedCount, currentPSVs, onPSVClick } = this.props
		return (
			<div className="LinkedPSVsDisplayer">
				<div className="pay-status">
					{expectedCount === currentPSVs.length
						? t("p_payment_encoded")
						: `${t("missing_vouchers")}: ${expectedCount - currentPSVs.length}`}
				</div>

				<PootsyMultipleValuesBox
					values={[
						{
							label: t("added_psv_label_by_affiliates_title"),
							values: currentPSVs.map(psv => {
								if (!psv.code) {
									psv.code = t("non_scanned_psv")
								}
								return (
									<div
										className="psv"
										onClick={e => onPSVClick(e)}
										data-psvid={psv.id}
									>
										<span className="tooltip">{t("click_to_remove")}</span>
										{psv.code}
									</div>
								)
							}),
						},
					]}
				/>
			</div>
		)
	}
}

LinkedPSVsDisplayer.contextTypes = { t: PropTypes.func }
export default LinkedPSVsDisplayer
