import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"

import * as actions from "../actions"

import NewCustomerModal from "./NewCustomerModal"
import NewResidenceModal from "./NewResidenceModal"
import {
	PootsyButton,
	PootsySubHeading,
	PootsyValueDisplayer,
	LabeledDateTime,
} from "./FunctionalDesign"
import ModalLayout from "./ModalLayout"
import SearchCustomer from "./SearchCustomer"
import { PootsyRadioInputs, PootsySelectInput } from "./FunctionalInputs"
import InputsChangeHandler from "./InputsChangeHandler"

export class NewDirectBookingModal extends Component {
	state = {
		showRequired: {
			recurrence: false,
			chosenCustomer: false,
			voucherType: false,
			residenceIndex: false,
		},
	}
	componentDidMount = () => {
		this.setState = this.setState.bind(this)
		let {
			currForm: { selectedEndDate },
			newDirectBookingModal: { prefillRange, prefillState },
		} = this.props
		this.handleChanges({
			startHour: moment(prefillRange.start),
			endHour: moment(prefillRange.end),
			selectedEndDate: selectedEndDate,
			voucherType: "",
			residenceIndex: "",
		})
		if (prefillState) {
			this.handleChanges(prefillState(prefillRange))
		}
	}
	disableEndDays = day => {
		let {
			currForm: { recurrence },
			newDirectBookingModal: { prefillRange },
		} = this.props
		if (day.isBefore(prefillRange.start)) {
			return true
		}

		if (recurrence === "1" || recurrence === "2") {
			return (day.diff(prefillRange.start, "days") + 1) % (Number(recurrence) * 7) !== 0
		}
	}
	showRequired = toRequire => {
		let newRequired = { ...this.state.showRequired }
		toRequire.forEach(entry => {
			newRequired[entry] = true
		})
		this.setState({ showRequired: newRequired })
	}
	handleChange = changes => {
		this.props.dispatch(actions.directBookingModalStateChange(changes))
	}
	toggleNewResidenceModal = () => {
		this.handleChange({
			showNewResidenceModal: !this.props.newDirectBookingModal.showNewResidenceModal,
		})
	}
	toggleNewCustomerModal = () => {
		this.handleChange({
			showNewCustomerModal: !this.props.newDirectBookingModal.showNewCustomerModal,
		})
	}
	handleChanges = changes => {
		let { dispatch } = this.props
		this.handleChange({ showCreationWarning: false })
		dispatch(actions.editDirectBooking(changes))
	}
	handleCustomerClick = customer => {
		let changes = { chosenCustomer: customer }
		if (customer.attributes.residences.data.length === 1) {
			changes.residenceIndex = "0"
		}
		if (customer.attributes.voucher_type_preference) {
			changes.voucherType = customer.attributes.voucher_type_preference
		} else {
			changes.voucherType = ""
		}
		this.handleChanges(changes)
	}
	submit = () => {
		let { newDirectBookingModal, dispatch, affiliateWorkerId, workerContractId } = this.props
		let {
			startHour,
			endHour,
			recurrence,
			chosenCustomer,
			residenceIndex,
			selectedEndDate,
			voucherType,
		} = this.props.currForm
		let toCheck = ["recurrence", "chosenCustomer", "voucherType", "residenceIndex"],
			valid = true
		let toRequire = []
		toCheck.forEach(entry => {
			if (!this.props.currForm[entry]) {
				valid = false
				toRequire.push(entry)
			}
		})
		if (!valid) {
			this.showRequired(toRequire)
			return
		}
		let data = {
			// if the creation warning is already displayed, force the direct booking
			force: newDirectBookingModal.showCreationWarning,
			delivery_date: newDirectBookingModal.prefillRange.start.format(),
			start_time: startHour.format(),
			end_time: endHour.format(),
			end_date: selectedEndDate
				? moment(selectedEndDate)
						.add(1, "day")
						.format("YYYY-MM-DD")
				: null,
			recurrence: recurrence !== "0",
			recurrence_delay: recurrence,
			residence_id: chosenCustomer.attributes.residences.data[residenceIndex].id,
			zip_code: chosenCustomer.attributes.residences.data[residenceIndex].attributes.zip_code,
			affiliate_worker_id: affiliateWorkerId,
			voucher_type: voucherType,
		}

		if (newDirectBookingModal.customSubmit) {
			newDirectBookingModal.customSubmit(data)
			return
		}

		let url = "/front/affiliates/customer_contracts/" + chosenCustomer.id + "/direct_booking/"
		let init = { method: "POST", body: JSON.stringify(data) }
		dispatch(actions.simpleFetch(url, init, "SUBMITDIRECTBOOKINGFROMAGENDA")).then(
			({ actionType }) => {
				if (actionType === actions.SUBMITDIRECTBOOKINGFROMAGENDA_SUCCESS) {
					dispatch(
						actions.refetchAgendaLines(
							[workerContractId],
							newDirectBookingModal.prefillRange.start.format()
						)
					)
				}
			}
		)
	}
	closeModal = () => {
		let { dispatch, closeModal, newDirectBookingModal } = this.props
		if (!newDirectBookingModal.controlledState) {
			dispatch(actions.resetDirectBookingForm())
		}
		closeModal()
	}
	calculateNumberOfMinutes = (start, end) => {
		return Math.round(end.diff(start, "seconds") / 60)
	}
	render() {
		let { t } = this.context
		let { newDirectBookingModal, currForm } = this.props
		let { showRequired } = this.state
		if (!currForm.populated) {
			return null
		}
		let disablingMessages = [
			"customer_worker_incompatibility",
			"customer_unavailability",
			"wrong_time_gap",
		]
		let isIllegalNumberOfHours =
			this.calculateNumberOfMinutes(currForm.startHour, currForm.endHour) > 540
		let confirmButtonColor = "pink"
		let cancelButtonColor = "cancel"
		if (isIllegalNumberOfHours) {
			confirmButtonColor = "cancel"
			cancelButtonColor = "pink"
		}
		let submissionDisabled =
			newDirectBookingModal.creationWarning &&
			disablingMessages.includes(newDirectBookingModal.creationWarning.toLowerCase())
		let buttons = [
			<PootsyButton
				key="a"
				text={t("confirm")}
				onClick={this.submit}
				theme={confirmButtonColor}
				disabled={submissionDisabled}
			/>,
			<PootsyButton
				key="b"
				text={t("cancel")}
				theme={cancelButtonColor}
				onClick={this.closeModal}
			/>,
		]
		return (
			<InputsChangeHandler onChange={this.handleChanges}>
				<ModalLayout
					title={
						t("new_direct_booking") +
						" " +
						newDirectBookingModal.prefillRange.start.format("DD/MM/YYYY")
					}
					buttons={buttons}
					closeModal={this.closeModal}
					formDivClass="NewDirectBookingModal"
				>
					{newDirectBookingModal.showNewResidenceModal && (
						<NewResidenceModal
							context="directBooking"
							customerId={currForm.chosenCustomer.id}
							onCancel={this.toggleNewResidenceModal}
						/>
					)}
					{newDirectBookingModal.showNewCustomerModal && (
						<NewCustomerModal
							fromPage="newDirectBooking"
							onCancel={this.toggleNewCustomerModal}
						/>
					)}
					<div className="new-direct-booking-warning">
						{t("new_direct_booking_warning")}
					</div>
					<div className="new-direct-booking-details">
						<div className="details-col times">
							<LabeledDateTime
								label={t("start_time")}
								showDatePicker={false}
								timePickerProps={{
									value: currForm.startHour,
									name: "startHour",
									onChange: this.handleChanges,
								}}
							/>
							<LabeledDateTime
								label={t("end_time")}
								showDatePicker={false}
								timePickerProps={{
									value: currForm.endHour,
									name: "endHour",
									onChange: this.handleChanges,
									filterHours: h => h >= currForm.startHour.hours(),
									filterMinutes: m =>
										currForm.startHour.hours() < currForm.endHour.hours()
											? m >= 0 && m <= 59
											: m > currForm.startHour.minute(),
								}}
							/>
						</div>
						<div className="details-col">
							<PootsyRadioInputs
								groupLabel={t("recurrence")}
								groupName="recurrence"
								showRequired={showRequired.recurrence}
								selected={currForm.recurrence}
								options={[
									{ id: "once", value: "0", label: t("once") },
									{ id: "weekly", value: "1", label: t("every_week") },
									{ id: "bimonthly", value: "2", label: t("every_two_weeks") },
									{ id: "monthly", value: "4", label: t("every_four_weeks") },
								]}
							/>
						</div>
						<LabeledDateTime
							showTimePicker={false}
							label={t("optional_end_date")}
							datePickerProps={{
								name: "selectedEndDate",
								value: currForm.selectedEndDate,
								alwaysOpened: false,
								disableAutoFocus: true,
								onChange: this.handleChanges,
								disableDay: this.disableEndDays,
								disableInput: true,
							}}
						/>
					</div>
					{isIllegalNumberOfHours && (
						<div
							className="warning-number-of-hours"
							style={{ "padding-bottom": "5%", color: "red" }}
						>
							{t("more_than_9_hours")}
						</div>
					)}
					<div className="new-direct-booking-customer-selection">
						<div className="left">
							<div className="search-or-create-customer">
								<SearchCustomer handleCustomerClick={this.handleCustomerClick} />
								<PootsyButton
									text={t("create_new_customer")}
									size="small"
									onClick={this.toggleNewCustomerModal}
								/>
							</div>
						</div>
						<div className="right">
							{currForm.chosenCustomer && (
								<div className="new-direct-booking-chosen-customer">
									<PootsySubHeading text={t("booking_for_following_customer")} />
									<div className="customer-info">
										<PootsyValueDisplayer
											label={t("name")}
											values={[
												currForm.chosenCustomer.attributes.title +
													" " +
													currForm.chosenCustomer.attributes.display_name,
											]}
										/>
										<PootsyValueDisplayer
											label={t("sodexo_number")}
											values={[
												currForm.chosenCustomer.attributes
													.sodexo_reference || "Unknown",
											]}
										/>
									</div>
									<PootsySelectInput
										name="voucherType"
										defaultText={t("voucher_type")}
										showRequired={showRequired.voucherType}
										selected={currForm.voucherType}
										options={[
											{ label: t("electronic"), value: "electronic" },
											{ label: t("paper"), value: "paper" },
											{ label: t("other"), value: "other" },
										]}
									/>
									{currForm.chosenCustomer &&
										currForm.voucherType !== "" &&
										currForm.chosenCustomer.attributes
											.voucher_type_preference !== null &&
										currForm.chosenCustomer.attributes
											.voucher_type_preference !== "" &&
										currForm.chosenCustomer.attributes
											.voucher_type_preference !== currForm.voucherType && (
											<div className="voucher-preference-mismatch-warning">
												{t("not_customer_preferred_voucher_type_warning")}
											</div>
										)}
									<div className="chosen-customer-address">
										<PootsyRadioInputs
											groupLabel={t("residence")}
											groupName="residenceIndex"
											selected={currForm.residenceIndex}
											showRequired={showRequired.residenceIndex}
											options={currForm.chosenCustomer.attributes.residences.data.map(
												(entry, index) => ({
													id: entry.id,
													value: index.toString(),
													label: entry.attributes.address,
												})
											)}
										/>
									</div>
									<PootsyButton
										text={t("add_new_residence")}
										size="small"
										onClick={this.toggleNewResidenceModal}
									/>
								</div>
							)}
						</div>
					</div>
					{newDirectBookingModal.showCreationWarning && (
						<div className="new-direct-booking-creation-warning">
							<div>
								{t("booking_warning_" + newDirectBookingModal.creationWarning)}
							</div>
							{!submissionDisabled && (
								<div>{t("force_direct_booking_instructions")}</div>
							)}
						</div>
					)}
				</ModalLayout>
			</InputsChangeHandler>
		)
	}
}
NewDirectBookingModal.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currForm: state.redData.currentDirectBookingForm,
	newDirectBookingModal: state.redComponents.newDirectBookingModal,
})
export default connect(mapStateToProps)(NewDirectBookingModal)
