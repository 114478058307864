import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { showWorkshop } from "../config"
import * as actions from "../actions"

import { NavLink } from "../components/NavLink"
import UserDropdown from "../components/UserDropdown"

import pLogo from "../static/images/p-logo.png"

class TopNav extends Component {
	logout = () => {
		let { dispatch } = this.props
		dispatch(actions.processLogout())
		localStorage.removeItem("pootsy-token")
		localStorage.removeItem("pootsy-affiliateId")
		localStorage.removeItem("pootsy-last-chosen-affiliate-id")
	}
	changeLanguage = e => {
		let { lang } = e.target.dataset
		this.props.dispatch(actions.setLang(lang))
		this.submitDefaultLanguageUpdate(lang)
	}
	submitDefaultLanguageUpdate = newDefaultLang => {
		this.props.dispatch(actions.submitSettingsUpdate({ newDefaultLang }))
	}
	toggleNotificationCenter = e => {
		e.stopPropagation()
		let {
			dispatch,
			notificationsComponent: { show },
		} = this.props
		dispatch(actions.notificationStateChange({ show: !show }))
		if (!show) {
			this.props.dispatch(actions.fetchNotifications())
			dispatch(actions.longPollingChange({ requestContent: true }))
		} else {
			dispatch(actions.longPollingChange({ requestContent: false }))
		}
	}
	toggleTasksOverlay = e => {
		e.stopPropagation()
		let {
			dispatch,
			tasksComponent: { show },
		} = this.props
		if (!show) {
			dispatch(actions.longPollingChange({ requestContent: true }))
			dispatch(actions.resetTaskComponent())
			dispatch(actions.fetchTasks())
		} else {
			dispatch(actions.longPollingChange({ requestContent: false }))
		}
		dispatch(actions.tasksStateChange({ show: !show }))
	}
	markNotificationsAsRead = e => {
		e.stopPropagation()
		this.props.dispatch(actions.markNotificationsAsRead())
	}
	handleNotificationClick = (e, n) => {
		e.stopPropagation()
		this.props.dispatch(actions.processNotificationClick(n))
	}
	render() {
		let { t } = this.context
		let {
			history,
			warnings,
			currentAffiliate,
			currentUser,
			lang,
			notifications,
			notificationsComponent,
		} = this.props
		let links = [
			{
				link: "/",
				label: t("agenda"),
				navLinkProps: { exact: true },
				condition: () => !currentUser.isWorkshopLimited,
			},
			{
				link: "/booking-wishes",
				label: t("booking_wishes"),
				navLinkProps: { exact: true },
				condition: () => !currentUser.isWorkshopLimited,
			},
			{
				link: "/dashboard",
				label: t("dashboard"),
				condition: () => !currentUser.isWorkshopLimited,
			},
			{
				link: "/bookings",
				label: t("bookings"),
				condition: () => !currentUser.isWorkshopLimited,
			},
			{
				link: "/cleaners",
				label: t("workers"),
				condition: () => !currentUser.isWorkshopLimited,
			},
			{
				link: "/customers",
				label: t("customers"),
			},
			{
				link: "/timesheets",
				label: t("social_secretary"),
				condition: () => !currentUser.isWorkshopLimited,
			},
			{
				link: "/workshop/com-center/today",
				label: t("workshop"),
				condition: () => showWorkshop && currentAffiliate.workshopAddOn,
			},
			{
				link: "/sodexo/exports",
				label: t("sodexo"),
				condition: () => !currentUser.isWorkshopLimited,
			},
			{
				link: "/documents/worker-register",
				label: t("documents"),
				condition: () => !currentUser.isWorkshopLimited && currentAffiliate.dimonaAddOn,
			},
		]
		return (
			<Fragment>
				{process.env.NODE_ENV === "production" && warnings.warningNotChrome && (
					<div className="not-chrome-warning">
						{t("not_chrome_warning")}{" "}
						<a
							href="https://www.google.com/chrome/"
							target="_blank"
							rel="noopener noreferrer"
						>
							{t("click_here_to_install")}
						</a>
					</div>
				)}
				{process.env.NODE_ENV === "production" && warnings.warningOldVersionUsage && (
					<div className="old-version-warning">{t("using_old_pai_version_warning")}</div>
				)}
				{process.env.NODE_ENV === "production" && !currentAffiliate.hasConventions && (
					<div className="no-convention-warning">{t("no_convention_warning_desc")}</div>
				)}
				<div className="main-menu">
					<div className="nav">
						{!currentUser.isWorkshopLimited && (
							<Fragment>
								<NavLink to="/" exact={true}>
									<img src={pLogo} alt="logo" />
								</NavLink>
								<span className="line-separator" />
							</Fragment>
						)}
						{links.map(entry => {
							if (entry.condition && !entry.condition()) {
								return null
							}
							return (
								<Fragment key={entry.label}>
									<NavLink to={entry.link} {...entry.navLinkProps}>
										{entry.label}
									</NavLink>
									<span className="line-separator" />
								</Fragment>
							)
						})}
						<div className="navlink">
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://intercom.help/pootsypro"
							>
								{t("help")}
							</a>
						</div>
						<span className="line-separator" />
					</div>
					<UserDropdown
						currentUser={currentUser}
						affiliateName={currentAffiliate.commercialName}
						push={history.push}
						logout={this.logout}
						lang={lang}
						changeLanguage={this.changeLanguage}
						affiliateLogoUrl={currentAffiliate.logoUrl}
						notifications={notifications}
						notificationsComponent={notificationsComponent}
						toggleNotificationCenter={this.toggleNotificationCenter}
						handleNotificationClick={this.handleNotificationClick}
						markNotificationsAsRead={this.markNotificationsAsRead}
						toggleTasksOverlay={this.toggleTasksOverlay}
					/>
				</div>
			</Fragment>
		)
	}
}

TopNav.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	warnings: state.warnings,
	currentAffiliate: state.redData.currentAffiliate,
	currentUser: state.redData.currentUser,
	lang: state.locale.lang,
	notifications: state.redData.notifications,
	notificationsComponent: state.redComponents.notificationCenterComponent,
	tasksComponent: state.redComponents.tasksComponent,
})
export default connect(mapStateToProps)(TopNav)
