import React, { Component } from "react"

export default class Paginator extends Component {
	navigatePages = e => {
		let { page } = e.target.dataset
		let { goToPage, name, totalPages, currPage } = this.props
		currPage = Number(currPage)
		let pageToGo
		if (page === "next") {
			pageToGo = currPage + 1 > totalPages ? totalPages : currPage + 1
		} else if (page === "previous") {
			pageToGo = currPage - 1 < 1 ? 1 : currPage - 1
		} else {
			pageToGo = Number(page)
		}
		goToPage({ name: name, value: pageToGo })
	}
	render = () => {
		let { currPage, totalPages } = this.props
		let pageLinks = [...Array(totalPages)]
		if (totalPages <= 1) {
			return null
		}
		return (
			<div className="paginator">
				<div
					className="paginator-previous"
					data-page="previous"
					onClick={this.navigatePages}
				>
					&lt;
				</div>
				{pageLinks
					.map((entry, i) => {
						if (
							i === 0 ||
							i === pageLinks.length - 1 ||
							Math.abs(i + 1 - currPage) < 3
						) {
							return i + 1
						}
						return ""
					})
					.filter(entry => entry !== "")
					.map(entry => (
						<div
							key={entry}
							className={
								"paginator-page-link" +
								(entry === parseInt(currPage, 10) ? " active" : "")
							}
							data-page={entry}
							onClick={this.navigatePages}
						>
							{entry}
						</div>
					))}
				<div className="paginator-next" data-page="next" onClick={this.navigatePages}>
					&gt;
				</div>
			</div>
		)
	}
}
