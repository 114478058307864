import { stringifyQuery } from "../helpers/qs"
import {
	simpleFetch,
	fetchEventsAgenda,
	agendaStateChange,
	resetAgendaData,
	directBookingModalStateChange,
} from "./index"
import moment from "moment"

export const BOOKING_WISHES_STATE_CHANGE = "BOOKING_WISHES_STATE_CHANGE"
export const bookingWishesStateChange = changes => ({ type: BOOKING_WISHES_STATE_CHANGE, changes })

export const REPLACE_AGENDA_SCHEDULES = "REPLACE_AGENDA_SCHEDULES"
export const replaceAgendaSchedules = schedules => ({ type: REPLACE_AGENDA_SCHEDULES, schedules })

export const UPDATE_BOOKING_WISHES_HIGHLIGHTS = "UPDATE_BOOKING_WISHES_HIGHLIGHTS"
export const updateBookingWishesHighlights = ({ bookingWishes }) => ({
	type: UPDATE_BOOKING_WISHES_HIGHLIGHTS,
	bookingWishes,
})

export const UPDATE_SELECTED_OCCURRENCES_DATES = "UPDATE_SELECTED_OCCURRENCES_DATES"
export const updateSelectedOccurrencesDates = ({ bookingWishes }) => ({
	type: UPDATE_SELECTED_OCCURRENCES_DATES,
	bookingWishes,
})

export const CREATE_BOOKINGS_FROM_HIGHLIGHTS_STATE_CHANGE =
	"CREATE_BOOKINGS_FROM_HIGHLIGHTS_STATE_CHANGE"
export const createBookingsFromHighlightsModalStateChange = changes => ({
	type: CREATE_BOOKINGS_FROM_HIGHLIGHTS_STATE_CHANGE,
	changes,
})

export const DIRECT_BOOKING_FOR_REPLACEMENT_MODAL_STATE_CHANGE =
	"DIRECT_BOOKING_FOR_REPLACEMENT_MODAL_STATE_CHANGE"
export const directBookingForReplacementModalStateChange = changes => ({
	type: DIRECT_BOOKING_FOR_REPLACEMENT_MODAL_STATE_CHANGE,
	changes,
})

const bwHighlightColors = ["#fdba88", "brown", "palegreen", "slateblue", "mediumvioletred"]
const highlightableTimeranges = ["daily_schedule", "theoretical_hour", "availability_cancellation"]
export const processHighlightBookingWish = bookingWishGroup => (dispatch, getState) => {
	let {
		redComponents: {
			bookingWishesComponent: { currentHighlights },
		},
	} = getState()
	Object.entries(currentHighlights).forEach(([groupId, entry]) => {
		if (entry.isHighlighted) {
			entry.isHighlighted = false
			entry.color = null
		}
	})
	let newCurrentHighlights = {
		...currentHighlights,
		[bookingWishGroup.id]: { isHighlighted: true, color: bwHighlightColors[0] },
	}
	dispatch(bookingWishesStateChange({ currentHighlights: newCurrentHighlights }))

	let bookingWishStagedForFulfillment = bookingWishGroup.bookingWishes.find(
		entry =>
			entry.occurrenceToReplace.id === bookingWishGroup.singleOccurrenceStagedForReplacement
	)
	let occurrenceStaged = bookingWishStagedForFulfillment.occurrenceToReplace
	let originalDuration = occurrenceStaged.endTime.diff(occurrenceStaged.startTime, "minutes")
	let customer = bookingWishStagedForFulfillment.customer
	let residenceIndex = customer.residences.data.reduce((acc, entry, index) => {
		if (occurrenceStaged.address.id === Number(entry.id)) {
			acc = index.toString()
		}
		return acc
	}, undefined)
	dispatch(
		directBookingModalStateChange({
			prefillState: prefillRange => {
				return {
					startHour: moment(prefillRange.start),
					endHour: moment(prefillRange.start).add(originalDuration, "minutes"),
					voucherType: occurrenceStaged.voucherType,
					recurrence: "0",
					residenceIndex,
					chosenCustomer: {
						id: customer.id,
						attributes: {
							title: "",
							display_name: customer.display_name,
							sodexo_reference: customer.sodexo_reference,
							voucher_type_preference: customer.voucher_type_preference,
							residences: customer.residences,
						},
					},
				}
			},
		})
	)
	// dispatch(computeHighlights())
	dispatch(resetAgendaData([]))
}

const findTimerangeCandidates = (
	schedules,
	bookingWishesGroups,
	currentHighlights,
	candidatesPerWorkerPerBwg
) => {
	schedules.forEach(sched => {
		if (!candidatesPerWorkerPerBwg[sched.cleaner.id]) {
			// multiple schedules can be for the same worker if the agenda is in full month mode
			candidatesPerWorkerPerBwg[sched.cleaner.id] = {}
		}

		let seenTrs = []
		let today = moment()
		sched.timeRanges.forEach(tr => {
			if (!highlightableTimeranges.includes(tr.type) || tr.start.isSameOrBefore(today)) {
				return tr
			}

			Object.entries(currentHighlights).forEach(([groupId, currentHighlight]) => {
				if (!currentHighlight.isHighlighted) {
					return
				}
				let bookingWishGroup = bookingWishesGroups[groupId]
				if (!bookingWishGroup) {
					return
				}
				if (!candidatesPerWorkerPerBwg[sched.cleaner.id][bookingWishGroup.id]) {
					candidatesPerWorkerPerBwg[sched.cleaner.id][bookingWishGroup.id] = {
						trCandidates: [],
						color: currentHighlight.color,
						selectedOccurrencesForBwg: bookingWishGroup.replacedOccurrencesDates.filter(
							entry => entry.selected
						),
					}
				}
				bookingWishGroup.allWishedDateranges.forEach(wdr => {
					let isCandidate = false
					if (wdr.isRecurrent) {
						isCandidate =
							tr.start.day() === wdr.dayOfWeek &&
							tr.start.hour() <= wdr.fromTime.hour() &&
							tr.end.hour() >= wdr.toTime.hour()
					} else {
						isCandidate = tr.start.isBetween(wdr.from, wdr.to, "day", "[]")
					}

					if (isCandidate && !seenTrs.includes(tr.id)) {
						// don't add the same tr twice because of multiple matches with wished dateranges

						// TODO:
						// using the tr.id is problematic because the same event can be cut in multiple timeranges
						seenTrs.push(tr.id)
						candidatesPerWorkerPerBwg[sched.cleaner.id][
							bookingWishGroup.id
						].trCandidates.push(tr)
					}
				})
			})
		})
	})
}

const applyTimerangesHighlights = (schedules, candidatesPerWorkerPerBwg) => {
	let scheduleHighlights = {}
	let newSchedules = schedules.map(sched => {
		let newTimeranges = sched.timeRanges.map(tr => {
			if (!highlightableTimeranges.includes(tr.type)) {
				return tr
			}
			let highlightColorsForTr = []
			if (!scheduleHighlights[sched.cleaner.id]) {
				scheduleHighlights[sched.cleaner.id] = {
					worker: sched.cleaner,
					bookingCandidates: [],
				}
			}
			Object.entries(candidatesPerWorkerPerBwg[sched.cleaner.id]).forEach(
				([bwgId, { selectedOccurrencesForBwg, color, trCandidates }]) => {
					if (selectedOccurrencesForBwg.length === 0) {
						highlightColorsForTr.filter(entry => entry !== color)
						return
					}
					if (
						trCandidates.length >= selectedOccurrencesForBwg.length &&
						trCandidates.some(entry => entry.id === tr.id)
					) {
						// The timeranges will only be highlighted if there's at least the same
						// number of highlighted TRs as the number of selected occurrences to replace
						highlightColorsForTr.push(color)
						scheduleHighlights[sched.cleaner.id].bookingCandidates.push(tr)
					}
				}
			)
			tr.highlightColors = highlightColorsForTr
			return tr
		})

		if (
			scheduleHighlights[sched.cleaner.id] &&
			scheduleHighlights[sched.cleaner.id].bookingCandidates.length === 0
		) {
			delete scheduleHighlights[sched.cleaner.id]
		}

		return { ...sched, timeRanges: newTimeranges }
	})

	return { newSchedules, scheduleHighlights }
}

export const computeHighlights = () => (dispatch, getState) => {
	let {
		redData: {
			agenda: { schedules },
			bookingWishes: bookingWishesGroups,
		},
		redComponents: {
			bookingWishesComponent: { currentHighlights },
		},
	} = getState()
	let candidatesPerWorkerPerBwg = {}
	findTimerangeCandidates(
		schedules,
		bookingWishesGroups,
		currentHighlights,
		candidatesPerWorkerPerBwg
	)
	let { newSchedules, scheduleHighlights } = applyTimerangesHighlights(
		schedules,
		candidatesPerWorkerPerBwg
	)
	dispatch(replaceAgendaSchedules(newSchedules))
	dispatch(bookingWishesStateChange({ scheduleHighlights: scheduleHighlights }))
}

export const processRemoveHighlightedBookingWish = bookingWishGroup => (dispatch, getState) => {
	let {
		// redData: { agenda: { schedules }, },
		redComponents: {
			bookingWishesComponent: { currentHighlights },
		},
	} = getState()
	// let oldColor = currentHighlights[bookingWishGroup.id].color
	let newCurrentHighlights = {
		...currentHighlights,
		[bookingWishGroup.id]: {
			isHighlighted: false,
			color: null,
		},
	}
	// const newSchedules = schedules.map(sched => {
	// 	return {
	// 		...sched,
	// 		timeRanges: sched.timeRanges.map(tr => {
	// 			if (tr.highlightColors) {
	// 				tr.highlightColors = tr.highlightColors.filter(color => color !== oldColor)
	// 			}
	// 			return tr
	// 		}),
	// 	}
	// })
	dispatch(directBookingModalStateChange({ prefillState: undefined }))
	dispatch(bookingWishesStateChange({ currentHighlights: newCurrentHighlights }))
	dispatch(resetAgendaData([]))
}

export const processSelectedOccurrencesChange = (occurrenceId, bookingWishGroup) => (
	dispatch,
	getState
) => {
	if (bookingWishGroup.replacedOccurrencesDates.length <= 1) {
		// no need to select replaced occurrences if there's only one
		return
	}
	let {
		redData: { bookingWishes: bookingWishesGroups },
	} = getState()
	let newOccurrenceStagedForReplacement = bookingWishesGroups[
		bookingWishGroup.id
	].bookingWishes.find(entry => entry.occurrenceToReplace.id === occurrenceId)

	if (newOccurrenceStagedForReplacement.status !== "PENDING") {
		// do not stage occurrence for replacement if the status isn't pending
		return
	}
	dispatch(
		updateSelectedOccurrencesDates({
			bookingWishes: {
				...bookingWishesGroups,
				[bookingWishGroup.id]: {
					...bookingWishGroup,
					singleOccurrenceStagedForReplacement: occurrenceId,
					// replacedOccurrencesDates: bookingWishGroup.replacedOccurrencesDates.map(
					// 	entry => {
					// 		if (entry.id === replacedOccurrenceDate.id) {
					// 			return { ...entry, selected: !entry.selected }
					// 		}
					// 		return entry
					// 	}
					// ),
				},
			},
		})
	)
	// if (bookingWishGroup.isHighlighted) {
	// 	dispatch(computeHighlights())
	// }
}

export const FETCH_BOOKING_WISHES = "FETCH_BOOKING_WISHES"
export const FETCH_BOOKING_WISHES_REQUEST = "FETCH_BOOKING_WISHES_REQUEST"
export const FETCH_BOOKING_WISHES_SUCCESS = "FETCH_BOOKING_WISHES_SUCCESS"
export const FETCH_BOOKING_WISHES_FAILURE = "FETCH_BOOKING_WISHES_FAILURE"
export const fetchBookingWishes = () => (dispatch, getState) => {
	let {
		redComponents: {
			bookingWishesComponent: {
				page,
				customersFilters,
				workersFilters,
				squadsFilters,
				rangeStart,
				rangeEnd,
				statusFilter,
			},
		},
	} = getState()

	let params = {
		page,
		filter_customer_contract_ids: customersFilters.map(entry => entry.customerContractId),
		filter_affiliate_worker_ids: workersFilters.map(entry => entry.affiliateWorkerId),
		filter_squad_ids: squadsFilters,
		filter_status: statusFilter === "all" ? "" : statusFilter,
	}
	if (rangeStart && rangeEnd) {
		params.filter_start_date = rangeStart.format("YYYY-MM-DD")
		params.filter_end_date = rangeEnd.format("YYYY-MM-DD")
	}
	let init = { method: "GET" }
	let url = "/front/affiliates/booking_wishes" + stringifyQuery(params)
	return dispatch(simpleFetch(url, init, FETCH_BOOKING_WISHES))
}

export const SUBMIT_BOOKING_WISH_DATERANGE = "SUBMIT_BOOKING_WISH_DATERANGE"
export const SUBMIT_BOOKING_WISH_DATERANGE_REQUEST = "SUBMIT_BOOKING_WISH_DATERANGE_REQUEST"
export const SUBMIT_BOOKING_WISH_DATERANGE_SUCCESS = "SUBMIT_BOOKING_WISH_DATERANGE_SUCCESS"
export const SUBMIT_BOOKING_WISH_DATERANGE_FAILURE = "SUBMIT_BOOKING_WISH_DATERANGE_FAILURE"
export const submitBookingWishDaterange = ({
	bookingWishGroup,
	from,
	to,
	dayOfWeek,
	fromTime,
	toTime,
	isRecurrentTimerange,
}) => (dispatch, getState) => {
	let data = {
		booking_wish_id: bookingWishGroup.bookingWishes[0].id,
	}
	if (isRecurrentTimerange) {
		data.day_of_week = dayOfWeek
		data.from_time = fromTime.format("HH:mm")
		data.to_time = toTime.format("HH:mm")
	} else {
		data.from = from.format("YYYY-MM-DD")
		data.to = to.format("YYYY-MM-DD")
	}
	let init = { method: "POST", body: JSON.stringify(data) }
	let url = "/front/affiliates/booking_wishes/add_daterange"
	return dispatch(simpleFetch(url, init, SUBMIT_BOOKING_WISH_DATERANGE))
}

export const SUBMIT_REMOVE_BOOKING_WISH_DATERANGE = "SUBMIT_REMOVE_BOOKING_WISH_DATERANGE"
export const SUBMIT_REMOVE_BOOKING_WISH_DATERANGE_REQUEST =
	"SUBMIT_REMOVE_BOOKING_WISH_DATERANGE_REQUEST"
export const SUBMIT_REMOVE_BOOKING_WISH_DATERANGE_SUCCESS =
	"SUBMIT_REMOVE_BOOKING_WISH_DATERANGE_SUCCESS"
export const SUBMIT_REMOVE_BOOKING_WISH_DATERANGE_FAILURE =
	"SUBMIT_REMOVE_BOOKING_WISH_DATERANGE_FAILURE"
export const submitRemoveBookingWishDaterange = ({ bookingWishGroup, wishedDaterangeId }) => (
	dispatch,
	getState
) => {
	let init = {
		method: "POST",
		body: JSON.stringify({
			booking_wish_id: bookingWishGroup.bookingWishes[0].id,
			wished_daterange_id: wishedDaterangeId,
		}),
	}
	let url = "/front/affiliates/booking_wishes/remove_daterange"
	return dispatch(simpleFetch(url, init, SUBMIT_REMOVE_BOOKING_WISH_DATERANGE))
}

export const SUBMIT_REPLACEMENTS = "SUBMIT_REPLACEMENTS"
export const SUBMIT_REPLACEMENTS_REQUEST = "SUBMIT_REPLACEMENTS_REQUEST"
export const SUBMIT_REPLACEMENTS_SUCCESS = "SUBMIT_REPLACEMENTS_SUCCESS"
export const SUBMIT_REPLACEMENTS_FAILURE = "SUBMIT_REPLACEMENTS_FAILURE"
export const submitReplacements = () => async (dispatch, getState) => {
	let {
		redComponents: {
			createBookingsFromHighlightsModalComponent: { bookingWishGroup, replacements },
		},
	} = getState()

	let init = {
		method: "POST",
		body: JSON.stringify({
			replacements_params: replacements.map(entry => ({
				start_time: entry.start.format("HH:mm"),
				end_time: entry.end.format("HH:mm"),
				occurrence_id: entry.occurrenceId,
				timerange_id: entry.timerange.id,
				replacement_date: entry.timerange.start.format("YYYY-MM-DD"),
				booking_wish_id: bookingWishGroup.bookingWishes.find(
					bw => bw.occurrenceToReplace.id === entry.occurrenceId
				).id,
			})),
		}),
	}
	let url = "/front/affiliates/booking_wishes/create_replacement_exceptions"
	let { actionType } = await dispatch(simpleFetch(url, init, SUBMIT_REPLACEMENTS))
	if (actionType === SUBMIT_REPLACEMENTS_SUCCESS) {
		dispatch(bookingWishesStateChange({ showCreateBookingFromHighlightsModal: false }))
		dispatch(fetchBookingWishes())
		dispatch(fetchEventsAgenda())
	}
}

export const SUBMIT_DIRECT_REPLACEMENTS = "SUBMIT_DIRECT_REPLACEMENTS"
export const SUBMIT_DIRECT_REPLACEMENTS_REQUEST = "SUBMIT_DIRECT_REPLACEMENTS_REQUEST"
export const SUBMIT_DIRECT_REPLACEMENTS_SUCCESS = "SUBMIT_DIRECT_REPLACEMENTS_SUCCESS"
export const SUBMIT_DIRECT_REPLACEMENTS_FAILURE = "SUBMIT_DIRECT_REPLACEMENTS_FAILURE"
export const submitDirectReplacements = data => async (dispatch, getState) => {
	let {
		redComponents: {
			bookingWishesComponent: { currentHighlights },
		},
		redData: { bookingWishes },
	} = getState()

	let bookingWishGroupId = Object.keys(currentHighlights)[0]
	let bookingWishGroup = bookingWishes[bookingWishGroupId]

	data.start_date = data.delivery_date
	data.occurrence_to_replace_ids = [bookingWishGroup.singleOccurrenceStagedForReplacement]
	data.booking_wish_group_id = bookingWishGroup.id
	data.day_period = Number(data.recurrence_delay) * 7

	let init = { method: "POST", body: JSON.stringify(data) }
	let url = "/front/affiliates/booking_wishes/create_direct_replacement"
	let { actionType } = await dispatch(simpleFetch(url, init, SUBMIT_DIRECT_REPLACEMENTS))
	if (actionType === SUBMIT_DIRECT_REPLACEMENTS_SUCCESS) {
		dispatch(
			bookingWishesStateChange({
				showDirectBookingForReplacementModal: false,
				currentHighlights: {},
			})
		)
		dispatch(fetchBookingWishes())
		dispatch(fetchEventsAgenda())
	}
}

export const SEARCH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT = "SEARCH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT"
export const SEARCH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT_REQUEST =
	"SEARCH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT_REQUEST"
export const SEARCH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT_SUCCESS =
	"SEARCH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT_SUCCESS"
export const SEARCH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT_FAILURE =
	"SEARCH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT_FAILURE"

export const SEARCH_FULL_MONTH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT =
	"SEARCH_FULL_MONTH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT"
export const SEARCH_FULL_MONTH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT_REQUEST =
	"SEARCH_FULL_MONTH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT_REQUEST"
export const SEARCH_FULL_MONTH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT_SUCCESS =
	"SEARCH_FULL_MONTH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT_SUCCESS"
export const SEARCH_FULL_MONTH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT_FAILURE =
	"SEARCH_FULL_MONTH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT_FAILURE"
export const searchTimeslotsForCurrentHighlight = ({
	minDate = null,
	affiliateWorkerId = null,
} = {}) => async (dispatch, getState) => {
	let {
		redComponents: {
			bookingWishesComponent: {
				currentHighlights,
				squadsFiltersForSearch,
				badgesFilters,
				minReplacementDuration,
			},
			agenda: { fullMonthAffiliateWorkerId },
		},
		redData: { currentAffiliate, bookingWishes },
	} = getState()
	let highlightedWishes = Object.entries(currentHighlights).reduce(
		(acc, [bookingWishGroupId, value]) => {
			if (value.isHighlighted) {
				acc.push(bookingWishGroupId)
			}
			return acc
		},
		[]
	)

	if (
		highlightedWishes.length !== 1 ||
		(currentAffiliate.squads.length >= 2 && squadsFiltersForSearch.length < 1)
	) {
		return
	}

	let bookingWishGroup = bookingWishes[highlightedWishes[0]]

	let actionType = SEARCH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT
	let body = {
		wishes_group_id: bookingWishGroup.id,
		selected_occurrences_to_replace: [bookingWishGroup.singleOccurrenceStagedForReplacement],
		filter_squad_ids: squadsFiltersForSearch,
		filter_badges: badgesFilters,
		min_search_date: minDate ? minDate.format("YYYY-MM-DD") : null,
	}

	if (minReplacementDuration) {
		body.minimum_replacement_duration = Number(minReplacementDuration) * 3600
	}

	if (fullMonthAffiliateWorkerId) {
		body.full_month_for_affiliate_worker_id = fullMonthAffiliateWorkerId
		actionType = SEARCH_FULL_MONTH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT
	}

	let init = { method: "POST", body: JSON.stringify(body) }
	let url = "/front/affiliates/booking_wishes/search_timeslots_for_booking_wish_group"
	let { actionType: responseActionType, data } = await dispatch(
		simpleFetch(url, init, actionType)
	)

	if (
		responseActionType === SEARCH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT_SUCCESS ||
		responseActionType === SEARCH_FULL_MONTH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT_SUCCESS
	) {
		dispatch(highlightTssResults(data.available_events))
		let affiliateWorkerIdsFound = data.available_events.map(entry => entry.affiliate_worker_id)
		dispatch(
			agendaStateChange({
				workersFilters: currentAffiliate.allAffiliateWorkers
					.filter(entry => affiliateWorkerIdsFound.includes(entry.id))
					.map(entry => ({
						affiliateWorkerId: entry.id,
						value: entry.id,
						label: entry.attributes.display_name,
					})),
			})
		)
		dispatch(bookingWishesStateChange({ showAgenda: true, tssResults: data.available_events }))
		if (responseActionType === SEARCH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT_SUCCESS) {
			dispatch(sortAgendasByDistance())
		}
	}
}

export const highlightTssResults = tssResults => (dispatch, getState) => {
	let {
		redData: {
			agenda: { schedules },
		},
	} = getState()
	let newSchedules = schedules.reduce((acc, sched) => {
		let newTimeranges = sched.timeRanges.map(tr => {
			if (!highlightableTimeranges.includes(tr.type)) {
				return tr
			}
			let res = tssResults.find(result => tr.id === result.first_occurrence_id)
			if (res) {
				tr.highlightColors = ["yellow"]
				tr.highlightInfos = [
					{ label: "distance", value: res.distance_in_km },
					{ label: "vehicle_type", value: sched.cleaner.vehicleType },
				]
			}
			return tr
		})
		acc.push({ ...sched, timeRanges: newTimeranges })
		return acc
	}, [])
	dispatch(replaceAgendaSchedules(newSchedules))
}

export const sortAgendasByBadge = () => (dispatch, getState) => {
	let {
		redData: {
			agenda: { schedules },
		},
	} = getState()
	dispatch(
		replaceAgendaSchedules(
			schedules.sort((a, b) => {
				return b.weekData.vacant_seconds_for_week - a.weekData.vacant_seconds_for_week
			})
		)
	)
	dispatch(bookingWishesStateChange({ agendasSorting: "badge" }))
}

export const sortAgendasByDistance = () => (dispatch, getState) => {
	let {
		redData: {
			agenda: { weekStart, schedules },
		},
		redComponents: {
			bookingWishesComponent: { tssResults },
		},
	} = getState()
	let sortedAwIds = tssResults
		.filter(entry => {
			let date = moment(entry.first_occurrence_id.split("__")[1])
			return weekStart.isSame(date, "isoweek")
		})
		.sort((a, b) => a.distance_in_km - b.distance_in_km)
		.map(entry => entry.affiliate_worker_id)
		.filter((entry, index, arr) => arr.indexOf(entry) === index)

	let newSchedules = sortedAwIds.map(entry =>
		schedules.find(sched => sched.cleaner.affiliateWorkerId === entry)
	)
	dispatch(replaceAgendaSchedules(newSchedules))
	dispatch(bookingWishesStateChange({ agendasSorting: "distance" }))
}

export const UPDATE_BOOKING_WISH_GROUP_STATUS = "UPDATE_BOOKING_WISH_GROUP_STATUS"
export const UPDATE_BOOKING_WISH_GROUP_STATUS_REQUEST = "UPDATE_BOOKING_WISH_GROUP_STATUS_REQUEST"
export const UPDATE_BOOKING_WISH_GROUP_STATUS_SUCCESS = "UPDATE_BOOKING_WISH_GROUP_STATUS_SUCCESS"
export const UPDATE_BOOKING_WISH_GROUP_STATUS_FAILURE = "UPDATE_BOOKING_WISH_GROUP_STATUS_FAILURE"
export const updateBookingWishGroupStatus = () => async (dispatch, getState) => {
	let {
		redComponents: {
			bookingWishesComponent: { bookingWishStagedForDismissal, bookingWishStagedForPending },
		},
	} = getState()
	if (!bookingWishStagedForDismissal && !bookingWishStagedForPending) {
		return
	}
	let bookingWish = undefined
	let newStatus = "DISMISSED"
	if (bookingWishStagedForDismissal) {
		bookingWish = bookingWishStagedForDismissal.bookingWishGroup.bookingWishes.find(
			entry => bookingWishStagedForDismissal.occurrenceId === entry.occurrenceToReplace.id
		)
	}
	if (bookingWishStagedForPending) {
		bookingWish = bookingWishStagedForPending.bookingWishGroup.bookingWishes.find(
			entry => bookingWishStagedForPending.occurrenceId === entry.occurrenceToReplace.id
		)
		newStatus = "PENDING"
	}
	let init = {
		method: "POST",
		body: JSON.stringify({ booking_wish_id: bookingWish.id, status: newStatus }),
	}
	let url = "/front/affiliates/booking_wishes/update_status"
	let { actionType } = await dispatch(simpleFetch(url, init, UPDATE_BOOKING_WISH_GROUP_STATUS))
	if (actionType === UPDATE_BOOKING_WISH_GROUP_STATUS_SUCCESS) {
		dispatch(fetchBookingWishes())
		dispatch(
			bookingWishesStateChange({
				bookingWishStagedForDismissal: undefined,
				showDismissConfirmationModal: false,
				showPendingConfirmationModal: false,
			})
		)
	}
}

export const UPDATE_BOOKING_WISH_NOTE = "UPDATE_BOOKING_WISH_NOTE"
export const UPDATE_BOOKING_WISH_NOTE_REQUEST = "UPDATE_BOOKING_WISH_NOTE_REQUEST"
export const UPDATE_BOOKING_WISH_NOTE_SUCCESS = "UPDATE_BOOKING_WISH_NOTE_SUCCESS"
export const UPDATE_BOOKING_WISH_NOTE_FAILURE = "UPDATE_BOOKING_WISH_GROUP_NOTE_FAILURE"
export const updateBookingWishNote = ({ bookingWishGroup, note }) => async (dispatch, getState) => {
	let init = {
		method: "POST",
		body: JSON.stringify({ booking_wish_group_id: bookingWishGroup.id, note: note }),
	}
	let url = "/front/affiliates/booking_wishes/update_note"
	let { actionType } = await dispatch(simpleFetch(url, init, UPDATE_BOOKING_WISH_NOTE))
	if (actionType === UPDATE_BOOKING_WISH_NOTE_SUCCESS) {
		dispatch(fetchBookingWishes())
		dispatch(
			bookingWishesStateChange({
				bookingWishStagedForDismissal: undefined,
				showDismissConfirmationModal: false,
				showPendingConfirmationModal: false,
			})
		)
	}
}
