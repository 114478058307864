import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"

import * as actions from "../actions"

import PootsyCalendar from "./PootsyCalendar"
import {
	PageLayout,
	ContentLayout,
	EmptyDashboardSection,
	DashboardList,
	DashboardCounter,
	DashboardCounterComingSoon,
	SmallSpinnerSection,
} from "./FunctionalDesign"
import { PootsySelectInput } from "./FunctionalInputs"

import { usefulSign } from "../svg/usefulSign"
import { goForIt } from "../svg/goForIt"

class Dashboard extends Component {
	state = {
		workerRankingFilter: "best",
		badCustomersFilter: "paper",
		periodType: "month",
		currentDate: moment().startOf("month"),
		compareBaseDate: moment()
			.startOf("month")
			.subtract(1, "month"),
		compareIntervalOptions: [],
		sodexoFromDate: moment()
			.startOf("month")
			.subtract(1, "month"),
		sodexoToDate: moment().startOf("month"),
		showKPI: true,
		showSodexo: false,
		showQuality: false,
		showClients: false,
	}
	componentDidMount = () => {
		//		this.fetchCustomersSection()
		//		this.fetchQualitySection()
		//
		this.setupCompareIntervalOptions(this.state.periodType)
	}
	fetchCustomersSection = () => {
		let init = { method: "GET" }
		this.props.dispatch(
			actions.simpleFetch(
				"/front/affiliates/dashboard/customers",
				init,
				"DASHBOARD_CUSTOMERS"
			)
		)
	}
	fetchQualitySection = () => {
		let init = { method: "GET" }
		this.props.dispatch(
			actions.simpleFetch("/front/affiliates/dashboard/quality", init, "DASHBOARD_QUALITY")
		)
	}
	fetchSodexoSection = () => {
		let init = { method: "GET" }
		let { sodexoFromDate, sodexoToDate } = this.state
		let url = "/front/affiliates/dashboard/sodexo"
		url += `?from_date=${sodexoFromDate.format("YYYY-MM-DD")}&to_date=${sodexoToDate.format(
			"YYYY-MM-DD"
		)}`
		this.props.dispatch(actions.simpleFetch(url, init, "DASHBOARD_SODEXO"))
	}
	fetchCompanyKPISection = () => {
		let { currentDate, compareBaseDate, periodType } = this.state
		let init = { method: "GET" }
		let url = "/front/affiliates/dashboard/company_kpi"
		let companyKPIStartDate = currentDate.format("YYYY-MM-DD")
		let companyKPIEndDate = moment(currentDate)
			.add(1, periodType)
			.subtract(1, "day")
			.format("YYYY-MM-DD")
		let companyKPICompareStartDate = compareBaseDate.format("YYYY-MM-DD")
		let companyKPICompareEndDate = moment(compareBaseDate)
			.add(1, periodType)
			.subtract(1, "day")
			.format("YYYY-MM-DD")
		url += `?from_date=${companyKPIStartDate}&to_date=${companyKPIEndDate}`
		url += `&from_date_comparison=${companyKPICompareStartDate}&to_date_comparison=${companyKPICompareEndDate}`
		this.props.dispatch(actions.simpleFetch(url, init, "DASHBOARD_COMPANY_KPI"))
	}
	selectPeriodType = e => {
		let { period } = e.target.dataset
		this.setupCompareIntervalOptions(period)
		this.setState({ periodType: period })
	}
	setupCompareIntervalOptions = periodType => {
		let { currentDate, compareBaseDate } = this.state
		let startOf = periodType === "week" ? "isoweek" : periodType
		let limit = moment()
			.startOf(startOf)
			.subtract(1, "year")
		let options = []
		for (let m = moment().startOf(startOf); m.isAfter(limit); m.subtract(1, periodType)) {
			options.push({
				label: this.displayDateFormat(periodType, m),
				value: moment(m).format("YYYY-MM-DD"),
			})
		}
		this.setState(
			{
				compareIntervalOptions: options,
				currentDate: moment(currentDate).startOf(startOf),
				compareBaseDate: moment(compareBaseDate).startOf(startOf),
			},
			() => {
				/* this.fetchCompanyKPISection() */
				this.setState({ showKPI: false })
			}
		)
	}
	displayDateFormat = (periodType, date) => {
		let { t } = this.context
		if (periodType === "week") {
			return `${t("week")} ${date.week()} (${date.format("DD/MM/YYYY")} > ${moment(date)
				.add(1, "week")
				.format("DD/MM/YYYY")})`
		} else if (periodType === "month") {
			return date.format("MMMM YYYY")
		} else if (periodType === "quarter") {
			return `${t("year_quarter")}${date.quarter()} ${date.year()} `
		}
	}
	computeTrend = (oldValue, newValue) => {
		let diff = newValue - oldValue
		let trend = (diff / oldValue) * 100
		trend = isNaN(trend) ? 0 : trend
		return trend
	}
	handleDateChange = e => {
		let { name, value } = e.target
		this.setState({ [name]: moment(value) }, () => {
			this.fetchCompanyKPISection()
		})
	}
	handleSodexoDateChange = changes => {
		this.setState(changes, () => {
			this.fetchSodexoSection()
		})
	}
	goToBookingPage = id => {
		this.props.history.push({ pathname: "/bookings/" + id })
	}
	goToWorkerPage = id => {
		this.props.history.push({ pathname: "cleaners/" + id + "/profile" })
	}
	goToCustomerPage = (id, page, search) => {
		this.props.history.push({ pathname: "customers/" + id + "/" + page, search })
	}
	goToSodexoPage = () => {
		this.props.history.push({ pathname: "sodexo/connection" })
	}
	browseWorkerRankingFilter = () => {
		this.setState(state => ({
			workerRankingFilter: state.workerRankingFilter === "best" ? "worst" : "best",
		}))
	}
	browseBadCustomerFilters = () => {
		this.setState(state => ({
			badCustomersFilter: state.badCustomersFilter === "paper" ? "electronic" : "paper",
		}))
	}
	showKPI = () => {
		let { dashboard } = this.props

		if (!dashboard.companyKPI) {
			this.fetchCompanyKPISection()
		}
		this.setState({ showKPI: true })
	}
	showSodexo = () => {
		let { dashboard } = this.props
		if (!dashboard.sodexo) {
			this.fetchSodexoSection()
		}
		this.setState({ showSodexo: true })
	}
	showQuality = () => {
		let { dashboard } = this.props
		if (!dashboard.quality) {
			this.fetchQualitySection()
		}
		this.setState({ showQuality: true })
	}

	showClients = () => {
		let { dashboard } = this.props
		if (!dashboard.customers) {
			this.fetchCustomersSection()
		}
		this.setState({ showClients: true })
	}

	render() {
		let { t } = this.context
		let { currAffiliate, dashboard, dashboardComponent } = this.props
		let {
			workerRankingFilter,
			badCustomersFilter,
			periodType,
			currentDate,
			compareBaseDate,
			compareIntervalOptions,
			sodexoFromDate,
			sodexoToDate,
		} = this.state
		let SodexoAlternativeContent = (
			<div className="no-sodexo-add-on">
				{goForIt}
				<div className="discover-more-button" onClick={this.goToSodexoPage}>
					{t("discover_more_about_sodexo")}
				</div>
				{usefulSign}
			</div>
		)
		let SodexoHeaderContent = (
			<div className="sodexo-date-pickers">
				<div className="label">{t("from")}</div>
				<PootsyCalendar
					name="sodexoFromDate"
					value={sodexoFromDate}
					onChange={this.handleSodexoDateChange}
					calendarStyle={{ top: "120%", right: 0 }}
					disableDay={current => current.isAfter(sodexoToDate, "day")}
				/>
				<div className="label">{t("to")}</div>
				<PootsyCalendar
					name="sodexoToDate"
					value={sodexoToDate}
					onChange={this.handleSodexoDateChange}
					calendarStyle={{ top: "120%", right: 0 }}
					disableDay={current => current.isBefore(sodexoFromDate, "day")}
				/>
			</div>
		)
		let KPIHeaderContent = (
			<Fragment>
				<div className="period-selector">
					<div
						className={"period" + (periodType === "week" ? " active" : "")}
						data-period="week"
						onClick={this.selectPeriodType}
					>
						{t("week")}
					</div>
					<div
						className={"period" + (periodType === "month" ? " active" : "")}
						data-period="month"
						onClick={this.selectPeriodType}
					>
						{t("month")}
					</div>
					<div
						className={"period" + (periodType === "quarter" ? " active" : "")}
						data-period="quarter"
						onClick={this.selectPeriodType}
					>
						{t("year_quarter")}
					</div>
				</div>
				<div className="dates-selector">
					<PootsySelectInput
						name="currentDate"
						selected={currentDate.format("YYYY-MM-DD")}
						options={compareIntervalOptions}
						onChange={this.handleDateChange}
					/>
					<div className="compared-to">{t("compared_to")}</div>
					<PootsySelectInput
						name="compareBaseDate"
						selected={compareBaseDate.format("YYYY-MM-DD")}
						options={compareIntervalOptions}
						onChange={this.handleDateChange}
					/>
				</div>
			</Fragment>
		)
		let pageHeaderLeft = <h1 className="page-title dashboard">{t("dashboard")}</h1>
		return (
			<PageLayout headerLeft={pageHeaderLeft} customClass="dashboard">
				<ContentLayout customClass="dashboard">
					<div className="dashboard-wrapper">
						{KPIHeaderContent}
						{!this.state.showKPI && (
							<EmptyDashboardSection
								label={t("dashboard_company_kpi_section_title")}
								loadFunction={this.fetchCompanyKPISection}
								isLoading={dashboardComponent.fetchingCompanyKPI}
								showFunction={this.showKPI}
							/>
						)}
						{this.state.showKPI && (
							<SmallSpinnerSection
								label={t("dashboard_company_kpi_section_title")}
								loadFunction={this.fetchCompanyKPISection}
								isLoading={dashboardComponent.fetchingCompanyKPI}
								hideFunction={() => this.setState({ showKPI: false })}
								sectionClass={"dashboard-section"}
							>
								{dashboard.companyKPI && (
									<DashboardCounter
										label={t("dashboard_company_kpi.average_rating")}
										tooltipText={t(
											"dashboard_company_kpi.average_rating.tooltip"
										)}
										count={dashboard.companyKPI.average_rating.value}
										comparedCount={
											dashboard.companyKPI.average_rating.value_comparison
										}
										trend={this.computeTrend(
											dashboard.companyKPI.average_rating.value_comparison,
											dashboard.companyKPI.average_rating.value
										)}
									/>
								)}
								{dashboard.companyKPI && (
									<DashboardCounterComingSoon
										label={t("dashboard_company_kpi.lost_hours")}
									/>
								)}
								{dashboard.companyKPI && (
									<DashboardCounterComingSoon
										label={t("dashboard_company_kpi.extra_hours")}
									/>
								)}
								{dashboard.companyKPI && (
									<DashboardCounter
										label={t("dashboard_company_kpi.worked_hours")}
										tooltipText={t(
											"dashboard_company_kpi.worked_hours.tooltip"
										)}
										count={dashboard.companyKPI.worked_hours.value}
										comparedCount={
											dashboard.companyKPI.worked_hours.value_comparison
										}
										trend={this.computeTrend(
											dashboard.companyKPI.worked_hours.value_comparison,
											dashboard.companyKPI.worked_hours.value
										)}
									/>
								)}
							</SmallSpinnerSection>
						)}
						{SodexoHeaderContent}
						{!this.state.showSodexo && (
							<EmptyDashboardSection
								label={t("dashboard_sodexo_section_title")}
								isLoading={dashboardComponent.fetchingSodexo}
								alternativeContent={
									!currAffiliate.sodexoConnectionStatus.authToken &&
									SodexoAlternativeContent
								}
								showFunction={this.showSodexo}
							/>
						)}
						{this.state.showSodexo && (
							<SmallSpinnerSection
								label={t("dashboard_sodexo_section_title")}
								isLoading={dashboardComponent.fetchingSodexo}
								alternativeContent={
									!currAffiliate.sodexoConnectionStatus.authToken &&
									SodexoAlternativeContent
								}
								hideFunction={() => this.setState({ showSodexo: false })}
								sectionClass={"dashboard-section"}
							>
								{/*
								<div className="coming-soon">{t('coming_soon')}</div>
								*/}
								{dashboard.sodexo && (
									<Fragment>
										<DashboardCounter
											label={t("dashboard_sodexo.missing_psv")}
											tooltipText={t("dashboard_sodexo.missing_psv.tooltip")}
											count={dashboard.sodexo.missing_psv}
										/>
										<DashboardCounter
											label={t("dashboard_sodexo.missing_esv")}
											tooltipText={t("dashboard_sodexo.missing_esv.tooltip")}
											count={dashboard.sodexo.unpaid_esv}
										/>
										<DashboardCounter
											label={t("dashboard_sodexo.opposed_esv")}
											tooltipText={t("dashboard_sodexo.opposed_esv.tooltip")}
											count={dashboard.sodexo.opposed_esv}
										/>
										<DashboardCounter
											label={t("dashboard_sodexo.unvalidated_esv")}
											tooltipText={t(
												"dashboard_sodexo.unvalidated_esv.tooltip"
											)}
											count={dashboard.sodexo.unvalidated_esv}
										/>
									</Fragment>
								)}
							</SmallSpinnerSection>
						)}
						{!this.state.showQuality && (
							<EmptyDashboardSection
								label={t("dashboard_quality_section_title")}
								isLoading={dashboardComponent.fetchingQuality}
								showFunction={this.showQuality}
							/>
						)}
						{this.state.showQuality && (
							<SmallSpinnerSection
								label={t("dashboard_quality_section_title")}
								isLoading={dashboardComponent.fetchingQuality}
								hideFunction={() => this.setState({ showQuality: false })}
								sectionClass={"dashboard-section"}
							>
								<DashboardList
									label={t("dashboard_quality.worker_rankings")}
									link="/cleaners?sortByRatings=desc"
									tooltipText={t("dashboard_quality.worker_rankings.tooltip")}
									headerContent={
										<Fragment>
											<div
												className={
													"worker-ranking-button" +
													(workerRankingFilter === "best"
														? " active"
														: "")
												}
												onClick={this.browseWorkerRankingFilter}
											>
												{t("ranking_top")}
											</div>
											<div
												className={
													"worker-ranking-button" +
													(workerRankingFilter === "worst"
														? " active"
														: "")
												}
												onClick={this.browseWorkerRankingFilter}
											>
												{t("ranking_flop")}
											</div>
										</Fragment>
									}
								>
									{dashboard.quality &&
										dashboard.quality.worker_rankings[workerRankingFilter].map(
											entry => (
												<div
													key={entry.worker_id}
													className="problem-booking"
													onClick={this.goToWorkerPage.bind(
														this,
														entry.worker_id
													)}
												>
													<div className="column">
														{entry.worker_name}
													</div>
													<div className="column">
														{entry.review_average}
													</div>
												</div>
											)
										)}
								</DashboardList>
								<DashboardList
									label={t("dashboard_quality.bad_reviews_bookings")}
									tooltipText={t(
										"dashboard_quality.bad_reviews_bookings.tooltip"
									)}
								>
									{dashboard.quality &&
										dashboard.quality.bookings_with_bad_reviews.map(entry => (
											<div
												key={entry.id}
												className="bad-review-booking"
												onClick={this.goToBookingPage.bind(this, entry.id)}
											>
												<div>
													{" "}
													{moment(entry.scheduled_date).format(
														"DD/MM/YY"
													)}{" "}
												</div>
												<div> {entry.customer_name} </div>
												<div> {entry.rating} </div>
											</div>
										))}
								</DashboardList>
								<DashboardList
									label={t("dashboard_quality.problem_bookings")}
									tooltipText={t("dashboard_quality.problem_bookings.tooltip")}
								>
									{dashboard.quality &&
										dashboard.quality.bookings_with_problem.map(entry => (
											<div
												key={entry.id}
												className="problem-booking"
												onClick={this.goToBookingPage.bind(this, entry.id)}
											>
												<div>
													{" "}
													{moment(entry.scheduled_date).format(
														"DD/MM/YY"
													)}{" "}
												</div>
												<div> {entry.customer_name} </div>
											</div>
										))}
								</DashboardList>
							</SmallSpinnerSection>
						)}
						{!this.state.showClients && (
							<EmptyDashboardSection
								label={t("dashboard_customers_section_title")}
								isLoading={dashboardComponent.fetchingCustomers}
								showFunction={this.showClients}
							/>
						)}
						{this.state.showClients && (
							<SmallSpinnerSection
								label={t("dashboard_customers_section_title")}
								isLoading={dashboardComponent.fetchingCustomers}
								hideFunction={() => this.setState({ showClients: false })}
								sectionClass={"dashboard-section"}
							>
								<DashboardList
									label={t("dashboard_customers.bad_customers")}
									tooltipText={t("dashboard_customers.bad_customers.tooltip")}
									headerContent={
										<Fragment>
											<div
												className={
													"bad-customer-button" +
													(badCustomersFilter === "electronic"
														? " active"
														: "")
												}
												onClick={this.browseBadCustomerFilters}
											>
												{t("electronic")}
											</div>
											<div
												className={
													"bad-customer-button" +
													(badCustomersFilter === "paper"
														? " active"
														: "")
												}
												onClick={this.browseBadCustomerFilters}
											>
												{t("paper")}
											</div>
										</Fragment>
									}
								>
									{dashboard.customers &&
										(badCustomersFilter === "electronic" ? (
											<div className="coming-soon">{t("coming_soon")}</div>
										) : (
											dashboard.customers.bad_customers[
												badCustomersFilter
											].map(entry => (
												<div
													key={entry.customer_id}
													className="bad-customer"
													onClick={this.goToCustomerPage.bind(
														this,
														entry.customer_id,
														"payments",
														// Those from and to parameters were chosen to align with what PBE does
														// so the payment page of the customer will display the same amount
														// of missing vouchers
														`?from=${moment()
															.subtract(30, "days")
															.format(
																"YYYY-MM-DD"
															)}&to=${moment().format("YYYY-MM-DD")}`
													)}
												>
													<div className="column">
														{entry.customer_name}
													</div>
													<div className="column">
														{entry.missing_vouchers}
													</div>
												</div>
											))
										))}
								</DashboardList>
								<DashboardList
									label={t("dashboard_customers.happy_customers")}
									link="/customers?sortByRatings=desc"
									tooltipText={t("dashboard_customers.happy_customers.tooltip")}
								>
									{dashboard.customers &&
										dashboard.customers.happy_customers.map(entry => (
											<div
												key={entry.customer_id}
												className="happy-customer"
												onClick={this.goToCustomerPage.bind(
													this,
													entry.customer_id,
													"profile",
													""
												)}
											>
												<div className="column">
													{" "}
													{entry.customer_name}{" "}
												</div>
												<div className="column"> {entry.rating} </div>
											</div>
										))}
								</DashboardList>
								<DashboardList
									label={t("dashboard_customers.unhappy_customers")}
									link="/customers?sortByRatings=asc"
									tooltipText={t("dashboard_customers.unhappy_customers.tooltip")}
								>
									{dashboard.customers &&
										dashboard.customers.unhappy_customers.map(entry => (
											<div
												key={entry.customer_id}
												className="unhappy-customer"
												onClick={this.goToCustomerPage.bind(
													this,
													entry.customer_id,
													"profile",
													""
												)}
											>
												<div className="column">
													{" "}
													{entry.customer_name}{" "}
												</div>
												<div className="column"> {entry.rating} </div>
											</div>
										))}
								</DashboardList>
							</SmallSpinnerSection>
						)}
					</div>
				</ContentLayout>
			</PageLayout>
		)
	}
}

Dashboard.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currAffiliate: state.redData.currentAffiliate,
	dashboard: state.redData.dashboard,
	dashboardComponent: state.redComponents.dashboardComponent,
})
export default connect(mapStateToProps)(Dashboard)
