import { stringifyQuery, parseQuery } from "../helpers/qs"
import { history } from "../history"

export const URL_QUERY_CHANGE = "URL_QUERY_CHANGE"
export const handleURLQueryChanges = changes => ({ type: URL_QUERY_CHANGE, changes })

export const RESET_QUERY = "RESET_QUERY"
export const resetQuery = () => ({ type: RESET_QUERY })

export const setQueries = (newQueries, newRealQuery, newUrlQuery) => (dispatch, getState) => {
	dispatch(
		handleURLQueryChanges({
			queries: newQueries,
			realQuery: newRealQuery,
			urlQuery: newUrlQuery,
		})
	)
	history.push({ pathname: window.location.pathname, search: stringifyQuery(newUrlQuery) })
}

export const replaceQueries = newQueries => (dispatch, getState) => {
	let newUrlQuery = {},
		newRealQuery = {}
	Object.keys(newQueries).forEach(entry => {
		let query = newQueries[entry]
		if (query.defaultValue || query.mustBeSet) {
			newUrlQuery[query.urlName] = query.defaultValue
			newRealQuery[query.queryName] = query.defaultValue
		}
	})
	dispatch(setQueries(newQueries, newRealQuery, newUrlQuery))
}

export const addQueries = newQueries => (dispatch, getState) => {
	let {
		currentURLQuery: { queries, urlQuery, realQuery },
	} = getState()
	let currentURLQuery = parseQuery(window.location.search)
	newQueries = { ...queries, ...newQueries }
	let newUrlQuery = { ...urlQuery },
		newRealQuery = { ...realQuery }
	Object.keys(newQueries).forEach(entry => {
		let query = newQueries[entry]
		let currentValue = currentURLQuery[query.urlName]
		if ((query.defaultValue || query.mustBeSet) && !currentValue) {
			newUrlQuery[query.urlName] = query.defaultValue
			newRealQuery[query.queryName] = query.defaultValue
		} else if (currentValue) {
			newRealQuery[query.queryName] = currentValue
			newUrlQuery[query.urlName] = currentValue
		}
	})
	dispatch(setQueries(newQueries, newRealQuery, newUrlQuery))
}

export const updateQuery =
	(changes, newUpdates = true) =>
	(dispatch, getState) => {
		let {
			currentURLQuery: { queries, realQuery, urlQuery },
		} = getState()

		let newRealQuery, newUrlQuery
		if (Array.isArray(changes)) {
			let realQueryChanges = changes.reduce((acc, entry) => {
				if (!queries[entry.name]) {
					console.log(entry, queries)
				}
				if (entry.value) {
					acc[queries[entry.name].queryName] = entry.value
				}
				return acc
			}, {})
			let urlQueryChanges = changes.reduce((acc, entry) => {
				if (entry.value) {
					acc[queries[entry.name].queryName] = entry.value
				}
				acc[queries[entry.name].urlName] = entry.value
				return acc
			}, {})
			if (newUpdates) {
				newRealQuery = { ...realQuery, ...realQueryChanges }
				newUrlQuery = { ...urlQuery, ...urlQueryChanges }
			} else {
				newRealQuery = realQueryChanges
				newUrlQuery = urlQueryChanges
			}
		} else {
			newRealQuery = {
				...realQuery,
				[queries[changes.name].queryName]: changes.value ? changes.value : undefined,
			}
			newUrlQuery = {
				...urlQuery,
				[queries[changes.name].urlName]: changes.value ? changes.value : undefined,
			}
		}
		dispatch(handleURLQueryChanges({ realQuery: newRealQuery, urlQuery: newUrlQuery }))
		history.push({ pathname: window.location.pathname, search: stringifyQuery(newUrlQuery) })
	}

export const STORE_QUERY = "STORE_QUERY"
export const storeQuery = componentName => (dispatch, getState) => {
	let { currentURLQuery } = getState()
	dispatch({
		data: JSON.stringify(currentURLQuery),
		componentName: componentName,
		type: STORE_QUERY,
	})
}
