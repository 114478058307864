import React, { useContext, useState } from "react"
import moment from "moment"

import { LocaleContext } from "../index.js"
import {
	useSubmitBookingWishDaterange,
	useSubmitRemoveBookingWishDaterange,
	useToggleBookingWishHighlighting,
	useCheckReplacedOccurrenceHandler,
	useStageOccurrenceForDismissalHandler,
	useStageOccurrenceForPendingHandler,
	useSubmitBookingWishNoteUpdate,
	// useToggleCreateBookingsFromHighlightsModal,
} from "./BookingWishesHooks"
// import { useToggleDirectBookingForReplacementModal } from "./DirectBookingForReplacementModalHooks"

import { LabeledDateTime, PootsyButton, Tooltip, NoCSSInfoBox } from "./FunctionalDesign"
import { PootsyCheckboxInput, PootsySelectInput, PootsyTextArea } from "./FunctionalInputs"
import ModalLayout from "./ModalLayout"
// import { ReplacementIcon } from "../svg/replacementIcon"

import monitorIcon from "../static/images/monitor-icon.png"
import fileIcon from "../static/images/file-icon.png"

const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

const AddBookingWishDaterangeModal = ({ bookingWishGroup, closeModal }) => {
	const t = useContext(LocaleContext)
	const [fromDate, handleFromDateChange] = useState(moment())
	const [toDate, handleToDateChange] = useState(moment())
	const [fromTime, handleFromTimeChange] = useState(moment().set({ minute: 0 }))
	const [toTime, handleToTimeChange] = useState(moment().set({ minute: 0 }))
	const [dayOfWeek, handleDayOfWeekChange] = useState(undefined)
	const [isRecurrentTimerange, handleIsRecurrentTimerangeChange] = useState(false)
	const submitDaterange = useSubmitBookingWishDaterange(
		bookingWishGroup,
		fromDate,
		toDate,
		dayOfWeek,
		fromTime,
		toTime,
		isRecurrentTimerange,
		closeModal
	)
	return (
		<ModalLayout
			customClass="AddBookingWishDaterangeModal"
			title={t("add_booking_wish_daterange_modal_title")}
			closeModal={closeModal}
			buttons={[
				<PootsyButton key="a" text={t("confirm")} onClick={submitDaterange} />,
				<PootsyButton key="b" text={t("cancel")} theme="cancel" onClick={closeModal} />,
			]}
		>
			<PootsyCheckboxInput
				label={t("create_recurrent_timerange")}
				name="rec-tr"
				onChange={() => handleIsRecurrentTimerangeChange(!isRecurrentTimerange)}
			/>
			{!isRecurrentTimerange && (
				<div className="dates">
					<LabeledDateTime
						label={t("from")}
						showTimePicker={false}
						datePickerProps={{
							value: fromDate,
							name: "fromDate",
							onChange: changes => handleFromDateChange(changes.fromDate),
						}}
					/>
					<LabeledDateTime
						label={t("to")}
						showTimePicker={false}
						datePickerProps={{
							value: toDate,
							name: "toDate",
							onChange: changes => handleToDateChange(changes.toDate),
							disableDay: current => current.isBefore(fromDate, "day"),
						}}
					/>
					<PootsyButton
						text={t("remove_dates")}
						size="small"
						onClick={() => {
							handleFromDateChange(undefined)
							handleToDateChange(undefined)
						}}
					/>
				</div>
			)}
			{isRecurrentTimerange && (
				<div className="day-of-week">
					<PootsySelectInput
						name="dayOfWeek"
						defaultText={t("day_of_week")}
						selected={dayOfWeek}
						onChange={e => handleDayOfWeekChange(e.target.value)}
						options={[
							{ label: t("monday"), value: 1 },
							{ label: t("tuesday"), value: 2 },
							{ label: t("wednesday"), value: 3 },
							{ label: t("thursday"), value: 4 },
							{ label: t("friday"), value: 5 },
							{ label: t("saturday"), value: 6 },
							{ label: t("sunday"), value: 0 },
						]}
					/>
					<LabeledDateTime
						label={t("from_time")}
						showDatePicker={false}
						timePickerProps={{
							value: fromTime,
							name: "fromTime",
							onChange: changes => handleFromTimeChange(changes.fromTime),
						}}
					/>
					<LabeledDateTime
						label={t("to_time")}
						showDatePicker={false}
						timePickerProps={{
							value: toTime,
							name: "toTime",
							filterHours: h => h >= fromTime.hours(),
							filterMinutes: m =>
								fromTime.hours() < toTime.hours()
									? m >= 0 && m <= 59
									: m > fromTime.minute(),
							onChange: changes => handleToTimeChange(changes.toTime),
						}}
					/>
				</div>
			)}
			<div className="summary"></div>
		</ModalLayout>
	)
}

const BookingWish = ({ bookingWishGroup, currentHighlights, expanded }) => {
	const t = useContext(LocaleContext)
	const [showAddDaterangeModal, toggleAddDaterangeModal] = useState(false)
	const [showNoteTooltip, toggleNoteTooltip] = useState(false)
	const [currentNote, setCurrentNote] = useState(bookingWishGroup.bookingWishes[0].note || "")

	let customer = bookingWishGroup.bookingWishes[0].customer
	let worker = bookingWishGroup.bookingWishes[0].worker
	let note = bookingWishGroup.bookingWishes[0].note
	let firstOccurrenceToReplace = bookingWishGroup.bookingWishes[0].occurrenceToReplace
	let currentHighlight = currentHighlights[bookingWishGroup.id]
	let isHighlighted = !!currentHighlight && currentHighlight.isHighlighted
	let color = currentHighlight && currentHighlight.color
	const handleRemoveWishedDaterange = useSubmitRemoveBookingWishDaterange(bookingWishGroup)
	const toggleBookingWishHighlight = useToggleBookingWishHighlighting()
	const handleCheckReplacedOccurrenceDate = useCheckReplacedOccurrenceHandler()
	const handleStageOccurrenceForDismissal = useStageOccurrenceForDismissalHandler(
		bookingWishGroup
	)
	const handleStageOccurrenceForPending = useStageOccurrenceForPendingHandler(bookingWishGroup)
	const updateBookingWishNote = useSubmitBookingWishNoteUpdate(
		bookingWishGroup,
		currentNote,
		toggleNoteTooltip
	)

	let occurrenceStagedForReplacement = bookingWishGroup.replacedOccurrencesDates.find(
		entry => entry.id === bookingWishGroup.singleOccurrenceStagedForReplacement
	)

	return (
		<div
			className={"BookingWish " + (expanded ? "expanded" : "")}
			onClick={() => {
				if (!expanded && !!occurrenceStagedForReplacement)
					toggleBookingWishHighlight(bookingWishGroup, isHighlighted)
			}}
		>
			<div className="top" style={{ background: color ? color : undefined }}>
				<div className="occurrence-to-replace">
					<div className="worker-customer">
						<NoCSSInfoBox label={t("customer")} value={customer.display_name} />
						<NoCSSInfoBox label={t("worker")} value={worker.display_name} />
						<Tooltip>
							<div className="tel">{customer.telephone || t("no_phone_number")}</div>
						</Tooltip>
					</div>
					<div className="note">
						<div onClick={() => toggleNoteTooltip(!showNoteTooltip)}>{t("note")}</div>
						{showNoteTooltip && (
							<div className="note-container">
								<PootsyTextArea
									name="note"
									value={currentNote}
									onChange={e => setCurrentNote(e.target.value)}
								/>
								<PootsyButton
									text={t("save")}
									onClick={updateBookingWishNote}
									size="small"
								/>
							</div>
						)}
						{note && !showNoteTooltip && <Tooltip>{note}</Tooltip>}
					</div>
					<div className="details">
						<div className="zip">{firstOccurrenceToReplace.address.zip}</div>
						<div className="time">
							{firstOccurrenceToReplace.startTime.format("HH:mm")} >{" "}
							{firstOccurrenceToReplace.endTime.format("HH:mm")}
						</div>
						<div className="voucher-type">
							<img
								alt="presentation"
								src={
									firstOccurrenceToReplace.voucherType &&
									firstOccurrenceToReplace.voucherType.toLowerCase() ===
										"electronic"
										? monitorIcon
										: fileIcon
								}
							/>
							<Tooltip>
								<div className="vt">{firstOccurrenceToReplace.voucherType}</div>
							</Tooltip>
						</div>
						{bookingWishGroup.trigger && (
							<div className="trigger">
								<NoCSSInfoBox
									label={t("bw_reason")}
									value={t(bookingWishGroup.trigger.trigger_type)}
								/>
								<div className="trigger-tooltip">
									<NoCSSInfoBox
										label={t("bw_trigger_type")}
										value={t(bookingWishGroup.trigger.trigger_type)}
									/>
									{bookingWishGroup.trigger.display_fields
										.filter(e => e.value)
										.map(e => (
											<NoCSSInfoBox
												key={e.label}
												label={t(e.label)}
												value={t(e.value)}
											/>
										))}
								</div>
							</div>
						)}
						{occurrenceStagedForReplacement && (
							<div className="selected-occurrence-date">
								{occurrenceStagedForReplacement.date.format("DD/MM/YY")}
							</div>
						)}
					</div>
				</div>
				<div className="actions">
					<PootsyButton
						name={"bookingwishid__" + bookingWishGroup.id}
						text={t("highlight")}
						size="small"
						theme="fadedGreen"
						onClick={() => toggleBookingWishHighlight(bookingWishGroup, isHighlighted)}
						// checked={isHighlighted}
						disabled={!occurrenceStagedForReplacement}
					/>
					{/*
						<PootsyButton
							text={t("add_custom_booking_from_agenda_highlights")}
							size="small"
							theme="green"
							disabled={!isHighlighted}
							onClick={() => toggleDirectBookingForReplacementModal(bookingWishGroup)}
						/>
						<PootsyButton
							text={t("add_booking_from_agenda_highlights")}
							size="small"
							theme="green"
							disabled={!isHighlighted}
							onClick={() => toggleCreateBookingsFromHighlightsModal(bookingWishGroup)}
						/>
						<PootsyCheckboxInput
							name={"bookingwishid__" + bookingWishGroup.id}
							label={"highlight"}
							onChange={() => toggleBookingWishHighlight(bookingWishGroup, isHighlighted)}
							checked={isHighlighted}
							disabled={bookingWishGroup.bookingWishes[0].status !== "PENDING"}
						/>
					*/}
				</div>
			</div>
			<div className="bottom">
				{bookingWishGroup.replacedOccurrencesDates.length > 0 && (
					<div className="replaced-occurrences">
						<div className="title">{t("occurrences_to_replace")}:</div>
						<div className="impacted-occurrences">
							{bookingWishGroup.bookingWishes.map(e => (
								<div
									key={e.id}
									className={
										"impacted-occurrence " +
										(bookingWishGroup.singleOccurrenceStagedForReplacement ===
										e.occurrenceToReplace.id
											? "selected"
											: "")
									}
									onClick={() =>
										handleCheckReplacedOccurrenceDate(
											e.occurrenceToReplace.id,
											bookingWishGroup
										)
									}
								>
									<div className="date">
										{t(e.occurrenceToReplace.date.format("DD/MM/YY"))}
									</div>
									<div className="status">{t(e.status.toLowerCase())}</div>
									{e.status === "PENDING" && (
										<div
											className="dismiss-occurrence"
											data-id={e.occurrenceToReplace.id}
											onClick={handleStageOccurrenceForDismissal}
										>
											x
										</div>
									)}
									{e.status === "DISMISSED" && (
										<div
											className="pending-occurrence"
											data-id={e.occurrenceToReplace.id}
											onClick={handleStageOccurrenceForPending}
										>
											-> {t("PENDING".toLowerCase())}
										</div>
									)}
								</div>
							))}
						</div>
					</div>
				)}
				<div className="wished-dateranges">
					<div className="title">
						{t("wished_dateranges")}
						<div className="plus-button" onClick={() => toggleAddDaterangeModal(true)}>
							+
						</div>
					</div>
					{bookingWishGroup.allWishedDateranges.length === 0 && (
						<div className="no-dateranges">{t("no_booking_wished_dateranges")}</div>
					)}
					<div className="list">
						{bookingWishGroup.allWishedDateranges.map(entry => (
							<div key={entry.id} className="wished-daterange">
								{entry.isRecurrent ? (
									<div className="week-timerange">
										<div className="day-of-week">
											{t(daysOfWeek[entry.dayOfWeek])} -&nbsp;
										</div>
										<div>
											{t("from")} {entry.fromTime.format("HH:mm")} {t("to")}{" "}
											{entry.toTime.format("HH:mm")}
										</div>
									</div>
								) : (
									<div className="daterange">
										{entry.from.format("DD/MM/YY")} -{" "}
										{entry.to.format("DD/MM/YY")}
									</div>
								)}
								<div
									className="remove-button"
									onClick={() => handleRemoveWishedDaterange(entry.id)}
								>
									x
								</div>
							</div>
						))}
					</div>
				</div>
				{showAddDaterangeModal && (
					<AddBookingWishDaterangeModal
						bookingWishGroup={bookingWishGroup}
						closeModal={() => toggleAddDaterangeModal(false)}
					/>
				)}
			</div>
		</div>
	)
}

export default BookingWish
