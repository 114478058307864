import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { history } from "../history"
import * as actions from "../actions"

import {
	ContentLayout,
	OptionBox,
	PootsyButton,
	SimpleLine,
	PootsySubHeading,
} from "./FunctionalDesign"
import ModalLayout from "./ModalLayout"
import { PootsyTextInput, PootsyTextArea } from "./FunctionalInputs"

class CustomerLinkedAccounts extends Component {
	state = {
		queuedCall: "",
		showCreateLinkModal: false,
		customerSearchValue: "",
		chosenCustomer: {},
		linkNote: "",
	}
	componentWillUnmount = () => {
		this.resetModal()
	}
	resetModal = () => {
		this.props.dispatch(actions.resetCustomerSuggestions())
		clearTimeout(this.state.queuedCall)
		this.setState({ chosenCustomer: {}, customerSearchValue: "" })
	}
	toggleCreateLinkModal = () => {
		this.resetModal()
		this.setState(state => ({ showCreateLinkModal: !state.showCreateLinkModal }))
	}
	handleSearchCustomers = e => {
		let { value } = e.target
		let { dispatch } = this.props
		if (value === "") {
			dispatch(actions.resetCustomerSuggestions())
			this.setState({ customerSearchValue: "" })
			return
		}
		this.scheduleApiCall(value)
		this.setState({ customerSearchValue: value })
	}
	scheduleApiCall = value => {
		let { queuedCall } = this.state
		clearTimeout(queuedCall)
		let futureCall
		if (value.length < 3) {
			return
		} else {
			futureCall = setTimeout(() => this.fetchCustomerSuggestions(value), 800)
		}
		this.setState({ queuedCall: futureCall })
	}
	fetchCustomerSuggestions = search => {
		let query = "?filter_active=active&filter_name=" + search
		let init = { method: "GET" }
		this.props.dispatch(
			actions.simpleFetch(
				"/front/affiliates/customer_contracts" + query,
				init,
				"CUSTOMERSUGGESTION"
			)
		)
	}
	handleCustomerClick = customer => {
		let { dispatch } = this.props
		this.setState({ chosenCustomer: customer, customerSearchValue: "" })
		dispatch(actions.resetCustomerSuggestions())
	}
	handleNoteChange = e => {
		e.stopPropagation()
		this.setState({ linkNote: e.target.value })
	}
	goToCustomer = id => {
		history.push("/customers/" + id + "/profile")
	}
	submitLinkCustomer = () => {
		let {
			currentCustomer: { id },
		} = this.props
		let { chosenCustomer, linkNote } = this.state
		let init = {
			method: "POST",
			body: JSON.stringify({ other_customer_contract_id: chosenCustomer.id, note: linkNote }),
		}
		let url = "/front/affiliates/customer_contracts/" + id + "/linked_customer_contracts"
		chosenCustomer.attributes.note = linkNote
		this.props.dispatch(
			actions.simpleFetch(url, init, "SUBMIT_LINK_CUSTOMER", {
				linkedCustomer: chosenCustomer,
			})
		)
		this.toggleCreateLinkModal()
	}
	submitRemoveCustomerLink = e => {
		let { id } = e.target.dataset
		let { currentCustomer } = this.props
		let init = { method: "DELETE" }
		let url =
			"/front/affiliates/customer_contracts/" +
			currentCustomer.id +
			"/linked_customer_contracts/" +
			id
		this.props.dispatch(
			actions.simpleFetch(url, init, "SUBMIT_REMOVE_LINK_CUSTOMER", { toRemoveID: id })
		)
	}
	render() {
		let { t } = this.context
		let { customerSuggestions, currentCustomer } = this.props
		let { showCreateLinkModal, customerSearchValue, chosenCustomer, linkNote } = this.state
		let optionsBoxes = (
			<OptionBox boxTitle={t("customer_bio")}>
				<div className="known-workers">
					<div className="known-workers-title">{t("known_workers_title")}</div>
					{currentCustomer.knownWorkers &&
						currentCustomer.knownWorkers.all &&
						currentCustomer.knownWorkers.all.map(kw => (
							<div key={kw.id} className="known-worker">
								{kw.display_name}
								{currentCustomer.knownWorkers.active.map(kwa => kwa.id).includes(kw)
									? t("active_known_worker")
									: ""}
							</div>
						))}
				</div>
				<PootsyButton
					text={t("create_customer_link_button")}
					onClick={this.toggleCreateLinkModal}
				/>
			</OptionBox>
		)
		let createLinkModalButtons = [
			<PootsyButton
				key="a"
				text={t("submit")}
				disabled={chosenCustomer.id ? false : true}
				onClick={this.submitLinkCustomer}
			/>,
			<PootsyButton
				key="b"
				text={t("cancel")}
				theme="cancel"
				onClick={this.toggleCreateLinkModal}
			/>,
		]
		return (
			<ContentLayout optionsBoxes={optionsBoxes} customClass="customer-linked-accounts">
				<div className="linked-customers">
					{currentCustomer.linkedCustomers && (
						<PootsySubHeading text={t("linked_customers_title")} />
					)}
					{currentCustomer.linkedCustomers &&
						currentCustomer.linkedCustomers.length === 0 && (
							<div className="no-linked-accounts">{t("no_linked_accounts")}</div>
						)}
					{currentCustomer.linkedCustomers &&
						currentCustomer.linkedCustomers.map(lc => (
							<SimpleLine key={lc.id}>
								<div>
									<span
										className="customer-line-name"
										onClick={this.goToCustomer.bind(this, lc.id)}
									>
										{lc.attributes.display_name}
									</span>
								</div>
								<div className="customer-line-contact">
									<span className="customer-line-address">
										{lc.attributes.residences &&
											lc.attributes.residences.data &&
											lc.attributes.residences.data[0] &&
											lc.attributes.residences.data[0].attributes.address}
									</span>
								</div>
								<div className="linked-customer-note">{lc.attributes.note}</div>
								<div className="linked-customer-remove-button">
									<PootsyButton
										text={t("remove_customer_link")}
										theme="red"
										onClick={this.submitRemoveCustomerLink}
										dataset={{ "data-id": lc.id }}
									/>
								</div>
							</SimpleLine>
						))}
				</div>
				{showCreateLinkModal && (
					<ModalLayout
						formDivClass="create-customer-link-modal"
						title={t("create_customer_link_modal_title")}
						closeModal={this.toggleCreateLinkModal}
						buttons={createLinkModalButtons}
					>
						<div className="customer-search-box">
							<PootsyTextInput
								label={t("search_customers")}
								onChange={this.handleSearchCustomers}
								value={customerSearchValue}
							/>
							{customerSuggestions.length > 0 && (
								<div className="customer-suggestions">
									{customerSuggestions
										.filter(entry => entry.id !== currentCustomer.id)
										.map(cs => (
											<div
												key={cs.id}
												className="customer-suggestion"
												onClick={this.handleCustomerClick.bind(this, cs)}
											>
												<div className="customer-name">
													{cs.attributes.display_name}
												</div>
											</div>
										))}
								</div>
							)}
						</div>
						{chosenCustomer.id && (
							<div className="chosen-customer">
								{t("customers_to_be_linked_confirmation")}
								<div className="customers-to-be-linked">
									<div>{chosenCustomer.attributes.display_name}</div>
									<div>></div>
									<div>{currentCustomer.displayName}</div>
								</div>
							</div>
						)}
						<PootsyTextArea
							label={t("note")}
							value={linkNote}
							onChange={this.handleNoteChange}
						/>
					</ModalLayout>
				)}
			</ContentLayout>
		)
	}
}

CustomerLinkedAccounts.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currentCustomer: state.redData.currentCustomer,
	customerSuggestions: state.redData.customerSuggestions,
})
export default connect(mapStateToProps)(CustomerLinkedAccounts)
