import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment"

import { PootsyButton, LabeledDateTime } from "./FunctionalDesign"


export default class WorkerContractSavingCard extends Component {
	render = () => {
		let { t } = this.context
		let {
			currentEditedKeys,
			showRequired,
			currentContract,
			handleFormChanges,
			handleUpdateWorkerContract,
			resetWorkerContractData,
			forceWorkerContractUpdate,
			canSaveContract,
		} = this.props
		let startDateIsEdited = currentEditedKeys.includes("startDate")
		let endDateIsEdited = currentEditedKeys.includes("endDate")

		let disableSaving =
			currentContract.temporalStatus === "past" || startDateIsEdited || endDateIsEdited
		return (
			<div
				className={
					"worker-contract-saving-card" + (currentEditedKeys.length > 0 ? " active" : "")
				}
			>
				<div className="section label">
					<div className="description">{t("notif_unsaved_changes")}</div>
					<PootsyButton
						text={t("reset_changes")}
						theme="cancel"
						onClick={resetWorkerContractData}
					/>
				</div>
				<div className={"section save" + (disableSaving ? " disabled" : "")}>
					<div className="description">{t("notif_unsaved_changes_save_description")}</div>
					<LabeledDateTime
						label={t("changes_will_apply_on")}
						showTimePicker={false}
						datePickerProps={{
							name: "changesDueDate",
							value: currentContract.changesDueDate,
							showRequired: showRequired.changesDueDate,
							disableDay: day => day.isBefore(moment()),
							onChange: handleFormChanges,
							calendarStyle: { top: "150%", right: 0 },
						}}
					/>
					<PootsyButton
						text={t("save_changes")}
						theme={canSaveContract ? "green" : "cancel"}
						onClick={canSaveContract ? handleUpdateWorkerContract : () => {}}
						disabled={!canSaveContract}
					/>
				</div>
				<div className="section force-update">
					<div className="description">
						{t("notif_unsaved_changes_force_update_description")}
					</div>
					<PootsyButton
						text={t("force_update")}
						theme={canSaveContract ? "green" : "cancel"}
						onClick={forceWorkerContractUpdate}
						disabled={!canSaveContract}
					/>
				</div>
				{/*
				<div className="section reset">
					<div className="description">{t("notif_unsaved_changes_reset_description")}</div>
				</div>
				*/}
			</div>
		)
	}
}

WorkerContractSavingCard.contextTypes = { t: PropTypes.func }
