import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { Route } from "react-router-dom"
import { connect } from "react-redux"

// import * as actions from '../actions'

import { NavLink } from "./NavLink"
import { PageLayout } from "./FunctionalDesign"
// import SodexoExports from "./SodexoExports"
import SodexoConflicts from "./SodexoConflicts"
import SodexoInsufficient from "./SodexoInsufficient"
import SodexoValidate from "./SodexoValidate"
import SodexoOpposed from "./SodexoOpposed"
import SodexoPSVConflicts from "./SodexoPSVConflicts"

import SodexoPSVDeliveries from "./SodexoPSVDeliveries"

class Sodexo extends Component {
	render() {
		let { t } = this.context
		let { match, currAffiliate } = this.props
		let pageHeaderLeft = <h1 className="page-title sodexo">{t("sodexo")}</h1>
		let linksArray = [
			{ label: t("sodexo_exports"), link: "exports" },
			{ label: t("sodexo_paper_service_vouchers"), link: "paperServiceVouchers" },
		]
		if (currAffiliate.sodexoConnectionStatus.authToken && currAffiliate.sodexoAddOn) {
			linksArray.push(
				{ label: t("sodexo_conflicts"), link: "conflicts" },
				{ label: t("sodexo_insufficient_balances"), link: "insufficient" },
				{ label: t("sodexo_validate"), link: "validate" },
				{ label: t("sodexo_opposed"), link: "opposed" }
			)
		}
		let subNavLinks = linksArray.map(entry => (
			<NavLink
				key={entry.link}
				className="sodexo-subnav-link"
				to={match.url + "/" + entry.link}
			>
				{entry.label}
			</NavLink>
		))
		return (
			<PageLayout headerLeft={pageHeaderLeft} subNavLinks={subNavLinks}>
				<Route path={match.path + "/exports"} component={SodexoPSVDeliveries} />
				{currAffiliate.sodexoConnectionStatus.authToken && currAffiliate.sodexoAddOn && (
					<Fragment>
						<Route path={match.path + "/conflicts"} component={SodexoConflicts} />
						<Route path={match.path + "/insufficient"} component={SodexoInsufficient} />
						<Route path={match.path + "/validate"} component={SodexoValidate} />
						<Route path={match.path + "/opposed"} component={SodexoOpposed} />
					</Fragment>
				)}
				{
					<Route
						path={match.path + "/paperServiceVouchers"}
						component={SodexoPSVConflicts}
					/>

					/*
					<Route path={match.path + "/connection"} component={SodexoConnection}/>
					<Route path={match.path + "/balance"}    component={SodexoInsufficientBalances}/>
				*/
				}
			</PageLayout>
		)
	}
}

Sodexo.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	lang: state.i18nState.lang,
	currAffiliate: state.redData.currentAffiliate,
	sodexoData: state.redData.sodexo,
	PDFdocuments: state.redData.sodexoPDF,
})
export default connect(mapStateToProps)(Sodexo)
