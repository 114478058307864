import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"
import * as actions from "../actions"

import {
	PootsyButton,
	LabeledDateTime,
	LabeledSimpleLine,
	PootsySubHeading,
} from "./FunctionalDesign"
import { PootsySelectInput } from "./FunctionalInputs"
import ModalLayout from "./ModalLayout"
import SearchWorker from "./SearchWorker"
import SearchCustomer from "./SearchCustomer"

class SodexoConflictModal extends Component {
	componentWillUnmount = () => {
		this.handleChanges({
			startTime: moment(),
			endTime: moment(),
			worker: { name: "" },
			customer: { name: "" },
		})
	}
	componentDidMount = () => {
		let { stagedPootsyService } = this.props.component
		this.handleChanges({
			startTime: moment(stagedPootsyService.startTime),
			endTime: moment(stagedPootsyService.endTime),
			worker: { name: stagedPootsyService.workerName },
			customer: { name: stagedPootsyService.customerName },
		})
	}
	handleChanges = changes => {
		this.props.dispatch(actions.sodexoConflictModalStateChange(changes))
	}
	handleWorkerChange = worker => {
		if (worker.attributes || worker.name) {
			this.props.dispatch(actions.sodexoConflictModalStateChange({ worker: worker }))
		} else {
			this.props.dispatch(
				actions.sodexoConflictModalStateChange({ worker: { name: worker } })
			)
		}
	}
	handleCustomerChange = customer => {
		this.props.dispatch(actions.sodexoConflictModalStateChange({ customer: customer }))
	}
	handleReachChange = reach_event => {
		this.props.dispatch(
			actions.sodexoConflictModalStateChange({ reach: reach_event.target.value })
		)
	}
	submit = () => {
		let { startTime, endTime, reach, worker, customer } = this.props.component
		this.props.stageBookingsLink({
			start_time: startTime.format("HH:mm"),
			end_time: endTime.format("HH:mm"),
			affiliate_worker_id: worker && worker.id,
			customer_contract_id: customer && customer.id,
			update_type: reach,
		})
	}
	render() {
		let { t } = this.context
		let {
			component: {
				stagedPootsyService,
				stagedSodexoBooking,
				reach,
				startTime,
				endTime,
				worker,
				customer,
			},
		} = this.props
		if (!worker.name) {
			return null
		}
		let buttons = [
			<PootsyButton
				key="a"
				text={t("save")}
				onClick={this.submit}
				disabled={
					reach && stagedSodexoBooking.voucherCount === endTime.diff(startTime, "hours")
						? false
						: true
				}
			/>,
			<PootsyButton
				key="b"
				text={t("cancel")}
				theme="cancel"
				onClick={this.props.closeModal}
			/>,
		]
		return (
			<ModalLayout
				title={t("solve_conflicts")}
				buttons={buttons}
				closeModal={this.props.closeModal}
				customClass="sodexo-conflict-modal"
			>
				<PootsySubHeading text={t("pootsy_booking_sodexo_conflict")} />
				<LabeledSimpleLine
					noLink={true}
					columns={[
						{ value: stagedPootsyService.workerName, label: t("worker") },
						{
							label: t("customer"),
							value: stagedPootsyService.customerName,
						},
						{
							label: t("start_time"),
							value: moment(stagedPootsyService.startTime).format("HH:mm"),
						},
						{
							label: t("end_time"),
							value: moment(stagedPootsyService.endTime).format("HH:mm"),
						},
						{ label: t("voucher_count"), value: stagedPootsyService.voucherCount },
					]}
				/>
				<PootsySubHeading text={t("sodexo_booking_sodexo_conflict")} />

				<LabeledSimpleLine
					noLink={true}
					columns={[
						{ value: stagedSodexoBooking.workerName, label: t("worker") },
						{
							label: t("customer"),
							value: stagedSodexoBooking.customerName,
						},
						{ label: t("voucher_count"), value: stagedSodexoBooking.voucherCount },
					]}
				/>

				<div className="change-reach">
					<PootsySelectInput
						name="reach"
						defaultText={t("modification_reach")}
						selected={reach}
						onChange={this.handleReachChange}
						showRequired={true}
						options={[
							{
								label: t("modify_one_occurrence"),
								value: "one_occurrence",
							},
							{
								label: t("modify_all_occurrences"),
								value: "all_occurrences_from_date",
							},
						]}
					/>
				</div>
				<div className="change-worker">
					<SearchWorker
						name="worker"
						onChange={this.handleWorkerChange}
						onClick={this.handleWorkerChange}
						value={
							(worker && worker.name) ||
							(worker && worker.attributes && worker.attributes.display_name) ||
							""
						}
					/>
					{worker && (worker.name || worker.attributes) && (
						<LabeledSimpleLine
							noLink={true}
							columns={[
								{
									value: worker.name || worker.attributes.display_name || "",
									label: t("worker"),
								},
							]}
						/>
					)}
				</div>
				{customer && (
					<div className="change-customer">
						<SearchCustomer
							name="worker"
							handleCustomerClick={this.handleCustomerChange}
							onClick={this.handleCustomerChange}
							value={
								!customer & "" ||
								(customer && customer.name) ||
								(customer.attributes && customer.attributes.display_name)
							}
						/>

						<LabeledSimpleLine
							noLink={true}
							columns={[
								{
									value:
										customer.name ||
										(customer.attributes && customer.attributes.display_name),
									label: t("customer"),
								},
							]}
						/>
					</div>
				)}
				<div className="change-times">
					<LabeledDateTime
						label={t("start_time")}
						showDatePicker={false}
						timePickerProps={{
							value: startTime || moment(),
							name: "startTime",
							onChange: this.handleChanges,
						}}
					/>
					<LabeledDateTime
						label={t("end_time")}
						showDatePicker={false}
						timePickerProps={{
							value: endTime || moment(),
							name: "endTime",
							onChange: this.handleChanges,
							filterHours: h => h >= startTime.hours(),
							filterMinutes: m =>
								startTime.hours() < endTime.hours()
									? m >= 0 && m <= 59
									: m > startTime.minute(),
						}}
					/>
				</div>
			</ModalLayout>
		)
	}
}

SodexoConflictModal.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.sodexoConflictModalComponent,
})
export default connect(mapStateToProps)(SodexoConflictModal)
