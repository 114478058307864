import React from "react"

export const BookingSelect = ({
	name,
	options,
	defaultText,
	customClass = "",
	showRequired = false,
	isRequired = false,
	disabled,
	allowEmpty = false,
	selected,
	updateFilters,
}) => {
	function setValue(e) {
		updateFilters({ name: name, value: e.target.childNodes[e.target.selectedIndex].value })
	}
	// A default value in the destructuring will not catch null values
	// which in turn will trigger the selection of the first option
	let wrapperClass = "pootsy-input pootsy-select-box " + customClass
	isRequired && (wrapperClass += " is-required")
	let inputFinalClass = "pootsy-select " + customClass
	showRequired && (inputFinalClass += " show-required")
	selected !== "" && (inputFinalClass += " option-chosen")
	return (
		<div className={wrapperClass}>
			<select
				name={name}
				className={inputFinalClass}
				value={selected}
				onChange={setValue}
				disabled={disabled}
			>
				<option value="" disabled={!allowEmpty} />
				{options.map(entry => (
					<option key={entry.value} value={entry.value} disabled={entry.disabled}>
						{entry.label}
					</option>
				))}
			</select>
			<label className="select-text-label">{defaultText}</label>
		</div>
	)
}
