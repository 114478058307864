export const IMPACTED_BOOKINGS_CHANGE = "IMPACTED_BOOKINGS_CHANGE"
export const impactedBookingsChange = changes => ({ type: IMPACTED_BOOKINGS_CHANGE, changes })

export const SHOW_IMPACTED_BOOKINGS_MODAL = "SHOW_IMPACTED_BOOKINGS_MODAL"
export const showImpactedBookingsModal = ({
	impactedBookings,
	impactedOas,
	meta,
	ui,
	triggerAction,
	linkedOccurrences,
	linkedWorkshopOrders,
	linkedPvdg,
}) => ({
	type: SHOW_IMPACTED_BOOKINGS_MODAL,
	impactedBookings,
	meta,
	triggerAction,
	linkedOccurrences,
	linkedWorkshopOrders,
	linkedPvdg,
	impactedOas,
})

export const RESET_IMPACTED_BOOKINGS = "RESET_IMPACTED_BOOKINGS"
export const resetImpactedBookings = () => ({ type: RESET_IMPACTED_BOOKINGS })

export const EDIT_IMPACTED_BOOKING = "EDIT_IMPACTED_BOOKING"
export const editImpactedBooking = (bookingID, changes) => ({
	type: EDIT_IMPACTED_BOOKING,
	bookingID,
	changes,
})

export const EDIT_IMPACTED_OCCURRENCE = "EDIT_IMPACTED_OCCURRENCE"
export const editImpactedOccurrence = (bookingID, occurrenceID, changes) => ({
	type: EDIT_IMPACTED_OCCURRENCE,
	bookingID,
	occurrenceID,
	changes,
})

export const REPLACE_ALL_IMPACTED_BOOKINGS = "REPLACE_ALL_IMPACTED_BOOKINGS"
export const replaceAllImpactedBookings = impactedBookings => ({
	type: REPLACE_ALL_IMPACTED_BOOKINGS,
	impactedBookings,
})

export const denormalizeImpactedBookingActions = impactedBookings => {
	return Object.keys(impactedBookings).reduce((acc, bookingID) => {
		let booking = impactedBookings[bookingID]
		booking.impactedOccurrences.forEach(entry => {
			let obj = {
				id: entry.id,
				action: booking.manualOccurrencesProcess ? entry.action : booking.action,
			}
			acc.push(obj)
		})
		return acc
	}, [])
}

export const checkImpactedBookingsReadyForSubmit = (
	impactedBookings,
	keepImpactedOAs,
	allowedOptions,
	linkedOccurrences,
	linkedWorkshopOrders,
	forceUnlink
) => {
	let invalidEntries = Object.keys(impactedBookings).reduce((acc, key) => {
		let impactedBooking = impactedBookings[key]
		if (impactedBooking.manualOccurrencesProcess) {
			impactedBooking.impactedOccurrences.forEach(entry => {
				if (
					!["create_pending_replacement", "do_not_create_pending_replacement"].includes(
						entry.action
					)
				) {
					acc.push(entry.id)
				}
			})
		} else {
			if (
				!["create_pending_replacement", "do_not_create_pending_replacement"].includes(
					impactedBooking.action
				)
			) {
				acc.push(key)
			}
		}
		return acc
	}, [])
	let ready =
		invalidEntries.length === 0 &&
		(!allowedOptions.includes("impacted_other_activities") || keepImpactedOAs !== "")

	if ((linkedOccurrences.length > 0 || linkedWorkshopOrders.length > 0) && !forceUnlink) {
		ready = false
	}
	return { ready, invalidEntries }
}

export const processImpactedBookingChange = (id, changes) => (dispatch, getState) => {
	dispatch(editImpactedBooking(id, changes))
	let {
		redData: { impactedBookings },
		redComponents: {
			impactedBookingsModal: {
				keepImpactedOAs,
				allowedOptions,
				linkedOccurrences,
				forceUnlink,
				linkedWorkshopOrders,
			},
		},
	} = getState()
	let { ready } = checkImpactedBookingsReadyForSubmit(
		impactedBookings,
		keepImpactedOAs,
		allowedOptions,
		linkedOccurrences,
		linkedWorkshopOrders,
		forceUnlink
	)
	dispatch(impactedBookingsChange({ readyForSubmit: ready }))
}

export const processImpactedOccurrenceChange = (bookingID, occurrenceID, changes) => (
	dispatch,
	getState
) => {
	dispatch(editImpactedOccurrence(bookingID, occurrenceID, changes))
	let {
		redData: { impactedBookings },
		redComponents: {
			impactedBookingsModal: {
				keepImpactedOAs,
				allowedOptions,
				linkedOccurrences,
				forceUnlink,
				linkedWorkshopOrders,
			},
		},
	} = getState()
	let { ready } = checkImpactedBookingsReadyForSubmit(
		impactedBookings,
		keepImpactedOAs,
		allowedOptions,
		linkedOccurrences,
		linkedWorkshopOrders,
		forceUnlink
	)
	dispatch(impactedBookingsChange({ readyForSubmit: ready }))
}

export const changeAllImpactedOccurrences = change => (dispatch, getState) => {
	let {
		redData: { impactedBookings },
	} = getState()
	let newImpactedBookings = Object.entries(impactedBookings).reduce((acc, [bookingId, obj]) => {
		acc[bookingId] = {
			...obj,
			impactedOccurrences: obj.impactedOccurrences.map(entry => ({ ...entry, ...change })),
		}
		return acc
	}, {})

	dispatch(replaceAllImpactedBookings(newImpactedBookings))
}

export const submitImpactedBookingsActions = callBack => (dispatch, getState) => {
	let {
		redData: { impactedBookings },
		redComponents: {
			impactedBookingsModal: {
				endDate,
				keepImpactedOAs,
				allowedOptions,
				linkedOccurrences,
				linkedWorkshopOrders,
				forceUnlink,
			},
		},
	} = getState()

	let { ready, invalidEntries } = checkImpactedBookingsReadyForSubmit(
		impactedBookings,
		keepImpactedOAs,
		allowedOptions,
		linkedOccurrences,
		linkedWorkshopOrders,
		forceUnlink
	)
	if (!ready) {
		dispatch(impactedBookingsChange({ showRequired: invalidEntries }))
		return
	}

	callBack({ impactedBookings, forceUnlink, endDate, keepImpactedOAs })
	dispatch(impactedBookingsChange({ showImpactedBookingsModal: false }))
}

export const handleRequiredActions = ({ triggerAction, error }) => (dispatch, getState) => {
	let requiredActionsData = error.json.error_data
	let iBookings = []
	let allowedOptions = []
	let linkedOccurrences = []
	let impactedOas = []
	let linkedWorkshopOrders = []
	if (requiredActionsData.impacted_bookings) {
		iBookings = requiredActionsData.impacted_bookings.impacted_booking_occurrences
		allowedOptions = requiredActionsData.impacted_bookings.meta.allowed_options
	}
	if (requiredActionsData.linked_sodexo) {
		linkedOccurrences = requiredActionsData.linked_sodexo.linked_occurrences
		linkedWorkshopOrders = requiredActionsData.linked_sodexo.linked_laundry_orders
	}
	if (requiredActionsData.impacted_other_activities) {
		impactedOas = requiredActionsData.impacted_other_activities.impacted_other_activities
	}
	dispatch(
		showImpactedBookingsModal({
			impactedBookings: iBookings,
			impactedOas,
			meta: { fetched: true, allowedOptions },
			linkedOccurrences,
			linkedWorkshopOrders,
			linkedPvdg: requiredActionsData.linked_in_pvdg,
			triggerAction,
		})
	)
}
