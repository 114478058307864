import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"

import * as actions from "../actions"

import Paginator from "./Paginator"
import { ContentLayout, OptionBox, WorkshopOrderLine } from "./FunctionalDesign"
import WorkshopOrdersFilters from "./WorkshopOrdersFilters"
import { PootsySelectInput } from "./FunctionalInputs"

class WorkerWorkshopOrders extends Component {
	UNSAFE_componentWillMount = () => {
		let { dispatch, selectedWorkshopId } = this.props
		if (!selectedWorkshopId) dispatch(actions.setGlobalWorkshopId())
	}
	handleChanges = changes => {
		this.props.dispatch(actions.workerWorkshopOrdersStateChange(changes))
	}
	handleChangePage = changes => {
		this.props.dispatch(actions.workshopOrdersFiltersStateChange({ page: changes.value }))
	}
	handleWorkshopChange = async e => {
		let { dispatch } = this.props
		await dispatch(actions.workshopStateChange({ workshopId: Number(e.target.value) }))
		dispatch(actions.fetchWorkshopOrders())
	}
	render() {
		let { t } = this.context
		let {
			currentAffiliate,
			selectedWorkshopId,
			match,
			history,
			location,
			currentWorker,
			workshopOrders,
			page,
			pagination,
			component: { totalBillableVouchersForCurrentWorkshopOrders },
		} = this.props
		let optionsBoxes = (
			<WorkshopOrdersFilters
				key="a"
				match={match}
				location={location}
				history={history}
				filteredFilters={["workers_filter"]}
				computeTotalVouchers={true}
				customQueries={{
					fromDateQuery: {
						urlName: "from",
						queryName: "from_date",
						mustBeSet: true,
						defaultValue: moment()
							.subtract(1, "month")
							.format("YYYY-MM-DD"),
					},
					toDateQuery: {
						urlName: "to",
						queryName: "to_date",
						mustBeSet: true,
						defaultValue: moment().format("YYYY-MM-DD"),
					},
					workerQuery: {
						urlName: "workers_filter",
						queryName: "filter_affiliate_worker_ids",
						mustBeSet: true,
						defaultValue: [currentWorker.id],
					},
				}}
			/>
		)
		let infoBox = (
			<OptionBox key="b" boxTitle={t("infos")} customClass="WorkshopOrdersFilters">
				<div className="total-billable-vouchers">
					<div className="label">
						{t("total_billable_voucher_for_current_workshop_orders")}
					</div>
					<div className="value">{totalBillableVouchersForCurrentWorkshopOrders}</div>
				</div>
			</OptionBox>
		)
		return (
			<ContentLayout
				optionsBoxes={[optionsBoxes, infoBox]}
				customClass="WorkerWorkshopOrders"
			>
				<PootsySelectInput
					name="workshopId"
					defaultText={t("workshop")}
					selected={selectedWorkshopId}
					onChange={this.handleWorkshopChange}
					options={currentAffiliate.workshops.map(entry => ({
						label: entry.workshopName,
						value: entry.id,
					}))}
				/>
				{workshopOrders.map(entry => (
					<WorkshopOrderLine key={entry.id} workshopOrder={entry} />
				))}
				<Paginator
					name="pageQuery"
					currPage={page}
					totalPages={pagination.total_pages}
					goToPage={this.handleChangePage}
				/>
			</ContentLayout>
		)
	}
}

WorkerWorkshopOrders.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.workerWorkshopOrdersComponent,
	workshopOrders: state.redData.workshopOrders,
	currentWorker: state.redData.currentWorker,
	selectedWorkshopId: state.redComponents.workshopComponent.workshopId,
	currentAffiliate: state.redData.currentAffiliate,
	page: state.redComponents.workshopOrdersFiltersComponent.page,
	pagination: state.redComponents.workshopOrdersFiltersComponent.pagination,
})
export default connect(mapStateToProps)(WorkerWorkshopOrders)
