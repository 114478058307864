import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"

import * as actions from "../actions"
import { PootsySelectInput } from "./FunctionalInputs"
import { PootsyButton, LabeledDateTime } from "./FunctionalDesign"
import ModalLayout from "./ModalLayout"
import InputsChangeHandler from "./InputsChangeHandler"

class ExtraAvailabilityModal extends Component {
	constructor() {
		super()

		this.state = {
			startTime: moment().minutes(0),
			startDate: moment(),
			endDate: moment(),
			endTime: moment()
				.add(1, "hour")
				.minutes(0),
			recurrence: "",
		}
		this.setState = this.setState.bind(this)
	}
	UNSAFE_componentWillMount = () => {
		let { prefillRange } = this.props
		this.setState({
			startDate: moment(prefillRange.start),
			endDate: moment(prefillRange.end),
		})
	}
	UNSAFE_componentWillReceiveProps = newProps => {
		if (newProps.submitSuccess) {
			let { dispatch, closeModal } = this.props
			dispatch(actions.acknowledgeRequestSuccess(actions.SUBMITEXTRAAVAIL_SUCCESS))
			closeModal()
		}
	}
	showRequired = toRequire => {
		let newRequired = { ...this.state.showRequired }
		newRequired[toRequire] = true
		this.setState({ showRequired: newRequired })
	}
	checkStartDate = startDate => {
		let { endDate } = this.state
		if (startDate.isAfter(endDate)) {
			let diff = moment.duration(startDate.diff(endDate))
			let newEndDate = moment(endDate).add(diff.add(1, "hour"))
			this.setState({ endDate: newEndDate })
		}
	}
	handleStartDateChange = date => {
		this.checkStartDate(date)
		this.setState({ startDate: date })
	}
	handleEndDateChange = date => {
		this.setState({ endDate: date })
	}
	handleDateTimeChange = changes => {
		if (changes.startDate) {
			this.checkStartDate(changes.startDate)
		}
		this.setState(changes)
	}

	submit = () => {
		let { dispatch, cleanerId } = this.props
		let { startDate, endDate, endTime, recurrence, startTime } = this.state
		let endDate1Hour = moment(endDate)
			.add(1, "day")
			.format("YYYY-MM-DD")
		let data = {
			start_date: startDate.format("YYYY-MM-DD"),
			start_time: startTime.format("HH:mm"),
			end_date: endDate1Hour,
			end_time: endTime.format("HH:mm"),
			worker_contract_id: cleanerId,
			day_period: recurrence,
		}
		let init = { method: "POST", body: JSON.stringify(data) }
		dispatch(
			actions.simpleFetch("/front/affiliates/extra_availabilities", init, "SUBMITEXTRAAVAIL")
		).then(({ actionType }) => {
			if (actionType === actions.SUBMITEXTRAAVAIL_SUCCESS) {
				dispatch(actions.refetchAgendaLines([cleanerId], startDate.format()))
			}
		})
	}

	handRecurrenceChange = e => {
		e.preventDefault()
		this.setState({ recurrence: e.target.value })
	}
	render() {
		let { t } = this.context
		let { closeModal } = this.props
		let { startDate, endDate, endTime, recurrence, startTime } = this.state
		let buttons = [
			<PootsyButton key="a" text={t("confirm")} onClick={this.submit} />,
			<PootsyButton key="b" text={t("cancel")} theme="cancel" onClick={closeModal} />,
		]
		return (
			<InputsChangeHandler setParentState={this.setState}>
				<ModalLayout
					title={t("new_extra_availability")}
					buttons={buttons}
					closeModal={closeModal}
					formDivClass="extra-avail-modal"
				>
					<LabeledDateTime
						label={t("start_date")}
						timePickerProps={{
							value: startTime,
							name: "startTime",
							onChange: this.handleDateTimeChange,
						}}
						datePickerProps={{
							name: "startDate",
							value: startDate,
							onChange: this.handleDateTimeChange,
						}}
					/>

					<LabeledDateTime
						label={t("end_date")}
						timePickerProps={{
							value: endTime,
							name: "endTime",
							onChange: this.handleDateTimeChange,
							filterHours: h => h >= startDate.hours(),
							filterMinutes: m =>
								startTime.hours() < endTime.hours()
									? m >= 0 && m <= 59
									: m > startTime.minute(),
						}}
						datePickerProps={{
							name: "endDate",
							value: endDate,
							onChange: this.handleDateTimeChange,
							disableDay: day => day.isBefore(startDate),
						}}
					/>
					<PootsySelectInput
						name="recurrence"
						options={[
							{ label: t("once"), value: "0" },
							{ label: t("every_week"), value: "7" },
							{ label: t("every_two_weeks"), value: "14" },
							{ label: t("every_four_weeks"), value: "28" },
						]}
						selected={recurrence}
						onChange={this.handRecurrenceChange}
					/>
				</ModalLayout>
			</InputsChangeHandler>
		)
	}
}
ExtraAvailabilityModal.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	submitSuccess: state.requestsSuccess[actions.SUBMITEXTRAAVAIL_SUCCESS],
})
export default connect(mapStateToProps)(ExtraAvailabilityModal)
