import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import Select from "react-select"
import _ from "lodash"
import moment from "moment"

import * as actions from "../actions"

import Filter from "./Filter"
import { TaskLine, LabeledDateTime, PootsyButton } from "./FunctionalDesign"
import { PootsyTextInput, PootsySelectInput } from "./FunctionalInputs"
import OverlayReveal from "./OverlayReveal"
import InputsChangeHandler from "./InputsChangeHandler"
import DraftTaskEditor from "./DraftTaskEditor"

import { AddIcon, PersonPinIcon, CategoryIcon, FilterIcon, BinIcon } from "../svg/gIcons"

class Tasks extends Component {
	handleChanges = async (changes, autoSave = true) => {
		await this.props.dispatch(actions.tasksStateChange(changes))
		await this.props.dispatch(actions.fetchTasks())
		if (autoSave) this.autoSaveWithCurrentFields()
	}
	autoSaveWithCurrentFields = ({ taskBody } = {}) => {
		let {
			currentTaskId,
			taskTitle,
			taskScheduledDate,
			taskCategory,
			taskAssignees,
		} = this.props.component
		this.autoSave({
			taskId: currentTaskId,
			taskBody,
			taskTitle,
			taskScheduledDate,
			taskCategory,
			taskAssignees,
		})
	}
	autoSave = _.debounce(
		({ taskBody, taskId, taskTitle, taskScheduledDate, taskCategory, taskAssignees }) => {
			this.props.dispatch(
				actions.autoSaveTask({
					taskId,
					taskBody,
					taskTitle,
					taskScheduledDate,
					taskCategory,
					taskAssignees,
				})
			)
		},
		1500
	)
	handleAssigneesChange = changes => {
		this.handleChanges({ taskAssignees: changes || [] })
	}
	handleFilterChange = change => {
		this.handleChanges({ [change.name]: change.value })
	}
	selectTask = id => {
		this.autoSave.flush() // flush all pending autosaving
		let { tasks } = this.props
		let task = tasks.find(entry => entry.id === id)
		this.handleChanges({ ...task, currentTaskId: id }, false)
	}
	handleAttributeFilterChange = () => {
		this.handleChanges({ todoFilterActive: !this.props.component.todoFilterActive })
	}
	handleTaskCheck = e => {
		e.stopPropagation()
		let { name, checked } = e.target
		let taskId = Number(name.split("::")[0])
		this.props.dispatch(actions.taskDone({ taskId, done: checked }))
	}
	toggleNewTask = () => {
		let { currentUser } = this.props
		this.props.dispatch(actions.resetTaskComponent())
		this.handleChanges(
			{
				currentTaskId: "new-task",
				show: true,
				taskAuthor: { display_name: currentUser.userName },
				taskCreatedAt: moment(),
				taskAssignees: [
					{ label: currentUser.userName, value: currentUser.currentAffiliateUserId },
				],
			},
			false
		)
	}
	toggleTaskAttributesFilter = () => {
		this.handleChanges({
			showAttributesFilter: !this.props.component.showAttributesFilter,
			showAssigneesFilter: false,
			showCategoryFilter: false,
		})
	}
	toggleTaskAssigneesFilter = () => {
		this.handleChanges({
			showAssigneesFilter: !this.props.component.showAssigneesFilter,
			showAttributesFilter: false,
			showCategoryFilter: false,
		})
	}
	toggleTaskCategoryFilter = () => {
		this.handleChanges({
			showCategoryFilter: !this.props.component.showCategoryFilter,
			showAssigneesFilter: false,
			showAttributesFilter: false,
		})
	}
	toggleDeletePopUp = () => {
		this.handleChanges({ showDeletePopUp: !this.props.component.showDeletePopUp }, false)
	}
	submitDeleteTask = () => {
		this.autoSave.cancel() // cancel pending autosave calls
		this.props.dispatch(actions.deleteCurrentTask())
	}
	render() {
		let { t } = this.context
		let {
			tasks,
			affiliate,
			component: {
				currentTaskId,
				show,
				taskTitle,
				taskScheduledDate,
				taskCategory,
				taskAssignees,
				taskBody,
				tasksWithSpinner,
				taskAuthor,
				taskCreatedAt,
				showAttributesFilter,
				showAssigneesFilter,
				showCategoryFilter,
				currentAssigneesFilter,
				currentCategoryFilters,
				todoFilterActive,
				showDeletePopUp,
				scheduledDateFilter,
			},
		} = this.props
		if (todoFilterActive) {
			let today = moment()
			tasks = tasks.filter(
				entry =>
					!entry.done &&
					(entry.taskScheduledDate.isSameOrBefore(today) || !entry.taskScheduledDate)
			)
		}
		if (currentCategoryFilters.length > 0) {
			tasks = tasks.filter(entry => currentCategoryFilters.includes(entry.taskCategory))
		}
		if (scheduledDateFilter && scheduledDateFilter.isValid()) {
			tasks = tasks.filter(entry =>
				entry.taskScheduledDate.isSame(scheduledDateFilter, "day")
			)
		}
		return (
			<OverlayReveal customClass="Tasks" show={show}>
				<InputsChangeHandler onChange={this.handleChanges} style={{}}>
					<div className="tasks-list">
						{tasks.length === 0 && (
							<div className="no-tasks-message">{t("no_tasks_message")}</div>
						)}
						{tasks.map(entry => (
							<TaskLine
								key={entry.id}
								task={entry}
								selected={currentTaskId === entry.id}
								select={this.selectTask}
								showSpinner={tasksWithSpinner.includes(entry.id)}
								onCheckboxChange={this.handleTaskCheck}
							/>
						))}
					</div>
					<div className="tasks-actions">
						<FilterIcon
							active={showAttributesFilter}
							customClass={
								todoFilterActive ||
								(scheduledDateFilter && scheduledDateFilter.isValid())
									? "engaged"
									: ""
							}
							onClick={this.toggleTaskAttributesFilter}
							tooltipText={t("task_todo_date_filter_icon_tooltip")}
						/>
						<PersonPinIcon
							active={showAssigneesFilter}
							onClick={this.toggleTaskAssigneesFilter}
							customClass={currentAssigneesFilter.length > 0 ? "engaged" : ""}
							tooltipText={t("task_assignees_filter_icon_tooltip")}
						/>
						<CategoryIcon
							active={showCategoryFilter}
							onClick={this.toggleTaskCategoryFilter}
							customClass={currentCategoryFilters.length > 0 ? "engaged" : ""}
							tooltipText={t("task_category_filter_icon_tooltip")}
						/>
						<AddIcon onClick={this.toggleNewTask} />
						{showAttributesFilter && (
							<div className="task-filter attributes-filter">
								<LabeledDateTime
									label={t("scheduled_date")}
									showTimePicker={false}
									datePickerProps={{
										name: "scheduledDateFilter",
										value: scheduledDateFilter,
										onChange: this.handleChanges,
										allowUnset: true,
										calendarStyle: {
											left: 0,
											bottom: 0,
											transform: "scale(.8)",
										},
									}}
								/>
								<div
									className={
										"task-attribute-filter" +
										(todoFilterActive ? " active" : "")
									}
									onClick={() => this.handleAttributeFilterChange("todo")}
								>
									{t("todo")}
								</div>
								<div
									className={
										"task-attribute-filter" +
										(!todoFilterActive ? " active" : "")
									}
									onClick={() => this.handleAttributeFilterChange("all")}
								>
									{t("all")}
								</div>
							</div>
						)}
						{showAssigneesFilter && (
							<div className="task-filter assignees-filter">
								<Filter
									label={t("task_assignees_filter")}
									name="currentAssigneesFilter"
									possibleValues={affiliate.users.map(entry => ({
										label: entry.display_name,
										value: entry.id,
									}))}
									currentFilters={currentAssigneesFilter}
									onChange={this.handleFilterChange}
								/>
							</div>
						)}
						{showCategoryFilter && (
							<div className="task-filter categories-filter">
								<PootsySelectInput
									name="currentCategoryFilters"
									defaultText={t("task_category_filter")}
									selected={currentCategoryFilters}
									onChange={this.handleFilterChange}
									options={affiliate.availableTasksCategories.map(entry => ({
										label: t(entry),
										value: entry,
									}))}
								/>
							</div>
						)}
					</div>
					{currentTaskId > -1 || currentTaskId === "new-task" ? (
						<Fragment>
							<div className="top-inputs">
								<div className="task-title">
									<PootsyTextInput
										placeholder={t("task_title")}
										name="taskTitle"
										value={taskTitle}
									/>
								</div>
								<div className="date-assignees">
									<div className="date-category">
										<LabeledDateTime
											label={t("scheduled_date")}
											showTimePicker={false}
											datePickerProps={{
												name: "taskScheduledDate",
												value: taskScheduledDate,
												onChange: this.handleChanges,
												allowUnset: true,
												calendarStyle: {
													top: "0%",
													left: 0,
													transform: "scale(.8, .8)",
												},
											}}
										/>
										<PootsySelectInput
											name="taskCategory"
											defaultText={t("task_category")}
											selected={taskCategory}
											options={affiliate.availableTasksCategories.map(
												entry => ({
													label: t(entry),
													value: entry,
												})
											)}
										/>
									</div>
									<div className="assignees-selection">
										<Select
											value={taskAssignees}
											isMulti={true}
											onChange={this.handleAssigneesChange}
											options={affiliate.users.map(entry => ({
												label: entry.display_name,
												value: entry.id,
											}))}
										/>
									</div>
								</div>
							</div>
							<DraftTaskEditor
								id={currentTaskId}
								preloadedBody={taskBody}
								autoSave={this.autoSaveWithCurrentFields}
							/>
							{currentTaskId !== "new-task" && (
								<div className="editor-footer">
									<div>
										{t("created_by_with_date", {
											author: taskAuthor.display_name,
											date: taskCreatedAt.format("DD/MM/YY"),
										})}
									</div>
									<BinIcon
										// onClick={this.submitDeleteTask}
										onClick={this.toggleDeletePopUp}
									/>
									{showDeletePopUp && (
										<div className="delete-task-pop-up">
											<div className="prompt">{t("delete_task_warning")}</div>
											<div className="buttons">
												<PootsyButton
													size="small"
													text={t("yes")}
													onClick={this.submitDeleteTask}
												/>
												<PootsyButton
													size="small"
													theme="white"
													text={t("no")}
													onClick={this.toggleDeletePopUp}
												/>
											</div>
										</div>
									)}
								</div>
							)}
						</Fragment>
					) : (
						<div className="no-selection">{t("no_task_selected")}</div>
					)}
				</InputsChangeHandler>
			</OverlayReveal>
		)
	}
}

Tasks.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	tasks: state.redData.tasks,
	component: state.redComponents.tasksComponent,
	affiliate: state.redData.currentAffiliate,
	currentUser: state.redData.currentUser,
})
export default connect(mapStateToProps)(Tasks)
