import React, { useState, useEffect, useContext } from "react"
import { LocaleContext } from "../index.js"
import _ from "lodash"
import { useSelector, useDispatch } from "react-redux"
import * as actions from "../actions"
import sendIcon from "../static/images/send-icon.png"
import attachIcon from "../static/images/attach-icon.png"
import Filter from "./Filter"
import {
	PootsyTextInput,
	PootsyRadioInputs,
	PootsySelectInput,
	PootsyTextArea,
	PootsyFileInput,
} from "./FunctionalInputs"
import Paginator from "./Paginator"
import {
	PageLayout,
	ContentLayout,
	OptionBox,
	PootsyButton,
	CommunicationWorkerLine,
	MessageHistoryWorkerLine,
	SmallSpinnerSection,
} from "./FunctionalDesign"
import { NavLink } from "./NavLink"
import InputsChangeHandler from "./InputsChangeHandler"

export const Communication = () => {
	// states
	const [currMess, setCurrMess] = useState("")
	const [pickedCleaners, setPickedCleaners] = useState({})
	const [chatImg, setChatImg] = useState("")
	// dispatch && translation
	const dispatch = useDispatch()
	const t = useContext(LocaleContext)
	// props
	const currUser = useSelector(state => state.redData.currentUser)
	const cleaners = useSelector(state => state.redData.cleaners)
	const affiliateMessages = useSelector(state => state.redData.affiliateMessages)
	const workersComponent = useSelector(state => state.redComponents.workersComponent)
	const communicationComponent = useSelector(state => state.redComponents.communicationComponent)
	const currentQuery = useSelector(state => state.currentURLQuery)

	useEffect(() => {
		const fetchFilters = async () => {
			await dispatch(
				actions.simpleFetch(
					"/front/affiliates/worker_filter",
					{ method: "GET" },
					"WORKERSFILTERS"
				)
			)
		}
		const fetchData = async () => {
			await dispatch(
				actions.addQueries({
					squadsQuery: {
						urlName: "squadsFilters",
						queryName: "filter_squads",
						mustBeSet: true,
						defaultValue: currUser.teams.map(entry => entry.id),
					},
					pageQuery: {
						urlName: "page",
						queryName: "page",
						mustBeSet: true,
						defaultValue: "1",
					},
					accountLoggedInQuery: {
						urlName: "accountLoggedIn",
						queryName: "filter_account_logged_in",
						mustBeSet: true,
						defaultValue: "",
					},
					statusQuery: {
						urlName: "status",
						queryName: "filter_status",
						mustBeSet: true,
						defaultValue: "active",
					},
					zipQuery: {
						urlName: "zipFilters",
						queryName: "filter_zip_codes",
						mustBeSet: false,
					},
					paginate: {
						urlName: "paginate",
						queryName: "paginate",
						mustBeSet: false,
						defaultValue: false,
					},
					socSecIdQuery: {
						urlName: "socSecIdFilters",
						queryName: "filter_soc_sec_id",
						mustBeSet: true,
					},
					contractStartDateQuery: {
						urlName: "contractStartDateFilters",
						queryName: "filter_contract_start_date",
						mustBeSet: true,
					},
					searchQuery: { urlName: "search", queryName: "filter_name", mustBeSet: false },
					sortByRatingsQuery: {
						urlName: "sortByRatings",
						queryName: "sort_by_rating_average",
						mustBeSet: false,
					},
				})
			)
		}
		fetchFilters()
		fetchData()
		dispatch(actions.fetchWorkersCommunication())
		dispatch(actions.fetchWorkers({ paginate: false }))
	}, [currUser.teams, dispatch])

	async function sendMessage(e) {
		e.stopPropagation()
		if (currMess === "" && chatImg === "") {
			return
		}
		let formattedCleaners = handleCleanersPickedHash(pickedCleaners)
		if (formattedCleaners.length === 1) {
			return window.location.replace(
				`${window.location.origin}/cleaners/${formattedCleaners[0]}/chat`
			)
		}
		if (formattedCleaners.length === 0) {
			return
		}
		let data = {
			content: currMess,
			picked_cleaners: formattedCleaners,
			group_selected: formattedCleaners.length === cleaners.length,
			filters: currentQuery.urlQuery,
		}
		if (chatImg) {
			data.images = [{ base64_content: chatImg.file, filename: chatImg.filename }]
		}
		let init = { method: "POST", body: JSON.stringify(data) }
		await dispatch(
			actions.simpleFetch(
				"/front/affiliates/affiliate_workers/0/messages/batch_create",
				init,
				"POSTBATCHWORKERMESSAGES"
			)
		)
		await dispatch(actions.fetchWorkersCommunication())
		setCurrMess("")
		setPickedCleaners({})
		setChatImg("")
	}

	function handleAccountLoggedInQueryChange(e) {
		updateQuery({ name: "accountLoggedInQuery", value: e.target.value })
	}

	function handleSocSecIdFilterChange(e) {
		let { value } = e.target
		if (value.length === 0 || value.length >= 1) {
			debouncedUpdateQuery({ name: "socSecIdQuery", value: value })
		}
	}

	function handleChanges(e) {
		if (e.currMess) {
			setCurrMess(e.currMess)
		} else if (e.chatImg) {
			setChatImg(e.chatImg)
		}
	}

	function handleContractStartDateFilterChange(e) {
		let { value } = e.target
		if (value.length === 0 || value.length >= 1) {
			debouncedUpdateQuery({ name: "contractStartDateQuery", value: value })
		}
	}

	const debouncedUpdateQuery = _.debounce(changes => {
		updateQuery(changes)
	}, 1500)

	function handleCleanersPickedHash(clHash) {
		let resultArray = []
		Object.keys(clHash).forEach(key => {
			if (clHash[key]) {
				resultArray.push(key)
			}
		})
		return resultArray
	}

	function handleSelectFilterChange(e) {
		let { name, value } = e.target
		updateQuery({ name, value })
	}

	async function updateQuery(changes) {
		if (changes.name !== "pageQuery") {
			if (Array.isArray(changes)) {
				changes = [...changes, { name: "pageQuery", value: 1 }]
			} else {
				changes = [changes, { name: "pageQuery", value: 1 }]
			}
		}
		await dispatch(actions.updateQuery(changes))
		dispatch(actions.fetchWorkers({ paginate: false }))
		dispatch(actions.fetchWorkersCommunication())
	}

	async function updateHistory(changes) {
		await dispatch(actions.updateQuery(changes))
		dispatch(actions.fetchWorkersCommunication())
	}

	if (workersComponent.filters.areas === undefined) {
		return null
	}
	let boxOneFilters = [
		{
			queryId: "zipQuery",
			currValues: currentQuery.urlQuery.zipFilters,
			label: t("postal_codes"),
			filtersList: workersComponent.filters.areas.list,
		},
		{
			queryId: "squadsQuery",
			currValues: currentQuery.urlQuery.squadsFilters,
			label: t("search_squads"),
			filtersList: workersComponent.filters.squads.list,
		},
	].map(entry => {
		let possibleValues
		entry.filtersList === undefined
			? (possibleValues = [])
			: (possibleValues = entry.filtersList)
		return (
			<Filter
				key={entry.queryId}
				label={entry.label}
				name={entry.queryId}
				possibleValues={possibleValues}
				currentFilters={entry.currValues}
				onChange={updateQuery}
			/>
		)
	})

	let accountLoggedInPossibleValues = workersComponent.filters.account_logged_in.list || []

	let boxOne = (
		<OptionBox key="show" boxTitle={t("show")}>
			{boxOneFilters}
			<PootsySelectInput
				name="statusQuery"
				defaultText={t("workers_statuses")}
				customClass="bookings-select-filter"
				allowEmpty={true}
				onChange={handleSelectFilterChange}
				selected={currentQuery.urlQuery.status}
				options={[
					{ label: t("active"), value: "active" },
					{ label: t("inactive"), value: "inactive" },
				]}
			/>
			<PootsyTextInput
				label={t("contract_start_date")}
				name="contractStartDateFilters"
				customClass="bookings-text-filter"
				type="date"
				onChange={handleContractStartDateFilterChange}
			/>
			<PootsySelectInput
				name="sortByRatingsQuery"
				defaultText={t("sort_by_ratings")}
				customClass="bookings-select-filter"
				allowEmpty={true}
				onChange={handleSelectFilterChange}
				selected={currentQuery.urlQuery.sortByRatings}
				options={[
					{ label: t("asc_ordering"), value: "asc" },
					{ label: t("desc_ordering"), value: "desc" },
				]}
			/>
			<PootsyTextInput
				label={t("soc_sec_id_filters")}
				name="socSecIdFilters"
				customClass="bookings-text-filter"
				onChange={handleSocSecIdFilterChange}
			/>
			<PootsyRadioInputs
				groupName="accountLoggedInQuery"
				groupLabel={t("cleaners_with_account")}
				onChange={handleAccountLoggedInQueryChange}
				selected={currentQuery.urlQuery.accountLoggedIn}
				options={accountLoggedInPossibleValues.map(e => ({
					id: e.label,
					value: e.value,
					label: t(e.label),
				}))}
			/>
		</OptionBox>
	)

	let optionsBoxes = [boxOne]

	let pageHeaderLeft = <h1 className="page-title cleaners">{t("cleaners")}</h1>
	let pageHeaderRight = (
		<NavLink to="/cleaners/new">
			<PootsyButton text={t("add_new_cleaner")} />
		</NavLink>
	)
	let links = [
		{ label: t("workers"), link: "/cleaners" },
		{ label: t("communication"), link: "/communication" },
	]

	let subNavLinks = links.map(entry => (
		<NavLink key={entry.label} to={entry.link} exact={true}>
			{entry.label}
		</NavLink>
	))

	let messagesList = (
		<div className="messages_list">
			<h2 className="pootsy-subheading">{t("message_history")}</h2>
			{affiliateMessages.data &&
				affiliateMessages.data.map(message => (
					<MessageHistoryWorkerLine key={message.id} message={message.attributes} />
				))}
		</div>
	)

	function selectAll() {
		let selectedAll = { ...pickedCleaners }
		cleaners.forEach(cleaner => {
			selectedAll[cleaner.id] = true
		})
		setPickedCleaners(selectedAll)
	}

	function eraseAll() {
		let eraseAll = { ...pickedCleaners }
		Object.keys(eraseAll).forEach(key => {
			eraseAll[key] = false
		})
		setPickedCleaners(eraseAll)
	}

	function hangleCleanerChange(id) {
		let newValues = { ...pickedCleaners }
		newValues[id] = Object.hasOwn(newValues, id) ? !newValues[id] : true
		setPickedCleaners(newValues)
	}

	return (
		<PageLayout
			headerLeft={pageHeaderLeft}
			headerRight={pageHeaderRight}
			subNavLinks={subNavLinks}
		>
			<ContentLayout customClass="cleaners" optionsBoxes={optionsBoxes}>
				<InputsChangeHandler
					customClass={"pootsy-cleaner-chat-room"}
					onChange={handleChanges}
				>
					<div className="message-input">
						<PootsyTextArea name="currMess" value={currMess} />
						{chatImg && (
							<div className="current-image">
								<img alt="messimg" src={chatImg.file} />
							</div>
						)}
						<div className="message-buttons">
							<div className="send-button" onClick={sendMessage}>
								<div className="prompt">{t("send_chat_message")}</div>
								<img alt="send-icon" src={sendIcon} />
							</div>
							<PootsyFileInput
								label={<img alt="ai" src={attachIcon} />}
								name="chatImg"
								accept="image/*"
							/>
						</div>
					</div>
				</InputsChangeHandler>
				<PootsyButton text={t("select_all")} onClick={selectAll} />
				<PootsyButton text={t("unselect_all")} onClick={eraseAll} />
				<div className="comm-cleaners-list">
					{cleaners.map(entry => (
						<CommunicationWorkerLine
							key={entry.id}
							worker={entry}
							onClick={hangleCleanerChange}
							checked={pickedCleaners[entry.id]}
						/>
					))}
				</div>
				<SmallSpinnerSection isLoading={affiliateMessages.loading}>
					<div>{messagesList}</div>
				</SmallSpinnerSection>

				<Paginator
					name="pageQuery"
					currPage={currentQuery.urlQuery.page}
					totalPages={communicationComponent.pagination.total_pages}
					goToPage={updateHistory}
				/>
			</ContentLayout>
		</PageLayout>
	)
}

Communication.defaultProps = {
	currentFilters: [],
	showRequired: false,
}
