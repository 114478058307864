import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { history } from "../history"
import * as actions from "../actions"

import {
	ContentLayout,
	OptionBox,
	PootsyButton,
	SimpleLine,
	PootsySubHeading,
} from "./FunctionalDesign"
import ModalLayout from "./ModalLayout"
import { PootsyTextInput, PootsyTextArea } from "./FunctionalInputs"

class CustomerIncompatibilities extends Component {
	state = {
		queuedCall: "",
		showCreateIncompatibilityModal: false,
		workerSearchValue: "",
		chosenWorker: {},
		incompatibilityNote: "",
	}
	componentDidMount = () => {
		let {
			match: { params },
		} = this.props
		this.props.dispatch(actions.fetchCustomerWorkerIncompatibilities(params.id))
	}
	componentWillUnmount = () => {
		this.resetModal()
	}
	resetModal = () => {
		this.props.dispatch(actions.resetWorkerSuggestions())
		clearTimeout(this.state.queuedCall)
		this.setState({ chosenWorker: {}, workerSearchValue: "" })
	}
	toggleCreateIncompatibilityModal = () => {
		this.resetModal()
		this.setState(state => ({
			showCreateIncompatibilityModal: !state.showCreateIncompatibilityModal,
		}))
	}
	handleSearchWorkers = e => {
		let { value } = e.target
		let { dispatch } = this.props
		if (value === "") {
			dispatch(actions.resetWorkerSuggestions())
			this.setState({ workerSearchValue: "" })
			return
		}
		this.scheduleApiCall(value)
		this.setState({ workerSearchValue: value })
	}
	scheduleApiCall = value => {
		let { queuedCall } = this.state
		clearTimeout(queuedCall)
		let futureCall
		if (value.length < 3) {
			return
		} else {
			futureCall = setTimeout(() => this.fetchWorkerSuggestions(value), 800)
		}
		this.setState({ queuedCall: futureCall })
	}
	fetchWorkerSuggestions = search => {
		let query = "?filter_name=" + search
		let init = { method: "GET" }
		this.props.dispatch(
			actions.simpleFetch(
				"/front/affiliates/affiliate_workers" + query,
				init,
				"WORKERSUGGESTIONS"
			)
		)
	}
	handleWorkerClick = worker => {
		let { dispatch } = this.props
		this.setState({ chosenWorker: worker, workerSearchValue: "" })
		dispatch(actions.resetWorkerSuggestions())
	}
	handleNoteChange = e => {
		e.stopPropagation()
		this.setState({ incompatibilityNote: e.target.value })
	}
	goToWorker = id => {
		history.push("/cleaners/" + id + "/profile")
	}
	submitIncompatibility = () => {
		let {
			currentCustomer: { id },
		} = this.props
		let { chosenWorker, incompatibilityNote } = this.state
		let init = {
			method: "POST",
			body: JSON.stringify({
				affiliate_worker_id: chosenWorker.id,
				note: incompatibilityNote,
			}),
		}
		let url = "/front/affiliates/customer_contracts/" + id + "/worker_incompatibilities"
		chosenWorker.attributes.note = incompatibilityNote
		this.props.dispatch(actions.simpleFetch(url, init, "SUBMIT_CUSTOMER_INCOMPATIBILITY"))
		this.toggleCreateIncompatibilityModal()
	}
	submitRemoveIncompatibility = e => {
		e.stopPropagation()
		let { id } = e.target.dataset
		let { currentCustomer } = this.props
		let init = { method: "DELETE" }
		let url = `/front/affiliates/customer_contracts/${currentCustomer.id}/worker_incompatibilities/${id}`
		this.props.dispatch(
			actions.simpleFetch(url, init, "SUBMIT_REMOVE_CUSTOMER_INCOMPATIBILITY", {
				toRemoveID: id,
			})
		)
	}
	render() {
		let { t } = this.context
		let { workerSuggestions, currentCustomer } = this.props
		let {
			showCreateIncompatibilityModal,
			workerSearchValue,
			chosenWorker,
			incompatibilityNote,
		} = this.state
		let optionsBoxes = (
			<OptionBox boxTitle={t("customer_bio")}>
				<div className="known-workers">
					<div className="known-workers-title">{t("known_workers_title")}</div>
					{currentCustomer.knownWorkers &&
						currentCustomer.knownWorkers.all &&
						currentCustomer.knownWorkers.all.map(kw => (
							<div key={kw.id} className="known-worker">
								{kw.display_name}
								{currentCustomer.knownWorkers.active.map(kwa => kwa.id).includes(kw)
									? t("active_known_worker")
									: ""}
							</div>
						))}
				</div>
				<PootsyButton
					text={t("create_customer_incompatibility_button")}
					onClick={this.toggleCreateIncompatibilityModal}
				/>
			</OptionBox>
		)
		let createIncompatibilityModalButtons = [
			<PootsyButton key="a" text={t("submit")} onClick={this.submitIncompatibility} />,
			<PootsyButton
				key="b"
				text={t("cancel")}
				theme="cancel"
				onClick={this.toggleCreateIncompatibilityModal}
			/>,
		]
		return (
			<ContentLayout optionsBoxes={optionsBoxes} customClass="customer-incompatibilities">
				<div className="customer-incompatibilities">
					{currentCustomer.incompatibilities && (
						<PootsySubHeading text={t("customers_incompatibilities_title")} />
					)}
					{currentCustomer.incompatibilities &&
						currentCustomer.incompatibilities.length === 0 && (
							<div className="no-incompatibilities">{t("no_incompatibilities")}</div>
						)}
					{currentCustomer.incompatibilities &&
						currentCustomer.incompatibilities.map(ci => (
							<SimpleLine
								key={ci.id}
								onClick={this.goToWorker.bind(
									this,
									ci.attributes.worker_contract_id
								)}
							>
								<div className="incompatibility-line-contact">
									<span className="worker-name">{ci.attributes.worker_name}</span>
								</div>
								<div className="incompatibility-note">{ci.attributes.note}</div>
								<div className="remove-customer-incompatibility">
									<PootsyButton
										text={t("remove_customer_incompatibility")}
										theme="red"
										onClick={this.submitRemoveIncompatibility}
										dataset={{ "data-id": ci.id }}
									/>
								</div>
							</SimpleLine>
						))}
				</div>
				{showCreateIncompatibilityModal && (
					<ModalLayout
						formDivClass="create-customer-incompatibility-modal"
						title={t("create_customer_incompatibility_modal_title")}
						closeModal={this.toggleCreateIncompatibilityModal}
						buttons={createIncompatibilityModalButtons}
					>
						<div className="worker-search-box">
							<PootsyTextInput
								label={t("search_cleaners")}
								onChange={this.handleSearchWorkers}
								value={workerSearchValue}
							/>
							{workerSuggestions.length > 0 && (
								<div className="worker-suggestions">
									{workerSuggestions.map(cs => (
										<div
											key={cs.id}
											className="worker-suggestion"
											onClick={this.handleWorkerClick.bind(this, cs)}
										>
											<div className="worker-name">
												{cs.attributes.display_name}
											</div>
										</div>
									))}
								</div>
							)}
						</div>
						{chosenWorker.id && (
							<div className="chosen-worker">
								{t("worker_to_be_incompatible_confirmation")}
								<div className="worker-to-be-incompatible">
									<div>{chosenWorker.attributes.display_name}</div>
									<div>></div>
									<div>{currentCustomer.displayName}</div>
								</div>
							</div>
						)}
						<PootsyTextArea
							label={t("note")}
							value={incompatibilityNote}
							onChange={this.handleNoteChange}
						/>
					</ModalLayout>
				)}
			</ContentLayout>
		)
	}
}

CustomerIncompatibilities.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currentCustomer: state.redData.currentCustomer,
	workerSuggestions: state.redData.workerSuggestions,
})
export default connect(mapStateToProps)(CustomerIncompatibilities)
