import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"

import * as actions from "../actions"

import { OptionBox, PootsyButton } from "./FunctionalDesign"
import DateFilterer from "./DateFilterer"
import { BookingBackEndFilters } from "./BookingBackEndFilters"

class BookingFilters extends Component {
	state = {
		queuedCall: "",
		filters: {},
		bookingFilters: [],
		urlQuery: {},
		filtersUrls: {
			workers_filter: "workerQuery",
			customers_filter: "customers_filter",
		},
	}

	componentDidMount = async () => {
		let { dispatch, customQueries } = this.props
		await dispatch(actions.fetchBookingFilters())
		await dispatch(
			actions.addQueries({
				...customQueries,
				pageQuery: {
					urlName: "page",
					queryName: "page",
					mustBeSet: true,
					defaultValue: "1",
				},
			})
		)
		this.setFiltersUrls()
	}

	componentDidUpdate = (prevProps, newProps) => {
		if (
			prevProps.bookingFilters.length > 0 &&
			Object.keys(prevProps.currentQuery.urlQuery).length > 0 &&
			(this.state.bookingFilters.length === 0 ||
				(this.state.bookingFilters.length > 0 &&
					Object.keys(this.state.urlQuery).toString() !==
						Object.keys(prevProps.currentQuery.urlQuery).toString()))
		) {
			this.updateBookingFilters()
		}
	}

	setFiltersUrls = () => {
		let loadFilters = {}
		let query = this.props.currentQuery.queries
		Object.keys(query).forEach(key => {
			loadFilters[query[key].urlName] = key
		})
		let urlQuery = this.props.currentQuery.urlQuery
		Object.keys(urlQuery).forEach(key => {
			if (loadFilters[key]) {
				this.setState({
					filters: Object.assign({}, this.state.filters, {
						[loadFilters[key]]: urlQuery[key],
					}),
				})
			}
		})
	}

	componentWillUnmount = () => {
		this.props.dispatch(
			actions.bookingFiltersStateChange({
				bookingsNeedRefetch: true,
				page: 1,
			})
		)
		this.props.dispatch(actions.resetQuery())
	}
	UNSAFE_componentWillReceiveProps = async newProps => {
		let {
			dispatch,
			component: { search: oldSearch, page: oldPage, fetched: oldFetched },
		} = this.props
		let {
			bookingFilters,
			component: {
				search: newSearch,
				page: newPage,
				fetched: newFetched,
				bookingsNeedRefetch,
			},
		} = newProps
		if (oldSearch !== newSearch && (newSearch.length === 0 || newSearch.length > 2)) {
			this.scheduleApiCall(newSearch)
		}
		if (oldPage !== newPage) {
			this.updateQuery({ name: "pageQuery", value: newPage })
		}
		if ((!oldFetched && newFetched) || bookingsNeedRefetch) {
			let newQueries = bookingFilters.reduce((acc, filter) => {
				if (
					filter.name === "squads" &&
					filter.default_value.length === 0 &&
					filter.values.length > 0
				) {
					filter.default_value = [filter.values[0].value.toString()]
				}
				if (this.props.match.path !== "/bookings") {
					filter.default_value = []
				}
				acc[filter.name] = {
					urlName: filter.name,
					queryName: filter.url_param,
					mustBeSet: false,
					defaultValue: filter.default_value,
				}
				return acc
			}, {})
			this.handleChanges({ bookingsNeedRefetch: false })
			await dispatch(actions.addQueries(newQueries))
			dispatch(actions.fetchBookings())
		}
	}
	scheduleApiCall = value => {
		let { queuedCall } = this.state
		clearTimeout(queuedCall)
		let futureCall = setTimeout(
			() => this.updateQuery({ name: "searchQuery", value: value }),
			800
		)
		this.setState({ queuedCall: futureCall })
	}
	handleChanges = changes => {
		this.props.dispatch(actions.bookingFiltersStateChange(changes))
	}
	updateQuery = async (changes, newUpdates = true) => {
		let { dispatch } = this.props
		if (changes.name !== "pageQuery") {
			if (Array.isArray(changes)) {
				changes = [...changes, { name: "pageQuery", value: 1 }]
			} else {
				changes = [changes, { name: "pageQuery", value: 1 }]
			}
		}
		await dispatch(actions.updateQuery(changes, newUpdates))
		dispatch(actions.fetchBookings())
	}
	handleDateFiltersChange = dateFilters => {
		let query = [
			{ name: "fromDateQuery", value: dateFilters.from.format("YYYY-MM-DD") },
			{ name: "toDateQuery", value: dateFilters.to.format("YYYY-MM-DD") },
		]
		this.updateQuery(query)
	}
	handleSelectFilterChange = e => {
		let { name, value } = e.target
		this.updateQuery({ name, value })
	}
	handleUpdateFilters = changes => {
		if (changes.name === "cleanerQuery" || changes.name === "customerQuery") {
			changes = [changes, { name: "pageQuery", value: 1 }]
		}
		this.updateQuery(changes)
	}
	handleShortcutNext = () => {
		this.updateQuery([
			{ name: "fromDateQuery", value: moment().format("YYYY-MM-DD") },
			{
				name: "toDateQuery",
				value: moment()
					.add(2, "week")
					.format("YYYY-MM-DD"),
			},
		])
	}
	handleShortcutPrevious = () => {
		this.updateQuery([
			{
				name: "fromDateQuery",
				value: moment()
					.subtract(2, "month")
					.format("YYYY-MM-DD"),
			},
			{
				name: "toDateQuery",
				value: moment()
					.subtract(1, "day")
					.format("YYYY-MM-DD"),
			},
		])
	}

	checkAffiliate = () => {
		let affiliate = document.getElementsByClassName("affiliate-name")
		if (affiliate.length > 0) {
			return affiliate[0].innerText !== "DIENSTENTHUIS BV"
		}
		return true
	}

	sendFilterParams = () => {
		let { filters, filtersUrls } = this.state
		for (const [key, value] of Object.entries(filtersUrls)) {
			if (this.props.filteredFilters.includes(key)) {
				let newFilters = Object.assign({}, filters, {
					[value]: [this.props.match.params.id],
				})
				this.setState({ filters: newFilters })
			}
		}
		let query = this.getQuery(filters)
		this.updateQuery(query, false)
	}

	updateFilters = value => {
		let newBookingFilters = [...this.state.bookingFilters]
		let newValues = Object.assign({}, this.state.filters, { [value.name]: value.value })
		newBookingFilters.forEach(entry => {
			if (entry.name === value.name) {
				entry.value = value.value
			}
		})
		this.setState({ filters: newValues, bookingFilters: newBookingFilters })
	}

	getQuery = query => {
		let resultQuery = []
		Object.keys(query).forEach(key => {
			if (query[key].length !== 0) {
				let hash = { name: key, value: query[key] }
				resultQuery.push(hash)
			}
		})
		return resultQuery
	}

	checkfilteredFilters = item => {
		let { filteredFilters } = this.props
		if (filteredFilters.includes(item.name)) {
			return false
		}
		if (filteredFilters.includes("customers_filter") && item.name === "region_filter") {
			return false
		}

		return true
	}

	updateBookingFilters = () => {
		let { filters, bookingFilters } = this.state
		let {
			currentQuery: { urlQuery },
		} = this.props
		let newBookingFilters = []
		let newFilters = Object.assign({}, filters)

		if (bookingFilters.length === 0) {
			newBookingFilters = [...this.props.bookingFilters]
			newBookingFilters.forEach(entry => (entry.value = urlQuery[entry.name]))
		}
		let intersections = newBookingFilters
			.map(filter => filter.name)
			.filter(value => Object.keys(newFilters).includes(value))
		newBookingFilters.forEach(entry => {
			// find elements that presented in filters and not presented in bookingFilters
			if (intersections.includes(entry.name)) {
				entry.value = newFilters[entry.name]
			}
			// add elements that presented in bookingFilters and not presented in state
			if (entry.value && !Object.keys(newFilters).includes(entry.name)) {
				newFilters = Object.assign({}, newFilters, { [entry.name]: entry.value })
			}
			// fix problem with wrong type of select value
			if (
				entry.input === "select" &&
				Array.isArray(entry.value) &&
				entry.value.length === 0
			) {
				entry.value = ""
			}
		})
		this.setState({
			bookingFilters: newBookingFilters,
			filters: newFilters,
			urlQuery: urlQuery,
		})
	}

	render() {
		let { t } = this.context
		let {
			dateFiltersHandledElsewhere,
			children,
			currentQuery: {
				urlQuery: { from, to },
			},
		} = this.props
		let mFrom = moment(from)
		let mTo = moment(to)
		let today = moment()
		let onlyFutureBookings = mFrom.isSameOrAfter(today, "day") && mTo.isAfter(today, "day")
		let onlyPastBookings = mFrom.isBefore(today, "day") && mTo.isBefore(today, "day")
		return (
			<OptionBox boxTitle={t("filters")} customClass="BookingFilters">
				{!dateFiltersHandledElsewhere && (
					<Fragment>
						{this.checkAffiliate() && (
							<>
								<div
									className={
										"type-query-option" + (onlyFutureBookings ? " active" : "")
									}
									data-type="next"
									onClick={this.handleShortcutNext}
								>
									{t("today_and_future_filter")}
								</div>
								<div
									className={
										"type-query-option" + (onlyPastBookings ? " active" : "")
									}
									data-type="previous"
									onClick={this.handleShortcutPrevious}
								>
									{t("past_filter")}
								</div>
							</>
						)}
						<DateFilterer
							updateFilters={this.updateFilters}
							onChange={this.handleDateFiltersChange}
							from={mFrom}
							to={mTo}
							skipFilter={true}
						/>
					</Fragment>
				)}
				<BookingBackEndFilters
					updateFilters={this.updateFilters}
					filters={this.state.bookingFilters.filter(this.checkfilteredFilters)}
					filterInputChange={this.handleUpdateFilters}
					selectInputChange={this.handleSelectFilterChange}
					context={t}
				/>

				<div className="filter-buttons">
					<div className="button-filter">
						<PootsyButton text={t("filter")} onClick={this.sendFilterParams} />
					</div>

					{children && children}
				</div>
			</OptionBox>
		)
	}
}

BookingFilters.defaultProps = {
	customQueries: [],
	filteredFilters: [],
	dateFiltersHandledElsewhere: false,
}
BookingFilters.propTypes = {
	match: PropTypes.shape({
		path: PropTypes.string,
		url: PropTypes.string,
		isExact: PropTypes.bool,
		params: PropTypes.object,
	}).isRequired,
	location: PropTypes.shape({
		pathname: PropTypes.string,
		search: PropTypes.string,
		hash: PropTypes.string,
		state: PropTypes.object,
		key: PropTypes.string,
	}).isRequired,
	history: PropTypes.shape({
		length: PropTypes.number,
		action: PropTypes.string,
		block: PropTypes.func,
		createHred: PropTypes.func,
		go: PropTypes.func,
		goBack: PropTypes.func,
		goForward: PropTypes.func,
		listen: PropTypes.func,
		location: PropTypes.object,
		push: PropTypes.func,
		replace: PropTypes.func,
	}).isRequired,
}
BookingFilters.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	bookingFilters: state.redData.bookingFilters,
	component: state.redComponents.bookingFiltersComponent,
	currentQuery: state.currentURLQuery,
})

export default connect(mapStateToProps)(BookingFilters)
