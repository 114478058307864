import React from "react"



export const goForIt = (
	<svg className="go-for-it" id="Layer_1" viewBox="0 0 109.5 135.1">
		<path
			d="M55.3 93.1c-.6 1.4-.7 3-.7 4.6.1 6 1 11.9 2.8 17.6 5.1-2.7 11.7-1.4 16.5-4.8 1.1-.7 2-1.8 2.3-3.1.2-.9 0-1.8-.1-2.7-.5-2.5-1-4.9-1.7-7.3-.6-2-1.2-4-2.3-5.8-1-1.8-2.4-3.4-4.2-4.4-1.7-.9-4.3-1.4-6-.4-.4.2-.8.7-1.1.9l-1.2.9c-.8.6-1.6 1.1-2.3 1.8-.8.9-1.5 1.7-2 2.7z"
			fill="#f1b382"
		/>
		<path
			className="st1"
			d="M29 106.8c5-2 18.3 1.8 18.3-2.1 0-3.1.2-6.4.9-9.5.4-1.6.8-3.2 1.7-4.5.8-1.2 2-2.1 2.9-3.2 1.3-1.6 2.1-3.6 3.3-5.3.2-.3.4-.5.7-.6.4 0 .7.3.9.6 1.9 2.6 2 6.4.1 9-.5.7-1.2 1.4-1.6 2.2-.6 1-.8 2.2-.8 3.4s.2 2.4.4 3.5c.7 4 2.1 7.9 3.1 11.8 1.7-.8 3.7-.5 5.5-.2s3.3.3 5.1-.2c2.6-.7 4.5-2.9 7-3.8-.2 1.1-2.2 3.6-3.4 5.3-1.2 1.6-9.5 2.7-11.3 3.6-4 2-14.1 2.4-16 3-6 2-32.4 8-32 12"
		/>
		<path
			transform="rotate(-4.534 64.8 83.194) scale(1.00003)"
			fill="#bc3c82"
			d="M58.9 46h11.8v74.4H58.9z"
		/>
		<path
			className="st1"
			d="M60.9 93.7l-.1-.2c-.8-1.2-.5-2.8.7-3.7l7.3-5.1c1.2-.8 2.8-.5 3.7.7l.1.2c.8 1.2.5 2.8-.7 3.7l-7.3 5.1c-1.2.8-2.9.5-3.7-.7zM61.6 99.5c-.8-1.3-.3-3 1-3.8l9.2-5.2c1.3-.8 3-.3 3.8 1 .8 1.3.3 3-1 3.8l-9.2 5.2c-1.3.8-3 .3-3.8-1zM63.3 105l-.1-.3c-.6-1.3.1-2.9 1.4-3.4l9.7-4.1c1.3-.6 2.9.1 3.4 1.4l.1.3c.6 1.3-.1 2.9-1.4 3.4l-9.7 4.1c-1.2.5-2.8-.1-3.4-1.4zM66.8 109.7l-.1-.3c-.5-1.1 0-2.3 1.1-2.8l7.5-3.2c1.1-.5 2.3 0 2.8 1.1l.1.3c.5 1.1 0 2.3-1.1 2.8l-7.5 3.2c-1.1.5-2.3 0-2.8-1.1z"
		/>
		<path
			className="st3"
			d="M65.1 92.4L63.5 90c-.1-.2-.4-.2-.6-.1l-1.3.9s-1 .8-.2 2.2c0 0 .6 1.1 1.9 1 0 0 .5-.1 1.3-.7l.4-.3c.2-.2.3-.4.1-.6zM66 98.6l-1.5-2.4c-.1-.2-.4-.3-.6-.1l-1.3.8s-1 .7-.4 2.2c0 0 .6 1.2 1.9 1.1 0 0 .5-.1 1.3-.7l.4-.3c.2-.1.3-.4.2-.6zM67.5 104.5l-1-2.5c-.1-.2-.3-.3-.6-.2l-1.4.6s-1.1.5-.7 2c0 0 .4 1.2 1.6 1.3 0 0 .5 0 1.3-.4l.4-.2c.3-.2.4-.4.4-.6zM70.6 109.3l-.9-2.1c-.1-.2-.3-.3-.5-.2l-1.2.5s-.9.4-.6 1.7c0 0 .3 1 1.3 1.1 0 0 .4 0 1.1-.3l.4-.1c.4-.2.5-.4.4-.6zM55 84.8l1.5.9c.1.1.3-.1.4-.3l.8-1.6s.6-1.3-.2-2c0 0-.6-.5-1.5.4 0 0-.3.4-.8 1.5l-.2.5c-.1.3-.1.5 0 .6z"
		/>
		<path className="st4" d="M109 43.7l-94.8 6.6-3.5-39.5L105.2.7z" />
		<path
			fill="#fff"
			stroke="#bc3c82"
			strokeWidth={2}
			strokeMiterlimit={10}
			d="M101.4 38.3l-81.3 6.1-2.6-29.2 81.1-8.3z"
		/>
		<path
			d="M0 135.1v-25.5c18.4-4.3 34.2-8.3 42.7-11 0 0-.4 1.4 0 4.9.4 3.6 1.7 9.5 5.3 18.5.6 1.5 1.2 3 1.9 4.6 0 0-8.8 1.2-22.7 3.5-2.2.4-4.5.8-6.9 1.2-.1 0-.3 0-.4.1-6 1-12.6 2.3-19.6 3.6-.2 0-.2 0-.3.1z"
			fill="#ed6d66"
		/>
		<path
			className="st4"
			d="M27.8 25.4c1.2-.1 2.5.2 3.5 1 .1.1.2.3.1.4l-1 1.3c-.1.1-.3.1-.4.1-.6-.4-1.2-.6-1.9-.5-1.5.1-2.6 1.5-2.5 3.1.1 1.5 1.5 2.7 3 2.5.4 0 .8-.1 1.2-.3l.1-1-.9.1c-.2 0-.3-.1-.3-.2l-.1-1.4c0-.2.1-.3.2-.3l2.8-.3c.1 0 .3.1.3.2l.4 3.8c0 .1 0 .2-.1.2 0 0-1.3 1-3.4 1.2-2.8.3-5.2-1.7-5.5-4.5-.3-2.7 1.7-5.2 4.5-5.4zM37.6 24.4c2.8-.3 5.2 1.8 5.5 4.5.3 2.8-1.7 5.2-4.5 5.5s-5.2-1.7-5.5-4.5 1.7-5.2 4.5-5.5zm.7 7.8c1.5-.1 2.7-1.5 2.5-3-.1-1.5-1.5-2.7-3.1-2.5-1.5.1-2.7 1.5-2.5 3.1.2 1.4 1.6 2.6 3.1 2.4zM47.4 23.9c0-.1.1-.3.2-.3l5.8-.6c.2 0 .3.1.3.2l.1 1.5c0 .1-.1.3-.2.3l-3.9.4.2 2 3.2-.3c.1 0 .3.1.3.2l.1 1.5c0 .1-.1.3-.2.3l-3.2.3.3 3.4c0 .1-.1.3-.2.3l-1.6.2c-.2 0-.3-.1-.3-.2l-.9-9.2zM59.5 22.3c2.8-.3 5.2 1.8 5.5 4.5.3 2.8-1.7 5.2-4.5 5.5s-5.2-1.7-5.5-4.5 1.7-5.3 4.5-5.5zm.8 7.8c1.5-.1 2.7-1.5 2.5-3-.1-1.5-1.5-2.7-3.1-2.5-1.5.1-2.7 1.5-2.5 3.1.2 1.4 1.5 2.5 3.1 2.4zM66.2 22c0-.1.1-.3.2-.3l4.2-.4c1.7-.2 3.2 1.1 3.3 2.7.1 1.3-.6 2.4-1.8 3l2.3 3.4c.1.2 0 .4-.2.4l-1.8.2c-.1 0-.2-.1-.2-.1L70 27.4l-1 .1.3 3.6c0 .1-.1.3-.2.3l-1.7.2c-.2 0-.3-.1-.3-.2l-.9-9.4zm4.7 3.5c.6-.1 1-.6 1-1.2s-.6-1-1.2-1l-2.1.2.2 2.2 2.1-.2zM78.7 20.8c0-.1.1-.3.2-.3l1.7-.2c.1 0 .3.1.3.2l.9 9.2c0 .1-.1.3-.2.3l-1.7.2c-.1 0-.3-.1-.3-.2l-.9-9.2zM84.7 22l-2 .2c-.2 0-.3-.1-.3-.2l-.1-1.5c0-.1.1-.3.2-.3l6.2-.6c.2 0 .3.1.3.2l.1 1.5c0 .1-.1.3-.2.3l-2 .2.7 7.4c0 .1-.1.3-.2.3l-1.7.2c-.1 0-.3-.1-.3-.2l-.7-7.5zM94.4 24.8L93.3 21l-.2-1.6c0-.1.1-.3.2-.3l2-.2c.1 0 .3.1.3.2l.2 1.6-.3 4c0 .1-.1.2-.2.2l-.6.1c-.1 0-.3-.1-.3-.2zm.7 1.5c.7-.1 1.4.5 1.5 1.2s-.5 1.4-1.2 1.4c-.7.1-1.4-.5-1.4-1.2-.2-.7.4-1.4 1.1-1.4z"
		/>
	</svg>
)
