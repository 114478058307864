import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"

const days = [
	{ tKey: "Monday", number: 0 },
	{ tKey: "Tuesday", number: 1 },
	{ tKey: "Wednesday", number: 2 },
	{ tKey: "Thursday", number: 3 },
	{ tKey: "Friday", number: 4 },
	{ tKey: "Saturday", number: 5 },
]

const dayHours = Array(13).fill("")

export default class WeekTimeranges extends Component {
	findTimeRange = (id, type) => {
		return this.props.timeranges.find(
			entry => entry.id.toString() === id.toString() && entry.type === type
		)
	}
	handleEditTimeRange = e => {
		e.stopPropagation()
		let { type, id } = e.target.dataset
		id = type === "booking" ? id : parseInt(id, 10)
		let timerange = this.findTimeRange(id, type)
		if (!timerange.allowEdition) {
			return
		}
		this.props.handleEditTimeRange(timerange)
	}
	handleRemoveTimeRange = e => {
		e.stopPropagation()
		let { type, id } = e.target.dataset
		id = type === "booking" ? id : parseInt(id, 10)
		this.props.handleRemoveTimeRange(this.findTimeRange(id, type))
	}
	renderDay = day => {
		let { t } = this.context
		let { timeranges } = this.props
		return (
			<div key={day.tKey} className="day">
				<div className="day-timeranges">
					<div className="timeranges">
						{timeranges
							.filter(entry => entry.startDate.day() - 1 === day.number)
							.map((entry, index, dayTimeranges) => {
								let dayMinuteStart =
									(entry.start.hour() - 7) * 60 + entry.start.minutes()
								let className =
									"timerange " + entry.type + " " + (entry.customClass || "")
								let style = {
									top: (dayMinuteStart / 780) * 100 + "%",
									height: (entry.duration / 780) * 100 + "%",
								}
								if (entry.fitWhenOverlap) {
									let conflictIndex
									let conflicts = dayTimeranges.filter(
										dtr =>
											dtr.fitWhenOverlap &&
											dtr.start.isBefore(entry.end) &&
											dtr.end.isAfter(entry.start)
									)
									conflicts.forEach((conflict, index) => {
										if (
											conflict.type === entry.type &&
											conflict.id === entry.id
										) {
											conflictIndex = index
										}
									})
									if (conflicts.length > 1) {
										let percent = 100 / conflicts.length
										// +3 and -6 are for margin
										style.left = percent * conflictIndex + 3 + "%"
										style.width = percent - 6 + "%"
										style.transform = "none"
									}
								}
								return (
									<Fragment key={entry.type + entry.id + index}>
										<div
											className={className}
											style={style}
											onClick={this.handleEditTimeRange}
											data-id={entry.id}
											data-type={entry.type}
										>
											{entry.start.format("HH:mm")} >{" "}
											{entry.end.format("HH:mm")}
											{entry.allowDeletion && (
												<div
													className="remove-button"
													data-id={entry.id}
													data-type={entry.type}
													onClick={this.handleRemoveTimeRange}
												>
													x
												</div>
											)}
										</div>
										{this.renderTooltip(entry, style, t)}
									</Fragment>
								)
							})}
					</div>
				</div>
			</div>
		)
	}
	renderTooltip = (entry, style, t) => {
		return (
			(entry.allowEdition || (entry.extraInfo && entry.extraInfo.length > 0)) && (
				<div className="tooltip" style={{ top: style.top }}>
					{entry.allowEdition && t("click_to_edit")}
					<div className="extra-infos">
						{entry.extraInfo &&
							entry.extraInfo.map(extraInfo => (
								<div key={extraInfo.label} className="extra-info">
									<div className="label">{t(extraInfo.label)}</div>
									&nbsp;
									<div className="value">{t(extraInfo.value)}</div>
								</div>
							))}
					</div>
				</div>
			)
		)
	}
	render() {
		let { t } = this.context
		let { top, bottom } = this.props
		return (
			<div className="week-timeranges">
				<div className="day-labels">
					{days.map(day => (
						<div key={day.tKey} className="day-label">
							{t(day.tKey)}
						</div>
					))}
				</div>
				{top && top}
				<div className="contents">
					<div className="hour-lines">
						{dayHours.map((e, i) => (
							<div key={i} className="hour-line">
								<div className="tooltip">{i + 7 + t("h")}</div>
							</div>
						))}
					</div>
					{days.map(this.renderDay)}
				</div>
				{bottom && bottom}
			</div>
		)
	}
}

WeekTimeranges.contextTypes = { t: PropTypes.func }
