import { useCallback, useEffect, useLayoutEffect } from "react"
import { useLocation } from "react-router-dom"
import { history } from "../history"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"
import moment from "moment"

import * as actions from "../actions"

let initialQueries = {
	weekQuery: {
		urlName: "week",
		queryName: "date_for_week",
		mustBeSet: true,
		defaultValue: moment().format("YYYY-MM-DD"),
	},
	badgesFilters: {
		urlName: "badgesFilters",
		queryName: "filter_badges",
		mustBeSet: false,
	},
	economicUnemploymentFilters: {
		urlName: "economicUnemploymentFilters",
		queryName: "filter_economic_unemployment",
		mustBeSet: false,
	},
	squadsQuery: {
		urlName: "squadsFilters",
		queryName: "squad_ids",
		mustBeSet: true,
	},
	activityType: {
		urlName: "activityType",
		queryName: "activity_type",
		mustBeSet: false,
	},
}

export const useInitAgendaQueries = (fetchAgenda, detachedMode = false) => {
	const dispatch = useDispatch()
	const currUser = useSelector(state => state.redData.currentUser)
	const storedQuery = useSelector(state => state.urlQueriesArchive.Agenda)
	const storedComponentState = useSelector(state => state.componentStateArchive.Agenda)
	const customInitState = useSelector(state => state.redComponents.agenda.customInitState)
	useEffect(() => {
		let parsedStoredQuery = storedQuery && JSON.parse(storedQuery)
		if (!detachedMode && parsedStoredQuery && !_.isEmpty(parsedStoredQuery["queries"])) {
			dispatch(actions.handleURLQueryChanges(parsedStoredQuery))
			dispatch(actions.agendaStateChange({ ...storedComponentState, ...customInitState }))
		} else {
			initialQueries.squadsQuery.defaultValue = currUser.teams.map(entry => entry.id)
			dispatch(actions.addQueries(initialQueries))
			dispatch(actions.agendaStateChange(customInitState))
		}
		if (!detachedMode) {
			fetchAgenda({ resetRows: false, fetchPage: null })
		}
		// eslint-disable-next-line
	}, [])
}

export const useInitAgendaWidth = scrollWrapper => {
	const dispatch = useDispatch()
	const initQueries = useLayoutEffect(() => {
		const resize = () => dispatch(actions.updateAgendaDimensions(scrollWrapper))
		resize()
		window.addEventListener("resize", resize)
		return () => window.removeEventListener("resize", resize)
		// eslint-disable-next-line
	}, [])
	return initQueries
}

export const useFetchAgenda = () => {
	const dispatch = useDispatch()
	const fetchAgenda = useCallback(
		async params => {
			let settings = { resetRows: false, fetchPage: null, ...params }
			await dispatch(actions.fetchEventsAgenda(settings))
		},
		[dispatch]
	)
	return fetchAgenda
}

export const useFetchNextAgendaPage = fetchAgenda => {
	const { pagesCount, fetching, pagesFetched } = useSelector(state => state.redComponents.agenda)
	const fetchNextPage = useCallback(
		_.debounce(() => {
			let maxPageFetched = Math.max(...pagesFetched)
			if (!pagesCount || maxPageFetched === pagesCount || fetching) {
				return
			}
			fetchAgenda({ fetchPage: maxPageFetched + 1 })
		}, 400),
		[fetchAgenda, fetching, pagesCount, pagesFetched]
	)
	return fetchNextPage
}

export const useFetchPreviousAgendaPage = fetchAgenda => {
	const { pagesCount, fetching, pagesFetched } = useSelector(state => state.redComponents.agenda)
	const fetchNextPage = useCallback(
		_.debounce(() => {
			let minPageFetched = Math.min(...pagesFetched)
			if (!pagesCount || minPageFetched === 1 || fetching) {
				return
			}
			fetchAgenda({ fetchPage: minPageFetched - 1 })
		}, 400),
		[fetchAgenda, fetching, pagesCount, pagesFetched]
	)
	return fetchNextPage
}

export const useFetchOnScroll = (scrollWrapper, fetchNextPage, fetchPreviousPage) => {
	useLayoutEffect(() => {
		const handleScroll = async e => {
			// let { agenda: { fullMonthAffiliateWorkerId } } = this.props
			// if (fullMonthAffiliateWorkerId) { return }
			const bottomReached =
				Math.round(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight
			const topReached = e.target.scrollTop === 0
			if (bottomReached) {
				fetchNextPage()
			} else if (topReached) {
				await fetchPreviousPage()
				// this.agendaScrollWrapper.scrollTop = 5
			}
		}
		const el = scrollWrapper.current
		el.addEventListener("scroll", handleScroll)
		return () => el.removeEventListener("scroll", handleScroll)
	})
}

export const useLastActiveTimerange = () => {
	const schedules = useSelector(state => state.redData.agenda.schedules)
	const lastActiveRange = useSelector(state => state.redComponents.agenda.lastActiveRange)
	return useCallback(() => {
		let tr = undefined
		for (let i = schedules.length - 1; i >= 0; i--) {
			let sched = schedules[i]
			if (sched.cleaner.id === lastActiveRange.ci) {
				tr = sched.timeRanges.find(tr => tr.id === lastActiveRange.tid)
				if (tr) {
					break
				}
			}
		}
		return tr
	}, [schedules, lastActiveRange])
}

export const useTimerangeClickHandler = () => {
	const dispatch = useDispatch()
	const handleTimerangeClick = useCallback(
		e => {
			let { affiliateworkerid, cleanerid, timerangeindex, timerangeid } = e.target.dataset
			let range = {
				ti: Number(timerangeindex),
				tid: timerangeid,
				ci: Number(cleanerid),
				awi: Number(affiliateworkerid),
			}
			dispatch(
				actions.agendaStateChange({
					activeTooltipRange: { ...range },
					lastActiveRange: { ...range },
					showAbsenceModal: false,
					showDirectBookingModal: false,
					showExtraAvailabilityModal: false,
					showDeleteExtraAvailModal: false,
					activeWorkerDrop: -1,
				})
			)
		},
		[dispatch]
	)
	return handleTimerangeClick
}

export const useWorkerDropdownToggling = (activeWorkerDrop, cleaner, closeRangeDropdown) => {
	const dispatch = useDispatch()
	const toggleWorkerDropdown = useCallback(() => {
		dispatch(
			actions.agendaStateChange({
				activeWorkerDrop: activeWorkerDrop === cleaner.id ? -1 : cleaner.id,
			})
		)
		closeRangeDropdown()
	}, [dispatch, cleaner.id, activeWorkerDrop, closeRangeDropdown])
	return toggleWorkerDropdown
}

export const useTimerangeDropdownClosing = () => {
	const dispatch = useDispatch()
	const closeRangeDropdown = useCallback(() => {
		dispatch(actions.agendaStateChange({ activeTooltipRange: { ti: -1, ci: -1 } }))
	}, [dispatch])
	return closeRangeDropdown
}

export const useAbsenceModalToggling = (getLastActiveTimerange, closeRangeDropdown) => {
	const dispatch = useDispatch()
	const showAbsenceModal = useSelector(state => state.redComponents.agenda.showAbsenceModal)
	const toggleAbsenceModal = useCallback(() => {
		let tr = getLastActiveTimerange()
		if (showAbsenceModal) {
			closeRangeDropdown()
		}

		dispatch(actions.agendaStateChange({ showAbsenceModal: !showAbsenceModal }))
		dispatch(
			actions.agendaAbsenceChange({
				activityCode: "",
				activityCategory: "",
				absenceNote: "",
				absenceType: "",
				absenceStart: moment(tr.start),
				absenceEnd: moment(tr.end),
				editedAbsenceID: "",
			})
		)
	}, [dispatch, closeRangeDropdown, showAbsenceModal, getLastActiveTimerange])
	return toggleAbsenceModal
}

export const useEditAbsenceModalToggling = (getLastActiveTimerange, closeRangeDropdown) => {
	const dispatch = useDispatch()
	const { showAbsenceModal, lastActiveRange } = useSelector(state => state.redComponents.agenda)
	const schedules = useSelector(state => state.redData.agenda.schedules)
	const { secSocEvents } = useSelector(state => state.redData.currentAffiliate)
	const toggleAbsenceModal = useCallback(() => {
		let tr = getLastActiveTimerange()
		let schedule = schedules.find(sched => sched.cleaner.id === lastActiveRange.ci)
		if (showAbsenceModal) {
			closeRangeDropdown()
		}

		let code = secSocEvents.codes.find(entry => entry.id === tr.code) || {}
		dispatch(actions.agendaStateChange({ showAbsenceModal: !showAbsenceModal }))
		dispatch(
			actions.agendaAbsenceChange({
				activityCode: tr.code,
				activityCategory: code.category_id,
				absenceNote: tr.affiliateNote,
				absenceStart: moment(tr.unavStartDate),
				absenceEnd: moment(tr.unavEndDate).set({ hours: 23, minutes: 55, seconds: 0 }),
				editedAbsenceID: tr.absenceId,
				lastEuAbsenceDate: schedule.cleaner.lastEuAbsenceDate,
			})
		)
	}, [
		dispatch,
		getLastActiveTimerange,
		schedules,
		lastActiveRange,
		closeRangeDropdown,
		secSocEvents.codes,
		showAbsenceModal,
	])
	return toggleAbsenceModal
}

export const useDeleteAbsenceModalToggling = closeRangeDropdown => {
	const dispatch = useDispatch()
	const showDeleteAbsenceModal = useSelector(
		state => state.redComponents.agenda.showDeleteAbsenceModal
	)
	const toggleDeleteAbsenceModal = useCallback(() => {
		if (showDeleteAbsenceModal) {
			closeRangeDropdown()
		}
		dispatch(actions.agendaStateChange({ showDeleteAbsenceModal: !showDeleteAbsenceModal }))
	}, [dispatch, closeRangeDropdown, showDeleteAbsenceModal])
	return toggleDeleteAbsenceModal
}

export const useDeleteHolidayModalToggling = closeRangeDropdown => {
	const dispatch = useDispatch()
	const showDeleteHolidayModal = useSelector(
		state => state.redComponents.agenda.showDeleteHolidayModal
	)
	return useCallback(() => {
		if (showDeleteHolidayModal) {
			closeRangeDropdown()
		}
		dispatch(actions.agendaStateChange({ showDeleteHolidayModal: !showDeleteHolidayModal }))
	}, [dispatch, closeRangeDropdown, showDeleteHolidayModal])
}

export const useRestoreHolidayModalToggling = closeRangeDropdown => {
	const dispatch = useDispatch()
	const showRestoreHolidayModal = useSelector(
		state => state.redComponents.agenda.showRestoreHolidayModal
	)
	return useCallback(() => {
		if (showRestoreHolidayModal) {
			closeRangeDropdown()
		}
		dispatch(actions.agendaStateChange({ showRestoreHolidayModal: !showRestoreHolidayModal }))
	}, [dispatch, closeRangeDropdown, showRestoreHolidayModal])
}

export const useDeleteAbsenceWarningModalToggling = closeRangeDropdown => {
	const dispatch = useDispatch()
	const showDeleteAbsenceWarningModal = useSelector(
		state => state.redComponents.agenda.showDeleteAbsenceWarningModal
	)
	const toggleDeleteAbsenceModal = useCallback(() => {
		if (showDeleteAbsenceWarningModal) {
			closeRangeDropdown()
		}
		dispatch(
			actions.agendaStateChange({
				showDeleteAbsenceWarningModal: !showDeleteAbsenceWarningModal,
			})
		)
	}, [dispatch, closeRangeDropdown, showDeleteAbsenceWarningModal])
	return toggleDeleteAbsenceModal
}

export const useExtraAvailabilityModalToggling = closeRangeDropdown => {
	const dispatch = useDispatch()
	const showExtraAvailabilityModal = useSelector(
		state => state.redComponents.agenda.showExtraAvailabilityModal
	)
	const toggleModal = useCallback(() => {
		if (showExtraAvailabilityModal) {
			closeRangeDropdown()
		}
		dispatch(
			actions.agendaStateChange({ showExtraAvailabilityModal: !showExtraAvailabilityModal })
		)
	}, [dispatch, closeRangeDropdown, showExtraAvailabilityModal])
	return toggleModal
}

export const useDirectBookingModalToggling = (closeRangeDropdown, getLastActiveTimerange) => {
	const dispatch = useDispatch()
	const showDirectBookingModal = useSelector(
		state => state.redComponents.agenda.showDirectBookingModal
	)
	const toggleModal = useCallback(() => {
		if (showDirectBookingModal) {
			closeRangeDropdown()
		}
		dispatch(actions.directBookingModalStateChange({ prefillRange: getLastActiveTimerange() }))
		dispatch(actions.agendaStateChange({ showDirectBookingModal: !showDirectBookingModal }))
	}, [dispatch, closeRangeDropdown, showDirectBookingModal, getLastActiveTimerange])
	return toggleModal
}

export const useBookingPageLink = getLastActiveTimerange => {
	const goToBooking = useCallback(() => {
		history.push({ pathname: "/bookings/" + getLastActiveTimerange().bookingId })
	}, [getLastActiveTimerange])
	return goToBooking
}

export const useAbsencesPageLink = getLastActiveTimerange => {
	const lastActiveRange = useSelector(state => state.redComponents.agenda.lastActiveRange)
	const goToAbsences = useCallback(() => {
		history.push({ pathname: "/cleaners/" + lastActiveRange.awi + "/absences/" })
	}, [lastActiveRange])
	return goToAbsences
}

export const useDeleteExtraAvailabilityModalToggling = closeRangeDropdown => {
	const dispatch = useDispatch()
	const showDeleteExtraAvailModal = useSelector(
		state => state.redComponents.agenda.showDeleteExtraAvailModal
	)
	const toggleModal = useCallback(() => {
		if (showDeleteExtraAvailModal) {
			closeRangeDropdown()
		}
		dispatch(
			actions.agendaStateChange({ showDeleteExtraAvailModal: !showDeleteExtraAvailModal })
		)
	}, [dispatch, closeRangeDropdown, showDeleteExtraAvailModal])
	return toggleModal
}

export const useEditBookingModalToggling = (closeRangeDropdown, getLastActiveTimerange) => {
	const dispatch = useDispatch()
	const showEditBookingModal = useSelector(
		state => state.redComponents.agenda.showEditBookingModal
	)
	const toggleModal = useCallback(() => {
		if (showEditBookingModal) {
			closeRangeDropdown()
			dispatch(actions.agendaStateChange({ showEditBookingModal: false }))
			return
		}
		let tr = getLastActiveTimerange()
		let forDate = false,
			id
		if (tr.parentBookingId) {
			forDate = moment(tr.bookingId.split("_")[1]).format("YYYY-MM-DD")
			id = tr.parentBookingId
		} else {
			id = tr.bookingId
		}
		dispatch(actions.editBookingInit(id, forDate))
		dispatch(actions.agendaStateChange({ showEditBookingModal: true }))
	}, [dispatch, closeRangeDropdown, showEditBookingModal, getLastActiveTimerange])
	return toggleModal
}

export const useCancelBookingModalToggling = (closeRangeDropdown, getLastActiveTimerange) => {
	const dispatch = useDispatch()
	const { pathname, search } = useLocation()
	const showCancelBookingModal = useSelector(
		state => state.redComponents.agenda.showCancelBookingModal
	)
	const toggleModal = useCallback(() => {
		if (showCancelBookingModal) {
			dispatch(actions.agendaStateChange({ showCancelBookingModal: false }))
			closeRangeDropdown()
			return
		}
		let tr = getLastActiveTimerange()
		dispatch(
			actions.cancelBookingModalInit({
				recurrentBooking: tr.recurrent,
				bookingID: tr.bookingId,
				sentToSodexo: tr.sentToSodexo,
				deliveryDate: tr.start.format("YYYY-MM-DD"),
				requestMeta: { from: "agenda", pushToPath: pathname, pushQuery: search },
			})
		)
		dispatch(actions.agendaStateChange({ showCancelBookingModal: true }))
	}, [
		dispatch,
		showCancelBookingModal,
		closeRangeDropdown,
		pathname,
		search,
		getLastActiveTimerange,
	])
	return toggleModal
}

export const useOtherActivityModalToggling = (closeRangeDropdown, getLastActiveTimerange) => {
	const dispatch = useDispatch()
	const showOtherActivityModal = useSelector(
		state => state.redComponents.agenda.showOtherActivityModal
	)
	const toggleModal = useCallback(() => {
		if (showOtherActivityModal) {
			closeRangeDropdown()
		}
		let tr = getLastActiveTimerange()
		dispatch(
			actions.agendaStateChange({
				showOtherActivityModal: !showOtherActivityModal,
				otherActivityInit: {
					startDate: moment(tr.start),
					start: moment(tr.start),
					end: moment(tr.end),
				},
			})
		)
	}, [dispatch, closeRangeDropdown, showOtherActivityModal, getLastActiveTimerange])
	return toggleModal
}

export const useEditOtherActivityModalToggling = (closeRangeDropdown, getLastActiveTimerange) => {
	const dispatch = useDispatch()
	const showEditOtherActivityModal = useSelector(
		state => state.redComponents.agenda.showEditOtherActivityModal
	)
	const toggleModal = useCallback(() => {
		if (showEditOtherActivityModal) {
			closeRangeDropdown()
		}
		let tr = getLastActiveTimerange()
		dispatch(
			actions.agendaStateChange({
				showEditOtherActivityModal: !showEditOtherActivityModal,
				otherActivityInit: {
					startDate: moment(tr.start),
					start: moment(tr.start),
					end: moment(tr.end),
				},
			})
		)
	}, [dispatch, closeRangeDropdown, showEditOtherActivityModal, getLastActiveTimerange])
	return toggleModal
}

export const useWorkshopHourModalToggling = (closeRangeDropdown, getLastActiveTimerange) => {
	const dispatch = useDispatch()
	const { lastActiveRange, showWorkshopHourModal } = useSelector(
		state => state.redComponents.agenda
	)
	const toggleModal = useCallback(() => {
		if (showWorkshopHourModal) {
			closeRangeDropdown()
		}
		let tr = getLastActiveTimerange()
		dispatch(
			actions.workshopHourModalStateChange({
				chosenWorker: { name: "", affiliateWorkerId: lastActiveRange.awi },
				startDate: moment(tr.start),
				start: moment(tr.start),
				end: moment(tr.end),
			})
		)
		dispatch(actions.agendaStateChange({ showWorkshopHourModal: !showWorkshopHourModal }))
	}, [
		dispatch,
		closeRangeDropdown,
		showWorkshopHourModal,
		getLastActiveTimerange,
		lastActiveRange,
	])
	return toggleModal
}

export const useEditWorkshopHourModalToggling = (closeRangeDropdown, getLastActiveTimerange) => {
	const dispatch = useDispatch()
	const { lastActiveRange, showEditWorkshopHourModal } = useSelector(
		state => state.redComponents.agenda
	)
	const toggleModal = useCallback(() => {
		if (showEditWorkshopHourModal) {
			closeRangeDropdown()
		}
		let tr = getLastActiveTimerange()
		dispatch(
			actions.workshopHourModalStateChange({
				chosenWorker: { name: "", affiliateWorkerId: lastActiveRange.awi },
				startDate: moment(tr.start),
				start: moment(tr.start),
				end: moment(tr.end),
			})
		)
		dispatch(
			actions.agendaStateChange({ showEditWorkshopHourModal: !showEditWorkshopHourModal })
		)
	}, [
		dispatch,
		closeRangeDropdown,
		showEditWorkshopHourModal,
		getLastActiveTimerange,
		lastActiveRange,
	])
	return toggleModal
}

export const useShowLaundryModalToggling = (closeRangeDropdown, getLastActiveTimerange) => {
	const dispatch = useDispatch()
	const { lastActiveRange, showLaundryModal } = useSelector(state => state.redComponents.agenda)
	const toggleModal = useCallback(() => {
		if (showLaundryModal) {
			closeRangeDropdown()
		}
		let tr = getLastActiveTimerange()
		dispatch(
			actions.workshopHourModalStateChange({
				chosenWorker: { name: "", affiliateWorkerId: lastActiveRange.awi },
				startDate: moment(tr.start),
				start: moment(tr.start),
				end: moment(tr.end),
			})
		)
		dispatch(actions.agendaStateChange({ showLaundryModal: !showLaundryModal }))
	}, [dispatch, closeRangeDropdown, showLaundryModal, getLastActiveTimerange, lastActiveRange])
	return toggleModal
}

export const useDeleteOtherActivityModalToggling = (closeRangeDropdown, getLastActiveTimerange) => {
	const dispatch = useDispatch()
	const { lastActiveRange, showDeleteOtherActivityModal } = useSelector(
		state => state.redComponents.agenda
	)
	const schedules = useSelector(state => state.redData.agenda.schedules)
	const toggleModal = useCallback(async () => {
		if (showDeleteOtherActivityModal) {
			closeRangeDropdown()
			dispatch(actions.agendaStateChange({ showDeleteOtherActivityModal: false }))
			return
		}
		let schedule = schedules.find(sched => sched.cleaner.id === lastActiveRange.ci)
		let tr = getLastActiveTimerange()
		await dispatch(
			actions.deleteOtherActivityModalInit({
				contractID: schedule.cleaner.id,
				affiliateWorkerID: lastActiveRange.awi,
				...tr,
			})
		)
		dispatch(actions.agendaStateChange({ showDeleteOtherActivityModal: true }))
	}, [
		dispatch,
		schedules,
		closeRangeDropdown,
		showDeleteOtherActivityModal,
		getLastActiveTimerange,
		lastActiveRange,
	])
	return toggleModal
}

export const useDirectBookingModificationModalToggling = (
	closeRangeDropdown,
	getLastActiveTimerange
) => {
	const dispatch = useDispatch()
	const showDirectBookingModificationModal = useSelector(
		state => state.redComponents.agenda.showDirectBookingModificationModal
	)
	const { weekStart } = useSelector(state => state.redData.agenda)
	const { activeTooltipRange } = useSelector(state => state.redComponents.agenda)
	const toggleModal = useCallback(() => {
		let booking = getLastActiveTimerange()
		dispatch(
			actions.directBookingModificationStateChange({
				bookingID: booking.bookingId,
				sentToSodexo: booking.sentToSodexo,
				startTime: moment(booking.start),
				endTime: moment(booking.end),
				dayPeriod: booking.dayPeriod,
				voucherType: booking.voucherType,
				customerContractId: booking.customerContractId,
				postSubmitAction: actions.refetchAgendaLines(
					[activeTooltipRange.ci],
					weekStart.format()
				),
				addressId: booking.addressId,
				ssCode: booking.code,
			})
		)
		if (showDirectBookingModificationModal) {
			closeRangeDropdown()
		}
		dispatch(
			actions.agendaStateChange({
				showDirectBookingModificationModal: !showDirectBookingModificationModal,
			})
		)
	}, [
		dispatch,
		showDirectBookingModificationModal,
		closeRangeDropdown,
		activeTooltipRange,
		weekStart,
		getLastActiveTimerange,
	])
	return toggleModal
}

export const useAddMissingLaundryOrderModalToggling = (
	closeRangeDropdown,
	getLastActiveTimerange
) => {
	const dispatch = useDispatch()
	const { lastActiveRange, showMissingLaundryOrderModal } = useSelector(
		state => state.redComponents.agenda
	)
	const schedules = useSelector(state => state.redData.agenda.schedules)
	const toggleModal = useCallback(() => {
		let schedule = schedules.find(sched => sched.cleaner.id === lastActiveRange.ci)
		let tr = getLastActiveTimerange()
		dispatch(
			actions.missingLaundryOrderModalStateChange({
				workerDisplayName: schedule.cleaner.name,
				workerContractId: lastActiveRange.ci,
				executionDate: moment(tr.start),
				workshopShiftId: tr.realEventId,
				chosenCustomer: {},
			})
		)
		dispatch(
			actions.agendaStateChange({
				showMissingLaundryOrderModal: !showMissingLaundryOrderModal,
			})
		)
		if (showMissingLaundryOrderModal) {
			closeRangeDropdown()
		}
	}, [
		dispatch,
		showMissingLaundryOrderModal,
		closeRangeDropdown,
		lastActiveRange.ci,
		schedules,
		getLastActiveTimerange,
	])
	return toggleModal
}

export const useHandleAbsenceModalChange = () => {
	const dispatch = useDispatch()
	const handler = useCallback(
		changes => {
			dispatch(actions.agendaAbsenceChange(changes))
		},
		[dispatch]
	)
	return handler
}

export const useSubmitAbsenceWithoutImpactedBookings = () => {
	const dispatch = useDispatch()
	const { currentAbsence } = useSelector(state => state.redData.agenda)
	const handler = useCallback(() => {
		let data = {
			activity_code: currentAbsence.activityCode,
			activity_category: currentAbsence.activityCategory,
			start_time: currentAbsence.absenceStart.format(),
			end_time: currentAbsence.absenceEnd.format(),
			affiliate_note: currentAbsence.absenceNote,
			motif_type: Number(currentAbsence.absenceType),
			form_c32a_number: currentAbsence.formC32ANumber,
			must_call_onss: currentAbsence.mustCallOnss,
			remove_other_activities: true,
		}
		dispatch(actions.submitAbsenceFromAgenda(data))
	}, [dispatch, currentAbsence])
	return handler
}

export const useSubmitDeleteExtraAvail = getLastActiveTimerange => {
	const dispatch = useDispatch()
	const { lastActiveRange } = useSelector(state => state.redComponents.agenda)
	const {
		urlQuery: { week },
	} = useSelector(state => state.currentURLQuery)
	const handler = useCallback(() => {
		let extraAvailRange = getLastActiveTimerange()
		let init = { method: "PUT", body: JSON.stringify({ week: week, delete: "true" }) }
		let url = "/front/affiliates/extra_availabilities/" + extraAvailRange.extraAvailId
		dispatch(actions.simpleFetch(url, init, "DELETEEXTRAAVAIL")).then(({ actionType }) => {
			if (actionType === actions.DELETEEXTRAAVAIL_SUCCESS) {
				let currWeek = moment(week, "YYYY-MM-DD")
				dispatch(actions.refetchAgendaLines([lastActiveRange.ci], currWeek.format()))
			}
		})
	}, [dispatch, getLastActiveTimerange, lastActiveRange.ci, week])
	return handler
}

export const useSubmitDeleteAbsence = (getLastActiveTimerange, toggleDeleteAbsenceModal) => {
	const dispatch = useDispatch()
	const { lastActiveRange, absencePendingCancel } = useSelector(
		state => state.redComponents.agenda
	)
	const data = useSelector(state => state.redData.agenda)
	const {
		urlQuery: { week },
	} = useSelector(state => state.currentURLQuery)
	const handler = useCallback(
		e => {
			let { kbc } = e.target.dataset
			let body = {}
			if (kbc) {
				body.keep_bookings_cancelled = kbc
			}
			let schedule = data.schedules.find(sched => sched.cleaner.id === lastActiveRange.ci)
			let tr = getLastActiveTimerange()
			if (absencePendingCancel > 0) {
				tr = schedule.timeRanges.find(entry => entry.absenceId === absencePendingCancel)
			}
			let url =
				"/front/affiliates/affiliate_workers/" +
				lastActiveRange.awi +
				"/unavailabilities/" +
				tr.absenceId
			let init = { method: "DELETE", body: JSON.stringify(body) }
			dispatch(
				actions.simpleFetch(url, init, "CANCELABSENCE_FROMAGENDA", {
					absenceId: tr.absenceId,
				})
			).then(({ actionType }) => {
				if (actionType === actions.CANCELABSENCE_FROMAGENDA_SUCCESS) {
					let currWeek = moment(week, "YYYY-MM-DD")
					dispatch(
						actions.agendaStateChange({
							showDeleteAbsenceWarningModal: false,
							showDeleteAbsenceModal: false,
							absencePendingCancel: -1,
						})
					)
					dispatch(actions.refetchAgendaLines([lastActiveRange.ci], currWeek.format()))
				}
			})
			toggleDeleteAbsenceModal()
		},
		[
			dispatch,
			toggleDeleteAbsenceModal,
			absencePendingCancel,
			data.schedules,
			lastActiveRange.awi,
			getLastActiveTimerange,
			lastActiveRange.ci,
			week,
		]
	)
	return handler
}
export const useSubmitDeleteHoliday = (getLastActiveTimerange, toggleDeleteHolidayModal) => {
	const dispatch = useDispatch()
	const { lastActiveRange } = useSelector(state => state.redComponents.agenda)
	const {
		urlQuery: { week },
	} = useSelector(state => state.currentURLQuery)
	return useCallback(
		e => {
			let tr = getLastActiveTimerange()
			let url =
				"/front/affiliates/affiliate_workers/" + lastActiveRange.awi + "/excluded_holidays"
			let init = { method: "POST", body: JSON.stringify({ holiday_id: tr.absenceId }) }
			dispatch(actions.simpleFetch(url, init, "DELETEHOLIDAY_FROMWORKER")).then(
				({ actionType }) => {
					if (actionType === actions.DELETEHOLIDAY_FROMWORKER_SUCCESS) {
						let currWeek = moment(week, "YYYY-MM-DD")
						dispatch(
							actions.agendaStateChange({
								showDeleteHolidayModal: false,
							})
						)
						dispatch(
							actions.refetchAgendaLines([lastActiveRange.ci], currWeek.format())
						)
					}
				}
			)
			toggleDeleteHolidayModal()
		},
		[
			dispatch,
			toggleDeleteHolidayModal,
			lastActiveRange.awi,
			getLastActiveTimerange,
			lastActiveRange.ci,
			week,
		]
	)
}
export const useSubmitRestoreHoliday = (
	getLastActiveTimerange,
	excludedHoliday,
	toggleRestoreHolidayModal
) => {
	const dispatch = useDispatch()
	const { lastActiveRange } = useSelector(state => state.redComponents.agenda)
	const {
		urlQuery: { week },
	} = useSelector(state => state.currentURLQuery)
	return useCallback(
		e => {
			let tr = getLastActiveTimerange()
			let url = excludedHoliday
				? "/front/affiliates/affiliate_workers/" +
				  lastActiveRange.awi +
				  "/excluded_holidays/" +
				  excludedHoliday.id
				: null
			let init = { method: "DELETE" }
			if (url) {
				dispatch(
					actions.simpleFetch(url, init, "RESTOREHOLIDAY_FROMWORKER", {
						absenceId: tr.absenceId,
					})
				).then(({ actionType }) => {
					if (actionType === actions.RESTOREHOLIDAY_FROMWORKER_SUCCESS) {
						let currWeek = moment(week, "YYYY-MM-DD")
						dispatch(
							actions.agendaStateChange({
								showRestoreHolidayModal: false,
							})
						)
						dispatch(
							actions.refetchAgendaLines([lastActiveRange.ci], currWeek.format())
						)
					}
				})
			}
			toggleRestoreHolidayModal()
		},
		[
			getLastActiveTimerange,
			excludedHoliday,
			lastActiveRange.awi,
			lastActiveRange.ci,
			toggleRestoreHolidayModal,
			dispatch,
			week,
		]
	)
}

export const useSumitWorkshopHour = () => {
	const dispatch = useDispatch()
	const { lastActiveRange } = useSelector(state => state.redComponents.agenda)
	const {
		urlQuery: { week },
	} = useSelector(state => state.currentURLQuery)
	const handler = useCallback(() => {
		dispatch(actions.submitWorkshopHourFromAgenda(lastActiveRange.ci, week))
	}, [dispatch, lastActiveRange.ci, week])
	return handler
}

export const useEditSumitWorkshopHour = () => {
	const dispatch = useDispatch()
	const { lastActiveRange } = useSelector(state => state.redComponents.agenda)
	const {
		urlQuery: { week },
	} = useSelector(state => state.currentURLQuery)
	const handler = useCallback(() => {
		let id = lastActiveRange.tid.split("__")[0]
		dispatch(actions.submitEditWorkshopHourFromAgenda(lastActiveRange.ci, week, id))
	}, [dispatch, lastActiveRange.ci, lastActiveRange.tid, week])
	return handler
}

export const useSubmitOtherActivity = () => {
	const dispatch = useDispatch()
	const { lastActiveRange } = useSelector(state => state.redComponents.agenda)
	const {
		urlQuery: { week },
	} = useSelector(state => state.currentURLQuery)
	const handler = useCallback(
		otherActivity => {
			dispatch(
				actions.submitOtherActivityForContractID(
					otherActivity,
					lastActiveRange.ci,
					lastActiveRange.awi,
					week
				)
			)
		},
		[dispatch, lastActiveRange.ci, lastActiveRange.awi, week]
	)
	return handler
}

export const useEditSubmitOtherActivity = () => {
	const dispatch = useDispatch()
	const { lastActiveRange } = useSelector(state => state.redComponents.agenda)
	const {
		urlQuery: { week },
	} = useSelector(state => state.currentURLQuery)
	const handler = useCallback(
		otherActivity => {
			dispatch(
				actions.submitEditOtherActivityForContractID(
					otherActivity,
					lastActiveRange.ci,
					lastActiveRange.awi,
					week
				)
			)
		},
		[dispatch, lastActiveRange.ci, lastActiveRange.awi, week]
	)
	return handler
}

export const useSelectFilterChangeHandler = fetchAgenda => {
	const dispatch = useDispatch()
	const handler = useCallback(
		async e => {
			let { name, value } = e.target
			if (value === "") {
				value = []
			}
			await dispatch(actions.updateQuery({ name, value }))
			fetchAgenda({ resetRows: false })
		},
		[dispatch, fetchAgenda]
	)
	return handler
}

export const useHandleSquadFilterChange = fetchAgenda => {
	const dispatch = useDispatch()
	const handler = useCallback(
		async update => {
			await dispatch(actions.updateQuery(update))
			fetchAgenda({ resetRows: false })
		},
		[dispatch, fetchAgenda]
	)
	return handler
}

export const useWeekNavigationHandler = fetchAgenda => {
	const dispatch = useDispatch()
	const handler = useCallback(
		async changes => {
			let query = { name: "weekQuery", value: changes.agendaWeek.format("YYYY-MM-DD") }
			await dispatch(actions.updateQuery(query))
			fetchAgenda({ resetRows: true })
		},
		[fetchAgenda, dispatch]
	)
	return handler
}

export const useWeekPickerToggling = () => {
	const dispatch = useDispatch()
	const { showWeekPicker } = useSelector(state => state.redComponents.agenda)
	const handler = useCallback(() => {
		dispatch(actions.agendaStateChange({ showWeekPicker: !showWeekPicker }))
	}, [dispatch, showWeekPicker])
	return handler
}

export const useLeaveWorkerFullMonth = fetchAgenda => {
	const dispatch = useDispatch()
	const handler = useCallback(() => {
		dispatch(actions.agendaStateChange({ fullMonthAffiliateWorkerId: undefined }))
		fetchAgenda({ resetRows: true })
	}, [dispatch, fetchAgenda])
	return handler
}

export const useWorkerPrefilledBooking = () => {
	const dispatch = useDispatch()
	const { schedules } = useSelector(state => state.redData.agenda)
	const handler = useCallback(
		e => {
			let { workerId } = e.target.dataset
			let schedule = schedules.find(sched => sched.cleaner.id === Number(workerId))
			history.push({ pathname: "/bookings/new" })
			dispatch(
				actions.prefillNewBookingPage({
					specificWorker: {
						name: schedule.cleaner.name,
						sodexo: schedule.cleaner.sodexoNumber,
					},
				})
			)
		},
		[dispatch, schedules]
	)
	return handler
}

export const useDisplayWorkerFullMonth = (toggleWorkerDropdown, fetchAgenda) => {
	const dispatch = useDispatch()
	const { customFullMonthRequest } = useSelector(state => state.redComponents.agenda)
	const handler = useCallback(
		e => {
			let { workerId } = e.target.dataset
			dispatch(actions.agendaStateChange({ fullMonthAffiliateWorkerId: workerId }))
			toggleWorkerDropdown()
			if (customFullMonthRequest) {
				customFullMonthRequest()
			} else {
				fetchAgenda({ resetRows: true })
			}
		},
		[dispatch, toggleWorkerDropdown, fetchAgenda, customFullMonthRequest]
	)
	return handler
}

export const useCustomerFilterHandler = fetchAgenda => {
	const dispatch = useDispatch()
	const { customersFilters } = useSelector(state => state.redComponents.agenda)
	const handler = useCallback(
		customer => {
			dispatch(
				actions.agendaStateChange({
					customersFilters: [
						...customersFilters,
						{
							customerContractId: customer.id,
							value: customer.id,
							label: customer.attributes.display_name,
						},
					],
				})
			)
			fetchAgenda({ resetRows: true })
		},
		[dispatch, fetchAgenda, customersFilters]
	)
	return handler
}

export const useRemoveCustomerFilter = fetchAgenda => {
	const dispatch = useDispatch()
	const { customersFilters } = useSelector(state => state.redComponents.agenda)
	const handler = useCallback(
		customer => {
			dispatch(
				actions.agendaStateChange({
					customersFilters: customersFilters.filter(
						entry => entry.value !== customer.value
					),
				})
			)
			fetchAgenda({ resetRows: true })
		},
		[dispatch, fetchAgenda, customersFilters]
	)
	return handler
}

export const useWorkerFilterHandler = fetchAgenda => {
	const dispatch = useDispatch()
	const { workersFilters } = useSelector(state => state.redComponents.agenda)
	const handler = useCallback(
		worker => {
			dispatch(
				actions.agendaStateChange({
					workersFilters: [
						...workersFilters,
						{
							affiliateWorkerId: worker.id,
							value: worker.id,
							label: worker.attributes.display_name,
						},
					],
				})
			)
			fetchAgenda({ resetRows: true })
		},
		[dispatch, fetchAgenda, workersFilters]
	)
	return handler
}

export const useRemoveWorkerFilter = fetchAgenda => {
	const dispatch = useDispatch()
	const { workersFilters } = useSelector(state => state.redComponents.agenda)
	const handler = useCallback(
		worker => {
			dispatch(
				actions.agendaStateChange({
					workersFilters: workersFilters.filter(entry => entry.value !== worker.value),
				})
			)
			fetchAgenda({ resetRows: true })
		},
		[dispatch, fetchAgenda, workersFilters]
	)
	return handler
}

export const useResetFilters = fetchAgenda => {
	const dispatch = useDispatch()
	const handler = useCallback(() => {
		dispatch(actions.resetAgendaComponentFilters())
		dispatch(actions.replaceQueries(initialQueries))
		fetchAgenda()
	}, [dispatch, fetchAgenda])
	return handler
}

export const useChangeFullMonthWorker = fetchAgenda => {
	const dispatch = useDispatch()
	const handler = useCallback(
		worker => {
			dispatch(actions.agendaStateChange({ fullMonthAffiliateWorkerId: worker.id }))
			fetchAgenda({ resetRows: true })
		},
		[dispatch, fetchAgenda]
	)
	return handler
}

export const useToggleFilterDropdown = () => {
	const dispatch = useDispatch()
	let filtersDropdownActive = useSelector(
		state => state.redComponents.agenda.filtersDropdownActive
	)
	const handler = useCallback(
		worker => {
			dispatch(actions.agendaStateChange({ filtersDropdownActive: !filtersDropdownActive }))
		},
		[dispatch, filtersDropdownActive]
	)
	return handler
}

export const useGoToWorkerPage = () => {
	return useCallback(e => {
		let { workerId } = e.target.dataset
		history.push({ pathname: "cleaners/" + workerId + "/profile" })
	}, [])
}

export const useSubmitImpactedBookingsActionsForOA = () => {
	const dispatch = useDispatch()
	const { lastActiveRange } = useSelector(state => state.redComponents.agenda)
	const {
		urlQuery: { week },
	} = useSelector(state => state.currentURLQuery)
	const handler = useCallback(
		otherActivity => {
			dispatch(
				actions.submitOAWithImpactedBookings(lastActiveRange.ci, lastActiveRange.awi, week)
			)
		},
		[dispatch, lastActiveRange.ci, lastActiveRange.awi, week]
	)
	return handler
}

export const useSubmitAbsenceWithImpactedBookings = () => {
	const dispatch = useDispatch()
	const { currentAbsence } = useSelector(state => state.redData.agenda)
	const handler = useCallback(
		({ impactedBookings, keepImpactedOAs, forceUnlink }) => {
			let data = {
				activity_code: currentAbsence.activityCode,
				activity_category: currentAbsence.activityCategory,
				start_time: currentAbsence.absenceStart.format(),
				end_time: currentAbsence.absenceEnd.format(),
				affiliate_note: currentAbsence.absenceNote,
				motif_type: currentAbsence.absenceType,
				form_c32a_number: currentAbsence.formC32ANumber,
				must_call_onss: currentAbsence.mustCallOnss,
				remove_other_activities: keepImpactedOAs === "no",
				force_unlink: forceUnlink,
				impacted_bookings_actions: actions.denormalizeImpactedBookingActions(
					impactedBookings
				),
			}
			dispatch(actions.submitAbsenceFromAgenda(data))
		},
		[dispatch, currentAbsence]
	)
	return handler
}
