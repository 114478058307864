import PropTypes from "prop-types"
import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import moment from "moment"
import { Link } from "react-router-dom"

import * as actions from "../actions"

import ChatRoom from "./ChatRoom"
import { formatMinutes } from "../helpers/timeFormatting"
import ModalLayout from "../components/ModalLayout"
import DeleteLaundryOrderModal from "./DeleteLaundryOrderModal"
import { OrderTicketPrint } from "./OrderTicketPrint"
import PSVScanner from "./PSVScanner"
import {
	ContentLayout,
	OptionBox,
	PageLayout,
	PootsyButton,
	PootsyMultipleValuesBox,
	PootsySubHeading,
	SimpleInfoBox,
	CustomerBalanceDisplayer,
	ConfirmationModal,
} from "./FunctionalDesign"
import { PootsySelectInput } from "./FunctionalInputs"
import UpdateWorkshopOrderModal from "./UpdateWorkshopOrderModal"
import FinishWorkshopOrderModal from "./FinishWorkshopOrderModal"
import OrderPaymentModal from "./OrderPaymentModal"
import WorkshopOrderQuoteDisplayer from "./WorkshopOrderQuoteDisplayer"
import LinkedPSVsDisplayer from "./LinkedPSVsDisplayer"
import ImpactedBookingsModal from "./ImpactedBookingsModal"

import arrowBackIcon from "../static/images/arrowback-icon.png"
import priorityIcon from "../static/images/priority-icon.png"

class WorkshopOrderDetails extends Component {
	UNSAFE_componentWillMount = () => {
		let { dispatch, match } = this.props
		dispatch(actions.fetchWorkshopOrderDetails(match.params.id))
	}
	handleChanges = changes => {
		this.props.dispatch(actions.workshopOrderDetailsStateChange(changes))
	}
	handleCurrentOrderChanges = changes => {
		this.props.dispatch(actions.editWorkshopOrder(changes))
	}
	sendMessage = (currMess, chatImg) => {
		let {
			currentWorkshopOrder: { customer },
		} = this.props
		this.props.dispatch(actions.submitCustomerMessage(customer.id, currMess, chatImg))
	}
	toggleDeleteWorkshopOrderModal = id => {
		this.handleChanges({
			showDeleteOrderModal: !this.props.component.showDeleteOrderModal,
			idStagedForDeletion: id,
		})
	}
	toggleDeletePsvConfirmationModal = e => {
		let {
			component: { showDeletePsvConfirmationModal },
		} = this.props
		let changes = {
			showDeletePsvConfirmationModal: !showDeletePsvConfirmationModal,
			psvStagedForDeletion: undefined,
		}
		if (e) {
			changes.psvStagedForDeletion = e.target.dataset.psvid
		}
		this.handleChanges(changes)
	}
	togglePaymentModal = () => {
		let {
			currentWorkshopOrder: { estimatedPrice },
			component: { showPaymentModal },
		} = this.props
		this.handleCurrentOrderChanges({ cashReceived: !showPaymentModal ? estimatedPrice : 0 })
		this.handleChanges({ showPaymentModal: !showPaymentModal })
	}
	toggleStartWorkshopOrderModal = () => {
		let {
			component: { showStartOrderModal },
		} = this.props
		this.handleChanges({ showStartOrderModal: !showStartOrderModal })
	}
	toggleRestartWorkshopOrderModal = () => {
		let {
			component: { showRestartOrderModal },
		} = this.props
		this.handleChanges({ showRestartOrderModal: !showRestartOrderModal })
	}
	toggleFinishWorkshopOrderModal = () => {
		let {
			dispatch,
			component: { showFinishOrderModal },
			currentWorkshopOrder: { startTime },
		} = this.props
		let now = moment()
		let defaultOrderDuration = now.diff(startTime, "minutes")
		dispatch(
			actions.editWorkshopOrder({
				endTime: now,
				executedDurationMinutes: defaultOrderDuration,
				workshopOrderBillingBasis: "executed",
			})
		)
		this.handleChanges({ showFinishOrderModal: !showFinishOrderModal })
	}
	toggleCancelWorkshopOrderModal = ({ show }) => {
		let {
			component: { showCancelOrderModal },
		} = this.props
		if (show === undefined) {
			this.handleChanges({ showCancelOrderModal: !showCancelOrderModal })
		} else {
			this.handleChanges({ showCancelOrderModal: show })
		}
	}
	toggleUpdateWorkshopOrderModal = () => {
		let {
			dispatch,
			currentWorkshopOrder,
			component: { showUpdateOrderModal },
		} = this.props
		if (!showUpdateOrderModal) {
			dispatch(
				actions.updateWorkshopOrderModalStateChange({
					scheduledPickupDate: currentWorkshopOrder.scheduledPickupDate,
					items: currentWorkshopOrder.items,
				})
			)
		}
		this.handleChanges({ showUpdateOrderModal: !showUpdateOrderModal })
	}
	toggleAddPSVModal = () => {
		let {
			component: { showAddPSVModal },
		} = this.props
		this.handleChanges({ showAddPSVModal: !showAddPSVModal })
	}
	submitStartWorkshopOrder = () => {
		let { dispatch, currentWorkshopOrder } = this.props
		dispatch(
			actions.submitUpdateWorkshopOrder({
				...currentWorkshopOrder,
				status: "IN_PROGRESS",
				startTime: moment(),
			})
		)
		this.toggleStartWorkshopOrderModal()
	}
	submitRestartWorkshopOrder = () => {
		let { dispatch, currentWorkshopOrder } = this.props
		dispatch(actions.submitUpdateWorkshopOrder({ ...currentWorkshopOrder, status: "PLANNED" }))
		this.toggleRestartWorkshopOrderModal()
	}
	submitCancelWorkshopOrder = () => {
		let { dispatch, currentWorkshopOrder } = this.props
		dispatch(
			actions.submitUpdateWorkshopOrder(
				{ ...currentWorkshopOrder, status: "CANCELLED" },
				{ customAction: "CANCEL_WORKSHOP_ORDER" }
			)
		)
		this.toggleCancelWorkshopOrderModal({ show: false })
	}
	submitUpdateWorkshopOrder = (newOrder, options = {}) => {
		let { dispatch } = this.props
		dispatch(actions.submitUpdateWorkshopOrder(newOrder, options))
		this.toggleUpdateWorkshopOrderModal()
	}
	submitUpdateWithImpactedActions = () => {
		let {
			dispatch,
			component: { pendingUpdatedWorkshopOrder },
		} = this.props
		dispatch(actions.submitUpdateWorkshopOrder(pendingUpdatedWorkshopOrder))
	}
	submitOrderPayment = () => {
		let { dispatch, currentWorkshopOrder: order } = this.props
		if (order.svType === "paper") {
			dispatch(
				actions.sendSodexoPSV({
					subjectType: "laundry_order",
					subjectID: order.id,
					workerSodexoRef: order.worker.sodexoRef,
					customerSodexoRef: order.customer.sodexoRef,
				})
			)
		} else if (order.svType === "electronic") {
			// I guess nothing...?
		}
		dispatch(actions.submitUpdateWorkshopOrder({ ...order, effectivePickUpDate: moment() }))
		this.togglePaymentModal()
	}
	submitDeleteOrder = async () => {
		let { dispatch, currentWorkshopOrder } = this.props
		let { actionType } = await dispatch(
			actions.submitDeleteLaundryOrder(currentWorkshopOrder.id)
		)
		if (actionType === actions.SUBMIT_DELETE_WORKSHOP_ORDER_SUCCESS) {
			this.props.history.goBack()
		}
	}
	destroyPSV = e => {
		let {
			dispatch,
			component: { psvStagedForDeletion },
		} = this.props
		dispatch(actions.destroyPSV(psvStagedForDeletion))
		this.toggleDeletePsvConfirmationModal()
	}
	handleSvTypeChange = e => {
		e.stopPropagation()
		let { dispatch, currentWorkshopOrder } = this.props
		dispatch(
			actions.submitUpdateWorkshopOrder({
				...currentWorkshopOrder,
				svType: e.target.value,
			})
		)
	}
	optionBoxes = () => {
		let { t } = this.context
		let {
			currentWorkshopOrder: {
				id,
				worker,
				status,
				svType,
				startTime,
				endTime,
				executedDurationMinutes,
				paymentMethod,
				sodexoWorkId,
			},
			currentWorkshopOrder,
			currentAffiliate,
		} = this.props

		let optionBoxTitle = (
			<div className="box-title"> {t("workshop_order_detail_status_title")} </div>
		)

		return (
			<div className="option-boxes">
				<OptionBox boxTitle={optionBoxTitle}>
					<div className={"status " + status}>{t("workshop_order_status_" + status)}</div>
					{startTime && (
						<div className="execution-times">
							<div>{t("started_at") + " " + startTime.format("HH:mm")}</div>
							<div>
								{status === "IN_PROGRESS" &&
									moment().diff(startTime, "minutes") + " " + t("minutes_ago")}
							</div>
							<div>
								{endTime &&
									status === "DONE" &&
									t("finished_at") + " " + endTime.format("HH:mm")}
							</div>
							<div>
								{executedDurationMinutes &&
									status === "DONE" &&
									t("the_order_lasted") +
										" " +
										formatMinutes(
											executedDurationMinutes,
											t("hours_shorthand")
										)}
							</div>
						</div>
					)}
					<PootsySelectInput
						name="sv_type"
						defaultText={t("voucher_type")}
						selected={svType}
						onChange={this.handleSvTypeChange}
						disable={["DONE", "COLLECTED"].includes(status)}
						options={[
							{ label: t("electronic"), value: "electronic" },
							{ label: t("paper"), value: "paper" },
						]}
					/>
					<PootsyMultipleValuesBox
						values={[{ label: t("payment_method"), values: [t(paymentMethod)] }]}
					/>
					{currentWorkshopOrder.customer.balance &&
						(["COLLECTED"].includes(status) ? (
							<OrderTicketPrint
								order={currentWorkshopOrder}
								affiliate={currentAffiliate}
								t={t}
								ticketType="payment_ticket"
							/>
						) : (
							<OrderTicketPrint
								order={currentWorkshopOrder}
								affiliate={currentAffiliate}
								t={t}
								ticketType="quote_ticket"
							/>
						))}
					{svType === "electronic" && sodexoWorkId && (
						<div className="sodexo-work-id">
							<PootsyMultipleValuesBox
								values={[
									{
										label: t("sodexo_work_id"),
										values: [sodexoWorkId],
									},
								]}
							/>
						</div>
					)}
				</OptionBox>
				<OptionBox key="info" boxTitle={t("cleaner_info")}>
					<PootsyMultipleValuesBox
						values={[
							{ label: t("first_name"), values: [worker.firstName] },
							{ label: t("last_name"), values: [worker.lastName] },
							{ label: t("phone"), values: [worker.telephone] },
							{ label: t("email"), values: [worker.email] },
						]}
					/>
					<Link to={"/cleaners/" + worker.id + "/profile"} target="_blank">
						<PootsyButton text={t("go_to_worker_page")} />
					</Link>
				</OptionBox>
				<OptionBox key="danger" boxTitle="DANGER ZONE" customClass="danger-zone">
					<PootsyButton
						text={t("delete_workshop_order")}
						theme="red"
						onClick={() => this.toggleDeleteWorkshopOrderModal(id)}
					/>
				</OptionBox>
			</div>
		)
	}
	customerInformation = () => {
		let { t } = this.context
		let {
			currentWorkshopOrder: { customer },
		} = this.props
		if (!customer.balance) {
			return null
		}
		return (
			<div key="customer" className="customer-infos">
				<PootsyMultipleValuesBox
					values={[
						{
							label: t("customer"),
							values: [
								customer.firstName + " " + customer.lastName,
								customer.telephone,
								customer.email,
							],
						},
						{
							label: t("customer_sodexo_reference"),
							values: [customer.sodexoRef],
						},
						{
							label: t("voucher_type_preference"),
							values: [
								customer.voucherTypePrefence
									? customer.voucherTypePrefence
									: t("unknown"),
							],
						},
					]}
				/>
				<CustomerBalanceDisplayer balance={customer.balance} />
				<Link to={"/customers/" + customer.id + "/profile"} target="_blank">
					<PootsyButton text={t("go_to_customer_page")} />
				</Link>
			</div>
		)
	}
	orderLinkedPSVs = () => {
		let { t } = this.context
		let {
			currentWorkshopOrder: { status, svType, linkedPSVs, billableSV },
		} = this.props
		if (svType !== "paper" || !["DONE", "COLLECTED"].includes(status)) return
		return [
			<LinkedPSVsDisplayer
				key="linked-psv"
				expectedCount={billableSV}
				currentPSVs={linkedPSVs}
				onPSVClick={this.toggleDeletePsvConfirmationModal}
			/>,
			<PootsyButton
				key="add-psv-button"
				text={t("add_psv")}
				onClick={this.toggleAddPSVModal}
			/>,
		]
	}
	backInHistory = () => {
		this.props.history.goBack()
	}
	pageHeaderLeft = () => {
		let { t } = this.context
		let { currentWorkshopOrder } = this.props
		return (
			<div className="booking-details page-header-left">
				<img
					alt="arrow back"
					className="arrow-back"
					src={arrowBackIcon}
					onClick={this.backInHistory}
				/>
				<h1 className="page-title booking-details">
					{t("workshop_order")} #{currentWorkshopOrder.displayId}
				</h1>
			</div>
		)
	}
	pageHeaderRight = () => {
		// let { t } = this.context
		let {
			currentWorkshopOrder: { status },
		} = this.props
		let header = ""
		switch (status) {
			case "PLANNED":
				header = this.plannedHeader()
				break
			case "PROBLEM":
			case "IN_PROGRESS":
				header = this.inProgressHeader()
				break
			case "DONE":
			case "COLLECTED":
				header = this.doneHeader()
				break
			default:
				header = ""
		}
		return header
	}
	doneHeader = () => {
		let { t } = this.context
		return (
			<div className="booking-details page-header-right">
				<PootsyButton
					key="a"
					text={t("cancel_workshop_order")}
					onClick={this.toggleCancelWorkshopOrderModal}
				/>
				<PootsyButton
					key="c"
					text={t("update_workshop_order")}
					onClick={this.toggleUpdateWorkshopOrderModal}
				/>
				<PootsyButton
					key="b"
					text={t("workshop_order_payment")}
					onClick={this.togglePaymentModal}
				/>
			</div>
		)
	}
	plannedHeader = () => {
		let { t } = this.context
		return (
			<div className="booking-details page-header-right">
				<PootsyButton
					text={t("start_workshop_order")}
					onClick={this.toggleStartWorkshopOrderModal}
				/>
				<PootsyButton
					text={t("update_workshop_order")}
					onClick={this.toggleUpdateWorkshopOrderModal}
				/>
				<PootsyButton
					text={t("cancel_workshop_order")}
					onClick={this.toggleCancelWorkshopOrderModal}
				/>
			</div>
		)
	}
	inProgressHeader = () => {
		let { t } = this.context
		return (
			<div className="booking-details page-header-right">
				<PootsyButton
					key="a"
					text={t("restart_workshop_order")}
					onClick={this.toggleRestartWorkshopOrderModal}
				/>
				<PootsyButton
					key="c"
					text={t("finish_workshop_order")}
					onClick={this.toggleFinishWorkshopOrderModal}
				/>
			</div>
		)
	}
	findItemPerAvItemId = avItemId => {
		let {
			currentWorkshopOrder: { items },
		} = this.props
		return items.find(item => item.avItemID === avItemId)
	}
	finalServiceVoucherCategory = () => {
		let { t } = this.context
		let {
			currentWorkshopOrder: {
				executedDurationMinutes,
				billableMinutes,
				billableSV,
				svType,
				linkedPSVs,
				sodexoStatus,
				executionDate,
				quote: { estimatedDuration },
			},
		} = this.props

		// ! the orkshop-voucher-payment-infos code is duplicated on WorkshopOrderLine (around lines 717)

		let missingVouchersCount = billableSV - linkedPSVs.length
		return (
			<Fragment>
				<PootsySubHeading text={t("workshop_voucher_details_title")} />
				<div className="workshop-voucher-details-infos">
					<SimpleInfoBox
						label={t("execution_date")}
						value={executionDate.format("DD/MM/YYYY")}
					/>
					<SimpleInfoBox
						label={t("estimated_duration_minutes")}
						value={estimatedDuration}
					/>
					<SimpleInfoBox
						label={t("executed_duration_minutes")}
						value={executedDurationMinutes}
					/>
					<SimpleInfoBox label={t("billable_minutes")} value={billableMinutes} />
				</div>
				<div className="workshop-voucher-payment-infos">
					<SimpleInfoBox label={t("billable_service_voucher")} value={billableSV} />
					{svType === "paper" ? (
						<SimpleInfoBox
							label={t("order_sv_collection_status")}
							value={
								missingVouchersCount <= 0
									? t(svType + "_collected_vouchers")
									: missingVouchersCount + " " + t("missing_count")
							}
						/>
					) : (
						<SimpleInfoBox
							label={t("sodexo_status")}
							value={t(sodexoStatus) || t("unknown")}
						/>
					)}
				</div>
			</Fragment>
		)
	}
	finalCashDetails = () => {
		let { t } = this.context
		let {
			currentWorkshopOrder: { quote, cashReceived },
		} = this.props
		let cashDetails = quote.details.find(entry => {
			return entry.categoryName === "CASH"
		})

		if (!cashDetails) return

		let orderIsPaid = cashDetails.totalDetails.category_total - cashReceived === 0

		return (
			<div className="workshop-cash">
				<PootsySubHeading text={t("workshop_cash_details_title")} />
				<div className="workshop-cash-payment-infos">
					<SimpleInfoBox
						label={t("cash_total")}
						value={cashDetails.totalDetails.category_total}
					/>
					<SimpleInfoBox label={t("cash_received")} value={cashReceived} />
					<SimpleInfoBox
						label={t("order_cash_collection_status")}
						value={orderIsPaid ? t("order_cash_paid") : t("order_cash_unpaid")}
					/>
				</div>
			</div>
		)
	}
	content = () => {
		let {
			currentWorkshopOrder: { status },
		} = this.props
		switch (status) {
			case "PLANNED":
			case "CANCELLED":
			case "IN_PROGRESS":
			case "PROBLEM":
				return this.plannedOrInProgressContent()
			case "DONE":
			case "COLLECTED":
				return this.doneContent()
			default:
				return ""
		}
	}
	plannedOrInProgressContent = () => {
		let { t } = this.context
		let {
			chat,
			currentWorkshopOrder: {
				quote,
				dropOffDate,
				scheduledPickupDate,
				executionDate,
				priority,
				billableHoursOverride,
			},
		} = this.props
		let required = [dropOffDate, scheduledPickupDate, executionDate]
		if (required.some(entry => !entry)) {
			return null
		}
		return (
			<Fragment>
				<div className="workshop-order-dates">
					<SimpleInfoBox
						label={t("drop_off_date")}
						value={dropOffDate.format("DD/MM/YYYY")}
					/>
					<SimpleInfoBox
						label={t("pickup_date")}
						value={scheduledPickupDate.format("DD/MM/YYYY")}
					/>
					<SimpleInfoBox
						label={t("execution_date")}
						value={executionDate.format("DD/MM/YYYY")}
					/>
					{billableHoursOverride && (
						<SimpleInfoBox
							label={t("billable_hours_override")}
							value={billableHoursOverride}
						/>
					)}
					{priority === 1 && (
						<div className="priority">
							<div className="label">{t("urgent_order")}</div>
							<img src={priorityIcon} alt="priority" />
						</div>
					)}
				</div>
				<WorkshopOrderQuoteDisplayer quote={quote} allowEdit={false} />
				<ChatRoom
					customClass="workshop-chat-room"
					messages={chat.messages}
					sendMessage={this.sendMessage}
				/>
			</Fragment>
		)
	}
	doneContent = () => {
		let { chat } = this.props
		let {
			currentWorkshopOrder: { quote },
		} = this.props
		return (
			<div className="workshop-done-content">
				{this.finalServiceVoucherCategory()}
				<WorkshopOrderQuoteDisplayer
					quote={quote}
					categoryFilter="CASH"
					allowEdit={false}
				/>
				{this.finalCashDetails()}
				<WorkshopOrderQuoteDisplayer
					quote={quote}
					categoryFilter="SERVICE_VOUCHER"
					allowEdit={false}
				/>
				<ChatRoom
					customClass="workshop-chat-room"
					messages={chat.messages}
					sendMessage={this.sendMessage}
				/>
			</div>
		)
	}
	cancelUpdate = () => {
		this.props.dispatch(actions.resetUpdateWorkshopOrderState())
		this.toggleFinishWorkshopOrderModal()
	}
	render = () => {
		let { t } = this.context
		let {
			currentWorkshopOrder,
			currentAffiliate,
			impactedBookings,
			component: {
				showStartOrderModal,
				showRestartOrderModal,
				showFinishOrderModal,
				showCancelOrderModal,
				showDeleteOrderModal,
				showUpdateOrderModal,
				showPaymentModal,
				showAddPSVModal,
				showDeletePsvConfirmationModal,
			},
		} = this.props
		return (
			<PageLayout
				customClass="WorkshopOrderDetails"
				headerLeft={this.pageHeaderLeft()}
				headerRight={this.pageHeaderRight()}
			>
				<ContentLayout
					optionsBoxes={this.optionBoxes()}
					rightPart={[this.customerInformation(), this.orderLinkedPSVs()]}
				>
					{showAddPSVModal && (
						<ModalLayout
							customClass="add-psv-modal"
							title={t("add_psv_modal_title")}
							closeModal={this.toggleAddPSVModal}
						>
							<PSVScanner
								scanningFor="laundry_order"
								subjectID={currentWorkshopOrder.id}
								workerSodexoRef={currentWorkshopOrder.worker.sodexoRef}
								customerSodexoRef={currentWorkshopOrder.customer.sodexoRef}
								counter={currentWorkshopOrder.billableSV}
								afterSubmitAction={actions.afterPSVSubmission}
							/>
						</ModalLayout>
					)}
					{showDeletePsvConfirmationModal && (
						<ConfirmationModal
							title={t("unlink_psv")}
							closeModal={this.toggleDeletePsvConfirmationModal}
							yesButton={<PootsyButton text={t("yes")} onClick={this.destroyPSV} />}
							noButton={
								<PootsyButton
									text={t("no")}
									theme="cancel"
									onClick={this.toggleDeletePsvConfirmationModal}
								/>
							}
						/>
					)}
					{impactedBookings.showImpactedBookingsModal && (
						<ImpactedBookingsModal
							onSubmit={this.submitCancelWorkshopOrder}
							triggerActionExpected={actions.CANCEL_WORKSHOP_ORDER_FAILURE}
						/>
					)}
					{impactedBookings.showImpactedBookingsModal && (
						<ImpactedBookingsModal
							onSubmit={this.submitUpdateWithImpactedActions}
							triggerActionExpected={actions.UPDATE_WORKSHOP_ORDER_FAILURE}
						/>
					)}
					{impactedBookings.showImpactedBookingsModal && (
						<ImpactedBookingsModal
							onSubmit={this.submitDeleteOrder}
							triggerActionExpected={actions.SUBMIT_DELETE_WORKSHOP_ORDER_FAILURE}
						/>
					)}
					{showStartOrderModal && (
						<ModalLayout
							customClass="start-workshop-order-modal"
							title={t("start_workshop_order")}
							closeModal={this.toggleStartWorkshopOrderModal}
							buttons={[
								<PootsyButton
									key="a"
									text={t("start_workshop_order")}
									onClick={this.submitStartWorkshopOrder}
								/>,
								<PootsyButton
									key="b"
									text={t("cancel")}
									theme="cancel"
									onClick={this.toggleStartWorkshopOrderModal}
								/>,
							]}
						>
							<div className="warning">{t("start_modal_description")}</div>
						</ModalLayout>
					)}
					{showRestartOrderModal && (
						<ModalLayout
							customClass="restart-workshop-order-modal"
							title={t("restart_workshop_order")}
							closeModal={this.toggleRestartWorkshopOrderModal}
							buttons={[
								<PootsyButton
									key="a"
									text={t("restart_workshop_order")}
									onClick={this.submitRestartWorkshopOrder}
								/>,
								<PootsyButton
									key="b"
									text={t("cancel")}
									theme="cancel"
									onClick={this.toggleRestartWorkshopOrderModal}
								/>,
							]}
						>
							<div className="warning">{t("restart_modal_description")}</div>
						</ModalLayout>
					)}
					{showFinishOrderModal && (
						<FinishWorkshopOrderModal
							closeModal={this.toggleFinishWorkshopOrderModal}
						/>
					)}
					{showUpdateOrderModal && (
						<UpdateWorkshopOrderModal
							submitUpdate={this.submitUpdateWorkshopOrder}
							closeModal={this.toggleUpdateWorkshopOrderModal}
						/>
					)}
					{showCancelOrderModal && (
						<ModalLayout
							customClass="cancel-workshop-order-modal"
							title={t("cancel_workshop_order")}
							closeModal={this.toggleCancelWorkshopOrderModal}
							buttons={[
								<PootsyButton
									key="a"
									text={t("cancel_workshop_order")}
									onClick={this.submitCancelWorkshopOrder}
								/>,
								<PootsyButton
									key="b"
									text={t("cancel")}
									theme="cancel"
									onClick={this.toggleCancelWorkshopOrderModal}
								/>,
							]}
						>
							<div className="warning">{t("cancel_modal_description")}</div>
						</ModalLayout>
					)}
					{showDeleteOrderModal && (
						<DeleteLaundryOrderModal
							id={currentWorkshopOrder.id}
							customClass="delete-workshop-order-modal"
							submit={this.submitDeleteOrder}
							closeModal={() => this.toggleDeleteWorkshopOrderModal(0)}
						/>
					)}
					{showPaymentModal && (
						<OrderPaymentModal
							order={currentWorkshopOrder}
							closeModal={this.togglePaymentModal}
							handleChanges={this.handleCurrentOrderChanges}
							submitOrderPayment={this.submitOrderPayment}
							currentAffiliate={currentAffiliate}
						/>
					)}
					{this.content()}
				</ContentLayout>
			</PageLayout>
		)
	}
}

WorkshopOrderDetails.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currentWorkshopOrder: state.redData.currentWorkshopOrder,
	currentAffiliate: state.redData.currentAffiliate,
	chat: state.redData.customerChat,
	component: state.redComponents.workshopOrderDetailsComponent,
	avOrderItemsList: state.redData.avOrderItemsList,
	impactedBookings: state.redComponents.impactedBookingsModal,
})
export default connect(mapStateToProps)(WorkshopOrderDetails)
