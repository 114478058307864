import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"

import * as actions from "../actions"

import {
	PootsyRadioInputs,
	PootsyTextInput,
	PootsyCheckboxInput,
	PootsySelectInput,
} from "./FunctionalInputs"
import { LabeledDateTime, PootsyButton, PootsySubHeading, PootsyHeading } from "./FunctionalDesign"
import { LineIndexation } from "./LineIndexation"

import ModalLayout from "./ModalLayout"

class SalariesIndexingForm extends Component {
	handleChanges = changes => {
		this.props.dispatch(actions.salariesIndexingFormStateChange(changes))
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch(actions.fetchHistoryIndexations())
	}
	handleRadioButtons = event => {
		this.handleChanges({ indexingMode: event.target.value })
	}
	sign_for_amount = mode => {
		if (mode === "percentage_mode") {
			return "%"
		} else {
			return "€"
		}
	}
	sendIndexation = () => {
		this.props.dispatch(actions.updateWorkerSalaries())
	}
	toggleAffectedWorker = event => {
		event.stopPropagation()
		let workers = this.props.component.workers
		let { name } = event.target
		let worker = workers.find(worker => {
			return worker.id === name
		})
		let index = workers.indexOf(worker)

		worker.affected = worker.affected !== undefined ? !worker.affected : false
		workers[index] = worker
		this.handleChanges({ workers: workers })
	}
	unselect_workers = () => {
		let workers = this.props.component.workers
		workers.forEach(worker => (worker.affected = false))
		this.handleChanges({ workers: workers })
	}

	select_workers = () => {
		let workers = this.props.component.workers
		workers.forEach(worker => (worker.affected = true))
		this.handleChanges({ workers: workers })
	}
	handleDateIndexations = event => {
		this.handleChanges({ dateIndexation: event["dateIndexation"] })
		this.props.dispatch(actions.newIndexationSalariesWorkerInfo())
	}
	handleSquadChanges = event => {
		this.handleChanges({ squad: event.target.value })
		this.props.dispatch(actions.newIndexationSalariesWorkerInfo())
	}
	handleIndexingAmountChanges = event => {
		let newIndex = ""
		if (isFinite(event.target.value.toString().replace(",", "."))) {
			newIndex = event.target.value.toString().replace(".", ",")
			this.handleChanges({ indexingAmount: newIndex })
		}
	}
	closeConfirmationModal = () => {
		this.handleChanges({ confirmationModal: false })
	}
	closeResultModal = () => {
		this.handleChanges({
			total_update: null,
			failed_indexation: null,
			success_indexation: null,
		})
	}
	render() {
		let { t } = this.context
		let { currAffiliate } = this.props

		return (
			<div className="SalariesIndexingForm">
				<div className="input-group-first">
					<div className="indexing-mode-buttons">
						<PootsyRadioInputs
							options={[
								{
									label: t("percentage"),
									id: "percentage_mode",
									value: "percentage_mode",
								},
								{
									label: t("fixed_amount"),
									id: "fixed_amount_mode",
									value: "fixed_amount_mode",
								},
								{
									label: t("fixed_salary"),
									id: "fixed_salary_mode",
									value: "fixed_salary_mode",
								},
							]}
							selected={this.props.component.indexingMode}
							groupName="indexingMode"
							groupLabel={t("indexing_mode")}
							onChange={this.handleRadioButtons}
						/>
					</div>
					<div className="indexing-amount-input">
						{this.props.component.indexingMode && (
							<div className="indexing-amounts">
								<PootsyTextInput
									type="text"
									name="indexingAmount"
									label={t("amount")}
									value={this.props.component.indexingAmount
										.toString()
										.replace(".", ",")}
									onChange={this.handleIndexingAmountChanges}
								/>
								{this.sign_for_amount(this.props.component.indexingMode)}
							</div>
						)}
					</div>
					<div className="date-input">
						{parseFloat(
							this.props.component.indexingAmount.toString().replace(",", ".")
						) !== 0.0 && (
							<LabeledDateTime
								showTimePicker={false}
								label={t("date_indexation")}
								datePickerProps={{
									name: "dateIndexation",
									value: this.props.component.dateIndexation,
									onChange: this.handleDateIndexations,
									calendarStyle: {
										transform: `scale(.8) translate(-50px, -33px)`,
										top: "-275px",
									},
								}}
							/>
						)}
					</div>
					{this.props.component.confirmationModal && (
						<ModalLayout
							title={t("confirm_indexation")}
							closeModal={this.closeConfirmationModal}
							customClass="salaries-modal"
						>
							<PootsyHeading>{t("indexation_salaries_workers")}</PootsyHeading>
							<div className={"modal_main_text"}>
								{t(
									(() => {
										if (
											this.props.component.indexingMode ===
											"fixed_salary_mode"
										) {
											return "fixed_amount_salary_explanation"
										} else if (
											parseFloat(
												this.props.component.indexingAmount
													.toString()
													.replace(",", ".")
											) > 0
										) {
											return "augmentation_salary_explanation"
										} else {
											return "diminution_salary_explanation"
										}
									})(),
									{
										date: this.props.component.dateIndexation.format(
											"DD-MM-YYYY"
										),
										amount: parseFloat(
											this.props.component.indexingAmount
												.toString()
												.replace(",", ".")
										),
										mode: this.sign_for_amount(
											this.props.component.indexingMode
										),
									}
								)}
							</div>
							<div className="warning">{t("irreversible_action_warning")} </div>
							<PootsyButton text={t("confirm")} onClick={this.sendIndexation} />

							<PootsyButton
								text={t("cancel")}
								theme="white"
								onClick={this.closeConfirmationModal}
							/>
						</ModalLayout>
					)}
				</div>

				{this.props.component.workers && (
					<Fragment>
						<PootsySubHeading text={t("affected_workers")} />
						<PootsyButton text={t("unselect_all")} onClick={this.unselect_workers} />
						<PootsyButton
							text={t("select_all")}
							onClick={this.select_workers}
							theme="white"
						/>
						{currAffiliate.squads && (
							<PootsySelectInput
								name="squad"
								defaultText={t("squad")}
								selected={this.props.component.squad}
								allowEmpty={true}
								options={currAffiliate.squads.map(e => ({
									label: e.name,
									value: e.id,
								}))}
								onChange={e => this.handleSquadChanges(e)}
							/>
						)}
						<div className="selector">
							{this.props.component.workers.map(worker => {
								return (
									<div className="worker-checkbox" key={worker.id}>
										<PootsyCheckboxInput
											label={worker.attributes.display_name}
											checked={
												worker.affected !== undefined
													? worker.affected
													: true
											}
											name={worker.id}
											onChange={this.toggleAffectedWorker}
										/>

										<div className="worker-index-details">
											{worker.attributes.last_index && (
												<div className="last-index">
													{t("last_index")} :{" "}
													{moment(
														worker.attributes.last_index.date
													).format("DD/MM/YY")}{" "}
													{": "}
													{worker.attributes.last_index.value}
													{this.sign_for_amount(
														worker.attributes.last_index.mode
													)}
												</div>
											)}
											{worker.attributes.next_index && (
												<div className="next-index">
													{t("next_index")} :{" "}
													{moment(
														worker.attributes.next_index.date
													).format("DD/MM/YY")}{" "}
													{": "}
													{worker.attributes.next_index.value}
													{this.sign_for_amount(
														worker.attributes.next_index.mode
													)}
												</div>
											)}
										</div>
									</div>
								)
							})}
						</div>
					</Fragment>
				)}
				{this.props.component.total_update && (
					<ModalLayout title={t("result_indexation")} closeModal={this.closeResultModal}>
						<PootsyHeading>{t("salary_indexes")}</PootsyHeading>
						<div>
							{t("number_worker_impacted")} {this.props.component.total_update}
						</div>
						<div>
							{t("number_worker_successfully_impacted")}
							{this.props.component.success_indexation.length}
						</div>

						<div> {t("failed_worker_indexation")}</div>
						{this.props.component.failed_indexation.map(worker => {
							return (
								<div key={worker.id} className={"failed_workers"}>
									{worker.display_name}
								</div>
							)
						})}
					</ModalLayout>
				)}
				<div className="confirm-button">
					{this.props.component.dateIndexation && (
						<PootsyButton
							text={t("execute_indexation")}
							onClick={() => this.handleChanges({ confirmationModal: true })}
						/>
					)}
				</div>
				<PootsySubHeading text={t("history_indexation")} />
				{this.props.component.history_indexations &&
					this.props.component.history_indexations.map(indexation => (
						<LineIndexation key={indexation.id} indexation={indexation} />
					))}
			</div>
		)
	}
}

SalariesIndexingForm.contextTypes = { t: PropTypes.func }

const mapStateToProps = state => ({
	component: state.redComponents.salariesIndexingFormComponent,
	currAffiliate: state.redData.currentAffiliate,
})
export default connect(mapStateToProps)(SalariesIndexingForm)
