import React, { Fragment, useContext } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { LocaleContext } from "../index.js"

import { useWeekPickerToggling } from "./AgendaHooks"
import { PootsyButton } from "./FunctionalDesign"
import WeekPicker from "./WeekPicker"
import MonthPicker from "./MonthPicker"
import CalendarWeekPicker from "./CalendarWeekPicker"
import moment from "moment"

const AgendaNavigation = ({
	handleWeekChange,
	showTssLink = true,
	disabled = false,
	leaveWorkerFullMonthMode,
}) => {
	const t = useContext(LocaleContext)
	const data = useSelector(state => state.redData.agenda)
	const agenda = useSelector(state => state.redComponents.agenda)

	const toggleWeekPicker = useWeekPickerToggling()

	let { fullMonthAffiliateWorkerId, showWeekPicker } = agenda
	let { weekStart } = data

	weekStart.locale(moment.locale())

	return (
		<Fragment>
			{fullMonthAffiliateWorkerId && (
				<div className="worker-full-month-mode-banner">
					<div className="current-month">{weekStart.format("MMMM")}</div>
					<div className="info">{t("you_are_in_worker_full_month_mode")}</div>
					<PootsyButton
						text={t("leave_worker_full_month_mode")}
						onClick={leaveWorkerFullMonthMode}
						size="small"
					/>
				</div>
			)}
			<CalendarWeekPicker
				week={weekStart}
				togglePicker={toggleWeekPicker}
				calendarProps={{
					name: "agendaWeek",
					controlToggle: true,
					parentShowPicker: showWeekPicker,
					calendarStyle: { top: "100%", left: 0 },
					disableCalendar: disabled,
					onChange: handleWeekChange,
				}}
			/>
			{fullMonthAffiliateWorkerId ? (
				<MonthPicker
					onChange={handleWeekChange}
					currentDate={weekStart}
					name="agendaWeek"
					disabled={disabled}
				/>
			) : (
				<WeekPicker
					onChange={handleWeekChange}
					currentDate={weekStart}
					name="agendaWeek"
					disabled={disabled}
				/>
			)}
			{showTssLink && (
				<Link to="/bookings/new">
					<PootsyButton text={t("find_timeslot")} />
				</Link>
			)}
		</Fragment>
	)
}

export default AgendaNavigation
