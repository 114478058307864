import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import * as actions from "../actions"

import InputsChangeHandler from "./InputsChangeHandler"
import PootsyAddressBlockInputs from "./PootsyAddressBlockInputs"
import {
	ContentLayout,
	OptionBox,
	PootsyMultipleValuesBox,
	PootsyHeading,
} from "./FunctionalDesign"
import { PootsyTextInput, PootsyFileInput, PootsySelectInput } from "./FunctionalInputs"

import fileIcon from "../static/images/file-icon-white.png"

class SettingsProfile extends Component {
	handleFormChanges = data => {
		this.props.dispatch(actions.editSettings(data))
	}
	handleCommercialAddressChanges = changes => {
		let {
			dispatch,
			currAffiliate: { commercialAddress },
		} = this.props
		let newCommercialAddress = { ...commercialAddress, ...changes }
		dispatch(actions.editSettings({ commercialAddress: newCommercialAddress }))
	}
	handleInvoiceAddressChanges = changes => {
		let {
			dispatch,
			currAffiliate: { invoicingAddress },
		} = this.props
		let newInvoiceAddress = { ...invoicingAddress, ...changes }
		dispatch(actions.editSettings({ invoicingAddress: newInvoiceAddress }))
	}
	render() {
		let { t } = this.context
		let {
			commercialName,
			companyName,
			VatNumber,
			visibleWorkerCount,
			phone,
			supportEmail,
			tariffPlan,
			commercialAddress,
			invoicingAddress,
			conventions,
			defaultLanguage,
			logoUrl,
			logoFile,
		} = this.props.currAffiliate

		let optionBoxTitle = <div className="box-title">{commercialName}</div>
		let optionBox = (
			<OptionBox boxTitle={optionBoxTitle}>
				<PootsyMultipleValuesBox
					values={[
						{ label: t("worker_count"), values: [visibleWorkerCount] },
						{ label: t("tariff_plan"), values: [tariffPlan.name] },
						{ label: t("max_cleaners"), values: [tariffPlan.maximum_worker] },
					]}
				/>
			</OptionBox>
		)
		return (
			<InputsChangeHandler onChange={this.handleFormChanges}>
				<ContentLayout optionsBoxes={optionBox} customClass="settings-profile">
					<PootsyTextInput
						name="companyName"
						label={t("company_name")}
						value={companyName}
					/>
					<PootsyTextInput
						name="commercialName"
						label={t("commercial_name")}
						value={commercialName}
					/>
					<PootsyTextInput name="phone" label={t("phone")} value={phone} />
					<PootsyTextInput name="VatNumber" label={t("vat_number")} value={VatNumber} />
					<PootsyTextInput
						name="supportEmail"
						label={t("customer_support_email")}
						value={supportEmail}
					/>
					<PootsySelectInput
						name="defaultLanguage"
						defaultText={t("language")}
						selected={defaultLanguage}
						options={[
							{ label: "Français", value: "fr" },
							{ label: "Nederlands", value: "nl" },
							{ label: "English", value: "en" },
						]}
					/>
					<div className="logo-upload-block">
						<div className="expected-sizes">{t("ideal_dimensions") + " 300x300"}</div>
						<PootsyFileInput
							name="logoFile"
							label={t("upload_logo_file")}
							accept="image/*"
						/>
						{(logoUrl || logoFile) && (
							<img
								alt="logo"
								className="to-be-uploaded-logo"
								src={logoFile ? logoFile.file : logoUrl}
							/>
						)}
					</div>
					<div className="conventions-uploads">
						{Object.keys(conventions).map(key => {
							let convention = conventions[key]
							let inputName = "conventionFiles:" + key
							let conventionFile = this.props.currAffiliate[inputName]
							return (
								<div key={key} className="convention-upload-block">
									<PootsyFileInput
										name={inputName}
										label={t("upload_convention_" + key + "_file")}
									/>
									{conventionFile && (
										<div className="convention-filename">
											{conventionFile.filename}
										</div>
									)}
									{convention.url && !conventionFile && (
										<a
											className="convention-link"
											target="_blank"
											rel="noopener noreferrer"
											href={convention.url}
										>
											<img
												className="file-icon"
												alt="convention"
												src={fileIcon}
											/>
											{t("uploaded_convention_" + key)}
										</a>
									)}
								</div>
							)
						})}
					</div>
					<div className="settings-profile-addresses">
						<div className="settings-profile-commercial-address">
							<PootsyHeading text={t("commercial_address")} fontSize="1em" />
							<PootsyAddressBlockInputs
								setValues={this.handleCommercialAddressChanges}
								handleChanges={this.handleCommercialAddressChanges}
								values={{
									city: commercialAddress.city,
									zip: commercialAddress.zip,
									box: commercialAddress.box,
									streetNumber: commercialAddress.streetNumber,
									street: commercialAddress.street,
								}}
							/>
						</div>
						<div className="settings-profile-invoicing-address">
							<PootsyHeading text={t("invoicing_address")} fontSize="1em" />
							<PootsyAddressBlockInputs
								setValues={this.handleInvoiceAddressChanges}
								handleChanges={this.handleInvoiceAddressChanges}
								values={{
									city: invoicingAddress.city,
									zip: invoicingAddress.zip,
									box: invoicingAddress.box,
									streetNumber: invoicingAddress.streetNumber,
									street: invoicingAddress.street,
								}}
							/>
						</div>
					</div>
				</ContentLayout>
			</InputsChangeHandler>
		)
	}
}

SettingsProfile.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({ currAffiliate: state.redData.currentAffiliate })
export default connect(mapStateToProps)(SettingsProfile)
