import React, { Fragment, useContext } from "react"

import { LocaleContext } from "../index.js"

import { PootsyButton } from "./FunctionalDesign"
import { PootsySelectInput } from "./FunctionalInputs"
import SearchWorker from "./SearchWorker"
import SearchCustomer from "./SearchCustomer"
import FilterFromSearch from "./FilterFromSearch"
import Filter from "./Filter"

import {
	useChangeFullMonthWorker,
	useToggleFilterDropdown,
	useSelectFilterChangeHandler,
	useHandleSquadFilterChange,
	useCustomerFilterHandler,
	useRemoveCustomerFilter,
	useWorkerFilterHandler,
	useRemoveWorkerFilter,
	useResetFilters,
} from "./AgendaHooks"
import arrowRightIcon from "../static/images/arrow-right-icon.png"
import moment from "moment"

const AgendaTopRow = ({ agenda, data, currentQuery, currAffiliate, fetchAgenda, showFilters }) => {
	const t = useContext(LocaleContext)
	let {
		badgesFilters,
		squadsFilters,
		economicUnemploymentFilters,
		activityType,
	} = currentQuery.urlQuery
	let { schedules, weekStart, allBadges } = data
	let {
		hoursWidth,
		filtersDropdownActive,
		now,
		days,
		hours,
		workersFilters,
		customersFilters,
		fullMonthAffiliateWorkerId,
	} = agenda

	const handleChangeWorkerFullMonth = useChangeFullMonthWorker(fetchAgenda)
	const toggleFiltersDropdown = useToggleFilterDropdown()
	const handleSelectFilterChange = useSelectFilterChangeHandler(fetchAgenda)
	const handleSquadFilterChange = useHandleSquadFilterChange(fetchAgenda)
	const handleCustomerFilter = useCustomerFilterHandler(fetchAgenda)
	const handleRemoveCustomerFilter = useRemoveCustomerFilter(fetchAgenda)
	const handleWorkerFilter = useWorkerFilterHandler(fetchAgenda)
	const handleRemoveWorkerFilter = useRemoveWorkerFilter(fetchAgenda)
	const handleResetFilters = useResetFilters(fetchAgenda)

	days = []
	weekStart.locale(moment.locale())
	for (let m = moment(weekStart); m.isSame(weekStart, "isoweek"); m.add(1, "day")) {
		days.push(moment(m))
	}

	let currentTimeMark
	let agendaHeight = schedules.length * 81 // << must be the same as the height of the cleaner-info-cell (+ 1px border)
	if (
		now.isSame(weekStart, "isoweek") &&
		now.hour() > 7 &&
		now.hour() < 19 &&
		now.isoWeekday() !== 7
	) {
		// To the actual number of hours since last monday, we need to subtract the hours _not_ displayed
		// - 8 hours are subtracted for the first day (not displayed: from Monday 0h to Monday 8h)
		// - 12 hours are subtracted for each subsequent day (not displayed: day(n) 20h, day(n+1) 8h)
		let hoursSinceWeekStart = now.diff(weekStart, "hours") - 8 - 12 * (now.isoWeekday() - 1)
		currentTimeMark = (
			<div
				className="current-time-mark"
				style={{
					left: hoursSinceWeekStart * hoursWidth,
					width: hoursWidth,
					height: agendaHeight,
				}}
			>
				<div className="hour-border" style={{ width: hoursWidth }} />
				<span className="current-time-tooltip">{now.format("HH") + t("h")}</span>
			</div>
		)
	}
	let activityTypes = [
		{ label: t("training"), value: "group_s_training" },
		{ label: t("group_s_conge"), value: "group_s_conge" },
		{ label: t("absence_sick"), value: "group_s_sickness" },
		{ label: t("economic_unemployment"), value: "CE" },
	]

	return (
		<div className="agenda-title-row">
			{showFilters ? (
				<div className="agenda-empty-cell-wrapper">
					{fullMonthAffiliateWorkerId && (
						<SearchWorker
							onChange={() => {}}
							searchOnlyActiveWorkers={true}
							onClick={handleChangeWorkerFullMonth}
						/>
					)}
					{!fullMonthAffiliateWorkerId && (
						<div
							className={
								"agenda-empty-cell " + (filtersDropdownActive ? "active" : "")
							}
						>
							<div className="agenda-empty-cell-info" onClick={toggleFiltersDropdown}>
								{t("everyone")}
								<img alt="arrow" src={arrowRightIcon} />
							</div>
							<FilterFromSearch
								currentValues={workersFilters}
								handleRemoveValue={handleRemoveWorkerFilter}
								searchComponent={
									<SearchWorker
										onChange={() => {}}
										searchOnlyActiveWorkers={true}
										onClick={handleWorkerFilter}
									/>
								}
							/>
							<FilterFromSearch
								currentValues={customersFilters}
								handleRemoveValue={handleRemoveCustomerFilter}
								searchComponent={
									<SearchCustomer handleCustomerClick={handleCustomerFilter} />
								}
							/>
							<PootsySelectInput
								defaultText={t("search_badges")}
								name="badgesFilters"
								options={allBadges.map(entry => ({
									label: t(entry.label),
									value: entry.value,
								}))}
								selected={badgesFilters}
								onChange={handleSelectFilterChange}
								allowEmpty={true}
							/>
							<Filter
								label={t("search_squads")}
								name="squadsQuery"
								possibleValues={currAffiliate.squads.map(entry => ({
									label: entry.name,
									value: entry.id,
								}))}
								currentFilters={squadsFilters}
								onChange={handleSquadFilterChange}
							/>
							<PootsySelectInput
								name="activityType"
								defaultText={t("activity_type")}
								selected={activityType}
								options={activityTypes}
								onChange={handleSelectFilterChange}
								allowEmpty={true}
							/>
							<PootsySelectInput
								name="economicUnemploymentFilters"
								defaultText={t("economic_unemployment_filter")}
								allowEmpty={true}
								onChange={handleSelectFilterChange}
								selected={economicUnemploymentFilters}
								options={[
									{ label: t("yes"), value: "true" },
									{ label: t("no"), value: "false" },
								]}
							/>
							<PootsyButton
								text={t("reset")}
								theme="cancel"
								onClick={handleResetFilters}
							/>
						</div>
					)}
				</div>
			) : (
				<div className="agenda-empty-cell-wrapper" />
			)}
			<div className="agenda-title-row-days-block">
				{currentTimeMark}
				{days.map(day => (
					<div key={day} className="agenda-title-day-cell">
						{day.isoWeekday() !== 7 ? (
							<Fragment>
								<span>
									{day.format(fullMonthAffiliateWorkerId ? "dddd" : "ddd DD/MM")}
								</span>
								{hours.map((entry, index) => (
									// no mark for the first hour, the left border of the cell counts as the first mark
									<div key={index} className="hour-tick">
										{index !== 0 && <div key={entry} className="hour-mark" />}
										<div
											className="agenda-mouse-time-marker"
											style={{ width: hoursWidth, height: agendaHeight }}
										>
											<div
												className="hour-border"
												style={{ width: hoursWidth }}
											/>
											<span className="agenda-mouse-hour-tooltip">
												{7 + index}h
											</span>
										</div>
									</div>
								))}
							</Fragment>
						) : (
							<div className="status">{t("status")}</div>
						)}
					</div>
				))}
			</div>
		</div>
	)
}

export default AgendaTopRow
