import React, { Component } from "react"
import ReactDOM from "react-dom"

import { PootsyHeading } from "./FunctionalDesign"
import InputsChangeHandler from "./InputsChangeHandler"

const MODAL_ROOT = document.body

export default class ModalLayout extends Component {
	UNSAFE_componentWillMount = () => {
		document.addEventListener("keydown", this.escapeClose, false)
	}
	componentWillUnmount = () => {
		document.removeEventListener("keydown", this.escapeClose)
	}
	escapeClose = e => {
		if (e.key === "Escape") {
			this.closeModal()
		}
	}
	closeModal = () => {
		this.props.closeModal()
	}
	eventBlock = e => {
		e.stopPropagation()
	}
	render = () => {
		let {
			title,
			buttons,
			customClass = "",
			formDivClass,
			showScrollIndicator,
			children,
			withInputsChangeHandler,
			handleChanges,
		} = this.props
		let scrollHintClass = showScrollIndicator ? " scroll-hint" : ""
		return ReactDOM.createPortal(
			<div className="pootsy-modal-click-outside" onClick={this.closeModal}>
				<div className={"pootsy-modal " + customClass} onClick={this.eventBlock}>
					<PootsyHeading text={title} />
					{withInputsChangeHandler ? (
						<InputsChangeHandler
							customClass={"content-div " + formDivClass}
							onChange={handleChanges}
							style={{}}
						>
							{children}
						</InputsChangeHandler>
					) : (
						<div className={"content-div " + formDivClass}>{children}</div>
					)}
					<div className={"buttons" + scrollHintClass}>{buttons}</div>
				</div>
			</div>,
			MODAL_ROOT
		)
	}
}

ModalLayout.defaultProps = {
	title: "",
	formDivClass: "",
	showScrollIndicator: false,
	closeModal: () => {},
	withInputsChangeHandler: false,
	handleChanges: () => {},
}
