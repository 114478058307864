import React, { Component } from "react"
import PropTypes from "prop-types"

import { PootsyValueDisplayer } from "./FunctionalDesign"
import { PootsySelectInput } from "./FunctionalInputs"
import SearchCustomer from "./SearchCustomer"

class CustomerSelector extends Component {
	render() {
		let { t } = this.context
		let { chosenCustomer, svType, handleCustomerClick } = this.props
		return (
			<div className="CustomerSelector">
				<SearchCustomer handleCustomerClick={handleCustomerClick} />
				{chosenCustomer.name && (
					<div className="chosen-customer">
						<div className="chosen-customer-info">
							<PootsyValueDisplayer
								label={t("name")}
								values={[chosenCustomer.name]}
							/>
							<PootsyValueDisplayer
								label={t("sodexo_number")}
								values={[chosenCustomer.sodexo || t("unknown")]}
							/>
						</div>
						<PootsySelectInput
							name="svType"
							defaultText={t("voucher_type")}
							selected={svType}
							options={[
								{ label: t("electronic"), value: "electronic" },
								{ label: t("paper"), value: "paper" },
								{ label: t("other"), value: "other" },
							]}
						/>
					</div>
				)}
			</div>
		)
	}
}

CustomerSelector.contextTypes = { t: PropTypes.func }
export default CustomerSelector
