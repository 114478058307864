import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import * as actions from "../actions"

import moment from "moment"
import PootsyAddressBlockInputs from "./PootsyAddressBlockInputs"
import { PootsyButton, PootsySubHeading, FlexLineBreak } from "./FunctionalDesign"
import ModalLayout from "./ModalLayout"
import {
	PootsyTextInput,
	PootsyCheckboxInput,
	PootsyRadioInputs,
	PootsySelectInput,
} from "./FunctionalInputs"

class NewCustomerModal extends Component {
	constructor() {
		super()
		// this.state = require('../tests/fakeStates').newCustomerfakeState
		this.state = {
			noSodexoNr: false,
			sodexoNumber: "",
			selectedDate: moment(),
			customerTitle: "",
			customerPets: "false",
			region: "",
			language: "",
			residence: "",
			box: "",
			city: "",
			email: "",
			firstName: "",
			lastName: "",
			mobilePhone: "",
			secondaryPhone: "",
			landlinePhone: "",
			streetNumber: "",
			surface: "",
			howToGetIn: "",
			otherInfo: "",
			zip: "",
			street: "",
			origin: "",
			niss: "",
			externalPartnerId: "",
			voucherTypePreference: "",
			inviteCustomer: true,
			showScrollIndicator: false,
			showRequired: {
				sodexoNumber: true,
				customerTitle: true,
				customerPets: false,
				region: true,
				language: true,
				residence: false,
				box: false,
				city: true,
				email: false,
				firstName: true,
				lastName: true,
				mobilePhone: false,
				streetNumber: true,
				surface: false,
				howToGetIn: false,
				otherInfo: false,
				zip: true,
				street: true,
				origin: false,
				niss: false,
				externalPartnerId: false,
				voucherTypePreference: true,
			},
		}
		// ( to be able to pass it down to <InputsChangeHandler/> )
		this.setState = this.setState.bind(this)
	}
	UNSAFE_componentWillReceiveProps = newProps => {
		if (newProps.submitSuccess) {
			let { dispatch, onCancel } = this.props
			dispatch(actions.acknowledgeRequestSuccess(actions.SUBMITNEWCUSTOMER_SUCCESS))
			onCancel()
		}
	}
	UNSAFE_componentWillMount = () => {
		// Check if window's too small, for the entire form to be displayed without scroll
		if (window.innerHeight <= 900) {
			this.setState({ showScrollIndicator: true })
		}
	}
	componentWillUnmount = () => {
		this.props.dispatch(actions.resetValidSodexo())
	}
	handleDateChange = changes => {
		this.setState(changes)
	}
	handleSodexoChanges = e => {
		let { value } = e.target
		if (value.length > 11) {
			this.checkSodexoNr(value)
			this.setState({ sodexoNumber: value })
		} else {
			this.props.dispatch(actions.resetValidSodexo())
		}
	}
	checkSodexoNr = sodexoToCheck => {
		let init = {
			method: "POST",
			body: JSON.stringify({ sodexo_reference: sodexoToCheck }),
		}
		this.props.dispatch(
			actions.fetchCheckSodexo(
				"/front/affiliates/customers/check_sodexo",
				init,
				sodexoToCheck
			)
		)
	}
	toggleRequiredColor = (el, makeItRed) => {
		this.setState(state => {
			let newRequired = { ...state.showRequired, [el]: makeItRed }
			return { showRequired: newRequired }
		})
	}
	submit = () => {
		// if customer already found with sodexo:
		// let url = `/api/v1/front/affiliates/customers/${this.state.customerDetailsId}/customer_contracts`;
		// no clue, "Create Residence Customer":
		// let url = `/api/v1/front/affiliates/customers/${this.state.customerDetailsId}/residences`;
		let { currentForm, dispatch } = this.props
		let valid = true
		if (!this.state.noSodexoNr && currentForm.validSodexo === "") {
			valid = false
		}
		if (valid) {
			let {
				selectedDate,
				customerTitle,
				customerPets,
				region,
				language,
				residence,
				box,
				city,
				email,
				firstName,
				lastName,
				mobilePhone,
				secondaryPhone,
				landlinePhone,
				voucherTypePreference,
				streetNumber,
				surface,
				howToGetIn,
				otherInfo,
				zip,
				street,
				inviteCustomer,
				origin,
				niss,
				country,
				externalPartnerId,
			} = this.state

			let data = {
				sodexo_reference: currentForm.validSodexo,
				sodexo_region: region,
				title: customerTitle,
				first_name: firstName,
				last_name: lastName,
				email: email,
				telephone: mobilePhone,
				secondary_phone: secondaryPhone,
				landline_phone: landlinePhone,
				default_language: language,
				street_name: street,
				street_number: streetNumber,
				street_box: box,
				zip_code: zip,
				country: country,
				surface: surface,
				how_to_get_in: howToGetIn,
				other_info: otherInfo,
				pets: JSON.parse(customerPets),
				town: city,
				residence_type: residence,
				start_date: selectedDate.format(),
				voucher_type_preference: voucherTypePreference,
				send_invitation: inviteCustomer,
				origin: origin,
				niss: niss,
				external_partner_id: externalPartnerId,
			}

			let init = { method: "POST", body: JSON.stringify(data) }
			if (this.props.fromPage === "newbooking") {
				dispatch(
					actions.simpleFetch(
						"/front/affiliates/customer_contracts",
						init,
						"CREATECUSTOMERFROMNEWBOOKING"
					)
				)
			} else if (this.props.fromPage === "newWorkshopOrder") {
				dispatch(
					actions.simpleFetch(
						"/front/affiliates/customer_contracts",
						init,
						"CREATE_CUSTOMER_FROM_NEW_WORKSHOP_ORDER"
					)
				)
			} else if (this.props.fromPage === "newDirectBooking") {
				dispatch(
					actions.simpleFetch(
						"/front/affiliates/customer_contracts",
						init,
						"CREATECUSTOMERFROMNEWDIRECTBOOKING"
					)
				)
			} else {
				dispatch(
					actions.simpleFetch(
						"/front/affiliates/customer_contracts",
						init,
						"SUBMITNEWCUSTOMER"
					)
				)
			}
			// reset sodexo after each request
			dispatch(actions.resetValidSodexo())
			this.setState({ sodexoNumber: "" })
		}
	}
	render() {
		let { t } = this.context
		let {
			showScrollIndicator,
			noSodexoNr,
			sodexoNumber,
			customerTitle,
			customerPets,
			region,
			language,
			residence,
			box,
			city,
			email,
			firstName,
			lastName,
			mobilePhone,
			secondaryPhone,
			landlinePhone,
			voucherTypePreference,
			streetNumber,
			surface,
			zip,
			street,
			country,
			showRequired,
			inviteCustomer,
			howToGetIn,
			otherInfo,
			origin,
			niss,
			externalPartnerId,
		} = this.state

		let requireArray = [
			this.state.sodexoNumber,
			this.state.region,
			this.state.firstName,
			this.state.lastName,
			this.state.customerTitle,
			this.state.language,
			this.state.street,
			this.state.voucherTypePreference,
			this.state.city,
			this.state.zip,
		]
		if (this.state.noSodexoNr) {
			requireArray.shift()
		}
		let areAllMandatoryFieldsFilledIn = requireArray.every(function(e) {
			return e !== ""
		})

		let buttons = [
			<PootsyButton
				key="a"
				text={t("confirm")}
				onClick={this.submit}
				disabled={!areAllMandatoryFieldsFilledIn}
			/>,
			<PootsyButton
				key="b"
				text={t("cancel")}
				theme="cancel"
				onClick={this.props.onCancel}
			/>,
		]
		return (
			<ModalLayout
				showScrollIndicator={showScrollIndicator}
				title={t("customer_details")}
				buttons={buttons}
				closeModal={this.props.onCancel}
				formDivClass="new-customer-modal"
				withInputsChangeHandler={true}
				handleChanges={this.setState}
			>
				<PootsySubHeading text={t("sodexo_details")} />
				<FlexLineBreak />
				<PootsyTextInput
					name="sodexoNumber"
					type="number"
					label={t("sodexo_number")}
					disabled={noSodexoNr}
					onChange={this.handleSodexoChanges}
					showRequired={showRequired.sodexoNumber}
					value={sodexoNumber}
				/>
				<PootsyCheckboxInput
					name="noSodexoNr"
					label={t("no_sodexo_number")}
					checked={noSodexoNr}
				/>
				<PootsySelectInput
					name="region"
					defaultText={t("region")}
					selected={region}
					showRequired={showRequired.region}
					options={[
						{ label: t("wallonia"), value: "Wallonie" },
						{ label: t("brussels"), value: "Bruxelles" },
						{ label: t("flanders"), value: "Vlaanderen" },
					]}
				/>
				<PootsySelectInput
					name="voucherTypePreference"
					defaultText={t("voucher_type_preference")}
					selected={voucherTypePreference}
					allowEmpty={true}
					showRequired={showRequired.voucherTypePreference}
					options={[
						{ label: t("electronic"), value: "electronic" },
						{ label: t("paper"), value: "paper" },
					]}
				/>
				<PootsySubHeading text={t("personal_details")} />
				<FlexLineBreak />
				<PootsyRadioInputs
					groupName="customerTitle"
					groupLabel={t("customer_title")}
					showRequired={showRequired.customerTitle}
					selected={customerTitle}
					options={[
						{ id: "radio-mr", value: "mr", label: t("mr") },
						{ id: "radio-mrs", value: "mrs", label: t("mrs") },
						{ id: "radio-mrs", value: "other", label: t("other") },
					]}
				/>
				<PootsyTextInput
					name="firstName"
					label={t("first_name")}
					showRequired={showRequired.firstName}
					value={firstName}
				/>
				<PootsyTextInput
					name="lastName"
					label={t("last_name")}
					showRequired={showRequired.lastName}
					value={lastName}
				/>
				<FlexLineBreak />
				<PootsyTextInput
					name="email"
					label={t("email")}
					showRequired={showRequired.email}
					value={email}
				/>
				<PootsySelectInput
					name="language"
					defaultText={t("language")}
					selected={language}
					showRequired={showRequired.language}
					options={[
						{ label: t("english"), value: "en" },
						{ label: t("dutch"), value: "nl" },
						{ label: t("french"), value: "fr" },
					]}
				/>
				<FlexLineBreak />
				<PootsyTextInput
					name="mobilePhone"
					label={t("phone")}
					showRequired={showRequired.mobilePhone}
					value={mobilePhone}
				/>
				<PootsyTextInput
					name="secondaryPhone"
					label={t("secondary_phone")}
					showRequired={showRequired.secondaryPhone}
					value={secondaryPhone}
				/>
				<PootsyTextInput
					name="landlinePhone"
					label={t("landline_phone")}
					showRequired={showRequired.landlinePhone}
					value={landlinePhone}
				/>
				<PootsyTextInput
					name="origin"
					label={t("customer_origin")}
					showRequired={showRequired.origin}
					value={origin}
				/>
				<PootsyTextInput
					name="niss"
					label={t("niss")}
					showRequired={showRequired.niss}
					value={niss}
				/>
				<PootsyTextInput
					name="externalPartnerId"
					label={t("external_partner_id")}
					showRequired={showRequired.externalPartnerId}
					value={externalPartnerId}
				/>
				<FlexLineBreak />
				<PootsySubHeading text={t("customer_address")} />
				<FlexLineBreak />
				<PootsySelectInput
					name="residence"
					defaultText={t("residence_type")}
					showRequired={showRequired.residence}
					selected={residence}
					options={[
						{ label: t("apartment"), value: "apartment" },
						{ label: t("house"), value: "house" },
						{ label: t("other"), value: "other" },
					]}
				/>
				<PootsyTextInput
					name="surface"
					type="number"
					label={t("surface")}
					min="10"
					showRequired={showRequired.surface}
					value={surface}
				/>
				<PootsyRadioInputs
					groupName="customerPets"
					groupLabel={t("pets")}
					showRequired={showRequired.customerPets}
					selected={customerPets}
					options={[
						{ id: "radio-pets", value: "true", label: t("yes") },
						{ id: "radio-no-pets", value: "false", label: t("no") },
					]}
				/>
				<FlexLineBreak />
				<PootsyAddressBlockInputs
					setValues={this.setState}
					showRequired={showRequired}
					values={{
						city: city,
						zip: zip,
						box: box,
						streetNumber: streetNumber,
						street: street,
						country: country,
					}}
				/>
				<FlexLineBreak />
				<FlexLineBreak />
				<PootsyTextInput
					name="howToGetIn"
					type="text"
					label={t("how_to_get_in")}
					value={howToGetIn}
				/>
				<PootsyTextInput
					name="otherInfo"
					type="text"
					label={t("other_info")}
					value={otherInfo}
				/>
				<FlexLineBreak />
				<PootsyCheckboxInput
					name="inviteCustomer"
					label={t("invite_customer_checkbox")}
					checked={inviteCustomer}
				/>
			</ModalLayout>
		)
	}
}

NewCustomerModal.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currentForm: state.redData.currentCustomerForm,
	submitSuccess: state.requestsSuccess[actions.SUBMITNEWCUSTOMER_SUCCESS],
})
export default connect(mapStateToProps)(NewCustomerModal)
