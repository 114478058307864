import React, { Fragment, useState } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import moment from "moment"
import { formatMinutes } from "../helpers/timeFormatting"

import PootsyCalendar from "./PootsyCalendar"
import ModalLayout from "./ModalLayout"
import PootsyTimePicker from "./PootsyTimePicker"
import { PootsyCheckboxInput } from "./FunctionalInputs"

import mapMarkerIcon from "../static/images/map-marker-icon.png"
import { NavLink } from "./NavLink"
import arrowBack from "../static/images/arrowback-icon.png"
import priorityIcon from "../static/images/priority-icon.png"
import { Arrow } from "../svg/arrow"
import arrowRightIcon from "../static/images/arrow-right-icon.png"
import { QuestionMark } from "../svg/questionMark"
import { ListExpand } from "../svg/listExpand"

import TimerSVG from "../svg/timer"
import HomeSVG from "../svg/home"
import FaceSVG from "../svg/face"
import recurrenceIcon from "../static/images/recurrence-icon.png"
import SmartphoneIcon from "../svg/smartphone-icon"
import greenDotIcon from "../static/images/greendot-icon.png"
import greyDotIcon from "../static/images/greydot-icon.png"
import monitorIcon from "../static/images/monitor-icon.png"
import fileIcon from "../static/images/file-icon.png"
import { normalizeError } from "../normalizers/Normalizers"

const noop = () => {}

export const PageLayout = ({
	customClass = "",
	headerRight,
	headerLeft,
	subNavLinks,
	onHeaderClick = noop,
	children,
}) => (
	<div className={"pootsy-page " + customClass}>
		<PageHeader leftPart={headerLeft} rightPart={headerRight} onClick={onHeaderClick} />
		{subNavLinks && <div className="pootsy-page-sub-nav">{subNavLinks}</div>}
		{children}
	</div>
)

export const ContentLayout = ({
	contentTop,
	optionsBoxes,
	rightPart,
	children,
	customClass = "",
}) => (
	<div className={"pootsy-page-main " + customClass}>
		{optionsBoxes && <div className="pootsy-content-options-boxes">{optionsBoxes}</div>}
		<div className={"pootsy-page-content " + customClass}>
			{contentTop && <div className="pootsy-page-content-top">{contentTop}</div>}
			{children}
		</div>
		{rightPart && <div className={"pootsy-content-right" + customClass}>{rightPart}</div>}
		<NavLink className="pootsy-copyright" to="/about">
			© Pootsy {moment().year()}
		</NavLink>
	</div>
)

export const LayoutSwitch = ({ layout, wrapperClass, layoutProps, children }) => {
	if (layout === "modal") {
		return (
			<ModalLayout {...layoutProps} formDivClass={wrapperClass}>
				{children}
			</ModalLayout>
		)
	} else if (layout === "page") {
		return (
			<PageLayout {...layoutProps}>
				<div className={wrapperClass}>{children}</div>
			</PageLayout>
		)
	}
}

export const ConfirmationModal = (
	{ title, prompt = "confirm_action_modal_prompt", yesButton, noButton, closeModal = noop },
	context
) => (
	<ModalLayout
		title={title}
		closeModal={closeModal}
		customClass="ConfirmationModal"
		buttons={
			<Fragment>
				{yesButton}
				{noButton}
			</Fragment>
		}
	>
		{context.t(prompt)}
	</ModalLayout>
)
ConfirmationModal.contextTypes = { t: PropTypes.func }

export const NotificationCard = ({ active, title, text, buttons }) => (
	<div className={"notification-card" + (active ? " active" : "")}>
		<div className="text">{text}</div>
		<div className="buttons">{buttons}</div>
	</div>
)

export const SmallSpinner = ({ show }) =>
	show ? (
		<div className="small-spinner">
			<div className="spinning-arc" />
		</div>
	) : null

export const SmallSpinnerSection = ({
	children,
	label,
	headerContent,
	isLoading,
	alternativeContent,
	hideFunction,
	sectionClass,
}) => {
	return (
		<div className={sectionClass}>
			<div onClick={hideFunction} className="header">
				<div className="label">{label}</div>
				<div className="right-part">{!alternativeContent && headerContent}</div>
			</div>
			{alternativeContent ? (
				alternativeContent
			) : isLoading ? (
				<SmallSpinner show={true} />
			) : (
				<div className="content">{children}</div>
			)}
		</div>
	)
}

export const EmptyDashboardSection = ({
	label,
	headerContent,
	isLoading,
	alternativeContent,
	showFunction,
}) => {
	return (
		<div className="dashboard-section">
			<div onClick={showFunction} className="header">
				<div className="label">{label}</div>
				<div className="right-part">{!alternativeContent && headerContent}</div>
			</div>
		</div>
	)
}

export const DashboardList = ({ label, headerContent, tooltipText, link = "", children }) => (
	<div className="dashboard-list">
		<div className="header">
			<div className="label">{label}</div>
			<div className="right-part">
				<div className="opt-content">{headerContent}</div>
				{link && <ListExpand link={link} />}
				<QuestionMark tooltipText={tooltipText} />
			</div>
		</div>
		{React.Children.map(children, child => {
			let className = child.props.className + " dashboard-list-item"
			return React.cloneElement(child, { className }, [
				...child.props.children,
				<Arrow key="arrow-svg" />,
			])
		})}
	</div>
)

export const DashboardCounterComingSoon = ({ label }, context) => (
	<div className="dashboard-counter">
		<div className="label">{label}</div>
		<div className="coming-soon">{context.t("coming_soon")}</div>
	</div>
)
DashboardCounterComingSoon.contextTypes = { t: PropTypes.func }

export const DashboardCounter = (
	{ label, count, comparedCount, trend, icon, tooltipText, children },
	context
) => {
	let countsAreEqual = trend === 0
	let isInfinity = !isFinite(trend)
	return (
		<div className="dashboard-counter">
			{icon}
			<QuestionMark tooltipText={tooltipText} />
			<div className="label">{label}</div>
			<div className="count">{Math.round(count * 100) / 100}</div>
			{comparedCount !== undefined && (
				<div className="trend-indication">
					<div className="compared-count">{Math.round(comparedCount * 100) / 100}</div>
					{countsAreEqual && <div className="trend equal">= {trend}%</div>}
					{!countsAreEqual &&
						!isInfinity &&
						(trend > 0 ? (
							<div className="trend positive">▲+{trend.toFixed(1)}%</div>
						) : (
							<div className="trend negative">▼{trend.toFixed(1)}%</div>
						))}
				</div>
			)}
		</div>
	)
}

export const WarningModal = ({ messages, acknowledge, button }, context) => {
	return (
		<ModalLayout
			formDivClass="pootsy-warning-modal"
			closeModal={acknowledge}
			buttons={button}
			title={context.t("warning")}
		>
			{messages.map(mess => (
				<div key={mess}>{context.t(mess)}</div>
			))}
		</ModalLayout>
	)
}
WarningModal.contextTypes = { t: PropTypes.func }

export const ErrorBox = ({ errors, errorStatus, acknowledge }, context) => {
	let button = <PootsyButton text={context.t("dismiss")} onClick={acknowledge} />
	let obj = normalizeError(errors)
	let errArr = Object.keys(obj).map(key => key + " : " + obj[key])
	return (
		<ModalLayout
			closeModal={acknowledge}
			buttons={button}
			title={`${context.t("server_error")} ${errorStatus}`}
		>
			<div className="error-box">
				{errArr.length === 0 ? context.t("no_backend_message_error") : errArr}
			</div>
		</ModalLayout>
	)
}
ErrorBox.contextTypes = { t: PropTypes.func }

export const PageHeader = ({ leftPart, rightPart, onClick = noop }) => (
	<div className="page-header" onClick={onClick}>
		{leftPart} {rightPart}
	</div>
)

export const OptionBox = ({ boxTitle, children, customClass = "" }) => (
	<div className="pootsy-options-box">
		{boxTitle && <label className="pootsy-options-box-title">{boxTitle}</label>}
		<div className={"pootsy-options " + customClass}>{children}</div>
	</div>
)

export const DashboardWidget = ({ title, children, headingRight = null }) => (
	<div className="dashboard-widget">
		<div className="heading">
			<PootsyHeading text={title} fontSize=".8em" />
			<div className="heading-right">{headingRight}</div>
		</div>
		{children}
	</div>
)

export const PootsyHeading = ({ text = "", fontSize, color = "#f663a2" }) => (
	<h1
		className="pootsy-heading"
		style={{ fontSize: fontSize, fontWeight: "lighter", color: color }}
	>
		{text}
	</h1>
)

export const PootsySubHeading = ({ text = "", fontSize, color = "#f663a2" }) => (
	<h2 className="pootsy-subheading">{text}</h2>
)

const POOTSY_BUTTON_COLORS = {
	none: { background: "transparent", color: "black" },
	pink: { background: "#f663a2", color: "white" },
	cancel: { background: "#EAEEF1", color: "black" },
	white: { background: "white", color: "#f663a2" },
	green: { background: "#48be7c", color: "white" },
	fadedGreen: { background: "#b4e5b1", color: "black" },
	red: { background: "#e81039", color: "white" },
	fadedRed: { background: "#ffe5e5", color: "black" },
	blue: { background: "#7DCDDB", color: "white" },
	disabled: { background: "#EAEEF1", color: "lightgrey" },
}
export const PootsyButton = ({
	icon,
	text,
	link,
	onClick,
	size = "",
	customClass = "",
	dataset = {},
	theme = "pink",
	disabled = false,
	fetching = false,
}) => {
	if (fetching) {
		disabled = true
	}
	if (disabled) {
		theme = "disabled"
		onClick = noop
	}
	let {
		[theme]: { color, background },
	} = POOTSY_BUTTON_COLORS
	let finalClass = "pootsy-button " + size + " " + customClass
	if (disabled) finalClass += " disabled"
	return (
		<span
			className={finalClass}
			style={{ background: background, color: color }}
			onClick={onClick}
			{...dataset}
		>
			<SmallSpinner show={fetching} />
			{text}
		</span>
	)
}

export const MapMarker = () => (
	<img
		alt="marker"
		src={mapMarkerIcon}
		style={{ height: 25, transform: "translate(-50%, -100%)" }}
	/>
)

export const StepButton = ({ active, disabled, onClick, text }) => {
	let className = "step-button"
	if (active) {
		className += " active"
	}
	if (disabled) {
		className += " disabled"
		onClick = null
	}
	return (
		<span className={className} onClick={onClick}>
			{text}
		</span>
	)
}

export const PootsyEvolvingData = ({ data, title }) => (
	<div className="pootsy-evolving-values">
		<PootsySubHeading text={title} />
		{data
			.filter(e => e.show)
			.map((e, i) => (
				<div
					key={"" + i + e.label + e.value}
					className="evolving-value"
					style={e.style || {}}
				>
					<div className="label">{e.label}</div>
					{String(e.value) && <div className="value">{e.value}</div>}
				</div>
			))}
	</div>
)

export const PootsyValueDisplayer = ({ values, label = "" }) => {
	return (
		<div className="pootsy-value-displayer">
			<div className="pootsy-value-displayer-label">{label} :</div>
			{values.map((entry, index) => (
				<div key={index} className="pootsy-value-displayer-value">
					{entry}
				</div>
			))}
		</div>
	)
}

export const PootsyMultipleValuesBox = ({ values }) => {
	return (
		<div className="pootsy-multiple-values-displayer-box">
			{values.map(entry => (
				<PootsyValueDisplayer key={entry.label} label={entry.label} values={entry.values} />
			))}
		</div>
	)
}

export const NavLinkArrowBack = ({ to }) => (
	<NavLink to={to}>
		<img alt="back" className="arrow-back-icon" src={arrowBack} />
	</NavLink>
)

const LineSeparator = () => <div className="pootsy-line-separator" />

export const FlexLineBreak = () => <div className="flex-line-break" />

export const SimpleLine = ({ link = "#", onClick, noLink = false, customClass = "", children }) => {
	let childrenCount = children.filter(child => child).length
	let line = (
		<div className={"pootsy-simple-line " + customClass} onClick={onClick}>
			{React.Children.map(
				children,
				(child, index) =>
					child && (
						<Fragment>
							{child}
							{index + 1 !== childrenCount && <LineSeparator />}
						</Fragment>
					)
			)}
		</div>
	)
	return noLink ? line : <Link to={link}>{line}</Link>
}

export const LabeledSimpleLine = ({ columns = [], simpleLineProps }) => {
	return (
		<SimpleLine {...simpleLineProps} customClass="LabeledSimpleLine">
			{columns.map((column, index) =>
				column.noLabel ? (
					<div key={index} className={"column content " + (column.customClass || "")}>
						{column.content}
					</div>
				) : (
					<div key={column.label} className={"column " + (column.customClass || "")}>
						<div className="label">{column.label}</div>
						<div className="value">{column.value}</div>
					</div>
				)
			)}
		</SimpleLine>
	)
}

export const DatedLine = (
	{
		link = "#",
		noLink = false,
		day,
		date,
		month,
		customClass = "",
		sideIcon,
		onClick = noop,
		children,
	},
	{ t }
) => {
	let displayChildren = children.filter(child => child)
	let content = (
		<div className={"pootsy-dated-line " + customClass}>
			<div className="dated-line-date">
				<span className="day">{day}</span>
				<span className="date">{date}</span>
				<span className="month">{month}</span>
				{sideIcon}
			</div>
			{React.Children.map(displayChildren, (child, index) => (
				<Fragment>
					{child}
					{index + 1 < displayChildren.length && <LineSeparator />}
				</Fragment>
			))}
		</div>
	)
	return noLink ? (
		<div className="pootsy-dated-line-link" onClick={onClick}>
			{content}
		</div>
	) : (
		<Link className="pootsy-dated-line-link" to={link}>
			{content}
		</Link>
	)
}
DatedLine.contextTypes = { t: PropTypes.func }

export const TimeDelta = ({ plannedTime, realTime }) => {
	if (!realTime.isValid()) {
		return null
	}
	let bookingTime, deliveryTime, diff

	bookingTime = moment(plannedTime)
	deliveryTime = moment(realTime)
	diff = moment.duration(deliveryTime.diff(bookingTime))
	let pileAlHeure = deliveryTime.isSame(bookingTime, "minutes")
	if (pileAlHeure) {
		return null
	}
	let after = deliveryTime.isAfter(bookingTime, "minutes")
	let before = deliveryTime.isBefore(bookingTime, "minutes")
	let { days, hours, minutes } = diff._data
	days = Math.abs(days)
	hours = Math.abs(hours)
	minutes = Math.abs(minutes)
	return (
		<span className={"time-delta" + (after ? " after" : " before")}>
			{after && "+"}
			{before && "-"}
			{days !== 0 && days + "d"} {hours !== 0 && hours + "h"} {minutes !== 0 && minutes + "'"}
		</span>
	)
}

export const StartToEndTime = ({ start, end, realStart, realEnd }, context) => {
	start = moment(start)
	end = moment(end)
	realStart = moment(realStart)
	realEnd = moment(realEnd)
	return (
		<div className="start-to-end-time">
			<div className="start-time">
				<label>{context.t("start")}:</label>
				<span className="start-time-value">
					{start.format("HH:mm")}
					{realStart !== null && realStart !== start && (
						<TimeDelta plannedTime={start} realTime={realStart} />
					)}
				</span>
			</div>
			<img alt="presentation" src={arrowRightIcon} />
			<div className="end-time">
				<label>{context.t("end")}:</label>
				<span className="end-time-value">
					{end.format("HH:mm")}
					{realEnd !== null && realEnd !== end && (
						<TimeDelta plannedTime={end} realTime={realEnd} />
					)}
				</span>
			</div>
		</div>
	)
}
StartToEndTime.contextTypes = { t: PropTypes.func }

export const LabeledDateTime = ({
	showDatePicker = true,
	showTimePicker = true,
	showLabel = true,
	allowEmptyTime = false,
	label,
	datePickerProps,
	timePickerProps,
}) => (
	<div className="labeled-datetime-picker">
		{showLabel && <div className="labeled-datetime-picker-label">{label}</div>}
		<div className="labeled-datetime-picker-inputs">
			{showDatePicker && <PootsyCalendar {...datePickerProps} />}
			{showTimePicker && <PootsyTimePicker {...timePickerProps} />}
		</div>
	</div>
)

export const internetIcon = (
	<svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20">
		<g fill="none" fillRule="evenodd">
			<path d="M-2-2h24v24H-2z" />
			<path
				fill="#6A7173"
				fillRule="nonzero"
				d="M9.99 0C4.47 0 0 4.48 0 10s4.47 10 9.99 10C15.52 20 20 15.52 20 10S15.52 0 9.99 0zm6.93 6h-2.95a15.65 15.65 0 0 0-1.38-3.56A8.03 8.03 0 0 1 16.92 6zM10 2.04c.83 1.2 1.48 2.53 1.91 3.96H8.09C8.52 4.57 9.17 3.24 10 2.04zM2.26 12C2.1 11.36 2 10.69 2 10s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2H2.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56A7.987 7.987 0 0 1 3.08 14zm2.95-8H3.08a7.987 7.987 0 0 1 4.33-3.56A15.65 15.65 0 0 0 6.03 6zM10 17.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM12.34 12H7.66c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 0 1-4.33 3.56zM14.36 12c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"
			/>
		</g>
	</svg>
)

const weekBadgeColorMapper = {
	unknown: "grey",
	agenda_badge_fixfix_extra_hours: "red",
	agenda_badge_fixfix_comp_hours: "orange",
	agenda_badge_fixfix_full: "green",
	agenda_badge_fixfix_incomplete: "orange",
	agenda_badge_varfix_extra_hours: "red",
	agenda_badge_varfix_comp_hours: "orange",
	agenda_badge_varfix_full: "green",
	agenda_badge_varfix_incomplete: "orange",
	agenda_badge_varvar_below_minimum: "black",
	agenda_badge_varvar_comp_hours_exceeded: "red",
	agenda_badge_varvar_full: "green",
	agenda_badge_varvar_incomplete_period: "orange",
	agenda_badge_varvar_overloaded: "red",
}
export const WeekBadge = ({ badge }, context) => {
	let color = weekBadgeColorMapper[badge]
	return <div className={"WeekBadge " + color}>{context.t(badge)}</div>
}
WeekBadge.contextTypes = { t: PropTypes.func }

export const PootsyTable = ({ labels, rows, title, loading }) => (
	<div className="pootsy-table">
		{title && <div className="table-title">{title}</div>}
		<div className="labels">
			{labels.map(entry => (
				<div key={entry} className="label">
					{entry}
				</div>
			))}
		</div>
		{rows.map((row, index) => (
			<div key={index} className="row" style={row.style || {}}>
				{row.columns.map((entry, i) => (
					<div key={i} className="column">
						{entry}
					</div>
				))}
				{loading && <SmallSpinner show={true} />}
			</div>
		))}
	</div>
)

export const SodexoBookingDatedLine = ({ booking, customClass = "", children }, context) => {
	let deliveryDate = moment(booking.deliveryDate)
	let { t } = context
	let payment_status = booking.sodexoStatus
		? context.t(booking.sodexoStatus)
		: booking.voucherType === "electronic"
		? t(booking.payStatus || "")
		: booking.voucherCount === booking.pServiceVouchersAffiliate.length
		? t("p_payment_encoded")
		: t(
				`p_payment_incomplete_${(() => {
					let missing = booking.voucherCount - booking.pServiceVouchersAffiliate.length
					return missing < 0 ? 0 : missing
				})()}`
		  )
	return (
		<DatedLine
			customClass={"sodexo-booking-dated-line " + customClass}
			link={"/bookings/" + booking.id}
			day={deliveryDate.format("ddd")}
			date={deliveryDate.date()}
			month={deliveryDate.format("MMMM")}
			sideIcon={
				booking.recurrence && (
					<img alt="recurrent" className="recurrence-icon" src={recurrenceIcon} />
				)
			}
		>
			<div className="column names">
				<div className="customer-name">{booking.customerName}</div>
				<div className="worker-name">
					{context.t("cleaner")}: {booking.workerName}
				</div>
			</div>
			<div className="column sodexo-status">
				<div className="label">
					{booking.sodexoStatus ? context.t("sodexo_status") : ""}
				</div>
				<div className="value">{payment_status}</div>
			</div>
			<div className="column service-vouchers">
				<div className="label">{context.t("sodexo_nb_of_hours")}</div>
				<div className="value">
					{booking.sodexoStatus ? booking.sodexoDuration : booking.voucherCount}
				</div>
			</div>
			<StartToEndTime
				start={booking.startTime}
				end={booking.endTime}
				realStart={booking.sdStartTime}
				realEnd={booking.sdEndTime}
			/>
			{children}
		</DatedLine>
	)
}
SodexoBookingDatedLine.contextTypes = { t: PropTypes.func }

export const WorkshopOrderLine = (
	{ workshopOrder, customClass = "", children, noLink = false },
	context
) => {
	let link = "/workshop/" + workshopOrder.id
	let cashDetails = workshopOrder.quote.details.find(entry => {
		return entry.category_name === "CASH"
	})

	let orderIsPaid = cashDetails.total_details.category_total - workshopOrder.cashReceived === 0
	let missingVouchersCount = workshopOrder.billableSV - workshopOrder.psvs.length
	let svType = workshopOrder.svType

	// ! the sodexoPaymentStatus code is duplicated on WorkshopOrderDetails (around lines 530)
	let sodexoPaymentStatus = context.t(workshopOrder.sodexoStatus) || context.t("unknown")
	if (svType === "paper") {
		if (missingVouchersCount <= 0) {
			sodexoPaymentStatus = context.t(svType + "_collected_vouchers")
		} else {
			sodexoPaymentStatus = missingVouchersCount + " " + context.t("missing_count")
		}
	}

	return (
		<SimpleLine customClass={"workshop-order-line " + customClass} link={link} noLink={noLink}>
			<div className="column id">
				<div className="value">#{workshopOrder.displayId}</div>
				{workshopOrder.priority === 1 && <img src={priorityIcon} alt="priority" />}
			</div>
			<div className="column names">
				<div className="customer-name">{workshopOrder.customerName}</div>
				<div className="worker-name">
					{context.t("cleaner")}: {workshopOrder.workerName}
				</div>
			</div>
			<div className="column drop-off-date">
				<div className="label">{context.t("order_drop_off_date")}</div>
				<div className="value">{workshopOrder.dropOffDate.format("DD/MM/YYYY")}</div>
			</div>
			<div className="column execution-date">
				<div className="label">{context.t("execution_date")}</div>
				<div className="value">{workshopOrder.executionDate.format("DD/MM/YYYY")}</div>
			</div>
			<div className="column pick-up-date">
				<div className="label">{context.t("order_pick_up_date")}</div>
				<div className="value">{workshopOrder.pickUpDate.format("DD/MM/YYYY")}</div>
			</div>
			<div className="column estimated-minutes">
				<div className="label">{context.t("order_estimated_minutes")}</div>
				<div className="value">{workshopOrder.estimatedDurationMinutes}</div>
			</div>
			<div className="column billable-sv">
				<div className="label">{context.t("billable_service_voucher")}</div>
				<div className="value">{workshopOrder.billableSV}</div>
			</div>
			<div className="column sodexo-billed-hours">
				<div className="label">{context.t("order_sodexo_billed_hours")}</div>
				<div className="value">{workshopOrder.sodexoBilledHours}</div>
			</div>
			<div className="column sodexo-status">
				<div className="label">
					{context.t("sodexo")}
					<img
						alt="presentation"
						className="sv-type-icon"
						src={
							workshopOrder.svType &&
							workshopOrder.svType.toLowerCase() === "electronic"
								? monitorIcon
								: fileIcon
						}
					/>
				</div>
				<div className="value">{context.t(workshopOrder.sodexoStatus)}</div>
			</div>
			<div className="column cost">
				<div className="label">{context.t("order_cost")}</div>
				<div className="value">{workshopOrder.estimatedPrice}€</div>
			</div>
			<div className="column payment-status">
				<div className="label">{context.t("payment_status")}</div>
				<div className="value">{sodexoPaymentStatus}</div>
			</div>
			<div className="column payment-status">
				<div className="label">{context.t("order_cash_collection_status")}</div>
				<div className="value">
					{orderIsPaid ? context.t("order_cash_paid") : context.t("order_cash_unpaid")}
				</div>
			</div>
			<div className="column status">
				<div className="label">{context.t("status")}</div>
				<div className={"value " + workshopOrder.status}>
					{context.t("workshop_order_status_" + workshopOrder.status)}
				</div>
			</div>
			{children}
		</SimpleLine>
	)
}
WorkshopOrderLine.contextTypes = { t: PropTypes.func }

export const AvailableWorkerCard = ({ selected, timeslot, onClick }, context) => {
	let finalClass = "AvailableWorkerCard" + (selected ? " active" : "")
	return (
		<div className={finalClass} onClick={() => onClick(timeslot)}>
			{timeslot.isExtraHour && <TimerSVG />}
			<div className="row">
				<div className="name">
					<FaceSVG />
					{timeslot.displayName}
				</div>
				<div className="distance">
					{timeslot.distanceFromLast
						? context.t("distance", {
								km: timeslot.distanceFromLast
									.toFixed(2)
									.toString()
									.replace(".", ","),
						  })
						: context.t("unknown")}
					{timeslot.lastLocationIsHome && <HomeSVG />}
				</div>
				<div className="allergies">
					{context.t("allergies") + " : " + timeslot.allergies}
				</div>
			</div>
			<div className="row">
				<div className="comment">{context.t("comment_tss") + " : " + timeslot.comment}</div>
			</div>
		</div>
	)
}
AvailableWorkerCard.contextTypes = { t: PropTypes.func }

export const SimpleInfoBox = ({ label, value }) => (
	<div className="SimpleInfoBox">
		<div className="label">{label}</div>
		<div className="value">{value}</div>
	</div>
)

export const NoCSSInfoBox = ({ label, value }) => (
	<div className="NoCSSInfoBox">
		<div className="label">{label}</div>
		<div className="value">{value}</div>
	</div>
)

export const PSVSlipLine = ({ psvSlip }, { t }) => (
	<DatedLine
		link={"/paper_voucher_delivery_group/" + psvSlip.id}
		date={psvSlip.psv_group_affiliate_reference}
		customClass="PSVSlipLine"
	>
		<div className="column">
			<div className="label">{t("region")}</div>
			<div className="value">{t(psvSlip.sodexo_region)}</div>
		</div>
		<div className="column">
			<div className="label">{t("month")}</div>
			<div className="value">{moment(psvSlip.month_start).format("MMMM - YYYY")}</div>
		</div>
		<div className="column">
			<div className="label">{t("total")}</div>
			<div className="value">{psvSlip.vouchers_total}</div>
		</div>
		<div className="column">
			<div className="label">{t("value")}</div>
			<div className="value">{psvSlip.total_value}</div>
		</div>
		<div className="column">
			<div className="label">{t("status")}</div>
			<div className="value">{t(psvSlip.status)}</div>
		</div>
	</DatedLine>
)
PSVSlipLine.contextTypes = { t: PropTypes.func }

// A Sodexo Service can be a booking or an order (so far...)
// It is a pootsy object, _not_ a sodexo booking
export const SodexoServiceLine = ({ sodexoService }, { t }) => (
	<DatedLine
		customClass="SodexoServiceLine"
		link={
			sodexoService.type === "LaundryOrder"
				? "/workshop/" + sodexoService.id
				: "/bookings/" + sodexoService.id + "_" + sodexoService.date.format("YYYY-MM-DD")
		}
		day={sodexoService.date.isValid() ? sodexoService.date.format("ddd") : ""}
		month={sodexoService.date.isValid() ? sodexoService.date.format("MMMM") : ""}
		date={sodexoService.date.isValid() ? sodexoService.date.format("D") : ""}
	>
		<div className="column names">
			{!sodexoService.isLinked && (
				<div className="psv-unlinked-warning">{t("psv_not_linked")}</div>
			)}
			<div className="names-container">
				<div className="customer-name">{sodexoService.customerName}</div>
				<div className="worker-name">
					{t("cleaner")}: {sodexoService.workerName}
				</div>
			</div>
			<div className="service-icon">
				<div className="sodexo-icon">
					{sodexoService.type === "LaundryOrder"
						? "0"
						: sodexoService.type === "BookingV2"
						? "Z"
						: ""}
				</div>
			</div>
		</div>
		<div className="column">
			<div className="label">{t("vouchers_count")}</div>
			<div className="value">{sodexoService.vouchersDueCount}</div>
		</div>
		<div className="column">
			<div className="label">{t("status")}</div>
			<div className="value">
				{sodexoService.type === "LaundryOrder"
					? t("workshop_order_status_" + sodexoService.status)
					: t(sodexoService.status)}
			</div>
		</div>
	</DatedLine>
)

SodexoServiceLine.contextTypes = { t: PropTypes.func }

export const SodexoWorkLine = ({ sodexoWork }, { t }) => (
	<DatedLine
		customClass="SodexoWorkLine"
		link={
			sodexoWork.type === "LaundryOrder"
				? "/workshop/" + sodexoWork.id
				: "/bookings/" + sodexoWork.id + "_" + sodexoWork.date.format("YYYY-MM-DD")
		}
		day={sodexoWork.date.isValid() ? sodexoWork.date.format("ddd") : ""}
		month={sodexoWork.date.isValid() ? sodexoWork.date.format("MMMM") : ""}
		date={sodexoWork.date.isValid() ? sodexoWork.date.format("D") : ""}
	>
		<div className="column names">
			{!sodexoWork.isLinked && (
				<div className="psv-unlinked-warning">{t("psv_not_linked")}</div>
			)}
			<div className="names-container">
				<div className="customer-name">{sodexoWork.customerName}</div>
				<div className="worker-name">
					{t("cleaner")}: {sodexoWork.workerName}
				</div>
			</div>
			<div className="service-icon">
				<div className="sodexo-icon">
					{sodexoWork.type === "LaundryOrder"
						? "0"
						: sodexoWork.type === "BookingV2"
						? "Z"
						: ""}
				</div>
			</div>
		</div>
		<div className="column">
			<div className="label">{t("vouchers_count")}</div>
			<div className="value">{sodexoWork.vouchersDueCount}</div>
		</div>
		<div className="column">
			<div className="label">{t("status")}</div>
			<div className="value">
				{sodexoWork.type === "LaundryOrder"
					? t("workshop_order_status_" + sodexoWork.status)
					: t(sodexoWork.status)}
			</div>
		</div>
	</DatedLine>
)
SodexoWorkLine.contextTypes = { t: PropTypes.func }

export const TaskLine = (
	{ task, selected, select, showSpinner, onCheckboxChange = noop },
	{ t }
) => {
	let late = moment().isAfter(task.taskScheduledDate) && !task.done
	return (
		<div className={"TaskLine " + (selected ? "selected " : "") + (late ? "late" : "")}>
			<PootsyCheckboxInput
				name={`${task.id}::taskDone`}
				checked={task.done}
				onChange={onCheckboxChange}
			/>
			<div className="content" onClick={() => select(task.id)}>
				<div className="title">{task.taskTitle}</div>
				<div className="bottom">
					<div className="due-date">
						{task.taskScheduledDate.isValid() &&
							task.taskScheduledDate.format("DD/MM/YY")}
					</div>
					<div className="category">{t(task.taskCategory)}</div>
					<div className="assignees">
						{task.taskAssignees[0].label}
						{task.taskAssignees.length > 1 && (
							<div className="other-assignees-count">
								+ {task.taskAssignees.length - 1}
							</div>
						)}
					</div>
				</div>
			</div>
			<SmallSpinner show={showSpinner} />
		</div>
	)
}
TaskLine.contextTypes = { t: PropTypes.func }

export const CustomerLine = ({ customer, noLink = false, onClick = noop }, { t }) => {
	return (
		<SimpleLine
			key={customer.id}
			customClass="CustomerLine"
			noLink={noLink}
			onClick={onClick}
			link={"/customers/" + customer.id + "/profile"}
		>
			<div className="customer-line-contact">
				<span className="customer-line-name">{customer.attributes.display_name}</span>
				<span className="customer-line-address">
					{customer.attributes.residences &&
						customer.attributes.residences.data &&
						customer.attributes.residences.data[0] &&
						customer.attributes.residences.data[0].attributes.address}
				</span>
			</div>

			<div className="customer-line-telephone">
				<span>{customer.attributes.telephone}</span>
			</div>
			{customer.attributes.average_rating && (
				<div className="customer-line-average-rating">
					<div className="label">{t("average_rating")}</div>
					{customer.attributes.average_rating}
				</div>
			)}
			{customer.attributes.next_booking && (
				<div className="customer-line-next-booking">
					{t("next") +
						": " +
						moment(customer.attributes.next_booking).format("DD/MM/YYYY")}
				</div>
			)}
			{customer.attributes.already_connected && <SmartphoneIcon />}
		</SimpleLine>
	)
}
CustomerLine.contextTypes = { t: PropTypes.func }

export const CommunicationWorkerLine = (
	{ worker, checked, noLink = false, onClick, areaLimit = 12 },
	{ t }
) => {
	return (
		<SimpleLine key={worker.id} customClass="WorkerLine" noLink={true}>
			<input
				type="checkbox"
				id={worker.id}
				name="scales"
				checked={checked}
				onClick={() => onClick(worker.id)}
			/>
			<div className="cleaner-line-contact">
				<span className="cleaner-line-name">{worker.attributes.display_name}</span>
			</div>
			{worker.attributes.already_connected && <SmartphoneIcon />}
		</SimpleLine>
	)
}
CommunicationWorkerLine.contextTypes = { t: PropTypes.func }

export const MessageHistoryWorkerLine = ({ message }, { t }) => {
	const [showWorkers, setShowWorkers] = useState("none")

	function changeDescription() {
		if (message.group_selected) {
			return
		}
		if (showWorkers === "none") {
			setShowWorkers("block")
		} else {
			setShowWorkers("none")
		}
	}

	let desc = message.group_selected ? t("selected_workers") + " " + t("from").toLowerCase() : ""
	message.description.split(";").forEach(element => {
		element.split(" ").forEach(word => (desc += " " + t(word)))
		desc += ";"
	})

	const workersList = message.workers.map(worker => <div key={worker}>{worker}</div>)
	let options = {
		year: "numeric",
		month: "numeric",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
		second: "numeric",
		hour12: false,
		timeZone: "Europe/Stockholm",
	}
	return (
		<div className="message_history">
			<div className="customer-line-next-booking">
				{new Intl.DateTimeFormat("en-US", options).format(new Date(message.created_at))}
			</div>
			<LineSeparator />
			<div className="customer-line-contact">{message.text}</div>
			<LineSeparator />
			<div className="customer-line-telephone" onClick={() => changeDescription()}>
				{desc.slice(0, -1)}
				<div className="display-workers" style={{ display: showWorkers }}>
					{workersList}
				</div>
			</div>
			{false && <SmartphoneIcon />}
		</div>
	)
}
MessageHistoryWorkerLine.contextTypes = { t: PropTypes.func }

export const WorkerLine = ({ worker, noLink = false, onClick, areaLimit = 12 }, { t }) => {
	return (
		<SimpleLine
			key={worker.id}
			customClass="WorkerLine"
			noLink={noLink}
			onClick={onClick}
			link={"/cleaners/" + worker.id + "/profile"}
		>
			<div className="cleaner-line-contact">
				<span className="cleaner-line-name">{worker.attributes.display_name}</span>
				<span className="cleaner-line-address">{worker.attributes.address}</span>
			</div>
			<div className="cleaner-line-areas">
				{worker.attributes.areas
					.filter((e, i) => i < areaLimit)
					.map(entry => (
						<span key={entry.zip_code} className="active-area">
							{entry.zip_code}
						</span>
					))}
				{worker.attributes.areas.length > areaLimit - 1 ? (
					<span className="active-area">. . .</span>
				) : (
					""
				)}
			</div>
			<div className="cleaner-line-status">
				<img
					alt="status-icon"
					src={worker.attributes.status === "active" ? greenDotIcon : greyDotIcon}
				/>
				<label>{t(worker.attributes.status)}</label>
			</div>
			<div className="cleaner-line-rating">
				<div className="label">{t("average_rating")}</div>
				<span className="rating">
					{worker.attributes.evaluation ? worker.attributes.evaluation + "/ 5" : "-"}
				</span>
			</div>
			{worker.attributes.already_connected && <SmartphoneIcon />}
		</SimpleLine>
	)
}
WorkerLine.contextTypes = { t: PropTypes.func }

export const SquadLine = (
	{
		squad,
		userIsInTeam,
		subscribeToTeam = noop,
		noLink = false,
		onClick,
		areaLimit = 12,
		toggleUpdateSquadModal = noop,
		deleteSquad = noop,
	},
	{ t }
) => (
	<SimpleLine customClass="team-line" noLink={true}>
		<div className="squad-name">
			{t("squad")}: {squad.attributes.name}
		</div>
		<div className="workers">
			<div className="column-title">
				{t("workers_in_squad")}: {squad.attributes.affiliate_workers.length}
			</div>
			<div className="people-list">
				{squad.attributes.affiliate_workers.map(squad => (
					<div key={squad.id} className="worker-in-squad">
						{squad.name}
					</div>
				))}
			</div>
		</div>
		<div className="customers">
			<div className="column-title">
				{t("customers_in_squad")}: {squad.attributes.customer_contracts.length}
			</div>
			<div className="people-list">
				{squad.attributes.customer_contracts.map(squad => (
					<div key={squad.id} className="customer-in-squad">
						{squad.name}
					</div>
				))}
			</div>
		</div>
		<PootsyCheckboxInput
			name={squad.id}
			label={t("current_user_in_team")}
			checked={userIsInTeam}
			onChange={subscribeToTeam}
		/>
		<PootsyButton
			text={t("update_squad")}
			onClick={toggleUpdateSquadModal}
			dataset={{ "data-teamid": squad.id }}
		/>
		<PootsyButton
			text={t("delete_squad")}
			theme="fadedRed"
			onClick={deleteSquad}
			dataset={{ "data-teamid": squad.id }}
		/>
	</SimpleLine>
)
SquadLine.contextTypes = { t: PropTypes.func }

export const DimonaLine = ({ dimona, customClass = "", children, noLink = false }, context) => {
	let link = noLink ? "#" : "/dimona/" + dimona.id
	return (
		<DatedLine
			customClass={"dimona-order-line " + customClass}
			link={link}
			day={dimona.executionDate.format("ddd")}
			date={dimona.executionDate.date()}
			month={dimona.executionDate.format("MMMM")}
		>
			<div className="column names">
				<div className="customer-name">testing</div>
			</div>
			{children}
		</DatedLine>
	)
}
DimonaLine.contextTypes = { t: PropTypes.func }

export const OnssErrorModal = ({ closeModal, errors, errorsTradKeys }, { t }) => {
	let displayTrad = errorsTradKeys.some(e => !!t(e))
	return (
		<ModalLayout customClass="OnssErrorModal" title={t("onss_error")} closeModal={closeModal}>
			{displayTrad ? (
				<div className="translated-error">{errorsTradKeys.map(e => t(e))}</div>
			) : (
				errorsTradKeys.map(e => (
					<div
						key={e}
						className="raw-error"
						onClick={() => {
							window.open("http://www.google.com/search?q=" + e.replace(/_/g, " "))
						}}
					>
						{e}
					</div>
				))
			)}
		</ModalLayout>
	)
}
OnssErrorModal.contextTypes = { t: PropTypes.func }

export const Tooltip = ({ children }) => {
	return <div className="Tooltip">{children}</div>
}

export const CustomerBalanceDisplayer = ({ balance }, { t }) => {
	if (!balance) {
		return null
	}
	return (
		<div className="CustomerBalanceDisplayer">
			<NoCSSInfoBox
				label={t("customer_workshop_total_minutes")}
				value={balance.order_minutes_total}
			/>
			<NoCSSInfoBox
				label={t("workshop_total_due_sv")}
				value={balance.total_due_service_vouchers}
			/>
			<NoCSSInfoBox
				label={t("workshop_service_vouchers_total_paid")}
				value={balance.service_vouchers_total}
			/>
			<NoCSSInfoBox
				label={t(
					balance.current_minutes_balance < 0
						? "customer_workshop_voucher_debit"
						: "customer_workshop_voucher_credit"
				)}
				value={balance.current_minutes_balance}
			/>
		</div>
	)
}
CustomerBalanceDisplayer.contextTypes = { t: PropTypes.func }

export const TimesheetTotalHoursMonth = ({ currTimesheet, customClass }, context) => {
	let t = context.t
	return (
		<div className={`TimesheetTotalHoursMonth ${customClass}`}>
			<div className="monthly-total-hours">
				{t(
					...currTimesheet.codesStats.monthlyTotalHoursTranslateArgs(t("hours_shorthand"))
				)}
			</div>
			<div className="vacant-hours">
				{currTimesheet.codesStats.month.sum_ths !== 0 &&
					t("timesheet_monthly_vacant_hours", {
						vacantHours: formatMinutes(
							Math.round(
								(currTimesheet.codesStats.month.sum_ths -
									currTimesheet.codesStats.month.sum_all_codes) *
									100
							) / 100,
							t("hours_shorthand")
						),
					})}
			</div>
			{Object.keys(currTimesheet.codesStats.month.codes_sum).map(entry => (
				<div key={entry} className="week-info">
					<div className="label">{t(entry)}:</div>
					&nbsp;
					<div className="value">
						{formatMinutes(
							Math.round(currTimesheet.codesStats.month.codes_sum[entry] * 100) / 100,
							t("hours_shorthand")
						)}
					</div>
				</div>
			))}
			<div className="vouchers-separator" />
			{Object.keys(currTimesheet.codesStats.month.vouchers).map(voucher => (
				<div key={voucher} className="week-info">
					<div className="label">{t(`${voucher}_expected`)}:</div>
					&nbsp;
					<div className="value">{currTimesheet.codesStats.month.vouchers[voucher]}</div>
				</div>
			))}
		</div>
	)
}
TimesheetTotalHoursMonth.contextTypes = { t: PropTypes.func }
