import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"

import * as actions from "../actions"

import ModalLayout from "./ModalLayout"
import { PootsySelectInput } from "./FunctionalInputs"
import { PootsyButton, LabeledDateTime, NoCSSInfoBox } from "./FunctionalDesign"
import SearchWorker from "./SearchWorker"

class NewEconomicSuspensionModal extends Component {
	componentWillUnmount = () => {
		this.props.dispatch(actions.resetNewEconomicSuspensionsModalState())
	}
	handleChanges = changes => {
		this.props.dispatch(actions.newEconomicSuspensionModalStateChange(changes))
	}
	handleSearchWorker = workerName => {
		this.handleChanges({ chosenWorker: { name: workerName, sodexo: "" } })
	}
	handleWorkerClick = worker => {
		let newChosenWorker = {
			name: worker.attributes.display_name,
			firstName: worker.attributes.first_name,
			lastName: worker.attributes.last_name,
			sodexo: worker.attributes.sodexo_reference,
			niss: worker.attributes.niss,
			affiliateWorkerId: Number(worker.id),
		}
		this.handleChanges({ chosenWorker: newChosenWorker })
		this.props.dispatch(actions.resetWorkerSuggestions())
	}
	submitSuspension = () => {
		let {
			customSubmit,
			component: {
				chosenWorker,
				startDate,
				endDate,
				suspensionType,
				declarationOnHonour,
				jointCommissionLevel,
			},
		} = this.props
		if (customSubmit) {
			customSubmit({
				chosenWorker,
				startDate,
				endDate,
				suspensionType,
				declarationOnHonour,
				jointCommissionLevel,
			})
			return
		}
		this.props.dispatch(actions.createSuspension())
	}
	render() {
		let { t } = this.context
		let {
			closeModal,
			bypassWorkerSearch,
			component: {
				chosenWorker,
				startDate,
				endDate,
				suspensionType,
				declarationOnHonour,
				jointCommissionLevel,
			},
		} = this.props
		return (
			<ModalLayout
				customClass="NewEconomicSuspensionModal"
				title={
					this.props.mode && this.props.mode === "batch"
						? t("batch_economic_suspensions")
						: t("new_economic_suspension")
				}
				withInputsChangeHandler={true}
				handleChanges={this.handleChanges}
				closeModal={closeModal}
			>
				{!bypassWorkerSearch && (
					<SearchWorker
						onChange={this.handleSearchWorker}
						onClick={this.handleWorkerClick}
						value={chosenWorker.name}
					/>
				)}
				{(chosenWorker.firstName || bypassWorkerSearch) && (
					<Fragment>
						{!bypassWorkerSearch && (
							<Fragment>
								<NoCSSInfoBox
									label={t("worker")}
									value={chosenWorker.firstName + " " + chosenWorker.lastName}
								/>
								<NoCSSInfoBox label={t("niss")} value={chosenWorker.niss} />
							</Fragment>
						)}
						<PootsySelectInput
							name="declarationOnHonour"
							defaultText={t("declaration_on_honour")}
							selected={declarationOnHonour}
							options={[
								{ label: t("no_honour_declaration"), value: "0" },
								{ label: t("honour_declaration_one"), value: "1" },
							]}
						/>
						<PootsySelectInput
							name="suspensionType"
							defaultText={t("suspensionType")}
							selected={suspensionType}
							options={[
								{ label: t("small_suspension"), value: "small_suspension" },
								{ label: t("big_suspension"), value: "big_suspension" },
							]}
						/>
						<div className="dates">
							<LabeledDateTime
								label={t("date_start")}
								showTimePicker={false}
								datePickerProps={{
									name: "startDate",
									value: startDate,
									onChange: this.handleChanges,
									disableDay: day =>
										day.isBefore(moment().add(8, "days"), "day") ||
										day.isSameOrAfter(endDate, "day"),
								}}
							/>
							<LabeledDateTime
								label={t("date_end")}
								showTimePicker={false}
								datePickerProps={{
									name: "endDate",
									value: endDate,
									onChange: this.handleChanges,
									disableDay: day => day.isSameOrBefore(startDate, "day"),
								}}
							/>
						</div>
						<PootsySelectInput
							name="jointCommissionLevel"
							defaultText={t("worker_profile")}
							selected={jointCommissionLevel}
							options={[
								{ label: t("OUVRIER"), value: "000" },
								{ label: t("EMPLOYEE"), value: "050" },
							]}
						/>
						<PootsyButton
							text={t("create_suspension")}
							onClick={this.submitSuspension}
						/>
					</Fragment>
				)}
			</ModalLayout>
		)
	}
}

NewEconomicSuspensionModal.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.newEconomicSuspensionsModalComponent,
})
export default connect(mapStateToProps)(NewEconomicSuspensionModal)
