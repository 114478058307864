import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import * as actions from "../actions"

import { PootsyButton, PootsySubHeading, SimpleInfoBox } from "./FunctionalDesign"
import { PootsyRadioInputs, PootsyTextInput, PootsyCheckboxInput } from "./FunctionalInputs"
import ModalLayout from "../components/ModalLayout"
import WorkshopOrderQuoteDisplayer from "./WorkshopOrderQuoteDisplayer"
import AvailableWorkshopOrderItemInput from "./AvailableWorkshopOrderItemInput"

import arrowRightIcon from "../static/images/arrow-right-icon.png"

class FinishWorkshopOrderModal extends Component {
	state = {
		queuedCall: "",
	}
	UNSAFE_componentWillMount = async () => {
		let {
			dispatch,
			currentWorkshopOrder: { items, quote: originalQuote },
		} = this.props
		dispatch(actions.fetchAvailableOrderItemsList())
		await this.handleChanges({ currentQuote: { ...originalQuote }, items: items })
		this.fetchOrderQuote()
	}
	componentWillUnmount = () => {
		this.props.dispatch(actions.resetFinishWorkshopOrderModalState())
	}
	handleChanges = changes => {
		this.props.dispatch(actions.finishWorkshopOrderModalStateChange(changes))
	}
	handleCurrentOrderChange = async changes => {
		let {
			currentWorkshopOrder: { quote, workshopOrderBillingBasis },
		} = this.props
		if (workshopOrderBillingBasis === "estimated" && changes.executedDurationMinutes) {
			return
		}
		if (changes.executedDurationMinutes || changes.workshopOrderBillingBasis) {
			if (changes.workshopOrderBillingBasis === "estimated") {
				changes.executedDurationMinutes = quote.estimatedDuration
			}
			await this.props.dispatch(actions.editWorkshopOrder({ ...changes }))
			this.fetchOrderQuote()
		}
	}
	handleOrderItemChange = async ({ avItemID, quantity }) => {
		let {
			component: { items },
		} = this.props
		let existingObj = items.find(entry => entry.avItemID === avItemID)
		let newChosenOrderItems = []
		if (existingObj) {
			newChosenOrderItems = items.map(entry =>
				entry.avItemID === avItemID ? { avItemID, quantity } : entry
			)
		} else {
			newChosenOrderItems = [...items, { avItemID, quantity }]
		}
		newChosenOrderItems = newChosenOrderItems.filter(entry => entry.quantity !== 0)
		await this.handleChanges({ items: newChosenOrderItems })
		this.fetchOrderQuote()
	}
	handleNotifyCustomerChange = e => {
		e.stopPropagation()
		this.handleChanges({ notifyCustomerForFinishedOrder: e.target.checked })
	}
	fetchOrderQuote = () => {
		let {
			dispatch,
			currentWorkshopOrder,
			component: { items },
		} = this.props
		let { queuedCall } = this.state
		clearTimeout(queuedCall)
		let futureCall
		futureCall = setTimeout(() => {
			dispatch(
				actions.fetchOrderQuote(
					items,
					currentWorkshopOrder.customer,
					currentWorkshopOrder.executedDurationMinutes,
					currentWorkshopOrder.workshopOrderBillingBasis
				)
			)
		}, 1000)
		this.setState({ queuedCall: futureCall })
	}
	toggleOrderItems = () => {
		let {
			component: { showOrderItems },
		} = this.props
		this.handleChanges({ showOrderItems: !showOrderItems })
	}
	submitFinishWorkshopOrder = () => {
		let {
			dispatch,
			currentWorkshopOrder,
			component: { items },
		} = this.props
		dispatch(
			actions.submitUpdateWorkshopOrder({
				...currentWorkshopOrder,
				status: "DONE",
				items,
			})
		)
	}
	render() {
		let { t } = this.context
		let {
			availableOrderItems,
			component: {
				showRequired,
				showOrderItems,
				items,
				currentQuote,
				notifyCustomerForFinishedOrder,
				fetchingQuote,
			},
			currentWorkshopOrder: { executedDurationMinutes, workshopOrderBillingBasis },
			closeModal,
		} = this.props
		let serviceVoucherDetails = currentQuote.details.find(entry => {
			return entry.categoryName === "SERVICE_VOUCHER"
		}) || { totalDetails: {} }
		return (
			<ModalLayout
				customClass="FinishWorkshopOrderModal"
				title={t("finish_workshop_order")}
				closeModal={closeModal}
				withInputsChangeHandler={true}
				handleChanges={this.handleCurrentOrderChange}
				buttons={[
					<PootsyButton
						key="a"
						text={t("finish_workshop_order")}
						onClick={this.submitFinishWorkshopOrder}
					/>,
					<PootsyButton key="b" text={t("cancel")} theme="cancel" onClick={closeModal} />,
				]}
			>
				<PootsySubHeading text={t("workshop_voucher_details_title")} />
				<div className="billing-basis-title">{t("workshop_order_billing_basis")}</div>
				<div className="workshop-durations">
					<PootsyTextInput
						label={t("executed_duration_minutes")}
						name="executedDurationMinutes"
						showRequired={showRequired.executedDurationMinutes}
						value={executedDurationMinutes || ""}
					/>
					<SimpleInfoBox
						label={t("estimated_duration_minutes")}
						value={serviceVoucherDetails.totalDetails.category_total}
					/>
				</div>
				<PootsyRadioInputs
					groupName="workshopOrderBillingBasis"
					groupLabel=""
					selected={workshopOrderBillingBasis}
					options={[
						{
							id: "radio-executed-duration",
							value: "executed",
							label: t("workshop_order_executed_duration"),
						},
						{
							id: "radio-estimated-duration",
							value: "estimated",
							label: t("workshop_order_estimated_duration"),
						},
					]}
				/>
				<div className="workshop-voucher-infos">
					<SimpleInfoBox
						label={t("estimated_balance_minutes_used")}
						value={serviceVoucherDetails.totalDetails.estimated_balance_minutes_used}
					/>
					<SimpleInfoBox
						label={t("billable_minutes")}
						value={serviceVoucherDetails.totalDetails.estimated_billable_minutes}
					/>
					<SimpleInfoBox
						label={t("billable_service_voucher")}
						value={
							serviceVoucherDetails.totalDetails.estimated_billable_service_voucher
						}
					/>
					<SimpleInfoBox
						label={t("minutes_to_be_reported")}
						value={serviceVoucherDetails.totalDetails.estimated_minutes_to_be_reported}
					/>
				</div>
				<WorkshopOrderQuoteDisplayer
					quote={currentQuote}
					categoryFilter="SERVICE_VOUCHER"
					handleOrderItemChange={this.handleOrderItemChange}
					loading={fetchingQuote}
				/>
				<div
					className={"collapser" + (showOrderItems ? "" : " toggled")}
					onClick={this.toggleOrderItems}
				>
					<img className="arrow-img" src={arrowRightIcon} alt="arrow" />{" "}
					<PootsySubHeading text={t("toggle_order_items")} />
				</div>
				<PootsyCheckboxInput
					name="notifyCustomerForFinishedOrder"
					label={t("notify_customer_for_finished_order")}
					checked={notifyCustomerForFinishedOrder}
					onChange={this.handleNotifyCustomerChange}
				/>
				<div className="order-items">
					{showOrderItems && (
						<div className="order-item-category">
							<PootsySubHeading text={t("CASH")} />
							<div className="items">
								{availableOrderItems
									.filter(entry => entry.categoryName === "CASH")
									.map(avOrderItem => {
										let chosen = items.find(
											entry => entry.avItemID === avOrderItem.id
										)
										let quantity = chosen ? chosen.quantity : 0
										return (
											<AvailableWorkshopOrderItemInput
												key={avOrderItem.id}
												orderItem={avOrderItem}
												quantity={quantity}
												onChange={this.handleOrderItemChange}
											/>
										)
									})}
							</div>
						</div>
					)}
				</div>
			</ModalLayout>
		)
	}
}

FinishWorkshopOrderModal.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currentWorkshopOrder: state.redData.currentWorkshopOrder,
	currentAffiliate: state.redData.currentAffiliate,
	availableOrderItems: state.redData.avOrderItemsList,
	component: state.redComponents.finishWorkshopOrderModalComponent,
})
export default connect(mapStateToProps)(FinishWorkshopOrderModal)
