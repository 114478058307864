import React, { Component } from "react"
import PropTypes from "prop-types"

import { SimpleLine } from "./FunctionalDesign"
export class LineIndexation extends Component {
	state = {
		showWorker: false,
	}
	toggleShowWorkers = () => {
		this.setState({ showWorker: !this.state.showWorker })
	}
	showUnitSymbol = () => {
		if (this.props.indexation.attributes.indexation_mode === "percentage_mode") {
			return "%"
		} else {
			return "€"
		}
	}

	render() {
		let indexation = this.props.indexation
		let { t } = this.context
		return (
			<SimpleLine noLink={true} customClass="indexation-line">
				<div className="indexation-info">{indexation.attributes.date_indexation} </div>
				<div className="indexation-info">{t(indexation.attributes.indexation_mode)} </div>
				<div className="indexation-info">
					{indexation.attributes.indexation_value.toString().replace(".", ",")}{" "}
					{this.showUnitSymbol()}
				</div>
				{!this.state.showWorker && (
					<div>
						<div className="indexation-info">
							{indexation.attributes.affiliate_workers.length} {t("workers")}
						</div>
						<div className="indexation-info" onClick={this.toggleShowWorkers}>
							{indexation.attributes.successful_workers.length} {t("success")}
						</div>
						<div className="indexation-info">
							{indexation.attributes.failed_workers.length} {t("failure")}
						</div>
					</div>
				)}
				{this.state.showWorker && (
					<div className="display-workers">
						{indexation.attributes.successful_workers.map(worker_name => (
							<div
								className="indexation-info success"
								onClick={this.toggleShowWorkers}
								key={worker_name}
							>
								{worker_name}
							</div>
						))}
						{indexation.attributes.failed_workers.map(worker_name => (
							<div
								className="indexation-info fail"
								onClick={this.toggleShowWorkers}
								key={worker_name}
							>
								{worker_name}
							</div>
						))}
					</div>
				)}

				<div className="indexation-info">{indexation.attributes.origin_affiliate} </div>
				<div className="indexation-status">{t(indexation.attributes.status)}</div>
				<div className="indexation-date">{indexation.attributes.scheduled_date}</div>
			</SimpleLine>
		)
	}
}

LineIndexation.contextTypes = { t: PropTypes.func }
