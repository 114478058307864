import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"

import { PootsyButton, LabeledDateTime } from "./FunctionalDesign"
import ModalLayout from "./ModalLayout"
import { PootsyRadioInputs } from "./FunctionalInputs"
import Filter from "./Filter"
import PootsyCalendar from "./PootsyCalendar"
import CompleteMonthPicker from "./CompleteMonthPicker"

import moment from "moment"

export default class CustomizePDFModal extends Component {
	state = {
		codeSumsSquads: [],
		codesSumsTimeSpan: "",
		codeSumsFromDate: moment().startOf("month"),
		selectedPrintDate: moment(),
	}
	handleStateChange = changes => {
		this.setState({ ...changes })
	}
	handleCodeSumsSquadsFilterChange = changes => {
		this.handleStateChange({ [changes.name]: changes.value })
	}
	render() {
		let { t } = this.context
		let { teams, origin, toggleCustomizePDFModal } = this.props
		let { codeSumsFromDate, codesSumsTimeSpan, codeSumsSquads, selectedPrintDate } = this.state

		return (
			<ModalLayout
				customClass="timesheet-codes-sums-modal"
				closeModal={toggleCustomizePDFModal}
				withInputsChangeHandler={true}
				handleChanges={this.handleStateChange}
				title={t("timesheets_codes_sums_what_to_generate")}
				buttons={[
					<PootsyButton
						key="a"
						text={t("timesheet_download_codes_sums_pdf")}
						onClick={() =>
							this.props.onConfirm(
								codeSumsFromDate,
								codesSumsTimeSpan,
								codeSumsSquads,
								selectedPrintDate
							)
						}
					/>,
					<PootsyButton
						key="b"
						theme="cancel"
						text={t("cancel")}
						onClick={toggleCustomizePDFModal}
					/>,
				]}
			>
				{origin !== "workerTimesheet" && (
					<Fragment>
						<Filter
							label={t("squads")}
							name="codeSumsSquads"
							possibleValues={teams.map(entry => ({
								label: entry.attributes.name,
								value: entry.id,
							}))}
							currentFilters={this.state.codeSumsSquads}
							onChange={this.handleCodeSumsSquadsFilterChange}
						/>
						<PootsyRadioInputs
							groupName="codesSumsTimeSpan"
							groupLabel={t("timesheets_codes_sums_timespan")}
							selected={this.state.codesSumsTimeSpan}
							options={[
								{ id: "month", value: "month", label: t("by_month") },
								{ id: "week", value: "week", label: t("by_week") },
							]}
						/>
						{this.state.codesSumsTimeSpan === "week" && (
							<PootsyCalendar
								name="codeSumsFromDate"
								value={this.state.codeSumsFromDate.isoWeekday(1)}
								weekPickerMode={true}
								alwaysOpened={true}
								calendarStyle={{ top: "100%", left: 0 }}
								disableDay={d =>
									d.isAfter(
										moment()
											.startOf("month")
											.add(2, "month")
									)
								}
								onChange={this.handleStateChange}
							/>
						)}
						{this.state.codesSumsTimeSpan === "month" && (
							<CompleteMonthPicker
								currentDate={this.state.codeSumsFromDate}
								filter={m => m.isBefore(moment().add(1, "month"))}
								onChange={m => {
									this.handleStateChange({ codeSumsFromDate: m })
								}}
							/>
						)}
					</Fragment>
				)}
				<LabeledDateTime
					label={t("timesheet_pdf_selected_print_date")}
					showTimePicker={false}
					datePickerProps={{
						name: "selectedPrintDate",
						value: selectedPrintDate,
						onChange: this.handleStateChange,
						calendarStyle: { bottom: "200%", left: "100%", zIndex: "2" },
					}}
				/>
			</ModalLayout>
		)
	}
}

CustomizePDFModal.contextTypes = { t: PropTypes.func }
