import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import * as actions from "../actions"

import moment from "moment"
import { PootsyButton, LabeledDateTime } from "./FunctionalDesign"
import ModalLayout from "./ModalLayout"
import { PootsySelectInput, PootsyCheckboxInput } from "./FunctionalInputs"

class CancelBookingModal extends Component {
	state = {
		cancelDate: "",
	}
	handleChanges = changes => {
		this.props.dispatch(actions.cancelBookingModalChange(changes))
	}
	submit = () => {
		let { dispatch, closeModal } = this.props
		dispatch(actions.submitCancelBooking(this.state.cancelDate))
		closeModal()
	}

	handleDateChanges = changes => {
		this.setState({ cancelDate: changes.absenceStart })
	}

	render() {
		let { t } = this.context
		let { closeModal, component } = this.props
		let buttons = [
			<PootsyButton key="a" text={t("submit")} onClick={this.submit} />,
			<PootsyButton key="b" text={t("cancel")} theme="cancel" onClick={closeModal} />,
		]
		return (
			<ModalLayout
				title={t("cancel_booking")}
				buttons={buttons}
				closeModal={closeModal}
				formDivClass="cancel-booking-modal"
				withInputsChangeHandler={true}
				handleChanges={this.handleChanges}
			>
				<div className="warning">
					{component.recurrentBooking
						? t("cancel_all_occurences_or_only_one?")
						: t("cancel_booking_warning_ponctual")}
				</div>
				<div className="warning-this-is-not-delete">{t("warning_this_is_not_delete")}</div>
				{component.recurrentBooking && (
					<PootsySelectInput
						name="reach"
						defaultText={t("cancel_reach")}
						selected={component.reach}
						options={[
							{
								label: t("cancel_all_occurences"),
								value: "definitive_from_date",
							},
							{ label: t("cancel_one_occurence"), value: "custom" },
						]}
					/>
				)}
				{component.reach === "definitive_from_date" && (
					<>
						<div style={{ marginBottom: "15px" }}>{t("cancel_from_date")}</div>
						<LabeledDateTime
							label={t("start_date")}
							showTimePicker={false}
							datePickerProps={{
								value: this.state.cancelDate || moment(component.deliveryDate),
								name: "absenceStart",
								onChange: this.handleDateChanges,
								disableDay: current =>
									current.isBefore(moment(component.deliveryDate), "day"),
							}}
						/>
					</>
				)}
				<PootsyCheckboxInput
					name="createPendingBookingWishes"
					label={t("create_pending_booking_wishes")}
					checked={component.createPendingBookingWishes}
				/>
			</ModalLayout>
		)
	}
}

CancelBookingModal.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.cancelBookingModal,
})
export default connect(mapStateToProps)(CancelBookingModal)
