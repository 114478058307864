import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import * as actions from "../actions"

import InputsChangeHandler from "./InputsChangeHandler"
import PootsyAddressBlockInputs from "./PootsyAddressBlockInputs"
import { PootsyButton } from "./FunctionalDesign"
import ModalLayout from "./ModalLayout"
import { PootsyTextInput, PootsyRadioInputs, PootsySelectInput } from "./FunctionalInputs"

class NewResidenceModal extends Component {
	constructor() {
		super()
		this.state = {
			street: "",
			streetNumber: "",
			box: "",
			zip: "",
			city: "",
			residenceType: "",
			surface: "",
			customerPets: "",
			howToGetIn: "",
			otherInfo: "",
			showRequired: {
				street: false,
				streetNumber: false,
				box: false,
				zip: false,
				city: false,
				residenceType: false,
				surface: false,
				customerPets: false,
				howToGetIn: false,
				otherInfo: false,
			},
		}
		// ( to be able to pass it down to <InputsChangeHandler/> )
		this.setState = this.setState.bind(this)
	}
	toggleRequiredColor = (el, makeItRed) => {
		this.setState(state => {
			state.showRequired[el] = makeItRed
			return state
		})
	}
	submit = () => {
		let { dispatch, customerId, onCancel, context } = this.props
		let {
			street,
			streetNumber,
			box,
			zip,
			city,
			residenceType,
			surface,
			customerPets,
			howToGetIn,
			otherInfo,
			country,
		} = this.state

		let arrayToCheck = ["street", "streetNumber", "zip", "city"]

		let valid = true
		arrayToCheck.forEach(entry => {
			if (this.state[entry] === "") {
				valid = false
				this.toggleRequiredColor(entry, true)
			} else {
				this.toggleRequiredColor(entry, false)
			}
		})
		if (valid) {
			let init = {
				method: "POST",
				body: JSON.stringify({
					customer_id: customerId,
					residence_type: residenceType,
					surface: surface,
					pets: customerPets,
					street_name: street,
					street_number: streetNumber,
					how_to_get_in: howToGetIn,
					other_info: otherInfo,
					street_box: box,
					zip_code: zip,
					town: city,
					country: country,
				}),
			}
			let actionType = {
				directBooking: "NEWDIRECTBOOKING_SUBMITNEWRESIDENCE",
				booking: "NEWBOOKING_SUBMITNEWRESIDENCE",
				customerProfile: "CUSTOMERPROFILE_SUBMITNEWRESIDENCE",
			}[context]
			let url = "/front/affiliates/customers/residences"
			dispatch(actions.simpleFetch(url, init, actionType))
			onCancel()
		}
	}
	render() {
		let { t } = this.context
		let { onCancel } = this.props
		let {
			showRequired,
			street,
			streetNumber,
			box,
			zip,
			city,
			country,
			residenceType,
			surface,
			howToGetIn,
			otherInfo,
			customerPets,
		} = this.state
		let buttons = [
			<PootsyButton key="a" text={t("confirm")} onClick={this.submit} />,
			<PootsyButton key="b" text={t("cancel")} theme="cancel" onClick={onCancel} />,
		]
		return (
			<InputsChangeHandler setParentState={this.setState}>
				<ModalLayout
					title={t("new_residence_details")}
					buttons={buttons}
					closeModal={onCancel}
				>
					<div className="new-residence-modal-inputs">
						<label className="section-label">{t("residence_details")}</label>
						<div className="new-residence-modal-column residence-details">
							<PootsySelectInput
								name="residenceType"
								defaultText={t("residence_type")}
								showRequired={showRequired.residenceType}
								selected={residenceType}
								options={[
									{ label: t("apartment"), value: "apartment" },
									{ label: t("house"), value: "house" },
									{ label: t("other"), value: "other" },
								]}
							/>
							<PootsyTextInput
								name="surface"
								type="number"
								label={t("surface")}
								showRequired={showRequired.surface}
								value={surface}
							/>
							<PootsyRadioInputs
								groupName="customerPets"
								groupLabel={t("pets")}
								showRequired={showRequired.customerPets}
								selected={customerPets}
								options={[
									{ id: "radio-pets", value: "true", label: t("yes") },
									{ id: "radio-no-pets", value: "false", label: t("no") },
								]}
							/>
						</div>
						<label className="section-label">{t("address")}</label>
						<div className="new-residence-modal-column">
							<PootsyAddressBlockInputs
								setValues={this.setState}
								showRequired={showRequired}
								values={{
									city: city,
									zip: zip,
									box: box,
									streetNumber: streetNumber,
									street: street,
									country: country,
								}}
							/>
							<PootsyTextInput
								name="howToGetIn"
								type="text"
								label={t("how_to_get_in")}
								value={howToGetIn}
							/>
							<PootsyTextInput
								name="otherInfo"
								type="text"
								label={t("other_info")}
								value={otherInfo}
							/>
						</div>
					</div>
				</ModalLayout>
			</InputsChangeHandler>
		)
	}
}

NewResidenceModal.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({})
export default connect(mapStateToProps)(NewResidenceModal)
