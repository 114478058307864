import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import * as actions from "../actions"

export class AffiliatePicker extends Component {
	handleAffiliateClick = e => {
		let { affiliates } = this.props
		let chosenAffiliate = affiliates.find(aff => aff.id.toString() === e.target.dataset.id)
		this.props.dispatch(actions.setAffiliate(chosenAffiliate))
	}
	render() {
		let { t } = this.context
		let { affiliates } = this.props
		return (
			<div className="affiliate-picker">
				<div className="picker-box">
					<div className="box-title">{t("affiliate_accessible_picker_title")}</div>
					<div className="affiliates">
						{affiliates.map((aff, i) => (
							<div
								key={i}
								className="affiliate"
								onClick={this.handleAffiliateClick}
								data-id={aff.id}
							>
								{aff.commercialName}
							</div>
						))}
					</div>
				</div>
			</div>
		)
	}
}

AffiliatePicker.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	affiliates: state.redData.affiliates,
})
export default connect(mapStateToProps)(AffiliatePicker)
