import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"

import * as actions from "../actions"
import {
	PageLayout,
	ContentLayout,
	SmallSpinner,
	NoCSSInfoBox,
	SimpleLine,
	OptionBox,
	PootsySubHeading,
} from "./FunctionalDesign"

class PaymentReminderPage extends Component {
	componentDidMount = () => {
		let {
			dispatch,
			match: { params },
		} = this.props
		dispatch(actions.fetchPaymentReminder(params.id))
	}
	componentWillUnmount = () => {
		this.props.dispatch(actions.resetPaymentReminder())
	}
	handleChanges = changes => {
		this.props.dispatch(actions.PaymentReminderPageStateChange(changes))
	}
	line = entry => {
		let { t } = this.context
		return (
			<SimpleLine key={entry.customerName} noLink={true} customClass="targeted-customer">
				<NoCSSInfoBox label={t("customer_name")} value={entry.customerName} />
				<NoCSSInfoBox label={t("customer_address")} value={entry.customerAddress} />
				<NoCSSInfoBox
					label={t("total_missing_sv_count_for_client")}
					value={entry.totalMissingSvCount}
				/>
				<NoCSSInfoBox
					label={t("total_occurrences_for_payment_reminder")}
					value={entry.totalOccurrences}
				/>
			</SimpleLine>
		)
	}
	render() {
		let { t } = this.context
		let { paymentReminder } = this.props
		let pageHeaderLeft = (
			<h1 className="page-title customers">{t("payment_reminder_page_title")}</h1>
		)
		let optionsBoxes = []
		if (paymentReminder.id) {
			optionsBoxes = (
				<OptionBox boxTitle={t("payment_reminder_infos")}>
					<NoCSSInfoBox
						label={t("creation_date")}
						value={moment(paymentReminder.attributes.created_at).format("DD/MM/YYYY")}
					/>
					<NoCSSInfoBox label={t("author")} value={paymentReminder.attributes.author} />
				</OptionBox>
			)
		}

		let sentLines = []
		let unsentLines = []
		if (paymentReminder.id) {
			paymentReminder.attributes.targeted_customers.forEach(entry => {
				let totalMissingSvCount = 0
				let sent = false
				entry.booking_occurrences.forEach(entry => {
					if (entry.reminder_status === "SENT") {
						sent = true
					}
					totalMissingSvCount += entry.missing_sv_count || 0
				})
				let line = {
					customerName: entry.customer.display_name,
					customerAddress: entry.customer.address,
					totalOccurrences: entry.booking_occurrences.length,
					totalMissingSvCount,
					sent,
				}
				if (sent) {
					sentLines.push(line)
				} else {
					unsentLines.push(line)
				}
			})
		}
		return (
			<PageLayout customClass="PaymentReminderPage" headerLeft={pageHeaderLeft}>
				{!paymentReminder.id ? (
					<SmallSpinner show={true} />
				) : (
					<ContentLayout optionsBoxes={optionsBoxes}>
						<div className="top"></div>
						<div className="targeted-customers">
							{unsentLines.length > 0 && (
								<div>
									<PootsySubHeading text={t("unsent_lines_title")} />
									<div className="unsent-lines">{unsentLines.map(this.line)}</div>
								</div>
							)}
							{sentLines.length > 0 && (
								<div>
									<PootsySubHeading text={t("sent_lines_title")} />
									<div className="sent-lines">{sentLines.map(this.line)}</div>
								</div>
							)}
						</div>
					</ContentLayout>
				)}
			</PageLayout>
		)
	}
}

PaymentReminderPage.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.paymentReminderPageComponent,
	paymentReminder: state.redData.paymentReminder,
})
export default connect(mapStateToProps)(PaymentReminderPage)
