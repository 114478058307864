import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"

import { LabeledDateTime, PootsySubHeading } from "./FunctionalDesign"
import { PootsyRadioInputs, PootsyTextArea, PootsySelectInput } from "./FunctionalInputs"
import CustomSelect from "./CustomSelect"

export class WorkerContractInputs extends Component {
	render() {
		let { t } = this.context
		let {
			contract,
			showRequired,
			affiliateActiveAreas,
			superAreas,
			lang,
			handleFormChanges,
			previousContract,
			dimonaAddOn,
			operatingHeadquarters,
			currentEditedKeys,
		} = this.props
		let superAreaValues = superAreas.map(superArea => ({
			label: superArea.attributes["name_" + lang],
			values: superArea.attributes.areas,
		}))
		superAreaValues.push({
			label: t("add_all_affiliate_areas"),
			values: affiliateActiveAreas.map(entry => entry.value),
		})
		let disabledStartDateDays = day =>
			(contract.endDate.isValid() && day.isAfter(contract.endDate, "day")) ||
			(previousContract &&
				day.isSameOrBefore(previousContract.startDate) &&
				((previousContract.endDate && day.isBefore(previousContract.endDate, "day")) ||
					(contract.oldContractEndDate &&
						day.isBefore(contract.oldContractEndDate, "day"))))
		return (
			<Fragment>
				<div className="dates section">
					<PootsySubHeading text={t("dates")} />
					<LabeledDateTime
						label={t("start_date")}
						showTimePicker={false}
						datePickerProps={{
							name: "startDate",
							value: contract.startDate,
							onChange: handleFormChanges,
							calendarStyle: { top: "150%", left: 0 },
							disableDay: disabledStartDateDays,
						}}
					/>
					<LabeledDateTime
						label={t("signing_date")}
						showTimePicker={false}
						datePickerProps={{
							name: "signingDate",
							value: contract.signingDate,
							onChange: handleFormChanges,
							calendarStyle: { top: "150%", left: 0 },
						}}
					/>
					<LabeledDateTime
						label={t("end_date")}
						showTimePicker={false}
						datePickerProps={{
							name: "endDate",
							value: contract.endDate,
							onChange: handleFormChanges,
							calendarStyle: { top: "150%", right: 0 },
							disableDay: day => day.isBefore(contract.startDate, "day"),
							allowUnset: true,
						}}
					/>
				</div>
				<div className="transportation section">
					<PootsySubHeading text={t("transportation")} />
					<PootsyRadioInputs
						groupName="vehicleType"
						groupLabel={t("vehicle_type")}
						showRequired={showRequired.vehicleType}
						selected={contract.vehicleType}
						options={[
							{ id: "car", value: "car", label: t("car") },
							{ id: "bike", value: "bike", label: t("bike") },
							{
								id: "public_transport",
								value: "public_transport",
								label: t("public_transport"),
							},
							{ id: "company_car", value: "company_car", label: t("company_car") },
							{ id: "other", value: "other", label: t("other") },
						]}
					/>
				</div>
				<div className="section">
					<PootsySubHeading text={t("contract_sponsor")} />
					<PootsyRadioInputs
						groupName="sponsor"
						groupLabel={t("contract_sponsor_label")}
						selected={contract.sponsor}
						options={[
							{ id: "true", value: "true", label: t("yes") },
							{ id: "false", value: "false", label: t("no") },
						]}
					/>
				</div>
				<div className="comment section">
					<PootsySubHeading text={t("comment")} />
					<PootsyTextArea label={t("comment")} name="comment" value={contract.comment} />
				</div>
				<div className="section covered-areas">
					<PootsySubHeading text={t("areas_covered")} />
					<CustomSelect
						name="coveredAreas"
						selectLabel={t("areas_covered")}
						valuesGroups={superAreaValues}
						valuesList={affiliateActiveAreas}
						currentValues={contract.coveredAreas}
						onChange={handleFormChanges}
						showRequired={showRequired.coveredAreas}
						isRequired={true}
					/>
				</div>
				<div className="section contract-variability">
					<PootsySubHeading text={t("contract_variability")} />
					<PootsyRadioInputs
						groupName="contractVariability"
						groupLabel={t("contract_type")}
						showRequired={showRequired.contractVariability}
						selected={contract.contractVariability}
						displayWarning={
							contract.id !== "new-contract" &&
							currentEditedKeys.includes("contractVariability")
						}
						warning={t("contract_variability_change_warning")}
						options={[
							{ id: "fixed_flex", value: "fixed_flex", label: t("fixed_flex") },
							{
								id: "fixed_flex_week",
								value: "fixed_flex_week",
								label: t("fixed_flex_week"),
							},
							{ id: "fixed", value: "fixed", label: t("fixed") },
							{ id: "variable", value: "variable", label: t("variable") },
							{
								id: "var_var",
								value: "variable_variable",
								label: t("variable_variable"),
							},
						]}
					/>
				</div>
				{contract.addendum && (
					<div className="section">
						<PootsySubHeading text={t("contract_term")} />
						<PootsyRadioInputs
							groupName="fixedTerm"
							groupLabel={t("type_of_term")}
							selected={contract.fixedTerm}
							options={[
								{ id: "fixed_term", value: "fixed_term", label: t("fixed_term") },
								{ id: "open_ended", value: "open_ended", label: t("open_ended") },
								{ id: "unknown", value: "unknown", label: t("unknown") },
							]}
						/>
					</div>
				)}
				{dimonaAddOn && (
					<div className="section">
						<PootsySubHeading text={t("operating_headquarters")} />

						<PootsySelectInput
							name="operatingHeadquarter"
							defaultText={t("operating_headquarters")}
							selected={contract.operatingHeadquarter.id}
							options={operatingHeadquarters.map(hq => {
								let value = hq.id
								let label = `${hq.attributes.address.town} - ${hq.attributes.address.street_name} - ${hq.attributes.reference_number} `
								return { value: value, label: label }
							})}
							onChange={e => {
								e.stopPropagation()
								let hq = operatingHeadquarters.find(
									obj => obj.id === e.target.value
								)
								handleFormChanges({ operatingHeadquarter: hq })
							}}
						/>
					</div>
				)}
			</Fragment>
		)
	}
}

WorkerContractInputs.contextTypes = { t: PropTypes.func }
export default WorkerContractInputs
