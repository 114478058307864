import React from "react"
import { BookingFilter } from "./BookingFilter"
import { BookingSelect } from "./BookingSelect"
import { sortArrayByName } from "../helpers/arraySortByName"

export const BookingBackEndFilters = ({
	filters,
	filterInputChange,
	selectInputChange,
	updateFilters,
	context,
}) => {
	const allFilters = (
		<>
			{filters.map(entry => {
				if (entry.input === "filter" && entry.values.length > 0) {
					return (
						<BookingFilter
							key={entry.name}
							label={context(entry.name + "_label")}
							name={entry.name}
							currentValues={entry.value}
							possibleValues={sortArrayByName(entry.values, "label")}
							onChange={filterInputChange}
							updateFilters={updateFilters}
						/>
					)
				} else if (entry.input === "select") {
					return (
						<BookingSelect
							key={entry.name}
							name={entry.name}
							defaultText={context(entry.name + "_label")}
							allowEmpty={true}
							onChange={selectInputChange}
							selected={entry.value || ""}
							updateFilters={updateFilters}
							options={entry.values.map(v => ({
								label: context(v.label),
								value: v.value,
							}))}
						/>
					)
				}
				return null
			})}
		</>
	)

	return <div>{allFilters}</div>
}
