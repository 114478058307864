import React, { Component } from "react"
import PropTypes from "prop-types"

import moment from "moment"
import InputsChangeHandler from "./InputsChangeHandler"
import ModalLayout from "./ModalLayout"
import { PootsyButton, PootsyValueDisplayer, LabeledDateTime } from "./FunctionalDesign"
import { PootsySelectInput, PootsyTextInput } from "./FunctionalInputs"

export default class ChangeBookingStatusModal extends Component {
	constructor(props) {
		super(props)
		let selectedStartDate = props.booking.sdStartTime
			? moment(props.booking.sdStartTime)
			: moment().set({ minutes: 0, seconds: 0 })
		let selectedEndDate = props.booking.sdEndTime
			? moment(props.booking.sdEndTime)
			: moment().set({ minutes: 0, seconds: 0 })
		this.state = {
			possibleWhen: {
				assigned: [
					{ label: "start", value: "start" },
					{ label: "end", value: "end" },
					{ label: "impossible_to_deliver", value: "impossible_to_deliver" },
				],
				ongoing: [
					{ label: "end", value: "end" },
					{ label: "faces_problem", value: "faces_problem" },
					{ label: "impossible_to_deliver", value: "impossible_to_deliver" },
				],
				finished: [
					{ label: "start", value: "start" },
					{ label: "impossible_to_deliver", value: "impossible_to_deliver" },
				],
				problem: [
					{ label: "start", value: "start" },
					{ label: "impossible_to_deliver", value: "impossible_to_deliver" },
				],
				not_delivered: [
					{ label: "assign", value: "assign" },
					{ label: "start", value: "start" },
				],
				cancelled: [{ label: "assign", value: "assign" }],
				finished_without_confirmation: [
					{ label: "start", value: "start" },
					{ label: "impossible_to_deliver", value: "impossible_to_deliver" },
				],
			},
			event: "",
			selectedStartDate,
			selectedEndDate,
			voucherCount: "",
			payStatus: "",
			showRequired: { event: false, voucherCount: false, payStatus: false },
		}
		// ( to be able to pass it down to <InputsChangeHandler/> )
		this.setState = this.setState.bind(this)
	}
	handleDateTimeChange = changes => {
		this.setState(changes)
	}
	toggleRequiredColor = (el, makeItRed) => {
		this.setState(state => {
			let showRequired = { ...state.showRequired, [el]: makeItRed }
			return { ...state, showRequired: showRequired }
		})
	}
	submit = () => {
		let { onSubmit, booking } = this.props
		let { event, selectedStartDate, selectedEndDate, voucherCount, payStatus } = this.state

		let valid = true
		if (event === "") {
			this.toggleRequiredColor("event", true)
			valid = false
		} else if (event === "end" && booking.serviceDeliveryStatus !== "assigned") {
			if (voucherCount === "") {
				this.toggleRequiredColor("voucherCount", true)
				valid = false
			}
		}
		if (valid) {
			let data = {
				start_time: selectedStartDate.format(),
				end_time: selectedEndDate.format(),
				voucher_count: parseInt(voucherCount, 10),
				event: event,
			}
			if (payStatus !== "") {
				data.pay_status = payStatus
			}
			onSubmit(data)
		}
	}
	render() {
		let { t } = this.context
		if (Object.keys(this.props.booking).length < 1) {
			return null
		}

		let {
			possibleWhen,
			event,
			selectedStartDate,
			selectedEndDate,
			voucherCount,
			showRequired,
		} = this.state
		let { booking, onCancel } = this.props

		let notAssignable = event === "assign" && booking.cancelReason !== "explicit_cancel"
		let selectOptions = possibleWhen[booking.serviceDeliveryStatus].map(e => ({
			label: t(e.label),
			value: e.value,
		}))
		let buttons = [
			<PootsyButton key="a" text={t("confirm")} onClick={this.submit} />,
			<PootsyButton key="b" text={t("cancel")} theme="cancel" onClick={onCancel} />,
		]
		if (notAssignable) {
			buttons = []
		}
		return (
			<InputsChangeHandler setParentState={this.setState}>
				<ModalLayout
					title={t("change_booking_status")}
					buttons={buttons}
					closeModal={onCancel}
					formDivClass="change-booking-status-modal"
				>
					<PootsyValueDisplayer
						label={t("current_booking_status")}
						values={[t(booking.serviceDeliveryStatus)]}
					/>
					{selectOptions.length > 0 && (
						<PootsySelectInput
							name="event"
							defaultText={t("new_booking_status")}
							selected={event}
							showRequired={showRequired.event}
							options={selectOptions}
						/>
					)}
					<div className="change-booking-status-modal-bottom">
						{notAssignable && (
							<div className="warning-cant-assign-implicit-cancel">
								{booking.cancelReason === "cancelled_by_holiday"
									? t("warning_cant_assign_implicit_cancel_by_holiday")
									: t("warning_cant_assign_implicit_cancel_by_absence")}
							</div>
						)}
						{event === "start" && (
							<div className="ongoing-inputs">
								<LabeledDateTime
									label={t("change_booking_status_start_time_prompt")}
									timePickerProps={{
										value: selectedStartDate,
										name: "selectedStartDate",
										onChange: this.handleDateTimeChange,
									}}
									datePickerProps={{
										name: "selectedStartDate",
										value: selectedStartDate,
										onChange: this.handleDateTimeChange,
									}}
								/>
							</div>
						)}
						{event === "end" && (
							<div className="finished-inputs">
								{booking.serviceDeliveryStatus === "assigned" && (
									<LabeledDateTime
										label={t("change_booking_status_start_time_prompt")}
										timePickerProps={{
											value: selectedStartDate,
											name: "selectedStartDate",
											onChange: this.handleDateTimeChange,
										}}
										datePickerProps={{
											name: "selectedStartDate",
											value: selectedStartDate,
											onChange: this.handleDateTimeChange,
										}}
									/>
								)}
								<LabeledDateTime
									label={t("change_booking_status_end_time_prompt")}
									timePickerProps={{
										value: selectedEndDate,
										name: "selectedEndDate",
										onChange: this.handleDateTimeChange,
										filterHours: h => h >= selectedStartDate.hours(),
										filterMinutes: m =>
											selectedStartDate.hours() < selectedEndDate.hours()
												? m >= 0 && m <= 59
												: m > selectedStartDate.minute(),
									}}
									datePickerProps={{
										name: "selectedEndDate",
										value: selectedEndDate,
										onChange: this.handleDateTimeChange,
									}}
								/>
								<PootsyTextInput
									name="voucherCount"
									label={t("billable_hours")}
									type="number"
									min="1"
									max="9"
									value={voucherCount}
									showRequired={showRequired.voucherCount}
								/>
							</div>
						)}
					</div>
				</ModalLayout>
			</InputsChangeHandler>
		)
	}
}

ChangeBookingStatusModal.contextTypes = { t: PropTypes.func }
