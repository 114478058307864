import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import InputsChangeHandler from "./InputsChangeHandler"
import { PootsyCheckboxInput } from "./FunctionalInputs"
import { PootsySubHeading } from "./FunctionalDesign"
import * as actions from "../actions"

/**
 * Allow you to select option regarding the sodexo connection
 */
class SodexoConnectionSettings extends Component {
	handleFormChanges = changes => {
		this.props.dispatch(actions.editSettings(changes))
	}

	handleSodexoConnectionSettingsChange = changes => {
		let { sodexoConnectionSettings } = this.props.currAffiliate
		let newSodexoConnectionSettings = { ...sodexoConnectionSettings }
		let keyChanged = Object.keys(changes)[0]
		newSodexoConnectionSettings[keyChanged] = changes[keyChanged]
		this.handleFormChanges({ sodexoConnectionSettings: newSodexoConnectionSettings })
	}

	render() {
		let { t } = this.context
		let { currAffiliate } = this.props
		return (
			<div>
				<PootsySubHeading text={t("sodexo_conn_settings_title")} />
				<InputsChangeHandler
					customClass="inputs-card sodexo-connection-settings"
					onChange={this.handleSodexoConnectionSettingsChange}
				>
					<div className="settings-sodexo-page-wrapper">
						<p>{t("sodexo_conn_settings_auto_remi_ins_bal_desc")}</p>
						<PootsyCheckboxInput
							name="autoReminderInsufficientBalance"
							label={t("auto_reminder_insufficient_balance")}
							checked={
								currAffiliate.sodexoConnectionSettings
									.autoReminderInsufficientBalance
							}
						/>
					</div>
				</InputsChangeHandler>
			</div>
		)
	}
}

SodexoConnectionSettings.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currAffiliate: state.redData.currentAffiliate,
})
export default connect(mapStateToProps)(SodexoConnectionSettings)
