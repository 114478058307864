import { combineReducers } from "redux"
import moment from "moment"
import * as ActionTypes from "../actions"

import {
	normalizeAgendaData,
	normalizeEventAgendaData,
	normalizeAgendaRow,
	normalizeEventAgendaRow,
	normalizeFullMonthAgenda,
} from "../normalizers/AgendaNormalizers"
import {
	normalizeAffiliate,
	normalizeAllAreas,
	normalizeBookingLine,
	normalizeWorkshopOrderLine,
	normalizeSodexoBooking,
	normalizeSodexoPaperServiceVouchers,
	normalizeCurrentWorker,
	normalizeCurrentBooking,
	normalizeCurrentCustomer,
	normalizeWorkerContract,
	normalizeDocumentTemplate,
	normalizeWorkerWeek,
	normalizeSecSocTermMapping,
	normalizeTimesheetsData,
	normalizeImpactedBookings,
	normalizeAvailableLaundryOrderItem,
	normalizeDimonaLine,
	normalizeNotification,
	normalizeWorkshopOrder,
	normalizeWorkshop,
	normalizeAffiliateUser,
	normalizeTask,
	normalizeBookingWish,
	normalizeBookingWishes,
} from "../normalizers/Normalizers"
import { ALL_BADGES } from "../constants/agendaBadges"

const currentUserInitialState = {
	userName: "",
	accountID: "",
	email: "",
	accesses: [],
	teams: [],
	notificationsPreferences: [],
	isMaster: false,
	isAffiliate: false,
	dueTasksCount: 0,
	unreadNotificationsCount: 0,
	currentAffiliateUserId: 0,
}
const currentUser = (state = currentUserInitialState, action) => {
	switch (action.type) {
		case ActionTypes.CHECKTOKEN_SUCCESS:
		case ActionTypes.SUBSCRIBE_TO_TEAM_SUCCESS:
		case ActionTypes.AUTH_SUCCESS:
		case ActionTypes.CURRENT_USER_UPDATE_SUCCESS:
			return {
				...state,
				userName: action.data.attributes.display_name,
				accountID: action.data.id,
				email: action.data.attributes.email,
				accesses: action.data.attributes.accesses.map(access => ({
					role: access.role,
					affiliateID: access.affiliate.id,
				})),
				teams: action.data.attributes.accessible_squads.data,
				notificationsPreferences: action.data.attributes.account_notification_preferences,
				unreadNotificationsCount: action.data.attributes.unread_notifications,
			}
		case ActionTypes.POLL_SERVER_SUCCESS:
			return {
				...state,
				unreadNotificationsCount: action.unread_notifications,
				dueTasksCount: action.due_tasks,
			}
		case ActionTypes.MARK_NOTIFICATIONS_AS_READ_SUCCESS:
		case ActionTypes.FETCH_NOTIFICATIONS_SUCCESS:
			return {
				...state,
				unreadNotificationsCount: action.meta.unread_notifications,
			}
		case ActionTypes.SELECT_AFFILIATE:
			let currentRole = state.accesses.find(
				access => access.affiliateID === action.chosenAffiliate.id
			).role
			let currentAffiliateUserId = action.chosenAffiliate.users.find(
				user => user.account_id === Number(state.accountID)
			).id
			return {
				...state,
				currentAffiliateUserId,
				isMaster: currentRole === "affiliate_master_user",
				isWorkshopLimited: currentRole === "affiliate_workshop_slave",
				isAffiliate: ["affiliate_master_user", "affiliate_slave_user"].includes(
					currentRole
				),
			}
		case ActionTypes.LOGOUT:
			return currentUserInitialState
		default:
			return state
	}
}

const allAreas = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.ALLAREAS_SUCCESS:
			return normalizeAllAreas(action.data)
		default:
			return state
	}
}

const allWorkerLanguages = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.FETCHAVAILABLEWORKERLANGUAGES_SUCCESS:
			return action.data.map(e => ({ id: e.id, ref: e.attributes.reference }))
		default:
			return state
	}
}

const changeAffLang = (affiliate, lang) => {
	affiliate.default_language = lang
	return affiliate
}

const affiliates = (state = [], action) => {
	let defaultLanguage = "en"
	switch (action.type) {
		case ActionTypes.CHECKTOKEN_SUCCESS:
			defaultLanguage = action.data.attributes.default_language
		/* falls through */
		case ActionTypes.AUTH_SUCCESS:
			return action.data.attributes.accesses.map(access =>
				normalizeAffiliate(changeAffLang(access.affiliate, defaultLanguage))
			)
		case ActionTypes.LOGOUT:
			return []
		default:
			return state
	}
}

let currentAffiliateInitialData, currAffiliateData
const currentAffiliate = (state = {}, action) => {
	let newUsers, newEditedKeys
	switch (action.type) {
		case ActionTypes.SELECT_AFFILIATE:
			currAffiliateData = { ...action.chosenAffiliate }
			currentAffiliateInitialData = { ...currAffiliateData }
			return { ...currAffiliateData, editedKeys: [] }
		case ActionTypes.FETCH_AFFILIATE_SUCCESS:
		case ActionTypes.UPDATESETTINGS_SUCCESS:
		case ActionTypes.SUBMIT_EXPENSES_UPDATE_SUCCESS:
		case ActionTypes.SODEXO_MANUAL_SYNC_SUCCESS:
		case ActionTypes.SODEXO_CONNECTION_TOKEN_SUCCESS:
			let normalizedAffiliate = normalizeAffiliate({
				...action.data.attributes,
				id: action.data.id,
			})
			let secsocSpecificFieldData = state.secSocSpecificFields.reduce((acc, entry) => {
				acc[entry.reference] = state[entry.reference]
				return acc
			}, {})
			currentAffiliateInitialData = {
				...normalizedAffiliate,
				secSocSpecificFields: state.secSocSpecificFields,
				...secsocSpecificFieldData,
			}
			return { ...currentAffiliateInitialData, editedKeys: [] }
		case ActionTypes.FETCH_SECSOC_SPECIFIC_FIELDS_SUCCESS:
		case ActionTypes.SUBMIT_SECSOC_SPECIFIC_FIELDS_SUCCESS:
			currentAffiliateInitialData.secSocSpecificFields = []
			currentAffiliateInitialData = Object.keys(action).reduce((acc, key) => {
				if (action[key].reference) {
					acc.secSocSpecificFields.push(action[key])
					acc[action[key].reference] = action[key].value || ""
				}
				return acc
			}, currentAffiliateInitialData)
			return { ...currentAffiliateInitialData, editedKeys: [] }
		case ActionTypes.NEWAFFILIATEUSER_SUCCESS:
			newUsers = [...state.users, { ...action.data.attributes, id: action.data.id }]
			currentAffiliateInitialData = { ...currentAffiliateInitialData, users: newUsers }
			return { ...state, users: newUsers }
		case ActionTypes.UPDATE_AFFILIATE_USER_ROLE_SUCCESS:
			currentAffiliateInitialData = {
				...currentAffiliateInitialData,
				users: state.users.map(entry => {
					if (entry.id === action.data.id) {
						return normalizeAffiliateUser(action.data)
					}
					return entry
				}),
			}
			return { ...state, ...currentAffiliateInitialData }
		case ActionTypes.REVOKEAFFILIATEUSER_SUCCESS:
			newUsers = [...state.users.filter(e => e.account_id.toString() !== action.id)]
			currentAffiliateInitialData = { ...currentAffiliateInitialData, users: newUsers }
			return { ...state, users: newUsers }
		case ActionTypes.EDIT_SETTINGS:
			let key = Object.keys(action.changes)[0]
			newEditedKeys = [...state.editedKeys]
			let a = JSON.stringify(action.changes[key])
			let b = JSON.stringify(currentAffiliateInitialData[key])
			if (a !== b) {
				newEditedKeys.push(key)
			} else {
				newEditedKeys = state.editedKeys.filter(e => e !== key)
			}
			return { ...state, editedKeys: newEditedKeys, ...action.changes }
		case ActionTypes.SUBMIT_NEW_WORKSHOP_SUCCESS:
			return { ...state, workshops: [...state.workshops, normalizeWorkshop(action.data)] }
		case ActionTypes.CREATEWORKER_SUCCESS:
			return {
				...state,
				allAffiliateWorkers: [
					...state.allAffiliateWorkers,
					{
						id: action.data.id,
						attributes: {
							display_name: `${action.data.attributes.first_name} ${action.data.attributes.last_name}`,
						},
					},
				],
			}
		case ActionTypes.UPDATE_WORKSHOP_SUCCESS:
		case ActionTypes.UPDATE_WORKSHOP_ADDRESS_SUCCESS:
			return {
				...state,
				workshops: state.workshops.map(entry => {
					if (entry.id === action.data.id) {
						return normalizeWorkshop(action.data)
					}
					return entry
				}),
			}
		case ActionTypes.RESET_SETTINGS:
			return { ...currentAffiliateInitialData, editedKeys: [] }
		case ActionTypes.LOGOUT:
			return {}
		default:
			return state
	}
}

let currentAffiliateLocalisedInputsInit = {
	isEdited: false,
	slogans: [
		{ lang: "fr", value: "" },
		{ lang: "nl", value: "" },
		{ lang: "en", value: "" },
	],
	bios: [
		{ lang: "fr", value: "" },
		{ lang: "nl", value: "" },
		{ lang: "en", value: "" },
	],
}
const currentAffiliateLocalisedFields = (state = currentAffiliateLocalisedInputsInit, action) => {
	switch (action.type) {
		case ActionTypes.CHECKTOKEN_SUCCESS:
		case ActionTypes.AUTH_SUCCESS:
			let { localized_slogans, localized_bios } = action.data.attributes.accesses[0].affiliate
			currentAffiliateLocalisedInputsInit = {
				slogans: currentAffiliateLocalisedInputsInit.slogans.map(slogan => {
					let existingSlogan = localized_slogans.find(
						api_slogan => slogan.lang === api_slogan.lang
					)
					return existingSlogan ? existingSlogan : slogan
				}),
				bios: currentAffiliateLocalisedInputsInit.bios.map(bio => {
					let existingBio = localized_bios.find(api_bio => bio.lang === api_bio.lang)
					return existingBio ? existingBio : bio
				}),
			}
			return { ...currentAffiliateLocalisedInputsInit, isEdited: false }
		case ActionTypes.UPDATESETTINGS_SUCCESS:
			let { localized_slogans: l_s, localized_bios: l_b } = action.data.attributes
			currentAffiliateLocalisedInputsInit = {
				slogans: currentAffiliateLocalisedInputsInit.slogans.map(slogan => {
					let existingSlogan = l_s.find(api_slogan => slogan.lang === api_slogan.lang)
					return existingSlogan ? existingSlogan : slogan
				}),
				bios: currentAffiliateLocalisedInputsInit.bios.map(bio => {
					let existingBio = l_b.find(api_bio => bio.lang === api_bio.lang)
					return existingBio ? existingBio : bio
				}),
			}
			return { ...currentAffiliateLocalisedInputsInit, isEdited: false }
		case ActionTypes.AFFILIATE_LOCALISED_FIELDS_DATA_CHANGE:
			return { ...state, ...action.changes, isEdited: true }
		case ActionTypes.RESET_SETTINGS:
			return { ...currentAffiliateLocalisedInputsInit, isEdited: false }
		default:
			return state
	}
}

const dashboardInitialState = {}
const dashboard = (state = dashboardInitialState, action) => {
	switch (action.type) {
		case ActionTypes.DASHBOARD_CUSTOMERS_SUCCESS:
			return { ...state, customers: action.data }
		case ActionTypes.DASHBOARD_SODEXO_SUCCESS:
			return { ...state, sodexo: action.data }
		case ActionTypes.DASHBOARD_QUALITY_SUCCESS:
			return { ...state, quality: action.data }
		case ActionTypes.DASHBOARD_COMPANY_KPI_SUCCESS:
			return { ...state, companyKPI: action.data }
		case ActionTypes.LOGOUT:
			return dashboardInitialState
		default:
			return state
	}
}

let currentSchedules, newSchedules
const agendaInit = {
	weekStart: moment().startOf("isoweek"),
	schedules: [],
	allBadges: ALL_BADGES.map(e => ({ label: e, value: e })),
	currentAbsence: {
		activityCode: "",
		activityCategory: "",
		absenceStart: "",
		absenceType: "",
		absenceEnd: "",
		absenceNote: "",
		editedAbsenceID: "",
		formC32ANumber: "",
		mustCallOnss: true,
	},
}
const agenda = (state = agendaInit, action) => {
	let seen, monthStart, firstMonday
	switch (action.type) {
		case ActionTypes.AGENDA_ABSENCE_CHANGE:
			return { ...state, currentAbsence: { ...state.currentAbsence, ...action.changes } }
		case ActionTypes.EVENT_AGENDA_REQUEST:
		case ActionTypes.AGENDA_WORKER_FULL_MONTH_REQUEST:
		case ActionTypes.SEARCH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT_REQUEST:
		case ActionTypes.SEARCH_FULL_MONTH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT_REQUEST:
			return { ...state, schedules: [] }
		case ActionTypes.EVENT_AGENDA_SUCCESS:
			currentSchedules = normalizeEventAgendaData(action)
			return {
				...state,
				weekStart: moment(action.start_time),
				schedules: [...currentSchedules],
			}
		case ActionTypes.SEARCH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT_SUCCESS:
			currentSchedules = normalizeEventAgendaData(action.agendas)
			return {
				...state,
				weekStart: moment(action.agendas.start_time),
				schedules: [...currentSchedules],
			}
		case ActionTypes.SEARCH_FULL_MONTH_TIMESLOTS_FOR_CURRENT_HIGHGLIGHT_SUCCESS:
			monthStart = moment(action.agendas.start_time).startOf("month")
			firstMonday = moment(monthStart).startOf("isoweek")
			if (firstMonday.isBefore(monthStart)) {
				firstMonday.add(7, "days")
			}
			return {
				...state,
				weekStart: firstMonday,
				schedules: normalizeFullMonthAgenda(action.agendas),
			}
		case ActionTypes.AGENDA_WORKER_FULL_MONTH_SUCCESS:
			let workerWeeks = normalizeFullMonthAgenda(action)
			monthStart = moment(action.start_time).startOf("month")
			firstMonday = moment(monthStart).startOf("isoweek")
			if (firstMonday.isBefore(monthStart)) {
				firstMonday.add(7, "days")
			}
			return {
				...state,
				weekStart: firstMonday,
				schedules: workerWeeks,
			}
		case ActionTypes.AGENDA_SUCCESS:
			currentSchedules = normalizeAgendaData(action)
			return {
				...state,
				weekStart: moment(action.start_time),
				schedules: [...currentSchedules],
			}
		case ActionTypes.AGENDA_ROWS_SUCCESS:
			newSchedules = state.schedules.map(sched => {
				let fetchedRow = action.weekly_agenda.find(
					entry => entry.worker.contract_id === sched.cleaner.id
				)
				if (fetchedRow) {
					return normalizeAgendaRow(action.start_time, fetchedRow)
				}
				return sched
			})
			return { ...state, schedules: newSchedules }
		case ActionTypes.ADD_AGENDA_ROWS_SUCCESS:
			return { ...state, schedules: state.schedules.concat(normalizeAgendaData(action)) }
		case ActionTypes.ADD_PREVIOUS_EVENT_AGENDA_ROWS_SUCCESS:
			seen = []
			return {
				...state,
				schedules: normalizeEventAgendaData(action)
					.concat(state.schedules)
					.filter(entry => {
						if (seen.includes(entry.cleaner.id)) {
							return false
						}
						seen.push(entry.cleaner.id)
						return true
					}),
			}
		case ActionTypes.ADD_NEXT_EVENT_AGENDA_ROWS_SUCCESS:
			seen = []
			return {
				...state,
				schedules: state.schedules
					.concat(normalizeEventAgendaData(action))
					.filter(entry => {
						if (seen.includes(entry.cleaner.id)) {
							return false
						}
						seen.push(entry.cleaner.id)
						return true
					}),
			}
		case ActionTypes.REPLACE_EVENT_AGENDA_ROWS_SUCCESS:
			newSchedules = state.schedules.map(sched => {
				let fetchedRow = action.events.find(entry => entry.worker.id === sched.cleaner.id)
				if (fetchedRow) {
					return normalizeEventAgendaRow(action.start_time, fetchedRow)
				}
				return sched
			})
			return { ...state, schedules: newSchedules }
		case ActionTypes.SUBMIT_LOCK_WEEK_SUCCESS:
			return {
				...state,
				schedules: state.schedules.map(s => {
					if (s.cleaner.affiliateWorkerId === action.affiliate_worker_id) {
						return {
							...s,
							weekData: {
								...s.weekData,
								closed_week: { id: action.id, closed: !action.reopened },
							},
						}
					}
					return s
				}),
			}
		case ActionTypes.REPLACE_AGENDA_SCHEDULES:
			return { ...state, schedules: action.schedules }
		case ActionTypes.LOGOUT:
		case ActionTypes.RESET_AGENDA:
		case ActionTypes.RESET_AGENDA_DATA:
			return agendaInit
		default:
			return state
	}
}

let uneditedScheduleData = []
const timesheetsData = (state = [], action) => {
	let scheduleIndex, sched, newDayEvents, newMonthEvents, newSched
	switch (action.type) {
		case ActionTypes.FETCHSCHEDULES_SUCCESS:
		case ActionTypes.CLOSE_TIMESHEET_MONTH_SUCCESS:
		case ActionTypes.REOPEN_TIMESHEET_WEEK_SUCCESS:
		case ActionTypes.REOPEN_TIMESHEET_MONTH_SUCCESS:
			uneditedScheduleData = normalizeTimesheetsData(action.data.merged_timesheets)
			return [...uneditedScheduleData]
		case ActionTypes.CONFIRM_REFRESH_TIMESHEET_SUCCESS:
			uneditedScheduleData = normalizeTimesheetsData(action.data)
			return [...uneditedScheduleData]
		case ActionTypes.SUBMITTIMESHEETUPDATE_SUCCESS:
			return state.map(entry => {
				if (entry.timesheetInfo.timesheetID === action.timesheet_id) {
					return normalizeTimesheetsData([action])[0]
				}
				return entry
			})
		case ActionTypes.SOFTDELETE_TIMESHEET_EVENT:
		case ActionTypes.TIMESHEETS_DATACHANGE:
			return state.map((entry, index) => {
				if (index === action.scheduleIndex) {
					return {
						...entry,
						eventsForMonth: {
							...entry.eventsForMonth,
							[action.day]: entry.eventsForMonth[action.day].map((event, index) => {
								if (index === action.eventIndex) {
									return action.newEvent
								}
								return event
							}),
						},
					}
				}
				return entry
			})
		case ActionTypes.TIMESHEETS_COSTLINE_CHANGE:
			return state.map(entry => {
				if (entry.timesheetInfo.timesheetID === action.scheduleID) {
					return {
						...entry,
						costsInfos: {
							...entry.costsInfos,
							costsLines: entry.costsInfos.costsLines.map(costLine => {
								if (
									costLine.type === action.costLineType &&
									costLine.id === action.costLineID
								) {
									return action.newCostLine
								}
								return costLine
							}),
						},
					}
				}
				return entry
			})
		case ActionTypes.ADD_TIMESHEET_EVENT:
			sched = state.find((sched, index) => {
				scheduleIndex = index
				return sched.worker.id === action.workerId
			})
			newDayEvents = [...sched.eventsForMonth[action.day], action.event]
			newMonthEvents = { ...sched.eventsForMonth, [action.day]: newDayEvents }
			newSched = { ...sched, eventsForMonth: newMonthEvents }
			return [...state.filter((e, i) => scheduleIndex !== i), newSched]
		case ActionTypes.TIMESHEET_INFO_UPDATED:
			return action.updatedSchedules
		case ActionTypes.SUBMIT_TIMESHEET_COMMENTS_SUCCESS:
			return state.map(entry => {
				if (entry.timesheetInfo.timesheetID === action.id) {
					return {
						...entry,
						timesheetInfo: {
							...entry.timesheetInfo,
							workerComment: action.worker_comment,
							secSocComment: action.social_secretary_comment,
						},
					}
				}
				return entry
			})
		case ActionTypes.FETCH_EXPENSES_DATA_SUCCESS:
		case ActionTypes.SUBMIT_EXPENSE_SUCCESS:
		case ActionTypes.REMOVE_EXPENSE_SUCCESS:
		case ActionTypes.SUBMIT_TIMESHEET_MANUAL_ENTRY_SUCCESS:
		case ActionTypes.REMOVE_TIMESHEET_MANUAL_ENTRY_SUCCESS:
		case ActionTypes.UPDATE_EXPENSE_EXTRA_FIELD_SUCCESS:
			return state.map(sched => {
				if (sched.timesheetInfo.timesheetID === parseInt(action.data.id, 10)) {
					let {
						all_expenses,
						meal_vouchers,
						travel_expenses,
						clothes_expenses,
					} = action.data.attributes
					return {
						...sched,
						costsInfos: {
							costsLines: all_expenses,
							mealVouchers: meal_vouchers,
							commuteExpenses: travel_expenses,
							clothesExpenses: clothes_expenses,
						},
						// timesheetCommunication: {
						// 	salarySlipMessage: "",
						// 	payrollConsultantMessage: "",
						// }
					}
				} else {
					return sched
				}
			})
		case ActionTypes.TIMESHEET_COMMENTS_CHANGE:
			return state.map(entry => {
				if (entry.timesheetInfo.timesheetID === action.timesheetID) {
					return {
						...entry,
						timesheetInfo: {
							...entry.timesheetInfo,
							...action.changes,
						},
					}
				}
				return entry
			})
		case ActionTypes.LOGOUT:
		case ActionTypes.RESET_TIMESHEETS_COMPONENT:
			return []
		default:
			return state
	}
}

const secSocPdf = (state = { url: "" }, action) => {
	switch (action.type) {
		case ActionTypes.SCHEDULE_PDF_SUCCESS:
			return { url: action.pdfUrl }
		case ActionTypes.LOGOUT:
			return { url: "" }
		default:
			return state
	}
}

const pootsyEvents = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.FETCH_SEC_SOC_MAPPINGS_SUCCESS:
			return action.meta.pootsy_events
		default:
			return state
	}
}

const pootsyExpenses = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.FETCH_SEC_SOC_MAPPINGS_SUCCESS:
			return action.meta.pootsy_expenses
		default:
			return state
	}
}

const bookings = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.FETCHBOOKINGS_SUCCESS:
			return action.data.map(normalizeBookingLine)
		case ActionTypes.LOGOUT:
		case ActionTypes.FETCHBOOKINGS_REQUEST:
			return []
		default:
			return state
	}
}

const bookingFilters = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.BOOKINGFILTERS_SUCCESS:
			return action.data
		case ActionTypes.LOGOUT:
			return []
		default:
			return state
	}
}

const workshopOrders = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.FETCH_WORKSHOP_ORDERS_REQUEST:
			return []
		case ActionTypes.FETCH_WORKSHOP_ORDERS_SUCCESS:
			return action.data.map(normalizeWorkshopOrderLine)
		case ActionTypes.EDIT_WORKSHOP_ORDER_LIST:
			return action.newList
		case ActionTypes.LOGOUT:
			return []
		default:
			return state
	}
}

const workshopOrdersFilters = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.WORKSHOP_ORDERS_FILTERS_SUCCESS:
			return action.data
		case ActionTypes.LOGOUT:
			return []
		default:
			return state
	}
}

const dimonas = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.FETCH_DIMONAS_SUCCESS:
			return action.data.map(normalizeDimonaLine)
		case ActionTypes.LOGOUT:
			return []
		default:
			return state
	}
}

const dimonasFilters = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.DIMONAS_FILTERS_SUCCESS:
			return Object.values(action.data)
		case ActionTypes.LOGOUT:
			return []
		default:
			return state
	}
}

const sodexoBookings = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.FETCH_SODEXO_BOOKINGS_SUCCESS:
			return action.data.map(normalizeSodexoBooking)
		case ActionTypes.LOGOUT:
		case ActionTypes.FETCH_SODEXO_BOOKINGS_REQUEST:
			return []
		default:
			return state
	}
}

const sodexoPaperServiceVouchers = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.FETCH_SODEXO_PAPER_SERVICE_VOUCHERS_SUCCESS:
			return action.data.map(normalizeSodexoPaperServiceVouchers)
		case ActionTypes.LOGOUT:
		case ActionTypes.FETCH_SODEXO_PAPER_SERVICE_VOUCHERS_REQUEST:
			return []
		default:
			return state
	}
}

let currentBookingInitialData = {}
const currentBooking = (state = { editedKeys: [] }, action) => {
	switch (action.type) {
		case ActionTypes.FETCHBOOKING_SUCCESS:
		case ActionTypes.NEWBOOKING_SUCCESS:
		case ActionTypes.CHANGEBOOKINGSTATUS_SUCCESS:
		case ActionTypes.UPDATE_BOOKING_SODEXO_STATUS_SUCCESS:
		case ActionTypes.SEND_PSV_REMINDER_SUCCESS:
		case ActionTypes.SEND_ESV_REMINDER_SUCCESS:
			currentBookingInitialData = normalizeCurrentBooking(action.data)
			return { ...state, ...currentBookingInitialData }
		case ActionTypes.EDIT_CURRENTBOOKING:
			let key = Object.keys(action.data)[0]
			let newEditedKeys = [...state.editedKeys]
			let a = JSON.stringify(action.data[key])
			let b = JSON.stringify(currentBookingInitialData[key])
			if (a !== b) {
				newEditedKeys.push(key)
			} else {
				newEditedKeys = state.editedKeys.filter(e => e !== key)
			}
			return { ...state, editedKeys: newEditedKeys, ...action.data }
		case ActionTypes.SUBMITBOOKINGMESSAGE_SUCCESS:
			return { ...state, activities: [action.activity, ...state.activities] }
		case ActionTypes.SUBMIT_PSV_SUCCESS:
			return {
				...state,
				pServiceVouchersAffiliate: [
					...state.pServiceVouchersAffiliate,
					{ code: action.code, id: action.id },
				],
			}
		case ActionTypes.REMOVE_PSV_SUCCESS:
			return {
				...state,
				pServiceVouchers: state.pServiceVouchers.filter(
					psv => psv.id !== action.deleted_id
				),
				pServiceVouchersAffiliate: state.pServiceVouchersAffiliate.filter(
					psv => psv.id !== action.deleted_id
				),
			}
		case ActionTypes.FETCHBOOKING_REQUEST:
		case ActionTypes.LOGOUT:
			return { editedKeys: [] }
		default:
			return state
	}
}

const checkPSVCodeInit = {
	currentPSV: "",
	lastPSVChecked: "",
	linkedBookingID: "",
	skipCode: false,
}
const checkPSVCode = (state = checkPSVCodeInit, action) => {
	switch (action.type) {
		case ActionTypes.PSV_INPUT_CHANGE:
			return { ...state, currentPSV: action.psv }
		case ActionTypes.PSV_TOGGLE_SKIP_CODE:
			return { ...state, skipCode: !state.skipCode }
		case ActionTypes.PSV_RELATED_BOOKINGS_REQUEST:
			return { ...state, lastPSVChecked: state.currentPSV }
		case ActionTypes.PSV_RELATED_BOOKINGS_SUCCESS:
			let linkedBookingID = action.linked_booking ? action.linked_booking.data.id : null
			return { ...state, linkedBookingID }
		case ActionTypes.BOOKINGDETAILS_UICHANGE:
			if (action.changes.showAddSVModal === false) {
				return checkPSVCodeInit
			}
			return state
		case ActionTypes.LOGOUT:
			return checkPSVCodeInit
		default:
			return state
	}
}

const newBookingEmptyState = {
	showRequired: {},
	duration: "",
	language: "",
	specificWorker: { name: "", sodexo: "" },
	residenceIndex: "0",
	selectedDate: "",
	selectedWorker: {},
	chosenCustomer: {},
	startTime: "",
	recurrence: "",
	reach: "",
}
const currentNewBookingFormData = (state = newBookingEmptyState, action) => {
	switch (action.type) {
		case ActionTypes.NEWBOOKINGPAGE_PREFILL:
			return { ...newBookingEmptyState, ...action.changes }
		case ActionTypes.FETCHEDITEDBOOKING_SUCCESS:
			let start = moment(action.data.attributes.start_time)
			let end = moment(action.data.attributes.end_time)
			let duration = end.diff(start, "hour")
			return {
				...state,
				duration: duration,
				startTime: action.data.attributes.formated_start_time,
				recurrence: action.data.attributes.recurrence_delay.toString(),
				selectedWorker: {
					name: action.data.attributes.worker.display_name,
					contractId: action.data.attributes.worker.contract_id,
				},
				selectedEndDate: moment(action.data.attributes.end_date, "YYYY-MM-DD"),
				selectedDate: moment(action.data.attributes.delivery_date, "YYYY-MM-DD"),
				specificWorker: {
					name: action.data.attributes.worker.display_name,
					sodexo: action.data.attributes.worker.sodexo,
				},
				chosenCustomer: {
					id: action.data.attributes.customer.customer_contract_id,
					name: action.data.attributes.customer.display_name,
					sodexo: action.data.attributes.customer.sodexo_reference,
					residences: action.data.attributes.customer.residences.data,
				},
			}
		case ActionTypes.NEWBOOKINGMODAL_DATACHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.CHOOSE_CUSTOMER:
			return {
				...state,
				chosenCustomer: {
					id: action.customer.id,
					name: action.customer.attributes.display_name,
					sodexo: action.customer.attributes.sodexo_reference,
					residences: action.customer.attributes.residences.data,
				},
			}
		case ActionTypes.CREATECUSTOMERFROMNEWBOOKING_SUCCESS:
			return {
				...state,
				chosenCustomer: {
					id: action.data.id,
					name: action.data.attributes.display_name,
					sodexo: action.data.attributes.sodexo_reference,
					residences: action.data.attributes.residences.data,
				},
			}
		case ActionTypes.NEWBOOKING_SUBMITNEWRESIDENCE_SUCCESS:
			let newChosenCustomer = { ...state.chosenCustomer }
			newChosenCustomer.residences = [...state.chosenCustomer.residences, action.data]
			return { ...state, chosenCustomer: newChosenCustomer }
		case ActionTypes.LOGOUT:
		case ActionTypes.RESET_NEWBOOKING_FORM:
			return newBookingEmptyState
		default:
			return state
	}
}

const currentEditedBooking = (state = {}, action) => {
	switch (action.type) {
		case ActionTypes.FETCHEDITEDBOOKING_SUCCESS:
			return { ...action.data.attributes, id: action.data.id }
		case ActionTypes.FETCHBOOKING_REQUEST:
		case ActionTypes.LOGOUT:
			return {}
		default:
			return state
	}
}

const currentDirectBookingForm = (state = { populated: false }, action) => {
	switch (action.type) {
		case ActionTypes.EDIT_DIRECTBOOKING:
			return { ...state, ...action.data, populated: true }
		case ActionTypes.RESET_DIRECTBOOKING:
			return { populated: false }
		case ActionTypes.NEWDIRECTBOOKING_SUBMITNEWRESIDENCE_SUCCESS:
			let newChosenCustomer = { ...state.chosenCustomer }
			newChosenCustomer.attributes.residences.data = [
				...state.chosenCustomer.attributes.residences.data,
				action.data,
			]
			return { ...state, chosenCustomer: newChosenCustomer }
		case ActionTypes.CREATECUSTOMERFROMNEWDIRECTBOOKING_SUCCESS:
			let newState = { ...state, chosenCustomer: action.data, residenceIndex: "0" }
			if (action.data.attributes.voucher_type_preference) {
				newState.voucherType = action.data.attributes.voucher_type_preference
			}
			return newState
		case ActionTypes.SUBMITDIRECTBOOKINGFROMAGENDA_SUCCESS:
		case ActionTypes.LOGOUT:
			return { populated: false }
		default:
			return state
	}
}

const cleaners = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.FETCHWORKERS_SUCCESS:
			return [...action.data]
		case ActionTypes.FETCHWORKERS_REQUEST:
		case ActionTypes.LOGOUT:
			return []
		default:
			return state
	}
}

const newWorkerEmptyState = {
	showRequired: {
		cleanerTitle: false,
		firstName: true,
		lastName: true,
		nissNumber: true,
		mainLanguage: true,
	},
	// first step data
	cleanerTitle: "",
	mainLanguage: "",
	sodexoNumber: "",
	nissNumber: "",
	streetNumber: "",
	zip: "",
	street: "",
	box: "",
	city: "",
	email: "",
	firstName: "",
	lastName: "",
	mobilePhone: "",
	secSocIdentifier: "",
	sodexoPin: "",
	contractStartDate: moment(),
	contractEndDate: "",
	coveredAreas: [],
	squadId: "",
	needsUpfrontPayment: false,
}
const currentNewWorkerFormData = (state = newWorkerEmptyState, action) => {
	switch (action.type) {
		case ActionTypes.SELECT_AFFILIATE:
			let newRequired = { ...state.showRequired }
			let isRequired =
				action.chosenAffiliate.secSocName === "Group S" ||
				action.chosenAffiliate.secSocName === "Securex"
			newRequired.cleanerTitle = isRequired
			return { ...state, showRequired: newRequired }
		case ActionTypes.NEW_WORKER_DATACHANGE:
			return { ...state, ...action.changes }
		case ActionTypes.NEW_WORKER_REQUIREFIELD:
			let newShowRequired = { ...state.showRequired }
			newShowRequired[action.field] = action.makeItRed
			return { ...state, showRequired: newShowRequired }
		// debug only action
		case ActionTypes.NEWWORKERFAKESTATE:
			return { ...state, ...action.formData }
		case ActionTypes.LOGOUT:
		case ActionTypes.CREATEWORKER_SUCCESS:
			return newWorkerEmptyState
		default:
			return state
	}
}

let cleanerInitialData = {}
const currentWorker = (state = { editedKeys: [] }, action) => {
	let cleanerData
	let newEditedKeys
	switch (action.type) {
		case ActionTypes.FETCHWORKER_SUCCESS:
			cleanerData = normalizeCurrentWorker(action.data)
			cleanerInitialData = { ...cleanerData }
			return { ...state, ...cleanerData }
		case ActionTypes.UPDATEWORKER_SUCCESS:
		case ActionTypes.CREATEWORKER_SUCCESS:
		case ActionTypes.WORKERCONTRACTEND_SUCCESS:
		case ActionTypes.REMOVEWORKERCONTRACTEND_SUCCESS:
		case ActionTypes.FORGET_WORKER_CONTRACT_SUCCESS:
			cleanerData = normalizeCurrentWorker(action.data)
			cleanerInitialData = { ...cleanerData }
			return { ...state, editedKeys: [], ...cleanerData }
		case ActionTypes.FETCH_REGULAR_WORKER_SCHEDULES_SUCCESS:
			return {
				...state,
				regularSchedules: action.data.map(rSchedule => ({
					info: rSchedule.info,
					bookings: rSchedule.bookings.map(booking => ({
						id: booking.id,
						customer: booking.customer,
						city: booking.town,
						zip: booking.zip_code,
						dayPeriod: booking.day_period,
						voucherType: booking.voucher_type,
						startTime: moment(booking.start_time),
						endTime: moment(booking.end_time),
						startDate: moment(booking.start_date),
						endDate: booking.end_date ? moment(booking.end_date) : null,
					})),
				})),
			}
		case ActionTypes.EDIT_WORKER_CONTRACT:
			let key = Object.keys(action.data)[0]
			newEditedKeys = [...state.editedKeys]
			let a = JSON.stringify(action.data[key])
			let b = JSON.stringify(cleanerInitialData[key])
			if (a !== b) {
				newEditedKeys.push(key)
			} else {
				newEditedKeys = state.editedKeys.filter(e => e !== key)
			}
			return { ...state, editedKeys: newEditedKeys, ...action.data }
		case ActionTypes.FETCH_WORKER_INCOMPATIBILITIES_SUCCESS:
			return { ...state, incompatibilities: action.data }
		case ActionTypes.SUBMIT_WORKER_INCOMPATIBILITY_SUCCESS:
			return { ...state, incompatibilities: [...state.incompatibilities, action.data] }
		case ActionTypes.SUBMIT_REMOVE_WORKER_INCOMPATIBILITY_SUCCESS:
			return {
				...state,
				incompatibilities: state.incompatibilities.filter(
					lc => lc.id.toString() !== action.requestMeta.toRemoveID
				),
			}
		case ActionTypes.RESET_WORKER_DATA:
		case ActionTypes.FETCHWORKER_REQUEST:
		case ActionTypes.LOGOUT:
			return { ...cleanerInitialData, editedKeys: [] }
		default:
			return state
	}
}

const workerContractsOriginalData = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.FETCHWORKER_SUCCESS:
			return action.data.attributes.worker_contracts.data.map(normalizeWorkerContract)
		case ActionTypes.SUBMIT_UPDATE_WORKER_CONTRACT_SUCCESS:
		case ActionTypes.SUBMIT_NEW_WORKER_CONTRACT_SUCCESS:
		case ActionTypes.SUBMIT_FORCE_UPDATE_WORKER_CONTRACT_SUCCESS:
			return action.data.map(normalizeWorkerContract)
		case ActionTypes.FETCHWORKER_REQUEST:
		case ActionTypes.LOGOUT:
			return []
		default:
			return state
	}
}

const workerContracts = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.FETCHWORKER_SUCCESS:
			return action.data.attributes.worker_contracts.data.map(normalizeWorkerContract)
		case ActionTypes.SUBMIT_NEW_WORKER_CONTRACT_SUCCESS:
			return action.data.map(normalizeWorkerContract)
		case ActionTypes.SUBMIT_UPDATE_WORKER_CONTRACT_SUCCESS:
		case ActionTypes.SUBMIT_FORCE_UPDATE_WORKER_CONTRACT_SUCCESS:
		case ActionTypes.EDIT_WORKER_CONTRACT_V2:
			return state.map(entry => {
				if (entry.id === action.contractID) {
					return { ...entry, ...action.changes }
				}
				return entry
			})
		case ActionTypes.REMOVE_NEW_CONTRACT:
			return state.filter(entry => entry.id !== "new-contract")
		case ActionTypes.ADD_NEW_CONTRACT:
			return [...state, action.emptyContract]
		case ActionTypes.SAVE_WORKER_DOCUMENT_SUCCESS:
			return state.map(entry => {
				if (entry.id === action.requestMeta.contractID) {
					let newDocuments = [
						...entry.documents,
						{ id: action.data.id, ...action.data.attributes },
					]
					return {
						...entry,
						documents: newDocuments,
					}
				}
				return entry
			})
		case ActionTypes.DELETE_WORKER_DOCUMENT_SUCCESS:
			return state.map(entry => {
				if (entry.id === action.requestMeta.contractID) {
					return {
						...entry,
						documents: entry.documents.filter(
							entry => entry.id !== action.requestMeta.deletedID
						),
					}
				}
				return entry
			})
		case ActionTypes.FETCHWORKER_REQUEST:
		case ActionTypes.LOGOUT:
			return []
		default:
			return state
	}
}

const currentWorkerAbsences = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.FETCHWORKERABSENCES_SUCCESS:
			return [...action.data]
		case ActionTypes.CREATEABSENCE_SUCCESS:
			return [...state, action.data]
		case ActionTypes.UPDATEABSENCE_SUCCESS:
			// TODO fix this:
			return state.map(entry => {
				if (entry.id === action.data.id) {
					return action.data
				}
				return entry
			})
		case ActionTypes.APPROVEABSENCE_SUCCESS:
		case ActionTypes.REFUSEABSENCE_SUCCESS:
			return state.map(entry => {
				if (entry.id === action.data.id) {
					return action.data
				}
				return entry
			})
		case ActionTypes.CANCELABSENCE_SUCCESS:
			return state.filter(e => parseInt(e.id, 10) !== action.canceled_absence_id)
		case ActionTypes.LOGOUT:
			return []
		default:
			return state
	}
}

const currentWorkerBookings = (state = { bookings: [], meta: {} }, action) => {
	switch (action.type) {
		case ActionTypes.FETCHWORKERBOOKINGS_SUCCESS:
			return { bookings: action.data.map(normalizeBookingLine), meta: { ...action.meta } }
		case ActionTypes.LOGOUT:
			return { bookings: [], meta: {} }
		default:
			return state
	}
}

const workerSuggestions = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.WORKERSUGGESTIONS_SUCCESS:
			return [...action.data]
		case ActionTypes.SET_WORKER_SUGGESTIONS:
			return action.suggestions
		case ActionTypes.LOGOUT:
		case ActionTypes.WORKER_SUGGESTIONS_RESET:
			return []
		default:
			return state
	}
}

const customers = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.FETCHCUSTOMERS_SUCCESS:
			return [...action.data]
		case ActionTypes.FETCHCUSTOMERS_REQUEST:
		case ActionTypes.LOGOUT:
			return []
		default:
			return state
	}
}

const currentCustomerForm = (state = { validSodexo: "" }, action) => {
	switch (action.type) {
		case ActionTypes.SODEXO_WAITING_FOR_VALIDATION:
			return { validSodexo: "", sodexoWaitingForValidation: action.number }
		case ActionTypes.CHECKSODEXO_SUCCESS:
			let newState = { ...state }
			let sodexoNr = newState.sodexoWaitingForValidation
			delete newState.sodexoWaitingForValidation
			newState.validSodexo = sodexoNr
			return newState
		case ActionTypes.CHECKSODEXO_FAILURE:
		case ActionTypes.SUBMITNEWCUSTOMER_REQUEST:
		case ActionTypes.LOGOUT:
		case ActionTypes.RESET_VALID_SODEXO:
			return { validSodexo: "" }
		default:
			return state
	}
}
let customerInitialData = {}
const currentCustomer = (state = { editedKeys: [] }, action) => {
	let newEditedKeys
	switch (action.type) {
		case ActionTypes.SUBMITNEWCUSTOMER_SUCCESS:
		case ActionTypes.FETCHCUSTOMER_SUCCESS:
		case ActionTypes.FORGET_CUSTOMER_CONTRACT_SUCCESS:
		case ActionTypes.SET_CUSTOMER_CONTRACT_INACTIVE_SUCCESS:
		case ActionTypes.REACTIVATE_CUSTOMER_CONTRACT_SUCCESS:
			customerInitialData = normalizeCurrentCustomer(action.data)
			return { editedKeys: [], ...customerInitialData }
		case ActionTypes.UPDATECUSTOMER_SUCCESS:
			customerInitialData = normalizeCurrentCustomer(action.data)
			return { editedKeys: [], ...customerInitialData }
		case ActionTypes.EDIT_CUSTOMERPROFILE:
			let key = Object.keys(action.data)[0]
			newEditedKeys = [...state.editedKeys]
			let a = JSON.stringify(action.data[key])
			let b = JSON.stringify(customerInitialData[key])
			if (a !== b) {
				newEditedKeys.push(key)
			} else {
				newEditedKeys = state.editedKeys.filter(e => e !== key)
			}
			return { ...state, editedKeys: newEditedKeys, ...action.data }
		case ActionTypes.FETCH_LINKED_CUSTOMER_SUCCESS:
			return { ...state, linkedCustomers: action.data }
		case ActionTypes.SUBMIT_LINK_CUSTOMER_SUCCESS:
			return {
				...state,
				linkedCustomers: [...state.linkedCustomers, action.requestMeta.linkedCustomer],
			}
		case ActionTypes.SUBMIT_REMOVE_LINK_CUSTOMER_REQUEST:
			return state
		case ActionTypes.SUBMIT_REMOVE_LINK_CUSTOMER_SUCCESS:
			return {
				...state,
				linkedCustomers: state.linkedCustomers.filter(
					lc => lc.id.toString() !== action.requestMeta.toRemoveID
				),
			}
		case ActionTypes.FETCH_CUSTOMER_INCOMPATIBILITIES_SUCCESS:
			return { ...state, incompatibilities: action.data }
		case ActionTypes.SUBMIT_CUSTOMER_INCOMPATIBILITY_SUCCESS:
			return { ...state, incompatibilities: [...state.incompatibilities, action.data] }
		case ActionTypes.SUBMIT_REMOVE_CUSTOMER_INCOMPATIBILITY_SUCCESS:
			return {
				...state,
				incompatibilities: state.incompatibilities.filter(
					lc => lc.id.toString() !== action.requestMeta.toRemoveID
				),
			}
		case ActionTypes.FETCH_CUSTOMER_PAYMENTS_SUCCESS:
			return {
				...state,
				paymentsStatus: action.data,
				pastBookings: {
					bookings: action.bookings.data.map(b =>
						normalizeBookingLine({ id: b.id, attributes: b })
					),
					totalPages: action.bookings.total_pages,
				},
			}
		case ActionTypes.CUSTOMERPROFILE_SUBMITNEWRESIDENCE_SUCCESS:
			return { ...state, allResidences: [...state.allResidences, action.data] }
		case ActionTypes.UPDATERESIDENCE_SUCCESS:
			let newResidences = [
				...state.allResidences.filter(e => e.id !== action.data.id),
				action.data,
			]
			return { ...state, allResidences: newResidences }
		case ActionTypes.INVITECUSTOMER_SUCCESS:
			return { ...state, invited: true }
		case ActionTypes.RESET_CUSTOMERDATA:
			return { ...customerInitialData, editedKeys: [] }
		case ActionTypes.FETCHCUSTOMER_REQUEST:
		case ActionTypes.ENDCUSTOMERCONTRACT_SUCCESS:
		case ActionTypes.LOGOUT:
			return { editedKeys: [] }
		default:
			return state
	}
}

const currentCustomerAbsences = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.FETCH_CUSTOMER_ABSENCES_SUCCESS:
			return [...action.data]
		case ActionTypes.CREATE_CUSTOMER_ABSENCE_SUCCESS:
			return [...state, action.data]
		case ActionTypes.DELETE_CUSTOMER_ABSENCE_SUCCESS:
			return state.filter(
				e => parseInt(e.id, 10) !== parseInt(action.requestMeta.deletedID, 10)
			)
		case ActionTypes.LOGOUT:
			return []
		default:
			return state
	}
}

const customerChatInit = { messages: [] }
const customerChat = (state = customerChatInit, action) => {
	switch (action.type) {
		case ActionTypes.FETCHCUSTOMERMESSAGES_SUCCESS:
			let messages = action.data.map(m => ({
				id: m.id,
				createdAt: m.attributes.created_at,
				senderType: m.attributes.sender_type,
				senderName: m.attributes.display_name,
				content: m.attributes.content,
				images: m.attributes.images,
			}))
			return { ...state, messages: messages }
		case ActionTypes.SUBMITCUSTOMERMESSAGE_SUCCESS:
			let newMess = {
				id: action.data.id,
				createdAt: action.data.attributes.created_at,
				senderType: action.data.attributes.sender_type,
				senderName: action.data.attributes.display_name,
				content: action.data.attributes.content,
				images: action.data.attributes.images,
			}
			return { ...state, messages: [...state.messages, newMess] }
		case ActionTypes.LOGOUT:
			return customerChatInit
		default:
			return state
	}
}

const cleanerChatInit = { messages: [] }
const cleanerChat = (state = cleanerChatInit, action) => {
	switch (action.type) {
		case ActionTypes.FETCHWORKERMESSAGES_SUCCESS:
			let messages = action.data.map(m => ({
				id: m.id,
				createdAt: m.attributes.created_at,
				senderType: m.attributes.sender_type,
				senderName: m.attributes.display_name,
				content: m.attributes.content,
				images: m.attributes.images,
			}))
			return { ...state, messages: messages }
		case ActionTypes.SUBMITWORKERMESSAGE_SUCCESS:
			let newMess = {
				id: action.data.id,
				createdAt: action.data.attributes.created_at,
				senderType: action.data.attributes.sender_type,
				senderName: action.data.attributes.display_name,
				content: action.data.attributes.content,
				images: action.data.attributes.images,
			}
			return { ...state, messages: [...state.messages, newMess] }
		case ActionTypes.LOGOUT:
			return cleanerChatInit
		default:
			return state
	}
}

const affiliateMessages = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.FETCHBATCHWORKERMESSAGES_SUCCESS:
			return { data: action.data, loading: false }
		case ActionTypes.FETCHBATCHWORKERMESSAGES_REQUEST:
			return { loading: true }
		case ActionTypes.POSTBATCHWORKERMESSAGES_REQUEST:
			return { loading: true }
		case ActionTypes.POSTBATCHWORKERMESSAGES_SUCCESS:
			return { loading: false }
		default:
			return state
	}
}

const cancelledEvents = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.CANCELLEDEVENTS_SUCCESS:
			return { data: action.data, loading: false }
		case ActionTypes.CANCELLEDEVENTS_REQUEST:
			return { loading: true }
		default:
			return state
	}
}

const customerSuggestions = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.CUSTOMERSUGGESTION_SUCCESS:
			return [...action.data]
		case ActionTypes.LOGOUT:
		case ActionTypes.CHOOSE_CUSTOMER:
		case ActionTypes.CUSTOMERSUGGESTION_RESET:
			return []
		default:
			return state
	}
}

const sodexoInit = { flanders: {}, wallonia: {}, brussels: {} }
const sodexo = (state = sodexoInit, action) => {
	switch (action.type) {
		case ActionTypes.PSV_DATA_SUCCESS:
			return { ...state, ...action.data }
		case ActionTypes.LOGOUT:
			return []
		default:
			return state
	}
}

const sodexoPDFInit = { returnSlipURL: "", PSVDeclarationURL: "" }
const sodexoPDF = (state = sodexoPDFInit, action) => {
	switch (action.type) {
		case ActionTypes.RETURN_SLIP_PDF_SUCCESS:
			return { ...state, returnSlipURL: action.returnSlipPDF }
		case ActionTypes.LOGOUT:
			return sodexoPDFInit
		default:
			return state
	}
}

const superAreas = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.FETCH_SUPERAREAS_SUCCESS:
			return action.data
		case ActionTypes.LOGOUT:
			return []
		default:
			return state
	}
}

const teams = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.FETCH_TEAMS_SUCCESS:
			return action.data
		case ActionTypes.CREATE_TEAM_SUCCESS:
			return [...state, action.data]
		case ActionTypes.UPDATE_TEAM_SUCCESS:
			return state.map(entry => (entry.id === action.data.id ? action.data : entry))
		case ActionTypes.DELETE_TEAM_SUCCESS:
			return state.filter(entry => entry.id !== action.requestMeta.deletedId)
		case ActionTypes.LOGOUT:
			return []
		default:
			return state
	}
}

const noTeamsWorkers = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.FETCH_WORKERS_WITHOUT_SQUADS_SUCCESS:
			return action.data
		case ActionTypes.LOGOUT:
			return []
		default:
			return state
	}
}

const impactedBookingsInit = {}
const impactedBookings = (state = impactedBookingsInit, action) => {
	switch (action.type) {
		case ActionTypes.SHOW_IMPACTED_BOOKINGS_MODAL:
			return normalizeImpactedBookings(action.impactedBookings, action.meta.allowedOptions)
		case ActionTypes.EDIT_IMPACTED_BOOKING:
			return {
				...state,
				[action.bookingID]: { ...state[action.bookingID], ...action.changes },
			}
		case ActionTypes.EDIT_IMPACTED_OCCURRENCE:
			return {
				...state,
				[action.bookingID]: {
					...state[action.bookingID],
					impactedOccurrences: state[action.bookingID].impactedOccurrences.map(entry => {
						if (entry.id === action.occurrenceID) {
							return { ...entry, ...action.changes }
						}
						return entry
					}),
				},
			}
		case ActionTypes.REPLACE_ALL_IMPACTED_BOOKINGS:
			return action.impactedBookings
		case ActionTypes.SUBMIT_OTHER_ACTIVITY_WITH_IMPACTED_BOOKINGS_SUCCESS:
		case ActionTypes.FORGET_WORKER_CONTRACT_SUCCESS:
		case ActionTypes.RESET_IMPACTED_BOOKINGS:
		case ActionTypes.LOGOUT:
			return impactedBookingsInit
		default:
			return state
	}
}

const documentTemplates = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.FETCH_DOCUMENT_TEMPLATES_SUCCESS:
			return action.data.map(normalizeDocumentTemplate)
		case ActionTypes.SUBMIT_DOCUMENT_TEMPLATE_SUCCESS:
			return [...state, normalizeDocumentTemplate(action.data)]
		case ActionTypes.UPDATE_DOCUMENT_TEMPLATE_SUCCESS:
			return state.map(entry => {
				if (entry.id === Number(action.data.id)) {
					return normalizeDocumentTemplate(action.data)
				}
				return entry
			})
		case ActionTypes.DELETE_DOCUMENT_TEMPLATE_SUCCESS:
			return state.filter(entry => parseInt(entry.id, 10) !== action.deleted_id)
		case ActionTypes.LOGOUT:
			return []
		default:
			return state
	}
}

const workerPlannedWeekTimeRanges = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.FETCH_WORKER_WEEK_SUCCESS:
			return [...state, ...normalizeWorkerWeek(action)]
		case ActionTypes.RESET_WORKER_WEEK_STATE:
		case ActionTypes.LOGOUT:
			return []
		default:
			return state
	}
}

const workerPlannedWeekReport = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.FETCH_WORKER_WEEK_SUCCESS:
			let week = moment(action.start_time)
			let metadata = action.events[0].weeks_metadata[action.start_time]
			let worker = action.events[0].worker
			return [
				...state,
				{
					week,
					metadata,
					contractHours: metadata.contract_hours,
					workerContractID: worker.id,
					// schedulePeriodID: metadata.schedule_period_id,
				},
			]
		case ActionTypes.RESET_WORKER_WEEK_STATE:
		case ActionTypes.LOGOUT:
			return []
		default:
			return state
	}
}

const secSocTermsMappings = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.FETCH_SEC_SOC_MAPPINGS_SUCCESS:
			return action.data.map(normalizeSecSocTermMapping)
		case ActionTypes.SUBMIT_SEC_SOC_TERM_MAPPING_SUCCESS:
			return [...state, normalizeSecSocTermMapping(action.data)]
		case ActionTypes.DELETE_SEC_SOC_TERM_MAPPING_SUCCESS:
			return state.filter(entry => entry.id !== action.requestMeta.deletedID)
		case ActionTypes.LOGOUT:
			return []
		default:
			return state
	}
}

const secSocCustomExpensesCodes = (state = {}, action) => {
	switch (action.type) {
		case ActionTypes.FETCH_SEC_SOC_CUSTOM_EXPENSES_CODES_SUCCESS:
			return { data: action.data, meta: action.meta }
		case ActionTypes.SUBMIT_CUSTOM_EXPENSE_CODE_SUCCESS:
			return { ...state, data: [...state.data, action.data] }
		case ActionTypes.DELETE_CUSTOM_EXPENSE_CODE_SUCCESS:
			return {
				...state,
				data: state.data.filter(
					entry => entry.id !== action.deleted_custom_expense_code_id
				),
			}
		case ActionTypes.LOGOUT:
			return []
		default:
			return state
	}
}

const avOrderItemsListInit = []
const avOrderItemsList = (state = avOrderItemsListInit, action) => {
	switch (action.type) {
		case ActionTypes.FETCH_AVAILABLE_ORDER_ITEMS_LIST_SUCCESS:
			return action.data.map(normalizeAvailableLaundryOrderItem)
		case ActionTypes.SUBMIT_NEW_AVAILABLE_ORDER_ITEM_SUCCESS:
			return [...state, normalizeAvailableLaundryOrderItem(action.data)]
		case ActionTypes.DELETED_AVAILABLE_ORDER_ITEM:
			return state.filter(avOrderItem => avOrderItem.id !== action.id)
		default:
			return state
	}
}

const notificationsInit = []
const notifications = (state = notificationsInit, action) => {
	switch (action.type) {
		case ActionTypes.MARK_NOTIFICATIONS_AS_READ_SUCCESS:
		case ActionTypes.FETCH_NOTIFICATIONS_SUCCESS:
			return action.data.map(normalizeNotification)
		case ActionTypes.POLL_SERVER_SUCCESS:
			if (action.notifications) {
				return action.notifications.data.map(normalizeNotification)
			}
			return state
		default:
			return state
	}
}

let currentWorkshopOrderInitialState = {
	worker: {},
	customer: {},
	startDate: moment(),
}
const currentWorkshopOrder = (state = currentWorkshopOrderInitialState, action) => {
	let newEditedKeys
	switch (action.type) {
		case ActionTypes.FETCH_WORKSHOP_ORDER_SUCCESS:
		case ActionTypes.UPDATE_WORKSHOP_ORDER_SUCCESS:
		case ActionTypes.CANCEL_WORKSHOP_ORDER_SUCCESS:
			let workshopOrderData = normalizeWorkshopOrder(action.data)
			currentWorkshopOrderInitialState = { ...workshopOrderData }
			return { ...state, ...workshopOrderData, editedKeys: [] }
		case ActionTypes.RESET_UPDATE_WORKSHOP_ORDER:
			return { ...state, ...currentWorkshopOrderInitialState, editedKeys: [] }
		case ActionTypes.EDIT_WORKSHOP_ORDER:
			let key = Object.keys(action.data)[0]
			newEditedKeys = [...state.editedKeys]
			let a = JSON.stringify(action.data[key])
			let b = JSON.stringify(currentWorkshopOrderInitialState[key])
			if (a !== b) {
				newEditedKeys.push(key)
			} else {
				newEditedKeys = state.editedKeys.filter(e => e !== key)
			}
			return { ...state, editedKeys: newEditedKeys, ...action.data }

		case ActionTypes.REMOVE_WORKSHOP_ORDER_PSV_SUCCESS:
			return {
				...state,
				linkedPSVs: state.linkedPSVs.filter(entry => entry.id !== action.deleted_id),
			}
		default:
			return state
	}
}

const tasksInit = []
const tasks = (state = tasksInit, action) => {
	switch (action.type) {
		case ActionTypes.FETCH_TASKS_SUCCESS:
			return action.data.map(normalizeTask)
		case ActionTypes.POLL_SERVER_SUCCESS:
			if (action.tasks) {
				return action.tasks.data.map(normalizeTask)
			}
			return state
		case ActionTypes.AUTO_SAVE_TASK_SUCCESS:
		case ActionTypes.TASK_DONE_SUCCESS:
			let currentIndex = state.findIndex(entry => Number(entry.id) === Number(action.data.id))
			if (currentIndex === -1) {
				return [normalizeTask(action.data), ...state]
			}
			state.splice(currentIndex, 1, normalizeTask(action.data))
			return [...state]
		case ActionTypes.SUBMIT_DELETE_CURRENT_TASK_SUCCESS:
			return state.filter(entry => entry.id !== action.requestMeta.deletedId)
		default:
			return state
	}
}

const economicSuspensions = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.FETCH_ECONOMIC_SUSPENSIONS_SUCCESS:
			return action.data
		case ActionTypes.LOGOUT:
			return []
		default:
			return state
	}
}

const paymentReminders = (state = [], action) => {
	switch (action.type) {
		case ActionTypes.FETCH_PAYMENT_REMINDERS_SUCCESS:
			return action.data
		case ActionTypes.LOGOUT:
			return []
		default:
			return state
	}
}

const paymentReminder = (state = {}, action) => {
	switch (action.type) {
		case ActionTypes.FETCH_PAYMENT_REMINDER_SUCCESS:
			return action.data
		case ActionTypes.RESET_PAYMENT_REMINDER:
			return {}
		case ActionTypes.LOGOUT:
			return []
		default:
			return state
	}
}

const bookingWishes = (state = {}, action) => {
	switch (action.type) {
		case ActionTypes.FETCH_BOOKING_WISHES_SUCCESS:
			return normalizeBookingWishes({
				triggers: action.meta.triggers,
				bookingWishes: action.data,
			})
		case ActionTypes.SUBMIT_BOOKING_WISH_DATERANGE_SUCCESS:
		case ActionTypes.SUBMIT_REMOVE_BOOKING_WISH_DATERANGE_SUCCESS:
			let oldWishGroup = state[action.data.attributes.wishes_group_id]
			let newBookingWishes = oldWishGroup.bookingWishes.map(entry => {
				if (entry.id === action.data.id) {
					return normalizeBookingWish(action.data)
				}
				return entry
			})
			let newState = {
				...state,
				[oldWishGroup.id]: {
					...oldWishGroup,
					allWishedDateranges: newBookingWishes.reduce(
						(acc, entry) => acc.concat(entry.wishedDateranges),
						[]
					),
					bookingWishes: newBookingWishes,
				},
			}
			return newState
		case ActionTypes.UPDATE_BOOKING_WISH_GROUP_STATUS_SUCCESS:
			let newBookingWish = normalizeBookingWish(action.data)
			return {
				...state,
				[newBookingWish.groupId]: {
					...state[newBookingWish.groupId],
					bookingWishes: state[newBookingWish.groupId].bookingWishes.map(entry => {
						if (entry.id === newBookingWish.id) {
							return newBookingWish
						}
						return entry
					}),
				},
			}
		case ActionTypes.UPDATE_BOOKING_WISHES_HIGHLIGHTS:
		case ActionTypes.UPDATE_SELECTED_OCCURRENCES_DATES:
			return action.bookingWishes
		case ActionTypes.LOGOUT:
			return []
		default:
			return state
	}
}

export const redData = combineReducers({
	currentUser,
	allAreas,
	allWorkerLanguages,
	affiliates,
	affiliateMessages,
	cancelledEvents,
	currentAffiliate,
	currentAffiliateLocalisedFields,
	dashboard,
	agenda,
	timesheetsData,
	secSocPdf,
	pootsyEvents,
	pootsyExpenses,
	bookings,
	bookingFilters,
	currentBooking,
	checkPSVCode,
	currentNewBookingFormData,
	currentEditedBooking,
	currentDirectBookingForm,
	cleaners,
	currentNewWorkerFormData,
	currentWorker,
	currentWorkerAbsences,
	currentWorkerBookings,
	workerSuggestions,
	workerContractsOriginalData,
	workerContracts,
	customers,
	currentCustomerForm,
	currentCustomer,
	currentCustomerAbsences,
	customerChat,
	cleanerChat,
	customerSuggestions,
	sodexo,
	sodexoPDF,
	superAreas,
	teams,
	noTeamsWorkers,
	impactedBookings,
	documentTemplates,
	workerPlannedWeekTimeRanges,
	workerPlannedWeekReport,
	secSocTermsMappings,
	sodexoBookings,
	sodexoPaperServiceVouchers,
	workshopOrdersFilters,
	workshopOrders,
	currentWorkshopOrder,
	avOrderItemsList,
	dimonasFilters,
	dimonas,
	notifications,
	tasks,
	economicSuspensions,
	paymentReminders,
	paymentReminder,
	bookingWishes,
	secSocCustomExpensesCodes,
})
