import React, { Component } from "react"
import PropTypes from "prop-types"

import { PootsyButton } from "./FunctionalDesign"
import { PootsyTextInput } from "./FunctionalInputs"
import ModalLayout from "./ModalLayout"

class UpdateSquadModal extends Component {
	render() {
		let { t } = this.context
		let {
			workerSearchValue,
			workerSuggestions,
			chosenWorkers,
			submitUpdateTeam,
			toggleUpdateSquadModal,
			handleSearchWorkers,
			handleWorkerClick,
			removeExistingWorker,
			removeAddedWorker,
			workersStagedForRemovalIDs,
			editedTeam,
		} = this.props
		let updateModalButtons = [
			<PootsyButton key="a" text={t("save")} onClick={submitUpdateTeam} />,
			<PootsyButton
				key="b"
				text={t("cancel")}
				theme="cancel"
				onClick={toggleUpdateSquadModal}
			/>,
		]
		return (
			<ModalLayout
				title={t("update_squad")}
				buttons={updateModalButtons}
				closeModal={toggleUpdateSquadModal}
				formDivClass="update-squad-modal"
			>
				<div className="search-inputs">
					<div className="worker-search-box">
						<PootsyTextInput
							label={t("search_cleaners")}
							onChange={handleSearchWorkers}
							value={workerSearchValue}
						/>
						{workerSuggestions.length > 0 && (
							<div className="worker-suggestions">
								{workerSuggestions
									.filter(entry => {
										let IDsInTeam = editedTeam.attributes.affiliate_workers.map(
											e => e.id.toString()
										)
										return IDsInTeam.indexOf(entry.id) === -1
									})
									.filter(
										entry =>
											chosenWorkers
												.map(e => e.id.toString())
												.indexOf(entry.id) === -1
									)
									.map(cs => (
										<div
											key={cs.id}
											className="worker-suggestion"
											onClick={handleWorkerClick.bind(this, cs)}
										>
											<div className="worker-name">
												{cs.attributes.display_name}
											</div>
										</div>
									))}
							</div>
						)}
					</div>
				</div>
				<div className="people-lists">
					<div className="workers">
						<div className="list-title">{t("workers")}</div>
						{workersStagedForRemovalIDs.length > 0 && (
							<div className="staged-for-removal-tooltip">
								{t("worker_will_be_removed")}
							</div>
						)}
						<div className="people-in-squad-list">
							{editedTeam.attributes.affiliate_workers.map(entry => {
								let stagedForRemoval =
									workersStagedForRemovalIDs.indexOf(entry.id.toString()) > -1
								return (
									<div
										key={entry.id}
										className={
											"worker-in-squad" +
											(stagedForRemoval ? " staged-for-removal" : "")
										}
										onClick={removeExistingWorker}
										data-id={entry.id}
									>
										- {entry.name}
										<div className="tooltip">
											{stagedForRemoval ? t("cancel") : t("click_to_remove")}
										</div>
									</div>
								)
							})}
						</div>
						<div className="added-workers">
							{chosenWorkers.map(entry => (
								<div
									key={entry.id}
									className="added-worker"
									onClick={removeAddedWorker}
									data-id={entry.id}
								>
									+ {entry.attributes.display_name}
									<div className="tooltip">{t("click_to_remove")}</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</ModalLayout>
		)
	}
}

UpdateSquadModal.contextTypes = { t: PropTypes.func }
export default UpdateSquadModal
