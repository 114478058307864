import { isOverlapping } from "../helpers/isOverlapping"
import { simpleFetch, denormalizeImpactedBookingActions } from "./index"
import { stringifyQuery } from "../helpers/qs"
import { uuid } from "../helpers/uuid"
import moment from "moment"

export const EDIT_SETTINGS = "EDIT_SETTINGS"
export const editSettings = changes => ({ type: EDIT_SETTINGS, changes })

export const RESET_SETTINGS = "RESET_SETTINGS"
export const resetSettings = () => ({ type: RESET_SETTINGS })

export const SETTINGS_RMVHOLIDAYWARNING = "SETTINGS_RMVHOLIDAYWARNING"
export const settingsRmvHolidayWarning = error => ({ type: SETTINGS_RMVHOLIDAYWARNING, error })

export const SETTINGS_UICHANGE = "SETTINGS_UICHANGE"
export const settingsUIChange = changes => ({ type: SETTINGS_UICHANGE, changes })

export const PDF_EDITOR_STATE_CHANGE = "PDF_EDITOR_STATE_CHANGE"
export const pdfEditorStateChange = changes => ({ type: PDF_EDITOR_STATE_CHANGE, changes })

export const SETTINGS_DIMONA_STATE_CHANGE = "SETTINGS_DIMONA_STATE_CHANGE"
export const settingsDimonaStateChanges = changes => ({
	type: SETTINGS_DIMONA_STATE_CHANGE,
	changes,
})

export const SETTINGS_TEMPLATE_STATE_CHANGE = "SETTINGS_TEMPLATE_STATE_CHANGE"
export const settingsTemplateStateChange = changes => ({
	type: SETTINGS_TEMPLATE_STATE_CHANGE,
	changes,
})

export const SALARIES_INDEXING_FORM_STATE_CHANGE = "SALARIES_INDEXING_FORM_STATE_CHANGE"
export const salariesIndexingFormStateChange = changes => ({
	type: SALARIES_INDEXING_FORM_STATE_CHANGE,
	changes,
})

export const SUBMIT_HEADQUARTER = "SUBMIT_HEADQUARTER"
export const SUBMIT_HEADQUARTER_REQUEST = "SUBMIT_HEADQUARTER_REQUEST"
export const SUBMIT_HEADQUARTER_SUCCESS = "SUBMIT_HEADQUARTER_SUCCESS"
export const SUBMIT_HEADQUARTER_FAILURE = "SUBMIT_HEADQUARTER_FAILURE"
export const submitNewHeadquarter = () => async (dispatch, getState) => {
	let {
		redComponents: {
			settingsDimona: { new_headquarter },
		},
	} = getState()
	let init = {
		method: "POST",
		body: JSON.stringify(new_headquarter),
	}
	let url = "/front/affiliates/operating_headquarters"
	await dispatch(simpleFetch(url, init, SUBMIT_HEADQUARTER))
	url = "/front/affiliates/affiliates"
	init = { method: "GET" }
	dispatch(simpleFetch(url, init, "FETCH_AFFILIATE"))
}

export const FETCH_HISTORY_INDEXATIONS = "FETCH_HISTORY_INDEXATIONS"
export const FETCH_HISTORY_INDEXATIONS_REQUEST = "FETCH_HISTORY_INDEXATIONS_REQUEST"
export const FETCH_HISTORY_INDEXATIONS_SUCCESS = "FETCH_HISTORY_INDEXATIONS_SUCCESS"
export const FETCH_HISTORY_INDEXATIONS_FAILURE = "FETCH_HISTORY_INDEXATIONS_FAILURE"
export const fetchHistoryIndexations = () => (dispatch, getState) => {
	let init = { method: "GET" }
	let url = "/front/affiliates/salary_indexations"
	return dispatch(simpleFetch(url, init, FETCH_HISTORY_INDEXATIONS))
}
export const UPDATE_WORKER_SALARIES = "UPDATE_WORKER_SALARIES"
export const UPDATE_WORKER_SALARIES_REQUEST = "UPDATE_WORKER_SALARIES_REQUEST"
export const UPDATE_WORKER_SALARIES_SUCCESS = "UPDATE_WORKER_SALARIES_SUCCESS"
export const UPDATE_WORKER_SALARIES_FAILURE = "UPDATE_WORKER_SALARIES_FAILURE"

export const updateWorkerSalaries = () => async (dispatch, getState) => {
	let {
		redComponents: {
			salariesIndexingFormComponent: {
				dateIndexation,
				workers,
				indexingMode,
				indexingAmount,
			},
		},
	} = getState()
	let selectedWorkersIds = []
	workers.forEach(worker => {
		if (worker.affected) {
			selectedWorkersIds.push(worker.id)
		}
	})
	let params = {
		date_indexation: dateIndexation.format("YYYY-MM-DD"),
		worker_ids: selectedWorkersIds,
		indexation_value: parseFloat(indexingAmount.toString().replace(",", ".")),
		indexation_mode: indexingMode,
	}
	let url = "/front/affiliates/worker_salaries/update_batch"
	let init = {
		method: "PUT",
		body: JSON.stringify(params),
	}
	await dispatch(simpleFetch(url, init, UPDATE_WORKER_SALARIES))
	dispatch(fetchHistoryIndexations())
}

export const INDEXING_WORKERS_SALARY = "INDEXING_WORKERS_SALARY"
export const INDEXING_WORKERS_SALARY_REQUEST = "INDEXING_WORKERS_SALARY_REQUEST"
export const INDEXING_WORKERS_SALARY_SUCCESS = "INDEXING_WORKERS_SALARY_SUCCESS"
export const INDEXING_WORKERS_SALARY_FAILURE = "INDEXING_WORKERS_SALARY_FAILURE"

export const newIndexationSalariesWorkerInfo = () => (dispatch, getState) => {
	let {
		redComponents: {
			salariesIndexingFormComponent: { dateIndexation, squad },
		},
	} = getState()
	let params = {
		date_indexation: dateIndexation.format("YYYY-MM-DD"),
		squad: squad,
	}
	let url = "/front/affiliates/worker_salaries/new" + stringifyQuery(params)
	let init = {
		method: "GET",
	}
	dispatch(simpleFetch(url, init, INDEXING_WORKERS_SALARY))
}

export const UPDATESETTINGS_REQUEST = "UPDATESETTINGS_REQUEST"
export const UPDATESETTINGS_SUCCESS = "UPDATESETTINGS_SUCCESS"
export const UPDATESETTINGS_FAILURE = "UPDATESETTINGS_FAILURE"

export const UPDATE_AFFILIATE_USER_ROLE_REQUEST = "UPDATE_AFFILIATE_USER_ROLE_REQUEST"
export const UPDATE_AFFILIATE_USER_ROLE_SUCCESS = "UPDATE_AFFILIATE_USER_ROLE_SUCCESS"
export const UPDATE_AFFILIATE_USER_ROLE_FAILURE = "UPDATE_AFFILIATE_USER_ROLE_FAILURE"

export const NEWAFFILIATEUSER_REQUEST = "NEWAFFILIATEUSER_REQUEST"
export const NEWAFFILIATEUSER_SUCCESS = "NEWAFFILIATEUSER_SUCCESS"
export const NEWAFFILIATEUSER_FAILURE = "NEWAFFILIATEUSER_FAILURE"

export const REVOKEAFFILIATEUSER_REQUEST = "REVOKEAFFILIATEUSER_REQUEST"
export const REVOKEAFFILIATEUSER_SUCCESS = "REVOKEAFFILIATEUSER_SUCCESS"
export const REVOKEAFFILIATEUSER_FAILURE = "REVOKEAFFILIATEUSER_FAILURE"

export const FETCH_SECSOC_SPECIFIC_FIELDS_REQUEST = "FETCH_SECSOC_SPECIFIC_FIELDS_REQUEST"
export const FETCH_SECSOC_SPECIFIC_FIELDS_SUCCESS = "FETCH_SECSOC_SPECIFIC_FIELDS_SUCCESS"
export const FETCH_SECSOC_SPECIFIC_FIELDS_FAILURE = "FETCH_SECSOC_SPECIFIC_FIELDS_FAILURE"

export const SUBMIT_SECSOC_SPECIFIC_FIELDS_REQUEST = "SUBMIT_SECSOC_SPECIFIC_FIELDS_REQUEST"
export const SUBMIT_SECSOC_SPECIFIC_FIELDS_SUCCESS = "SUBMIT_SECSOC_SPECIFIC_FIELDS_SUCCESS"
export const SUBMIT_SECSOC_SPECIFIC_FIELDS_FAILURE = "SUBMIT_SECSOC_SPECIFIC_FIELDS_FAILURE"

export const CREATE_CUSTOMER_ABSENCE_REQUEST = "CREATE_CUSTOMER_ABSENCE_REQUEST"
export const CREATE_CUSTOMER_ABSENCE_SUCCESS = "CREATE_CUSTOMER_ABSENCE_SUCCESS"
export const CREATE_CUSTOMER_ABSENCE_FAILURE = "CREATE_CUSTOMER_ABSENCE_FAILURE"

export const DELETE_CUSTOMER_ABSENCE_REQUEST = "DELETE_CUSTOMER_ABSENCE_REQUEST"
export const DELETE_CUSTOMER_ABSENCE_SUCCESS = "DELETE_CUSTOMER_ABSENCE_SUCCESS"
export const DELETE_CUSTOMER_ABSENCE_FAILURE = "DELETE_CUSTOMER_ABSENCE_FAILURE"

export const CREATE_TEAM_REQUEST = "CREATE_TEAM_REQUEST"
export const CREATE_TEAM_SUCCESS = "CREATE_TEAM_SUCCESS"
export const CREATE_TEAM_FAILURE = "CREATE_TEAM_FAILURE"

export const UPDATE_TEAM_REQUEST = "UPDATE_TEAM_REQUEST"
export const UPDATE_TEAM_SUCCESS = "UPDATE_TEAM_SUCCESS"
export const UPDATE_TEAM_FAILURE = "UPDATE_TEAM_FAILURE"

export const DELETE_TEAM_REQUEST = "DELETE_TEAM_REQUEST"
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS"
export const DELETE_TEAM_FAILURE = "DELETE_TEAM_FAILURE"

export const SUBSCRIBE_TO_TEAM_REQUEST = "SUBSCRIBE_TO_TEAM_REQUEST"
export const SUBSCRIBE_TO_TEAM_SUCCESS = "SUBSCRIBE_TO_TEAM_SUCCESS"
export const SUBSCRIBE_TO_TEAM_FAILURE = "SUBSCRIBE_TO_TEAM_FAILURE"

export const FETCH_SEC_SOC_MAPPINGS_REQUEST = "FETCH_SEC_SOC_MAPPINGS_REQUEST"
export const FETCH_SEC_SOC_MAPPINGS_SUCCESS = "FETCH_SEC_SOC_MAPPINGS_SUCCESS"
export const FETCH_SEC_SOC_MAPPINGS_FAILURE = "FETCH_SEC_SOC_MAPPINGS_FAILURE"

export const SUBMIT_SEC_SOC_TERM_MAPPING_REQUEST = "SUBMIT_SEC_SOC_TERM_MAPPING_REQUEST"
export const SUBMIT_SEC_SOC_TERM_MAPPING_SUCCESS = "SUBMIT_SEC_SOC_TERM_MAPPING_SUCCESS"
export const SUBMIT_SEC_SOC_TERM_MAPPING_FAILURE = "SUBMIT_SEC_SOC_TERM_MAPPING_FAILURE"

export const FETCH_SEC_SOC_CUSTOM_EXPENSES_CODES_REQUEST =
	"FETCH_SEC_SOC_CUSTOM_EXPENSES_CODES_REQUEST"
export const FETCH_SEC_SOC_CUSTOM_EXPENSES_CODES_SUCCESS =
	"FETCH_SEC_SOC_CUSTOM_EXPENSES_CODES_SUCCESS"
export const FETCH_SEC_SOC_CUSTOM_EXPENSES_CODES_FAILURE =
	"FETCH_SEC_SOC_CUSTOM_EXPENSES_CODES_FAILURE"

export const SUBMIT_CUSTOM_EXPENSE_CODE_REQUEST = "SUBMIT_CUSTOM_EXPENSE_CODE_REQUEST"
export const SUBMIT_CUSTOM_EXPENSE_CODE_SUCCESS = "SUBMIT_CUSTOM_EXPENSE_CODE_SUCCESS"
export const SUBMIT_CUSTOM_EXPENSE_CODE_FAILURE = "SUBMIT_CUSTOM_EXPENSE_CODE_FAILURE"

export const DELETE_CUSTOM_EXPENSE_CODE_REQUEST = "DELETE_CUSTOM_EXPENSE_CODE_REQUEST"
export const DELETE_CUSTOM_EXPENSE_CODE_SUCCESS = "DELETE_CUSTOM_EXPENSE_CODE_SUCCESS"
export const DELETE_CUSTOM_EXPENSE_CODE_FAILURE = "DELETE_CUSTOM_EXPENSE_CODE_FAILURE"

export const DELETE_SEC_SOC_TERM_MAPPING_REQUEST = "DELETE_SEC_SOC_TERM_MAPPING_REQUEST"
export const DELETE_SEC_SOC_TERM_MAPPING_SUCCESS = "DELETE_SEC_SOC_TERM_MAPPING_SUCCESS"
export const DELETE_SEC_SOC_TERM_MAPPING_FAILURE = "DELETE_SEC_SOC_TERM_MAPPING_FAILURE"

export const CURRENT_USER_UPDATE_REQUEST = "CURRENT_USER_UPDATE_REQUEST"
export const CURRENT_USER_UPDATE_SUCCESS = "CURRENT_USER_UPDATE_SUCCESS"
export const CURRENT_USER_UPDATE_FAILURE = "CURRENT_USER_UPDATE_FAILURE"

export const addOpeningHour = ({ currentOpeningDay, currentOpeningStart, currentOpeningEnd }) => (
	dispatch,
	getState
) => {
	let { openingHours } = getState().redData.currentAffiliate
	let newOpening = {
		id: uuid(), // < this is a placeholder identifier, the db ID will be used once created
		day: currentOpeningDay,
		start: moment(currentOpeningStart),
		end: moment(currentOpeningEnd),
	}
	let overlap = isOverlapping(openingHours, newOpening)
	if (overlap.timeRangeWithinAnother || overlap.overlappingEntryIndices.length > 0) {
		return
	}
	let newOpeningHours = [...openingHours, newOpening]
	dispatch(editSettings({ openingHours: newOpeningHours }))
}

export const AFFILIATE_LOCALISED_FIELDS_DATA_CHANGE = "AFFILIATE_LOCALISED_FIELDS_DATA_CHANGE"
export const affiliateLocalisedFieldDataChange = data => (dispatch, getState) => {
	let { currentAffiliateLocalisedFields: state } = getState().redData
	let key = Object.keys(data)[0]
	let field = key.split(":")[0]
	let lang = key.split(":")[1]
	let value = data[key]
	let newValues = state[field].map(obj => {
		if (obj.lang === lang) {
			return { value, lang }
		}
		return obj
	})
	let changes = { [field]: newValues }
	dispatch({ type: AFFILIATE_LOCALISED_FIELDS_DATA_CHANGE, changes })
}

export const submitSecSocSettingsUpdate = () => (dispatch, getState) => {
	let { currentAffiliate } = getState().redData
	let url = "/front/affiliates/social_secretary_additional_field_values"
	let init = {
		method: "POST",
		body: JSON.stringify({
			values: currentAffiliate.secSocSpecificFields.map(entry => {
				let value =
					currentAffiliate[entry.reference] === ""
						? null
						: currentAffiliate[entry.reference]
				return { reference: entry.reference, value: value }
			}),
		}),
	}
	dispatch(simpleFetch(url, init, "SUBMIT_SECSOC_SPECIFIC_FIELDS"))
}

export const SUBMIT_EXPENSES_UPDATE_REQUEST = "SUBMIT_EXPENSES_UPDATE_REQUEST"
export const SUBMIT_EXPENSES_UPDATE_SUCCESS = "SUBMIT_EXPENSES_UPDATE_SUCCESS"
export const SUBMIT_EXPENSES_UPDATE_FAILURE = "SUBMIT_EXPENSES_UPDATE_FAILURE"
export const submitExpensesUpdate = () => (dispatch, getState) => {
	const handleSeparator = value => {
		if (!value) {
			return null
		}
		return value.toString().replace(",", ".")
	}

	let { currentAffiliate } = getState().redData
	let url = "/front/affiliates/social_secretary_settings"
	let init = {
		method: "PUT",
		body: JSON.stringify({
			meal_vouchers_config: {
				automatic: currentAffiliate.mealVoucherConfig.automatic,
				rule: currentAffiliate.mealVoucherConfig.rule,
				use_different_codes: currentAffiliate.mealVoucherConfig.use_different_codes,
			},
			travel_expenses_config: {
				automatic: currentAffiliate.travelExpConfig.automatic,
				last_customer_home_own_transport:
					currentAffiliate.travelExpConfig.last_customer_home_own_transport || null,
				last_customer_home_public_transport:
					currentAffiliate.travelExpConfig.last_customer_home_public_transport || null,
				last_customer_home_bike:
					currentAffiliate.travelExpConfig.last_customer_home_bike || null,
				last_customer_home_company_car:
					currentAffiliate.travelExpConfig.last_customer_home_company_car || null,
				last_customer_home_others:
					currentAffiliate.travelExpConfig.last_customer_home_others || null,
				bike_amount_per_km: handleSeparator(
					currentAffiliate.travelExpConfig.bike_amount_per_km
				),
				public_transport_amount_per_km: handleSeparator(
					currentAffiliate.travelExpConfig.public_transport_amount_per_km
				),
				max_hours_between_clients: handleSeparator(
					currentAffiliate.travelExpConfig.max_hours_between_clients
				),
				car_home_client_rate: handleSeparator(
					currentAffiliate.travelExpConfig.car_home_client_rate
				),
				cc_car_less_15_rate: handleSeparator(
					currentAffiliate.travelExpConfig.cc_car_less_15_rate
				),
				cc_car_more_15_rate: handleSeparator(
					currentAffiliate.travelExpConfig.cc_car_more_15_rate
				),
				travel_time_rate: handleSeparator(
					currentAffiliate.travelExpConfig.travel_time_rate
				),
				travel_time_minimum: handleSeparator(
					currentAffiliate.travelExpConfig.travel_time_minimum
				),
			},
			clothes_expenses_config: {
				automatic: currentAffiliate.clothesExpConfig.automatic,
				rule: currentAffiliate.clothesExpConfig.rule || null,
			},
		}),
	}
	dispatch(simpleFetch(url, init, "SUBMIT_EXPENSES_UPDATE"))
}

export const submitSettingsUpdate = ({ newDefaultLang }) => (dispatch, getState) => {
	let {
		redData: {
			impactedBookings,
			currentAffiliate: aff,
			currentAffiliateLocalisedFields: affLF,
		},
		redComponents: {
			impactedBookingsModal: { forceUnlink },
		},
	} = getState()
	let fmtAddresses = [aff.commercialAddress, aff.invoicingAddress].map(entry => ({
		town: entry.city,
		country: entry.country,
		street_name: entry.street,
		street_number: entry.streetNumber,
		street_box: entry.box,
		zip_code: entry.zip,
	}))
	let files = []
	if (aff.logoFile) {
		files.push({
			attribute: "logo",
			filename: `affiliate-${aff.id}-logo.${aff.logoFile.extension}`,
			base64_content: aff.logoFile.file,
		})
	}
	;["en", "fr", "nl"].forEach(lang => {
		let conventionFile = aff["conventionFiles:" + lang]
		if (conventionFile) {
			files.push({
				attribute: "convention_" + lang,
				filename: `affiliate-${aff.id}-convention.pdf`,
				base64_content: conventionFile.file,
			})
		}
	})
	if (aff.coverPictureFile) {
		files.push({
			attribute: "cover_picture",
			filename: `affiliate-${aff.id}-cover-picture.${aff.coverPictureFile.extension}`,
			base64_content: aff.coverPictureFile.file,
		})
	}
	let possibleNumberKeys = ["maxMinutesOvertime", "fixedFeeAmount"]
	Object.keys(aff.workshopSettings).forEach(setting => {
		if (possibleNumberKeys.includes(setting)) {
			aff.workshopSettings[setting] = aff.workshopSettings[setting]
				.toString()
				.replace(",", ".")
		}
	})
	let data = {
		vat_number: aff.VatNumber,
		email: aff.adminEmail,
		customer_support_email: aff.supportEmail,
		default_language: newDefaultLang ? newDefaultLang : aff.defaultLanguage,
		telephone: aff.phone,
		areas: aff.activeAreas.map(entry => entry.value),
		name: aff.companyName,
		commercial_name: aff.commercialName,
		min_booking_mins: aff.minBookingMins,
		min_inter_bookings_minutes: aff.minIntraBookingMins,
		allowed_work_on_saturday: aff.workAllowedSaturday,
		allowed_work_on_sunday: aff.workAllowedSunday,
		commercial_address: fmtAddresses[0],
		invoicing_address: fmtAddresses[1],
		files: files,
		days_before_new_booking:
			aff.minDaysBeforeNewBooking === "0" || aff.minDaysBeforeNewBooking === ""
				? null
				: aff.minDaysBeforeNewBooking,
		days_before_booking_cancellation: aff.minDaysBeforeBookingCancel,
		days_before_customer_contract_cancellation: aff.minDaysBeforeEndingCustomerContract,
		accepts_ponctual_bookings: aff.acceptsPonctualBookings,
		sodexo_affiliation_number: aff.sodexoAffiliationNb,
		sodexo_agreement_number_brussels: aff.sodexoAgreementNbBrussels,
		sodexo_agreement_number_vlaanderen: aff.sodexoAgreementNbFlanders,
		sodexo_agreement_number_wallonie: aff.sodexoAgreementNbWallonia,
		holidays: aff.currYearHolidaysTimeRanges.map(entry => ({
			start_date: entry.start.format("YYYY-MM-DD"),
			end_date: entry.end.format("YYYY-MM-DD"),
			custom_ss_code: entry.customSsCode || entry.custom_ss_code,
		})),
		opening_hours: aff.openingHours.map(oh => ({
			day_of_week: oh.day,
			start_time: oh.start.format(),
			end_time: oh.end.format(),
		})),
		localized_bios: affLF.bios,
		localized_slogans: affLF.slogans,
		sec_soc_prefill_worker_contract: aff.secSocPrefillWorkerContract,
		sodexo_connection_settings: aff.sodexoConnectionSettings,
		dimona_settings: aff.dimonaSettings,
		economic_unemployment_settings: aff.ecoUnemploymentSettings,
		workshop_settings: aff.workshopSettings,
		hide_unfulfilling_timeslots_to_customers: aff.hideUnfulfillingTimeslotsToCustomers,
		onss_reference: aff.onssReference,
		bce_reference: aff.bceReference,
		commission: aff.commission.toString().replace(",", "."),
		language_onss: aff.languageONSS,
		reason_small_eco_unemployment: aff.reasonSmallEcoUnemployment,
		reason_big_eco_unemployment: aff.reasonBigEcoUnemployment,
		onss_contact_first_name: aff.onssContactFirstName,
		onss_contact_last_name: aff.onssContactLastName,
		onss_contact_telephone: aff.onssContactTelephone,
		impacted_bookings_actions: denormalizeImpactedBookingActions(impactedBookings),
		force_unlink: forceUnlink,
	}
	if (aff.coverPictureFile === "" && aff.coverPictureUrl === "") {
		data.remove_cover_picture = true
	}
	let secSocSpecificFieldIsEdited = aff.secSocSpecificFields.some(entry =>
		aff.editedKeys.includes(entry.reference)
	)
	let expensesIsEdited =
		aff.editedKeys.includes("mealVoucherConfig") ||
		aff.editedKeys.includes("travelExpConfig") ||
		aff.editedKeys.includes("clothesExpConfig")
	let affiliateKeys = Object.keys(aff).filter(
		entry => !aff.secSocSpecificFields.some(sspf => sspf.reference === entry)
	)
	let affiliateIsEdited =
		aff.editedKeys.some(entry => affiliateKeys.includes(entry)) || !!newDefaultLang
	if (secSocSpecificFieldIsEdited) {
		dispatch(submitSecSocSettingsUpdate())
	}
	if (expensesIsEdited) {
		dispatch(submitExpensesUpdate())
	}
	if (affiliateIsEdited || affLF.isEdited) {
		let init = { method: "PUT", body: JSON.stringify(data) }
		dispatch(simpleFetch("/front/affiliates/settings", init, "UPDATESETTINGS"))
	}
}

export const FETCH_TEAMS = "FETCH_TEAMS"
export const FETCH_TEAMS_REQUEST = "FETCH_TEAMS_REQUEST"
export const FETCH_TEAMS_SUCCESS = "FETCH_TEAMS_SUCCESS"
export const FETCH_TEAMS_FAILURE = "FETCH_TEAMS_FAILURE"
export const fetchTeams = () => (dispatch, getState) => {
	let init = { method: "GET" }
	let url = "/front/affiliates/squads"
	return dispatch(simpleFetch(url, init, FETCH_TEAMS))
}
