import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"
import { PDFDownloadLink, Font, Document, Page, Text, View } from "@react-pdf/renderer"
import { UI_ROOT } from "../config.js"


import * as actions from "../actions"
import { isTimerangeForSchedule } from "../actions"

import Helvetica from "../static/fonts/Helvetica.ttf"
Font.register({ family: "Helvetica", src: UI_ROOT + Helvetica })

const days = [
	{ tKey: "Monday", number: 0 },
	{ tKey: "Tuesday", number: 1 },
	{ tKey: "Wednesday", number: 2 },
	{ tKey: "Thursday", number: 3 },
	{ tKey: "Friday", number: 4 },
	{ tKey: "Saturday", number: 5 },
]

export const WorkerSchedulePdf = ({ schedulePeriods, timeranges, contractStart, t }) => (
	<Fragment>
		<View style={{ flexDirection: "row", marginBottom: 10 }}>
			<View
				style={{ padding: 5, backgroundColor: "#ffead9", borderRadius: 3, marginRight: 10 }}
			>
				<Text>{t("booking")}</Text>
			</View>
			<View style={{ padding: 5, backgroundColor: "#fdba88", borderRadius: 3 }}>
				<Text>{t("theoretical_hour")}</Text>
			</View>
		</View>
		{schedulePeriods.map((schedulePeriod, index) => (
			<View key={schedulePeriod.id} wrap={false} style={{ height: 375, marginBottom: 10 }}>
				<Text>
					{schedulePeriod.startDate.isSame(
						moment(contractStart).startOf("isoweek"),
						"day"
					)
						? t("first_week")
						: t("second_week")}
				</Text>
				<View style={{ flex: 1, flexDirection: "row", alignItems: "stretch" }}>
					{days.map(day => (
						<View key={day.number} style={{ width: `${100 / 6}%` }}>
							<View style={{}}>
								<Text>{t(day.tKey)}</Text>
							</View>
							<View
								style={{
									flexGrow: 1,
									position: "relative",
									alignItems: "flex-end",
									backgroundColor: "#fafafa",
									fontSize: 8,
									borderLeft: "1pt solid #eaeef0",
									padding: 5,
									...(day.number === 5
										? { borderRight: "1pt solid #eaeef0" }
										: {}),
								}}
							>
								{timeranges
									.filter(
										entry =>
											entry.startDate.day() - 1 === day.number &&
											isTimerangeForSchedule({
												timerange: entry,
												schedulePeriod: schedulePeriod,
												schedulePeriods: schedulePeriods,
											})
									)
									.map((entry, index) => {
										let dayMinuteStart =
											(entry.start.hour() - 7) * 60 + entry.start.minutes()
										let style = {
											position: "absolute",
											top: (dayMinuteStart / 720) * 100 + "%",
											height: (entry.duration / 720) * 100 + "%",
											borderRadius: 3,
											padding: 3,
											width: {
												"recurrent-booking": "80%",
												"theo-hour": "100%",
											}[entry.type],
											backgroundColor: {
												"recurrent-booking": "#ffead9",
												"theo-hour": "#fdba88",
											}[entry.type],
										}
										return (
											<View key={entry.id} style={style}>
												<Text>
													{entry.start.format("HH:mm")} >{" "}
													{entry.end.format("HH:mm")}
												</Text>
												{entry.extraInfo &&
													entry.extraInfo.map(entry => (
														<View
															key={entry.label}
															style={{
																flexDirection: "row",
																flexWrap: "wrap",
															}}
														>
															<Text>{t(entry.value)}</Text>
														</View>
													))}
												<Text>{entry.customerAddress}</Text>
											</View>
										)
									})}
							</View>
						</View>
					))}
				</View>
			</View>
		))}
	</Fragment>
)

class WorkerSchedulePdfDownloader extends Component {
	state = {
		loading: true,
		hasRendered: false,
	}
	shouldComponentUpdate = () => {
		let { loading, hasRendered } = this.state
		return !loading && !hasRendered
	}
	handleChanges = changes => {
		this.props.dispatch(actions.pDFWorkerScheduleStateChange(changes))
	}
	render() {
		let {
			children,
			disable,
			timeranges,
			currentContract: { startDate, schedulePeriods },
		} = this.props
		if (disable) return children
		return (
			<PDFDownloadLink
				document={
					<Document
						onRender={() => {
							this.setState({ loading: false, hasRendered: true })
						}}
					>
						<Page
							size="A4"
							// orientation="landscape"
							style={{
								fontFamily: "Helvetica",
								fontSize: 11,
								padding: 10,
								lineHeight: 1.2,
							}}
						>
							<WorkerSchedulePdf
								t={this.context.t}
								contractStart={startDate}
								timeranges={timeranges}
								schedulePeriods={schedulePeriods}
							/>
						</Page>
					</Document>
				}
			>
				{children}
			</PDFDownloadLink>
		)
	}
}

WorkerSchedulePdfDownloader.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	component: state.redComponents.WorkerSchedulePdfDownloaderComponent,
})
export default connect(mapStateToProps)(WorkerSchedulePdfDownloader)
