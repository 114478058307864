import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import * as actions from "../actions"

import InputsChangeHandler from "./InputsChangeHandler"
import { PootsyButton, LabeledDateTime, WorkshopOrderLine } from "./FunctionalDesign"
import ModalLayout from "./ModalLayout"
import { PootsySelectInput } from "./FunctionalInputs"

class DeleteOtherActivity extends Component {
	state = {
		reach: "",
		deletionDate: "",
		showRequired: { reach: false },
	}
	UNSAFE_componentWillMount = () => {
		// ( to be able to pass it down to <InputsChangeHandler/> )
		this.setState = this.setState.bind(this)
	}
	componentWillUnmount = () => {
		this.props.dispatch(actions.editWorkshopOrderList([]))
	}
	handleDeletionDate = date => {
		this.setState(date)
		this.fetchImpactedWorkshopOrders(date)
	}	
	handleChanges = changes => {
		let {
			state: { otherActivity },
		} = this.props
		if (otherActivity.isWorkshopHour && changes.reach) {
			this.fetchImpactedWorkshopOrders(changes)
		}
		this.setState(changes)
	}
	fetchImpactedWorkshopOrders = async (changes) => {
		const {
			dispatch,
			state: { otherActivity },
		} = this.props
		let deletionDate = ""
		if (changes.deletionDate || this.state.deletionDate) { 
			deletionDate = (changes.deletionDate || this.state.deletionDate).format()
		}
		dispatch(actions.workshopStateChange({ 
			workshopId: otherActivity.workshopId, 
			pickedDate: otherActivity.bookingId.split("_")[1], 
			reach: changes.reach || this.state.reach,
			deletionDate: deletionDate
		}))
		await dispatch(
			actions.addQueries({
				workshopShiftIds: {
					urlName: "wsids",
					queryName: "filter_by_workshop_hour_ids",
					mustBeSet: true,
					defaultValue: [otherActivity.realEventId],
				},
			})
		)
		await dispatch(actions.fetchWorkshopOrders())
		dispatch(actions.resetQuery())
	}
	submit = async () => {
		let { reach, deletionDate } = this.state
		let {
			dispatch,
			state: { otherActivity },
			closeModal,
			currentQuery: {
				urlQuery: { week },
			},
		} = this.props
		// dispatch(actions.simpleFetch('/front/affiliates/bookings/' + id, init, 'DELETEBOOKING', state.requestMeta ))
		if (otherActivity.computed) {
			let body = {
				scheduled_date: otherActivity.start.format("YYYY-MM-DD"),
				real_event_id: otherActivity.realEventId,
				cancel_type: "one_occurrence",
			}
			let init = { method: "POST", body: JSON.stringify(body) }
			let { actionType } = await dispatch(
				actions.simpleFetch(
					"/front/affiliates/event_exceptions/",
					init,
					"ADD_EVENT_EXCEPTION"
				)
			)
			if (actionType === "ADD_EVENT_EXCEPTION_SUCCESS") {
				dispatch(
					actions.refetchAgendaLines(
						[otherActivity.contractID],
						otherActivity.start.format("YYYY-MM-DD")
					)
				)
			}
		} else {
			let url =
				"/front/affiliates/worker_contracts/" +
				otherActivity.affiliateWorkerID +
				"/other_activities/" +
				otherActivity.realEventId
			let init = {
				method: "DELETE",
				body: JSON.stringify({
					delete_type: reach || "all",
					delete_date: deletionDate || otherActivity.start.format("YYYY-MM-DD"),
					week: week,
					affiliate_worker_id: otherActivity.affiliateWorkerID,
				}),
			}
			dispatch(
				actions.simpleFetch(url, init, "DELETE_OTHER_ACTIVITY_FROM_AGENDA", {
					oaID: otherActivity.otherActivityID,
					workerID: otherActivity.contractID,
				})
			).then(({ actionType }) => {
				if (actionType === actions.DELETE_OTHER_ACTIVITY_FROM_AGENDA_SUCCESS) {
					dispatch(
						actions.refetchAgendaLines(
							[otherActivity.contractID],
							otherActivity.start.format()
						)
					)
				}
			})
		}
		closeModal()
	}
	render() {
		let { t } = this.context
		let {
			pagination,
			closeModal,
			state: { otherActivity },
			workshopOrders: impactedworkshopOrders,
		} = this.props
		let { showRequired, reach, deletionDate } = this.state
		let buttons = [
			<PootsyButton key="a" text={t("yes")} onClick={this.submit} />,
			<PootsyButton key="b" text={t("no")} theme="cancel" onClick={closeModal} />,
		]
		let warning
		if (otherActivity.recurrent) {
			if (reach === "all") {
				warning = t("delete_other_activity_warning_delete_all")
				if (otherActivity.isWorkshopHour) {
					warning = t("delete_workshop_hour_warning_delete_all")
				}
			} else if (reach === "all_from_date") {
				warning = t("delete_other_activity_warning_delete_all_from_date")
			}
		} else {
			warning = t("delete_other_activity_warning_ponctual")
		}
		return (
			<InputsChangeHandler onChange={this.handleChanges}>
				<ModalLayout
					title={t("delete_other_activity")}
					buttons={buttons}
					closeModal={closeModal}
					formDivClass="delete-other-activity-modal"
				>
					{otherActivity.recurrent && (
						<PootsySelectInput
							name="reach"
							defaultText={t("deletion_reach")}
							selected={reach}
							showRequired={showRequired.reach}
							options={[
								{
									label: t("delete_this_other_activity_single"),
									value: "single",
								},
								{
									label: t("delete_other_activity_all_from_date"),
									value: "all_from_date",
								},
								{ label: t("delete_other_activity_all_occurrences"), value: "all" },
							]}
						/>
					)}
					{reach === "all_from_date" && (
						<LabeledDateTime
							label={t("deletion_date")}
							showTimePicker={false}
							datePickerProps={{
								name: "deletionDate",
								value: deletionDate,
								onChange: this.handleDeletionDate,
								disableDay: current => current.isBefore(otherActivity.start),
							}}
						/>
					)}
					<div className="deletion-prompt">{warning}</div>
					{impactedworkshopOrders.length > 0 && (
						<Fragment>
							<div className="counter">
								{t("impacted_workshop_orders_counter", {
									shownCount:
										pagination.total_objects >= 10
											? 10
											: pagination.total_objects,
									totalCount: pagination.total_objects,
								})}
							</div>
							<div className="impacted-worshop-orders">
								{impactedworkshopOrders.map(entry => (
									<WorkshopOrderLine key={entry.id} workshopOrder={entry} />
								))}
							</div>
						</Fragment>
					)}
				</ModalLayout>
			</InputsChangeHandler>
		)
	}
}

const mapStateToProps = state => ({
	state: state.redComponents.deleteOtherActivityModal,
	workshopOrders: state.redData.workshopOrders,
	pagination: state.redComponents.workshopOrdersFiltersComponent.pagination,
	currentQuery: state.currentURLQuery,
})
DeleteOtherActivity.contextTypes = { t: PropTypes.func }
export default connect(mapStateToProps)(DeleteOtherActivity)
