import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { history } from "../history"

import * as actions from "../actions"

import moment from "moment"

import BookingItem from "./BookingItem"
import Paginator from "./Paginator"
import { ContentLayout } from "./FunctionalDesign"
import BookingFilters from "./BookingFilters"
import { PootsyTextInput } from "./FunctionalInputs"

class CustomerBookings extends Component {
	handleSearchChange = e => {
		this.props.dispatch(actions.bookingFiltersStateChange({ search: e.target.value }))
	}
	handleChangePage = changes => {
		this.props.dispatch(actions.bookingFiltersStateChange({ page: changes.value }))
	}
	render() {
		let { t } = this.context
		let { pagination, match, bookings, page } = this.props
		let optionsBoxes = (
			<BookingFilters
				key="a"
				match={match}
				location={window.location}
				history={history}
				filteredFilters={["customers_filter"]}
				customQueries={{
					fromDateQuery: {
						urlName: "from",
						queryName: "from_date",
						mustBeSet: true,
						defaultValue: moment().format("YYYY-MM-DD"),
					},
					toDateQuery: {
						urlName: "to",
						queryName: "to_date",
						mustBeSet: true,
						defaultValue: moment().format("YYYY-MM-DD"),
					},
					searchQuery: { urlName: "search", queryName: "filter_name", mustBeSet: false },
					ratingQuery: {
						urlName: "rating",
						queryName: "filter_rating",
						mustBeSet: false,
					},
					typeQuery: { urlName: "type", queryName: "type", mustBeSet: false },
					customerQuery: {
						urlName: "customers_filter",
						queryName: "filter_customer_contract_ids",
						mustBeSet: true,
						defaultValue: [match.params.id],
					},
				}}
			/>
		)
		let contentTop = (
			<PootsyTextInput
				customClass="bookings-search-input"
				label={t("search_booking")}
				onChange={this.handleSearchChange}
			/>
		)
		return (
			<ContentLayout optionsBoxes={optionsBoxes} contentTop={contentTop}>
				{bookings.map(entry => (
					<BookingItem key={entry.id} booking={entry} />
				))}
				<Paginator
					name="pageQuery"
					currPage={page}
					totalPages={pagination.total_pages}
					goToPage={this.handleChangePage}
				/>
			</ContentLayout>
		)
	}
}

CustomerBookings.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	bookings: state.redData.bookings,
	currentCustomer: state.redData.currentCustomer,
	filters: state.redData.bookingFilters,
	pagination: state.redComponents.customerComponent.pagination,
	page: state.redComponents.bookingFiltersComponent.page,
})
export default connect(mapStateToProps)(CustomerBookings)
