import React, { Component } from "react"
import PropTypes from "prop-types"

import ModalLayout from "./ModalLayout"
import { PootsyButton } from "./FunctionalDesign"

class CustomerPaymentReminderModal extends Component {
	state = { url: "" }

	showPaymentReminderPDF = () => {
		this.props.generatePaymentReminderPDF().then(response => {
			this.setState({ url: response.data.pdfUrl })
		})
	}

	closePDFModal = () => {
		this.setState({ url: "" })
	}
	render() {
		let { t } = this.context
		let { toggleModal, sendReminder, customerHasEmail } = this.props
		return (
			<ModalLayout
				title={t("customer_payment_reminder_modal_title")}
				closeModal={toggleModal}
				formDivClass="send-customer-payment-reminder-modal"
			>
				{customerHasEmail ? (
					<div className="customer-has-email">
						{t("send_customer_payment_reminder_modal_desc")}
						<div className="small-prompt">
							{t("customer_payment_reminder_you_can_also_check_sodexo_page_prompt")}
						</div>
						<PootsyButton key="a" text={t("confirm")} onClick={sendReminder} />
						<PootsyButton
							theme="cancel"
							text={t("show_payment_reminder")}
							onClick={() => this.showPaymentReminderPDF()}
						/>
						{this.state.url.length > 0 && (
							<ModalLayout
								title={t("secsoc_pdf")}
								closeModal={this.closePDFModal}
								formDivClass="pdf-modal"
							>
								{this.state.url && (
									<embed
										className="pdf-preview"
										src={this.state.url}
										type="application/pdf"
									/>
								)}
							</ModalLayout>
						)}
						<PootsyButton
							key="b"
							text={t("cancel")}
							theme="cancel"
							onClick={toggleModal}
						/>
					</div>
				) : (
					<div className="customer-doesnt-have-email">
						{t("cant_send_customer_payment_reminder_no_email_desc")}
						<div className="small-prompt">
							{t("customer_payment_reminder_you_can_also_check_sodexo_page_prompt")}
						</div>
						<PootsyButton
							key="b"
							text={t("cancel")}
							theme="cancel"
							onClick={toggleModal}
						/>
					</div>
				)}
			</ModalLayout>
		)
	}
}

CustomerPaymentReminderModal.contextTypes = { t: PropTypes.func }
export default CustomerPaymentReminderModal
