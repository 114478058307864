import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import * as actions from "../actions"

import { ContentLayout, OptionBox, PootsyButton } from "./FunctionalDesign"
import DraftEditor from "./DraftEditor"
import ModalLayout from "../components/ModalLayout"
import DraftTemplateToPDF from "./DraftTemplateToPDF"

import { CurlyBracesDocument } from "../svg/curlyBracesDocument"

import { translationPrefixes } from "../constants/socialSecretariesFields"

class SettingsTemplates extends Component {
	state = {
		showEditor: false,
		editionMode: false,
		editedTemplate: "",
		templatePreviewID: -1,
	}
	componentDidMount = () => {
		let { dispatch } = this.props
		let url = "/front/affiliates/document_templates"
		let init = { method: "GET" }
		dispatch(actions.simpleFetch(url, init, "FETCH_DOCUMENT_TEMPLATES"))
	}
	handleChanges = changes => {
		this.props.dispatch(actions.settingsTemplateStateChange(changes))
	}
	handleTemplateMetaChange = e => {
		let { name, value } = e.target
		this.setState({ [name]: value })
	}
	createNewTemplate = () => {
		this.setState({ showEditor: true, editedTemplate: "" })
	}
	editTemplate = id => {
		let { documentTemplates } = this.props
		let template = documentTemplates.find(entry => entry.id === id)
		this.setState({ showEditor: true, editionMode: true, editedTemplate: template })
		this.handleChanges({
			templateName: template.templateName,
			templateTarget: template.templateTarget,
		})
	}
	closeEditor = () => {
		this.setState({ showEditor: false, editionMode: false, editedTemplate: "" })
	}
	handleSubmit = ({ templateBody }) => {
		let {
			dispatch,
			component: { templateName, templateTarget },
		} = this.props
		let { editionMode, editedTemplate } = this.state
		let url = "/front/affiliates/document_templates"
		let action = "SUBMIT_DOCUMENT_TEMPLATE"
		let init = {
			method: "POST",
			body: JSON.stringify({
				template_name: templateName,
				template_body: templateBody,
				target: templateTarget,
			}),
		}
		if (editionMode) {
			url += "/" + editedTemplate.id
			init.method = "PUT"
			action = "UPDATE_DOCUMENT_TEMPLATE"
		}
		dispatch(actions.simpleFetch(url, init, action))
		this.closeEditor()
	}
	previewTemplate = e => {
		e.stopPropagation()
		let { templateid } = e.target.dataset
		this.setState({ templatePreviewID: parseInt(templateid, 10) })
	}
	closePreview = () => {
		this.setState({ templatePreviewID: -1 })
	}
	deleteTemplate = e => {
		let { dispatch } = this.props
		e.stopPropagation()
		let { templateid } = e.target.dataset
		let url = "/front/affiliates/document_templates/" + templateid
		let init = { method: "DELETE" }
		dispatch(actions.simpleFetch(url, init, "DELETE_DOCUMENT_TEMPLATE"))
	}
	render() {
		let { t } = this.context
		let { currAffiliate, documentTemplates, component } = this.props
		let { showEditor, editedTemplate, templatePreviewID } = this.state
		let optionBox = (
			<OptionBox boxTitle={t("templates")}>
				<PootsyButton text={t("new_template")} onClick={this.createNewTemplate} />
			</OptionBox>
		)
		let translationPrefix = translationPrefixes[currAffiliate.secSocName] || ""
		let variables = component.variables.general
			.concat(component.variables[component.templateTarget])
			.map(entry => ({ label: entry, value: entry }))

		if (component.templateTarget === "worker") {
			variables = variables.concat(
				component.variables.secSoc.map(entry => ({
					label: translationPrefix + entry,
					value: entry,
				}))
			)
		}

		return (
			<ContentLayout optionsBoxes={optionBox} customClass="settings-templates">
				{templatePreviewID > -1 && (
					<ModalLayout
						formDivClass="template-preview-modal"
						closeModal={this.closePreview}
					>
						<DraftTemplateToPDF
							mode="preview"
							template={documentTemplates.find(
								entry => entry.id === templatePreviewID
							)}
						/>
					</ModalLayout>
				)}
				{showEditor && (
					<DraftEditor
						templateName={component.templateName}
						templateTarget={component.templateTarget}
						closeEditor={this.closeEditor}
						handleTemplateMetaChange={this.handleChanges}
						variables={variables}
						preloadedTemplate={editedTemplate}
						onSubmit={this.handleSubmit}
					/>
				)}
				<div className="templates">
					{documentTemplates.map(entry => (
						<div
							key={entry.id}
							className={"template " + entry.target}
							onClick={this.editTemplate.bind(this, entry.id)}
						>
							<div className="column icon">
								<CurlyBracesDocument />
							</div>
							<div className="column template-name">{entry.templateName}</div>
							<div className="column template-target">{entry.target}</div>
							<div className="column preview-button">
								<PootsyButton
									className="preview-template"
									theme="none"
									text={t("preview")}
									onClick={this.previewTemplate}
									dataset={{ "data-templateid": entry.id }}
								/>
							</div>
							<div className="column delete-button">
								<PootsyButton
									className="delete-template"
									theme="fadedRed"
									text={t("delete")}
									onClick={this.deleteTemplate}
									dataset={{ "data-templateid": entry.id }}
								/>
							</div>
						</div>
					))}
				</div>
			</ContentLayout>
		)
	}
}

SettingsTemplates.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	documentTemplates: state.redData.documentTemplates,
	component: state.redComponents.settingsTemplatesComponent,
	currAffiliate: state.redData.currentAffiliate,
})
export default connect(mapStateToProps)(SettingsTemplates)
