import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import * as actions from "../actions"

import moment from "moment"

import SodexoConflictModal from "./SodexoConflictModal"
import SlideIn from "./SlideIn"
import {
	ContentLayout,
	OptionBox,
	PootsySubHeading,
	PootsyButton,
	NotificationCard,
	SmallSpinnerSection,
} from "./FunctionalDesign"
import { PootsyCheckboxInput, PootsySelectInput } from "./FunctionalInputs"
import DateFilterer from "./DateFilterer"
import Paginator from "./Paginator"
import SearchWorker from "./SearchWorker"
import SearchCustomer from "./SearchCustomer"

class SodexoConflicts extends Component {
	state = {
		isFilterActivated: false,
	}
	componentDidMount = () => {
		let { currUser } = this.props
		if (currUser.teams[0]) {
			this.handleStateChanges({ selectedSquadId: currUser.teams[0].id })
		}
	}
	fetchAllBookings = () => {
		let {
			dispatch,
			state: { selectedPootsyServiceTab },
		} = this.props
		if (selectedPootsyServiceTab === "bookings") {
			dispatch(actions.fetchPootsyBookingsForSodexoConflicts())
		} else {
			dispatch(actions.fetchPootsyWorkshopOrdersForSodexoConflicts())
		}
		dispatch(actions.fetchSodexoBookingsForSodexoConflicts())
		this.setState({ isFilterActivated: true })
	}
	handleStateChanges = changes => {
		this.props.dispatch(actions.sodexoConflictsStateChange(changes))
	}
	handleSmartFilterChange = e => {
		this.handleStateChanges({ isSmartFiltersActive: e.target.checked })
	}
	handleDateFilterChange = changes => {
		let newObj = { rangeStart: changes.from, rangeEnd: changes.to, pBookingsPage: 1 }
		this.handleStateChanges(newObj)
		this.fetchAllBookings()
	}
	selectPootsyServiceTab = tab => {
		this.handleStateChanges({ selectedPootsyServiceTab: tab })
	}
	stagePootsyBooking = bookingID => {
		let {
			dispatch,
			pootsyBookings,
			state: { isSmartFiltersActive, showSodexoStagedBooking },
		} = this.props
		this.handleStateChanges({ stagedPootsyBookingID: bookingID, showPootsyStagedBooking: true })
		if (isSmartFiltersActive && !showSodexoStagedBooking) {
			// if the sodexo booking was already staged we don't refetch the list
			let stagedBooking = pootsyBookings.find(entry => entry.id === bookingID)
			this.handleStateChanges({
				specificWorker: { name: "", sodexo: "" },
				specificCustomer: { name: " ", sodexo: "" },
				filterWorkerOrCustomer: true,
				filterSodexoBookingsCustomerSodexoRef: stagedBooking.customerSodexoRef,
				filterSodexoBookingsWorkerSodexoRef: stagedBooking.workerSodexoRef,
				sBookingsPage: 1,
			})
			dispatch(actions.fetchSodexoBookingsForSodexoConflicts())
		}
	}
	stagePootsyWorkshopOrder = woID => {
		let {
			dispatch,
			pootsyWorkshopOrders,
			state: { isSmartFiltersActive, showSodexoStagedBooking },
		} = this.props
		this.handleStateChanges({
			stagedPootsyWorkshopOrderID: woID,
			showPootsyStagedWorkshopOrder: true,
		})
		if (isSmartFiltersActive && !showSodexoStagedBooking) {
			// if the sodexo booking was already staged we don't refetch the list
			let stagedBooking = pootsyWorkshopOrders.find(entry => entry.id === woID)
			this.handleStateChanges({
				specificWorker: { name: "", sodexo: "" },
				specificCustomer: { name: " ", sodexo: "" },
				filterWorkerOrCustomer: true,
				filterSodexoBookingsCustomerSodexoRef: stagedBooking.customerSodexoRef,
				filterSodexoBookingsWorkerSodexoRef: stagedBooking.workerSodexoRef,
				sBookingsPage: 1,
			})
			dispatch(actions.fetchSodexoBookingsForSodexoConflicts())
		}
	}
	stageSodexoBooking = bookingID => {
		let {
			dispatch,
			sodexoBookings,
			state: { isSmartFiltersActive, showPootsyStagedBooking, showPootsyStagedWorkshopOrder },
		} = this.props
		this.handleStateChanges({ stagedSodexoBookingID: bookingID, showSodexoStagedBooking: true })
		if (isSmartFiltersActive && !showPootsyStagedBooking && !showPootsyStagedWorkshopOrder) {
			// if the pootsy booking was already staged we don't refetch the list
			let stagedSodexoBooking = sodexoBookings.find(entry => entry.id === bookingID)
			this.handleStateChanges({
				specificWorker: { name: "", sodexo: "" },
				specificCustomer: { name: " ", sodexo: "" },
				filterWorkerOrCustomer: true,
				filterPootsyBookingsCustomerSodexoRef: stagedSodexoBooking.customerSodexoRef,
				filterPootsyBookingsWorkerSodexoRef: stagedSodexoBooking.workerSodexoRef,
				pBookingsPage: 1,
			})
			dispatch(actions.fetchPootsyBookingsForSodexoConflicts())
		}
	}
	handleRemoveStagedPootsyService = () => {
		let {
			state: { showSodexoStagedBooking },
		} = this.props
		this.handleStateChanges({
			showPootsyStagedBooking: false,
			showPootsyStagedWorkshopOrder: false,
			filterSodexoBookingsCustomerSodexoRef: null,
			filterSodexoBookingsWorkerSodexoRef: null,
			sBookingsPage: 1,
		})
		if (this.props.state.isSmartFiltersActive && !showSodexoStagedBooking) {
			// if the sodexo booking is still staged we don't need to refetch
			this.props.dispatch(actions.fetchSodexoBookingsForSodexoConflicts())
		}
	}
	handleRemoveStagedSodexoBooking = () => {
		let {
			state: { showPootsyStagedBooking, showPootsyStagedWorkshopOrder },
		} = this.props
		this.handleStateChanges({
			showSodexoStagedBooking: false,
			filterPootsyBookingsCustomerSodexoRef: null,
			filterPootsyBookingsWorkerSodexoRef: null,
			pBookingsPage: 1,
		})
		if (
			this.props.state.isSmartFiltersActive &&
			!showPootsyStagedBooking &&
			!showPootsyStagedWorkshopOrder
		) {
			// if the pootsy booking is still staged we don't need to refetch
			this.props.dispatch(actions.fetchPootsyBookingsForSodexoConflicts())
		}
	}
	stageBookingsLink = newPootsyChanges => {
		let {
			state: { stagedLinks, stagedPootsyBookingID, stagedSodexoBookingID },
		} = this.props
		let stagedLink = {
			id: Math.floor(Math.random() * 1000000000000),
			pootsyBookingID: stagedPootsyBookingID,
			sodexoBookingID: stagedSodexoBookingID,
			changes: newPootsyChanges,
		}
		this.handleStateChanges({
			stagedLinks: [...stagedLinks, stagedLink],
			showSodexoStagedBooking: false,
			showPootsyStagedBooking: false,
			showConflictResolutionModal: false,
		})
	}
	stageWorkshopOrderLink = () => {
		let {
			state: { stagedLinks, stagedPootsyWorkshopOrderID, stagedSodexoBookingID },
		} = this.props

		let stagedLink = {
			id: Math.floor(Math.random() * 1000000000000),
			pootsyWorkshopOrderID: stagedPootsyWorkshopOrderID,
			sodexoBookingID: stagedSodexoBookingID,
		}
		this.handleStateChanges({
			stagedLinks: [...stagedLinks, stagedLink],
			showSodexoStagedBooking: false,
			showPootsyStagedBooking: false,
			showConflictResolutionModal: false,
		})
	}
	handlePootsyServiceLink = () => {
		let {
			pootsyBookings,
			sodexoBookings,
			pootsyWorkshopOrders,
			state: {
				stagedPootsyBookingID,
				stagedPootsyWorkshopOrderID,
				stagedSodexoBookingID,
				showPootsyStagedBooking,
			},
		} = this.props

		// GO FETCH BOOKING AND LINK
		let stagedSodexoBooking = sodexoBookings.find(entry => entry.id === stagedSodexoBookingID)
		let stagedService = undefined
		// COMPARE 3 things: WORKER, CUSTOMER, HOURS WORK
		if (showPootsyStagedBooking) {
			stagedService = pootsyBookings.find(entry => entry.id === stagedPootsyBookingID)
		} else {
			stagedService = pootsyWorkshopOrders.find(
				entry => entry.id === stagedPootsyWorkshopOrderID
			)
			this.stageWorkshopOrderLink(stagedService)
			return
		}
		if (
			stagedSodexoBooking.customerSodexoRef !== stagedService.customerSodexoRef ||
			stagedService.workerSodexoRef !== stagedSodexoBooking.workerSodexoRef ||
			stagedSodexoBooking.voucher_count !==
				moment(stagedService.start_time).diff(moment(stagedService.end_time), "hours")
		) {
			this.props.dispatch(
				actions.sodexoConflictModalStateChange({
					stagedServiceType: showPootsyStagedBooking ? "booking" : "workshop_order",
					stagedPootsyService: stagedService,
					stagedSodexoBooking: stagedSodexoBooking,
				})
			)
			this.handleStateChanges({ showConflictResolutionModal: true })
		}
	}
	handleRemoveStagedLink = stagedLinkID => {
		let { stagedLinks } = this.props.state
		this.handleStateChanges({
			stagedLinks: stagedLinks.filter(entry => entry.id !== stagedLinkID),
		})
	}
	handleSearchSpecificWorker = workerName => {
		let {
			state: { specificWorker },
		} = this.props
		this.handleStateChanges({
			specificWorker: { name: workerName, sodexo: "" },
			filterSodexoBookingsWorkerSodexoRef: null,
			filterPootsyBookingsWorkerSodexoRef: null,
		})
		if (specificWorker.sodexo) {
			// worker was previously selected
			this.fetchAllBookings()
		}
	}
	handleSpecificWorkerClick = worker => {
		let newSpecWorker = {
			name: worker.attributes.display_name,
			sodexo: worker.attributes.sodexo_reference,
		}
		this.handleStateChanges({
			specificWorker: newSpecWorker,
			language: "",
			filterWorkerOrCustomer: false,
		})
		this.fetchAllBookings()
	}

	handleSearchCustomer = customerName => {
		let {
			state: { specificCustomer },
		} = this.props
		this.handleStateChanges({
			specificCustomer: { name: customerName, sodexo: "" },
			filterSodexoBookingsCustomerSodexoRef: null,
			filterPootsyBookingsCustomerSodexoRef: null,
		})
		if (specificCustomer.sodexo) {
			// customer was previously selected
			this.fetchAllBookings()
		}
	}

	handleCustomerClick = customer => {
		let newSpecCustomer = {
			name: customer.attributes.display_name,
			sodexo: customer.attributes.sodexo_reference,
			voucher_type: customer.attributes.voucher_type_preference,
		}

		this.handleStateChanges({
			specificCustomer: newSpecCustomer,
			filterWorkerOrCustomer: false,
		})
		this.fetchAllBookings()
	}

	handleChangePBookingsPage = changes => {
		this.handleStateChanges({ pBookingsPage: changes.value })
		this.props.dispatch(actions.fetchPootsyBookingsForSodexoConflicts())
	}
	handleChangePWorkshopOrdersPage = changes => {
		this.handleStateChanges({ pWorkshopOrdersPage: changes.value })
		this.props.dispatch(actions.fetchPootsyWorkshopOrdersForSodexoConflicts())
	}
	handleChangeSBookingsPage = changes => {
		this.handleStateChanges({ sBookingsPage: changes.value })
		this.props.dispatch(actions.fetchSodexoBookingsForSodexoConflicts())
	}
	submitManualSync = () => {
		let url = "/front/affiliates/sodexo/sodexo_sync"
		let init = { method: "POST", body: "{}" }
		this.props.dispatch(actions.simpleFetch(url, init, "SODEXO_MANUAL_SYNC"))
	}
	submitLinks = () => {
		this.props.dispatch(actions.submitSodexoLinks())
	}
	handleChangeSquad = e => {
		this.handleStateChanges({ selectedSquadId: e.target.value, pBookingsPage: 1 })
		this.fetchAllBookings()
	}
	renderLinkableService = ({ service, onClick }) => {
		if (!service) {
			return this.renderLinkableBookingOutline()
		}
		let { t } = this.context
		let clickHandler = () => onClick(service.id)
		let finalClass = "linkable-service"
		finalClass += onClick ? " clickable" : ""
		// staged pootsy bookings & laundry orders have their date missing
		return (
			<div key={service.id} className={finalClass} onClick={clickHandler}>
				<div className="column">
					<div className="date">
						{service.deliveryDate || service.executionDate.format("YYYY-MM-DD")}
					</div>
					<div className="billable-hours">
						{t("service_vouchers") +
							": " +
							((service.voucherCount && service.voucherCount.toString()) ||
								service.bestKnownBillableSV)}
					</div>
				</div>
				<div className="column worker-customer-name">
					<div className="customer">
						{service.customerName || service.customerIdentifier}
					</div>
					<div className="worker">
						{t("cleaner") + ": " + (service.workerName || service.workerIdentifier)}
					</div>
				</div>
				{service.sodexoStatus && (
					<div className="column">
						<div className="status">{t(service.sodexoStatus)}</div>
					</div>
				)}
				{/*
					<div className="column">
						<div className="billable-hours">
							{t('billable_hours') + ': ' + booking.voucherCount}
						</div>
					</div>
				*/}
			</div>
		)
	}
	renderLinkableBookingOutline = show => {
		return (
			<SlideIn slideDirection="to-top" show={show}>
				<div className="linkable-service-outline">
					<div className="column">
						<div className="line" />
						<div className="line big" />
					</div>
					<div className="column">
						<div className="line" />
						<div className="line big" />
					</div>
				</div>
			</SlideIn>
		)
	}
	render() {
		let {
			sodexoBookings,
			pootsyBookings,
			pootsyWorkshopOrders,
			currAffiliate,
			state: {
				stagedPootsyBookingID,
				stagedPootsyWorkshopOrderID,
				stagedSodexoBookingID,
				showPootsyStagedBooking,
				showPootsyStagedWorkshopOrder,
				showSodexoStagedBooking,
				isSmartFiltersActive,
				stagedLinks,
				rangeStart,
				rangeEnd,
				today,
				pBookingsPage,
				sBookingsPage,
				pWorkshopOrdersPage,
				specificWorker,
				specificCustomer,
				pBookingsPagination,
				pWorkshopOrdersPagination,
				sBookingsPagination,
				showConflictResolutionModal,
				selectedSquadId,
				selectedPootsyServiceTab,
			},
		} = this.props
		let { t } = this.context
		let optionBoxes = (
			<OptionBox>
				<PootsySelectInput
					name="squad"
					allowEmpty={true}
					defaultText={t("squads")}
					options={currAffiliate.squads.map(entry => ({
						label: entry.name,
						value: entry.id,
					}))}
					onChange={this.handleChangeSquad}
					selected={selectedSquadId}
					// onChange={this.handleWorkshopChange}
				/>
				<PootsySubHeading text={t("date_filters")} />{" "}
				<DateFilterer
					alwaysOpened={true}
					from={rangeStart}
					to={rangeEnd}
					rangeLimitEnd={today}
					onChange={this.handleDateFilterChange}
				/>
				<PootsySubHeading text={t("smart_filters")} />
				<PootsyCheckboxInput
					name="isSmartFiltersActive"
					label={t("smart_filters")}
					checked={isSmartFiltersActive}
					onChange={this.handleSmartFilterChange}
					disabled={showPootsyStagedBooking || showSodexoStagedBooking}
				/>
				<PootsySubHeading text={t("sync")} />
				<PootsyButton text={t("manual_sync")} onClick={this.submitManualSync} />
			</OptionBox>
		)
		let stagedSodexoBooking = sodexoBookings.find(entry => entry.id === stagedSodexoBookingID)
		let stagedPootsyBooking = pootsyBookings.find(entry => entry.id === stagedPootsyBookingID)
		let stagedPootsyWorkshopOrder = pootsyWorkshopOrders.find(
			entry => entry.id === stagedPootsyWorkshopOrderID
		)
		let pbInStagedLinks = stagedLinks.map(entry => entry.pootsyBookingID)
		let sbInStagedLinks = stagedLinks.map(entry => entry.sodexoBookingID)
		let notifButtons = [
			<PootsyButton key="a" text={t("edit_booking_button")} onClick={this.submitLinks} />,
		]
		return (
			<ContentLayout customClass="sodexo-conflicts" optionsBoxes={optionBoxes}>
				{showConflictResolutionModal && (
					<SodexoConflictModal
						closeModal={() =>
							this.handleStateChanges({ showConflictResolutionModal: false })
						}
						stageBookingsLink={this.stageBookingsLink}
					/>
				)}
				<NotificationCard
					key="notif"
					text={t("notif_unsaved_changes")}
					active={stagedLinks.length > 0}
					buttons={notifButtons}
				/>
				<div className="inputs-area">
					<SearchWorker
						onChange={this.handleSearchSpecificWorker}
						onClick={this.handleSpecificWorkerClick}
						value={specificWorker && specificWorker.name}
					/>
					<SearchCustomer
						onChange={this.handleSearchCustomer}
						handleCustomerClick={this.handleCustomerClick}
						value={specificCustomer && specificCustomer.name}
					/>
				</div>
				<div className="staged-links">
					{stagedLinks.length > 0 && (
						<PootsySubHeading text={t("staged_booking_links")} />
					)}
					{stagedLinks.map(entry => (
						<SlideIn key={entry.id}>
							<div className="staged-link">
								{this.renderLinkableService({
									service: entry.pootsyBookingID
										? pootsyBookings.find(pb => pb.id === entry.pootsyBookingID)
										: pootsyWorkshopOrders.find(
												pb => pb.id === entry.pootsyWorkshopOrderID
										  ),
								})}
								<div
									className="link-line"
									onClick={() => this.handleRemoveStagedLink(entry.id)}
								/>
								{this.renderLinkableService({
									service: sodexoBookings.find(
										sb => sb.id === entry.sodexoBookingID
									),
								})}
							</div>
						</SlideIn>
					))}
				</div>
				<div className="staging-area">
					<div className="staged-pootsy-booking">
						{this.renderLinkableBookingOutline(
							!(showPootsyStagedBooking || showPootsyStagedWorkshopOrder)
						)}
						<SlideIn show={showPootsyStagedBooking || showPootsyStagedWorkshopOrder}>
							{this.renderLinkableService({
								service: stagedPootsyWorkshopOrder || stagedPootsyBooking,
								onClick: this.handleRemoveStagedPootsyService,
							})}
						</SlideIn>
					</div>
					<div className="link">
						<div
							className={
								"link-line" +
								((showPootsyStagedBooking || showPootsyStagedWorkshopOrder) &&
								showSodexoStagedBooking
									? " pink"
									: " grey")
							}
						/>
						<PootsyButton
							text={t("link_sodexo_booking")}
							onClick={this.handlePootsyServiceLink}
							disabled={
								!(showPootsyStagedBooking || showPootsyStagedWorkshopOrder) ||
								!showSodexoStagedBooking
							}
						/>
					</div>
					<div className="staged-sodexo-booking">
						{this.renderLinkableBookingOutline(!showSodexoStagedBooking)}
						<SlideIn show={showSodexoStagedBooking}>
							{this.renderLinkableService({
								service: stagedSodexoBooking,
								onClick: this.handleRemoveStagedSodexoBooking,
							})}
						</SlideIn>
					</div>
				</div>
				{currAffiliate.workshopAddOn && (
					<div className="tabs">
						{/* center that */}
						<div
							className={
								"tab bookings " +
								(selectedPootsyServiceTab === "bookings" ? "selected" : "")
							}
							onClick={() => this.selectPootsyServiceTab("bookings")}
						>
							{t("bookings")}
						</div>
						<div
							className={
								"tab workshop-orders " +
								(selectedPootsyServiceTab === "workshop_orders" ? "selected" : "")
							}
							onClick={() => this.selectPootsyServiceTab("workshop_orders")}
						>
							{t("workshop_orders")}
						</div>
					</div>
				)}
				<div className="conflict-boxes">
					<div className="pootsy-services">
						{selectedPootsyServiceTab === "bookings" && (
							<div className="pootsy-bookings-tab">
								{!this.state.isFilterActivated && (
									<PootsySubHeading text={t("placeholder_bookings_list")} />
								)}
								{this.state.isFilterActivated && (
									<div>
										<PootsySubHeading text={t("pootsy_bookings")} />
										<SmallSpinnerSection
											isLoading={this.props.state.fetchingBookings}
											sectionClass={"small-spinner-section"}
										>
											{pootsyBookings
												.filter(
													entry =>
														!(
															showPootsyStagedBooking &&
															entry.id === stagedPootsyBookingID
														) && !pbInStagedLinks.includes(entry.id)
												)
												.map((entry, i) => (
													<SlideIn key={entry.id}>
														{this.renderLinkableService({
															service: entry,
															onClick: this.stagePootsyBooking,
														})}
													</SlideIn>
												))}
										</SmallSpinnerSection>
										<Paginator
											currPage={pBookingsPage}
											totalPages={pBookingsPagination.total_pages}
											goToPage={this.handleChangePBookingsPage}
										/>
									</div>
								)}
							</div>
						)}
						{selectedPootsyServiceTab === "workshop_orders" && (
							<div className="pootsy-workshop-orders-tab">
								{!this.state.isFilterActivated && (
									<PootsySubHeading text={t("placeholder_workshopOrders_list")} />
								)}
								{this.state.isFilterActivated && (
									<div>
										<PootsySubHeading text={t("pootsy_workshop_orders")} />
										<SmallSpinnerSection
											isLoading={this.props.state.fetchingBookings}
											sectionClass={"small-spinner-section"}
										>
											{pootsyWorkshopOrders
												.filter(
													entry =>
														!(
															showPootsyStagedWorkshopOrder &&
															entry.id === stagedPootsyWorkshopOrderID
														) && !pbInStagedLinks.includes(entry.id)
												)
												.map((entry, i) => (
													<SlideIn key={entry.id}>
														{this.renderLinkableService({
															service: entry,
															onClick: this.stagePootsyWorkshopOrder,
														})}
													</SlideIn>
												))}
										</SmallSpinnerSection>
										<Paginator
											currPage={pWorkshopOrdersPage}
											totalPages={pWorkshopOrdersPagination.total_pages}
											goToPage={this.handleChangePWorkshopOrdersPage}
										/>
									</div>
								)}
							</div>
						)}
					</div>
					<div className="sodexo-bookings">
						<PootsySubHeading text={t("sodexo_bookings")} />
						<SmallSpinnerSection
							isLoading={this.props.state.fetchingSodexoBookings}
							sectionClass={"small-spinner-section"}
						>
							{sodexoBookings
								.filter(
									entry =>
										!(
											showSodexoStagedBooking &&
											entry.id === stagedSodexoBookingID
										) && !sbInStagedLinks.includes(entry.id)
								)
								.map((entry, i) => (
									<SlideIn key={entry.id}>
										{this.renderLinkableService({
											service: entry,
											onClick: this.stageSodexoBooking,
										})}
									</SlideIn>
								))}
						</SmallSpinnerSection>
						<Paginator
							currPage={sBookingsPage}
							totalPages={sBookingsPagination.total_pages}
							goToPage={this.handleChangeSBookingsPage}
						/>
					</div>
				</div>
			</ContentLayout>
		)
	}
}

SodexoConflicts.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	state: state.redComponents.sodexoConflictsComponent,
	pootsyBookings: state.redData.bookings,
	pootsyWorkshopOrders: state.redData.workshopOrders,
	sodexoBookings: state.redData.sodexoBookings,
	currAffiliate: state.redData.currentAffiliate,
	currUser: state.redData.currentUser,
})
export default connect(mapStateToProps)(SodexoConflicts)
