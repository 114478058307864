import { env } from "./config"
import * as SentryInit from "@sentry/browser"
import { Integrations } from "@sentry/tracing"

if (process.env.NODE_ENV !== "test") {
	SentryInit.init({
		dsn: "https://bdeb80df450e4e838d765607ccc6f851@o48201.ingest.sentry.io/103244",
		environment: env,
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: 0.7,
	})
}

export const Sentry = SentryInit
