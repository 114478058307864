import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Route } from "react-router-dom"
import { showWorkshop, showDimona } from "../config"

import * as actions from "../actions"

import SettingsProfile from "./SettingsProfile"
import SettingsCompany from "./SettingsCompany"
import SettingsUsers from "./SettingsUsers"
import SettingsAffiliatePage from "./SettingsAffiliatePage"
import SettingsSecSoc from "./SettingsSecSoc"
import SettingsSodexo from "./SettingsSodexo"
import SettingsTeams from "./SettingsTeams"
import SettingsTemplates from "./SettingsTemplates"
import SettingsSecSocMapping from "./SettingsSecSocMapping"
import SettingsMe from "./SettingsMe"

import { NavLink } from "./NavLink"
import InputsChangeHandler from "./InputsChangeHandler"
import { PageLayout, PootsyButton, NotificationCard, internetIcon } from "./FunctionalDesign"
import ModalLayout from "./ModalLayout"
import { PootsyTextInput, PootsyRadioInputs } from "./FunctionalInputs"
import ImpactedBookingsModal from "./ImpactedBookingsModal"

import profileIcon from "../static/images/profile-icon.png"
import companySettingsIcon from "../static/images/company-settings-icon.png"
import usersIcon from "../static/images/users-icon.png"

import SettingsWorkshop from "./SettingsWorkshop"
import SettingsDimona from "./SettingsDimona"

// Affiliate user properties:
//       property :title
//       property :first_name
//       property :last_name
//       property :email

class Settings extends Component {
	state = {
		userTitle: "",
		firstName: "",
		lastName: "",
		email: "",
		showRequired: { userTitle: false, firstName: false, lastName: false, email: false },
	}
	componentDidMount = () => {
		// ( to be able to pass it down to <InputsChangeHandler/> )
		let { currAffiliate } = this.props
		this.setState = this.setState.bind(this)
		if (currAffiliate.secSocAddOn && currAffiliate.socialSecretaryID) {
			this.fetchSecSocSpecificFields()
		}
		this.fetchAffiliate()
	}
	fetchAffiliate = () => {
		let { dispatch } = this.props
		let url = "/front/affiliates/affiliates"
		let init = { method: "GET" }
		dispatch(actions.simpleFetch(url, init, actions.FETCH_AFFILIATE))
	}
	fetchSecSocSpecificFields = () => {
		let { dispatch } = this.props
		let url = "/front/affiliates/social_secretary_additional_field_values"
		let init = { method: "GET" }
		dispatch(actions.simpleFetch(url, init, "FETCH_SECSOC_SPECIFIC_FIELDS"))
	}
	toggleNewUserModal = () => {
		let { settingsComponent, dispatch } = this.props
		dispatch(
			actions.settingsUIChange({
				showNewAffiliateUserModal: !settingsComponent.showNewAffiliateUserModal,
			})
		)
		this.setState(state => ({
			userTitle: "",
			firstName: "",
			lastName: "",
			email: "",
		}))
	}
	resetSettingsData = () => {
		let { dispatch } = this.props
		dispatch(actions.resetSettings())
	}
	submitSettingsUpdate = () => {
		let { dispatch } = this.props
		dispatch(actions.submitSettingsUpdate({ newDefaultLang: "" }))
	}
	submitNewUser = () => {
		let { userTitle, firstName, lastName, email } = this.state
		let init = {
			method: "POST",
			body: JSON.stringify({
				title: userTitle,
				first_name: firstName,
				last_name: lastName,
				email,
			}),
		}
		this.props.dispatch(
			actions.simpleFetch("/front/affiliates/users", init, "NEWAFFILIATEUSER")
		)
		this.toggleNewUserModal()
	}
	render() {
		let { t } = this.context
		let {
			match,
			currUser,
			settingsComponent: { showNewAffiliateUserModal },
			impactedBookingsModal: { showImpactedBookingsModal },
			currAffiliate: { editedKeys, sodexoAddOn, workshopAddOn, dimonaAddOn },
			currLocalFields: { isEdited: isLocalFieldsEdited },
		} = this.props
		let { showRequired, userTitle, firstName, lastName, email } = this.state
		let pageHeaderLeft = <h1 className="page-title settings">{t("settings")}</h1>
		let pageHeaderRight = (
			<PootsyButton text={t("add_user")} onClick={this.toggleNewUserModal} />
		)
		let linksArray = [
			{
				label: t("profile"),
				link: "profile",
				icon: <img alt="icon" className="settings-subnav-icon" src={profileIcon} />,
			},
			{
				label: t("company_settings"),
				link: "company-settings",
				icon: <img alt="icon" className="settings-subnav-icon" src={companySettingsIcon} />,
			},
			{ label: t("company_page_settings"), link: "page-settings", icon: internetIcon },
			{ label: t("squads"), link: "teams", icon: "" },
			{ label: t("templates"), link: "templates" },
			{ label: t("sec_soc_mapping"), link: "secsoc-mapping" },
			{
				label: t("secsoc_settings"),
				link: "company-secsoc",
				icon: <img alt="icon" className="settings-subnav-icon" src={companySettingsIcon} />,
			},
			{ label: t("my_profile"), link: "me" },
		]
		if (showWorkshop && workshopAddOn) {
			linksArray.push({
				label: t("workshop_settings"),
				link: "workshop",
				icon: <img alt="icon" className="settings-subnav-icon" src={companySettingsIcon} />,
			})
		}
		// Add addOnControlHere
		if (showDimona && dimonaAddOn) {
			linksArray.push({
				label: t("dimona_settings"),
				link: "dimona",
				icon: <img alt="icon" className="settings-subnav-icon" src={companySettingsIcon} />,
			})
		}
		if (currUser.isMaster) {
			linksArray.push({
				label: t("users"),
				link: "users",
				icon: <img alt="icon" className="settings-subnav-icon" src={usersIcon} />,
			})
		}
		if (sodexoAddOn) {
			linksArray.push({
				label: t("sodexo_settings"),
				link: "sodexo-connection",
				icon: <img alt="icon" className="settings-subnav-icon" src={companySettingsIcon} />,
			})
		}
		let subNavLinks = linksArray.map(entry => (
			<NavLink
				key={entry.link}
				className="settings-subnav-link"
				to={"/settings/" + entry.link}
			>
				{entry.icon}
				{entry.label}
			</NavLink>
		))
		let notifButtons = [
			<PootsyButton key="a" text={t("save_changes")} onClick={this.submitSettingsUpdate} />,
			<PootsyButton
				key="b"
				text={t("reset_changes")}
				theme="cancel"
				onClick={this.resetSettingsData}
			/>,
		]
		subNavLinks.push(
			<NotificationCard
				key="notif"
				text={t("notif_unsaved_changes")}
				active={editedKeys.length > 0 || isLocalFieldsEdited}
				buttons={notifButtons}
			/>
		)
		return (
			<PageLayout
				headerLeft={pageHeaderLeft}
				headerRight={pageHeaderRight}
				subNavLinks={subNavLinks}
				customClass="settings-page"
			>
				<InputsChangeHandler
					customClass="settings-change-handler"
					setParentState={this.setState}
				>
					{showImpactedBookingsModal && (
						<ImpactedBookingsModal
							onSubmit={this.submitSettingsUpdate}
							triggerActionExpected={actions.UPDATESETTINGS_FAILURE}
						/>
					)}
					{showNewAffiliateUserModal && (
						<ModalLayout
							title={t("new_user")}
							formDivClass="settings-add-user-modal"
							closeModal={this.toggleNewUserModal}
						>
							<PootsyRadioInputs
								groupName="userTitle"
								groupLabel={t("user_title")}
								showRequired={showRequired.userTitle}
								selected={userTitle}
								options={[
									{ id: "radio-mr", value: "mr", label: t("mr") },
									{ id: "radio-mrs", value: "mrs", label: t("mrs") },
								]}
							/>
							<PootsyTextInput
								name="firstName"
								label={t("first_name")}
								value={firstName}
							/>
							<PootsyTextInput
								name="lastName"
								label={t("last_name")}
								value={lastName}
							/>
							<PootsyTextInput name="email" label={t("email")} value={email} />
							<div className="buttons">
								<PootsyButton text={t("save")} onClick={this.submitNewUser} />
								<PootsyButton
									text={t("cancel")}
									theme="cancel"
									onClick={this.toggleNewUserModal}
								/>
							</div>
						</ModalLayout>
					)}
					<Route path={match.path + "/profile"} component={SettingsProfile} />
					<Route path={match.path + "/company-settings"} component={SettingsCompany} />
					<Route path={match.path + "/page-settings"} component={SettingsAffiliatePage} />
					<Route path={match.path + "/teams"} component={SettingsTeams} />
					<Route path={match.path + "/templates"} component={SettingsTemplates} />
					{currUser.isMaster && (
						<Route path={match.path + "/users"} component={SettingsUsers} />
					)}
					<Route path={match.path + "/company-secsoc"} component={SettingsSecSoc} />
					{sodexoAddOn && (
						<Route
							path={match.path + "/sodexo-connection"}
							component={SettingsSodexo}
						/>
					)}
					<Route
						path={match.path + "/secsoc-mapping"}
						component={SettingsSecSocMapping}
					/>
					<Route path={match.path + "/me"} component={SettingsMe} />
					{showWorkshop && workshopAddOn && (
						<Route path={match.path + "/workshop"} component={SettingsWorkshop} />
					)}
					{showDimona && (
						<Route path={match.path + "/dimona"} component={SettingsDimona} />
					)}
				</InputsChangeHandler>
			</PageLayout>
		)
	}
}

Settings.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currAffiliate: state.redData.currentAffiliate,
	currUser: state.redData.currentUser,
	currLocalFields: state.redData.currentAffiliateLocalisedFields,
	settingsComponent: state.redComponents.settingsComponent,
	impactedBookingsModal: state.redComponents.impactedBookingsModal,
})
export default connect(mapStateToProps)(Settings)
