import { stringifyQuery } from "../helpers/qs"
import moment from "moment"
import {
	simpleFetch,
	submitOtherActivity,
	submitWorkshopHour,
	submitEditWorkshopHour,
	SUBMIT_OTHER_ACTIVITY_SUCCESS,
	SUBMIT_WORKSHOP_HOUR_SUCCESS,
	SUBMIT_EDIT_WORKSHOP_HOUR_SUCCESS,
	denormalizeImpactedBookingActions,
	submitEditOtherActivity,
} from "./index"

export const AGENDA_STATE_CHANGE = "AGENDA_STATE_CHANGE"
export const agendaStateChange = changes => ({ type: AGENDA_STATE_CHANGE, changes })

export const RESET_AGENDA = "RESET_AGENDA"
export const resetAgenda = () => ({ type: RESET_AGENDA })

export const RESET_AGENDA_DATA = "RESET_AGENDA_DATA"
export const resetAgendaData = () => ({ type: RESET_AGENDA_DATA })

export const DELETE_OTHERACTIVITY_MODAL_INIT = "DELETE_OTHERACTIVITY_MODAL_INIT"
export const deleteOtherActivityModalInit = otherActivity => ({
	type: DELETE_OTHERACTIVITY_MODAL_INIT,
	otherActivity,
})

export const AGENDA_ABSENCES_DELETE_WARNING = "AGENDA_ABSENCES_DELETE_WARNING"
export const absencesCancelWarningAgenda = absenceId => ({
	type: AGENDA_ABSENCES_DELETE_WARNING,
	absenceId,
})

export const updateAgendaDimensions = scrollWrapper => (dispatch, getState) => {
	// 190 is the size of the leftmost column
	// 84 is the total number of hours displayed in the agenda
	const el = scrollWrapper.current
	let hoursWidth = (el.getBoundingClientRect().width - 190) / 84
	dispatch(
		agendaStateChange({
			hoursWidth: hoursWidth,
			minutesWidth: hoursWidth / 60,
		})
	)
}

export const RESET_AGENDA_COMPONENT_FILTERS = "RESET_AGENDA_COMPONENT_FILTERS"
export const resetAgendaComponentFilters = () => ({ type: RESET_AGENDA_COMPONENT_FILTERS })

export const AGENDA_ABSENCE_CHANGE = "AGENDA_ABSENCE_CHANGE"
export const agendaAbsenceChange = changes => ({ type: AGENDA_ABSENCE_CHANGE, changes })

export const AGENDA = "AGENDA"
export const AGENDA_REQUEST = "AGENDA_REQUEST"
export const AGENDA_SUCCESS = "AGENDA_SUCCESS"
export const AGENDA_FAILURE = "AGENDA_FAILURE"

export const AGENDA_ROWS_REQUEST = "AGENDA_ROWS_REQUEST"
export const AGENDA_ROWS_SUCCESS = "AGENDA_ROWS_SUCCESS"
export const AGENDA_ROWS_FAILURE = "AGENDA_ROWS_FAILURE"

export const DELETE_OTHER_ACTIVITY_FROM_AGENDA_REQUEST = "DELETE_OTHER_ACTIVITY_FROM_AGENDA_REQUEST"
export const DELETE_OTHER_ACTIVITY_FROM_AGENDA_SUCCESS = "DELETE_OTHER_ACTIVITY_FROM_AGENDA_SUCCESS"
export const DELETE_OTHER_ACTIVITY_FROM_AGENDA_FAILURE = "DELETE_OTHER_ACTIVITY_FROM_AGENDA_FAILURE"

export const CREATEABSENCEFROMAGENDA_REQUEST = "CREATEABSENCEFROMAGENDA_REQUEST"
export const CREATEABSENCEFROMAGENDA_SUCCESS = "CREATEABSENCEFROMAGENDA_SUCCESS"
export const CREATEABSENCEFROMAGENDA_FAILURE = "CREATEABSENCEFROMAGENDA_FAILURE"

export const UPDATEABSENCEFROMAGENDA_REQUEST = "UPDATEABSENCEFROMAGENDA_REQUEST"
export const UPDATEABSENCEFROMAGENDA_SUCCESS = "UPDATEABSENCEFROMAGENDA_SUCCESS"
export const UPDATEABSENCEFROMAGENDA_FAILURE = "UPDATEABSENCEFROMAGENDA_FAILURE"

export const SUBMITEXTRAAVAIL_REQUEST = "SUBMITEXTRAAVAIL_REQUEST"
export const SUBMITEXTRAAVAIL_SUCCESS = "SUBMITEXTRAAVAIL_SUCCESS"
export const SUBMITEXTRAAVAIL_FAILURE = "SUBMITEXTRAAVAIL_FAILURE"

export const CANCELABSENCE_FROMAGENDA_REQUEST = "CANCELABSENCE_FROMAGENDA_REQUEST"
export const CANCELABSENCE_FROMAGENDA_SUCCESS = "CANCELABSENCE_FROMAGENDA_SUCCESS"
export const CANCELABSENCE_FROMAGENDA_FAILURE = "CANCELABSENCE_FROMAGENDA_FAILURE"

export const DELETEHOLIDAY_FROMWORKER_REQUEST = "DELETEHOLIDAY_FROMWORKER_REQUEST"
export const DELETEHOLIDAY_FROMWORKER_SUCCESS = "DELETEHOLIDAY_FROMWORKER_SUCCESS"
export const DELETEHOLIDAY_FROMWORKER_FAILURE = "DELETEHOLIDAY_FROMWORKER_FAILURE"

export const RESTOREHOLIDAY_FROMWORKER_REQUEST = "RESTOREHOLIDAY_FROMWORKER_REQUEST"
export const RESTOREHOLIDAY_FROMWORKER_SUCCESS = "RESTOREHOLIDAY_FROMWORKER_SUCCESS"
export const RESTOREHOLIDAY_FROMWORKER_FAILURE = "RESTOREHOLIDAY_FROMWORKER_FAILURE"

export const SUBMITDIRECTBOOKINGFROMAGENDA_REQUEST = "SUBMITDIRECTBOOKINGFROMAGENDA_REQUEST"
export const SUBMITDIRECTBOOKINGFROMAGENDA_SUCCESS = "SUBMITDIRECTBOOKINGFROMAGENDA_SUCCESS"
export const SUBMITDIRECTBOOKINGFROMAGENDA_FAILURE = "SUBMITDIRECTBOOKINGFROMAGENDA_FAILURE"

export const EVENT_AGENDA = "EVENT_AGENDA"
export const EVENT_AGENDA_REQUEST = "EVENT_AGENDA_REQUEST"
export const EVENT_AGENDA_SUCCESS = "EVENT_AGENDA_SUCCESS"
export const EVENT_AGENDA_FAILURE = "EVENT_AGENDA_FAILURE"
export const ADD_AGENDA_ROWS = "ADD_AGENDA_ROWS"
export const ADD_AGENDA_ROWS_REQUEST = "ADD_AGENDA_ROWS_REQUEST"
export const ADD_AGENDA_ROWS_SUCCESS = "ADD_AGENDA_ROWS_SUCCESS"
export const ADD_AGENDA_ROWS_FAILURE = "ADD_AGENDA_ROWS_FAILURE"
export const ADD_PREVIOUS_EVENT_AGENDA_ROWS = "ADD_PREVIOUS_EVENT_AGENDA_ROWS"
export const ADD_PREVIOUS_EVENT_AGENDA_ROWS_REQUEST = "ADD_PREVIOUS_EVENT_AGENDA_ROWS_REQUEST"
export const ADD_PREVIOUS_EVENT_AGENDA_ROWS_SUCCESS = "ADD_PREVIOUS_EVENT_AGENDA_ROWS_SUCCESS"
export const ADD_PREVIOUS_EVENT_AGENDA_ROWS_FAILURE = "ADD_PREVIOUS_EVENT_AGENDA_ROWS_FAILURE"
export const ADD_NEXT_EVENT_AGENDA_ROWS = "ADD_NEXT_EVENT_AGENDA_ROWS"
export const ADD_NEXT_EVENT_AGENDA_ROWS_REQUEST = "ADD_NEXT_EVENT_AGENDA_ROWS_REQUEST"
export const ADD_NEXT_EVENT_AGENDA_ROWS_SUCCESS = "ADD_NEXT_EVENT_AGENDA_ROWS_SUCCESS"
export const ADD_NEXT_EVENT_AGENDA_ROWS_FAILURE = "ADD_NEXT_EVENT_AGENDA_ROWS_FAILURE"
export const AGENDA_WORKER_FULL_MONTH = "AGENDA_WORKER_FULL_MONTH"
export const AGENDA_WORKER_FULL_MONTH_REQUEST = "AGENDA_WORKER_FULL_MONTH_REQUEST"
export const AGENDA_WORKER_FULL_MONTH_SUCCESS = "AGENDA_WORKER_FULL_MONTH_SUCCESS"
export const AGENDA_WORKER_FULL_MONTH_FAILURE = "AGENDA_WORKER_FULL_MONTH_FAILURE"
export const fetchEventsAgenda = ({ resetRows = false, fetchPage } = {}) => async (
	dispatch,
	getState
) => {
	let {
		currentURLQuery: { realQuery },
		redComponents: {
			agenda: {
				currentPage,
				workersFilters,
				customersFilters,
				fullMonthAffiliateWorkerId,
				filterOnlyAvailbleThs,
				postFetchAction,
			},
		},
	} = getState()
	let init = { method: "GET" }
	let params = { ...realQuery }
	params.page = fetchPage || currentPage || 1
	if (params.page < 1) {
		return
	}
	let actionType = EVENT_AGENDA
	if (resetRows) {
		params.page = 1
		dispatch(agendaStateChange({ currentPage: 1, pagesFetched: [] }))
	}
	if (fetchPage) {
		actionType =
			fetchPage < currentPage ? ADD_PREVIOUS_EVENT_AGENDA_ROWS : ADD_NEXT_EVENT_AGENDA_ROWS
	}
	if (workersFilters.length > 0) {
		params.affiliate_workers_ids = workersFilters.map(entry => entry.affiliateWorkerId)
	}
	if (customersFilters.length > 0) {
		params.customer_contracts_ids = customersFilters.map(entry => entry.customerContractId)
	}
	if (filterOnlyAvailbleThs && !fullMonthAffiliateWorkerId) {
		params.filter_available_ths = true
	}
	if (fullMonthAffiliateWorkerId) {
		actionType = AGENDA_WORKER_FULL_MONTH
		params.customer_contracts_ids = []
		params.page = 1
		params.affiliate_workers_ids = [fullMonthAffiliateWorkerId]
		params.date_for_week = undefined
		params.date_for_month = moment(realQuery.date_for_week)
			.startOf("month")
			.format("YYYY-MM-DD")
	}
	let url = "/front/affiliates/events" + stringifyQuery(params)
	let response = await dispatch(simpleFetch(url, init, actionType))
	if (postFetchAction) {
		dispatch(postFetchAction())
	}
	return response
}

export const fetchAgenda = ({ resetRows = false } = {}) => (dispatch, getState) => {
	let {
		currentURLQuery: { realQuery },
		redComponents: {
			agenda: { currentPage, workersFilters, customersFilters },
		},
	} = getState()
	let init = { method: "GET" }
	let params = { ...realQuery }
	params.page = currentPage
	let actionType = ADD_AGENDA_ROWS
	if (resetRows) {
		actionType = AGENDA
		params.page = 1
		dispatch(agendaStateChange({ currentPage: 1 }))
	}
	if (workersFilters.length > 0) {
		params.affiliate_workers_ids = workersFilters.map(entry => entry.affiliateWorkerId)
	}
	if (customersFilters.length > 0) {
		params.customer_contracts_ids = customersFilters.map(entry => entry.customerContractId)
	}
	let url = "/front/affiliates/agenda" + stringifyQuery(params)
	return dispatch(simpleFetch(url, init, actionType))
}

export const REPLACE_EVENT_AGENDA_ROWS = "REPLACE_EVENT_AGENDA_ROWS"
export const REPLACE_EVENT_AGENDA_ROWS_REQUEST = "REPLACE_EVENT_AGENDA_ROWS_REQUEST"
export const REPLACE_EVENT_AGENDA_ROWS_SUCCESS = "REPLACE_EVENT_AGENDA_ROWS_SUCCESS"
export const REPLACE_EVENT_AGENDA_ROWS_FAILURE = "REPLACE_EVENT_AGENDA_ROWS_FAILURE"
export const refetchAgendaLines = (contractIDs, week) => async (dispatch, getState) => {
	let {
		redData: {
			agenda: { schedules, weekStart: currentAgendaWeekStart },
		},
		redComponents: {
			agenda: { fullMonthAffiliateWorkerId, postFetchAction },
		},
	} = getState()
	let affiliateWorkerIDs = schedules.reduce((acc, entry) => {
		if (contractIDs.includes(entry.cleaner.id)) {
			acc.push(entry.cleaner.affiliateWorkerId)
		}
		return acc
	}, [])
	contractIDs = schedules.reduce((acc, entry) => {
		if (affiliateWorkerIDs.includes(entry.cleaner.affiliateWorkerId)) {
			acc.push(entry.cleaner.id)
		}
		return acc
	}, [])
	let actionType = REPLACE_EVENT_AGENDA_ROWS
	let params = { date_for_week: week, worker_contract_ids: contractIDs, page: 1 }
	if (fullMonthAffiliateWorkerId) {
		actionType = AGENDA_WORKER_FULL_MONTH
		params.customer_contracts_ids = []
		params.worker_contract_ids = null
		params.page = 1
		params.affiliate_workers_ids = [fullMonthAffiliateWorkerId]
		params.date_for_week = undefined
		params.date_for_month = currentAgendaWeekStart.startOf("month").format("YYYY-MM-DD")
	}
	let init = { method: "GET" }
	let url = "/front/affiliates/events" + stringifyQuery(params)
	await dispatch(simpleFetch(url, init, actionType))
	if (postFetchAction) {
		dispatch(postFetchAction())
	}
}

export const SUBMIT_LOCK_WEEK = "SUBMIT_LOCK_WEEK"
export const SUBMIT_LOCK_WEEK_REQUEST = "SUBMIT_LOCK_WEEK_REQUEST"
export const SUBMIT_LOCK_WEEK_SUCCESS = "SUBMIT_LOCK_WEEK_SUCCESS"
export const SUBMIT_LOCK_WEEK_FAILURE = "SUBMIT_LOCK_WEEK_FAILURE"
export const submitLockAgendaWeek = ({ workerId, affiliateWorkerId }) => async (
	dispatch,
	getState
) => {
	let {
		redData: {
			agenda: { weekStart, schedules },
		},
	} = getState()
	let schedule = schedules.find(entry => entry.cleaner.id === workerId)
	let body = {
		id: schedule && schedule.weekData.closed_week.id,
		closed_week: weekStart.format("YYYY-MM-DD"),

		affiliate_worker_id: affiliateWorkerId,
	}
	let init = {
		method: "POST",
	}
	let url = "/front/affiliates/closed_weeks"
	if (schedule.weekData.closed_week.id) {
		init.method = "PUT"
		body.reopened = schedule.weekData.closed_week.closed
	}
	init.body = JSON.stringify(body)

	await dispatch(simpleFetch(url, init, SUBMIT_LOCK_WEEK))
}

export const submitOtherActivityForContractID = (
	otherActivity,
	contractID,
	affiliateWorkerId,
	week
) => async (dispatch, getState) => {
	let { actionType } = await dispatch(
		submitOtherActivity(otherActivity, contractID, affiliateWorkerId)
	)

	if (actionType !== SUBMIT_OTHER_ACTIVITY_SUCCESS) {
		return
	}
	let currWeek = moment(week, "YYYY-MM-DD")
	dispatch(refetchAgendaLines([contractID], currWeek.format()))
}

export const submitEditOtherActivityForContractID = (
	otherActivity,
	contractID,
	affiliateWorkerId,
	week
) => async (dispatch, getState) => {
	let { actionType } = await dispatch(
		submitEditOtherActivity(otherActivity, contractID, affiliateWorkerId)
	)

	if (actionType !== SUBMIT_OTHER_ACTIVITY_SUCCESS) {
		return
	}
	let currWeek = moment(week, "YYYY-MM-DD")
	dispatch(refetchAgendaLines([contractID], currWeek.format()))
}

export const submitWorkshopHourFromAgenda = (contractID, week) => async (dispatch, getState) => {
	let { actionType } = await dispatch(submitWorkshopHour())

	if (actionType !== SUBMIT_WORKSHOP_HOUR_SUCCESS) {
		return
	}
	let currWeek = moment(week, "YYYY-MM-DD")
	dispatch(refetchAgendaLines([contractID], currWeek.format()))
}

export const submitEditWorkshopHourFromAgenda = (contractID, week, id) => async (
	dispatch,
	getState
) => {
	let { actionType } = await dispatch(submitEditWorkshopHour(id))

	if (actionType !== SUBMIT_EDIT_WORKSHOP_HOUR_SUCCESS) {
		return
	}
	let currWeek = moment(week, "YYYY-MM-DD")
	dispatch(refetchAgendaLines([contractID], currWeek.format()))
}

export const submitAbsenceFromAgenda = data => async (dispatch, getState) => {
	let {
		redData: { agenda },
		redComponents: { agenda: agendaUI },
	} = getState()
	let init = { method: "POST", body: JSON.stringify(data) }
	let url =
		"/front/affiliates/affiliate_workers/" + agendaUI.lastActiveRange.awi + "/unavailabilities"
	let action = "CREATEABSENCEFROMAGENDA"
	if (agenda.currentAbsence.editedAbsenceID) {
		init.method = "PUT"
		url += "/" + agenda.currentAbsence.editedAbsenceID
		action = "UPDATEABSENCEFROMAGENDA"
	}
	let { actionType } = await dispatch(simpleFetch(url, init, action))
	if (![CREATEABSENCEFROMAGENDA_SUCCESS, UPDATEABSENCEFROMAGENDA_SUCCESS].includes(actionType)) {
		return
	}
	let impactedBookingsActions = data.impacted_bookings_actions || []
	let workersToRefetch = [
		agendaUI.lastActiveRange.ci,
		...impactedBookingsActions.reduce((acc, entry) => {
			if (entry.action === "update_worker") {
				acc.push(entry.modify_params.worker_contract_id)
			}
			return acc
		}, []),
	]
	dispatch(refetchAgendaLines(workersToRefetch, agenda.weekStart.format()))
	dispatch(
		agendaStateChange({
			showImpactedBookingsModalForAbsence: false,
			showAbsenceModal: false,
			activeTooltipRange: { ti: -1, ci: -1 },
		})
	)
	dispatch(
		agendaAbsenceChange({
			activityCode: "",
			activityCategory: "",
			absenceStart: "",
			absenceType: "",
			absenceEnd: "",
			absenceNote: "",
			editedAbsenceID: "",
			formC32ANumber: "",
			mustCallOnss: true,
		})
	)
}

export const SUBMIT_OTHER_ACTIVITY_WITH_IMPACTED_BOOKINGS =
	"SUBMIT_OTHER_ACTIVITY_WITH_IMPACTED_BOOKINGS"
export const SUBMIT_OTHER_ACTIVITY_WITH_IMPACTED_BOOKINGS_REQUEST =
	"SUBMIT_OTHER_ACTIVITY_WITH_IMPACTED_BOOKINGS_REQUEST"
export const SUBMIT_OTHER_ACTIVITY_WITH_IMPACTED_BOOKINGS_SUCCESS =
	"SUBMIT_OTHER_ACTIVITY_WITH_IMPACTED_BOOKINGS_SUCCESS"
export const SUBMIT_OTHER_ACTIVITY_WITH_IMPACTED_BOOKINGS_FAILURE =
	"SUBMIT_OTHER_ACTIVITY_WITH_IMPACTED_BOOKINGS_FAILURE"
export const submitOAWithImpactedBookings = (contractID, affiliateWorkerId, week) => async (
	dispatch,
	getState
) => {
	let {
		redData: { impactedBookings },
		redComponents: {
			impactedBookingsModal: { otherActivity, forceUnlink },
		},
	} = getState()
	let url = "/front/affiliates/worker_contracts/" + contractID + "/other_activities"
	let init = {
		method: "POST",
		body: JSON.stringify({
			affiliate_worker_id: affiliateWorkerId,
			worker_contract_id: contractID,
			start_date: otherActivity.startDate.format("YYYY-MM-DD"),
			end_date:
				otherActivity.dayPeriod !== "0" && otherActivity.endDate.isValid()
					? otherActivity.endDate.format("YYYY-MM-DD")
					: undefined,
			start_time: otherActivity.start.format("HH:mm"),
			end_time: otherActivity.end.format("HH:mm"),
			day_period: otherActivity.dayPeriod,
			affiliate_note: otherActivity.note,
			activity_category: otherActivity.activityCategory,
			activity_code: otherActivity.activityCode,
			form_c32a_number: otherActivity.formC32ANumber,
			must_call_onss: otherActivity.mustCallOnss,
			force_unlink: forceUnlink,
			impacted_bookings_actions: denormalizeImpactedBookingActions(impactedBookings),
		}),
	}
	let { actionType } = await dispatch(
		simpleFetch(url, init, SUBMIT_OTHER_ACTIVITY_WITH_IMPACTED_BOOKINGS)
	)
	if (actionType === SUBMIT_OTHER_ACTIVITY_WITH_IMPACTED_BOOKINGS_SUCCESS) {
		let currWeek = moment(week, "YYYY-MM-DD")
		let linesToFetch = [contractID].concat(
			Object.keys(impactedBookings).reduce((acc, key) => {
				let entity = impactedBookings[key]
				if (entity.replacementWorkerID) {
					acc.push(entity.replacementWorkerID)
				}
				return acc
			})
		)

		dispatch(refetchAgendaLines(linesToFetch, currWeek.format()))
		dispatch(agendaStateChange({ showImpactedBookingsModalForOA: false }))
	}
}
