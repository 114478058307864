import React, { Component } from "react"
import PropTypes from "prop-types"
import { sortArrayByName } from "../helpers/arraySortByName"
import Filter from "./Filter"
import { PootsySelectInput } from "./FunctionalInputs"

export default class BackEndFilters extends Component {
	render() {
		let { t } = this.context
		let { filters, filterInputChange, selectInputChange } = this.props
		return filters.map(entry => {
			if (entry.input === "filter") {
				return (
					<Filter
						key={entry.name}
						label={t(entry.name + "_label")}
						name={entry.name}
						possibleValues={sortArrayByName(entry.values, "label")}
						currentFilters={entry.value}
						onChange={filterInputChange}
					/>
				)
			} else if (entry.input === "select") {
				return (
					<PootsySelectInput
						key={entry.name}
						name={entry.name}
						defaultText={t(entry.name + "_label")}
						allowEmpty={true}
						onChange={selectInputChange}
						selected={entry.value || ""}
						options={entry.values.map(v => ({ label: t(v.label), value: v.value }))}
					/>
				)
			}
			return null
		})
	}
}

BackEndFilters.contextTypes = { t: PropTypes.func }
