import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment"

import { LabeledDateTime, PootsyButton } from "./FunctionalDesign"

export default class DateFilterer extends Component {
	state = {
		from: "",
		to: "",
	}
	componentDidMount = () => {
		this.setState({ from: this.props.from, to: this.props.to })
	}
	handleDateChange = changes => {
		let { from, to } = this.state
		if (changes.from && changes.from.isAfter(to)) {
			changes.to = moment(changes.from)
		} else if (changes.to && changes.to.isBefore(from)) {
			changes.from = moment(changes.to)
		}
		this.setState(changes)
		if (this.props.updateFilters) {
			if (changes.from) {
				this.props.updateFilters({
					name: "fromDateQuery",
					value: changes.from.format("YYYY-MM-DD"),
				})
			}
			if (changes.to) {
				this.props.updateFilters({
					name: "toDateQuery",
					value: changes.to.format("YYYY-MM-DD"),
				})
			}
		}
	}
	handleShortcutClick = e => {
		let { onChange, rangeLimitEnd } = this.props
		let { shortcut } = e.target.dataset
		if (shortcut === "thisMonth") {
			let now = moment()
			let to = moment(now).endOf("month")
			onChange({
				from: moment(now).startOf("month"),
				to: to.isAfter(rangeLimitEnd) ? moment(rangeLimitEnd) : to,
			})
		} else if (shortcut === "lastMonth") {
			let lastMonth = moment().subtract(1, "month")
			onChange({
				from: moment(lastMonth).startOf("month"),
				to: moment(lastMonth).endOf("month"),
			})
		}
	}
	filterByDate = () => {
		this.props.onChange(this.state)
	}
	render() {
		let { t } = this.context
		let { rangeLimitEnd } = this.props
		let { from, to } = this.state
		return (
			<div className="date-filterer">
				<div className="date-filterer-label">{t("date_filters")}</div>
				<div className="date-filterer-inputs">
					<div className="shortcuts">
						<div
							className="shortcut"
							onClick={this.handleShortcutClick}
							data-shortcut="lastMonth"
						>
							{t("last_month")}
						</div>

						<div
							className="shortcut"
							onClick={this.handleShortcutClick}
							data-shortcut="thisMonth"
						>
							{t("this_month")}
						</div>
					</div>
					<div className="range-inputs">
						<LabeledDateTime
							label={t("from")}
							showTimePicker={false}
							datePickerProps={{
								value: from,
								name: "from",
								onChange: this.handleDateChange,
								calendarStyle: {
									top: "120%",
									left: -50,
									transform: "scale(.8, .8)",
								},
								disableDay: day => day.isAfter(to),
							}}
						/>
						<LabeledDateTime
							label={t("to")}
							showTimePicker={false}
							datePickerProps={{
								value: this.state.to,
								name: "to",
								onChange: this.handleDateChange,
								calendarStyle: {
									top: "120%",
									left: -150,
									transform: "scale(.8, .8)",
								},
								disableDay: day => day.isAfter(rangeLimitEnd, "day"),
							}}
						/>
					</div>
					{!this.props.skipFilter && (
						<div className="button-filter">
							<PootsyButton
								text={t("filter")}
								size="small"
								onClick={this.filterByDate}
							/>
						</div>
					)}
				</div>
			</div>
		)
	}
}

const noop = () => {}
DateFilterer.defaultProps = {
	defaultStart: moment(),
	defaultEnd: moment().add(2, "week"),
	rangeLimitEnd: moment.invalid(),
	onChange: noop,
}
DateFilterer.contextTypes = { t: PropTypes.func }
