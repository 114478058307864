import React, { Fragment, useContext } from "react"
import { formatMinutes } from "../helpers/timeFormatting"
import moment from "moment"

import { LocaleContext } from "../index.js"

import recurrenceIcon from "../static/images/recurrence-icon.png"
import CheckMark from "../svg/checkMark"
import AgendaTimerangeDropdown from "./AgendaTimerangeDropdown"
import { useTimerangeClickHandler } from "./AgendaHooks"
import { NoCSSInfoBox } from "./FunctionalDesign"

const getTimeRangeDimensions = (tr, weekStart, minutesWidth) => {
	let displayedWeekEnd = moment(weekStart)
		.add(6, "days")
		.hour(19)
	let trStartInWeek = tr.start.isBefore(weekStart) ? moment(weekStart) : tr.start
	let trEndInWeek = displayedWeekEnd.isBefore(tr.end) ? moment(displayedWeekEnd) : tr.end
	let minutesFromWeekStart = trStartInWeek.diff(weekStart, "minutes")
	// if the range starts before the week, default the distance from weekstart to 0 minutes
	let timeRangeStart = moment(trStartInWeek)
	if (minutesFromWeekStart < 0) {
		minutesFromWeekStart = 0
		timeRangeStart = moment(weekStart)
	}
	let startMinute = timeRangeStart.hour() * 60 + timeRangeStart.minutes()
	let durationWithinWeek = trEndInWeek.diff(trStartInWeek, "minutes")
	// Removing the offset caused by the agenda day cells starting at 7h, default to 0 offset if before 8h
	let dayOffset = startMinute <= 420 ? 0 : 420 - startMinute
	let leftPosInMinutes = 720 * timeRangeStart.diff(weekStart, "days") - dayOffset
	let daySpan = moment(trEndInWeek)
		.startOf("day")
		.diff(moment(trStartInWeek).startOf("day"), "days")
	let widthInMinutes = durationWithinWeek - daySpan * 720
	if (startMinute < 420) {
		// Here removing the minutes before 8h
		widthInMinutes -= 420 - startMinute
	}
	if (trEndInWeek.hour() > 18) {
		// 1140 minutes === 19 Hours (i.e removing all the minutes beyond 19h)
		widthInMinutes -= trEndInWeek.hour() * 60 + trEndInWeek.minutes() - 1140
	}
	let leftPos = leftPosInMinutes * minutesWidth
	let width = widthInMinutes * minutesWidth
	return { left: leftPos, width }
}

const BookingTimerangeInfo = ({ tr, displayTime }) => {
	const t = useContext(LocaleContext)
	let showCheckmark = tr.showCheckmark
	let blueFill = tr.blueFill
	let doubleMark = tr.doubleMark
	return (
		<Fragment>
			{displayTime && (
				<div className="displayed-duration">
					{formatMinutes(tr.duration, t("hours_shorthand"))}
				</div>
			)}
			{<div className="customer-name">{tr.customerName[0] + ". " + tr.customerLastName}</div>}
			{!showCheckmark && tr.voucherType && (
				<div className="voucher-type-indicator">{tr.voucherType[0]}</div>
			)}
			{showCheckmark && <CheckMark doubleMark={doubleMark} blueFill={blueFill} />}
		</Fragment>
	)
}

const TimerangeTooltip = ({ tr, displayStartTime, displayEndTime, secSocEvents, workshops }) => {
	const t = useContext(LocaleContext)
	let code = secSocEvents.codes.find(entry => entry.id === tr.code) || {}
	return (
		<div className="tooltip">
			{tr.type !== "absence" && displayStartTime + " > " + displayEndTime}
			{tr.type === "outsideContract" && <div>{t("contract_end")}</div>}
			{tr.type === "extra_availability" && <div>{t("extra_availability")}</div>}
			{tr.type === "absence" && (
				<div className="absence-info">
					<div>{t("absence")}</div>
					<div>
						{t("start")}: {tr.unavStartDate.format("DD/MM HH:mm")}
					</div>
					<div>
						{t("end")}: {tr.unavEndDate.format("DD/MM HH:mm")}
					</div>
					<div>{tr.affiliateNote}</div>
					<div>{t(code.category_id)}</div>
					<div>
						{code.id} - {t(code.label)}
					</div>
				</div>
			)}
			{tr.highlightInfos && (
				<div className="highlight-infos">
					{tr.highlightInfos
						.filter(entry => entry.label === "distance")
						.map(entry => (
							<NoCSSInfoBox
								key={entry.label}
								label={t(entry.label, { km: entry.value })}
								value={t(entry.value)}
							/>
						))}
				</div>
			)}
			{(tr.type === "other_activity" || tr.isWorkshopHour) && (
				<div className="other-activity-info">
					<div className="recurrence">
						{
							{
								0: t("once"),
								7: t("every_week"),
								14: t("every_two_weeks"),
								28: t("every_four_weeks"),
							}[tr.dayPeriod]
						}
						{tr.dayPeriod !== 0 && (
							<img
								alt="recurrence"
								className="recurrence-icon"
								src={recurrenceIcon}
							/>
						)}
					</div>
					<div className="category">{t(code.category_id)}</div>
					<div className="code">{code.id}</div>
					<div className="affiliateNote">{tr.affiliateNote}</div>
					{tr.isWorkshopHour &&
						(() => {
							let workshop = workshops.find(
								entry => Number(entry.id) === tr.workshopId
							)
							if (workshop) {
								return <div className="workshop">{workshop.workshopName}</div>
							}
						})()}
					{tr.eventEndDate && (
						<div>
							{t("end_date")}: {tr.eventEndDate.format("DD/MM/YYYY")}
						</div>
					)}
				</div>
			)}
			{tr.type === "booking" && (
				<div className="booking-info">
					<div className="booking-info-recurrence">
						{
							{
								0: t("once"),
								7: t("every_week"),
								14: t("every_two_weeks"),
								28: t("every_four_weeks"),
							}[tr.dayPeriod]
						}
						{tr.recurrent && (
							<img
								alt="recurrence"
								className="booking-info-recurrence-icon"
								src={recurrenceIcon}
							/>
						)}
					</div>
					<div>{t(tr.voucherType)}</div>
					<div>{tr.customerName}</div>
					<div>{tr.customerSodexoReference}</div>
					<div>{tr.customerTelephone}</div>
					<div>
						{t("worker_sodexo_reference")}: {tr.workerSodexoReference}
					</div>
					<div>
						{t("worker_social_secretary_identifier")}: {tr.workerSocialSecretary}
					</div>
					<div>{tr.address}</div>
					<div>{tr.note}</div>
					{tr.eventEndDate && (
						<div>
							{t("end_date")}: {tr.eventEndDate.format("DD/MM/YYYY")}
						</div>
					)}
					{tr.code && (
						<div>
							{t("ss_code")}: {tr.code}
						</div>
					)}
				</div>
			)}
		</div>
	)
}

const AgendaTimerange = ({
	tr,
	trIndex,
	cleaner,
	showTrDropdown,
	secSocEvents,
	weekStart,
	minutesWidth,
	workshops,
}) => {
	let lineID = {}
	lineID["data-cleanerid"] = cleaner.id
	lineID["data-affiliateworkerid"] = cleaner.affiliateWorkerId
	let { left, width } = getTimeRangeDimensions(tr, weekStart, minutesWidth)
	let displayStartTime = tr.start.format("HH:mm")
	let displayEndTime = tr.end.format("HH:mm")
	let displayTime = true
	if (
		tr.type === "emptyRange" ||
		width < 30 ||
		(tr.start.hour() < 9 && tr.duration > 719) ||
		tr.overlapCount > -1
	) {
		displayTime = false
	}
	let style = { left, width }
	if (tr.overlapCount > -1) {
		style.height = 100 / (tr.overlapCount + 1)
		style.top = (tr.overlapIndex - 1) * style.height + "%"
		style.height += "%"
	}
	if (tr.exceptionType === "explicit_cancel") {
		tr.type = "availability_cancellation"
	}
	let className = "clickable-range " + tr.type + " " + (tr.customClass || "")
	if (tr.type === "booking" && tr.dayPeriod === 0) {
		className += " ponctual"
		if (tr.isReplacement) {
			className += " replacement"
		}
	}
	const handleTimeRangeClick = useTimerangeClickHandler()
	return (
		<span className="agenda-filled-range" style={style}>
			<div
				className={className}
				{...lineID}
				data-timerangeindex={trIndex}
				data-timerangeid={tr.id}
				onClick={handleTimeRangeClick}
			>
				{displayTime && <div className="displayed-time">{displayStartTime}</div>}
				{displayTime && <div className="displayed-time">{displayEndTime}</div>}
				{displayTime && tr.type === "other_activity" && (
					<div className="code">{tr.code}</div>
				)}
				{tr.type === "booking" && (
					<BookingTimerangeInfo tr={tr} displayTime={displayTime} />
				)}
				{tr.highlightInfos && tr.highlightInfos.find(e => e.label !== "distance") && (
					<div className="distance">
						{tr.highlightInfos.find(e => e.label === "distance").value}km
					</div>
				)}
			</div>
			<TimerangeTooltip
				tr={tr}
				displayStartTime={displayStartTime}
				displayEndTime={displayEndTime}
				secSocEvents={secSocEvents}
				workshops={workshops}
			/>
			{showTrDropdown && <AgendaTimerangeDropdown tr={tr} width={width} cleaner={cleaner} />}
			{tr.highlightColors && tr.highlightColors.length > 0 && (
				<div className="highlight-colors">
					{tr.highlightColors.map(color => (
						<div
							key={color}
							className="highlight-color"
							style={{ background: color, borderColor: color }}
						></div>
					))}
				</div>
			)}
		</span>
	)
}

export default AgendaTimerange
