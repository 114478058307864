import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import LinkedPSVsDisplayer from "./LinkedPSVsDisplayer"
import {
	PootsyButton,
	OptionBox,
	PootsyMultipleValuesBox,
	PootsyValueDisplayer,
} from "./FunctionalDesign"
import { NavLink } from "./NavLink"

class BookingDetailsOptionBox extends Component {
	render() {
		let { t } = this.context
		let {
			booking,
			toggleDeletePSVModal,
			toggleAddSVModal,
			togglePSVReminderModal,
			toggleESVReminderModal,
			validateSodexoCreationStatus,
			linkedCustomers,
			toggleDirectBookingModificationModal,
		} = this.props
		let occurrenceCustomer = booking.customer
		let defaultCustomer = occurrenceCustomer
		if (
			booking.parentEvent &&
			booking.parentEvent.data.attributes.customer_contract_id !==
				booking.customer.customer_contract_id
		) {
			let customerFound = linkedCustomers.find(
				entry =>
					Number(entry.id) === booking.parentEvent.data.attributes.customer_contract_id
			)
			if (customerFound) {
				defaultCustomer = {
					customer_contract_id: Number(customerFound.id),
					display_name: customerFound.attributes.display_name,
				}
			}
		}
		let customerIsDefault = occurrenceCustomer === defaultCustomer
		let customerInfo = [
			{
				label: t("customer"),
				values: [
					`${occurrenceCustomer.display_name} ${
						customerIsDefault ? t("default_customer") : ""
					}`,
					booking.customer.telephone,
					<a href={"mailto:" + booking.customer.email}>{booking.customer.email}</a>,
					<NavLink
						to={"/customers/" + defaultCustomer.customer_contract_id + "/profile"}
						className="customer-profile-link"
					>
						{t("link_to_customer_profile")}
					</NavLink>,
				],
			},
		]
		if (!customerIsDefault) {
			customerInfo.push({
				label: t("default_customer"),
				values: [
					defaultCustomer.display_name,
					<NavLink
						to={"/customers/" + booking.customer.customer_contract_id + "/profile"}
						className="customer-profile-link"
					>
						{t("link_to_customer_profile")}
					</NavLink>,
				],
			})
		}
		let cleanerInfo = [
			{
				label: t("cleaner"),
				values: [
					booking.worker.display_name,
					booking.worker.telephone,
					<a href={"mailto:" + booking.worker.email}>{booking.worker.email}</a>,
					booking.worker.sodexo,
				],
			},
		]
		let payReminderInfo = booking.paymentReminders.map(reminder => {
			return (
				<NavLink to={"/customers/payment_reminder/" + reminder.id}>
					{t(reminder.origin) + " - " + moment(reminder.created_at).format("DD/MM/YYYY")}
				</NavLink>
			)
		})

		if (booking.workerLanguage) {
			cleanerInfo[0].values.push(t(booking.workerLanguage))
		}
		return (
			<OptionBox key="a" boxTitle={t("booking_info")}>
				<PootsyButton
					text={t("direct_booking_modification")}
					onClick={toggleDirectBookingModificationModal}
					size="small"
				/>
				<PootsyMultipleValuesBox
					values={[
						{
							label: t("sodexo_work_id"),
							values: [booking.sodexoWorkId || t("null")],
						},
					]}
				/>
				<PootsyMultipleValuesBox values={customerInfo} />
				<div className="booking-details-cleaner-details">
					<PootsyMultipleValuesBox values={cleanerInfo} />
					<NavLink
						to={"/cleaners/" + booking.worker.affiliate_worker_id + "/profile"}
						className="cleaner-profile-link"
					>
						{t("link_to_worker_profile")}
					</NavLink>
				</div>
				{booking.voucherType === "electronic" &&
					(booking.sodexoStatus ? (
						<div className="sodexo-status">
							<div className="synced-badge">{t("booking_synced_with_sodexo")}</div>
							<div className="label">{t("sodexo_status")}</div>
							<div className="value">{t(booking.sodexoStatus)}</div>
							<div className="label">{t("sodexo_nb_of_hours")}</div>
							<div className="value">{t(booking.sodexoDuration)}</div>
							{booking.sodexoStatus === "Created" && (
								<PootsyButton
									text={t("validate_sodexo_creation_button")}
									onClick={validateSodexoCreationStatus}
									theme="green"
								/>
							)}
							{booking.sodexoStatus === "InsufficientCustomerCredit" && (
								<PootsyButton
									text={t("send_esv_reminder")}
									onClick={toggleESVReminderModal}
								/>
							)}
						</div>
					) : (
						// - if not sodexo > stay like today
						<div className="sodexo-not-synced">
							{!booking.customer.sodexo_reference && (
								<div className="customer-without-sodexo-warning">
									{t("customer_without_sodexo_warning")}
								</div>
							)}
						</div>
					))}
				{booking.voucherType === "paper" && (
					<div className="paper-vouchers-added">
						<LinkedPSVsDisplayer
							expectedCount={booking.voucherCount}
							currentPSVs={booking.pServiceVouchersAffiliate}
							onPSVClick={toggleDeletePSVModal}
						/>
						<PootsyButton text={t("add_psv")} onClick={toggleAddSVModal} />
						{booking.voucherCount !== booking.pServiceVouchers.length &&
							["finished", "finished_without_confirmation"].includes(
								booking.serviceDeliveryStatus
							) && (
								<PootsyButton
									text={t("send_psv_reminder")}
									onClick={togglePSVReminderModal}
								/>
							)}
						{booking.pServiceVouchers.length > 0 && (
							<Fragment>
								<PootsyMultipleValuesBox
									values={[
										{
											label: t("added_psv_label_by_workers_title"),
											values: booking.pServiceVouchers.map(psv => {
												let code = psv.code
												if (!psv.code) {
													code = t("non_scanned_psv")
												}
												return (
													<div
														className="psv"
														onClick={toggleDeletePSVModal}
														data-psvid={psv.id}
													>
														<span
															onClick={toggleDeletePSVModal}
															className="tooltip"
														>
															{t("click_to_remove")}
														</span>
														{code}
													</div>
												)
											}),
										},
									]}
								/>
							</Fragment>
						)}
					</div>
				)}
				<div className="booking-details-payment-details">
					{booking.paymentReminders.length > 0 && (
						<div className="payment-reminder-box">
							<PootsyValueDisplayer
								label={t("reminders_sent")}
								values={payReminderInfo}
							/>
						</div>
					)}
				</div>
				{booking.endDate && (
					<Fragment>
						<PootsyValueDisplayer
							label={t("end_date_booking")}
							values={[moment(booking.endDate).format("DD/MM/YYYY")]}
						/>
					</Fragment>
				)}
			</OptionBox>
		)
	}
}

BookingDetailsOptionBox.contextTypes = { t: PropTypes.func }
export default BookingDetailsOptionBox
