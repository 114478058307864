import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import moment from "moment"

import { TimesheetTotalHoursMonth } from "./FunctionalDesign"
import { formatMinutes } from "../helpers/timeFormatting"
import TimesheetDay from "./TimesheetDay"

import pdfIcon from "../static/images/pdf-icon.png"

export default class TimesheetsMainContent extends Component {
	humanizeMinutesCount = minutes => {
		let { t } = this.context
		minutes = Math.abs(Math.round(minutes))
		return formatMinutes(minutes, t("hours_shorthand"))
	}
	exportWeek = m => {
		this.props.exportWeek(m.endOf("isoweek"))
	}
	currentWeekIsClosed = date => {
		let { currTimesheet } = this.props
		let endOfWeek = moment(date.endOf("isoweek"))
		return currTimesheet.timesheetInfo.intermediateClosureDates.some(entry =>
			entry.isSame(endOfWeek, "day")
		)
	}
	render() {
		let { t } = this.context
		let {
			editMode,
			currTimesheet,
			secSocEvents,
			handleScheduleModif,
			toggleNewEventModal,
			softDeleteEvent,
			downloadWorkerWeekPdf,
		} = this.props
		return (
			<Fragment>
				<div className="secsoc-schedules-event-labels">
					<div className="labels">
						<div className="label date">
							<span>{t("date")}</span>
						</div>
						<div className="label type">
							<span>{t("type")}</span>
						</div>
						<div className="label start">
							<span>{t("start")}</span>
						</div>
						<div className="label end">
							<span>{t("end")}</span>
						</div>
						<div className="label group hours">
							<div className="group-title">{t("hours")}</div>
							<div className="group-labels">
								<div className="group-label planned">{t("hours_planned")}</div>
								<div className="group-label real">{t("hours_real")}</div>
							</div>
						</div>
						<div className="label group vouchers">
							<div className="group-title">{t("vouchers")}</div>
							<div className="group-labels">
								<div className="group-label expected">{t("vouchers_expected")}</div>
								<div className="group-label received">{t("vouchers_received")}</div>
							</div>
						</div>
						<div className="label group customer">
							<div className="group-title">{t("customer")}</div>
							<div className="group-labels">
								<div className="group-label name">{t("name")}</div>
								<div className="group-label address">{t("address")}</div>
							</div>
						</div>
						<div className="label km">
							<span>{t("km")}</span>
						</div>
						{editMode && <div className="label delete-button-padder" />}
					</div>
				</div>
				{currTimesheet.codesStats.month && (
					<TimesheetTotalHoursMonth
						currTimesheet={currTimesheet}
						customClass="secsoc-schedule-month-banner"
					/>
				)}
				<div className="secsoc-schedules events-wrapper">
					{Object.keys(currTimesheet.eventsForMonth)
						.sort((a, b) => (moment(b).isBefore(moment(a)) ? 1 : -1))
						.map(day => {
							let events = currTimesheet.eventsForMonth[day]
							let d = moment(day, "YYYY-MM-DD")
							let weekStartKey = moment(d)
								.startOf("isoweek")
								.format("YYYY-MM-DD")
							let showWeekBanner = d.isoWeekday() === 1 || d.date() === 1
							let timesheetID = currTimesheet.timesheetInfo.timesheetID
								? currTimesheet.timesheetInfo.timesheetID
								: -1

							let weeklyTotalHours = ""
							if (showWeekBanner) {
								if (
									currTimesheet.worker.contractVariability === "variable_variable"
								) {
									weeklyTotalHours = t("timesheet_weekly_total_durations_no_th", {
										allCodesTotal: this.humanizeMinutesCount(
											currTimesheet.codesStats[weekStartKey].sum_all_codes
										),
										minutesPlannedPerWeek: this.humanizeMinutesCount(
											currTimesheet.worker.minutesPlannedPerWeek
										),
									})
								} else if (
									currTimesheet.worker.contractVariability === "variable"
								) {
									let minutesPlannedForWeek = ""
									let schedulePeriod = currTimesheet.worker.schedulePeriods.find(
										sp => sp.applies_for.includes(weekStartKey)
									)
									if (schedulePeriod) {
										minutesPlannedForWeek = schedulePeriod.max_work_mins
									}
									weeklyTotalHours = t("timesheet_weekly_total_durations_no_th", {
										allCodesTotal: this.humanizeMinutesCount(
											currTimesheet.codesStats[weekStartKey].sum_all_codes
										),
										minutesPlannedPerWeek: this.humanizeMinutesCount(
											minutesPlannedForWeek
										),
									})
								} else {
									weeklyTotalHours = t("timesheet_weekly_total_durations", {
										allCodesTotal: this.humanizeMinutesCount(
											currTimesheet.codesStats[weekStartKey].sum_all_codes
										),
										thTotal: this.humanizeMinutesCount(
											currTimesheet.codesStats[weekStartKey].sum_ths
										),
									})
								}
							}
							return (
								<Fragment key={day}>
									{showWeekBanner && (
										<div className="secsoc-schedule-week-banner">
											<div className="week-label">
												{t("week") + " " + d.week()}
											</div>
											<div className="week-infos">
												<div className="weekly-total-hours">
													{weeklyTotalHours}
												</div>
												<div className="vacant-hours">
													{currTimesheet.codesStats[weekStartKey]
														.sum_ths !== 0 &&
														t("timesheet_weekly_vacant_hours", {
															vacantHours: this.humanizeMinutesCount(
																currTimesheet.codesStats[
																	weekStartKey
																].sum_ths -
																	currTimesheet.codesStats[
																		weekStartKey
																	].sum_all_codes
															),
														})}
												</div>
												{Object.keys(
													currTimesheet.codesStats[weekStartKey].codes_sum
												).map(entry => (
													<div key={entry} className="week-info">
														<div className="label">{t(entry)}:</div>
														&nbsp;
														<div className="value">
															{this.humanizeMinutesCount(
																currTimesheet.codesStats[
																	weekStartKey
																].codes_sum[entry]
															)}
														</div>
													</div>
												))}
												<div className="vouchers-separator" />
												{Object.keys(
													currTimesheet.codesStats[weekStartKey].vouchers
												).map(voucher => (
													<div key={voucher} className="week-info">
														<div className="label">
															{t(`${voucher}_expected`)}:
														</div>
														&nbsp;
														<div className="value">
															{
																currTimesheet.codesStats[
																	weekStartKey
																].vouchers[voucher]
															}
														</div>
													</div>
												))}
											</div>
											<img
												title={t("sec_soc_download_week_sched")}
												className="dl-pdf-icon-button"
												alt="pdf"
												src={pdfIcon}
												data-weekstart={d.startOf("isoweek").format()}
												onClick={downloadWorkerWeekPdf}
											/>
										</div>
									)}
									<TimesheetDay
										editMode={editMode}
										day={day}
										timesheetID={timesheetID}
										workerId={currTimesheet.worker.id}
										events={events}
										handleScheduleModif={handleScheduleModif}
										toggleNewEventModal={toggleNewEventModal}
										secSocEvents={secSocEvents}
										softDeleteEvent={softDeleteEvent}
										intermediateClosureDates={
											currTimesheet.timesheetInfo.intermediateClosureDates
										}
									/>
								</Fragment>
							)
						})}
				</div>
			</Fragment>
		)
	}
}

TimesheetsMainContent.contextTypes = { t: PropTypes.func }
