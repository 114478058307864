import jsPDF from "jspdf"
import "jspdf-autotable"
import { simpleFetch } from "./index"
import { stringifyQuery, parseQuery } from "../helpers/qs"
import { formatMinutes } from "../helpers/timeFormatting"
import { history } from "../history"
import moment from "moment"

import { normalizeTimesheetsData } from "../normalizers/Normalizers"

export const TIMESHEETS_STATE_CHANGE = "TIMESHEETS_STATE_CHANGE"
export const timesheetsStateChange = changes => ({ type: TIMESHEETS_STATE_CHANGE, changes })

export const RESET_TIMESHEETS_COMPONENT = "RESET_TIMESHEETS_COMPONENT"
export const resetTimesheetsComponent = () => ({ type: RESET_TIMESHEETS_COMPONENT })

export const TIMESHEETS_DATACHANGE = "TIMESHEETS_DATACHANGE"
export const timesheetsDataChange = (scheduleIndex, day, eventIndex, newEvent, changes) => ({
	type: TIMESHEETS_DATACHANGE,
	scheduleIndex,
	day,
	eventIndex,
	newEvent,
	changes,
})

export const TIMESHEETS_COSTLINE_CHANGE = "TIMESHEETS_COSTLINE_CHANGE"
export const timesheetsCostLineChange = ({
	scheduleID,
	costLineType,
	costLineID,
	newCostLine,
}) => ({
	type: TIMESHEETS_COSTLINE_CHANGE,
	scheduleID,
	costLineType,
	costLineID,
	newCostLine,
})

export const TIMESHEET_INFO_UPDATED = "TIMESHEET_INFO_UPDATED"
export const updateAllTimesheets = updatedSchedules => ({
	type: TIMESHEET_INFO_UPDATED,
	updatedSchedules,
})

export const TIMESHEET_COMMENTS_CHANGE = "TIMESHEET_COMMENTS_CHANGE"
export const timesheetCommentsChange = (timesheetID, changes) => ({
	type: TIMESHEET_COMMENTS_CHANGE,
	timesheetID,
	changes,
})

export const ADD_TIMESHEET_EVENT = "ADD_TIMESHEET_EVENT"
export const addTimesheetEvent = (event, day, workerId) => ({
	type: ADD_TIMESHEET_EVENT,
	event,
	day,
	workerId,
})

export const SOFTDELETE_TIMESHEET_EVENT = "SOFTDELETE_TIMESHEET_EVENT"
export const softDeleteTimesheetEvent = (day, weekNr, workerIndex, eventIndex) => ({
	type: SOFTDELETE_TIMESHEET_EVENT,
	day,
	weekNr,
	workerIndex,
	eventIndex,
})

export const NEW_TIMESHEET_EVENT_MODAL_STATE_CHANGE = "NEW_TIMESHEET_EVENT_MODAL_STATE_CHANGE"
export const newTimesheetEventModalStateChange = changes => ({
	type: NEW_TIMESHEET_EVENT_MODAL_STATE_CHANGE,
	changes,
})

export const TIMESHEET_EXPORT_MODAL_STATE_CHANGE = "TIMESHEET_EXPORT_MODAL_STATE_CHANGE"
export const timesheetExportModalStateChange = changes => ({
	type: TIMESHEET_EXPORT_MODAL_STATE_CHANGE,
	changes,
})

export const FETCHSCHEDULES_REQUEST = "FETCHSCHEDULES_REQUEST"
export const FETCHSCHEDULES_SUCCESS = "FETCHSCHEDULES_SUCCESS"
export const FETCHSCHEDULES_FAILURE = "FETCHSCHEDULES_FAILURE"

export const SCHEDULE_PDF_REQUEST = "SCHEDULE_PDF_REQUEST"
export const SCHEDULE_PDF_SUCCESS = "SCHEDULE_PDF_SUCCESS"
export const SCHEDULE_PDF_FAILURE = "SCHEDULE_PDF_FAILURE"

export const SUBMITTIMESHEETUPDATE_REQUEST = "SUBMITTIMESHEETUPDATE_REQUEST"
export const SUBMITTIMESHEETUPDATE_SUCCESS = "SUBMITTIMESHEETUPDATE_SUCCESS"
export const SUBMITTIMESHEETUPDATE_FAILURE = "SUBMITTIMESHEETUPDATE_FAILURE"

export const TIMESHEET_EXPORT_REQUEST = "TIMESHEET_EXPORT_REQUEST"
export const TIMESHEET_EXPORT_SUCCESS = "TIMESHEET_EXPORT_SUCCESS"
export const TIMESHEET_EXPORT_FAILURE = "TIMESHEET_EXPORT_FAILURE"

export const CLOSE_TIMESHEET_MONTH_REQUEST = "CLOSE_TIMESHEET_MONTH_REQUEST"
export const CLOSE_TIMESHEET_MONTH_SUCCESS = "CLOSE_TIMESHEET_MONTH_SUCCESS"
export const CLOSE_TIMESHEET_MONTH_FAILURE = "CLOSE_TIMESHEET_MONTH_FAILURE"

export const UPDATE_EXPENSE_EXTRA_FIELD_REQUEST = "UPDATE_EXPENSE_EXTRA_FIELD_REQUEST"
export const UPDATE_EXPENSE_EXTRA_FIELD_SUCCESS = "UPDATE_EXPENSE_EXTRA_FIELD_SUCCESS"
export const UPDATE_EXPENSE_EXTRA_FIELD_FAILURE = "UPDATE_EXPENSE_EXTRA_FIELD_FAILURE"

export const FETCH_EXPENSES_DATA_REQUEST = "FETCH_EXPENSES_DATA_REQUEST"
export const FETCH_EXPENSES_DATA_SUCCESS = "FETCH_EXPENSES_DATA_SUCCESS"
export const FETCH_EXPENSES_DATA_FAILURE = "FETCH_EXPENSES_DATA_FAILURE"
export const fetchExpensesData = monthTimesheetID => (dispatch, getState) => {
	let url = "/front/affiliates/expenses?month_timesheet_id=" + monthTimesheetID
	let init = { method: "GET" }
	return dispatch(simpleFetch(url, init, "FETCH_EXPENSES_DATA"))
}

export const SUBMIT_EXPENSE_REQUEST = "SUBMIT_EXPENSE_REQUEST"
export const SUBMIT_EXPENSE_SUCCESS = "SUBMIT_EXPENSE_SUCCESS"
export const SUBMIT_EXPENSE_FAILURE = "SUBMIT_EXPENSE_FAILURE"
export const submitExpense = (type, mtID) => (dispatch, getState) => {
	let url = "/front/affiliates/expenses/" + type
	let init = { method: "POST", body: JSON.stringify({ type, month_timesheet_id: mtID }) }
	dispatch(simpleFetch(url, init, "SUBMIT_EXPENSE"))
}

export const REMOVE_EXPENSE_REQUEST = "REMOVE_EXPENSE_REQUEST"
export const REMOVE_EXPENSE_SUCCESS = "REMOVE_EXPENSE_SUCCESS"
export const REMOVE_EXPENSE_FAILURE = "REMOVE_EXPENSE_FAILURE"
export const removeExpense = (type, mtID) => (dispatch, getState) => {
	let url = "/front/affiliates/expenses/" + type
	let init = { method: "DELETE", body: JSON.stringify({ type, month_timesheet_id: mtID }) }
	dispatch(simpleFetch(url, init, "REMOVE_EXPENSE"))
}

export const SUBMIT_TIMESHEET_MANUAL_ENTRY_REQUEST = "SUBMIT_TIMESHEET_MANUAL_ENTRY_REQUEST"
export const SUBMIT_TIMESHEET_MANUAL_ENTRY_SUCCESS = "SUBMIT_TIMESHEET_MANUAL_ENTRY_SUCCESS"
export const SUBMIT_TIMESHEET_MANUAL_ENTRY_FAILURE = "SUBMIT_TIMESHEET_MANUAL_ENTRY_FAILURE"
export const submitManualEntry = (manualEntry, mtID) => (dispatch, getState) => {
	let url = "/front/affiliates/expenses/manual_entries"
	let init = {
		method: "POST",
		body: JSON.stringify({
			...manualEntry,
			month_timesheet_id: mtID,
		}),
	}
	dispatch(simpleFetch(url, init, "SUBMIT_TIMESHEET_MANUAL_ENTRY"))
}

export const REMOVE_TIMESHEET_MANUAL_ENTRY_REQUEST = "REMOVE_TIMESHEET_MANUAL_ENTRY_REQUEST"
export const REMOVE_TIMESHEET_MANUAL_ENTRY_SUCCESS = "REMOVE_TIMESHEET_MANUAL_ENTRY_SUCCESS"
export const REMOVE_TIMESHEET_MANUAL_ENTRY_FAILURE = "REMOVE_TIMESHEET_MANUAL_ENTRY_FAILURE"
export const removeManualEntry = (manualEntryID, mtID) => (dispatch, getState) => {
	let url = "/front/affiliates/expenses/manual_entries"
	let init = {
		method: "DELETE",
		body: JSON.stringify({ month_timesheet_id: mtID, id: manualEntryID }),
	}
	dispatch(simpleFetch(url, init, "REMOVE_TIMESHEET_MANUAL_ENTRY"))
}

export const checkTimesheetMappingNeeds = timesheetID => (dispatch, getState) => {
	let {
		redData: { timesheetsData },
	} = getState()

	let currTimesheet = timesheetsData.find(
		sched => sched.timesheetInfo.timesheetID === timesheetID
	)
	let currTimesheetNeedsMapping = Object.keys(currTimesheet.eventsForMonth).some(day => {
		let events = currTimesheet.eventsForMonth[day]
		return events.some(
			event =>
				!(event.event_type === "other_activity") &&
				!event.affiliate_deleted &&
				!event.social_secretary_code
		)
	})
	let newSchedules = timesheetsData.map(sched => {
		if (sched.timesheetInfo.timesheetID === currTimesheet.timesheetInfo.timesheetID) {
			return {
				...currTimesheet,
				timesheetInfo: { ...sched.timesheetInfo, needsMapping: currTimesheetNeedsMapping },
			}
		}
		return sched
	})
	dispatch(updateAllTimesheets(newSchedules))
}

export const goToQueryTimesheetWorker = () => (dispatch, getState) => {
	let {
		redData: { timesheetsData },
	} = getState()
	let query = parseQuery(window.location.search)
	let timesheetWasFetched = timesheetsData.some(
		entry => entry.worker.id === parseInt(query.workerID, 10)
	)
	if (timesheetWasFetched) {
		let id = Number(query.workerID)
		dispatch(timesheetsStateChange({ selectedWorker: id }))
	}
}

export const fetchTimesheets = () => async (dispatch, getState) => {
	let {
		redComponents: { timesheetsComponent },
	} = getState()
	let timesheetsQuery = stringifyQuery({
		for_month: timesheetsComponent.selectedMonth.format(),
		worker_contracts: [timesheetsComponent.selectedWorker],
		squad_ids: timesheetsComponent.selectedSquads,
		// worker_contracts: [3613],
	})
	let timesheetsURL = "/front/affiliates/schedule"
	let init = { method: "GET" }
	let response = await dispatch(
		simpleFetch(timesheetsURL + timesheetsQuery, init, "FETCHSCHEDULES")
	)
	if (response.actionType === FETCHSCHEDULES_SUCCESS) {
		dispatch(goToQueryTimesheetWorker())
	}
}

export const fetchSecSocMappings = () => (dispatch, getState) => {
	let init = { method: "GET" }
	let mappingsURL = "/front/affiliates/social_secretary_term_mappings"
	return dispatch(simpleFetch(mappingsURL, init, "FETCH_SEC_SOC_MAPPINGS"))
}

export const fetchTimesheetsData = () => async (dispatch, getState) => {
	await dispatch(fetchSecSocMappings())
	dispatch(fetchTimesheets())
}

export const selectTimesheetsMonth = month => async (dispatch, getState) => {
	history.push({
		search: stringifyQuery({
			...parseQuery(window.location.search),
			month: month.format("YYYY-MM-DD"),
		}),
	})
	dispatch(timesheetsStateChange({ selectedMonth: month }))
	dispatch(fetchTimesheets())
}

export const selectTimesheetWorker = workerID => async (dispatch, getState) => {
	let {
		redComponents: { timesheetsComponent },
	} = getState()
	dispatch(timesheetsStateChange({ selectedWorker: workerID, editMode: false }))
	await dispatch(fetchTimesheets())
	if (timesheetsComponent.selectedTab === 1) {
		// << This is targeting the "costs" tab
		let {
			redData: { timesheetsData },
		} = getState()
		let currTimesheet = timesheetsData.find(e => e.worker.id === workerID)
		await dispatch(fetchExpensesData(currTimesheet.timesheetInfo.timesheetID))
	}
}

export const requestTimesheetDataExport = (query, fileRequest) => async (dispatch, getState) => {
	let init = { method: "POST", body: JSON.stringify(query) }
	let url = "/front/affiliates/social_secretary_exports"
	let requestMeta = {}
	if (fileRequest) {
		requestMeta = { fileRequest: fileRequest, fileKeyName: "timesheetExportURL" }
	}
	await dispatch(simpleFetch(url, init, "TIMESHEET_EXPORT", requestMeta))
	dispatch(fetchTimesheets())
}
export const requestSplittedTimesheetDataExport = (query, fileRequest) => async (
	dispatch,
	getState
) => {
	let init = { method: "POST", body: JSON.stringify(query) }
	let url = "/front/affiliates/social_secretary_exports"
	let requestMeta = {}
	if (fileRequest) {
		requestMeta = { fileRequest: fileRequest, fileKeyName: "timesheetExportURL" }
	}
	await dispatch(simpleFetch(url, init, "TIMESHEET_EXPORT", requestMeta))
}

export const requestTimesheetPDF = init => (dispatch, getState) => {
	let requestMeta = { fileRequest: true, fileKeyName: "pdfUrl" }
	dispatch(simpleFetch("/front/affiliates/schedule", init, "SCHEDULE_PDF", requestMeta))
}

export const submitTimesheetUpdate = (timesheetID, eventDate, newEvent) => async (
	dispatch,
	getState
) => {
	let data = { event_date: eventDate, new_event: newEvent }
	let init = { method: "PUT", body: JSON.stringify(data) }
	await dispatch(
		simpleFetch(`/front/affiliates/timesheets/${timesheetID}`, init, "SUBMITTIMESHEETUPDATE")
	)
	dispatch(checkTimesheetMappingNeeds(timesheetID))
}

export const submitCloseTimesheet = (date = undefined) => async (dispatch, getState) => {
	let {
		redData: { timesheetsData },
		redComponents: { timesheetsComponent },
	} = getState()
	let timeesheet = timesheetsData.find(e => e.worker.id === timesheetsComponent.selectedWorker)
	if (timeesheet.timesheetInfo.needsMapping && !date) {
		dispatch(timesheetsStateChange({ showUnclosableTimesheetWarningModal: true }))
		return
	}
	let init = { method: "GET" }
	let url = `/front/affiliates/timesheets/${timeesheet.timesheetInfo.timesheetID}/close_month${
		date ? `?date=${date.format("YYYY-MM-DD")}` : ""
	}`

	await dispatch(simpleFetch(url, init, "CLOSE_TIMESHEET_MONTH"))
	dispatch(checkTimesheetMappingNeeds(timeesheet.timesheetInfo.timesheetID))
}

export const REOPEN_TIMESHEET_WEEK = "REOPEN_TIMESHEET_WEEK"
export const REOPEN_TIMESHEET_WEEK_REQUEST = "REOPEN_TIMESHEET_WEEK_REQUEST"
export const REOPEN_TIMESHEET_WEEK_SUCCESS = "REOPEN_TIMESHEET_WEEK_SUCCESS"
export const REOPEN_TIMESHEET_WEEK_FAILURE = "REOPEN_TIMESHEET_WEEK_FAILURE"
export const submitReopenTimesheetWeek = week => async (dispatch, getState) => {
	let {
		redData: { timesheetsData },
		redComponents: { timesheetsComponent },
	} = getState()
	let timesheet = timesheetsData.find(e => e.worker.id === timesheetsComponent.selectedWorker)
	let init = { method: "GET" }
	let url = `/front/affiliates/timesheets/${
		timesheet.timesheetInfo.timesheetID
	}/reopen_week?week=${timesheetsComponent.selectedMonth.format("YYYY-MM-DD")}`

	await dispatch(simpleFetch(url, init, REOPEN_TIMESHEET_WEEK))
	dispatch(checkTimesheetMappingNeeds(timesheet.timesheetInfo.timesheetID))
}

export const SUBMIT_TIMESHEET_COMMENTS = "SUBMIT_TIMESHEET_COMMENTS"
export const SUBMIT_TIMESHEET_COMMENTS_REQUEST = "SUBMIT_TIMESHEET_COMMENTS_REQUEST"
export const SUBMIT_TIMESHEET_COMMENTS_SUCCESS = "SUBMIT_TIMESHEET_COMMENTS_SUCCESS"
export const SUBMIT_TIMESHEET_COMMENTS_FAILURE = "SUBMIT_TIMESHEET_COMMENTS_FAILURE"
export const submitTimesheetCommentsChanges = () => (dispatch, getState) => {
	let {
		redData: { timesheetsData },
		redComponents: { timesheetsComponent },
	} = getState()
	let currTimesheet = timesheetsData.find(
		timesheet => timesheet.worker.id === timesheetsComponent.selectedWorker
	)
	let init = {
		method: "PUT",
		body: JSON.stringify({
			worker_comment: currTimesheet.timesheetInfo.workerComment,
			social_secretary_comment: currTimesheet.timesheetInfo.secSocComment,
			month_timesheet_id: currTimesheet.timesheetInfo.timesheetID,
		}),
	}
	let url = "/front/affiliates/timesheet_comments/"
	dispatch(simpleFetch(url, init, SUBMIT_TIMESHEET_COMMENTS))
}

export const FETCH_TIMESHEET_CODES_SUMS = "FETCH_TIMESHEET_CODES_SUMS"
export const FETCH_TIMESHEET_CODES_SUMS_REQUEST = "FETCH_TIMESHEET_CODES_SUMS_REQUEST"
export const FETCH_TIMESHEET_CODES_SUMS_SUCCESS = "FETCH_TIMESHEET_CODES_SUMS_SUCCESS"
export const FETCH_TIMESHEET_CODES_SUMS_FAILURE = "FETCH_TIMESHEET_CODES_SUMS_FAILURE"
export const createCodesSumsPdf = (
	translate,
	codeSumsFromDate,
	codesSumsTimeSpan,
	codeSumsSquads
) => async (dispatch, getState) => {
	let toDate = moment(codeSumsFromDate).endOf(codesSumsTimeSpan)
	let url = "/front/affiliates/lists/ss_codes_sums/"
	let init = {
		method: "POST",
		body: JSON.stringify({
			from_date: codeSumsFromDate.format("YYYY-MM-DD"),
			to_date: toDate.format("YYYY-MM-DD"),
			squad_ids: codeSumsSquads,
		}),
	}
	let { actionType, data } = await dispatch(simpleFetch(url, init, FETCH_TIMESHEET_CODES_SUMS))
	if (actionType === FETCH_TIMESHEET_CODES_SUMS_SUCCESS) {
		let dataFromJson = data.data
		let allCodes = dataFromJson.reduce((acc, entry) => {
			Object.keys(entry.code_sums).forEach(code => {
				if (acc.includes(code)) return
				acc.push(code)
			})
			return acc
		}, [])
		let vouchers = dataFromJson.reduce((acc, entry) => {
			Object.keys(entry.vouchers).forEach(voucher => {
				if (acc.includes(voucher)) return
				acc.push(voucher)
			})
			return acc
		}, [])
		let timesheetsCodesSumsPDF = {
			showHead: "firstPage",
			margin: { top: 15, left: 5, bottom: 5, right: 5 },
			styles: { fontSize: 9, cellPadding: 1, halign: "left", valign: "middle" },
			columns: [
				{ header: translate("worker"), dataKey: "workerName" },
				...allCodes.map(code => ({ header: translate(code), dataKey: code })),
				...vouchers.map(voucher => ({
					header: translate(`${voucher}_expected`),
					dataKey: voucher,
				})),
				{ header: translate("total"), dataKey: "total" },
			],
			columnStyles: {
				total: { cellWidth: "wrap" },
				...allCodes.reduce((acc, code) => {
					acc[code] = { cellWidth: "wrap" }
					return acc
				}, {}),
				...vouchers.reduce((acc, voucher) => {
					acc[voucher] = { cellWidth: "wrap" }
					return acc
				}, {}),
			},
			body: dataFromJson.reduce((acc, entry) => {
				let row = allCodes.reduce(
					(acc, code) => {
						acc[code] = 0
						return acc
					},
					{ workerName: entry.worker_name, total: formatMinutes(entry.total) }
				)
				Object.keys(entry.code_sums).forEach(code => {
					row[code] = formatMinutes(entry.code_sums[code])
				})
				Object.keys(entry.vouchers).forEach(voucher => {
					row[voucher] = entry.vouchers[voucher]
				})
				acc.push(row)
				return acc
			}, []),
		}
		let doc = new jsPDF("landscape")
		doc.text(
			`${moment().format("DD/MM/YY")} ${translate("timesheets_codes_sums_pdf_title")}`,
			5,
			10
		)
		doc.text(
			`${translate("period")}: ${codeSumsFromDate.format("DD/MM/YYYY")} - ${toDate.format(
				"DD/MM/YYYY"
			)}`,
			292,
			10,
			{ align: "right" }
		)
		doc.autoTable(timesheetsCodesSumsPDF)
		doc.save(
			"ss-codes-list-" + codeSumsFromDate.format("YYYY-MM-DD") + toDate.format("YYYY-MM-DD")
		)
	}
}

export const REOPEN_TIMESHEET_MONTH = "REOPEN_TIMESHEET_MONTH"
export const REOPEN_TIMESHEET_MONTH_REQUEST = "REOPEN_TIMESHEET_MONTH_REQUEST"
export const REOPEN_TIMESHEET_MONTH_SUCCESS = "REOPEN_TIMESHEET_MONTH_SUCCESS"
export const REOPEN_TIMESHEET_MONTH_FAILURE = "REOPEN_TIMESHEET_MONTH_FAILURE"
export const submitReopenTimesheetMonth = week => async (dispatch, getState) => {
	let {
		redData: { timesheetsData },
		redComponents: { timesheetsComponent },
	} = getState()
	let timesheet = timesheetsData.find(e => e.worker.id === timesheetsComponent.selectedWorker)
	let init = { method: "GET" }
	let url = `/front/affiliates/timesheets/${timesheet.timesheetInfo.timesheetID}/reopen_month`

	await dispatch(simpleFetch(url, init, REOPEN_TIMESHEET_MONTH))
	dispatch(checkTimesheetMappingNeeds(timesheet.timesheetInfo.timesheetID))
}

export const REOPEN_ALL_MONTH_TIMESHEETS = "REOPEN_ALL_MONTH_TIMESHEETS"
export const REOPEN_ALL_MONTH_TIMESHEETS_REQUEST = "REOPEN_ALL_MONTH_TIMESHEETS_REQUEST"
export const REOPEN_ALL_MONTH_TIMESHEETS_SUCCESS = "REOPEN_ALL_MONTH_TIMESHEETS_SUCCESS"
export const REOPEN_ALL_MONTH_TIMESHEETS_FAILURE = "REOPEN_ALL_MONTH_TIMESHEETS_FAILURE"
export const submitReopenAllTimesheets = () => async (dispatch, getState) => {
	let {
		redComponents: {
			timesheetsComponent: { selectedMonth },
		},
	} = getState()
	let init = { method: "PUT", body: JSON.stringify({ for_month: selectedMonth.format() }) }
	let url = `/front/affiliates/timesheets/reopen_all_month_timesheets`

	let { actionType } = await dispatch(simpleFetch(url, init, REOPEN_ALL_MONTH_TIMESHEETS))
	if (actionType === REOPEN_ALL_MONTH_TIMESHEETS_SUCCESS) dispatch(fetchTimesheets())
}

export const REFRESH_TIMESHEET = "REFRESH_TIMESHEET"
export const REFRESH_TIMESHEET_REQUEST = "REFRESH_TIMESHEET_REQUEST"
export const REFRESH_TIMESHEET_SUCCESS = "REFRESH_TIMESHEET_SUCCESS"
export const REFRESH_TIMESHEET_FAILURE = "REFRESH_TIMESHEET_FAILURE"
export const submitRefreshCurrentTimesheet = () => async (dispatch, getState) => {
	let {
		redData: { timesheetsData },
		redComponents: { timesheetsComponent },
	} = getState()
	let currTimesheet = timesheetsData.find(
		timesheet => timesheet.worker.id === timesheetsComponent.selectedWorker
	)
	let init = {
		method: "POST",
		body: JSON.stringify({ id: currTimesheet.timesheetInfo.timesheetID }),
	}
	let url = "/front/affiliates/timesheets/compare_to_refreshed_timesheet/"
	let { data, actionType } = await dispatch(simpleFetch(url, init, REFRESH_TIMESHEET))
	if (actionType === REFRESH_TIMESHEET_SUCCESS) {
		let ref_exp_data = data.refreshed_expense_data
		let ori_exp_data = data.original_expense_data
		dispatch(
			timesheetsStateChange({
				showRefreshCompareResults: true,
				refreshCompareResults: {
					...data,
					refreshed_timesheet: {
						...normalizeTimesheetsData([data.refreshed_timesheet])[0],
						costsInfos: {
							costsLines: ref_exp_data.all_expenses,
							mealVouchers: ref_exp_data.meal_vouchers,
							commuteExpenses: ref_exp_data.travel_expenses,
							clothesExpenses: ref_exp_data.clothes_expenses,
						},
					},
					original_timesheet: {
						...normalizeTimesheetsData([data.original_timesheet])[0],
						costsInfos: {
							costsLines: ori_exp_data.all_expenses,
							mealVouchers: ori_exp_data.meal_vouchers,
							commuteExpenses: ori_exp_data.travel_expenses,
							clothesExpenses: ori_exp_data.clothes_expenses,
						},
					},
				},
			})
		)
	}
}

export const CONFIRM_REFRESH_TIMESHEET = "CONFIRM_REFRESH_TIMESHEET"
export const CONFIRM_REFRESH_TIMESHEET_REQUEST = "CONFIRM_REFRESH_TIMESHEET_REQUEST"
export const CONFIRM_REFRESH_TIMESHEET_SUCCESS = "CONFIRM_REFRESH_TIMESHEET_SUCCESS"
export const CONFIRM_REFRESH_TIMESHEET_FAILURE = "CONFIRM_REFRESH_TIMESHEET_FAILURE"
export const submitConfirmRefreshCurrentTimesheet = () => async (dispatch, getState) => {
	let {
		redData: { timesheetsData },
		redComponents: { timesheetsComponent },
	} = getState()
	let currTimesheet = timesheetsData.find(
		timesheet => timesheet.worker.id === timesheetsComponent.selectedWorker
	)
	let init = {
		method: "POST",
		body: JSON.stringify({ id: currTimesheet.timesheetInfo.timesheetID }),
	}
	let url = "/front/affiliates/timesheets/refresh_timesheet/"
	let { actionType } = await dispatch(simpleFetch(url, init, CONFIRM_REFRESH_TIMESHEET))
	if (actionType === CONFIRM_REFRESH_TIMESHEET_SUCCESS) {
		dispatch(timesheetsStateChange({ showRefreshCompareResults: false }))
	}
}
