import React, { Component } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"

import * as actions from "../actions"

import BookingItem from "./BookingItem"
import Paginator from "./Paginator"
import { PageLayout, ContentLayout, PootsyButton } from "./FunctionalDesign"
import { PootsyTextInput } from "./FunctionalInputs"

import BookingFilters from "./BookingFilters"

class Bookings extends Component {
	handleSearchChange = e => {
		this.props.dispatch(actions.bookingFiltersStateChange({ search: e.target.value }))
	}
	handleChangePage = changes => {
		this.props.dispatch(actions.bookingFiltersStateChange({ page: changes.value }))
	}
	fetchListForPDF = () => {
		this.props.dispatch(actions.createBookingsListPDF(this.context.t))
	}
	render() {
		let { t } = this.context
		let { bookings, page, pagination, match, location, history } = this.props
		let pageHeaderLeft = <h1 className="page-title bookings">{t("bookings")}</h1>
		let pageHeaderRight = (
			<Link to="/bookings/new">
				<PootsyButton text={t("add_new_booking")} />
			</Link>
		)
		let optionsBoxes = (
			<BookingFilters
				key="a"
				match={match}
				location={location}
				history={history}
				customQueries={{
					fromDateQuery: {
						urlName: "from",
						queryName: "from_date",
						mustBeSet: true,
						defaultValue: moment().format("YYYY-MM-DD"),
					},
					toDateQuery: {
						urlName: "to",
						queryName: "to_date",
						mustBeSet: true,
						defaultValue: moment().format("YYYY-MM-DD"),
					},
					searchQuery: { urlName: "search", queryName: "filter_name", mustBeSet: false },
					ratingQuery: {
						urlName: "rating",
						queryName: "filter_rating",
						mustBeSet: false,
					},
					service_delivery_status_filter: {
						urlName: "service_delivery_status_filter",
						queryName: "filter_service_delivery_status",
						mustBeSet: false,
						defaultValue: "assigned",
					},
					typeQuery: { urlName: "type", queryName: "type", mustBeSet: false },
				}}
			>
				<PootsyButton text={t("download_list_pdf")} onClick={this.fetchListForPDF} />
			</BookingFilters>
		)
		let contentTop = (
			<PootsyTextInput
				customClass="bookings-search-input"
				label={t("search_booking")}
				onChange={this.handleSearchChange}
			/>
		)
		return (
			<PageLayout headerLeft={pageHeaderLeft} headerRight={pageHeaderRight}>
				<ContentLayout
					optionsBoxes={optionsBoxes}
					contentTop={contentTop}
					customClass="bookings"
				>
					<div className="booking-list">
						{bookings.map(entry => (
							<BookingItem key={entry.id} booking={entry} />
						))}
					</div>
					<Paginator
						name="pageQuery"
						currPage={page}
						totalPages={pagination.total_pages}
						goToPage={this.handleChangePage}
					/>
				</ContentLayout>
			</PageLayout>
		)
	}
}

Bookings.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	lang: state.i18nState.lang,
	bookings: state.redData.bookings,
	page: state.redComponents.bookingFiltersComponent.page,
	pagination: state.redComponents.bookingFiltersComponent.pagination,
	bookingsComponent: state.redComponents.bookingsComponent,
})
export default connect(mapStateToProps)(Bookings)
