import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import ModalLayout from "./ModalLayout"
import { publicTransportLogic } from "../constants/travelExpensesExplanation"
import {
	fetchExpensesData,
	submitExpense,
	removeExpense,
	submitManualEntry,
	timesheetsCostLineChange,
	simpleFetch,
	removeManualEntry,
} from "../actions"

import TimesheetCostLines from "./TimesheetCostLines"
import ManualEntryForm from "./ManualEntryForm"
import { PootsyButton, PootsySubHeading } from "./FunctionalDesign"

class TimesheetsCosts extends Component {
	state = {
		showCalculationModal: false,
	}
	UNSAFE_componentWillMount = () => {
		let { dispatch, currTimesheet } = this.props
		let id = currTimesheet.timesheetInfo.timesheetID
		dispatch(fetchExpensesData(id))
	}
	addMealVouchers = () => {
		this.submitExpense("meal_vouchers")
	}
	addCommuteExpense = () => {
		this.submitExpense("travel_expenses")
	}
	addClothesExpenses = () => {
		this.submitExpense("clothes_expenses")
	}
	addManualEntry = manualEntry => {
		let { currTimesheet } = this.props
		this.props.dispatch(submitManualEntry(manualEntry, currTimesheet.timesheetInfo.timesheetID))
	}
	removeMealVouchers = () => {
		this.removeExpense("meal_vouchers")
	}
	removeCommuteExpense = () => {
		this.removeExpense("travel_expenses")
	}
	removeClothesExpenses = () => {
		this.removeExpense("clothes_expenses")
	}
	submitExpense = type => {
		let { currTimesheet } = this.props
		this.props.dispatch(submitExpense(type, currTimesheet.timesheetInfo.timesheetID))
	}
	removeExpense = type => {
		let { currTimesheet } = this.props
		this.props.dispatch(removeExpense(type, currTimesheet.timesheetInfo.timesheetID))
	}
	handleCostLineChange = ({ scheduleID, costLineType, costLineID, newCostLine }) => {
		this.props.dispatch(
			timesheetsCostLineChange({ scheduleID, costLineType, costLineID, newCostLine })
		)
	}
	removeManualEntry = manualEntryID => {
		let { currTimesheet } = this.props
		this.props.dispatch(
			removeManualEntry(manualEntryID, currTimesheet.timesheetInfo.timesheetID)
		)
	}
	closeModal = () => {
		this.setState({ showCalculationModal: false })
	}
	submitChanges = inits => {
		inits.forEach(init => {
			this.props.dispatch(
				simpleFetch(
					"/front/affiliates/expenses/update_expense_extra_field",
					init,
					"UPDATE_EXPENSE_EXTRA_FIELD"
				)
			)
		})
	}
	render() {
		let { t } = this.context
		let { currTimesheet, currAffiliate } = this.props
		if (!currTimesheet.costsInfos) {
			return null
		}
		let {
			total_distance,
			total_amount,
			worker_vehicle,
		} = currTimesheet.costsInfos.commuteExpenses
		return (
			<div className="timesheets-costs">
				<TimesheetCostLines
					currTimesheet={currTimesheet}
					secSocEvents={currAffiliate.secSocEvents}
					handleCostLineChange={this.handleCostLineChange}
					removeManualEntry={this.removeManualEntry}
					submitChanges={this.submitChanges}
				/>
				<ManualEntryForm
					secSocEvents={currAffiliate.secSocEvents}
					addManualEntry={this.addManualEntry}
				/>
				<div className="section meal-vouchers">
					<PootsySubHeading text={t("meal_vouchers_section_title")} />
					<div className="infos">
						<div className="infos">
							{t("meal_voucher_days_worked")}:{" "}
							{currTimesheet.costsInfos.mealVouchers.data.days_worked}
						</div>
						<div className="infos">
							{t("meal_voucher_amount")}:{" "}
							{currTimesheet.costsInfos.mealVouchers.data.amount}
						</div>
					</div>
					{currTimesheet.costsInfos.mealVouchers.added_to_timesheet ? (
						<PootsyButton
							text={t("remove_from_timesheet")}
							onClick={this.removeMealVouchers}
							theme="cancel"
						/>
					) : (
						<PootsyButton text={t("add_to_timesheet")} onClick={this.addMealVouchers} />
					)}
				</div>
				{this.state.showCalculationModal && (
					<ModalLayout closeModal={this.closeModal}>
						{currTimesheet && currTimesheet.costsInfos.commuteExpenses && (
							<div style={{ whiteSpace: "pre-wrap" }}>
								{publicTransportLogic(
									total_distance,
									total_amount * 5,
									worker_vehicle,
									currAffiliate.defaultLanguage
								)}
							</div>
						)}
					</ModalLayout>
				)}
				<div className="section clothes-expenses">
					<PootsySubHeading text={t("clothes_expenses_section_title")} />
					<div className="infos">
						<div className="infos">
							{t("clothes_expenses_days_worked")}:{" "}
							{currTimesheet.costsInfos.clothesExpenses.data.days_worked}
						</div>
						<div className="infos">
							{t("clothes_expenses_amount")}:{" "}
							{currTimesheet.costsInfos.clothesExpenses.data.amount} €
						</div>
					</div>
					{currTimesheet.costsInfos.clothesExpenses.added_to_timesheet ? (
						<PootsyButton
							text={t("remove_from_timesheet")}
							onClick={this.removeClothesExpenses}
							theme="cancel"
						/>
					) : (
						<PootsyButton
							text={t("add_to_timesheet")}
							onClick={this.addClothesExpenses}
						/>
					)}
				</div>
				<div className="section commute-expenses">
					<PootsySubHeading text={t("commute_expenses_section_title")} />
					<div className="worker-vehicle-type">
						{t("worker_uses_vehicle_type", {
							vehicleType: t(currTimesheet.costsInfos.commuteExpenses.worker_vehicle),
						})}
						<div
							className="show-calculation-travel-cost"
							onClick={() => this.setState({ showCalculationModal: true })}
						>
							{t("show_calculation_logic")}
						</div>
					</div>
					<div className="summary-table">
						<div className="summary-table-section">
							<div className="commute-type">{t("commute_expenses_home_client")}</div>
							<div className="labels">
								<div className="label date" />
								<div className="label km">{t("km")}</div>
								<div className="label date">{t("€")}</div>
							</div>
							<div className="commute-expenses">
								{currTimesheet.costsInfos.commuteExpenses.data
									.filter(entry => entry.distance_type === "home_client")
									.map((entry, index) => (
										<div key={index} className="commute-expense">
											<div className="column date">{entry.date}</div>
											<div className="column km">{entry.distance_in_km}</div>
											<div className="column cost">
												{entry.amount
													.toFixed(2)
													.toString()
													.replace(".", ",")}
											</div>
										</div>
									))}
							</div>
							<div className="total">
								<div className="column empty" />
								<div className="column total-amount">
									{currTimesheet.costsInfos.commuteExpenses.data
										.filter(entry => entry.distance_type === "home_client")
										.reduce((acc, entry) => {
											acc += parseFloat(entry.distance_in_km) || 0
											return acc
										}, 0)
										.toFixed(2)
										.toString()
										.replace(".", ",")}
								</div>
								<div className="column total-km">
									{currTimesheet.costsInfos.commuteExpenses.data
										.filter(entry => entry.distance_type === "home_client")
										.reduce((acc, entry) => {
											acc += parseFloat(entry.amount) || 0
											return acc
										}, 0)
										.toFixed(2)
										.toString()
										.replace(".", ",")}
								</div>
							</div>
						</div>
						<div className="summary-table-section">
							<div className="commute-type">
								{t("commute_expenses_client_client_less_15")}
							</div>
							<div className="labels">
								<div className="label date" />
								<div className="label km">{t("km")}</div>
								<div className="label date">{t("€")}</div>
							</div>
							<div className="commute-expenses">
								{currTimesheet.costsInfos.commuteExpenses.data
									.filter(
										entry =>
											entry.distance_type === "client_client" &&
											entry.distance_in_km <= 15
									)
									.map((entry, index) => (
										<div key={index} className="commute-expense">
											<div className="column date">{entry.date}</div>
											<div className="column km">{entry.distance_in_km}</div>
											<div className="column cost">
												{entry.amount
													.toFixed(2)
													.toString()
													.replace(".", ",")}
											</div>
										</div>
									))}
							</div>
							<div className="total">
								<div className="column empty" />
								<div className="column total-amount">
									{currTimesheet.costsInfos.commuteExpenses.data
										.filter(
											entry =>
												entry.distance_type === "client_client" &&
												entry.distance_in_km <= 15
										)
										.reduce((acc, entry) => {
											acc += parseFloat(entry.distance_in_km) || 0
											return acc
										}, 0)
										.toFixed(2)
										.toString()
										.replace(".", ",")}
								</div>
								<div className="column total-km">
									{currTimesheet.costsInfos.commuteExpenses.data
										.filter(
											entry =>
												entry.distance_type === "client_client" &&
												entry.distance_in_km <= 15
										)
										.reduce((acc, entry) => {
											acc += parseFloat(entry.amount) || 0
											return acc
										}, 0)
										.toFixed(2)
										.toString()
										.replace(".", ",")}
								</div>
							</div>
						</div>
						<div className="summary-table-section">
							<div className="commute-type">
								{t("commute_expenses_client_client_more_15")}
							</div>
							<div className="labels">
								<div className="label date" />
								<div className="label km">{t("km")}</div>
								<div className="label date">{t("€")}</div>
							</div>
							<div className="commute-expenses">
								{currTimesheet.costsInfos.commuteExpenses.data
									.filter(
										entry =>
											entry.distance_type === "client_client" &&
											entry.distance_in_km > 15
									)
									.map((entry, index) => (
										<div key={index} className="commute-expense">
											<div className="column date">{entry.date}</div>
											<div className="column km">{entry.distance_in_km}</div>
											<div className="column cost">
												{entry.amount
													.toFixed(2)
													.toString()
													.replace(".", ",")}
											</div>
										</div>
									))}
							</div>
							<div className="total">
								<div className="column empty" />
								<div className="column total-amount">
									{currTimesheet.costsInfos.commuteExpenses.data
										.filter(
											entry =>
												entry.distance_type === "client_client" &&
												entry.distance_in_km > 15
										)
										.reduce((acc, entry) => {
											acc += parseFloat(entry.distance_in_km) || 0
											return acc
										}, 0)
										.toFixed(2)
										.toString()
										.replace(".", ",")}
								</div>
								<div className="column total-km">
									{currTimesheet.costsInfos.commuteExpenses.data
										.filter(
											entry =>
												entry.distance_type === "client_client" &&
												entry.distance_in_km > 15
										)
										.reduce((acc, entry) => {
											acc += parseFloat(entry.amount) || 0
											return acc
										}, 0)
										.toFixed(2)
										.toString()
										.replace(".", ",")}
								</div>
							</div>
						</div>
						<div className="summary-table-section">
							<div className="commute-type">{t("commute_expenses_travel_time")}</div>
							<div className="labels">
								<div className="label date" />
								<div className="label km">{t("km")}</div>
								<div className="label date">{t("€")}</div>
							</div>
							<div className="commute-expenses">
								{currTimesheet.costsInfos.commuteExpenses.data
									.filter(entry => entry.distance_type === "travel_time")
									.map((entry, index) => (
										<div key={index} className="commute-expense">
											<div className="column date">{entry.date}</div>
											<div className="column km">{entry.distance_in_km}</div>
											<div className="column cost">
												{entry.amount
													.toFixed(2)
													.toString()
													.replace(".", ",")}
											</div>
										</div>
									))}
							</div>
							<div className="total">
								<div className="column empty" />
								<div className="column total-amount">
									{currTimesheet.costsInfos.commuteExpenses.data
										.filter(entry => entry.distance_type === "travel_time")
										.reduce((acc, entry) => {
											acc += parseFloat(entry.distance_in_km) || 0
											return acc
										}, 0)
										.toFixed(2)
										.toString()
										.replace(".", ",")}
								</div>
								<div className="column total-km">
									{currTimesheet.costsInfos.commuteExpenses.data
										.filter(entry => entry.distance_type === "travel_time")
										.reduce((acc, entry) => {
											acc += parseFloat(entry.amount) || 0
											return acc
										}, 0)
										.toFixed(2)
										.toString()
										.replace(".", ",")}
								</div>
							</div>
						</div>
						<div className="summary-table-section">
							<div className="commute-type">{t("commute_expenses_client_home")}</div>
							<div className="labels">
								<div className="label date" />
								<div className="label km">{t("km")}</div>
								<div className="label date">{t("€")}</div>
							</div>
							<div className="commute-expenses">
								{currTimesheet.costsInfos.commuteExpenses.data
									.filter(entry => entry.distance_type === "client_home")
									.map((entry, index) => (
										<div key={index} className="commute-expense">
											<div className="column date">{entry.date}</div>
											<div className="column km">{entry.distance_in_km}</div>
											<div className="column cost">
												{entry.amount
													.toFixed(2)
													.toString()
													.replace(".", ",")}
											</div>
										</div>
									))}
							</div>
							<div className="total">
								<div className="column empty" />
								<div className="column total-amount">
									{currTimesheet.costsInfos.commuteExpenses.data
										.filter(entry => entry.distance_type === "client_home")
										.reduce((acc, entry) => {
											acc += parseFloat(entry.distance_in_km) || 0
											return acc
										}, 0)
										.toFixed(2)
										.toString()
										.replace(".", ",")}
								</div>
								<div className="column total-km">
									{currTimesheet.costsInfos.commuteExpenses.data
										.filter(entry => entry.distance_type === "client_home")
										.reduce((acc, entry) => {
											acc += parseFloat(entry.amount) || 0
											return acc
										}, 0)
										.toFixed(2)
										.toString()
										.replace(".", ",")}
								</div>
							</div>
						</div>
					</div>
					{currTimesheet.costsInfos.commuteExpenses.added_to_timesheet ? (
						<PootsyButton
							text={t("remove_from_timesheet")}
							onClick={this.removeCommuteExpense}
							theme="cancel"
						/>
					) : (
						<PootsyButton
							text={t("add_to_timesheet")}
							onClick={this.addCommuteExpense}
						/>
					)}
				</div>
			</div>
		)
	}
}

TimesheetsCosts.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	currAffiliate: state.redData.currentAffiliate,
})
export default connect(mapStateToProps)(TimesheetsCosts)
