const countriesList = [
	{ label: "", value: "" },
	{ label: "belgium", value: "11" },
	{ label: "germany", value: "12" },
	{ label: "france", value: "13" },
	{ label: "italy", value: "14" },
	{ label: "luxembourg", value: "15" },
	{ label: "netherlands", value: "16" },
	{ label: "denmark", value: "17" },
	{ label: "united_kingdom", value: "31" },
	{ label: "ireland", value: "18" },
	{ label: "spain", value: "32" },
	{ label: "greece", value: "33" },
	{ label: "poland", value: "34" },
	{ label: "portugal", value: "35" },
	{ label: "switzerland", value: "36" },
	{ label: "turkey", value: "37" },
	{ label: "yougoslavia", value: "38" },
	{ label: "europe", value: "39" },
	{ label: "zair", value: "51" },
	{ label: "algeria", value: "52" },
	{ label: "morocco", value: "53" },
	{ label: "tunisia", value: "54" },
	{ label: "africa", value: "59" },
	{ label: "america", value: "69" },
	{ label: "asia", value: "79" },
	{ label: "oceania", value: "89" },
	{ label: "unknown", value: "99" },
]

const countriesList2 = [
	{ label: " (Unknown)", value: "00000" },
	{ label: "Albania", value: "00101" },
	{ label: "Andorra", value: "00102" },
	{ label: "Germany", value: "00103" },
	{ label: "Austria", value: "00105" },
	{ label: "Bulgaria", value: "00106" },
	{ label: "Cyprus", value: "00107" },
	{ label: "Denmark", value: "00108" },
	{ label: "Spain", value: "00109" },
	{ label: "Finland", value: "00110" },
	{ label: "France", value: "00111" },
	{ label: "United Kingdom", value: "00112" },
	{ label: "Luxembourg /Grand Duchy/", value: "00113" },
	{ label: "Greece", value: "00114" },
	{ label: "Hungary", value: "00115" },
	{ label: "Ireland/Eire", value: "00116" },
	{ label: "Iceland", value: "00117" },
	{ label: "Liechtenstein", value: "00118" },
	{ label: "Malta", value: "00119" },
	{ label: "Monaco /Principality/", value: "00120" },
	{ label: "Norway", value: "00121" },
	{ label: "Poland", value: "00122" },
	{ label: "Portugal", value: "00123" },
	{ label: "Romania", value: "00124" },
	{ label: "San Marino", value: "00125" },
	{ label: "Sweden", value: "00126" },
	{ label: "Switzerland", value: "00127" },
	{ label: "Italy", value: "00128" },
	{ label: "The Netherlands", value: "00129" },
	{ label: "Holy See", value: "00133" },
	{ label: "Latvia", value: "00135" },
	{ label: "Estonia", value: "00136" },
	{ label: "Lithuania", value: "00137" },
	{ label: "Czech Republic", value: "00140" },
	{ label: "Slovak Republic", value: "00141" },
	{ label: "Belarus (Rep.)", value: "00142" },
	{ label: "Ukraine (Rep.)", value: "00143" },
	{ label: "Moldava (Rep.)", value: "00144" },
	{ label: "Russia (Federation)", value: "00145" },
	{ label: "Croatia (Rep.)", value: "00146" },
	{ label: "Slovenia (Rep.)", value: "00147" },
	{ label: "Macedonia", value: "00148" },
	{ label: "Bosnia and Herzegovina", value: "00149" },
	{ label: "Belgium", value: "00150" },
	{ label: "Montenegro", value: "00151" },
	{ label: "Serbia", value: "00152" },
	{ label: "Kosovo", value: "00153" },
	{ label: "Gibraltar", value: "00180" },
	{ label: "Myanmar", value: "00201" },
	{ label: "Sri Lanka", value: "00203" },
	{ label: "China - Taiwan", value: "00204" },
	{ label: "Singapore", value: "00205" },
	{ label: "South Korea (Rep.)", value: "00206" },
	{ label: "India", value: "00207" },
	{ label: "Indonesia", value: "00208" },
	{ label: "Japan", value: "00209" },
	{ label: "Laos", value: "00210" },
	{ label: "Malaysia", value: "00212" },
	{ label: "Nepal", value: "00213" },
	{ label: "Philippines", value: "00214" },
	{ label: "East Timor (Democratic Republic of)", value: "00215" },
	{ label: "Cambodia (Kingdom of)", value: "00216" },
	{ label: "China/People's Rep./", value: "00218" },
	{ label: "North Korea (People's Republic)", value: "00219" },
	{ label: "Socialist Republic of Vietnam", value: "00220" },
	{ label: "Mongolia (People's Republic)", value: "00221" },
	{ label: "Maldives", value: "00222" },
	{ label: "Bhutan", value: "00223" },
	{ label: "Brunei", value: "00224" },
	{ label: "Kazakhstan (Rep.)", value: "00225" },
	{ label: "Kyrgyzstan (Rep.)", value: "00226" },
	{ label: "Uzbekistan (Rep.)", value: "00227" },
	{ label: "Tajikistan (Rep.)", value: "00228" },
	{ label: "Turkmenistan (Rep.)", value: "00229" },
	{ label: "China (Hong Kong SAR)", value: "00230" },
	{ label: "China (Macau SAR)", value: "00231" },
	{ label: "Burma", value: "00232" },
	{ label: "Hong-Kong", value: "00234" },
	{ label: "Thailand", value: "00235" },
	{ label: "North Vietnam", value: "00236" },
	{ label: "Bangladesh", value: "00237" },
	{ label: "Armenia", value: "00249" },
	{ label: "Azerbaijan (Rep.)", value: "00250" },
	{ label: "Afghanistan", value: "00251" },
	{ label: "Saudi Arabia", value: "00252" },
	{ label: "Georgia (Rep.)", value: "00253" },
	{ label: "Iraq", value: "00254" },
	{ label: "Iran", value: "00255" },
	{ label: "Israel", value: "00256" },
	{ label: "Jordan", value: "00257" },
	{ label: "Lebanon", value: "00258" },
	{ label: "Pakistan", value: "00259" },
	{ label: "United Arabic Emirates", value: "00260" },
	{ label: "Syria /Syrian Arab Rep./", value: "00261" },
	{ label: "Turkey", value: "00262" },
	{ label: "Kuwait /Principality/", value: "00264" },
	{ label: "Sultanate of Oman", value: "00266" },
	{ label: "Qatar", value: "00267" },
	{ label: "Bahrain", value: "00268" },
	{ label: "Yemen (Rep.)", value: "00270" },
	{ label: "Palestine (State)", value: "00271" },
	{ label: "Palestinian Territory", value: "00273" },
	{ label: "Lesotho", value: "00301" },
	{ label: "Botswana", value: "00302" },
	{ label: "Burundi", value: "00303" },
	{ label: "Cameroon", value: "00304" },
	{ label: "Central African Republic", value: "00305" },
	{ label: "Congo (Dem. Rep)", value: "00306" },
	{ label: "Congo/People's Rep./", value: "00307" },
	{ label: "Burkina Faso", value: "00308" },
	{ label: "Côte d'Ivoire", value: "00309" },
	{ label: "Benin (People's Rep.)", value: "00310" },
	{ label: "Ethiopia", value: "00311" },
	{ label: "Gabon", value: "00312" },
	{ label: "Gambia", value: "00313" },
	{ label: "Ghana", value: "00314" },
	{ label: "Guinea", value: "00315" },
	{ label: "Mauritius /Island/", value: "00317" },
	{ label: "Liberia", value: "00318" },
	{ label: "Mali", value: "00319" },
	{ label: "Senegal", value: "00320" },
	{ label: "Niger", value: "00321" },
	{ label: "Nigeria (Federal Rep.)", value: "00322" },
	{ label: "Uganda", value: "00323" },
	{ label: "Democrat.Republ.Madagascar", value: "00324" },
	{ label: "South Africa", value: "00325" },
	{ label: "Rwanda /Rep./", value: "00327" },
	{ label: "Sierra Leone", value: "00328" },
	{ label: "Somalia /Rep./", value: "00329" },
	{ label: "Ngame", value: "00331" },
	{ label: "Tanzania /United Rep./", value: "00332" },
	{ label: "Chad", value: "00333" },
	{ label: "Togo", value: "00334" },
	{ label: "Zambia", value: "00335" },
	{ label: "Kenya", value: "00336" },
	{ label: "Equatorial Guinea", value: "00337" },
	{ label: "Guinea-Bissau", value: "00338" },
	{ label: "Cape Verde", value: "00339" },
	{ label: "Mozambique", value: "00340" },
	{ label: "Angola", value: "00341" },
	{ label: "Seychelles (Islands)", value: "00342" },
	{ label: "Archipelago of the Comoros", value: "00343" },
	{ label: "Zimbabwe", value: "00344" },
	{ label: "Republic of Djibouti", value: "00345" },
	{ label: "Sao Tome and Principe", value: "00346" },
	{ label: "Swaziland", value: "00347" },
	{ label: "Senegambia", value: "00348" },
	{ label: "Eritrea", value: "00349" },
	{ label: "Algeria", value: "00351" },
	{ label: "Arab Republic of Egypt", value: "00352" },
	{ label: "Libya", value: "00353" },
	{ label: "Morocco", value: "00354" },
	{ label: "Mauritania /Islamic Rep./", value: "00355" },
	{ label: "Sudan", value: "00356" },
	{ label: "Tunisia", value: "00357" },
	{ label: "Malawi", value: "00358" },
	{ label: "South Sudan", value: "00365" },
	{ label: "Cambinda", value: "00382" },
	{ label: "Namibia", value: "00384" },
	{ label: "Réunion", value: "00387" },
	{ label: "Sahara", value: "00388" },
	{ label: "Saint Helena (Island)", value: "00389" },
	{ label: "SanThome Island", value: "00393" },
	{ label: "Swaziland", value: "00395" },
	{ label: "Transkei", value: "00396" },
	{ label: "Bophutatswana", value: "00397" },
	{ label: "Canary Islands (E)", value: "00398" },
	{ label: "Madeira (P.)", value: "00399" },
	{ label: "Canada", value: "00401" },
	{ label: "United States of America", value: "00402" },
	{ label: "Antigua and Barbuda", value: "00403" },
	{ label: "Costa Rica", value: "00411" },
	{ label: "Cuba", value: "00412" },
	{ label: "Guatemala", value: "00413" },
	{ label: "Honduras", value: "00414" },
	{ label: "Jamaica", value: "00415" },
	{ label: "Mexico", value: "00416" },
	{ label: "Nicaragua", value: "00417" },
	{ label: "Panama", value: "00418" },
	{ label: "Haiti", value: "00419" },
	{ label: "Dominican Republic", value: "00420" },
	{ label: "El Salvador", value: "00421" },
	{ label: "Trinidad and Tobago", value: "00422" },
	{ label: "Barbados", value: "00423" },
	{ label: "British Antilles", value: "00424" },
	{ label: "Bahamas", value: "00425" },
	{ label: "Grenada", value: "00426" },
	{ label: "Republic of Dominica", value: "00427" },
	{ label: "Saint Lucia (republic)", value: "00428" },
	{ label: "Saint Vincent", value: "00429" },
	{ label: "Belize", value: "00430" },
	{ label: "Saint Kitts and Nevis", value: "00431" },
	{ label: "Us Virgin Islands (US)", value: "00478" },
	{ label: "British Virgin Islands (UK)", value: "00479" },
	{ label: "Dominica Island", value: "00480" },
	{ label: "French Antilles", value: "00481" },
	{ label: "The Netherlands Antilles", value: "00482" },
	{ label: "American Antilles", value: "00483" },
	{ label: "Bermuda", value: "00485" },
	{ label: "Puerto Rico (Island)", value: "00487" },
	{ label: "Turks and Caicos Islands", value: "00488" },
	{ label: "Anguilla", value: "00490" },
	{ label: "Cayman Islands (UK)", value: "00492" },
	{ label: "Montserrat (UK)", value: "00493" },
	{ label: "St-Pierre and Miquelon  (F)", value: "00495" },
	{ label: "Guadeloupe (F.)", value: "00496" },
	{ label: "Martinique (F.)", value: "00497" },
	{ label: "Greenland (D.K.)", value: "00498" },
	{ label: "Saint-Barthélemy (France)", value: "00499" },
	{ label: "Argentina", value: "00511" },
	{ label: "Bolivia", value: "00512" },
	{ label: "Brazil", value: "00513" },
	{ label: "Chili", value: "00514" },
	{ label: "Colombia", value: "00515" },
	{ label: "Ecuador", value: "00516" },
	{ label: "Paraguay", value: "00517" },
	{ label: "Peru", value: "00518" },
	{ label: "Uruguay", value: "00519" },
	{ label: "Venezuela", value: "00520" },
	{ label: "Guyana", value: "00521" },
	{ label: "Suriname", value: "00522" },
	{ label: "Falkland Islands", value: "00580" },
	{ label: "French Guyana", value: "00581" },
	{ label: "Micronesia", value: "00602" },
	{ label: "Marshall Islands (Republic of)", value: "00603" },
	{ label: "Niue", value: "00604" },
	{ label: "Cook", value: "00605" },
	{ label: "Australia", value: "00611" },
	{ label: "New Zealand", value: "00613" },
	{ label: "Nauru", value: "00615" },
	{ label: "Tonga", value: "00616" },
	{ label: "Fiji", value: "00617" },
	{ label: "Papua New Guinea", value: "00619" },
	{ label: "Pacific Islands", value: "00620" },
	{ label: "Tuvalu", value: "00621" },
	{ label: "Gilbert Islands", value: "00622" },
	{ label: "Solomon Islands", value: "00623" },
	{ label: "Vanuatu", value: "00624" },
	{ label: "Palau", value: "00679" },
	{ label: "Guam", value: "00681" },
	{ label: "Hawaii", value: "00682" },
	{ label: "New Caledonia", value: "00683" },
	{ label: "Polynesia", value: "00684" },
	{ label: "Tokelau /N-Z./", value: "00686" },
	{ label: "Tahiti", value: "00688" },
	{ label: "Wallis and Futuna /F.", value: "00689" },
	{ label: "American Samoa Islands", value: "00690" },
	{ label: "Pitcairn (UK overseas territory)", value: "00692" },
	{ label: "Terr. dep. on Australia", value: "00693" },
	{ label: "Terr. dep. on New Zealand", value: "00694" },
	{ label: "SHAPE", value: "00720" },
	{ label: "NATO", value: "00721" },
	{ label: "UN Refugee", value: "00730" },
	{ label: "UN undetermined", value: "00741" },
	{ label: "Stateless", value: "00900" },
]

export default [
	{
		categoryName: "contract",
		inputs: [
			{
				label: "contract_type",
				inputType: "select",
				options: [
					{ label: "no_subject", value: "0" },
					{ label: "unknown", value: "9" },
					{ label: "undefined_duration_contract", value: "A" },
					{ label: "defined_duration_contract", value: "B" },
					{ label: "defined_work_contract", value: "C" },
					{ label: "replacement_contract", value: "D" },
				],
				required: true,
			},
			{
				label: "disabled_worker",
				inputType: "select",
				options: [{ label: "no", value: "0" }, { label: "yes", value: "1" }],
				required: false,
			},
			{
				label: "studies",
				inputType: "select",
				options: [
					{ label: "no_education", value: "0" },
					{ label: "elementary_education", value: "1" },
					{ label: "secondary_education", value: "2" },
					{ label: "higher_non_univ_education", value: "3" },
					{ label: "higher_univ_education", value: "4" },
					{ label: "unknown", value: "9" },
				],
				required: true,
			},

			{
				label: "worker_category",
				inputType: "select",
				options: [
					{ label: "blue_collar", value: "A" },
					{ label: "white_collar", value: "B" },
					{ label: "blue_collar_apprentice", value: "C" },
					{ label: "white_collar_apprentice", value: "D" },
					{ label: "not_submitted_or_student", value: "E" },
					{ label: "domestic", value: "F" },
				],
				required: true,
			},
			{
				label: "apprentice_contract_number",
				inputType: "text",
				required: false,
				show: values => !["0", null].includes(values.apprentice_contract_type),
			},
			{
				label: "apprentice_contract_type",
				inputType: "select",
				options: [
					{ label: "no_subject", value: "0" },
					{ label: "apprentice_sme", value: "1" },
					{ label: "apprentice_industrial", value: "2" },
					{ label: "trading_company_manager_fr", value: "3" },
					{ label: "insertion_contract_cefa", value: "4" },
					{ label: "trading_company_manager_nl", value: "5" },
					{ label: "vocational_immersion_agreement", value: "6" },
					{ label: "worker_link_contract", value: "7" },
					{ label: "worker_link_contract_nl", value: "8" },
				],
				required: true,
			},
			{
				label: "worker_frontier",
				inputType: "select",
				options: [
					{ label: "no_subject", value: "0" },
					{ label: "french_border", value: "1" },
					{ label: "non_resident_artist", value: "8" },
					{ label: "resident_scale_1_2", value: "A" },
					{ label: "resident_scale_3_year_employment", value: "D" },
					{ label: "resident_scale_3_no_year_employment", value: "E" },
				],
				required: false,
			},
			{
				label: "nature_of_contract",
				inputType: "select",
				options: [
					{ label: "no_subject", value: "0" },
					{ label: "temporary_worker", value: "I" },
					{ label: "seasonal_worker", value: "S" },
					{ label: "intermittent", value: "T" },
				],
				required: true,
			},
			{
				label: "additionnal_years_ancienty",
				inputType: "text",
				required: false,
			},
			{
				label: "contract_specificity",
				inputType: "select",
				options: [
					{ label: "no_subject", value: "0" },
					{ label: "launching_job", value: "1" },
					{ label: "trainee_rd_230", value: "2" },
					{ label: "halftime_pep_with_deduction", value: "3" },
					{ label: "halftime_pep_without_deduction", value: "4" },
					{ label: "pep_other_than_halftime", value: "5" },
					{ label: "student", value: "6" },
					{ label: "traineeship", value: "7" },
					{ label: "extra_horeca", value: "8" },
					{ label: "super_extra_horeca", value: "E" },
					{ label: "occasional_worker_horeca", value: "F" },
					{ label: "transition_traineeship", value: "T" },
					{ label: "linked_training_wallonia", value: "W" },
					{ label: "flexijobs", value: "X" },
				],
				required: false,
			},
			{
				label: "direction",
				inputType: "select",
				secSocPrefillable: true,
				options: [{ label: "no_subject", value: "0" }, { label: "director", value: "1" }],
				required: false,
				default: (currentWorker, SecSocPrefill) =>
					currentWorker.direction || SecSocPrefill.direction,
			},
			{
				label: "accident_risk",
				inputType: "select",
				secSocPrefillable: true,
				options: [
					{ label: "no_subject", value: "000" },
					{ label: "sedentary_blue_collar", value: "001" },
					{ label: "cleaning_staff", value: "004" },
				],
				required: false,
				default: (currentWorker, SecSocPrefill) =>
					currentWorker.accident_risk || SecSocPrefill.accident_risk,
			},
			{
				label: "ape_plan",
				inputType: "select",
				options: [
					{ label: "no_subject", value: "0" },
					{ label: "ape_profit_company", value: "3" },
				],
			},
			{
				label: "reason_end_date",
				inputType: "select",
				options: [
					{ label: "in_service", value: "00" },
					{ label: "dismissal", value: "01" },
					{ label: "resignation", value: "02" },
					{ label: "serious_misconduct", value: "03" },
					{ label: "definite_term", value: "04" },
					{ label: "pension", value: "05" },
					{ label: "early_pension", value: "06" },
					{ label: "decease", value: "07" },
					{ label: "change_of_statute", value: "08" },
					{ label: "other", value: "09" },
					{ label: "resumption_after_time_credit", value: "11" },
					{ label: "anticipated_pension", value: "12" },
					{ label: "anticipated_pension_illness", value: "13" },
					{ label: "transfer_change", value: "15" },
					{ label: "collection_dismissal", value: "16" },
				],
				show: values => values.end_date,
			},
		],
	},

	{
		categoryName: "identification",
		inputs: [
			{
				label: "nationality",
				inputType: "select",
				options: countriesList,
				required: true,
			},
			{
				label: "birthdate",
				inputType: "datepicker",
				required: true,
			},
			{
				label: "birthplace_city",
				inputType: "text",
				required: true,
			},
			{
				label: "birthplace_country",
				inputType: "select",
				options: countriesList2,
				required: true,
			},
			{
				label: "civil_status",
				inputType: "select",
				required: true,
				options: [
					{ label: "marital_status_single", value: "1" },
					{ label: "married", value: "2" },
					{ label: "marital_status_widow", value: "3" },
					{ label: "separated", value: "4" },
					{ label: "divorced", value: "5" },
					{ label: "legally_separated", value: "6" },
					{ label: "legally_cohabiting", value: "7" },
					{ label: "unknown", value: "9" },
				],
				secSocPrefillable: false,
			},
			{
				label: "civil_status_date",
				inputType: "datepicker",
				required: false,
				show: values => ["2", "4", "5", "7"].includes(values.civil_status_code),
			},
		],
	},
	{
		categoryName: "family",
		inputs: [
			{
				label: "spouse_tax",
				inputType: "select",
				options: [
					{ label: "partner_with_income", value: "0" },
					{ label: "partner_with_no_income", value: "1" },
					{ label: "partner_less_than_230", value: "2" },
					{ label: "pension_less_than_138", value: "3" },
					{ label: "pension_less_than_459", value: "4" },
					{ label: "partner_works_eu", value: "5" },
				],
				default: "0",
				show: values => ["2", "4"].includes(values.civil_status_code),
			},

			{
				label: "spouse_first_name",
				inputType: "text",
				required: true,
				show: values => ["2", "7"].includes(values.civil_status_code),
			},
			{
				label: "spouse_last_name",
				inputType: "text",
				required: true,
				show: values => ["2", "7"].includes(values.civil_status_code),
			},
			{
				label: "spouse_dirthdate",
				inputType: "datepicker",
			},
			{
				label: "dependent_children",
				inputType: "text",
				required: false,
			},
			{
				label: "dependent_disabled_children",
				inputType: "text",
				required: false,
			},
			{
				label: "dependent_elders",
				inputType: "text",
				required: false,
			},
			{
				label: "dependent_disabled_elders",
				inputType: "text",
				required: false,
			},
			{
				label: "spouse_disabled",
				inputType: "select",
				options: [{ label: "no", value: "0" }, { label: "yes", value: "1" }],
				required: false,
			},
			{
				label: "dependent_adults",
				inputType: "text",
				required: false,
			},
			{
				label: "dependent_disabled_adults",
				inputType: "text",
				required: false,
			},
		],
	},
	{
		categoryName: "salary",
		inputs: [
			{
				label: "salary_type",
				inputType: "select",
				options: [
					{ label: "monthly", value: "M" },
					{ label: "hourly", value: "H1" },
					{ label: "daily", value: "D" },
				],
				required: true,
			},
			{ label: "salary_amount", inputType: "text", required: true },
		],
	},
	{
		categoryName: "taxation",
		inputs: [
			{
				label: "taxation_status",
				inputType: "select",
				options: [
					{ label: "with_advance_tax", value: "01" },
					{ label: "without_advance_tax", value: "03" },
					{ label: "without_advance_tax_yearly_work_excluded", value: "04" },
					{ label: "fiscal_file_281_20", value: "08" },
				],
				required: false,
			},
			{
				label: "taxation_specificity",
				inputType: "select",
				options: [
					{ label: "none", value: "0" },
					{ label: "additionnal_advance_amount", value: "1" },
					{ label: "additionnal_advance_percentage", value: "2" },
					{ label: "minimum_amount_advance", value: "3" },
					{ label: "fixed_amount", value: "4" },
				],
				required: false,
			},
			{ label: "amount_taxation_specificity", inputType: "text", required: false },
		],
	},
	{
		categoryName: "schedule",
		inputs: [
			{
				label: "c131_edition",
				inputType: "select",
				options: [
					{ label: "no_subject", value: "0" },
					{ label: "C131A_and_C131B", value: "1" },
					{ label: "only_C131A", value: "4" },
				],
			},
		],
	},

	{
		categoryName: "bank",
		inputs: [
			{
				label: "account_type",
				inputType: "select",
				options: [
					{ label: "no_bank_account", value: "0" },
					{ label: "postal_order", value: "2" },
					{ label: "circular_cheque_to_worker", value: "3" },
					{ label: "bank_iban", value: "4" },
					{ label: "circular_cheque_to_employer", value: "5" },
					{ label: "paper_iban", value: "6" },
				],
				required: true,
				secSocPrefillable: true,
				default: (currentWorker, SecSocPrefill) =>
					currentWorker.account_type || SecSocPrefill.account_type,
			},
			{
				label: "account_owner",
				inputType: "text",
				required: true,
				show: values => ["4", "6"].includes(values.account_type),
			},
			{
				label: "account_branch",
				inputType: "text",
				required: true,
				show: values => ["4", "6"].includes(values.account_type),
			},
			{
				label: "account_number",
				inputType: "text",
				required: true,
				show: values => ["4", "6"].includes(values.account_type),
			},
			{
				label: "account_digitcode",
				inputType: "text",
				required: true,
				show: values => ["4", "6"].includes(values.account_type),
			},
		],
	},
	{
		categoryName: "dates",
		inputs: [
			{ label: "first_entry_date", inputType: "datepicker", required: true },
			{ label: "start_date_pootsy", inputType: "datepicker", required: true },
			{ label: "end_date", inputType: "datepicker", required: false },
			{ label: "notice_start_date", inputType: "datepicker", required: false },
			{ label: "notification_notice_date", inputType: "datepicker", required: false },
		],
	},
]
