import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { PootsyButton } from "./FunctionalDesign"
import { sodexoConnectionConstants } from "../config.js"
import { stringifyQuery } from "../helpers/qs"

/**
 * Allow user to go to sodexo website in order to retrieve a website
 */
class SodexoConnectionTokenButton extends Component {
	url = () => {
		let params = {
			role: sodexoConnectionConstants.role,
			ui_locales: this.props.lang,
			response_type: sodexoConnectionConstants.responseType,
			client_id: sodexoConnectionConstants.clientId,
			display: sodexoConnectionConstants.display,
			redirect_uri: sodexoConnectionConstants.redirectUri,
			scope: sodexoConnectionConstants.scope,
		}
		return `${sodexoConnectionConstants.sodexoUrl}${stringifyQuery(params, { encode: false })}`
	}

	render() {
		let { t } = this.context
		return (
			<a href={this.url()}>
				<PootsyButton key="a" text={t("sodexo_conn_gen_token")} />
			</a>
		)
	}
}

SodexoConnectionTokenButton.contextTypes = { t: PropTypes.func }
const mapStateToProps = state => ({
	lang: state.locale.lang,
	sodexoConnectionStatus: state.redData.currentAffiliate.sodexoConnectionStatus,
})
export default connect(mapStateToProps)(SodexoConnectionTokenButton)
