import React from "react"
import { Transition } from "react-transition-group"



const transitionStyles = {
	entering: {
		opacity: 0,
	},
	entered: {
		opacity: 1,
		transform: "translateY(-10px)",
	},
	exiting: {
		opacity: 0,
		transform: "translateY(0px)",
	},
	exited: {
		display: "none",
	},
}

const OverlayReveal = ({ show, customClass = "", children }) => (
	<Transition timeout={300} in={show}>
		{status => {
			return (
				<div style={transitionStyles[status]} className={"OverlayReveal " + customClass}>
					{children}
				</div>
			)
		}}
	</Transition>
)

export default OverlayReveal
