export const translations = {
	nl: {
		affiliate_accessible_picker_title: "Kies een bedrijf",
		server_error: "Error",
		no_backend_message_error: "Please try again in a few minutes",
		checking_token: "Logging in",
	},
	en: {
		affiliate_accessible_picker_title: "Choose an agency",
		server_error: "Error",
		no_backend_message_error: "Please try again in a few minutes",
		checking_token: "Logging in",
	},
	fr: {
		affiliate_accessible_picker_title: "Choisissez une agence",
		server_error: "Erreur",
		no_backend_message_error: "Veuillez réessayer dans quelques minutes.",
		checking_token: "Authentification",
	},
}
